import React, { useState } from 'react'
import type { InventoryItemType } from 'typings'
import moment from 'moment/moment'
import { styled } from '@mui/system'
import { FlexRow } from 'components/atoms/Flex'
import { Capsule, Gutter } from 'components'
import { ProductName } from '../ProductName'
import { Button, TableCell, TableRow, Typography } from '@mui/material'
import { Stock } from 'screen/Inventory/TableComponents/Stock'
import { MdOutlineModeEditOutline } from 'react-icons/md'
import type { InventoryTableProps } from 'screen/Inventory/InventoryTable'
import { Abbr } from 'components/atoms/Abbr'
import analytics from 'utils/segment'
import { dateFormat, SEGMENT_EVENTS } from 'data'
import { currencyInput, truncate } from 'helpers'

const StyledTableRow = styled(TableRow)(() => ({
	// hide last border
	'&:last-child td, &:last-child th': {
		border: 0
	}
}))

const OutlinedButton = styled(Button)`
	border-radius: 7px;
	padding: 6px 12px;
	border: 1px solid ${({ theme }) => theme.palette.colors.gray['300']};
	color: ${({ theme }) => theme.palette.colors.black['300']};
`

const ProductCell = styled(TableCell)<{ width?: number }>`
	min-width: ${({ width }) => (width ? `${width}px` : '300px')};
	vertical-align: top;
`

const MedCell = styled(TableCell)<{ width?: number }>`
	min-width: ${({ width }) => (width ? `${width}px` : '180px')};
	max-width: 200px;
	vertical-align: top;
`

const CustomTableCell = styled(TableCell)<{ width?: number }>`
	min-width: ${({ width }) => (width ? `${width}px` : '200px')};
	max-width: 350px;
	vertical-align: top;
`

const LargeCell = styled(TableCell)<{ width?: number }>`
	min-width: ${({ width }) => (width ? `${width}px` : '200px')};
	max-width: 250px;
	vertical-align: top;
`

const EditIcon = styled(MdOutlineModeEditOutline)`
	color: ${({ theme }) => theme.palette.colors.black['500']};
`

const StyledCapsule = styled(Capsule)`
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	padding: 5px 14px;
`

const Item = styled(FlexRow)`
	max-width: 125px;
	margin-right: 5px;
`

const ShowMoreLabels = styled(Typography)`
	:hover {
		cursor: pointer;
	}
`

export type InventoryRowProps<T> = {
	gridTemplateColumns?: string
	row: T
	setActiveInventory?(id?: string): void
	setRestockOpen?(inventory: T): void
} & Pick<InventoryTableProps<T>, 'columnObj'>

export const InventoryRow: React.ComponentType<
	InventoryRowProps<InventoryItemType>
> = ({ columnObj, row, setActiveInventory, setRestockOpen }) => {
	const item = row.stock[0]

	const [showMoreLabels, setShowMoreLabels] = useState(false)

	const handleShowMoreLabels = (e: any) => {
		setShowMoreLabels(true)
		e.preventDefault()
	}

	const Text = ({ text }: { text: string }) => {
		return (
			<Typography variant={'caption'} fontWeight={400}>
				<Abbr title={currencyInput(`${text}`)} length={12} />
			</Typography>
		)
	}

	return (
		<>
			<StyledTableRow>
				<ProductCell component="th" scope="row">
					<ProductName row={row} />
				</ProductCell>
				<MedCell align="left" width={100}>
					<Abbr title={row.code} length={5} />
				</MedCell>
				{columnObj.labels && (
					<CustomTableCell
						align="left"
						width={columnObj.labels?.widthPx}
					>
						<FlexRow align={'center'}>
							{row.labels.length == 0 && (
								<Typography variant={'caption'}>-</Typography>
							)}
							{row.labels
								.slice(
									0,
									showMoreLabels ? row.labels.length : 2
								)
								.map(label => (
									<Item
										align="flex-start"
										title={label}
										key={label}
									>
										<Capsule
											disabled
											isSelected
											name={truncate(label, 10)}
											value={label}
											color={'blue'}
										/>
									</Item>
								))}
							{row.labels.length > 2 && !showMoreLabels && (
								<ShowMoreLabels
									variant="caption"
									onClick={handleShowMoreLabels}
								>
									{row.labels.length - 2}+
								</ShowMoreLabels>
							)}
						</FlexRow>
					</CustomTableCell>
				)}
				{columnObj.stock && (
					<CustomTableCell
						align="left"
						width={columnObj.stock?.widthPx}
					>
						<Stock row={row} />
					</CustomTableCell>
				)}
				{columnObj['perItemCostPrice'] && (
					<CustomTableCell
						align="left"
						width={columnObj['perItemCostPrice']?.widthPx}
					>
						<Text
							text={item ? `${item.perItemCostPrice}` : 'N/A'}
						/>
					</CustomTableCell>
				)}
				{columnObj['perItemSellPrice'] && (
					<CustomTableCell
						align="left"
						width={columnObj['perItemSellPrice']?.widthPx}
					>
						<Text
							text={item ? `${item.perItemSellPrice}` : 'N/A'}
						/>
					</CustomTableCell>
				)}
				{columnObj['date'] && (
					<CustomTableCell
						align="left"
						width={columnObj['date']?.widthPx}
					>
						{row?.stock[0] && (
							<StyledCapsule
								disabled
								isSelected
								color={'yellow'}
								value={moment(
									row?.stock[0].lastStockedAt
								).format(dateFormat)}
								name={moment(
									row?.stock[0].lastStockedAt
								).format(dateFormat)}
							/>
						)}
					</CustomTableCell>
				)}

				<LargeCell>
					<FlexRow justify={'flex-end'} style={{ width: '100%' }}>
						<OutlinedButton
							variant={'outlined'}
							onClick={() => {
								setRestockOpen?.(row)
								analytics.track(
									SEGMENT_EVENTS.INVENTORY_RESTOCK,
									{
										item_name: row.name
									}
								)
							}}
						>
							<Typography variant={'caption'}>Update</Typography>
						</OutlinedButton>
						<Gutter gap={0.3} />
						<OutlinedButton
							variant={'outlined'}
							onClick={() => {
								setActiveInventory?.(row?.id)
								analytics.track(SEGMENT_EVENTS.INVENTORY_EDIT, {
									item_name: row.name
								})
							}}
						>
							<EditIcon size={18} />
							<Gutter gap={0.3} />
							<Typography variant={'caption'}>Edit</Typography>
						</OutlinedButton>
					</FlexRow>
				</LargeCell>
			</StyledTableRow>
		</>
	)
}
