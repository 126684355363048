import { useSettings } from 'context/settings'
import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import type { RootState } from 'store/index-reducer'
import type { IBusiness } from 'typings'
import { styled } from '@mui/system'
import { FlexCol } from 'components/atoms/Flex'
import { useAppErrors, useDimension } from 'hooks'
import { getLookerReport } from 'utils/lookerReport'
import { getOrderBookerSettings } from 'api'

const Container = styled(FlexCol)<{ height: number }>`
	height: ${({ height }) => height - 160}px;
	width: 100%;
`

const Analytics = () => {
	const [displayOrderBookerModule, setDisplayOrderBookerModule] =
		useState<boolean>(false)

	const { businesses } = useSelector((state: RootState) => state.business)
	const { business } = useSettings()
	const { setAppError } = useAppErrors()
	const { height } = useDimension()

	const selectedBusiness = businesses.find(
		(buz: IBusiness) => buz.id === business
	)

	const fetchOrderBookerSettings = async () => {
		try {
			const res = await getOrderBookerSettings(business)
			setDisplayOrderBookerModule(
				res ? res.settings.displayOrderBookerModule : false
			)
		} catch (e: any) {
			setAppError(e)
		}
	}

	useEffect(() => {
		fetchOrderBookerSettings()
	}, [business, displayOrderBookerModule])

	return (
		<Container height={height}>
			<iframe
				title="Looker Studio Report"
				width="100%"
				height="100%"
				src={getLookerReport(
					selectedBusiness?.id,
					displayOrderBookerModule
				)}
				allowFullScreen
			></iframe>
		</Container>
	)
}

export { Analytics }
