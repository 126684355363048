import type { ColorsType, CreateOrderType } from 'typings'
import moment from 'moment/moment'
import { BsWhatsapp } from 'react-icons/bs'
import { AiOutlinePhone } from 'react-icons/ai'
import { BiEnvelope } from 'react-icons/bi'
import { MdAlternateEmail } from 'react-icons/md'
import { FiUser } from 'react-icons/fi'
import { objectMissingValue } from 'helpers/object'
import { HiOutlineDocumentText } from 'react-icons/hi'

export type SocialChannelProps = {
	id: number
	name: string
	value: string
	color: ColorsType
}

export const ORDER_INITIAL_VALUE = {
	type: 'inquiry',
	contact: undefined,
	items: [],
	channel: '',
	initiationAt: ''
}

export const ORDER_TYPE = [
	{
		id: 0,
		name: 'Inquiry',
		value: 'inquiry',
		color: 'blue'
	},
	{
		id: 1,
		name: 'Order',
		value: 'order',
		color: 'yellow'
	}
]

export const SOCIAL_CHANNEL: SocialChannelProps[] = [
	{
		id: 0,
		name: 'Phone',
		value: 'phone',
		color: 'blue'
	},
	{
		id: 1,
		name: 'WhatsApp',
		value: 'whatsapp',
		color: 'green'
	},
	{
		id: 2,
		name: 'Email',
		value: 'email',
		color: 'red'
	},
	{
		id: 3,
		name: 'Sms',
		value: 'sms',
		color: 'orange'
	},
	{
		id: 4,
		name: 'In Person',
		value: 'inperson',
		color: 'blueLight'
	}
]

export const SOCIAL = {
	phone: {
		color: 'blue',
		icon: AiOutlinePhone
	},
	whatsapp: {
		color: 'green',
		icon: BsWhatsapp
	},
	email: {
		color: 'red',
		icon: MdAlternateEmail
	},
	sms: {
		color: 'blue',
		icon: BiEnvelope
	},
	inperson: {
		color: 'blueLight',
		icon: FiUser
	},
	'order-form': {
		color: 'indigo',
		icon: HiOutlineDocumentText
	}
}

export const GENERATE_ORDER_DATA = (data: CreateOrderType): CreateOrderType => {
	return objectMissingValue(ORDER_INITIAL_VALUE, {
		...data,
		items: data.items
	})
}
