import produce from 'immer'
import { type InventoryActionsTypes, SET_ALL_LABEL } from './types'
import type { InventoryItemType, ResponsePageInfo } from 'typings'

interface InventoryState<T> {
	labels: string[]
	loading: boolean
	transformedData: T[]
	dataSource: T[]
	pageInfo: ResponsePageInfo
	activePageNumber: number
}

const initialState: InventoryState<InventoryItemType> = {
	labels: [],
	loading: true,
	transformedData: [],
	dataSource: [],
	activePageNumber: 1,
	pageInfo: { currentPage: 1, edgesPerPage: 8, totalPages: 1 }
}

const reducer = produce(
	(
		draft: InventoryState<InventoryItemType>,
		action: InventoryActionsTypes
	) => {
		switch (action.type) {
			case SET_ALL_LABEL:
				draft.labels = action.payload
				return

			default:
				return
		}
	},
	initialState
)

export default reducer
