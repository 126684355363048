import React, { useMemo, useState } from 'react'
import { styled } from '@mui/system'
import {
	Button,
	ClickAwayListener,
	Fade,
	Popper,
	Typography
} from '@mui/material'
import { Gutter } from 'components'
import { PrintOutlined, ShareOutlined, WhatsApp } from '@mui/icons-material'
import PopupState, { bindPopper, bindToggle } from 'material-ui-popup-state'
import { FlexCol, FlexRow } from 'components/atoms/Flex'
import type {
	InvoiceType,
	PaymentMethods,
	PaymentStatus,
	TransactionPaymentType
} from 'typings'
import { PrintInvoice } from './PrintInvoice'
import { PublishShareWarningModal } from './PublishShareWarningModal'
import { getPaymentByInvoiceId } from 'api'
import { publishAnInvoice } from 'api/invoice'
import {
	calculateInvoiceAdvanceAmount,
	invoice_calculation,
	LAYOUT_BREAKPOINT_WIDTH
} from 'data'
import { objectOmit } from 'helpers'
import { enqueueSnackbar } from 'notistack'
import { useNavigate } from 'react-router-dom'
import { useAppErrors } from 'hooks'
import moment from 'moment'

const TopButton = styled(Button)`
	background-color: ${({ theme }) => theme.palette.background.default};
	border: 1px solid ${({ theme }) => theme.palette.colors.gray[300]};
	align-items: center;
	min-width: 0;
	min-height: 32px;
	border-radius: 5px;
	padding: 0 9px;
	color: ${({ theme }) => theme.palette.colors.black['300']};
	box-shadow: 0 1px 2px 0 rgba(21, 29, 45, 0.08);
`

const MobileWrapper = styled(FlexRow)`
	@media (max-width: ${LAYOUT_BREAKPOINT_WIDTH}px) {
		display: none;
	}
`

const ShareIcon = styled(ShareOutlined)`
	font-size: 20px;
`

const ButtonText = styled(Typography)`
	color: ${({ theme }) => theme.palette.colors.gray['700']};
	font-weight: 500;
`

const PopupWrapper = styled(FlexCol)`
	background-color: ${({ theme }) => theme.palette.background.default};
	border: 1px solid ${({ theme }) => theme.palette.background.paper};
	border-radius: 8px;
	margin-top: 5px;
	min-width: 184px;
	box-shadow: 0 12px 16px -4px rgba(16, 24, 40, 0.08),
		0 4px 6px -2px rgba(16, 24, 40, 0.03);
`

const PopupButton = styled(Button)`
	justify-content: flex-start;
	width: 100%;
	padding: 10px 16px;
	color: ${({ theme }) => theme.palette.colors.gray['600']};
`

const PopupButtonText = styled(Typography)`
	font-size: 12px;
`
const PrintedText = styled(Typography)`
	font-size: 11px;
	color: ${({ theme }) => theme.palette.colors.gray['500']};
`

const WhatsappIcon = styled(WhatsApp)`
	font-size: 16px;
`

const StopIcon = styled(PrintOutlined)`
	font-size: 16px;
`

type ShareButtonProps = {
	whatsappButtonClick: () => void
	printButtonClick: () => void
	pdfRef: React.RefObject<HTMLDivElement>
	currentInvoice: InvoiceType
}

export const ShareButton: React.ComponentType<ShareButtonProps> = ({
	whatsappButtonClick,
	currentInvoice
}) => {
	const [openPrint, setOpenPrint] = useState<boolean>(false)
	const [openPublishShareWarningModal, setOpenPublishShareWarningModal] =
		useState<boolean>(false)
	const [payments, setPayments] = useState<TransactionPaymentType[]>([])
	const lastPrintedOn: Date = currentInvoice?.printingDetails?.isPrinted
		? currentInvoice?.printingDetails?.lastPrintedOn
		: new Date(Date.now())
	const printed: boolean = currentInvoice?.printingDetails?.isPrinted ?? false
	const [lastPrintedDate, setLastPrintedDate] = useState<Date>(lastPrintedOn)
	const [isPrinted, setIsPrinted] = useState<boolean>(printed)

	const navigate = useNavigate()
	const { setAppError } = useAppErrors()

	const handlePrint = (invoice: InvoiceType) => {
		if (invoice.status == 'draft') {
			setOpenPublishShareWarningModal(true)
		} else {
			setOpenPrint(true)
		}
	}
	const { totalAmount, advance } = useMemo(() => {
		let totalAmount = 0
		let advance = 0

		if (currentInvoice) {
			const { totalTax, baseAmtAfterDiscount } =
				invoice_calculation(currentInvoice)

			if (payments?.length === 0) {
				advance = calculateInvoiceAdvanceAmount(
					objectOmit(currentInvoice, 'id')
				)
			}

			let totalPayments = 0

			if (payments) {
				totalPayments = payments.reduce(
					(accumulator, payment) =>
						payment.type === 'sent'
							? accumulator - payment.amount
							: accumulator + payment.amount,
					totalPayments
				)
			}
			totalAmount = Number(
				(baseAmtAfterDiscount + totalTax - totalPayments).toFixed(2)
			)
		}

		return { totalAmount, advance }
	}, [currentInvoice, payments])

	const createPublishInvoiceRequest = async () => {
		try {
			if (currentInvoice) {
				let paymentStatus: PaymentStatus = 'pending'
				let paymentMethod: PaymentMethods = 'unpaid'
				if (advance > 0) {
					paymentMethod = 'cash'
					if (advance === totalAmount) {
						paymentStatus = 'paid'
					} else if (advance > totalAmount) {
						enqueueSnackbar('Advance greater than Total Amount', {
							variant: 'error'
						})
						return
					} else {
						paymentStatus = 'partial'
					}
				}
				if (totalAmount === 0) {
					paymentStatus = 'paid'
				}
				const updatedInvoice: InvoiceType = {
					...currentInvoice,
					paymentStatus,
					paymentMethod
				}
				const res = await publishAnInvoice(
					currentInvoice.id,
					updatedInvoice
				)

				enqueueSnackbar('Invoice Published Successfully', {
					variant: 'success'
				})

				navigate(`/invoice/view/${res?.id}`, {
					replace: true,
					state: { data: res, isUpdated: true }
				})

				const paymentsRes = await getPaymentByInvoiceId(
					currentInvoice.id
				)
				setPayments(paymentsRes)
			}
		} catch (e: any) {
			setAppError(e)
		}
	}

	return (
		<>
			<PopupState
				variant="popper"
				popupId="demo-popup-popper"
				disableAutoFocus={true}
			>
				{popupState => (
					<ClickAwayListener onClickAway={() => popupState.close()}>
						<div>
							<TopButton {...bindToggle(popupState)}>
								<MobileWrapper>
									<ButtonText variant={'subtitle2'}>
										Share
									</ButtonText>
									<Gutter gap={0.2} />
								</MobileWrapper>
								<ShareIcon />
							</TopButton>
							<Popper
								{...bindPopper(popupState)}
								transition
								placement="bottom-start"
							>
								{({ TransitionProps }) => (
									<Fade {...TransitionProps} timeout={350}>
										<PopupWrapper>
											<PopupButton
												onClick={whatsappButtonClick}
											>
												<FlexRow align="center">
													<WhatsappIcon />
													<Gutter gap={0.2} />
													<PopupButtonText variant="caption">
														WhatsApp
													</PopupButtonText>
												</FlexRow>
											</PopupButton>
											<PopupButton
												onClick={() =>
													handlePrint(currentInvoice)
												}
											>
												<FlexRow>
													<StopIcon />
													<Gutter gap={0.2} />
													<FlexCol>
														<PopupButtonText variant="caption">
															Print
														</PopupButtonText>
														{isPrinted && (
															<PrintedText variant="caption">
																{`Last Printed on ${moment(
																	lastPrintedDate
																).format(
																	'DD/M/YY'
																)}`}
															</PrintedText>
														)}
													</FlexCol>
												</FlexRow>
											</PopupButton>
										</PopupWrapper>
									</Fade>
								)}
							</Popper>
						</div>
					</ClickAwayListener>
				)}
			</PopupState>
			<PrintInvoice
				open={openPrint}
				onClose={() => setOpenPrint(false)}
				onPrintSuccess={() => {
					setIsPrinted(true)
					setLastPrintedDate(new Date(Date.now()))
				}}
				invoice={currentInvoice}
			/>
			<PublishShareWarningModal
				open={openPublishShareWarningModal}
				onClose={() => {
					setOpenPublishShareWarningModal(false)
					createPublishInvoiceRequest()
				}}
				onCancel={() => setOpenPublishShareWarningModal(false)}
			/>
		</>
	)
}
