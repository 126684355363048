import { axios } from 'store'
import type { IStockUnits } from 'typings/setting/stockUnit'

export const createStockUnit = async (payload: Pick<IStockUnits, 'name'>) => {
	const url = '/settings/stock-unit'

	try {
		const { data } = await axios.post<IStockUnits>(url, payload)

		return data
	} catch (error: any) {
		throw error.response.data
	}
}

export const getAllStockUnit = async () => {
	const url = '/settings/stock-unit'

	try {
		const { data } = await axios.get<IStockUnits[]>(url)

		return data
	} catch (error: any) {
		throw error.response.data
	}
}

export const deleteStockUnit = async (id: string) => {
	const url = `/settings/stock-unit/${id}`

	try {
		const { data } = await axios.delete<IStockUnits[]>(url)

		return data
	} catch (error: any) {
		throw error.response.data
	}
}

export const updateStockUnit = async (
	id: string,
	payload: Pick<IStockUnits, 'name'>
) => {
	const url = `/settings/stock-unit/${id}`

	try {
		const { data } = await axios.patch<IStockUnits>(url, payload)

		return data
	} catch (error: any) {
		throw error.response.data
	}
}
