export const FETCH_CBFS_INFO = 'FETCH_CBFS_INFO'
export const SET_CBFS_INFO = 'SET_CBFS_INFO'
interface IFetchCBFSInfo {
	type: typeof FETCH_CBFS_INFO
	businessId: string
}

interface ISetCBFSInfo {
	type: typeof SET_CBFS_INFO
	payload: any
}

export type CBFSActionTypes = IFetchCBFSInfo | ISetCBFSInfo
