import React, { useCallback, useMemo } from 'react'
import {
	Button,
	ClickAwayListener,
	Fade,
	Popper,
	styled,
	Typography,
	useTheme
} from '@mui/material'
import PopupState, { bindPopper, bindToggle } from 'material-ui-popup-state'
import { CheckBox, Gutter } from 'components'
import { FlexCol, FlexRow } from 'components/atoms/Flex'
import { CustomRadioGroup } from 'components/atoms/RadioGroup'
import { addOrRemoveArr, objectOmit } from 'helpers'
import analytics from 'utils/segment'
import { SECONDARY_BUTTON_BREAKPOINT_WIDTH, SEGMENT_EVENTS } from 'data'
import { FilterAltOutlined } from '@mui/icons-material'

const StyledButton = styled(Button, {
	shouldForwardProp: props => props !== 'isFilter'
})<{ isFilter?: boolean }>`
	background-color: ${({ theme, isFilter }) =>
		isFilter
			? theme.palette.colors.green[50]
			: theme.palette.background.default};
	border: 1px solid
		${({ theme, isFilter }) =>
			isFilter
				? theme.palette.colors.green[600]
				: theme.palette.colors.gray[300]};
	min-width: 0;
	min-height: 32px;
	border-radius: 5px;
	padding: 0px 9px;
`

const Wrapper = styled(FlexCol)`
	background-color: ${({ theme }) => theme.palette.background.default};
	border: 1px solid ${({ theme }) => theme.palette.background.paper};
	border-radius: 8px;
	margin-top: 5px;
	box-shadow: 0 12px 16px -4px rgba(16, 24, 40, 0.08),
		0px 4px 6px -2px rgba(16, 24, 40, 0.03);
`

const Option = styled(FlexRow)`
	padding: 8px 14px;
	width: 100%;

	:hover {
		cursor: pointer;
	}
`

const ClearBtn = styled(FlexRow)`
	width: 100%;
	padding: 10px 20px 10px 10px;

	:hover {
		cursor: pointer;
	}
`

const Title = styled(Option)`
	width: 100%;
	border-bottom: 1px solid ${({ theme }) => theme.palette.background.paper};
`

type OptionProps = {
	id: string | number
	label: string
	value: string
}

export type FilterButtonDataSourceProps = {
	title?: string
	mode?: 'multi' | 'single'
	name: string
	options: OptionProps[]
	id: string | number
	selectAll?: OptionProps
}

const SecondaryButtonWrapper = styled(FlexRow)`
	@media (max-width: ${SECONDARY_BUTTON_BREAKPOINT_WIDTH}px) {
		display: none;
	}
`

export type FilterObjProps = { [key: string]: string[] }

export type FilterButtonProps = {
	dataSource: FilterButtonDataSourceProps[]
	onSelect?(filterObj: FilterObjProps): void
	filterObj: FilterObjProps
}

export const FilterButton: React.ComponentType<FilterButtonProps> = ({
	dataSource,
	onSelect,
	filterObj
}) => {
	const theme = useTheme()
	const {
		palette: { colors, mode }
	} = theme

	const totalAppliedFilter = useMemo(() => {
		let length = 0
		for (const key in filterObj) {
			length += filterObj[key].length
		}
		return length
	}, [filterObj])

	const onSelectAll = useCallback(
		(name: string, options: OptionProps[], selectedObj?: string[]) => {
			analytics.track(SEGMENT_EVENTS.CONTACT_FILTER_SELECTED)
			const isAllSelected =
				selectedObj && selectedObj.length === options.length

			const newObj = {
				...filterObj,
				[name]: options.map(m => {
					return m.value
				})
			}

			onSelect?.(isAllSelected ? objectOmit(filterObj, name) : newObj)
		},
		[filterObj, onSelect]
	)

	const onCheckBoxSelect = useCallback(
		(name: string, value: string, selectedObj?: string[]) => {
			analytics.track(SEGMENT_EVENTS.CONTACT_FILTER_SELECTED)
			const res = addOrRemoveArr(selectedObj || [], value)

			const obj = {
				...filterObj,
				[name]: res
			}

			onSelect?.(res.length !== 0 ? obj : objectOmit(filterObj, name))
		},
		[filterObj, onSelect]
	)

	const RenderMultiSelect = useCallback(
		({ options, name, selectAll }: FilterButtonDataSourceProps) => {
			const selectedObj = filterObj[name]
			return (
				<FlexCol style={{ minWidth: 220 }}>
					<Gutter spacing={0.5} />
					{options.map(opt => (
						<Option
							align={'center'}
							key={opt.id}
							onClick={() =>
								onCheckBoxSelect(name, opt.value, selectedObj)
							}
						>
							<CheckBox
								checked={
									selectedObj &&
									selectedObj.indexOf(opt.value) !== -1
								}
							/>
							<Gutter gap={1} />
							<Typography variant={'body2'}>
								{opt.label}
							</Typography>
						</Option>
					))}
					{selectAll && (
						<Option
							align={'center'}
							onClick={() =>
								onSelectAll(name, options, selectedObj)
							}
						>
							<CheckBox
								checked={
									selectedObj &&
									selectedObj.length === options.length
								}
							/>
							<Gutter gap={1} />
							<Typography variant={'body2'}>
								{selectAll.label}
							</Typography>
						</Option>
					)}
				</FlexCol>
			)
		},
		[filterObj, onCheckBoxSelect, onSelectAll]
	)

	const RenderFilter = useCallback(
		({ title, options, mode, name }: FilterButtonDataSourceProps) => {
			const selectedObj = filterObj[name]
			return (
				<FlexCol style={{ minWidth: 220 }}>
					{title && (
						<Title>
							<Typography variant={'caption'} fontWeight={500}>
								{title}
							</Typography>
						</Title>
					)}

					{/* parent is already inside useCallback it's upto us*/}
					{mode === 'single' && (
						<Option align={'flex-start'}>
							<CustomRadioGroup
								options={options}
								defaultValue={
									selectedObj &&
									selectedObj.length > 0 &&
									selectedObj[0]
								}
								onChange={(
									event: React.ChangeEvent<HTMLInputElement>
								) =>
									onSelect?.({
										...filterObj,
										[name]: [event.target.value]
									})
								}
							/>
						</Option>
					)}
				</FlexCol>
			)
		},
		[filterObj, onSelect]
	)

	return (
		<PopupState
			variant="popper"
			popupId="demo-popup-popper"
			disableAutoFocus={true}
		>
			{popupState => (
				<ClickAwayListener onClickAway={() => popupState.close()}>
					<div>
						<StyledButton
							{...bindToggle(popupState)}
							isFilter={totalAppliedFilter !== 0}
						>
							<FlexRow
								onClick={() =>
									analytics.track(
										SEGMENT_EVENTS.CONTACT_FILTER
									)
								}
							>
								<FilterAltOutlined
									fontSize="small"
									sx={{ color: colors.gray['900'] }}
								/>
								<SecondaryButtonWrapper>
									<Gutter gap={0.3} />
									<Typography
										variant={'caption'}
										color={
											mode === 'dark'
												? colors.white['100']
												: colors.black['900']
										}
										fontWeight={500}
									>
										{totalAppliedFilter === 0
											? 'Filter by Type'
											: `${totalAppliedFilter} Filters Applied`}
									</Typography>
								</SecondaryButtonWrapper>
							</FlexRow>
						</StyledButton>
						<Popper
							{...bindPopper(popupState)}
							transition
							placement="bottom-start"
						>
							{({ TransitionProps }) => (
								<Fade {...TransitionProps} timeout={350}>
									<Wrapper>
										{dataSource.map(d => (
											<React.Fragment key={d.id}>
												{d.mode === 'multi' ? (
													<RenderMultiSelect {...d} />
												) : (
													<RenderFilter {...d} />
												)}
											</React.Fragment>
										))}
										<ClearBtn
											style={{ width: '100%' }}
											justify={'flex-end'}
											onClick={() => {
												onSelect?.({})
											}}
										>
											<Typography
												variant={'caption'}
												color={
													theme.palette.primary.main
												}
											>
												Clear All
											</Typography>
										</ClearBtn>
									</Wrapper>
								</Fade>
							)}
						</Popper>
					</div>
				</ClickAwayListener>
			)}
		</PopupState>
	)
}
