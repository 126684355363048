import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
	Pagination,
	Table,
	TableBody,
	TableCell,
	tableCellClasses,
	TableContainer,
	TableHead,
	TableRow,
	Typography
} from '@mui/material'
import { styled } from '@mui/system'
import { FlexRow } from 'components/atoms/Flex'
import { Button, Capsule, Gutter } from 'components'
import { useDimension, useTranslate } from 'hooks'
import type { RootState } from 'store/index-reducer'
import { MdOutlineRemoveRedEye, MdOutlineStore } from 'react-icons/md'
import moment from 'moment'
import { dateFormat, ROLE_COLORS } from 'data'
import type { ColorsType, IBusiness } from 'typings'
import { useSettings } from 'context/settings'
import { setCurrentBusiness } from 'store/user/actions'

const Wrapper = styled('div')`
	border: 1px solid ${({ theme }) => theme.palette.background.paper};
	border-radius: 7px;
	overflow: hidden;
	height: 360px;
	width: 100%;
`

const StyledTableCell = styled(TableCell)(({ theme }) => ({
	padding: 7,
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: theme.palette.background.paper,
		color:
			theme.palette.mode === 'dark'
				? theme.palette.colors['gray']['400']
				: theme.palette.colors['gray']['500']
	},
	[`&.${tableCellClasses.body}`]: {
		fontSize: 14
	},
	overflow: 'hidden',
	textOverflow: 'ellipsis'
}))

const Footer = styled(FlexRow)`
	justify-content: flex-end;
	align-items: center;
	padding: 10px 16px;
	width: 100%;
`

const StyledTableRow = styled(TableRow)(() => ({
	// hide last border
	'&:last-child td, &:last-child th': {
		border: 0
	}
}))

const SwitchBusinessButton = styled(Button)`
	border: 1px solid ${({ theme }) => theme.palette.colors['gray']['300']};
	width: 20ch;
	height: 5ch;
	font-size: 0.7rem;
	padding: 0;
	border-radius: 6px;
`

type BusinessTableProps = {
	setEditId: React.Dispatch<React.SetStateAction<string | undefined>>
	setIsEditModalOpen: React.Dispatch<React.SetStateAction<boolean>>
	setCapsuleNumber: React.Dispatch<React.SetStateAction<number | undefined>>
}

export const BusinessesTable: React.FC<BusinessTableProps> = ({
	setEditId,
	setIsEditModalOpen,
	setCapsuleNumber
}) => {
	const [rowsPerPage, setRowsPerPage] = useState<number>(0)
	const [config, setConfig] = useState({
		page: 0,
		sortOrder: {}
	})
	const { businesses } = useSelector((state: RootState) => state.business)
	const { setBusiness, business } = useSettings()
	const { height } = useDimension()
	const translate = useTranslate()
	const dispatch = useDispatch()
	const { page } = config
	const paginationCount = Math.ceil(businesses.length / rowsPerPage)
	const selectedBusiness = businesses.find(
		(buz: IBusiness) => buz.id === business
	)
	const { user } = useSelector((state: RootState) => state.user)
	const currentRole = useMemo(() => {
		if (user?.userRoles && user?.userRoles.length > 0)
			return user?.userRoles.find(f => f.business === business)?.role
		return 'admin'
	}, [user?.userRoles, business])

	const onConfigChange = useCallback(
		(key: string, value: any) => {
			setConfig({ ...config, [key]: value })
		},
		[config]
	)

	const handleSwitchBusiness = useCallback(
		(id: string) => {
			setBusiness(id)
			dispatch(setCurrentBusiness(id))
		},
		[dispatch, setBusiness]
	)

	useEffect(() => {
		if (height < 700) {
			setRowsPerPage(5)
		} else {
			setRowsPerPage(6)
		}
	}, [height])

	useEffect(() => {
		setCapsuleNumber(businesses.length)
	}, [setCapsuleNumber, businesses])

	const handleModalOpen = (id?: string) => {
		setIsEditModalOpen(true)
		setEditId(id)
	}

	const Role = ({ role }: { role: string }) => {
		const obj = ROLE_COLORS.find(
			obj => obj.name == role.charAt(0).toUpperCase() + role.slice(1)
		)

		return (
			<Capsule
				isSelected
				name={role.charAt(0).toUpperCase() + role.slice(1)}
				value={role}
				color={(obj?.color || 'green') as ColorsType}
			/>
		)
	}

	const RenderRow = useCallback(
		({ row }: { row: IBusiness }) => {
			return (
				<StyledTableRow
					key={row.name}
					style={{
						justifyContent: 'center'
					}}
				>
					<StyledTableCell
						component="th"
						scope="row"
						style={{ maxWidth: '40ch' }}
					>
						<FlexRow align="center" style={{ width: '100%' }}>
							{row.profilePic ? (
								<img
									src={row.profilePic}
									alt={row.name}
									loading="lazy"
									height={30}
									width={30}
									style={{
										objectFit: 'cover',
										borderRadius: '50%'
									}}
								/>
							) : (
								<MdOutlineStore size={20} color="green" />
							)}
							<Gutter gap={1} />
							<Typography
								variant={'caption'}
								fontWeight={500}
								sx={{
									maxWidth: '90%',
									textOverflow: 'ellipsis',
									overflow: 'hidden',
									whiteSpace: 'nowrap'
								}}
							>
								{row.name}
							</Typography>
							<Gutter gap={1} />

							{selectedBusiness &&
							selectedBusiness.id === row.id ? (
								<Typography
									variant="caption"
									color="primary"
									fontWeight={500}
								>
									Currently Active
								</Typography>
							) : (
								<SwitchBusinessButton
									buttonColor="white"
									textColor="black"
									variant="contained"
									onClick={() =>
										handleSwitchBusiness(row?.id || '')
									}
								>
									Switch to this Business
								</SwitchBusinessButton>
							)}
						</FlexRow>
					</StyledTableCell>
					<StyledTableCell
						align="left"
						style={{
							maxWidth: '20ch'
						}}
					>
						{row.businessType}
					</StyledTableCell>
					<StyledTableCell align="left">
						<FlexRow>
							{row.supplyChainRole.map(d => (
								<Role key={d} role={d} />
							))}
						</FlexRow>
					</StyledTableCell>
					<StyledTableCell align="left">
						{moment(row.updatedAt).format(dateFormat)}
					</StyledTableCell>
					<StyledTableCell align="left">
						{currentRole === 'owner' && (
							<Button
								onClick={() => handleModalOpen(row.id)}
								startIcon={<MdOutlineRemoveRedEye />}
								textColor="green"
								sx={{
									backgroundColor: theme =>
										theme.palette.colors.green['50'],
									width: '10ch',
									height: '4ch'
								}}
							>
								{translate(
									'profile.BusinessManagement.viewBusiness'
								)}
							</Button>
						)}
					</StyledTableCell>
				</StyledTableRow>
			)
		},
		[
			currentRole,
			handleModalOpen,
			handleSwitchBusiness,
			selectedBusiness,
			translate
		]
	)

	return (
		<>
			<Wrapper>
				<TableContainer sx={{ minWidth: 700 }}>
					<Table sx={{ minWidth: 700 }} aria-label="customized table">
						<TableHead>
							<TableRow>
								<StyledTableCell>
									Your Businesses
								</StyledTableCell>
								<StyledTableCell align="left">
									Business Type
								</StyledTableCell>
								<StyledTableCell align="left">
									Role
								</StyledTableCell>
								<StyledTableCell align="left">
									Last Edited
								</StyledTableCell>
								<StyledTableCell align="left" />
							</TableRow>
						</TableHead>
						<TableBody>
							{businesses
								.slice(
									page * rowsPerPage,
									page * rowsPerPage + rowsPerPage
								)
								.map(row => (
									<RenderRow key={row.id} row={row} />
								))}
						</TableBody>
					</Table>
				</TableContainer>
			</Wrapper>
			<Footer>
				<Pagination
					page={page + 1}
					count={paginationCount === Infinity ? 1 : paginationCount}
					variant="outlined"
					shape="rounded"
					onChange={(event, page) => onConfigChange('page', page - 1)}
				/>
			</Footer>
		</>
	)
}
