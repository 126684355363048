import React, { useCallback } from 'react'
import {
	ClickAwayListener,
	Fade,
	Popper,
	TableCell,
	Typography,
	useTheme
} from '@mui/material'
import { styled } from '@mui/system'
import type { IContactProps } from 'typings'
import { FlexCol, FlexRow } from 'components/atoms/Flex'
import { convertNumber } from 'helpers'
import { Capsule as SCapsule, FormatAmount, Gutter } from 'components'
import analytics from 'utils/segment'
import { SEGMENT_EVENTS } from 'data'
import { useNavigate } from 'react-router-dom'
import PopupState, { bindPopper, bindToggle } from 'material-ui-popup-state'
import type { KababMenuItemProps } from 'components/atoms/KababMenu'
import {
	KeyboardArrowDownOutlined,
	KeyboardArrowUpOutlined
} from '@mui/icons-material'

const Cell = styled(TableCell, {
	shouldForwardProp: props => props !== 'isVoid'
})<{ isVoid?: boolean }>`
	color: ${({ theme }) => theme.palette.colors.gray['500']};
	padding: 7px 7px 7px 24px;
`

const Capsule = styled(FlexRow)`
	background-color: ${({ theme }) => theme.palette.colors['gray']['50']};
	padding: 0 8px;
	border-radius: 25px;
	margin: 5px;
	color: ${({ theme }) => theme.palette.colors['gray']['700']};
	align-items: center;
	justify-content: center;
	white-space: nowrap;
	font-size: 12px;

	:hover {
		cursor: pointer;
	}
`

const Wrapper = styled(FlexCol)`
	background-color: ${({ theme }) => theme.palette.background.default};
	border: 1px solid ${({ theme }) => theme.palette.background.paper};
	border-radius: 8px;
	margin-top: 5px;
	box-shadow: 0 12px 16px -4px rgba(16, 24, 40, 0.08),
		0px 4px 6px -2px rgba(16, 24, 40, 0.03);
`

const WrapperCapsule = styled(SCapsule)`
	width: min-content;
	flex-wrap: nowrap;
	padding: 7px;
	background-color: ${({ theme }) => theme.palette.background.default};
	border: 1px solid ${({ theme }) => theme.palette.colors.gray['300']};
	border-radius: 9px;
`

const Col = styled(FlexCol)<{ isSelected?: boolean; disabled?: boolean }>`
	min-height: 50px;
	padding: 0 15px;
	width: 100%;
	background-color: ${({ theme, isSelected, disabled }) =>
		disabled
			? theme.palette.background.paper
			: isSelected
			? theme.palette.colors.blue['100']
			: theme.palette.background.default};

	:hover {
		cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
		background-color: ${({ theme }) => theme.palette.colors.blue['50']};
	}
`

const ArrowDownIcon = styled(KeyboardArrowDownOutlined)`
	font-size: 22px;
`

const ArrowUpIcon = styled(KeyboardArrowUpOutlined)`
	font-size: 22px;
`

const Row = styled(FlexRow)`
	align-items: center;
	flex-wrap: nowrap;
	justify-content: end;
`

export const HomeItem: React.ComponentType<{
	row: IContactProps
	setSelectedContact?(contact: IContactProps): void
	onSinglePaymentClick?(contact: IContactProps): void
}> = ({ row, setSelectedContact, onSinglePaymentClick }) => {
	const navigate = useNavigate()

	const theme = useTheme()
	const {
		palette: { colors }
	} = theme
	const balance =
		row?.balances?.filter(f => f.accountName === 'accounts_receivables')[0]
			?.balance || 0

	const Item = useCallback(
		({
			name,
			value,
			onSelect,
			onClick,
			disabled,
			color,
			icon,
			onClose,
			iconColor,
			...props
		}: Omit<KababMenuItemProps, 'id'> & { onClose: () => void }) => {
			const Icon = icon

			return (
				<Col
					justify={'center'}
					style={{ minWidth: 220 }}
					disabled={disabled}
					onClick={() => {
						if (!disabled) {
							onSelect?.(name, value)
							onClose()
							onClick?.()
						}
					}}
					{...props}
				>
					<FlexRow align={'center'}>
						{icon && (
							<FlexRow>
								<Icon
									size={20}
									color={
										iconColor
											? iconColor
											: colors['black']['700']
									}
								/>
								<Gutter gap={0.5} />
							</FlexRow>
						)}
						<Typography variant={'subtitle2'} color={color}>
							{name}
						</Typography>
					</FlexRow>
				</Col>
			)
		},
		[colors]
	)

	return (
		<>
			<Cell variant="head" align={'left'}>
				<FlexCol>
					<FlexRow align={'center'}>
						<Typography
							variant={'subtitle2'}
							color={colors.gray['900']}
						>
							{row.name}
						</Typography>
						{row.businessRelationship.map(d => (
							<Capsule key={d}>{d}</Capsule>
						))}
					</FlexRow>
					<Typography variant={'caption'} color={colors.gray['700']}>
						{row.phone.number
							? `+${row.phone.countryCode}-${row.phone.number}`
							: ''}
					</Typography>
				</FlexCol>
			</Cell>
			<Cell variant="head" align={'right'}>
				<FormatAmount
					limit={15}
					amount={convertNumber(parseFloat(`${balance}`))}
					prefixCurrency={row.balance.currency}
				/>
			</Cell>
			<Cell variant="head" align={'right'}>
				<Row>
					<PopupState
						variant="popper"
						popupId="demo-popup-popper"
						disableAutoFocus
					>
						{popupState => (
							<ClickAwayListener
								onClickAway={() => popupState.close()}
							>
								<div>
									<WrapperCapsule
										name="Record Payment"
										value="Record Payment"
										color="gray"
										renderRight={
											popupState.isOpen ? (
												<ArrowUpIcon />
											) : (
												<ArrowDownIcon />
											)
										}
										isSelected
										{...bindToggle(popupState)}
									/>
									<Popper
										{...bindPopper(popupState)}
										transition
										placement={'bottom-end'}
									>
										{({ TransitionProps }) => (
											<Fade
												{...TransitionProps}
												timeout={350}
											>
												<Wrapper>
													<Item
														key={'Record Payment'}
														onClose={
															popupState.close
														}
														name={
															'Record Single Payment'
														}
														value={'single payment'}
														onClick={() =>
															onSinglePaymentClick?.(
																row
															)
														}
													/>
													<Item
														key={
															'Record Payment in Bulk'
														}
														onClose={
															popupState.close
														}
														name={
															'Record Payments in Bulk'
														}
														value={'do something'}
														onClick={() =>
															setSelectedContact?.(
																row
															)
														}
													/>
												</Wrapper>
											</Fade>
										)}
									</Popper>
								</div>
							</ClickAwayListener>
						)}
					</PopupState>
					<Gutter gap={1} />
					<WrapperCapsule
						name="Ledger"
						value="Ledger"
						color="gray"
						isSelected
						onItemClick={() => {
							navigate(`/contacts/ledger/${row.id}`, {
								state: { currentUser: row }
							})
							analytics.track(SEGMENT_EVENTS.CONTACT_VIEW_DETAILS)
						}}
					/>
				</Row>
			</Cell>
		</>
	)
}
