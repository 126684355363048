import React, { useState } from 'react'
import { FlexRow } from 'components/atoms/Flex'
import { Button, styled, Typography, useMediaQuery } from '@mui/material'
import {
	INVOICE_FILTER_OPTIONS,
	LAYOUT_BREAKPOINT_WIDTH,
	SEGMENT_EVENTS
} from 'data'
import { type FilterObjProps, type InvoiceType } from 'typings'
import { InvoiceFilter } from './Components/InvoiceFilter'
import { objectOmit } from 'helpers'
import { DateFilter, Gutter, SearchBar } from 'components'
import { motion } from 'framer-motion'
import { CreateInvoicesButton } from './Components/CreateButton'
import { AiOutlinePrinter } from 'react-icons/ai'
import { RxCross2 } from 'react-icons/rx'
import analytics from 'utils/segment'
import { Search } from '@mui/icons-material'
import { FaRegCheckCircle } from 'react-icons/fa'

const CrossIconWrapper = styled(RxCross2)`
	border: 0.6px solid gray;
	border-radius: 5px;
	padding: 8px;
`

const ButtonWrapper = styled(Button)`
	color: ${({ theme }) => theme.palette.colors.gray['900']};
	background-color: ${({ theme }) => theme.palette.colors.yellow['300']};
	padding: 10px 20px;
	:disabled {
		background-color: ${({ theme }) => theme.palette.colors.gray['100']};
		color: ${({ theme }) => theme.palette.colors.gray['400']};
	}
	:hover {
		background-color: ${({ theme }) => theme.palette.colors.yellow['200']};
	}
`

const StyledButton = styled(Button)`
	background-color: ${({ theme }) => theme.palette.background.default};
	border: 1px solid ${({ theme }) => theme.palette.colors.gray[300]};
	align-items: center;
	min-width: 0;
	min-height: 32px;
	border-radius: 5px;
	padding: 0 9px;
`

const Wrapper = styled(FlexRow)`
	width: 100%;
	flex-wrap: nowrap;
	align-items: center;
	justify-content: space-between;
	padding: 5px 16px 4px 16px;
	border-bottom: 1px solid ${({ theme }) => theme.palette.colors.gray[200]};
`

const StyledSearchBar = styled(SearchBar)`
	width: 100%;
	.MuiOutlinedInput-root {
		border-radius: 5px;
		padding-right: 6px;
	}
`

const SearchIcon = styled(Search)`
	font-size: 20px;
	color: ${({ theme }) => theme.palette.colors.gray['700']};
`

type HeaderProps<T> = {
	dataSource: InvoiceType[]
	filterObj: T
	setFilterObj: React.Dispatch<React.SetStateAction<T>>
	bulkPrint: boolean
	setBulkPrint: React.Dispatch<React.SetStateAction<boolean>>
	selectedRows: InvoiceType[]
	setSelectedRows: React.Dispatch<React.SetStateAction<InvoiceType[]>>
	setBulkPrintModalOpen: React.Dispatch<React.SetStateAction<boolean>>
	onSearch: (text: string) => void
	onFilterSelect: (filterObj: { [key: string]: string[] }) => void
	bulkPublish: boolean
	setBulkPublish: React.Dispatch<React.SetStateAction<boolean>>
	setOpenPublishModal: React.Dispatch<React.SetStateAction<boolean>>
}

export const Header: React.FC<HeaderProps<FilterObjProps>> = ({
	dataSource,
	filterObj,
	setFilterObj,
	bulkPrint,
	setBulkPrint,
	selectedRows,
	setBulkPrintModalOpen,
	onSearch,
	onFilterSelect,
	bulkPublish,
	setBulkPublish,
	setSelectedRows,
	setOpenPublishModal
}) => {
	const [isActive, setIsActive] = useState<boolean>(false)

	const isMobile = useMediaQuery(`(max-width:${LAYOUT_BREAKPOINT_WIDTH}px)`)

	const dateFilter = {
		startDate: filterObj?.startDate,
		endDate: filterObj?.endDate
	}

	const onDateFilterSelect = (_filterObj: { [key: string]: string }) => {
		const startDate = _filterObj?.startDate
		const endDate = _filterObj?.endDate
		const obj = {
			...filterObj,
			startDate,
			endDate
		}
		setFilterObj(obj)
	}

	const MotionAnimation = {
		opacity: 1,
		transition: {
			duration: 0.5
		}
	}

	const SearchAnimation = {
		width: isMobile ? '100%' : '30ch',
		transition: {
			duration: 0.2,
			type: 'spring',
			damping: 14
		}
	}

	const searchButtonClicked = () => {
		setIsActive(true)
	}

	return (
		<Wrapper>
			<motion.div
				initial={{ opacity: 0 }}
				animate={MotionAnimation}
				exit={{ opacity: 0 }}
				hidden={isMobile && isActive}
			>
				<InvoiceFilter
					filterObj={objectOmit(filterObj, 'startDate', 'endDate')}
					onSelect={onFilterSelect}
					dataSource={INVOICE_FILTER_OPTIONS}
					numOfInvoices={dataSource.length}
				/>
			</motion.div>

			<FlexRow
				align={'center'}
				sx={{ width: isMobile && isActive ? '100%' : null }}
			>
				{isMobile && !isActive ? (
					<StyledButton onClick={searchButtonClicked}>
						<SearchIcon />
					</StyledButton>
				) : (
					<motion.div animate={SearchAnimation}>
						<StyledSearchBar
							onSearch={onSearch}
							onFocus={() => setIsActive(true)}
							autoFocus={isMobile}
							onBlur={(text: string) => {
								setIsActive(text !== '')
								analytics.track(SEGMENT_EVENTS.INVOICES_SEARCH)
							}}
							placeholder="Search using Contact Name"
							inputProps={{
								style: {
									padding: '4.5px 0 4.5px 14px'
								}
							}}
						/>
					</motion.div>
				)}

				<motion.div
					initial={{ opacity: 0 }}
					animate={MotionAnimation}
					exit={{ opacity: 0 }}
					hidden={isMobile && isActive}
				>
					<FlexRow align="center">
						<Gutter gap={isMobile ? 0.5 : 1} />
						<DateFilter
							filterObj={
								filterObj.startDate || filterObj.endDate
									? dateFilter
									: {}
							}
							onSelect={onDateFilterSelect}
						/>
						<Gutter gap={isMobile ? 0.5 : 1} />

						{(!bulkPrint || isMobile) &&
							(!bulkPublish || isMobile) && (
								<CreateInvoicesButton />
							)}
						{bulkPrint && !isMobile && (
							<FlexRow align="center">
								<ButtonWrapper
									disabled={selectedRows.length < 1}
									onClick={() => setBulkPrintModalOpen(true)}
								>
									<AiOutlinePrinter
										size={27}
										style={{
											paddingRight: '5px'
										}}
									/>
									<Typography
										variant="body2"
										fontWeight={'500'}
									>
										{selectedRows.length > 0
											? `Bulk Print (${selectedRows.length} selected)`
											: 'Bulk Print'}
									</Typography>
								</ButtonWrapper>
								<Gutter gap={1} />
								<CrossIconWrapper
									size={38}
									style={{ cursor: 'pointer' }}
									onClick={() => {
										setBulkPrint(false)
										setBulkPublish(false)
										setSelectedRows([])
										onFilterSelect({ status: [] })
									}}
								/>
							</FlexRow>
						)}
						{bulkPublish && (
							<FlexRow align="center">
								<ButtonWrapper
									disabled={selectedRows.length < 1}
									onClick={() => {
										analytics.track(
											SEGMENT_EVENTS.INVOICE_CLICK_BULK_PUBLISH
										)
										setOpenPublishModal(true)
									}}
								>
									<FaRegCheckCircle
										size={22}
										style={{
											paddingRight: '5px'
										}}
									/>
									<Typography
										variant="body2"
										fontWeight={'500'}
									>
										{selectedRows.length > 0
											? `Bulk Publish (${selectedRows.length} selected)`
											: 'Bulk Publish'}
									</Typography>
								</ButtonWrapper>
								<Gutter gap={1} />
								<CrossIconWrapper
									size={38}
									style={{ cursor: 'pointer' }}
									onClick={() => {
										bulkPublish &&
											analytics.track(
												SEGMENT_EVENTS.INVOICE_CLICK_EXIT_BULK_PUBLISH_MODE
											)
										setBulkPrint(false)
										setBulkPublish(false)
										setSelectedRows([])
										onFilterSelect({ status: [] })
									}}
								/>
							</FlexRow>
						)}
					</FlexRow>
				</motion.div>
			</FlexRow>
		</Wrapper>
	)
}
