import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { styled } from '@mui/system'
import { FlexCol, FlexRow } from 'components/atoms/Flex'
import { Button, FieldRadioGroup, FormatAmount, Gutter } from 'components'
import { ErrorMessage, Formik } from 'formik'
import { Typography, useTheme } from '@mui/material'
import { useAppErrors } from 'hooks'
import analytics from 'utils/segment'
import {
	CREATE_INVOICE_INITIAL,
	PaymentMethodOptions,
	SEGMENT_EVENTS
} from 'data'
import { TextInputWrapper } from 'screen/OrderCenter/NewOrder/NewOrderModal'
import type {
	ColorsCodeType,
	IBookerProps,
	IBusiness,
	InvoiceType,
	IOrderFormSettings
} from 'typings'
import * as Yup from 'yup'
import {
	getBookerInfo,
	getBusinessInfo,
	getOrderFormSettingsInfo,
	processOrderBookerForm
} from 'api'
import { ContactsDropdown } from 'screen/OrderBooker/Components/ContactsDropdown'
import {
	ORDER_BOOKER_FORM_INITIAL_VALUES,
	ORDER_BOOKER_INITIAL_DATA
} from 'data/orderbooker'
import { OrderBookerFormSubmittedModal } from './OrderBookerFormSubmittedModal'
import moment from 'moment'
import {
	convertNumber,
	objectFindKey,
	objectRemoveEmptyDeeply,
	truncate
} from 'helpers'
import { OrderBookerFormTable } from '../Components'
import { calculateDiscount } from 'helpers/mixed'

const Wrapper = styled(FlexCol)`
	width: 100%;
	height: 100%;
	min-height: 100vh;
	padding: 40px;
	align-items: center;
`

const Box = styled(FlexCol)`
	width: 100%;
	height: 100%;
	max-width: 1200px;
`

const AmountBox = styled('div')`
	padding: 15px 10px;
	width: 100%;
	background-color: ${({ theme }) => theme.palette.colors.gray['50']};
`
const SpaceBetween = styled(FlexRow)`
	width: 100%;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;

	@media (max-width: 600px) {
		flex-direction: column-reverse;
		justify-content: center;
		align-items: center;
	}
`
const Label = styled(Typography)`
	color: ${({ theme }) => theme.palette.colors.gray['900']};
	font-weight: 500;
`
const RowBetween = styled(FlexRow)`
	width: 100%;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
`
const Grid = styled('div')`
	width: 100%;
	display: grid;
	grid-template-columns: 3fr 1.5fr;
	column-gap: 20px;

	@media (max-width: 600px) {
		grid-template-columns: 1fr;
		row-gap: 20px;
	}
`
const Item = styled(FlexRow)`
	align-items: center;
	justify-content: space-between;
	margin-top: 15px;
`
const Divider = styled(FlexRow)`
	border-bottom: 1px solid
		${({ theme }) => theme.palette.colors['gray']['300']};
	width: 100%;
`

const FieldRadioGroupWrapper = styled(FieldRadioGroup)(({ theme }) => ({
	'& .MuiSvgIcon-root': {
		fontSize: 12
	},
	'& .MuiFormControlLabel-label': {
		fontSize: 12
	},
	color: theme.palette.colors.gray['500'],
	fontWeight: 'bold'
}))

const returnNumber = (num: string | number) => {
	if (typeof num === 'string') {
		num = parseInt(num, 10)
	}
	return num
}

const validationSchema = Yup.object().shape({
	contact: Yup.object().shape({
		phone: Yup.object().shape({
			number: Yup.string()
				.required('Contact required')
				.min(4, 'Phone number should be 4 digits long.')
		})
	}),
	items: Yup.array()
		.of(
			Yup.object().shape({
				perItemSellPrice: Yup.number()
					.positive('Error')
					.nullable()
					.typeError('Required'),
				quantity: Yup.number()
					.transform(v => (v === '' || Number.isNaN(v) ? null : v))
					.test('maxQuantity', (quantity, values) => {
						const { parent, options } = values

						const sameStockItems = options.context?.items.filter(
							(item: any) => item.stockId === parent.stockId
						)
						const indexInSameItems = sameStockItems.findIndex(
							(item: any) => item.id === parent.id
						)
						const totalUsed = sameStockItems.reduce(
							(acc: number, row: any, index: number) => {
								if (index <= indexInSameItems) {
									return (
										acc + (returnNumber(row.quantity) || 0)
									)
								}
								return acc
							},
							0
						)
						let maxQuantity = parent?.maxQuantity

						if ((totalUsed || 0) > maxQuantity) {
							if (maxQuantity < 0) {
								maxQuantity = 0
							}
							const allowedQuantity =
								maxQuantity - (totalUsed - (quantity || 0))
							return values.createError({
								path: values.path,
								message: `Qty should be less than ${
									allowedQuantity < 0
										? 0
										: allowedQuantity + 1
								}`
							})
						}
						return true
					})
					.integer()
					.min(1, 'Qty should be atleast 1')
					.required('Required')
					.positive('Qty should be atleast 1')
					.typeError('Required'),
				description: Yup.mixed().test(
					'description',
					(description, values) => {
						const { from } = values.options as any
						if (
							from &&
							objectFindKey(from[0].value, 'description')
						) {
							if (description === undefined) {
								return values.createError({
									path: values.path,
									message: 'Description is required'
								})
							}

							if (description === '') {
								return values.createError({
									path: values.path,
									message: 'Description is required'
								})
							}

							if (description.length > 500) {
								return values.createError({
									path: values.path,
									message:
										'Description should be less than 500 characters'
								})
							}
						}
						return true
					}
				)
			})
		)
		.min(1, 'min 1 item is required')
		.required('Required')
})

export const OrderBookerForm = () => {
	const displayStockQuantity = true
	const [loading, setLoading] = useState(true)
	const [orderBooker, setOrderBooker] = useState<IBookerProps>()
	const [business, setBusiness] = useState<IBusiness>()
	const [orderFormSettings, setOrderFormSettings] =
		useState<IOrderFormSettings>()
	const [open, setOpen] = React.useState(false)
	const { id } = useParams()
	const { setAppError } = useAppErrors()
	const theme = useTheme()
	const { colors } = theme.palette

	const getBooker = useCallback(async () => {
		try {
			const booker: IBookerProps = await getBookerInfo(id as string)
			const business = await getBusinessInfo(booker.businessId as string)
			const orderFormSettings = await getOrderFormSettingsInfo(
				booker.businessId as string
			)
			setOrderFormSettings(orderFormSettings)
			setOrderBooker(booker)
			setBusiness(business)
		} catch (e) {
			alert('Invalid link')
		} finally {
			setLoading(false)
		}
	}, [id])

	const displayItemLevelDiscount = React.useMemo(() => {
		if (orderFormSettings && orderFormSettings.settings) {
			return (
				orderFormSettings?.settings?.displayItemLevelDiscountOB || false
			)
		}
		return false
	}, [orderFormSettings])

	const calculateTotalAmount = (values: any) => {
		let total = 0
		let discountedTotal = 0

		if (!values || !values.items || values.items.length === 0) {
			return { total: 0, discountedTotal: 0 }
		}

		values.items.forEach((item: any) => {
			const sellPrice = isNaN(parseFloat(`${item.perItemSellPrice}`))
				? 0
				: parseFloat(`${item.perItemSellPrice}`)
			const quantity = isNaN(parseInt(`${item.quantity}`))
				? 0
				: parseInt(`${item.quantity}`)

			total += sellPrice * quantity

			discountedTotal += calculateDiscount(
				convertNumber(quantity || 0) * convertNumber(sellPrice || 0),
				item.discount?.amount || 0,
				item.discount?.type || 'percent'
			)
		})

		// Fixing to two decimal places
		total = parseFloat(total.toFixed(2))
		discountedTotal = parseFloat(discountedTotal.toFixed(2))

		return { total, discountedTotal }
	}

	const onSubmit = async (values: any, action: any) => {
		try {
			action.setSubmitting(true)
			const invoice: InvoiceType = {
				...CREATE_INVOICE_INITIAL,
				initiationAt: moment.utc(moment()).format(),
				taxes: [],
				businessInfo: {
					...business,
					businessName: business?.name
				},
				isPaymentTerms: false,
				paymentTermsSelected: '',
				extraCharges: [],
				percentageDiscount: 'percentage',
				...values,
				business: business?.id ?? '',
				user: business?.user
			}
			const data = objectRemoveEmptyDeeply(invoice)

			await processOrderBookerForm(data)
			setOpen(true)
		} catch (e: any) {
			setAppError(e, action)
		} finally {
			action.setSubmitting(false)
		}
	}

	useEffect(() => {
		if (loading) {
			getBooker()
		}
	}, [getBooker, loading])

	return (
		<>
			<Wrapper>
				<Box>
					{business && (
						<Formik
							initialValues={{
								...ORDER_BOOKER_FORM_INITIAL_VALUES,
								business,
								orderBooker
							}}
							onSubmit={onSubmit}
							validationSchema={validationSchema}
						>
							{({
								values,
								handleSubmit,
								dirty,
								isSubmitting
							}) => (
								<>
									<RowBetween>
										<Typography
											variant={'h4'}
											fontWeight={'bold'}
											style={{ width: '70%' }}
										>
											{business?.name}
										</Typography>
										<FlexCol
											align="flex-end"
											style={{ width: '25%' }}
										>
											<Typography
												variant="caption"
												style={{ fontSize: '9px' }}
												color={colors.gray['500']}
											>
												Order Booker
											</Typography>
											<Typography
												variant="caption"
												fontWeight={'500'}
												style={{ textAlign: 'end' }}
											>
												{truncate(
													orderBooker?.name || '',
													30
												)}
											</Typography>
										</FlexCol>
									</RowBetween>
									<Typography variant={'caption'}>
										Complete the Order Form to place order
									</Typography>

									<Gutter spacing={1.5} />

									<Typography
										variant="subtitle2"
										fontWeight={'500'}
										fontSize={13}
									>
										Customer
									</Typography>
									<Gutter spacing={0.1} />
									<ContactsDropdown
										name="contact"
										formId={id as string}
										orderBooker={
											orderBooker ||
											ORDER_BOOKER_INITIAL_DATA
										}
									/>
									<Typography color={colors.red['500']}>
										<ErrorMessage name="contact.phone.number" />
									</Typography>
									<Gutter spacing={2} />
									<OrderBookerFormTable
										OrderFormSettings={{
											settings: {
												displayStockQuantity,
												displaySellingPrice: false,
												displayItemLevelDiscountOB:
													displayItemLevelDiscount
											}
										}}
										name={'items'}
										businessId={business.id as string}
									/>
									<Gutter spacing={1} />
									<Grid>
										<FlexCol>
											<Typography
												variant="body2"
												fontWeight={'500'}
												color={colors.gray['700']}
											>
												Payment Method
											</Typography>
											<Gutter spacing={0.1} />
											<FieldRadioGroupWrapper
												row
												name="paymentMethod"
												options={PaymentMethodOptions}
											/>
											<Gutter spacing={1} />

											<Typography
												variant="caption"
												fontWeight={'500'}
											>
												Comments
											</Typography>
											<Gutter spacing={0.1} />
											<TextInputWrapper
												multiline
												rows={4}
												name="comments"
												placeholder="Enter comments to show on invoice"
												size="small"
												onClick={() => {
													analytics.track(
														SEGMENT_EVENTS.ORDER_COMMENT
													)
												}}
												style={{ width: '280px' }}
											/>
										</FlexCol>
										<FlexCol>
											<AmountBox>
												<Item>
													<Label variant="body2">
														Total Items
													</Label>
													<Typography
														variant="body2"
														fontWeight={'500'}
														color={
															colors.gray['900']
														}
													>
														{values.items.length}
													</Typography>
												</Item>
												<Gutter spacing={1} />
												{calculateTotalAmount(values)
													.total -
													calculateTotalAmount(values)
														.discountedTotal >
													0 && (
													<>
														<Divider />
														<Item>
															<Label variant="body2">
																Sub Total
															</Label>
															<Typography
																variant="body2"
																fontWeight={
																	'500'
																}
																color={
																	colors.gray[
																		'900'
																	]
																}
															>
																{
																	calculateTotalAmount(
																		values
																	).total
																}
															</Typography>
														</Item>
														<Item>
															<Label variant="body2">
																Item Discount
															</Label>
															<Typography
																variant="body2"
																fontWeight={
																	'500'
																}
																color={
																	colors.red[
																		'500'
																	]
																}
															>
																(-)
																{parseFloat(
																	`${
																		calculateTotalAmount(
																			values
																		)
																			.total -
																		calculateTotalAmount(
																			values
																		)
																			.discountedTotal
																	}`
																).toFixed(2)}
															</Typography>
														</Item>
													</>
												)}
												<Gutter spacing={1} />
												<Divider />
												<Item>
													<Typography
														variant="h6"
														fontWeight={'bold'}
														style={{ fontSize: 20 }}
													>
														Total Amount (PKR)
													</Typography>
													<Typography
														variant="h5"
														fontWeight={'600'}
														color={
															colors.gray['900']
														}
													>
														<FormatAmount
															variant={'h6'}
															limit={10}
															amount={
																calculateTotalAmount(
																	values
																)
																	.discountedTotal
															}
															style={{
																color: 'black',
																fontWeight: 600,
																fontSize: 20
															}}
														/>
													</Typography>
												</Item>
											</AmountBox>
											<Gutter spacing={1.5} />
											<SpaceBetween>
												<Button
													buttonColor={
														theme.palette.colors
															.green[
															'700'
														] as ColorsCodeType
													}
													textColor="white"
													onClick={() =>
														handleSubmit()
													}
													style={{ width: '100%' }}
													disabled={
														isSubmitting || !dirty
													}
												>
													Submit
												</Button>
											</SpaceBetween>
										</FlexCol>
									</Grid>
									<Gutter spacing={1} />
								</>
							)}
						</Formik>
					)}
				</Box>
				<OrderBookerFormSubmittedModal
					businessName={business?.name}
					open={open}
					onClose={() => setOpen(false)}
				/>
			</Wrapper>
		</>
	)
}
