import { call, fork, put, take } from 'redux-saga/effects'
import { FETCH_CONTACT_LIST } from './types'
import {
	setContactList,
	setContactLoading,
	setContactPageInfo
} from './actions'
import { LogError } from 'utils/log'
import type {
	ContactListPayloadType,
	DataResponseType,
	IContactProps
} from 'typings'
import { getContactList } from 'api/contacts'

function* fetchContactList(payload: ContactListPayloadType) {
	try {
		yield put(setContactLoading(true))
		const res: DataResponseType<IContactProps> = yield call(
			getContactList,
			payload
		)
		yield put(setContactList(res.edges))
		yield put(setContactPageInfo(res.pageInfo))
	} catch (e: any) {
		LogError(e)
	} finally {
		yield put(setContactLoading(false))
	}
}

function* contactWatcher(): any {
	while (true) {
		const everything = yield take([FETCH_CONTACT_LIST])
		if (!everything) {
			return
		}

		const { type } = everything

		switch (type) {
			case FETCH_CONTACT_LIST:
				yield fork(fetchContactList, everything.payload)
				break
		}
	}
}

export default [contactWatcher()]
