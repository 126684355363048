import {
	FETCH_ALL_LABEL,
	type InventoryActionsTypes,
	SET_ALL_LABEL
} from './types'

export const fetchAllLabel = (): InventoryActionsTypes => ({
	type: FETCH_ALL_LABEL
})

export const setAllLabel = (payload: string[]): InventoryActionsTypes => ({
	type: SET_ALL_LABEL,
	payload
})
