import {
	Paper,
	styled,
	Table,
	TableBody,
	TableContainer,
	Typography
} from '@mui/material'
import { FlexCol, FlexRow } from 'components/atoms/Flex'
import {
	AlertModal,
	FilterButton,
	type FilterObjProps,
	Gutter,
	SearchBar
} from 'components'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { TableHeadings } from 'screen/Quotations/Tables/TableHeadings'
import type { QuotationType, ResponsePageInfo } from 'typings'
import { QuotationItem } from 'screen/Quotations/Tables/QuotationItem'
import { deleteQuotation, getQuotationList } from 'api/quotation'
import { useBottomScrollListener } from 'react-bottom-scroll-listener'
import { useSettings } from 'context/settings'
import { useAppErrors } from 'hooks/useAppErrors'
import { useDimension } from 'hooks'
import { LAYOUT_PADDING, QUOTATION_FILTER_OBJ, SCROLLBAR_STYLE } from 'data'
import { useSnackbar } from 'notistack'
import { IoIosArrowBack } from 'react-icons/io'
import NoQuotation from 'assets/quotations/Recyclebin_Quotations.png'
import NoSearchQuotation from 'assets/quotations/SearchQuotation.png'

export const TitleBar = styled(FlexRow)`
	height: 40px;
	align-items: center;
`

export const Icon = styled(IoIosArrowBack)`
	color: ${({ theme }) => theme.palette.primary.contrastText};

	:hover {
		cursor: pointer;
	}
`

const StyledPaper = styled(Paper)`
	width: 100%;
	height: 100%;
	overflow: hidden;
	position: relative;
	z-index: 0;
	background-color: ${({ theme }) => theme.palette.background.default};
`

const Wrapper = styled('div')<{ height: number }>`
	border: 1px solid ${({ theme }) => theme.palette.background.paper};
	border-radius: 7px;
	overflow: auto;
	height: 90%;
	margin-top: 8px;
	width: 100%;
	max-height: ${({ height }) => `${height}px`};
	min-height: ${({ height }) => `${height}px`};

	${SCROLLBAR_STYLE}
`

const Container = styled(FlexCol)<{ height: number }>`
	width: 100%;
	height: 100%;
	overflow: scroll;
	max-height: ${({ height }) => `${height}px`};
	min-height: ${({ height }) => `${height}px`};

	${SCROLLBAR_STYLE}
`

const StyledTable = styled(Table)`
	min-width: 700px;
	background-color: ${({ theme }) => theme.palette.background.default};
`

const Image = styled('img')`
	min-width: 100px;
	max-width: 300px;
`

const Center = styled(FlexCol)`
	width: 100%;
	height: 100%;
	position: absolute;
	align-self: center;
	align-items: center;
	justify-content: center;
`

export const RecycleBinTable = () => {
	const [open, setOpen] = useState<boolean>(false)
	const [rowId, setRowId] = useState<string>('')
	const [filterObj, setFilterObj] = useState<FilterObjProps>({})
	const [dataSource, setDataSource] = useState<QuotationType[]>([])
	const [pageInfo, setPageInfo] = useState<ResponsePageInfo>({
		currentPage: 1,
		edgesPerPage: 10,
		totalPages: 1
	})
	const [tHeight, setTHeight] = useState<number>()
	const [loading, setLoading] = useState<boolean>(true)
	const [searchText, setSearchText] = useState<string>('')
	const [isActive, setIsActive] = useState<boolean>(false)
	const navigate = useNavigate()
	const scrollRef = useBottomScrollListener(() => getDataSource(), {
		offset: 10,
		debounce: 200
	})
	const ref = useRef<HTMLDivElement>(null)
	const { business } = useSettings()
	const { setAppError } = useAppErrors()
	const { height } = useDimension()
	const { enqueueSnackbar } = useSnackbar()

	const getDataSource = async () => {
		try {
			setLoading(true)
			if (
				!loading &&
				pageInfo?.totalPages &&
				pageInfo?.totalPages >= pageInfo.currentPage + 1
			) {
				const res = await getQuotationList(business, {
					page: pageInfo.currentPage + 1,
					limit: 20,
					deleted: true,
					searchKey: searchText,
					...filterObj
				})
				setDataSource([...dataSource, ...res.edges])
				setPageInfo(res.pageInfo)
			}
		} catch (e: any) {
			setAppError(e)
		} finally {
			setLoading(false)
		}
	}

	const getInitialDataSource = useCallback(async () => {
		try {
			setLoading(true)
			const res = await getQuotationList(business, {
				page: 1,
				limit: 20,
				deleted: true,
				searchKey: searchText,
				...filterObj
			})
			setDataSource(res.edges)
			setPageInfo(res.pageInfo)
		} catch (e: any) {
			setAppError(e)
		} finally {
			setLoading(false)
		}
	}, [business, filterObj, searchText, setAppError])

	const RenderRow = useCallback(
		({ row }: { row: QuotationType }) => {
			return (
				<QuotationItem
					row={row}
					onSuccess={() => getInitialDataSource()}
					onDelete={id => {
						setOpen(true)
						setRowId(id)
					}}
				/>
			)
		},
		[getInitialDataSource]
	)

	const onDelete = useCallback(
		async (id: string) => {
			try {
				await deleteQuotation(business, { quotationId: [id] })
				enqueueSnackbar('Successfully Deleted', {
					variant: 'success'
				})
				getInitialDataSource()
			} catch (e: any) {
				setAppError(e)
			} finally {
				setOpen(false)
			}
		},
		[business, enqueueSnackbar, getInitialDataSource, setAppError]
	)

	const TableHeight = useMemo(() => {
		return height - LAYOUT_PADDING * 2 - 150
	}, [height])

	useEffect(() => {
		getInitialDataSource()
	}, [getInitialDataSource])

	useEffect(() => {
		setTimeout(() => {
			setTHeight(ref.current?.clientHeight || 0)
		}, 100)
	})

	return (
		<>
			<Container height={height - LAYOUT_PADDING * 2 - 10}>
				<TitleBar>
					<Icon onClick={() => navigate('/quotation')} />
					<Gutter gap={0.5} />
					<Typography
						color={'primary'}
						variant={'h5'}
						fontWeight={600}
					>
						Recycle Bin
					</Typography>
				</TitleBar>

				<Gutter spacing={1} />

				<FlexRow align={'center'}>
					<SearchBar
						onSearch={text => setSearchText(text)}
						onFocus={() => setIsActive(true)}
						onBlur={text => setIsActive(text !== '')}
						sx={{ width: isActive ? '55ch' : '35ch' }}
					/>
					<Gutter gap={2} />
					{!isActive && (
						<FilterButton
							filterObj={filterObj}
							dataSource={QUOTATION_FILTER_OBJ}
							onSelect={filterObj => setFilterObj(filterObj)}
						/>
					)}
					{isActive && <div />}
				</FlexRow>

				<Gutter spacing={0.8} />

				<Wrapper height={TableHeight}>
					<StyledPaper ref={ref} style={{ height: TableHeight }}>
						<TableContainer
							ref={scrollRef as any}
							style={{
								minWidth: 700,
								width: '100%',
								maxHeight: tHeight
							}}
						>
							<StyledTable stickyHeader aria-label="sticky table">
								<TableHeadings readyToShare />
								<TableBody>
									{dataSource.length < 1 && (
										<Center>
											<Image
												src={
													searchText === ''
														? NoQuotation
														: NoSearchQuotation
												}
											/>
											<Gutter spacing={0.3} />
											<Typography variant={'body2'}>
												{searchText === ''
													? 'No Quotations found!'
													: `No quotation found for "${searchText}"`}
											</Typography>
										</Center>
									)}
									{dataSource.map(row => (
										<RenderRow row={row} key={row.id} />
									))}
								</TableBody>
							</StyledTable>
						</TableContainer>
					</StyledPaper>
				</Wrapper>
			</Container>
			<AlertModal
				open={open}
				setOpen={setOpen}
				onDelete={() => onDelete(rowId)}
				deleteItemId={rowId}
				onCancel={() => setRowId('')}
				cancelItemId={''}
				title={'Permanently Delete Quotation?'}
				message={
					'Are you sure you want to permanently delete this quotation?'
				}
			/>
		</>
	)
}
