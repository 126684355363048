import type { ContactInitialStateProps } from 'screen/Contact/AddContact/CreateContactModal'
import type { FilterButtonDataSourceProps } from 'components'
import moment from 'moment'

export const PaymentMethodOptions = [
	{ value: 'cash', label: 'Cash' },
	{ value: 'card', label: 'Card' },
	{ value: 'cheque', label: 'Cheque' },
	{ value: 'online', label: 'Online' }
]

export const BusinessRelationshipOptions = [
	{ value: 'supplier', label: 'Supplier' },
	{ value: 'customer', label: 'Customer' },
	{ value: 'both', label: 'Both' }
]

export const ToPayToReceiveOptions = [
	{ value: 'toPay', label: 'To Pay' },
	{ value: 'toReceive', label: 'To Receive' }
]

export const DummyBankDetail = {
	accountNumber: '',
	bankName: '',
	title: '',
	branch: '',
	IBAN: ''
}

export type TContactTabsProps = { id: number; name: string; value: string }
export const CONTACTS_TABS: TContactTabsProps[] = [
	{
		id: 0,
		name: 'Opening Balance',
		value: 'openingBalance'
	},
	{
		id: 1,
		name: 'NTN/CNIC Information',
		value: 'cnic'
	},
	{
		id: 2,
		name: 'Banking Information',
		value: 'bankingInformation'
	},
	{
		id: 3,
		name: 'Comments',
		value: 'comments'
	}
]

export const CONTACTS_RECORD_PAYMENT_INITIAL_VALUE = {
	date: moment().format(),
	note: '',
	method: 'cash',
	type: 'received',
	expandNote: false,
	expandLinkInvoice: false,
	amount: ''
}

export const CONTACTS_INITIAL_VALUE: ContactInitialStateProps = {
	business: '',
	name: '',
	businessName: '',
	businessRelationship: ['customer'],
	phone: {
		countryInitials: 'PK',
		number: '',
		countryCode: '92'
	},
	banking: [],
	email: '',
	address: '',
	comments: '',
	profilePic: '',
	selectedBank: 0,
	selectedTab: 'openingBalance',
	balance: {
		asOf: moment.utc().format(),
		currency: 'PKR',
		to: 'toPay',
		amount: ''
	},
	cnic: ''
}

export const CONTACT_FILTER_OBJ: FilterButtonDataSourceProps[] = [
	{
		id: 0,
		title: 'Contact Type',
		mode: 'multi',
		name: 'businessRelationship',
		options: [
			{ id: 0, label: 'Supplier', value: 'supplier' },
			{ id: 1, label: 'Customer', value: 'customer' }
		],
		selectAll: { id: 143, label: 'Both', value: 'both' }
	}
]

export const TRANSACTION_MAPPER = {
	invoice_issued: 'Invoice Issued',
	invoice_voided: 'Invoice Voided',
	payment_made_against_invoice: 'Payment Recorded',
	refund_processed: 'Payment Refunded',
	invoice_voided_and_inventory_returned:
		'Invoice voided and inventory returned',
	invoice_voided_and_inventory_not_returned:
		'invoice voided and inventory not returned',
	manual_payment_made_by_customer: 'Payment Received',
	manual_payment_made_to_customer: 'Payment Sent',
	opening_balance: 'Opening Balance'
}
