import React from 'react'
import { styled } from '@mui/system'
import { FlexCol, FlexRow } from 'components/atoms/Flex'
import { Button } from 'components/atoms/Button/Button'
import { StyledModal } from 'components/StyledModal'
import { Typography, useTheme } from '@mui/material'
import Delete from 'assets/alerts/delete.png'
import { Gutter } from 'components'
import type { ColorsCodeType } from 'typings'

const StyledAlertBox = styled(FlexRow)`
	max-width: 90%;
	width: auto;
	height: auto;
	border-radius: 8px;
	padding: 20px;
	background-color: ${({ theme }) => theme.palette.background.default};
`

const StyledCancelButton = styled(Button)`
	background-color: ${({ theme }) => theme.palette.background.default};
	width: 90px;
	height: 44px;
	color: ${({ theme }) => theme.palette.colors.gray['700']};
	border: 1px solid ${({ theme }) => theme.palette.colors.gray['300']};
	border-radius: 8px;
	padding: 0;

	:hover {
		background-color: #f1f1f1;
	}
`
const StyledDeleteButton = styled(Button)`
	border-radius: 8px;
	height: 44px;
	width: fit-content;
	padding: 0 20px;
	border: 1px solid;
`

const Row = styled(FlexRow)`
	align-items: flex-start;

	@media (max-width: 600px) {
		flex-direction: column-reverse;
	}
`

type OrderFormAlertProps = {
	title: string
	message: string
	message2?: string
	open: boolean
	setOpen: (open: boolean) => void
}
export const OrderFormAlert: React.ComponentType<OrderFormAlertProps> = ({
	open,
	setOpen,
	title,
	message,
	message2
}) => {
	const theme = useTheme()

	const handleCancel = () => {
		setOpen(false)
	}
	return (
		<StyledModal
			sx={{
				display: 'flex',
				flexDirection: 'column'
			}}
			open={open}
			onClose={() => setOpen(false)}
		>
			<StyledAlertBox>
				<img
					alt={'delete'}
					src={Delete}
					style={{ width: '50px', height: '50px' }}
				/>
				<Gutter gap={1} />
				<FlexCol style={{ width: '400px' }}>
					<Gutter spacing={0.25} />
					<FlexRow>
						<Typography fontWeight={'medium'} variant="body2">
							{title}
						</Typography>
					</FlexRow>
					<Gutter spacing={0.5} />

					<Typography variant="caption">{message}</Typography>
					<Gutter spacing={1} />
					<Typography variant="caption">{message2}</Typography>

					<Gutter spacing={2.5} />
					<Row
						justify="flex-end"
						align="center"
						style={{ width: '100%' }}
					>
						<StyledCancelButton autoFocus onClick={handleCancel}>
							Cancel
						</StyledCancelButton>
						<Gutter gap={0.25} />
						<StyledDeleteButton
							buttonColor={
								theme.palette.colors.red[
									'700'
								] as ColorsCodeType
							}
							textColor="white"
							onClick={handleCancel}
							mode={'primary'}
							autoFocus
						>
							Try with a different number
						</StyledDeleteButton>
					</Row>
				</FlexCol>
			</StyledAlertBox>
		</StyledModal>
	)
}
