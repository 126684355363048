import React from 'react'
import * as yup from 'yup'
import { Typography } from '@mui/material'
import {
	FieldImageDropZone,
	FieldTextInput,
	Gutter,
	ImagePlaceholder
} from 'components'
import { MdOutlineStoreMallDirectory } from 'react-icons/md'
import { useTranslate } from 'hooks'
import { uploadBusinessPic } from 'api'
import analytics from 'utils/segment'
import { SEGMENT_EVENTS } from 'data'

export const FormStep2Schema = yup.object().shape({
	name: yup
		.string()
		.trim()
		.test(
			'len',
			'Business Name length should be between 1 and 50 characters',
			val => {
				const len = (val || '').replace(/\s+/g, '').length
				return len >= 1 && len <= 50
			}
		)
		.required('This is a mandatory field and cannot be left empty'),
	address: yup
		.string()
		.trim()
		.test(
			'len',
			'Address length should be between 10 and 80 characters',
			val => {
				const len = (val || '').replace(/\s+/g, '').length
				return len >= 10 && len <= 80
			}
		)
		.required('This is a mandatory field and cannot be left empty')
})

type Props = {
	name: string
	handleSubmit: any
	values: any
}

export const Form2: React.ComponentType<Props> = ({ name, values }) => {
	const translate = useTranslate()

	return (
		<>
			<FieldImageDropZone
				name={'bProfilePic'}
				placeholder={ImagePlaceholder}
				icon={MdOutlineStoreMallDirectory}
				text={values[name]}
				api={uploadBusinessPic}
				onClick={() =>
					analytics.track(
						SEGMENT_EVENTS.BUSINESS_PICTURE_UPLOAD_CLICKED
					)
				}
			/>
			<Gutter spacing={2} />
			<Typography
				variant={'h4'}
				fontWeight="medium"
				sx={{ color: theme => theme.palette.colors.gray['600'] }}
			>
				{translate('onboarding.form.businessName')}
			</Typography>
			<Gutter spacing={1.5} />

			<FieldTextInput
				placeholder={translate(
					'onboarding.form.businessName.placeholder'
				)}
				inputProps={{ maxLength: 50 }}
				name={name}
				sx={{ width: '40ch' }}
				onClick={() =>
					analytics.track(SEGMENT_EVENTS.ONBOARDING_BUSINESS_NAME)
				}
			/>
			<Gutter spacing={1.5} />
			<FieldTextInput
				name="address"
				placeholder="Enter Address"
				sx={{ width: '40ch' }}
				inputProps={{ maxLength: 80 }}
				size="medium"
				multiline
				maxRows={2}
				rows={2}
				onClick={() =>
					analytics.track(
						SEGMENT_EVENTS.ONBOARDING_ENTER_ADDRESS_CLICKED
					)
				}
			/>
		</>
	)
}
