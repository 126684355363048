import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { FlexCol, FlexRow } from 'components/atoms/Flex'
import { Typography, useTheme } from '@mui/material'
import { Button, CheckBox, DateFilter, Gutter, SearchBar } from 'components'
import { styled } from '@mui/system'
import { LAYOUT_BREAKPOINT_WIDTH, LOAD_SHEET_EDIT_NAVIGATION } from 'data'
import type { LoadsheetType } from 'typings/loadsheet'
import { LOADSHEET_INITIAL_VALUE } from 'data/loadsheetList'
import { MdOutlineSave } from 'react-icons/md'
import { useAppErrors, useDimension } from 'hooks'
import { LoadSheetTabs } from 'screen/LoadSheet/Create/LoadSheetTabs'
import { Formik } from 'formik'
import { addOrRemoveArrObj } from 'helpers'
import { Summary } from 'screen/LoadSheet/Create/Summary'
import { createLoadSheet, getALoadSheet, updateLoadSheet } from 'api/loadSheet'
import { useSettings } from 'context/settings'
import { useDispatch, useSelector } from 'react-redux'
import type { RootState } from 'store/index-reducer'
import { useNavigate, useParams } from 'react-router-dom'
import * as Yup from 'yup'
import { useSnackbar } from 'notistack'
import { setNavigation } from 'store/app/actions'
import { IoIosClose } from 'react-icons/io'
import { BookerFilter } from '../Components/BookerFilter'

const Wrapper = styled(FlexCol)`
	width: 100%;
	height: 100%;
	max-height: 100%;
	flex-wrap: nowrap;
`

const Header = styled(FlexRow)`
	justify-content: space-between;
	max-height: 56px;
	padding: 8px;
	width: 100%;
	height: 100%;
	align-items: center;
	border-bottom: 1px solid ${({ theme }) => theme.palette.colors.gray['200']};
`

const SaveButton = styled(Button)`
	background-color: ${({ theme }) => theme.palette.colors.yellow['300']};
	color: ${({ theme }) => theme.palette.colors.gray['900']};
	border-radius: 8px;
	min-width: 38px;
	min-height: 38px;
	padding: 0 12px;

	:hover {
		background-color: ${({ theme }) => theme.palette.colors.yellow['200']};
	}
`

const Text = styled(Typography)`
	@media (max-width: ${LAYOUT_BREAKPOINT_WIDTH}px) {
		display: none;
	}
`

const Grid = styled('div')`
	display: grid;
	grid-template-columns: 4fr 1.5fr;
	width: 100%;
	height: 100%;
	column-gap: 10px;
`

const DateWrapper = styled(FlexRow)``

const Option = styled(FlexRow)`
	width: 100%;

	:hover {
		cursor: pointer;
	}
`

const RowButton = styled(FlexRow)`
	flex-wrap: nowrap;
	align-items: center;

	:hover {
		cursor: pointer;
	}
`

const validationSchema = Yup.object().shape({
	dispatchDate: Yup.string()
		.required('Amount is required')
		.typeError('Select a valid date'),
	invoiceItems: Yup.array()
		.of(Yup.object())
		.min(1, 'min 1 item is required')
		.required('min 1 item is required')
})

type FilterObjProps = { [key: string]: string }

export const CreateLoadSheet = () => {
	const [loading, setLoading] = useState(true)
	const [initialValue, setInitialValue] = useState(LOADSHEET_INITIAL_VALUE)
	const [searchKey, setSearchKey] = useState('')
	const { business } = useSettings()
	const dispatch = useDispatch()
	const [showIncludedInvoice, setShowIncludedInvoice] =
		useState<boolean>(false)
	const [value, setValue] = React.useState(0)
	const { height } = useDimension()
	const [filterObj, setFilterObj] = useState<FilterObjProps>({})
	const { businesses } = useSelector((state: RootState) => state.business)
	const { user } = useSelector((state: RootState) => state.user)
	const { id } = useParams()
	const { enqueueSnackbar } = useSnackbar()
	const navigate = useNavigate()
	const theme = useTheme()
	const { colors } = theme.palette
	const { setAppError } = useAppErrors()

	const businessInfo = useMemo(() => {
		const currentBusiness = businesses.find(bus => bus.id === business)

		return {
			businessName: currentBusiness?.name || '',
			profilePic: currentBusiness?.profilePic || '',
			address: currentBusiness?.address || '',
			email: user?.email || '',
			phone: user?.phone || { countryCode: '', number: '' }
		}
	}, [business, businesses, user?.phone])

	const dateFilter = {
		startDate: filterObj?.startDate,
		endDate: filterObj?.endDate
	}

	const onSearch = async (searchText: string) => {
		setSearchKey(searchText)
	}

	const dispatchTitle = useCallback(
		(loadSheet: LoadsheetType) => {
			dispatch(
				setNavigation(
					LOAD_SHEET_EDIT_NAVIGATION(
						loadSheet?.friendlyId || '0',
						loadSheet.id || '0'
					)
				)
			)
		},
		[dispatch]
	)

	const onDateFilterSelect = (_filterObj: { [key: string]: string }) => {
		const startDate = _filterObj?.startDate
		const endDate = _filterObj?.endDate
		const obj = {
			...filterObj,
			startDate,
			endDate
		}
		setFilterObj(obj)
	}

	const onSubmit = async (values: LoadsheetType, action: any) => {
		const finalValue = {
			...values,
			business: business,
			businessInfo: businessInfo,
			invoices: values.invoiceItems.map(d => d.id)
		}
		try {
			action.setSubmitting(true)
			let res
			if (id) {
				res = await updateLoadSheet(id, finalValue)
			} else {
				res = await createLoadSheet(finalValue as any)
			}
			enqueueSnackbar('Successfully saved', {
				variant: 'success'
			})
			navigate(`/load-sheet/view/${res.id}`, {
				replace: true,
				state: { data: res, isUpdated: true }
			})
		} catch (e: any) {
			enqueueSnackbar(e?.errors[0].message, {
				variant: 'error'
			})
			setAppError(e, action)
		} finally {
			action.setSubmitting(true)
		}
	}

	const getInitialValue = useCallback(async () => {
		try {
			setLoading(true)
			if (id) {
				const res = await getALoadSheet(business, id)
				dispatchTitle(res)
				setInitialValue(res)
			} else {
				setInitialValue({
					...LOADSHEET_INITIAL_VALUE,
					invoiceItems: []
				})
			}
		} catch (e) {
			//
		} finally {
			setLoading(false)
		}
	}, [business, dispatchTitle, id])

	useEffect(() => {
		getInitialValue()
	}, [getInitialValue])

	return (
		<Wrapper>
			{!loading && (
				<Formik
					initialValues={initialValue}
					onSubmit={onSubmit}
					validationSchema={validationSchema}
				>
					{({
						setFieldValue,
						values,
						handleSubmit,
						isValid,
						isSubmitting
					}) => (
						<>
							<Header>
								<FlexRow align={'center'}>
									<Typography variant={'h6'} fontWeight={600}>
										Create Load Sheet
									</Typography>
								</FlexRow>
								<FlexRow>
									<SearchBar onSearch={onSearch} />
									<Gutter gap={0.5} />

									<SaveButton
										onClick={() => handleSubmit()}
										disabled={
											!isValid ||
											isSubmitting ||
											values.invoiceItems.length < 1
										}
									>
										<MdOutlineSave />
										<Gutter gap={0.5} />
										<Text
											variant={'caption'}
											fontWeight={600}
										>
											Save Load Sheet
										</Text>
									</SaveButton>
								</FlexRow>
							</Header>
							<Header>
								<FlexRow
									align={'center'}
									style={{ flexWrap: 'nowrap' }}
								>
									<DateWrapper>
										<DateFilter
											filterObj={
												filterObj.startDate ||
												filterObj.endDate
													? dateFilter
													: {}
											}
											onSelect={onDateFilterSelect}
											placeholder={'Date'}
											disabled={value === 1}
										/>
									</DateWrapper>
									<Gutter gap={1.5} />
									<BookerFilter
										filterObj={filterObj}
										onSelect={val => setFilterObj(val)}
										disabled={value === 1}
									/>
									<Gutter gap={1.5} />
									<Option
										align={'center'}
										onClick={() => {
											setShowIncludedInvoice(
												!showIncludedInvoice
											)
										}}
									>
										<CheckBox
											checked={
												showIncludedInvoice ||
												value === 1
											}
											disabled={value === 1}
										/>
										<Gutter gap={0.5} />
										<Typography
											variant={'body2'}
											color={
												value === 1
													? colors.gray['400']
													: undefined
											}
										>
											Show Loaded Invoices
										</Typography>
									</Option>
								</FlexRow>
								<RowButton
									onClick={() => {
										setFilterObj({})
										setShowIncludedInvoice(false)
									}}
								>
									<IoIosClose
										size={25}
										color={colors.gray['700']}
									/>
									<Gutter gap={0.1} />
									<Typography
										variant={'body2'}
										color={colors.gray['700']}
									>
										Clear Filters
									</Typography>
								</RowButton>
							</Header>
							<Gutter />
							<Grid style={{ maxHeight: height - 56 * 3 - 20 }}>
								<LoadSheetTabs
									searchKey={searchKey}
									onItemSelect={inv => {
										setFieldValue(
											'invoiceItems',
											addOrRemoveArrObj(
												values.invoiceItems,
												inv,
												'id'
											)
										)
									}}
									filterObj={filterObj}
									showIncludedInvoice={showIncludedInvoice}
									value={value}
									setValue={setValue}
								/>
								<Summary />
							</Grid>
						</>
					)}
				</Formik>
			)}
		</Wrapper>
	)
}
