import React, { useCallback, useEffect, useState } from 'react'
import { styled } from '@mui/system'
import { FlexCol, FlexRow } from 'components/atoms/Flex'
import { Button, Chip, Typography, useTheme } from '@mui/material'
import { DateFilter, Gutter, SearchBar } from 'components'
import { LAYOUT_BREAKPOINT_WIDTH } from 'data'
import { AiOutlinePlus } from 'react-icons/ai'
import type { LoadsheetType } from 'typings/loadsheet'
import { LoadSheetTable } from 'screen/LoadSheet/Table/Table'
import { useNavigate } from 'react-router-dom'
import type { DataResponseType, ResponsePageInfo } from 'typings'
import { fetchLoadSheetList } from 'api/loadSheet'
import { useAppErrors } from 'hooks'
import { useSettings } from 'context/settings'

const Wrapper = styled(FlexCol)`
	width: 100%;
	height: 100%;
`

const Header = styled(FlexRow)`
	justify-content: space-between;
	max-height: 56px;
	padding: 8px;
	width: 100%;
	height: 100%;
	align-items: center;
	border-bottom: 1px solid var(--gray-200, #eaecf0);
`

const SaveButton = styled(Button)`
	background-color: ${({ theme }) => theme.palette.colors.yellow['300']};
	color: ${({ theme }) => theme.palette.colors.gray['900']};
	border-radius: 8px;
	min-width: 38px;
	min-height: 38px;
	padding: 0 12px;

	:hover {
		background-color: ${({ theme }) => theme.palette.colors.yellow['200']};
	}
`

const Text = styled(Typography)`
	@media (max-width: ${LAYOUT_BREAKPOINT_WIDTH}px) {
		display: none;
	}
`

const DateWrapper = styled(FlexRow)`
	@media (max-width: 1300px) {
		display: none;
	}
`

type FilterObjProps = { [key: string]: string }

export const Dashboard = () => {
	const [loading, setLoading] = useState<boolean>(false)
	const [searchKey, setSearchKey] = useState('')
	const [dataSource, setDataSource] = useState<LoadsheetType[]>([])
	const [pageInfo, setPageInfo] = useState<ResponsePageInfo>({
		totalPages: 1,
		currentPage: 1,
		edgesPerPage: 20
	})
	const [filterObj, setFilterObj] = useState<FilterObjProps>({})
	const navigate = useNavigate()
	const { business } = useSettings()
	const { setAppError } = useAppErrors()
	const dateFilter = {
		startDate: filterObj?.startDate,
		endDate: filterObj?.endDate
	}
	const theme = useTheme()
	const { colors } = theme.palette

	const getInitialInvoiceList = useCallback(async () => {
		try {
			setLoading(true)
			const res: DataResponseType<LoadsheetType> =
				await fetchLoadSheetList(business, {
					page: 1,
					limit: pageInfo.edgesPerPage,
					...filterObj,
					searchKey
				})
			setDataSource(res.edges)
			setPageInfo(res.pageInfo)
		} catch (e: any) {
			setAppError(e)
		} finally {
			setLoading(false)
		}
	}, [business, filterObj, pageInfo.edgesPerPage, searchKey, setAppError])

	const fetchNewLoadSheetList = async () => {
		try {
			setLoading(true)
			if (
				!loading &&
				pageInfo?.totalPages &&
				pageInfo?.totalPages >= pageInfo.currentPage + 1
			) {
				const res: DataResponseType<LoadsheetType> =
					await fetchLoadSheetList(business, {
						page: pageInfo.currentPage + 1,
						limit: pageInfo.edgesPerPage,
						...filterObj,
						searchKey
					})
				setDataSource([...dataSource, ...res.edges])
				setPageInfo(res.pageInfo)
			}
		} catch (e: any) {
			setAppError(e)
		} finally {
			setLoading(false)
		}
	}

	const onSearch = async (searchText: string) => {
		setDataSource([])
		setSearchKey(searchText)
	}

	useEffect(() => {
		getInitialInvoiceList()
	}, [getInitialInvoiceList])

	const onDateFilterSelect = (_filterObj: { [key: string]: string }) => {
		const startDate = _filterObj?.startDate
		const endDate = _filterObj?.endDate
		const obj = {
			...filterObj,
			startDate,
			endDate
		}
		setFilterObj(obj)
	}

	return (
		<Wrapper>
			<Header>
				<FlexRow align={'center'}>
					<Typography variant={'h6'} fontWeight={600}>
						Load Sheets
					</Typography>
					<Gutter gap={0.2} />
					<Chip label={dataSource.length} size={'small'} />
				</FlexRow>
				<FlexRow>
					<SearchBar
						onSearch={onSearch}
						InputProps={{
							startAdornment: (
								<Typography
									variant={'subtitle1'}
									color={colors.gray['400']}
								>
									LS-
								</Typography>
							)
						}}
					/>
					<Gutter gap={0.5} />
					<DateWrapper>
						<DateFilter
							filterObj={
								filterObj.startDate || filterObj.endDate
									? dateFilter
									: {}
							}
							onSelect={onDateFilterSelect}
						/>
					</DateWrapper>
					<Gutter gap={0.5} />
					<SaveButton
						onClick={() => {
							navigate('/load-sheet/create-load-sheet')
						}}
					>
						<AiOutlinePlus />
						<Gutter gap={0.5} />
						<Text variant={'caption'} fontWeight={600}>
							Create New
						</Text>
					</SaveButton>
				</FlexRow>
			</Header>
			<Gutter />
			<LoadSheetTable
				searchKey={searchKey}
				dataSource={dataSource}
				fetchLoadSheetList={fetchNewLoadSheetList}
			/>
		</Wrapper>
	)
}
