import React, { useCallback } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { styled } from '@mui/system'
import { FlexCol, FlexRow } from 'components/atoms/Flex'
import { Button, Capsule, FieldPhoneNumberInput, Gutter } from 'components'
import { Formik } from 'formik'
import { Typography, useTheme } from '@mui/material'
import { truncate } from 'helpers'
import { useAppErrors } from 'hooks'
import { CustomRadioGroup } from 'components/atoms/RadioGroup'
import { OrderFormTable } from 'screen/OrderForm/Table'
import analytics from 'utils/segment'
import { ORDER_FORM_TYPE, SEGMENT_EVENTS } from 'data'
import { TextInputWrapper } from 'screen/OrderCenter/NewOrder/NewOrderModal'
import Logo from 'assets/Logo.png'
import type {
	ColorsCodeType,
	IBusiness,
	IOrderFormProps,
	IOrderFormSettings
} from 'typings'
import * as Yup from 'yup'
import {
	getBusinessInfo,
	getOrderFormSettingsInfo,
	postOrderForm
} from 'api/orderForm'
import { OrderFormAlert } from 'screen/OrderForm/OrderFormAlert'
import { validatePhoneNo } from 'helpers/validatePhoneNo'
import { useSettings } from 'context/settings'

const Wrapper = styled(FlexCol)`
	width: 100%;
	height: 100%;
	min-height: 100vh;
	padding: 40px;
	align-items: center;
`

const Box = styled(FlexCol)`
	width: 100%;
	height: 100%;
	max-width: 1200px;
`

const StyledCapsule = styled(Capsule)`
	font-size: 12px;
	font-weight: 500;
	margin: 0;
`

const SpaceBetween = styled(FlexRow)`
	width: 100%;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;

	@media (max-width: 600px) {
		flex-direction: column-reverse;
		justify-content: center;
		align-items: center;
	}
`

const RowBetween = styled(FlexRow)`
	width: 100%;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
`

const Grid = styled('div')`
	width: 100%;
	display: grid;
	grid-template-columns: 3fr 1fr;
	column-gap: 20px;

	@media (max-width: 600px) {
		grid-template-columns: 1fr;
		row-gap: 20px;
	}
`

const Image = styled('img')`
	height: 34px;
	width: 90px;
`

const validationSchema = Yup.object().shape({
	contactPhone: Yup.string()
		.required('Required')
		.test('contactPhone', (num, values) => {
			const {
				parent: { contactPhone, dialCode, countryCode }
			} = values
			const res = validatePhoneNo(contactPhone, dialCode, countryCode)

			if (!res.isValid) {
				return values.createError({
					path: 'contactPhone',
					message: truncate(res.message, 47)
				})
			}
			return true
		})
		.min(4, 'Phone number should be 4 digits long.'),
	items: Yup.array()
		.of(
			Yup.object().shape({
				quantity: Yup.number()
					.integer()
					.min(1, 'Required')
					.required('Required')
			})
		)
		.min(1, 'min 1 item is required')
		.required('Required')
})

export const OrderForm = () => {
	const [isModalOpen, setIsModalOpen] = React.useState(false)
	const [loading, setLoading] = React.useState(true)
	const [business, setBusiness] = React.useState<IBusiness>()
	const [orderFormSettings, setOrderFormSettings] =
		React.useState<IOrderFormSettings>()
	const { id } = useParams()
	const { setAppError } = useAppErrors()
	const theme = useTheme()
	const navigate = useNavigate()
	const { segmentId } = useSettings()

	const initialValues: IOrderFormProps & {
		dialCode: string
		countryCode: string
	} = {
		business: id,
		contactPhone: '',
		items: [],
		type: 'order',
		dialCode: '92',
		countryCode: 'PK'
	}

	const getBusinessAndOrderFormSettings = useCallback(async () => {
		try {
			const business = await getBusinessInfo(id as string)
			setBusiness(business)
			const orderFormSettings = await getOrderFormSettingsInfo(
				id as string
			)
			setOrderFormSettings(orderFormSettings)
		} catch (e) {
			alert('Invalid link')
		} finally {
			setLoading(false)
		}
	}, [id])

	const onSubmit = async (values: typeof initialValues, action: any) => {
		try {
			action.setSubmitting(true)
			const res = await postOrderForm(values)
			action.resetForm()

			if (segmentId === null) {
				analytics.identify(
					`+${values.dialCode}${values.contactPhone}`,
					{
						name: `+${values.dialCode}${values.contactPhone}`,
						phone: `+${values.dialCode}${values.contactPhone}`,
						external: true
					}
				)
			}
			if (res) {
				navigate('/order-form/confirmation', {
					state: { id: id, business: business }
				})
			}
		} catch (e: any) {
			setAppError(e, action)
			if (e.errors[0] && e.errors[0].field === 'contactPhone') {
				setIsModalOpen(true)
			}
		} finally {
			action.setSubmitting(false)
		}
	}

	React.useEffect(() => {
		if (loading) {
			getBusinessAndOrderFormSettings()
		}
	}, [getBusinessAndOrderFormSettings, loading])

	const [displayStockQuantity, displaySellingPrice] = React.useMemo(() => {
		if (orderFormSettings && orderFormSettings.settings) {
			return [
				orderFormSettings.settings.displayStockQuantity,
				orderFormSettings.settings.displaySellingPrice
			]
		}
		return [false, false]
	}, [orderFormSettings])

	return (
		<>
			<Wrapper>
				<Box>
					{business && (
						<Formik
							initialValues={initialValues}
							onSubmit={onSubmit}
							validationSchema={validationSchema}
						>
							{({
								values,
								setFieldValue,
								handleSubmit,
								dirty,
								isSubmitting
							}) => (
								<>
									<RowBetween>
										<Typography
											variant={'h6'}
											fontWeight={'bold'}
										>
											{business?.name}
										</Typography>
										<StyledCapsule
											name={
												'Accepting orders and inquires'
											}
											value={
												'Accepting orders and inquires'
											}
											color={'green'}
											disabled
											isSelected
										/>
									</RowBetween>
									<Typography variant={'subtitle1'}>
										Complete the Order Form below to place
										your order or make an inquiry
									</Typography>
									<Gutter spacing={4} />
									<FieldPhoneNumberInput
										name={'contactPhone'}
										dialCode={'dialCode'}
										countryInitials={'countryCode'}
									/>
									<Gutter spacing={1} />
									<FlexRow
										justify={'center'}
										align={'center'}
									>
										<CustomRadioGroup
											options={ORDER_FORM_TYPE}
											defaultValue={values.type}
											onChange={(
												event: React.ChangeEvent<HTMLInputElement>
											) => {
												setFieldValue(
													'type',
													event.target.value
												)
											}}
										/>
									</FlexRow>
									<Gutter />
									<Grid>
										<FlexCol>
											<OrderFormTable
												OrderFormSettings={{
													settings: {
														displayStockQuantity,
														displaySellingPrice,
													}
												}}
												name={'items'}
												businessId={id as string}
											/>
										</FlexCol>
										<TextInputWrapper
											multiline
											rows={4}
											name="comments"
											placeholder="Comments"
											size="small"
											onClick={() => {
												analytics.track(
													SEGMENT_EVENTS.ORDER_COMMENT
												)
											}}
										/>
									</Grid>
									<Gutter spacing={2} />
									<SpaceBetween>
										<FlexRow
											align={'center'}
											style={{ marginTop: 20 }}
										>
											<Typography>Powered by</Typography>
											<Gutter gap={0.3} />
											<Image src={Logo} />
										</FlexRow>
										<Button
											buttonColor={
												theme.palette.colors.green[
													'700'
												] as ColorsCodeType
											}
											textColor="white"
											onClick={() => handleSubmit()}
											disabled={isSubmitting || !dirty}
										>
											Submit
										</Button>
									</SpaceBetween>
								</>
							)}
						</Formik>
					)}
				</Box>
			</Wrapper>
			<OrderFormAlert
				message={`The phone number you have entered could not be found in ${business?.name}'s Contact Book.
				`}
				message2={
					'In order to access the form, contact the business and request them to add your number.'
				}
				title={'Phone number not found'}
				open={isModalOpen}
				setOpen={setIsModalOpen}
			/>
		</>
	)
}
