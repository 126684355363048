import React from 'react'
import { styled } from '@mui/system'
import Bg from 'assets/onboarding/bg.jpg'
import { FlexCol } from 'components/atoms/Flex'

const Background = styled(FlexCol)`
	position: relative;
	justify-content: space-around;
	align-items: center;
	width: 100%;
	height: 100vh;
	background: url(${Bg}) no-repeat center;
	background-size: cover;
`

export const LoginLayout = () => {
	return <Background />
}
