import { Layout } from 'components/app/Layout'
import React, { Suspense } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom'
import { restoreState } from 'store/auth/actions'
import { type RootState } from 'store/index-reducer'
import {
	Analytics,
	Changelog,
	CreateQuotation,
	HelpCenter,
	Invoices,
	Login,
	NotFound,
	OrderBookers,
	OtpVerification,
	PrivateRoute,
	Profile,
	Quotations,
	RecycleBinTable,
	SettingLayout,
	SignUpComplete
} from 'screen'
import { Taxes } from 'screen/Settting/Taxes/Taxes'
import { SettingOrderForm } from 'screen/Settting/OrderForm/OrderForm'
import { SettingInventory } from 'screen/Settting/Inventory/Inventory'
import { Privacy } from 'screen/Settting/Privacy/Privacy'
import { Ledger } from 'screen/Contact/Ledger/Ledger'
import { OrderLayout } from 'screen/OrderCenter/OrderLayout'
import { OrderCenterScreen } from 'screen/OrderCenter/OrderCenterScreen'
import { RecyclebinOrderCenter } from './OrderCenter/Recyclebin/Recyclebin'
import { InvoiceView } from 'screen/Invoices/View/InvoiceView'
import { OrderForm } from 'screen/OrderForm'
import { OrderConfirmation } from 'screen/OrderForm/OrderConfirmation'
import { QuotationView } from 'screen/Quotations/View/QuotationView'
import { AddUser } from './User'
import { OrderBookerForm } from 'screen/OrderBooker'
import { SettingInvoice } from 'screen/Settting/Invoice'
import { CreateInvoice } from 'screen/Invoices/NewCreate/Invoice'
import { Dashboard } from 'screen/LoadSheet/Dashboard'
import { CreateLoadSheet } from 'screen/LoadSheet/Create/CreateLoadSheet'
import { LoadSheetView } from './LoadSheet/View/LoadSheetView'
import { Roles } from 'screen/Settting/UserRoles/Roles'
import { Home } from 'screen/Home/Home'
import { OrderFinancing } from 'screen/OrderFinancing'
import { RegisterForFinance } from 'screen/OrderFinancing/RegisterForFinance'
import { NewFinanceApplication } from 'screen/OrderFinancing/NewFinanceApplication'
import { LoanView } from 'screen/OrderFinancing/LoanView/LoanView'

const OnBoardingForm = React.lazy(
	() => import('screen/Onboarding/Form/OnBoardingForm')
)
const Contacts = React.lazy(() => import('screen/Contact/Contacts'))
const Inventory = React.lazy(() => import('screen/Inventory/Inventory'))

function AppRoutes() {
	const dispatch = useDispatch()
	const isRestoringState = useSelector(
		(state: RootState) => state.auth.isRestoring
	)

	if (isRestoringState) {
		dispatch(restoreState())
	}

	return (
		<BrowserRouter>
			<Routes>
				<Route
					element={
						<Layout>
							<PrivateRoute isRestoringState={isRestoringState} />
						</Layout>
					}
					caseSensitive={false}
				>
					<Route
						element={
							<OrderLayout>
								<Outlet />
							</OrderLayout>
						}
					>
						<Route
							index
							path={'/orders'}
							element={<OrderCenterScreen />}
						/>
						<Route index path={'/home'} element={<Home />} />
						<Route
							index
							path={'/orders/recyclebin'}
							element={<RecyclebinOrderCenter />}
						/>
					</Route>
					<Route path={'/analytics'} element={<Analytics />} />
					<Route
						path={'/contacts'}
						element={
							<Suspense fallback={<>...</>}>
								<Contacts />
							</Suspense>
						}
					/>
					<Route
						path={'/contacts/ledger/:contactId'}
						element={<Ledger />}
					/>
					<Route
						path={'/Inventory'}
						element={
							<Suspense fallback={<>...</>}>
								<Inventory />
							</Suspense>
						}
					/>
					<Route path={'/invoices'} element={<Invoices />} />
					<Route path={'/orderbookers'} element={<OrderBookers />} />
					<Route path={'/help'} element={<HelpCenter />} />
					<Route path={'/changelog'} element={<Changelog />} />
					<Route path={'/invoice/create-invoice'}>
						<Route path={':id'} element={<CreateInvoice />} />
						<Route path="" element={<CreateInvoice />} />
					</Route>
					<Route
						path={'/invoice/view/:id'}
						element={<InvoiceView />}
					/>
					<Route
						path={'/load-sheet/view/:id'}
						element={<LoadSheetView />}
					/>
					<Route path={'/quotation'} element={<Quotations />} />
					<Route path="/quotation/create-quotation">
						<Route path={':id'} element={<CreateQuotation />} />
						<Route path="" element={<CreateQuotation />} />
					</Route>
					<Route
						path={'/quotation/recyclebin'}
						element={<RecycleBinTable />}
					/>
					<Route
						path={'/quotation/view/:id'}
						element={<QuotationView />}
					/>
					<Route path={'/profile'} element={<Profile />} />

					<Route path={'/load-sheet/create-load-sheet'}>
						<Route path={':id'} element={<CreateLoadSheet />} />
						<Route path="" element={<CreateLoadSheet />} />
					</Route>
					<Route path={'/load-sheet'} element={<Dashboard />} />
					<Route path={'/whats-new'} element={<Changelog />} />
					<Route
						path={'/ordr-financing'}
						element={<OrderFinancing />}
					/>
					<Route
						path={'/ordr-finance/register'}
						element={<RegisterForFinance />}
					/>
					<Route
						path={'/ordr-financing/new-finance-application'}
						element={<NewFinanceApplication />}
					/>
					<Route path={'/ordr-financing/loanView/'}>
						<Route path={':id'} element={<LoanView />} />
						<Route path="" element={<LoanView />} />
					</Route>
					<Route
						element={
							<SettingLayout>
								<Outlet />
							</SettingLayout>
						}
					>
						<Route path={'/settings/taxes'} element={<Taxes />} />
						<Route
							path={'/settings/inventory'}
							element={<SettingInventory />}
						/>
						<Route
							path={'/settings/orderForm'}
							element={<SettingOrderForm />}
						/>
						<Route
							path={'/settings/privacy'}
							element={<Privacy />}
						/>
						<Route
							path="/settings/userRoles"
							element={<Roles />}
						></Route>
						<Route
							path={'/settings/invoice'}
							element={<SettingInvoice />}
						/>
					</Route>
				</Route>
				<Route path={'/order-form/:id'} element={<OrderForm />} />
				<Route
					path={'/orderbookers-form/:id'}
					element={<OrderBookerForm />}
				/>
				<Route
					path={'/order-form/confirmation'}
					element={<OrderConfirmation />}
				/>
				<Route path={'/login'} element={<Login />} />
				<Route
					path={'/onboarding'}
					element={
						<Suspense fallback={<>...</>}>
							<OnBoardingForm />
						</Suspense>
					}
				/>
				<Route
					path={'/otp-verification'}
					element={<OtpVerification />}
				/>
				<Route path={'/signup_complete'} element={<SignUpComplete />} />
				<Route path={'/admin-panel/add-user'} element={<AddUser />} />
				<Route path="*" element={<NotFound />} />
			</Routes>
		</BrowserRouter>
	)
}

export default AppRoutes
