import * as React from 'react'
import { useCallback } from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import type { ICart } from 'typings'
import { styled } from '@mui/system'
import { FormatAmount } from 'components'
import { FlexCol } from 'components/atoms/Flex'
import { currencyToLocal } from 'helpers/currency'
import { Abbr } from 'components/atoms/Abbr'

const StyledTableRow = styled(TableRow)`
	background-color: ${({ theme }) => theme.palette.background.default};
`

const StyledWrapper = styled(FlexCol)`
	border: 1px solid ${({ theme }) => theme.palette.background.paper};
	width: 100%;
	z-index: 0;
`

type OrderCenterDetailsProps = {
	items: ICart[]
	maxHeight?: number
}

export const OrderTable: React.ComponentType<OrderCenterDetailsProps> = ({
	items,
	maxHeight
}) => {
	const RenderRow = useCallback(({ row }: { row: ICart }) => {
		return (
			<StyledTableRow hover role="checkbox" tabIndex={-1}>
				<TableCell>
					<Abbr title={row.code} length={7} />
				</TableCell>
				<TableCell>{row.name}</TableCell>
				<TableCell>
					<Abbr title={row.unit} length={14} />
				</TableCell>
				<TableCell>
					<FormatAmount
						amount={row.quantity}
						variant="caption"
						limit={10}
						style={{ color: 'black' }}
						fontSize={16}
					/>
				</TableCell>
				<TableCell>
					<Abbr
						title={currencyToLocal(
							parseInt(row.perItemSellPrice.toString())
						)}
						length={10}
					/>
				</TableCell>
			</StyledTableRow>
		)
	}, [])

	return (
		<StyledWrapper>
			<TableContainer sx={{ maxHeight }}>
				<Table stickyHeader aria-label="sticky table">
					<TableHead>
						<StyledTableRow>
							<TableCell>Code</TableCell>
							<TableCell>Items</TableCell>
							<TableCell>Unit</TableCell>
							<TableCell>Quantity</TableCell>
							<TableCell>Selling Price(PKR)</TableCell>
						</StyledTableRow>
					</TableHead>
					<TableBody>
						{items.map(row => (
							<RenderRow
								key={`${row.id}-${row.unit}`}
								row={row}
							/>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</StyledWrapper>
	)
}
