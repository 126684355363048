import React, { useEffect, useState } from 'react'
import { AppLayout } from 'screen/Onboarding/AppLayout'
import { styled } from '@mui/system'
import { FlexCol, FlexRow } from 'components/atoms/Flex'
import {
	Button,
	FieldOtpTextInput,
	Gutter,
	HighLightMultipleWords,
	Timer
} from 'components'
import { Typography, useTheme } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import { Formik } from 'formik'
import { useTranslate } from 'hooks'
import type { ILogin } from 'typings/auth'
import type { RequestOtpProps } from 'api'
import { auth, getAllBusiness, getRefreshToken, requestOtp } from 'api'
import { useSnackbar } from 'notistack'
import { useDispatch } from 'react-redux'
import { setCurrentBusiness, setSession, setToken } from 'store/user/actions'
import moment from 'moment'
import OTP from 'assets/onboarding/otp.png'
import { fetchAllBusinessSuccess } from 'store/business/actions'
import { useAppErrors } from 'hooks/useAppErrors'
import { useSettings } from 'context/settings'
import analytics from 'utils/segment'
import { SEGMENT_EVENTS } from 'data'

const Container = styled(FlexCol)`
	padding: 20px;
	width: 100%;
	flex: 1;
`

const Wrapper = styled(FlexCol)`
	width: 300px;
`

const Row = styled(FlexRow)`
	margin-top: 50px;
	position: relative;
	aspect-ratio: 1.34;
	width: 500px;
	height: 394px;
	@media screen and (max-width: 1015px) {
		position: absolute;
		opacity: 0.5;
		z-index: 0;
	}
`
const Image = styled(FlexCol)`
	justify-content: space-around;
	align-items: center;
	width: 120%;
	height: 125%;
	background: url(${OTP}) no-repeat center;
	background-size: cover;
	right: 30px;
`

const ButtonWrapper = styled('div')`
	margin-top: 60px;
	position: absolute;
	left: 50px;
	bottom: 80px;
	@media screen and (max-width: 600px) {
		margin-top: 20px;
		width: 100%;
		text-align: center;
	}
`

const TryAgainWrapper = styled(FlexCol)`
	margin-left: 25px;

	@media screen and (max-width: 600px) {
		margin-left: 0;
		margin-top: 5px;
	}
`

export const OtpVerification = () => {
	const [resend, setResend] = useState<boolean>(false)
	const navigate = useNavigate()
	const { state } = useLocation()
	const dispatch = useDispatch()
	const { enqueueSnackbar } = useSnackbar()
	const { setAppError } = useAppErrors()
	const translate = useTranslate()
	const theme = useTheme()
	const { setBusiness } = useSettings()
	const [countDown, setCountDown] = useState(59)

	const mobNo = localStorage.getItem('phone')

	// if phone number doesn't exist in your localstorage then
	useEffect(() => {
		if (!mobNo) {
			navigate('/login', { replace: true })
		}
		const seconds = calculateTimeDifference(state?.otp.noNewCodeTill)
		if (!state?.otp) {
			setCountDown(120)
			setResend(true)
		} else if (seconds > 0) {
			setCountDown(seconds)
			setResend(true)
		} else {
			setResend(false)
		}
	}, [mobNo, navigate, state?.otp])

	const handleTimeChange = (time: number) => {
		if (time.toString() && time == 0) {
			setResend(false)
		}
	}

	const onSubmit = async (values: ILogin, actions: any) => {
		try {
			actions.setSubmitting(true)
			const { token, user } = await auth(values)

			localStorage.removeItem('phone')
			localStorage.setItem('token', token)

			dispatch(setSession(token, user))
			const { token: tt } = await getRefreshToken()

			dispatch(setToken(tt))
			// you can use saga here but our next route depends on the response
			const res = await getAllBusiness()

			// SEGMENT
			let userType = 'new_user'
			if (res.length > 0) userType = 'existing_user'
			analytics.track(SEGMENT_EVENTS.CLICK_CONTINUE, {
				screen: 'phone_verification',
				user_type: userType
			})
			analytics.identify(user?.id, {
				name: user?.fullName,
				phone: `+${user?.phone?.countryCode}${user?.phone?.number}`
			})
			// SEGMENT END

			if (res.length > 0) {
				setBusiness(res[0].id)
				dispatch(setCurrentBusiness(res[0].id))
				dispatch(fetchAllBusinessSuccess(res))
				navigate('/home')
			} else {
				navigate('/onboarding')
			}
		} catch (e: any) {
			setAppError(e, actions)
		} finally {
			actions.setSubmitting(false)
		}
	}

	const calculateTimeDifference = (noNewCodeTill: any) => {
		const CurrentDate = moment(Date.now())
		const duration = moment.duration(
			moment(noNewCodeTill).diff(CurrentDate)
		)
		return Math.floor(duration.asSeconds())
	}

	const onResend = async (payload: RequestOtpProps) => {
		try {
			analytics.track(SEGMENT_EVENTS.ONBOARDING_RESEND_OTP)
			const res = await requestOtp(payload)
			navigate('/otp-verification', { state: { otp: res } })
			// Calculating time diff b/w the time when otp can be resent and the current time to disable the resend button
			const seconds = calculateTimeDifference(res.noNewCodeTill)
			setCountDown(seconds)
			setResend(true)
			enqueueSnackbar(
				translate('onboarding.snackbar.otpVerification') +
					` ${payload.phone}`,
				{
					variant: 'success'
				}
			)
		} catch (e: any) {
			setAppError(e)
		}
	}

	return (
		<>
			<AppLayout>
				{mobNo && (
					<Formik
						initialValues={{
							phone: mobNo || '',
							code: ''
						}}
						onSubmit={onSubmit}
					>
						{({ handleSubmit, dirty, values, isSubmitting }) => (
							<Container
								align={'flex-start'}
								style={{ zIndex: 100 }}
							>
								<FlexRow
									justify="space-around"
									align="center"
									style={{
										width: '100%'
									}}
								>
									<FlexCol style={{ zIndex: 100 }}>
										<Gutter spacing={1} />

										<Typography
											variant={'h3'}
											fontWeight={'medium'}
										>
											{translate(
												'onboarding.phoneVerification'
											)}
										</Typography>
										<Gutter spacing={0.5} />
										<HighLightMultipleWords
											textToHighlight={translate(
												'onboarding.enterCode',
												{ mobNo }
											)}
											searchWords={[`${mobNo}`]}
										/>
										<Gutter spacing={2} />
										<FlexRow>
											<Wrapper>
												<FieldOtpTextInput
													name={'code'}
													length={4}
												/>
											</Wrapper>

											{resend ? (
												<TryAgainWrapper>
													<Typography
														sx={{
															color: theme.palette
																.colors.gray[
																'500'
															]
														}}
													>
														{translate(
															'onboarding.tryAgain'
														)}
														<Timer
															onTimeChange={
																handleTimeChange
															}
															countdownFrom={
																countDown
															}
														/>{' '}
													</Typography>
													<Typography
														component="span"
														sx={{
															color: theme.palette
																.colors.gray[
																'400'
															]
														}}
														variant="body1"
													>
														{translate(
															'onboarding.resend'
														)}
													</Typography>
												</TryAgainWrapper>
											) : (
												<TryAgainWrapper>
													<Typography
														color={
															theme.palette.colors
																.gray['500']
														}
														variant="body1"
														fontFamily="Inter"
														fontWeight={500}
													>
														{translate(
															'onboarding.didnotGetCode'
														)}
													</Typography>
													<Typography
														onClick={() =>
															onResend({
																phone:
																	mobNo?.toString() ||
																	''
															})
														}
														color="primary"
														sx={{
															':hover': {
																cursor: 'pointer'
															}
														}}
														variant="body1"
														fontFamily="Inter"
														fontWeight={500}
													>
														{translate(
															'onboarding.resend'
														)}
													</Typography>
												</TryAgainWrapper>
											)}
										</FlexRow>
										<Gutter spacing={2} />
										<Button
											mode={'primary'}
											buttonColor={'green'}
											textColor={'white'}
											onClick={() => handleSubmit()}
											type={'submit'}
											disabled={
												!dirty ||
												values.code.length < 4 ||
												isSubmitting
											}
										>
											{translate('app.button.continue')}
										</Button>
									</FlexCol>
									<Row>
										<Image />
									</Row>
								</FlexRow>

								<Gutter gap={2} />
								<ButtonWrapper>
									<Button
										mode="secondary"
										textColor={'green'}
										onClick={() => {
											analytics.track(
												SEGMENT_EVENTS.CLICK_BACK,
												{
													screen: 'phone_verification'
												}
											)
											navigate('/home', {
												replace: true
											})
										}}
									>
										{translate('app.button.back')}
									</Button>
								</ButtonWrapper>
							</Container>
						)}
					</Formik>
				)}
			</AppLayout>
		</>
	)
}
