import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { styled } from '@mui/system'
import { FlexCol, FlexRow } from 'components/atoms/Flex'
import { Capsule, CheckBox, Gutter } from 'components'
import {
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
	useTheme
} from '@mui/material'
import { useDimension } from 'hooks'
import {
	calculateInvoiceTotalAmount,
	dateFormat,
	GENERATE_INVOICE_DATA,
	INVOICE_STATUS_OPTIONS,
	LAYOUT_PADDING,
	PAYMENT_STATUS_OPTIONS,
	SCROLLBAR_STYLE,
	SEGMENT_EVENTS
} from 'data'
import { useSettings } from 'context/settings'
import { useAppErrors } from 'hooks/useAppErrors'
import { useBottomScrollListener } from 'react-bottom-scroll-listener'
import type {
	ColorsType,
	DataResponseType,
	FilterObjProps,
	InvoiceType,
	ResponsePageInfo
} from 'typings'
import { getInvoiceList } from 'api/invoice'
import moment from 'moment'
import { UserProfile } from 'components/app/UserProfile'
import { AiOutlineEye } from 'react-icons/ai'
import { BsWhatsapp } from 'react-icons/bs'
import { KababMenu } from 'screen/Invoices/Components/KababMenu'
import { useSnackbar } from 'notistack'
import { useNavigate } from 'react-router-dom'
import { type StatusOptionProps } from 'components/molecules/Status'
import { currencyInput } from 'helpers/currency'
import { formatId, objectPick } from 'helpers'
import NoInvoice from 'assets/invoices/NoInvoice.png'
import NoSearchInvoice from 'assets/invoices/SearchInvoice.png'
import { Abbr } from 'components/atoms/Abbr'
import analytics from 'utils/segment'
import { convertNumber } from 'helpers/number'
import { sendWhatsapp } from 'helpers/share'
import { BulkPrintModal } from './Components/BulkPrintModal'
import { Header } from './Header'
import type { RootState } from 'store/index-reducer'
import { BulkPublishModal } from './Components/BulkPublishModal'

const MAX_INVOICE_FOR_BULK_PRINT = 30

const Container = styled(FlexCol)<{ height: number }>`
	width: 100%;
	height: ${({ height }) => (height ? `${height}px` : '100%')};
	max-height: ${({ height }) => (height ? `${height}px` : '100%')};
	padding: ${LAYOUT_PADDING}px;
`

const StyledTableRow = styled(TableRow)(({ theme }) => ({
	backgroundColor: theme.palette.background.default,

	// hide last border
	'&:last-child td, &:last-child th': {
		border: 0
	}
}))

const StyledButton = styled(FlexRow, {
	shouldForwardProp: props => props !== 'color'
})<Pick<StatusOptionProps, 'color'>>`
	align-items: center;
	background-color: ${({ theme, color }) => theme.palette.colors[color][50]};
	border-radius: 30px;
	padding: 5px 20px;
	width: fit-content;
	flex-wrap: nowrap;

	:hover {
		cursor: not-allowed;
	}
`

const PrintedCapsule = styled(FlexRow)`
	align-items: center;
	background-color: ${({ theme }) => theme.palette.colors.gray['100']};
	color: ${({ theme }) => theme.palette.colors.gray['700']};
	border-radius: 30px;
	padding: 5px 20px;
	width: fit-content;
	flex-wrap: nowrap;

	:hover {
		cursor: not-allowed;
	}
`
const ShortCellHead = styled(TableCell)`
	min-width: 130px;
	max-width: 130px;
	background-color: ${({ theme }) => theme.palette.colors.gray['50']};
	color: ${({ theme }) => theme.palette.colors.gray['500']};
`

const MedCellHead = styled(TableCell)`
	min-width: 180px;
	max-width: 180px;
	background-color: ${({ theme }) => theme.palette.colors.gray['50']};
	color: ${({ theme }) => theme.palette.colors.gray['500']};
`

const ActionCellHead = styled(TableCell)`
	min-width: 120px;
	max-width: 150px;
	background-color: ${({ theme }) => theme.palette.colors.gray['50']};
	color: ${({ theme }) => theme.palette.colors.gray['500']};
`

const UserCellHead = styled(TableCell)`
	min-width: 220px;
	background-color: ${({ theme }) => theme.palette.colors.gray['50']};
	color: ${({ theme }) => theme.palette.colors.gray['500']};
`

const ShortCell = styled(TableCell)`
	min-width: 130px;
	max-width: 130px;
`

const CheckBoxCell = styled(TableCell)`
	min-width: 30px;
	max-width: 30px;
`
const CheckBoxCellHead = styled(TableCell)`
	min-width: 30px;
	max-width: 30px;
	background-color: ${({ theme }) => theme.palette.colors.gray['50']};
	color: ${({ theme }) => theme.palette.colors.gray['500']};
`

const MedCell = styled(TableCell)`
	min-width: 180px;
	max-width: 180px;
`

const ActionCell = styled(TableCell)`
	min-width: 200px;
	max-width: 220px;
`

const UserCell = styled(TableCell)`
	min-width: 220px;
`

const WrapperCapsule = styled(Capsule)`
	padding: 7px;
	border-radius: 6px;
	width: auto;
`
const StyledTableContainer = styled(TableContainer)<{ height: number }>`
	min-height: ${({ height }) => height}px;
	max-height: ${({ height }) => height}px;
	background-color: ${({ theme }) => theme.palette.background.default};
	${SCROLLBAR_STYLE}
`
const Image = styled('img')`
	min-width: 100px;
	max-width: 300px;
`

const Center = styled(FlexCol)`
	position: absolute;
	top: 50%;
	left: 42%;
	align-self: center;
	align-items: center;
	justify-content: center;
`
const StyledPaper = styled(Paper)`
	width: 100%;
	overflow: hidden;
	z-index: 0;

	::-webkit-scrollbar {
		display: none;
	}
	-ms-overflow-style: none;
	scrollbar-width: none;
`

const StyledCheckbox = styled(CheckBox)`
	&.Mui-disabled {
		cursor: not-allowed !important;
		opacity: 0.4;
		background-color: ${({ theme }) => theme.palette.colors.gray['300']};
	}
`

const Invoices = () => {
	const localFilter = localStorage.getItem('invoiceFilter')
	const [filterObj, setFilterObj] = useState<FilterObjProps>(
		localFilter === null ? { status: [] } : JSON.parse(localFilter)
	)
	const [loading, setLoading] = useState<boolean>(true)
	const [bulkPrint, setBulkPrint] = useState<boolean>(false)
	const [bulkPublish, setBulkPublish] = useState<boolean>(false)

	const [dataSource, setDataSource] = useState<InvoiceType[]>([])
	const [pageInfo, setPageInfo] = useState<ResponsePageInfo>({
		totalPages: 1,
		currentPage: 1,
		edgesPerPage: 20
	})
	const [searchKey, setSearchKey] = useState('')
	const [selectAll, setSelectAll] = useState(false)
	const [selectedRows, setSelectedRows] = useState<InvoiceType[]>([])
	const [open, setOpen] = useState<boolean>(false)
	const [openPublishModal, setOpenPublishModal] = useState<boolean>(false)
	const theme = useTheme()
	const { height } = useDimension()
	const { business } = useSettings()
	const navigate = useNavigate()
	const { setAppError } = useAppErrors()
	const { enqueueSnackbar } = useSnackbar()
	const { user } = useSelector((state: RootState) => state.user)
	const scrollRef = useBottomScrollListener(() => fetchInvoiceList(), {
		offset: 10,
		debounce: 200
	})

	const {
		palette: { colors }
	} = theme

	const onSearch = async (searchText: string) => {
		setDataSource([])
		setSearchKey(searchText)
	}

	const getInitialInvoiceList = useCallback(async () => {
		try {
			setLoading(true)
			const res: DataResponseType<InvoiceType> = await getInvoiceList(
				business,
				{
					page: 1,
					limit: pageInfo.edgesPerPage,
					...filterObj,
					searchKey
				}
			)
			setDataSource(res.edges)
			setPageInfo(res.pageInfo)
		} catch (e: any) {
			setAppError(e)
		} finally {
			setLoading(false)
		}
	}, [business, filterObj, pageInfo.edgesPerPage, searchKey, setAppError])

	const fetchInvoiceList = async () => {
		try {
			setLoading(true)
			if (
				!loading &&
				pageInfo?.totalPages &&
				pageInfo?.totalPages >= pageInfo.currentPage + 1
			) {
				const res: DataResponseType<InvoiceType> = await getInvoiceList(
					business,
					{
						page: pageInfo.currentPage + 1,
						limit: pageInfo.edgesPerPage,
						...filterObj,
						searchKey
					}
				)
				setDataSource([...dataSource, ...res.edges])
				setPageInfo(res.pageInfo)
			}
		} catch (e: any) {
			setAppError(e)
		} finally {
			setLoading(false)
		}
	}

	const capForBulkPrint = (maxInvoices: number, invoices: InvoiceType[]) => {
		let selected = invoices
		if (invoices.length > maxInvoices) {
			selected = invoices.slice(0, maxInvoices)

			enqueueSnackbar(
				`Can not select more than ${maxInvoices} invoices for Bulk Print`,
				{
					variant: 'error'
				}
			)
		}
		setSelectedRows(selected)
	}

	const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSelectAll(event.target.checked)
		if (event.target.checked) {
			const allRows = dataSource.filter(row => {
				row.businessInfo = {
					...row.businessInfo,
					email: user?.email || '',
					phone: user?.phone || { countryCode: '', number: '' }
				}
				return row.status != 'draft'
			})
			capForBulkPrint(MAX_INVOICE_FOR_BULK_PRINT, allRows)
		} else {
			setSelectedRows([])
		}
	}

	const handleSelectAllPulishInvoices = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setSelectAll(event.target.checked)
		if (event.target.checked) {
			analytics.track(SEGMENT_EVENTS.INVOICE_CLICK_SELECT_CHECKBOX)
			const allRows = dataSource.filter(row => {
				return row.status != 'publish'
			})
			setSelectedRows(allRows)
		} else {
			setSelectedRows([])
		}
	}

	const handleSelectRow = (
		event: React.ChangeEvent<HTMLInputElement>,
		invoiceSelected: InvoiceType
	) => {
		const invoiceSelectedData = {
			...invoiceSelected,
			businessInfo: {
				...invoiceSelected.businessInfo,
				email: user?.email || '',
				phone: user?.phone || { countryCode: '', number: '' }
			}
		}
		if (event.target.checked) {
			const newRowsSelected = [...selectedRows, invoiceSelectedData]
			if (bulkPrint) {
				capForBulkPrint(MAX_INVOICE_FOR_BULK_PRINT, newRowsSelected)
			} else {
				setSelectedRows(newRowsSelected)
			}
		} else {
			setSelectedRows(prevSelectedRows =>
				prevSelectedRows.filter(row => row.id !== invoiceSelected.id)
			)
		}
	}

	const RenderRow = useCallback(
		({ row }: { row: InvoiceType }) => {
			let selectedOption

			if (row.status && row.status !== 'publish') {
				selectedOption = INVOICE_STATUS_OPTIONS.find(
					p => p.value === row.status
				)
			} else {
				selectedOption = PAYMENT_STATUS_OPTIONS.find(
					p => p.value === row.paymentStatus
				)
			}
			const isPrinted = row?.printingDetails?.isPrinted

			return (
				<StyledTableRow>
					{bulkPrint && (
						<CheckBoxCell>
							<StyledCheckbox
								checked={selectedRows.some(
									invoice => invoice.id == row.id
								)}
								disabled={row.status == 'draft'}
								onChange={event => handleSelectRow(event, row)}
							/>
						</CheckBoxCell>
					)}
					{bulkPublish && (
						<CheckBoxCell>
							<StyledCheckbox
								checked={selectedRows.some(
									invoice => invoice.id == row.id
								)}
								disabled={row.status == 'publish'}
								onChange={event => handleSelectRow(event, row)}
							/>
						</CheckBoxCell>
					)}
					<UserCell>
						<UserProfile contact={row.contact} />
					</UserCell>
					<ShortCell>
						<Typography variant={'caption'}>
							{row.friendlyId ? formatId(row.friendlyId) : '-'}
						</Typography>
					</ShortCell>
					<ShortCell>
						<Typography variant={'caption'}>
							<Abbr
								title={(
									convertNumber(row.dueAmount) ||
									currencyInput(
										`${convertNumber(
											calculateInvoiceTotalAmount(
												GENERATE_INVOICE_DATA(row)
											)
										)}`
									)
								).toString()}
								length={11}
							/>
						</Typography>
					</ShortCell>
					<MedCell>
						<StyledButton
							color={selectedOption?.color as ColorsType}
						>
							<Typography
								variant={'caption'}
								color={
									colors[selectedOption?.color as ColorsType][
										'500'
									]
								}
							>
								{selectedOption?.label}
							</Typography>
						</StyledButton>
					</MedCell>
					<MedCell>
						<Typography variant={'caption'}>
							{moment.utc(row.initiationAt).format(dateFormat)}
						</Typography>
					</MedCell>
					{!bulkPrint && (
						<ActionCell>
							<FlexRow justify="flex-end" align={'center'}>
								<WrapperCapsule
									name=""
									value=""
									renderLeft={<BsWhatsapp color="green" />}
									color="green"
									isSelected
									onItemClick={() => {
										analytics.track(
											SEGMENT_EVENTS.SHARE_WHATSAPP,
											{
												screen: 'invoice_book'
											}
										)
										sendWhatsapp(
											row?.contact.phone.countryCode +
												row?.contact.phone.number
										)
									}}
								/>
								<WrapperCapsule
									name=""
									value=""
									renderLeft={
										<AiOutlineEye
											size={20}
											color="green"
											style={{
												paddingRight: '5px'
											}}
										/>
									}
									renderRight="View"
									color="green"
									isSelected
									onItemClick={() => {
										analytics.track(
											SEGMENT_EVENTS.INVOICE_VIEW,
											{
												screen: 'invoice_book'
											}
										)
										navigate(`/invoice/view/${row?.id}`, {
											state: { data: row }
										})
									}}
								/>
							</FlexRow>
						</ActionCell>
					)}
					{bulkPrint && isPrinted && (
						<MedCell>
							<FlexRow justify="flex-end" align={'center'}>
								<PrintedCapsule>
									<Typography variant={'caption'}>
										{'Printed'}
									</Typography>
								</PrintedCapsule>
							</FlexRow>
						</MedCell>
					)}
					{bulkPrint && !isPrinted && <MedCell />}
				</StyledTableRow>
			)
		},
		[
			navigate,
			bulkPrint,
			bulkPublish,
			selectedRows,
			colors,
			handleSelectRow
		]
	)

	useEffect(() => {
		getInitialInvoiceList()
	}, [getInitialInvoiceList])

	const onFilterSelect = (_filterObj: { [key: string]: string[] }) => {
		localStorage.setItem('invoiceFilter', JSON.stringify(_filterObj))
		const obj = {
			...objectPick(filterObj, 'startDate', 'endDate'),
			..._filterObj
		}

		setFilterObj(obj)
	}

	return (
		<>
			<Header
				dataSource={dataSource}
				filterObj={filterObj}
				setFilterObj={setFilterObj}
				bulkPrint={bulkPrint}
				setBulkPrint={setBulkPrint}
				selectedRows={selectedRows}
				setBulkPrintModalOpen={setOpen}
				onSearch={onSearch}
				onFilterSelect={onFilterSelect}
				bulkPublish={bulkPublish}
				setBulkPublish={setBulkPublish}
				setSelectedRows={setSelectedRows}
				setOpenPublishModal={setOpenPublishModal}
			/>
			<Container height={height - 110}>
				{/* Table work start from here */}
				<StyledPaper>
					<StyledTableContainer
						ref={scrollRef as any}
						height={height - LAYOUT_PADDING * 2 - 110}
					>
						<Table stickyHeader aria-label="sticky table">
							<TableHead>
								<TableRow>
									{bulkPrint && (
										<CheckBoxCellHead>
											<CheckBox
												checked={selectAll}
												onChange={handleSelectAll}
												indeterminate={
													selectedRows.length > 0 &&
													selectedRows.length <
														dataSource.length
												}
											/>
										</CheckBoxCellHead>
									)}
									{bulkPublish && (
										<CheckBoxCellHead>
											<CheckBox
												checked={selectAll}
												onChange={
													handleSelectAllPulishInvoices
												}
												indeterminate={
													selectedRows.length > 0 &&
													selectedRows.length <
														dataSource.length
												}
											/>
										</CheckBoxCellHead>
									)}
									<UserCellHead>
										<Typography
											variant={'body2'}
											fontWeight={'500'}
										>
											Contact
										</Typography>
									</UserCellHead>
									<ShortCellHead>
										<Typography
											variant={'body2'}
											fontWeight={'500'}
										>
											InvoiceId
										</Typography>
									</ShortCellHead>
									<ShortCellHead>
										<Typography
											variant={'body2'}
											fontWeight={'500'}
										>
											Total (PKR)
										</Typography>
									</ShortCellHead>
									<MedCellHead>
										<Typography
											variant={'body2'}
											fontWeight={'500'}
										>
											Status
										</Typography>
									</MedCellHead>
									<MedCellHead>
										<Typography
											variant={'body2'}
											fontWeight={'500'}
										>
											Date created
										</Typography>
									</MedCellHead>
									<ActionCellHead>
										<FlexRow
											justify={'flex-end'}
											onClick={() =>
												analytics.track(
													SEGMENT_EVENTS.INVOICE_KEBAB_MENU
												)
											}
										>
											<KababMenu
												isRecycleBin={false}
												bulkPrint={setBulkPrint}
												bulkPublish={setBulkPublish}
												selectedRows={setSelectedRows}
												onSelect={onFilterSelect}
											/>
										</FlexRow>
									</ActionCellHead>
								</TableRow>
							</TableHead>
							<TableBody>
								{dataSource.length < 1 && (
									<Center>
										<Image
											src={
												searchKey === ''
													? NoInvoice
													: NoSearchInvoice
											}
										/>
										<Gutter spacing={0.3} />
										<Typography variant={'body2'}>
											{searchKey === ''
												? 'Create your first invoice now!'
												: `No invoice found for "${searchKey}"`}
										</Typography>
									</Center>
								)}
								{dataSource.map(row => (
									<RenderRow key={row.id} row={row} />
								))}
							</TableBody>
						</Table>
					</StyledTableContainer>
				</StyledPaper>
				<BulkPrintModal
					open={open}
					onClose={() => setOpen(false)}
					invoices={selectedRows}
				/>
				<BulkPublishModal
					open={openPublishModal}
					onClose={() => setOpenPublishModal(false)}
					invoices={selectedRows}
					onSuccess={() => {
						setBulkPrint(false)
						setBulkPublish(false)
						setSelectedRows([])
						onFilterSelect({ status: [] })
					}}
				/>
			</Container>
		</>
	)
}

export { Invoices }
