import React, { useEffect, useState } from 'react'
import {
	Button,
	InputAdornment,
	styled,
	Typography,
	useTheme
} from '@mui/material'
import { Abbr, Gutter } from 'components/atoms'
import { FlexCol, FlexRow } from 'components/atoms/Flex'
import {
	calculateInvoiceAdvanceAmount,
	calculateInvoiceDiscountAmount,
	calculateInvoiceExtraCharges,
	calculateInvoiceSubTotal,
	calculateInvoiceTaxes,
	CONTACTS_RECORD_PAYMENT_INITIAL_VALUE,
	PaymentMethodOptions,
	PaymentOptions,
	SEGMENT_EVENTS
} from 'data'
import {
	FieldDatePicker,
	FieldNumberInput,
	FieldTextInput
} from 'components/molecules'
import { StyledModal } from 'components/StyledModal'
import { CustomRadioGroup } from 'components/atoms/RadioGroup'

import {
	CloseOutlined,
	NoteOutlined,
	ReceiptOutlined
} from '@mui/icons-material'
import { Formik } from 'formik'
import type {
	IContactProps,
	InvoiceType,
	PaymentMethod,
	PaymentStatus,
	PaymentType
} from 'typings'

import { useAppErrors } from 'hooks'
import * as Yup from 'yup'
import { enqueueSnackbar } from 'notistack'
import { InvoicePicker } from './InvoicePicker'
import { convertNumber, objectOmit } from 'helpers'
import analytics from 'utils/segment'
import { createPayment } from 'api'
import { recordPayment } from 'api/invoice'

const Container = styled(FlexCol)`
	align-self: center;
	justify-self: center;
	padding: 25px 35px;
	min-height: 494px;
	min-width: 500px;
	max-width: 510px;
	width: 100%;
	height: auto;
	background-color: ${({ theme }) => theme.palette.background.default};
	border-radius: 10px;
	margin-top: 20px;
	margin-bottom: 20px;
	overflow-y: scroll;
	justify-content: space-between;
`

const WrapperDiv = styled(FlexCol)`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`

const WrapperFieldRadioGroup = styled(CustomRadioGroup)(({ theme }) => ({
	'& .MuiSvgIcon-root': {
		fontSize: 15,
		color: theme.palette.colors.gray['300']
	},
	'& .MuiFormControlLabel-label': {
		fontSize: 12,
		color: theme.palette.colors.gray['500']
	},
	'& .MuiRadio-root': {
		'&.Mui-checked': {
			'& .MuiSvgIcon-root': {
				color: theme.palette.colors.green['600']
			}
		}
	}
}))

const WrapperButton = styled(Button)(({ theme }) => ({
	backgroundColor: theme.palette.colors.green['500'],
	color: theme.palette.colors.white['900'],
	'&:hover': {
		background: theme.palette.colors.green['700']
	},
	'&:disabled': {
		backgroundColor: theme.palette.colors.gray['200'],
		color: theme.palette.colors.gray['500']
	}
}))

const HeadingText = styled(Typography)`
	color: ${({ theme }) => theme.palette.colors.gray['700']};
	font-size: 12px;
	font-weight: 500;
`

const StyledFieldNumberInput = styled(FieldNumberInput)`
	align-self: center;
	align-items: center;
	max-width: 190px;
	width: 100%;
	input {
		font-size: 14px;
	}
`

const StyledFieldDatePicker = styled(FieldDatePicker)`
	align-self: center;
	align-items: center;
	max-width: 190px;
	width: 100%;
	input {
		font-size: 14px;
	}
`

const NoteButton = styled(Button)`
	justify-content: flex-start;
	border-radius: 8px;
	padding: 8px 14px;
	border: ${({ theme }) => `1px solid ${theme.palette.colors.gray['300']}`};
	color: ${({ theme }) => theme.palette.colors.black['300']};
`

const NoteButtonNoteIcon = styled(NoteOutlined)`
	font-size: 17.5px;
`

const NoteButtonCrossIcon = styled(CloseOutlined)`
	font-size: 14px;
`

const NoteButtonText = styled(Typography)`
	color: ${({ theme }) => theme.palette.colors.gray['700']};
	font-size: 12px;
	font-weight: 500;
`

const ReceiptIcon = styled(ReceiptOutlined)`
	font-size: 17.5px;
`

interface Props {
	contact: IContactProps
	open: boolean
	onClose?: () => void
	onSuccess?: () => void
}

export const RecordContactPaymentModal: React.ComponentType<Props> = ({
	contact,
	open,
	onClose,
	onSuccess
}) => {
	const [currentInvoice, setCurrentInvoice] = useState<
		InvoiceType | undefined
	>()
	const [dueAmount, setDueAmount] = useState<number>(0)

	const { setAppError } = useAppErrors()

	const theme = useTheme()
	const { colors } = theme.palette

	const { id, name, business } = contact

	const onSubmit = (values: any, actions: any) => {
		if (!!currentInvoice && values.type === 'received') {
			onInvoiceSubmit(values, actions)
		} else {
			onContactSubmit(values, actions)
		}
	}

	const onContactSubmit = async (values: any, actions: any) => {
		const finalObj = {
			...values,
			contact: id,
			business,
			note: values.expandNote ? values.note : ''
		}
		try {
			actions.setSubmitting(true)
			await createPayment(finalObj)
			enqueueSnackbar('Payment Recorded Successfully', {
				variant: 'success'
			})
			analytics.track(SEGMENT_EVENTS.LEDGER_MANUAL_PAYMENT_SUCCESSFUL, {
				payment_type: finalObj.type,
				payment_amount: finalObj.amount,
				payment_method_selected: finalObj.method,
				due_date_selected: finalObj.date,
				invoice_id: '',
				note: finalObj.note
			})
			onSuccess?.()
			onClose?.()
		} catch (e: any) {
			setAppError(e, actions)
		} finally {
			actions.setSubmitting(false)
			setCurrentInvoice(undefined)
		}
	}

	const onInvoiceSubmit = async (values: any, actions: any) => {
		if (currentInvoice) {
			try {
				actions.setSubmitting(true)
				const { id, business: businessId } = currentInvoice
				const amountNumber = convertNumber(values.amount)
				const finalObj = {
					paymentDate: values.date,
					paymentMethod: values.method,
					paymentAmount: amountNumber,
					paymentNote: values.expandNote ? values.note : '',
					businessId,
					contactId: contact?.id || '',
					paymentStatus:
						amountNumber === dueAmount
							? ('paid' as PaymentStatus)
							: ('partial' as PaymentStatus)
				}
				await recordPayment(id, finalObj)
				enqueueSnackbar('Payment Recorded Successfully', {
					variant: 'success'
				})
				analytics.track(
					SEGMENT_EVENTS.LEDGER_MANUAL_PAYMENT_SUCCESSFUL,
					{
						payment_type: 'received',
						payment_amount: finalObj.paymentAmount,
						payment_method_selected: finalObj.paymentMethod,
						due_date_selected: finalObj.paymentDate,
						invoice_id: id,
						note: finalObj.paymentNote,
						paymentStatus: finalObj.paymentStatus
					}
				)
				onSuccess?.()
				onClose?.()
			} catch (e: any) {
				setAppError(e, actions)
			} finally {
				actions.setSubmitting(false)
				setCurrentInvoice(undefined)
			}
		}
	}

	const totalAmount = (invoice: InvoiceType) => {
		const totalItemCost = calculateInvoiceSubTotal(invoice)
		const totalExtraCharges = calculateInvoiceExtraCharges(invoice)
		const totalDiscount = calculateInvoiceDiscountAmount(
			objectOmit(invoice, 'id')
		)
		const baseAmt = totalItemCost + totalExtraCharges
		const baseAmtAfterDiscount = baseAmt - totalDiscount

		const advance = calculateInvoiceAdvanceAmount(objectOmit(invoice, 'id'))

		const totalTax = calculateInvoiceTaxes(invoice, baseAmtAfterDiscount)

		return Number((baseAmtAfterDiscount + totalTax - advance).toFixed(2))
	}

	// Talal told you to fix this
	const validationSchema = Yup.object().shape({
		amount: Yup.number()
			.required('Amount is required')
			.positive('Should be greater than 0')
			.typeError('Number Required')
			.test('amount', (amount, values) => {
				const { parent } = values
				if (!!currentInvoice && parent.type === 'received' && amount) {
					if (amount > totalAmount(currentInvoice)) {
						return values.createError({
							path: values.path,
							message: `Cannot be more than ${dueAmount}`
						})
					}
				}
				return true
			}),
		date: Yup.date().required('Date is required'),
		note: Yup.string().when('expandNote', {
			is: true,
			then: Yup.string()
				.required('Min 1 character required')
				.min(1, 'Min 1 character required')
				.max(300, 'Max 300 characters required')
		})
	})

	useEffect(() => {
		if (open) {
			analytics.track(SEGMENT_EVENTS.LEDGER_START_MANUAL_PAYMENT)
		}
	}, [open])

	return (
		<StyledModal open={open} onClose={onClose}>
			<Formik
				initialValues={CONTACTS_RECORD_PAYMENT_INITIAL_VALUE}
				onSubmit={onSubmit}
				validationSchema={validationSchema}
			>
				{({
					values,
					handleSubmit,
					isSubmitting,
					setFieldValue,
					isValid,
					dirty
				}) => (
					<Container>
						<WrapperDiv>
							<Typography
								variant="subtitle1"
								fontWeight={'bold'}
								color={colors.gray['700']}
							>
								Record Payment for{' '}
								<Abbr title={name} length={25} />
							</Typography>
							<Gutter spacing={1} />
							<WrapperFieldRadioGroup
								row
								options={PaymentOptions}
								value={values.type}
								onChange={(
									event: React.ChangeEvent<HTMLInputElement>
								) => {
									setFieldValue(
										'type',
										event?.target.value as PaymentType
									)
								}}
							/>
							<Gutter spacing={0.7} />

							<FlexRow>
								<FlexCol>
									<HeadingText variant="caption">
										Amount
									</HeadingText>
									<StyledFieldNumberInput
										size="small"
										name="amount"
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<Typography>PKR</Typography>
												</InputAdornment>
											)
										}}
									/>
								</FlexCol>
								<Gutter gap={1} />
								<FlexCol>
									<HeadingText variant="caption">
										Payment Date
									</HeadingText>
									<StyledFieldDatePicker
										name={'date'}
										disableFuture
									/>
								</FlexCol>
							</FlexRow>

							<Gutter spacing={1} />

							<FlexRow align="center">
								<HeadingText variant="caption">
									Payment Method
								</HeadingText>
							</FlexRow>
							<WrapperFieldRadioGroup
								row
								options={PaymentMethodOptions}
								value={values.method}
								onChange={(
									event: React.ChangeEvent<HTMLInputElement>
								) => {
									setFieldValue(
										'method',
										event?.target.value as PaymentMethod
									)
								}}
							/>
							<Gutter spacing={0.7} />

							{values.type === 'received' && (
								<>
									{!values.expandLinkInvoice && (
										<NoteButton
											onClick={() =>
												setFieldValue(
													'expandLinkInvoice',
													true
												)
											}
										>
											<ReceiptIcon />
											<Gutter gap={0.3} />
											<NoteButtonText variant={'caption'}>
												Link to an Invoice
											</NoteButtonText>
										</NoteButton>
									)}
									{values.expandLinkInvoice && (
										<>
											<FlexRow align="center">
												<NoteButtonText
													variant={'caption'}
												>
													Link with an Invoice
												</NoteButtonText>
												<Gutter gap={0.3} />
												<NoteButtonCrossIcon
													onClick={() =>
														setFieldValue(
															'expandLinkInvoice',
															false
														)
													}
												/>
											</FlexRow>
											<Gutter spacing={0.3} />
										</>
									)}
									{values.expandLinkInvoice && (
										<InvoicePicker
											contactId={contact.id || ''}
											currentInvoice={currentInvoice}
											setCurrentInvoice={
												setCurrentInvoice
											}
											setDueAmount={setDueAmount}
										/>
									)}
									<Gutter spacing={0.8} />
								</>
							)}
							{/* I don't know what kind of unmanageable code is it.*/}
							{!values.expandNote && (
								<NoteButton
									onClick={() =>
										setFieldValue('expandNote', true)
									}
								>
									<NoteButtonNoteIcon />
									<Gutter gap={0.3} />
									<NoteButtonText variant={'caption'}>
										Add Note
									</NoteButtonText>
								</NoteButton>
							)}
							{values.expandNote && (
								<>
									<FlexRow align="center">
										<NoteButtonText variant={'caption'}>
											Note
										</NoteButtonText>
										<Gutter gap={0.3} />
										<NoteButtonCrossIcon
											onClick={() =>
												setFieldValue(
													'expandNote',
													false
												)
											}
										/>
									</FlexRow>
									<Gutter spacing={0.3} />
								</>
							)}
							{values.expandNote && (
								<FieldTextInput
									multiline
									rows={4}
									name={'note'}
									placeholder={'Enter description'}
									style={{ width: '240px' }}
									InputProps={{
										style: {
											fontSize: 12,
											padding: 14
										}
									}}
									inputProps={{ maxLength: 300 }}
								/>
							)}
						</WrapperDiv>
						<FlexRow justify="flex-end" style={{ width: '100%' }}>
							<Button
								style={{ color: colors.gray['500'] }}
								onClick={() => {
									setCurrentInvoice(undefined)
									onClose?.()
								}}
							>
								Cancel
							</Button>
							<WrapperButton
								onClick={() => handleSubmit()}
								disabled={isSubmitting || !dirty || !isValid}
							>
								Done
							</WrapperButton>
						</FlexRow>
					</Container>
				)}
			</Formik>
		</StyledModal>
	)
}
