import React, { useState } from 'react'
import type { ColorsCodeType, InventoryItemType } from 'typings'
import { FlexCol, FlexRow } from 'components/atoms/Flex'
import { Button, Gutter, SelectInventoryModal } from 'components'
import {
	ClickAwayListener,
	Fade,
	Popper,
	styled,
	Typography,
	useTheme
} from '@mui/material'
import PopupState, { bindPopper, bindToggle } from 'material-ui-popup-state'
import { MdAddCircle, MdOutlineKeyboardArrowDown } from 'react-icons/md'
import { useFormikContext } from 'formik'
import type { CurrentInvoiceProps } from 'screen/Invoices/NewCreate/Invoice'
import { INVOICE_ITEM, SEGMENT_EVENTS } from 'data'
import analytics from 'utils/segment'
import { objectOmit, objectPick } from 'helpers'
import moment from 'moment'

const Wrapper = styled(FlexCol)`
	background-color: ${({ theme }) => theme.palette.background.default};
	border: 1px solid ${({ theme }) => theme.palette.background.paper};
	border-radius: 8px;
	margin-top: 5px;
	box-shadow: 0 12px 16px -4px rgba(16, 24, 40, 0.08),
		0px 4px 6px -2px rgba(16, 24, 40, 0.03);
`

const Option = styled(FlexRow)`
	padding: 8px 14px;
	width: 100%;

	:hover {
		cursor: pointer;
	}
`

const StyledButton = styled(Button)`
	border-radius: var(--1, 8px);
	border: 1px solid ${({ theme }) => theme.palette.colors.gray[300]};
	background: ${({ theme }) => theme.palette.colors.white[100]};
	padding: 0;
	overflow: hidden;

	/* Shadow/xs */
	box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);

	:hover {
		background: ${({ theme }) => theme.palette.colors.gray[50]};
	}
`

const Box = styled(FlexRow)`
	padding: 10px 8px 10px 12px;
	border-right: 1px solid ${({ theme }) => theme.palette.colors.gray[300]};
`

export const CreateInvoicesButton = () => {
	const [isModalOpen, setIsModalOpen] = useState(false)
	const theme = useTheme()
	const {
		palette: { colors }
	} = theme
	const { values, setFieldValue } = useFormikContext<CurrentInvoiceProps>()

	const addItems = () => {
		if (values.items) {
			setFieldValue('items', [
				...values.items,
				{ ...INVOICE_ITEM, id: moment().toISOString() }
			])
		}
	}

	const onInventorySelect = (inventory: InventoryItemType) => {
		const allItems = values.items || []

		setFieldValue('items', [
			...allItems,
			{
				...objectOmit(
					inventory.stock[0],
					'costCurrency',
					'lastStockedAt',
					'sellCurrency',
					'id'
				),
				...objectPick(inventory, 'name', 'stockId'),
				stockId: inventory.stock[0].stockId,
				maxQuantity: inventory.stock[0].quantity,
				quantity: '1',
				id: moment().toISOString()
			}
		])
	}

	const onMultiSelect = (selectedItem: InventoryItemType[]) => {
		const allItems = values.items || []
		setFieldValue('items', [
			...allItems,
			...selectedItem.map((d, i) => {
				return {
					...objectOmit(
						d.stock[0],
						'costCurrency',
						'lastStockedAt',
						'sellCurrency',
						'id'
					),
					...objectPick(d, 'name', 'stockId'),
					stockId: d.stock[0].stockId,
					maxQuantity: d.stock[0].quantity,
					quantity: '1',
					id: moment().toISOString() + i
				}
			})
		])
	}

	const onClickContinue = () => {
		// I'll think about this later
	}

	return (
		<>
			<PopupState
				variant="popper"
				popupId="demo-popup-popper"
				disableAutoFocus={true}
			>
				{popupState => (
					<ClickAwayListener onClickAway={() => popupState.close()}>
						<div>
							<StyledButton
								textColor={
									colors['gray']['900'] as ColorsCodeType
								}
								mode={'primary'}
								buttonColor={
									colors['yellow']['300'] as ColorsCodeType
								}
							>
								<Box
									onClick={() => {
										addItems()
										analytics.track(
											SEGMENT_EVENTS.INVOICE_ADD_ANOTHER_LINE
										)
									}}
								>
									<MdAddCircle
										color={colors.green['600']}
										size={20}
									/>
									<Gutter gap={0.5} />
									<Typography variant={'caption'}>
										Add Another Line
									</Typography>
								</Box>
								<Box {...bindToggle(popupState)}>
									<MdOutlineKeyboardArrowDown />
								</Box>
							</StyledButton>
							<Popper
								{...bindPopper(popupState)}
								transition
								placement="bottom-end"
							>
								{({ TransitionProps }) => (
									<Fade {...TransitionProps} timeout={350}>
										<Wrapper>
											<FlexCol style={{ minWidth: 220 }}>
												<Gutter spacing={0.5} />
												<Option
													align={'center'}
													onClick={() => {
														setIsModalOpen(true)
														analytics.track(
															SEGMENT_EVENTS.INVOICE_ADD_ITEM_IN_BULK
														)
														popupState.close()
													}}
												>
													<Typography
														variant={'body2'}
													>
														Add Items in Bulk
													</Typography>
												</Option>
											</FlexCol>
										</Wrapper>
									</Fade>
								)}
							</Popper>
							<SelectInventoryModal
								selectedInventory={[]}
								open={isModalOpen}
								onClose={() => setIsModalOpen(false)}
								onRowClick={onInventorySelect}
								onMultipleSelect={onMultiSelect}
								onClickContinue={onClickContinue}
								segmentEventCall={() =>
									analytics.track(
										SEGMENT_EVENTS.INVOICE_ADD_ITEM
									)
								}
								multipleSelect={true}
							/>
						</div>
					</ClickAwayListener>
				)}
			</PopupState>
		</>
	)
}
