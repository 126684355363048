import React from 'react'
import * as yup from 'yup'
import { Typography } from '@mui/material'
import {
	FieldImageDropZone,
	FieldTextInput,
	Gutter,
	ImagePlaceholder
} from 'components'
import { useTranslate } from 'hooks'
import { BiUser } from 'react-icons/bi'
import { uploadProfilePic } from 'api'
import analytics from 'utils/segment'
import { SEGMENT_EVENTS } from 'data'

export const FormStep1Schema = yup.object().shape({
	fullName: yup
		.string()
		.trim()
		.test(
			'len',
			'Name length should be between 5 and 18 characters',
			val => {
				const len = (val || '').replace(/\s+/g, '').length
				return len >= 5 && len <= 18
			}
		)
		.matches(/^[a-zA-Z ]*$/, 'Name can only contain alphabetical letters ')
		.required('Required')
})

type Props = {
	name: string
	handleSubmit: any
	values: any
}

export const Form1: React.ComponentType<Props> = ({ name, values }) => {
	const translate = useTranslate()

	return (
		<>
			<FieldImageDropZone
				name={'profilePic'}
				placeholder={ImagePlaceholder}
				icon={BiUser}
				text={values[name]}
				api={uploadProfilePic}
				onClick={() =>
					analytics.track(SEGMENT_EVENTS.PROFILE_UPLOAD_CLICKED)
				}
			/>
			<Gutter spacing={2} />
			<Typography
				variant={'h4'}
				fontWeight="medium"
				sx={{ color: theme => theme.palette.colors.gray['600'] }}
			>
				{translate('onboarding.form.yourName')}
			</Typography>
			<Gutter spacing={1.5} />

			<FieldTextInput
				placeholder={translate('onboarding.form.yourName.placeholder')}
				name={name}
				sx={{ width: '40ch' }}
				onClick={() => {
					analytics.track(
						SEGMENT_EVENTS.ONBOARDING_ENTER_NAME_CLICKED
					)
				}}
			/>
		</>
	)
}
