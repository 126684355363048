import { type ModalProps, styled, Typography } from '@mui/material'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Button, Gutter, SearchBar } from 'components'
import { FlexCol, FlexRow } from 'components/atoms/Flex'
import type {
	DataResponseType,
	InventoryItemType,
	ISettings,
	ResponsePageInfo
} from 'typings'
import type { InventorySortType } from 'screen/index'
import { StyledModal } from 'components/StyledModal'
import { IoIosClose } from 'react-icons/io'
import { useAppErrors, useDimension } from 'hooks'
import AddContact from 'assets/contacts/add_contact.png'
import NoContacts from 'assets/contacts/no_contact_found.png'
import Paper from '@mui/material/Paper'
import { ScrollableInventoryTable } from './ScrollableInventoryTable'
import { getOpenOrderBookerInventoryList } from 'api'

const Container = styled(FlexCol)`
	background-color: ${({ theme }) => theme.palette.background.default};
	height: 80%;
	width: 75%;
	padding: 15px;
	z-index: 0;
	border-radius: 10px;

	@media (max-width: 937px) {
		width: 90%;
		height: 80%;
	}
`

const CloseButtonWrapper = styled(FlexCol)`
	justify-content: center;
	align-items: center;
	height: 30px;
	width: 30px;
	right: 1.75em;

	&:hover {
		cursor: pointer;
		border-radius: 25%;
		background-color: ${({ theme }) => theme.palette.colors.gray['100']};
		display: flex;
	}
`

const Center = styled(FlexCol)`
	position: absolute;
	width: 100%;
	height: 100%;
	align-self: center;
	align-items: center;
	justify-content: center;
`

const Image = styled('img')`
	min-width: 100px;
	max-width: 250px;
`

const StyledPaper = styled(Paper)`
	width: 100%;
	overflow: hidden;
	z-index: 0;
	position: relative;
`

type Props = {
	onRowClick?(inventory: InventoryItemType): void
	selectedInventory?: InventoryItemType[]
	segmentEventCall?(): void
	businessId: string
	OrderFormSettings: ISettings
} & Omit<ModalProps, 'children'>

export const SelectInventoryModal: React.ComponentType<Props> = ({
	onClose,
	businessId,
	onRowClick,
	selectedInventory,
	OrderFormSettings,
	...props
}) => {
	const [loading, setLoading] = useState<boolean>(true)
	const [dataSource, setDataSource] = useState<InventoryItemType[]>([])
	const [transformedData, setTransformedData] = useState<InventoryItemType[]>(
		[]
	)
	const [pageInfo, setPageInfo] = useState<ResponsePageInfo>({
		totalPages: 1,
		currentPage: 1,
		edgesPerPage: 20
	})
	const [searchKey, setSearchKey] = useState('')
	const [sortObj, setSortObj] = useState<InventorySortType>({
		sortOrder: 'asc',
		sortBy: 'name'
	})
	const ref = useRef<HTMLDivElement>(null)
	const { height } = useDimension()
	const { setAppError } = useAppErrors()

	const tableHeight = useMemo(() => {
		if (ref?.current) {
			return ref?.current?.clientHeight - 200
		}

		return height * 0.8 - 240
	}, [height])

	const onSearch = async (searchText: string) => {
		setDataSource([])
		setSearchKey(searchText)
	}

	const getTransformedData = (res: InventoryItemType[]) => {
		const arr: InventoryItemType[] = []
		res.map(m =>
			m.stock.map(d => {
				return arr.push(Object.assign({}, { ...m, stock: [d] }))
			})
		)

		return arr
	}

	const getInitialInventoryList = useCallback(async () => {
		try {
			setLoading(true)
			const res: DataResponseType<InventoryItemType> =
				await getOpenOrderBookerInventoryList(businessId, {
					page: 1,
					limit: pageInfo.edgesPerPage,
					searchKey,
					...sortObj,
					sortOrder: sortObj.sortOrder.toUpperCase() as 'ASC' | 'DESC'
				})

			setDataSource(res.edges)
			setTransformedData(getTransformedData(res.edges))
			setPageInfo(res.pageInfo)
		} catch (e: any) {
			setAppError(e)
		} finally {
			setLoading(false)
		}
	}, [businessId, pageInfo.edgesPerPage, searchKey, setAppError, sortObj])

	const fetchInventory = async () => {
		try {
			setLoading(true)
			if (
				!loading &&
				pageInfo?.totalPages &&
				pageInfo?.totalPages >= pageInfo.currentPage + 1
			) {
				const res: DataResponseType<InventoryItemType> =
					await getOpenOrderBookerInventoryList(businessId, {
						page: pageInfo.currentPage + 1,
						limit: pageInfo.edgesPerPage,
						searchKey,
						...sortObj,
						sortOrder: sortObj.sortOrder.toUpperCase() as
							| 'ASC'
							| 'DESC'
					})

				setTransformedData([
					...transformedData,
					...getTransformedData(res.edges)
				])
				setDataSource([...dataSource, ...res.edges])
				setPageInfo(res.pageInfo)
			}
		} catch (e: any) {
			setAppError(e)
		} finally {
			setLoading(false)
		}
	}

	const onClick = () => {
		setSearchKey('')
		onClose?.({}, 'escapeKeyDown')
	}

	const onSort = useCallback(
		(name: string) => {
			const order =
				sortObj.sortBy === name
					? sortObj.sortOrder === 'asc'
						? 'desc'
						: 'asc'
					: 'asc'

			setSortObj(() => {
				return {
					sortOrder: order,
					sortBy: name
				}
			})
		},
		[sortObj.sortBy, sortObj.sortOrder]
	)

	useEffect(() => {
		getInitialInventoryList()
	}, [getInitialInventoryList])

	return (
		<>
			<StyledModal onClose={onClose} {...props}>
				<Container ref={ref}>
					<FlexRow
						style={{ width: '100%' }}
						justify={'space-between'}
						align={'center'}
					>
						<Typography variant={'body1'} fontWeight={600}>
							Select items
						</Typography>
						<CloseButtonWrapper>
							<IoIosClose size={25} onClick={onClick} />
						</CloseButtonWrapper>
					</FlexRow>
					<Gutter />
					<FlexRow
						align={'center'}
						justify={'space-between'}
						style={{ width: '100%' }}
					>
						<FlexRow align={'center'} style={{ width: '100%' }}>
							<SearchBar
								onSearch={onSearch}
								sx={{ width: '100%', maxWidth: 500 }}
							/>
						</FlexRow>

						<div />
					</FlexRow>
					<Gutter spacing={1.4} />
					<StyledPaper>
						{dataSource.length === 0 && !loading && (
							<Center>
								<Image
									src={
										searchKey === ''
											? AddContact
											: NoContacts
									}
								/>
								<Gutter spacing={0.3} />
								<Typography variant={'body2'}>
									{searchKey === ''
										? 'Your Inventory is empty, add items to it to track your stock better'
										: `No items titled "${searchKey}" found`}
								</Typography>
							</Center>
						)}
						<ScrollableInventoryTable
							tableHeight={tableHeight}
							fetchInventory={fetchInventory}
							selectedInventory={selectedInventory || []}
							onSort={onSort}
							sortObj={sortObj}
							searchKey={searchKey}
							inventoryState={{
								dataSource: dataSource,
								loading: loading,
								transformedData: transformedData
							}}
							onRowClick={onRowClick}
							OrderFormSettings={OrderFormSettings}
						/>
					</StyledPaper>
					<Gutter spacing={1} />
					<FlexRow style={{ width: '100%' }} justify={'flex-end'}>
						<Button
							disabled={!selectedInventory?.length}
							mode={'primary'}
							style={{ padding: '6px 10px' }}
							onClick={onClick}
						>
							Continue
						</Button>
					</FlexRow>
				</Container>
			</StyledModal>
		</>
	)
}
