import React, { useCallback } from 'react'
import {
	TableBody,
	TableCell,
	TableHead,
	TableSortLabel,
	Typography,
	useTheme
} from '@mui/material'
import { styled } from '@mui/system'
import type { InventoryItemType } from 'typings'
import { InventoryRow } from 'components/app/InventoryModal/InventoryRow'
import type { InventorySortType } from 'screen/Inventory/Inventory'

const ProductCell = styled(TableCell, {
	shouldForwardProp: props => props !== 'width'
})<{ width?: number }>`
	min-width: ${({ width }) => (width ? `${width}px` : '300px')};
	background-color: ${({ theme }) => theme.palette.colors.gray['50']};
	color: ${({ theme }) => theme.palette.colors.gray['500']};
`

const MedCell = styled(TableCell)<{ width?: number }>`
	min-width: ${({ width }) => (width ? `${width}px` : '180px')};
	max-width: 200px;
	background-color: ${({ theme }) => theme.palette.colors.gray['50']};
	color: ${({ theme }) => theme.palette.colors.gray['500']};
`

const StyledTableBody = styled(TableBody)`
	height: 100%;
`

export type InventoryTableProps<T> = {
	gridTemplateColumns?: string
	searchKey?: string
	onSort(name: string): void
	sortObj: InventorySortType
	selectedInventory?: T[]
	onRowClick?(inventory: T): void
	onModalClose?(): void
	inventoryState: {
		dataSource: T[]
		transformedData: T[]
		loading: boolean
	}
	multipleSelect?: boolean
}

export const InventoryTable: React.ComponentType<
	InventoryTableProps<InventoryItemType>
> = ({
	sortObj,
	onSort,
	onRowClick,
	selectedInventory = [],
	multipleSelect,
	inventoryState = { dataSource: [], loading: false, transformedData: [] }
}) => {
	const theme = useTheme()
	const { colors } = theme.palette

	const RenderRow = useCallback(
		({ row }: { row: InventoryItemType }) => (
			<InventoryRow
				row={row}
				selectedInventory={selectedInventory}
				onRowClick={onRowClick}
				multipleSelect={multipleSelect}
			/>
		),
		[multipleSelect, onRowClick, selectedInventory]
	)

	return (
		<>
			<TableHead>
				<MedCell align={'left'} width={50} />
				<ProductCell
					id={'name'}
					sortDirection={sortObj.sortOrder}
					onClick={() => onSort('name')}
				>
					<TableSortLabel
						active={sortObj.sortBy === 'name'}
						direction={sortObj.sortOrder}
					>
						<Typography
							variant={'body2'}
							style={{ color: colors.gray['500'] }}
							fontWeight={'500'}
						>
							Item Name
						</Typography>
					</TableSortLabel>
				</ProductCell>
				<MedCell align="left" width={100}>
					<Typography variant={'body2'} fontWeight={'500'}>
						Code
					</Typography>
				</MedCell>
				<MedCell align="left" width={100}>
					<Typography variant={'body2'} fontWeight={'500'}>
						Stock
					</Typography>
				</MedCell>
			</TableHead>
			<StyledTableBody>
				{inventoryState.transformedData.map(row => (
					<RenderRow key={`${row.stock[0].id}`} row={row} />
				))}
			</StyledTableBody>
		</>
	)
}
