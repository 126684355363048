import React, { useCallback, useState } from 'react'
import { type FieldHelperProps, useField } from 'formik'
import { styled } from '@mui/system'
import {
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
	useTheme
} from '@mui/material'
import { FlexCol, FlexRow } from 'components/atoms/Flex'
import { Avatar, Button, ErrorText, FieldNumberInput, Gutter } from 'components'
import { AiOutlinePlus } from 'react-icons/ai'
import type { ICart, InventoryItemType, ISettings } from 'typings'
import { objectOmit, objectPick } from 'helpers/object'
import { RiDeleteBinLine } from 'react-icons/ri'
import { Abbr } from 'components/atoms/Abbr'
import { SCROLLBAR_STYLE, SEGMENT_EVENTS } from 'data'
import analytics from 'utils/segment'
import { SelectInventoryModal } from './Inventory/SelectInventoryModal'
import { currencyInput, getNameChar } from 'helpers'
import { ImageModal } from './Inventory/ImageModal'

const StyledTableRow = styled(TableRow)`
	background-color: ${({ theme }) => theme.palette.background.default};
`

const StyledPaper = styled(Paper)`
	width: 100%;
	overflow: hidden;
	position: relative;
`

const TableWrapper = styled(TableContainer)`
	background-color: ${({ theme }) => theme.palette.background.default};
	min-height: 330px;
	max-height: 330px;
	${SCROLLBAR_STYLE}
`

const StyledButton = styled(Button)`
	position: absolute;
	bottom: 2px;
	left: 2px;
	padding: 5px 10px;
	border-radius: 7px;
	background-color: ${({ theme }) => theme.palette.background.default};
	border: 1px solid ${({ theme }) => theme.palette.colors['gray']['200']};

	:hover {
		background-color: ${({ theme }) => theme.palette.background.paper};
	}
`

const DeleteButton = styled(RiDeleteBinLine)`
	color: ${({ theme }) => theme.palette.colors.rose['500']};

	:hover {
		cursor: pointer;
	}
`

type ItemProps = {
	id: string
	name: string
	code: string
	unit: string
	quantity: number
	perItemCostPrice: number
	perItemSellPrice: number
	stockId: string
	icon?: string
}

type OrderTableProps = {
	name: string
	OrderFormSettings: ISettings
	type?: string
	businessId: string
}

export const OrderFormTable: React.ComponentType<OrderTableProps> = ({
	name,
	OrderFormSettings,
	type,
	businessId
}) => {
	const [isModalOpen, setIsModalOpen] = useState(false)
	const [, meta, helpers] = useField(name)
	const theme = useTheme()
	const [modalOpen, setModalOpen] = useState(false)
	const [selectedImageUrl, setSelectedImageUrl] = useState('')
	const { displaySellingPrice } = OrderFormSettings.settings

	const onInventorySelect = (inventory: InventoryItemType) => {
		const item = inventory.stock[0]
		const exist = meta.value.find(
			(f: ItemProps) => f.stockId === item.stockId
		)

		if (exist) {
			helpers.setValue(
				meta.value.filter((f: ItemProps) => f.stockId !== item.stockId)
			)
		} else {
			if (inventory.stock.length > 0) {
				helpers.setValue([
					...meta.value,
					{
						...objectOmit(
							inventory.stock[0],
							'costCurrency',
							'lastStockedAt',
							'sellCurrency',
							'id'
						),
						...objectPick(inventory, 'name', 'icon'),
						quantity: 0
					}
				])
			}
		}
	}
	const handleClose = () => {
		setModalOpen(false)
	}
	const RenderRow = useCallback(
		({
			row,
			index,
			value,
			helpers,
			setSelectedImageUrl,
			setModalOpen
		}: {
			row: ICart
			index: number
			value: ItemProps[]
			helpers: FieldHelperProps<any>
			setSelectedImageUrl: any
			setModalOpen: any
		}) => {
			const toggleImageSize = (icon: string) => {
				if (icon) {
					setSelectedImageUrl(icon)
					setModalOpen(true)
				}
			}

			return (
				<StyledTableRow hover role="checkbox" tabIndex={-1}>
					<TableCell>
						<FlexRow>
							<FlexRow align={'center'}>
								{row.icon ? (
									<img
										src={row.icon}
										alt={row.name}
										loading="lazy"
										height={30}
										width={30}
										style={{
											objectFit: 'cover',
											cursor: 'pointer'
										}}
										onClick={() =>
											toggleImageSize(row.icon || '')
										}
									/>
								) : (
									<Avatar
										title={getNameChar(row.name)}
										borderWidth={0}
										sx={{
											backgroundColor:
												theme.palette.colors.green[
													'50'
												],
											color: theme.palette.colors.green[
												'900'
											],
											fontSize: 14,
											width: 30,
											height: 30
										}}
									/>
								)}
							</FlexRow>
							<Gutter gap={1} />
							<FlexCol>
								{row.name}
								<Typography
									variant={'caption'}
									color={theme.palette.colors.gray['500']}
								>
									{row.code}
								</Typography>
							</FlexCol>
						</FlexRow>
					</TableCell>
					<TableCell>
						<Abbr title={row.unit} length={10} />
					</TableCell>
					{displaySellingPrice && (
						<TableCell>
							{currencyInput(`${row.perItemSellPrice}`)}
						</TableCell>
					)}
					<TableCell>
						<FieldNumberInput
							name={`items[${index}].quantity`}
							size={'small'}
							sx={{ width: '8ch' }}
						/>
					</TableCell>
					<TableCell width={30}>
						<DeleteButton
							onClick={() => {
								helpers.setValue(
									value.filter(
										(f: ItemProps) =>
											!(
												f.stockId === row.stockId &&
												f.unit === row.unit
											)
									)
								)
							}}
						/>
					</TableCell>
				</StyledTableRow>
			)
		},
		[
			theme.palette.colors.green,
			theme.palette.colors.gray,
			displaySellingPrice
		]
	)

	return (
		<>
			<StyledPaper>
				<TableWrapper>
					<Table stickyHeader aria-label="sticky table">
						<TableHead>
							<StyledTableRow>
								<TableCell>
									<Typography variant={'subtitle2'}>
										Items
									</Typography>
								</TableCell>
								<TableCell>
									<Typography variant={'subtitle2'}>
										Unit
									</Typography>
								</TableCell>
								{displaySellingPrice && (
									<TableCell>
										<Typography variant={'subtitle2'}>
											Selling Price
										</Typography>
									</TableCell>
								)}
								<TableCell>
									<Typography variant={'subtitle2'}>
										Quantity
									</Typography>
								</TableCell>

								<TableCell width={30} />
							</StyledTableRow>
						</TableHead>
						<TableBody>
							{meta.value.map((row: any, index: number) => (
								<RenderRow
									key={`${row.stockId}-${row.unit}`}
									row={row}
									index={index}
									value={meta.value}
									helpers={helpers}
									setModalOpen={setModalOpen}
									setSelectedImageUrl={setSelectedImageUrl}
								/>
							))}
						</TableBody>
					</Table>
				</TableWrapper>
				<StyledButton
					onClick={() => {
						setIsModalOpen(true)
						analytics.track(SEGMENT_EVENTS.ADD_ITEMS, {
							screen: `record_${type}`
						})
					}}
				>
					<FlexRow justify={'center'} align={'center'}>
						<AiOutlinePlus color={'black'} />
						<Gutter gap={0.3} />
						<Typography color={'black'} variant={'caption'}>
							Add Items
						</Typography>
					</FlexRow>
				</StyledButton>
			</StyledPaper>
			{meta.touched && meta.error && meta.value.length === 0 && (
				<ErrorText text={'min 1 item is required'} />
			)}
			<SelectInventoryModal
				OrderFormSettings={OrderFormSettings}
				businessId={businessId}
				selectedInventory={meta.value}
				open={isModalOpen}
				onClose={() => setIsModalOpen(false)}
				onRowClick={onInventorySelect}
			/>
			<ImageModal
				open={modalOpen}
				imageUrl={selectedImageUrl}
				handleClose={handleClose}
			/>
		</>
	)
}
