import React, { useCallback } from 'react'
import { FlexCol, FlexRow } from 'components/atoms/Flex'
import {
	ClickAwayListener,
	Fade,
	Popper,
	type PopperProps,
	styled,
	Typography,
	useTheme
} from '@mui/material'
import PopupState, { bindPopper, bindToggle } from 'material-ui-popup-state'
import { BiDotsVerticalRounded } from 'react-icons/bi'
import { Gutter } from './Gutter'

const Wrapper = styled(FlexCol)`
	background-color: ${({ theme }) => theme.palette.background.default};
	border: 1px solid ${({ theme }) => theme.palette.background.paper};
	border-radius: 8px;
	margin-top: 5px;
	box-shadow: 0 12px 16px -4px rgba(16, 24, 40, 0.08),
		0px 4px 6px -2px rgba(16, 24, 40, 0.03);
`

const VerticalThreeDots = styled(BiDotsVerticalRounded)`
	cursor: pointer;
`

const Col = styled(FlexCol)<{ isSelected?: boolean; disabled?: boolean }>`
	min-height: 50px;
	padding: 0 15px;
	width: 100%;
	background-color: ${({ theme, isSelected, disabled }) =>
		disabled
			? theme.palette.background.paper
			: isSelected
			? theme.palette.colors.blue['100']
			: theme.palette.background.default};

	:hover {
		cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
		background-color: ${({ theme }) => theme.palette.colors.blue['50']};
	}
`

export type KababMenuItemProps = {
	id: string | number
	name: string
	value: string
	disabled?: boolean
	color?: string
	icon?: any
	isSelected?: boolean
	onClick?: () => void
	onSelect?(name: string, value: string): () => void
	iconColor?: string
}

type KababMenuProps = {
	dataSource?: KababMenuItemProps[]
} & Pick<PopperProps, 'placement'>

export const KababMenu: React.ComponentType<KababMenuProps> = ({
	dataSource,
	placement
}) => {
	const theme = useTheme()
	const {
		palette: { colors }
	} = theme

	const Item = useCallback(
		({
			name,
			value,
			onSelect,
			onClick,
			disabled,
			color,
			icon,
			onClose,
			iconColor,
			...props
		}: Omit<KababMenuItemProps, 'id'> & { onClose: () => void }) => {
			const Icon = icon

			return (
				<Col
					justify={'center'}
					style={{ minWidth: 220 }}
					disabled={disabled}
					onClick={() => {
						if (!disabled) {
							onSelect?.(name, value)
							onClose()
							onClick?.()
						}
					}}
					{...props}
				>
					<FlexRow align={'center'}>
						{icon && (
							<FlexRow>
								<Icon
									size={20}
									color={
										iconColor
											? iconColor
											: colors['black']['700']
									}
								/>
								<Gutter gap={0.5} />
							</FlexRow>
						)}
						<Typography variant={'subtitle2'} color={color}>
							{name}
						</Typography>
					</FlexRow>
				</Col>
			)
		},
		[colors]
	)

	return (
		<PopupState
			variant="popper"
			popupId="demo-popup-popper"
			disableAutoFocus
		>
			{popupState => (
				<ClickAwayListener onClickAway={() => popupState.close()}>
					<div>
						<VerticalThreeDots {...bindToggle(popupState)} />
						<Popper
							{...bindPopper(popupState)}
							transition
							placement={placement ? placement : 'bottom-end'}
						>
							{({ TransitionProps }) => (
								<Fade {...TransitionProps} timeout={350}>
									<Wrapper>
										{dataSource?.map(data => (
											<Item
												key={data.id}
												onClose={popupState.close}
												{...data}
											/>
										))}
									</Wrapper>
								</Fade>
							)}
						</Popper>
					</div>
				</ClickAwayListener>
			)}
		</PopupState>
	)
}
