import { IoIosAttach } from 'react-icons/io'
import { Abbr, AlertModal, Gutter } from 'components'
import { Typography, useTheme } from '@mui/material'
import React, { type Dispatch, type SetStateAction, useCallback } from 'react'
import { styled } from '@mui/system'
import { useDropzone } from 'react-dropzone'
import { FlexCol, FlexRow } from 'components/atoms/Flex'
import { AiOutlineDelete } from 'react-icons/ai'
import { uploadInvoiceAttachments } from 'api/invoice'
import * as Axios from 'axios'
import { useAppErrors } from 'hooks'
import { useField } from 'formik'
import { getFileExtension, isImageFileExtension } from 'helpers/mixed'
import { ImageModal } from 'screen/Invoices/View/ImageModal'
import { useSnackbar } from 'notistack'
import { CompressImage } from 'utils/image-compression'
import { TransparentButton } from 'components/atoms/Button/TransparentButton'

const Delete = styled(AiOutlineDelete)`
	:hover {
		cursor: pointer;
	}
`

const TextTypo = styled(Typography)`
	:hover {
		cursor: pointer;
	}
`

type Props = {
	disabled: boolean
	invoiceId: string
	fieldName: string
	uploadError: boolean
	loading: boolean
	setLoading: Dispatch<SetStateAction<boolean>>
	setUploadError: Dispatch<SetStateAction<boolean>>
	setMaxSizeError: Dispatch<SetStateAction<string>>
}

export const UploadComponent: React.ComponentType<Props> = ({
	invoiceId,
	fieldName,
	disabled,
	setMaxSizeError,
	setUploadError,
	uploadError,
	setLoading
}) => {
	const [open, setOpen] = React.useState(false)
	const [openAlert, setOpenAlert] = React.useState<boolean>(false)
	const [, meta, helpers] = useField(fieldName)
	const { setAppError } = useAppErrors()
	const { enqueueSnackbar } = useSnackbar()
	const theme = useTheme()
	const { colors } = theme.palette

	const {
		value: { name, url }
	} = meta

	const onItemClick = () => {
		const extension = getFileExtension(url || '')
		if (
			extension === 'png' ||
			extension === 'jpeg' ||
			extension === 'jpg'
		) {
			setOpen(true)
		} else if (url) {
			window.open(
				`${process.env.REACT_APP_S3_BUCKET}/${url}`,
				'_blank',
				'noopener'
			)
		}
	}

	const onDrop = useCallback(
		async (acceptedFiles: any) => {
			try {
				if (acceptedFiles && acceptedFiles[0]) {
					const selectedFile = acceptedFiles[0]
					const getExt = getFileExtension(selectedFile.name)
					const isImage = isImageFileExtension(getExt)

					if (!isImage && selectedFile.size > 1000000) {
						setMaxSizeError('File size should be less than 1MB')
						return
					}

					setMaxSizeError('')
					setLoading(true)
					helpers.setValue({ url: '', name: selectedFile.name })

					const data = await uploadInvoiceAttachments(
						invoiceId,
						isImage ? 'png' : getExt
					)
					let res: any = undefined

					if (isImage) {
						res = await CompressImage(selectedFile)
					}

					const reader = new FileReader()
					reader.onload = async evt => {
						await Axios.default
							.put(data.signedRequest, evt.target?.result, {
								headers: {
									'Content-Type': isImage
										? res.type
										: selectedFile.type
								}
							})
							.then(() => {
								helpers.setValue({
									url: data.uniqueFileName,
									name: acceptedFiles[0].name
								})
								setUploadError(false)
								enqueueSnackbar('Upload Successful', {
									variant: 'success'
								})
								setLoading(false)
							})
							.catch(() => {
								setUploadError(true)
								enqueueSnackbar('Upload Failed', {
									variant: 'error'
								})
								setLoading(false)
							})
					}

					reader.readAsArrayBuffer(isImage ? res : selectedFile)
				} else {
					setMaxSizeError(
						'File size should be less than 1MB or Unsupported file'
					)
					setLoading(false)
				}
			} catch (error: any) {
				setAppError(error)
				setLoading(false)
			}
		},
		[
			enqueueSnackbar,
			helpers,
			invoiceId,
			setAppError,
			setLoading,
			setMaxSizeError,
			setUploadError
		]
	)

	const { getRootProps, getInputProps } = useDropzone({
		onDrop,
		accept: {
			/*'image/png': ['.png'],
			'image/jpeg': ['.jpeg'],
			'image/jpg': ['.jpg'],
			'image/webp': ['.webp'],
			'image/bmp': ['.bmp'],
			'image/gif': ['.gif'],*/
			'application/pdf': ['.pdf']

			/*'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
				['.docx'],
			'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
				['.xls'],
			'application/vnd.ms-excel': ['.xls']*/
		}
		//maxSize: 1000000
	})

	const textColor = uploadError ? colors['red']['500'] : colors['gray']['700']

	return (
		<>
			<FlexCol>
				{!name && !disabled && (
					<FlexCol>
						<TransparentButton
							{...getRootProps()}
							disabled={disabled}
						>
							<input {...getInputProps()} disabled={disabled} />
							<IoIosAttach />
							<Gutter gap={0.5} />
							<Typography variant={'caption'}>
								Attach document
							</Typography>
						</TransparentButton>
					</FlexCol>
				)}
				{name && (
					<FlexRow align={'center'}>
						<IoIosAttach size={16} color={textColor} />
						<Gutter gap={0.1} />
						<TextTypo
							variant={'caption'}
							color={textColor}
							onClick={() => onItemClick()}
						>
							<Abbr title={name} length={12} />
						</TextTypo>

						<Gutter gap={0.5} />
						{!disabled && (
							<Delete
								size={20}
								onClick={() => {
									setOpenAlert(true)
								}}
								color={colors['red']['500']}
							/>
						)}
					</FlexRow>
				)}
			</FlexCol>
			<ImageModal
				url={`${process.env.REACT_APP_S3_BUCKET}/${url}`}
				open={open}
				onClose={() => setOpen(false)}
			/>
			<AlertModal
				open={openAlert}
				setOpen={setOpenAlert}
				title="Remove Attachment"
				message="Are you sure you want to remove this attachment? This action cannot be undone."
				onDelete={() => {
					helpers.setValue({ url: '', name: '' })
					setMaxSizeError('')
					setUploadError(false)
					setLoading(false)
					setOpenAlert(false)
				}}
			/>
		</>
	)
}
