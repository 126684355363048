import React, { useEffect, useState } from 'react'
import { NotionRenderer } from 'react-notion'
import { notionData } from 'data/notion'
import 'react-notion/src/styles.css'
import 'prismjs/themes/prism-tomorrow.css'
import { styled } from '@mui/material'
import { ErrorBoundary } from 'components'
import { LAYOUT_PADDING, SCROLLBAR_STYLE } from 'data'
import { useDimension } from 'hooks'

const Container = styled('div')<{ height: number }>`
	width: 100%;

	overflow-y: scroll;
	${SCROLLBAR_STYLE};
	height: 100%;
	max-height: ${({ height }) => height}px;
`

const Changelog = () => {
	const [blockMap, setBlockMap] = useState<any>(notionData)
	const { height } = useDimension()

	useEffect(() => {
		async function fetchNotionData() {
			try {
				const response = await fetch(
					'https://notion-api.splitbee.io/v1/page/0d9fc95e88794f16a5150b099fb0e111'
				)
				const data = await response.json()
				setBlockMap(data)
			} catch (error) {
				console.error('Error fetching Notion data:', error)
			}
		}

		fetchNotionData()
	}, [])

	return (
		<ErrorBoundary>
			<Container height={height - LAYOUT_PADDING - 20}>
				<NotionRenderer blockMap={blockMap} fullPage hideHeader />
				<style>
					{`.notion{
						margin-top: -50px;
					}`}
				</style>
			</Container>
		</ErrorBoundary>
	)
}

export { Changelog }
