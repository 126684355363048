import { axios } from 'store'
import type { IStockUnits, ITaxUnit } from 'typings'

type TaxUnit = {
	name: string
	rate: number
}

export const createTaxUnit = async (
	payload: Pick<TaxUnit, 'name' | 'rate'>
) => {
	const url = '/settings/tax-unit'

	try {
		const { data } = await axios.post<ITaxUnit>(url, payload)

		return data
	} catch (error: any) {
		throw error.response.data
	}
}

export const getAllTaxes = async () => {
	const url = '/settings/tax-unit'

	try {
		const { data } = await axios.get<ITaxUnit[]>(url)

		return data
	} catch (error: any) {
		throw error.response.data
	}
}

export const updateTaxUnit = async (
	id: string,
	payload: Pick<ITaxUnit, 'name' | 'rate'>
) => {
	const url = `/settings/tax-unit/${id}`

	try {
		const { data } = await axios.patch<ITaxUnit>(url, payload)

		return data
	} catch (error: any) {
		throw error.response.data
	}
}

export const deleteTaxUnit = async (id: string) => {
	const url = `/settings/tax-unit?ids=${id}`

	try {
		const { data } = await axios.delete<IStockUnits[]>(url)

		return data
	} catch (error: any) {
		throw error.response.data
	}
}
