import React, { useCallback, useEffect, useState } from 'react'
import { getLoanList, loanRePaymentList } from 'api/cbfs'
import { useParams } from 'react-router-dom'
import { styled } from '@mui/system'
import { FlexCol, FlexRow } from 'components/atoms/Flex'
import { Typography, useTheme } from '@mui/material'
import { Button, Gutter } from 'components'
import { currencyInput, pascalCase } from 'helpers'
import type { ColorsCodeType } from 'typings'
import { RepaymentModal } from 'screen/OrderFinancing/LoanView/RepaymentModal'
import moment from 'moment'
import Box from '@mui/material/Box'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { LoanDetails } from 'screen/OrderFinancing/LoanDetails'
import { RepaymentLoan } from 'screen/OrderFinancing/RepaymentLoan/RepaymentLoan'

const Wrapper = styled(FlexCol)`
	padding: 10px;
	width: 100%;
`

const Row = styled(FlexRow)`
	padding: 10px;
	border: 1px solid ${({ theme }) => theme.palette.colors.gray['200']};
	width: 100%;
	border-radius: 8px;
`

const Grid = styled('div')`
	display: grid;
	grid-template-columns: 3fr 1fr;
	row-gap: 10px;
	column-gap: 10px;
	margin-top: 10px;
	width: -webkit-fill-available;
	height: -webkit-fill-available;
`

const Row2 = styled(FlexCol)`
	padding: 10px;
	border-radius: 8px;
	border: 1px solid ${({ theme }) => theme.palette.colors.gray['200']};
`

const Row1 = styled(FlexCol)`
	padding: 10px;
	border-radius: 8px;
	border: 1px solid ${({ theme }) => theme.palette.colors.gray['200']};
`

const PrimaryButtonWrapper = styled(FlexRow)``

const StyledPrimaryButton = styled(Button)`
	min-width: 0;
	min-height: 32px;
	padding: 0 10px;
	border-radius: 5px;
	color: ${({ theme }) => theme.palette.colors.white['900']};
`

function a11yProps(index: number) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`
	}
}

interface TabPanelProps {
	children?: React.ReactNode
	index: number
	value: number
}

function CustomTabPanel(props: TabPanelProps) {
	const { children, value, index, ...other } = props

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box>
					<Gutter spacing={0.5} />
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	)
}

export const LoanView = () => {
	const [dataSource, setDataSource] = useState([])

	const [tab, setTab] = useState(1)
	const [isOpen, setIsOpen] = useState(false)
	const [loanDetail, setLoanDetail] = useState<any>()
	const { id } = useParams()
	const theme = useTheme()
	const { colors } = theme.palette

	const getLoanDetail = useCallback(async () => {
		try {
			const res = await getLoanList(id)
			setLoanDetail(res.loan)
			fetchRepaymentList(res.loan.id)
		} catch (e) {
			//
		}
	}, [id])

	const fetchRepaymentList = async (id: string) => {
		try {
			const res = await loanRePaymentList(id)
			setDataSource(res)
		} catch (e) {
			//
		}
	}

	useEffect(() => {
		getLoanDetail()
	}, [getLoanDetail])

	return (
		<Wrapper>
			{loanDetail && (
				<Row>
					<FlexCol>
						<Typography
							variant={'caption'}
							color={colors.gray['400']}
						>
							Loan Id
						</Typography>
						<Typography fontWeight={600}>
							{loanDetail.display_id}
						</Typography>
					</FlexCol>
					<Gutter gap={1} />
					<FlexCol>
						<Typography
							variant={'caption'}
							color={colors.gray['400']}
						>
							Status
						</Typography>
						<Typography fontWeight={600} color={colors.blue['600']}>
							{pascalCase(loanDetail.status.replaceAll('_', ' '))}
						</Typography>
					</FlexCol>
				</Row>
			)}
			{loanDetail && (
				<Grid>
					<Row1>
						<Box sx={{ width: '100%' }}>
							<Box
								sx={{
									borderBottom: 1,
									borderColor: colors.gray['300']
								}}
							>
								<Tabs
									value={tab}
									onChange={(event, value) => setTab(value)}
									aria-label="basic tabs example"
								>
									<Tab label={'Details'} {...a11yProps(0)} />
									<Tab
										label={'Re-Payment'}
										{...a11yProps(1)}
									/>
								</Tabs>
							</Box>
							<CustomTabPanel value={tab} index={0}>
								<LoanDetails loanDetail={loanDetail} />
							</CustomTabPanel>
							<CustomTabPanel value={tab} index={1}>
								<RepaymentLoan dataSource={dataSource} />
							</CustomTabPanel>
						</Box>
					</Row1>
					<Row2>
						<Typography variant={'body1'} fontWeight={500}>
							Principal Amount: PKR{' '}
							{currencyInput(loanDetail.principal_amount || 0)}{' '}
						</Typography>
						<Typography
							variant={'caption'}
							color={colors.gray['600']}
						>
							Financing Required: PKR{' '}
							{currencyInput(loanDetail.required_financing || 0)}{' '}
						</Typography>
						<Typography
							variant={'caption'}
							color={colors.gray['600']}
						>
							MarkUP/Day: PKR{' '}
							{currencyInput(loanDetail.markup_per_day || 0)}{' '}
						</Typography>
						<Typography
							variant={'caption'}
							color={colors.gray['600']}
						>
							Mark up: PKR {currencyInput(loanDetail.markup || 0)}{' '}
						</Typography>
						<Gutter spacing={0.5} />
						<Typography variant={'body1'} fontWeight={500}>
							Total Payment: PKR{' '}
							{currencyInput(
								loanDetail.principal_amount +
									loanDetail.markup || 0
							)}{' '}
						</Typography>
						<Gutter spacing={2} />
						<StyledPrimaryButton
							buttonColor={
								colors['green']['600'] as ColorsCodeType
							}
							onClick={() => {
								setIsOpen(true)
							}}
							disabled={loanDetail.status === 'under_review'}
						>
							<PrimaryButtonWrapper>
								Record Payment
							</PrimaryButtonWrapper>
						</StyledPrimaryButton>
					</Row2>
				</Grid>
			)}

			{loanDetail && (
				<RepaymentModal
					open={isOpen}
					onClose={() => setIsOpen(false)}
					initialValue={{
						loanId: loanDetail.id,
						amount: loanDetail.principal_amount + loanDetail.markup,
						type: 'repayment',
						paymentDate: moment().utc().format('YYYY-MM-DD'),
						paymentMethod: 'bank_transfer',
						userId: loanDetail.user_id
					}}
					onSuccess={() => {
						fetchRepaymentList(loanDetail.id)
					}}
				/>
			)}
		</Wrapper>
	)
}
