import React, { useCallback, useEffect, useState } from 'react'
import type { DataResponseType, IContactProps, ResponsePageInfo } from 'typings'
import { useAppErrors, useDimension, useTranslate } from 'hooks'
import { useSettings } from 'context/settings'
import { TableContainer, Typography, useTheme } from '@mui/material'
import { useBottomScrollListener } from 'react-bottom-scroll-listener'
import type { InventorySortType } from 'screen/Inventory'
import { LAYOUT_PADDING, SCROLLBAR_STYLE, SEGMENT_EVENTS } from 'data'
import { FlexCol, FlexRow } from 'components/atoms/Flex'
import { type FilterObjProps, Gutter } from 'components'
import analytics from 'utils/segment'
import { styled } from '@mui/system'
import { getContactList } from 'api/contacts'
import Table from '@mui/material/Table'
import Paper from '@mui/material/Paper'
import { ContactsTable } from 'screen/Contact/ContactsTable'
import { CreateContactModal } from 'screen/Contact/AddContact/CreateContactModal'
import { RecordContactPaymentModal } from 'components/app/Payments/RecordContactPaymentModal'
import { Header } from './Header'

const Container = styled(FlexCol)<{ height: number }>`
	width: 100%;
	height: ${({ height }) => (height ? `${height}px` : '100%')};
	max-height: ${({ height }) => (height ? `${height}px` : '100%')};
	padding: ${LAYOUT_PADDING}px;
`

const StyledTableContainer = styled(TableContainer)<{ height: number }>`
	min-height: ${({ height }) => height}px;
	max-height: ${({ height }) => height}px;
	background-color: ${({ theme }) => theme.palette.background.default};
	${SCROLLBAR_STYLE}
`

const Circle = styled('div')<{ color: string }>`
	width: 15px;
	height: 15px;
	background-color: ${({ theme, color }) =>
		color ? color : theme.palette.colors.green['600']};
	border-radius: 8px;
`

export type ContactSortType = { sortOrder: 'asc' | 'desc'; sortBy: string }

const Contacts = () => {
	const [selectedContact, setSelectedContact] = useState<
		IContactProps | undefined
	>(undefined)
	const [open, setOpen] = React.useState(false)
	const [openPaymentModal, setOpenPaymentModal] = React.useState(false)
	const [filterObj, setFilterObj] = useState<FilterObjProps>({})
	const [loading, setLoading] = useState<boolean>(true)
	const [dataSource, setDataSource] = useState<IContactProps[]>([])
	const [pageInfo, setPageInfo] = useState<ResponsePageInfo>({
		totalPages: 1,
		currentPage: 1,
		edgesPerPage: 20
	})
	const [searchKey, setSearchKey] = useState('')
	const [sortObj, setSortObj] = useState<InventorySortType>({
		sortOrder: 'asc',
		sortBy: 'name'
	})
	const { height } = useDimension()
	const { business } = useSettings()
	const { setAppError } = useAppErrors()
	const scrollRef = useBottomScrollListener(() => fetchInventory(), {
		offset: 10,
		debounce: 200
	})
	const translate = useTranslate()
	const theme = useTheme()
	const { colors } = theme.palette

	const getInitialContactList = useCallback(async () => {
		try {
			setLoading(true)
			const res: DataResponseType<IContactProps> = await getContactList({
				business,
				page: 1,
				limit: pageInfo.edgesPerPage,
				searchKey,
				...sortObj,
				sortOrder: sortObj.sortOrder.toUpperCase() as 'ASC' | 'DESC',
				...filterObj
			})
			setDataSource(res.edges)
			setPageInfo(res.pageInfo)
		} catch (e: any) {
			setAppError(e)
		} finally {
			setLoading(false)
		}
	}, [
		business,
		filterObj,
		pageInfo.edgesPerPage,
		searchKey,
		setAppError,
		sortObj
	])

	const fetchInventory = async () => {
		try {
			setLoading(true)
			if (
				!loading &&
				pageInfo?.totalPages &&
				pageInfo?.totalPages >= pageInfo.currentPage + 1
			) {
				const res: DataResponseType<IContactProps> =
					await getContactList({
						business,
						page: pageInfo.currentPage + 1,
						limit: pageInfo.edgesPerPage,
						searchKey,
						...sortObj,
						sortOrder: sortObj.sortOrder.toUpperCase() as
							| 'ASC'
							| 'DESC',
						...filterObj
					})

				setDataSource([...dataSource, ...res.edges])
				setPageInfo(res.pageInfo)
			}
		} catch (e: any) {
			setAppError(e)
		} finally {
			setLoading(false)
		}
	}

	const onSort = useCallback(
		(name: string) => {
			const order =
				sortObj.sortBy === name
					? sortObj.sortOrder === 'asc'
						? 'desc'
						: 'asc'
					: 'asc'

			setSortObj(() => {
				return {
					sortOrder: order,
					sortBy: name
				}
			})
		},
		[sortObj.sortBy, sortObj.sortOrder]
	)

	const createNewClick = () => {
		setOpen(true)
		analytics.track(SEGMENT_EVENTS.CONTACT_ADD_NEW, {
			screen: 'contact_book'
		})
	}

	useEffect(() => {
		getInitialContactList()
	}, [getInitialContactList])

	return (
		<>
			<Header
				numOfContacts={dataSource.length}
				setSearchText={setSearchKey}
				createNewClick={createNewClick}
				filterObj={filterObj}
				setFilterObj={setFilterObj}
			/>
			<Container height={height - 110}>
				<Paper
					sx={{
						width: '100%',
						overflow: 'hidden',
						zIndex: 0
					}}
				>
					<StyledTableContainer
						ref={scrollRef as any}
						height={height - 182}
					>
						<Table stickyHeader aria-label="sticky table">
							<ContactsTable
								onSort={onSort}
								sortObj={sortObj}
								searchKey={searchKey}
								dataSource={dataSource}
								loading={loading}
								onRecordPaymentClick={row => {
									setSelectedContact(row)
									setOpenPaymentModal(true)
								}}
								onSuccess={() => getInitialContactList()}
							/>
						</Table>
					</StyledTableContainer>
				</Paper>
				<Gutter spacing={1} />
				<FlexRow align={'center'}>
					<Gutter gap={1} />
					<Circle color={colors.red['600']} />
					<Gutter gap={0.5} />
					<Typography>{translate('contacts.toPay')}</Typography>
					<Gutter gap={1.5} />
					<Circle color={colors.green['600']} />
					<Gutter gap={0.5} />
					<Typography>{translate('contacts.toReceive')}</Typography>
				</FlexRow>
				<CreateContactModal
					open={open}
					onClose={() => setOpen(false)}
					onSuccess={() => {
						getInitialContactList()
					}}
				/>
				{selectedContact && (
					<RecordContactPaymentModal
						open={openPaymentModal}
						contact={selectedContact}
						onClose={() => setOpenPaymentModal(false)}
						onSuccess={() => getInitialContactList()}
					/>
				)}
			</Container>
		</>
	)
}

export default Contacts
