import type { ColorsType, ProfileFilterDataSourceProps } from 'typings'

type ROLE_COLORS_TYPE = {
	name: string
	color: ColorsType
}[]

export const ROLE_COLORS: ROLE_COLORS_TYPE = [
	{ name: 'Manufacturer', color: 'orange' },
	{ name: 'Retailer', color: 'green' },
	{ name: 'Supplier', color: 'purple' },
	{ name: 'Distributor', color: 'blue' }
]

export const PROFILE_OPTIONS: ProfileFilterDataSourceProps[] = [
	{ id: '1', label: 'Personal Information', value: 'personalInformation' },
	{ id: '2', label: 'Business Management', value: 'businessManagement' }
]
