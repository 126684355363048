import {
	styled,
	TableCell,
	tableCellClasses,
	TableHead,
	TableRow,
	Typography
} from '@mui/material'
import React from 'react'
import { BiDotsVerticalRounded } from 'react-icons/bi'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
	padding: 7,
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: theme.palette.colors.gray['50'],
		color:
			theme.palette.mode === 'dark'
				? theme.palette.colors['gray']['400']
				: theme.palette.colors['gray']['500']
	},
	[`&.${tableCellClasses.body}`]: {
		fontSize: 14
	}
}))

export const VerticalThreeDots = styled(BiDotsVerticalRounded)`
	cursor: pointer;
`

export const PopoverElement = styled(Typography)(({ theme }) => ({
	backgroundColor: theme.palette.background.default,
	padding: '10px 50px 10px 10px',
	cursor: 'pointer',
	'&:hover': {
		backgroundColor: theme.palette.background.paper
	}
}))

export const QuotationTableHeader = () => {
	return (
		<TableHead>
			<TableRow>
				<StyledTableCell style={{ paddingLeft: '5px' }}>
					Contacts
				</StyledTableCell>
				<StyledTableCell align="left">Quotation ID</StyledTableCell>
				<StyledTableCell align="left">Total(PKR)</StyledTableCell>
				<StyledTableCell align="left">Status</StyledTableCell>
				<StyledTableCell align="left">Date created</StyledTableCell>
			</TableRow>
		</TableHead>
	)
}
