import React from 'react'
import type { TypographyProps } from '@mui/material'
import { Typography, useTheme } from '@mui/material'

type LabelProps = TypographyProps

export const Label: React.ComponentType<LabelProps> = ({
	children,
	...rest
}) => {
	const theme = useTheme()
	const {
		palette: { mode, colors }
	} = theme

	return (
		<Typography
			color={mode === 'dark' ? colors.gray['100'] : colors.gray['700']}
			fontWeight={500}
			variant={'caption'}
			{...rest}
		>
			{children}
		</Typography>
	)
}
