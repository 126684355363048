import React from 'react'
import { Typography, styled, useMediaQuery, useTheme } from '@mui/material'
import { LAYOUT_BREAKPOINT_WIDTH } from 'data'
import { FlexRow } from 'components/atoms/Flex'
import { Button, Capsule, Gutter } from 'components'
import type { ColorsCodeType } from 'typings'
import { Add } from '@mui/icons-material'

const NumberCapsule = styled(Capsule)`
	background-color: ${({ theme }) => theme.palette.colors.gray['100']};
	color: ${({ theme }) => theme.palette.colors.black['700']};
	padding: 2px 10px;
	align-content: center;
	font-size: 14px;
	font-weight: 500;
`

const MobileWrapper = styled(FlexRow)`
	@media (max-width: ${LAYOUT_BREAKPOINT_WIDTH}px) {
		display: none;
	}
`

const Wrapper = styled(FlexRow)`
	width: 100%;
	flex-wrap: nowrap;
	align-items: center;
	justify-content: space-between;
	padding: 11px 16px 10px 16px;
	border-bottom: 1px solid ${({ theme }) => theme.palette.colors.gray['200']};

	@media (max-width: ${LAYOUT_BREAKPOINT_WIDTH}px) {
		padding-top: 10px;
		border-top: 1px solid ${({ theme }) => theme.palette.colors.gray['200']};
	}
`

const HeaderText = styled(Typography)`
	margin-bottom: 4px;

	@media (max-width: ${LAYOUT_BREAKPOINT_WIDTH}px) {
		margin-bottom: 0;
	}
`

const StyledButton = styled(Button)`
	min-width: 0;
	min-height: 32px;
	padding: 0px 10px;
	border-radius: 5px;
	color: ${({ theme }) => theme.palette.colors.gray['900']};
`

type HeaderProps = {
	headerText: string
	numOfItems?: number
	onButtonClick?: () => void
	buttonText?: string
}

export const Header: React.FC<HeaderProps> = ({
	headerText,
	numOfItems,
	onButtonClick,
	buttonText
}) => {
	const isMobile = useMediaQuery(`(max-width:${LAYOUT_BREAKPOINT_WIDTH}px)`)

	const theme = useTheme()
	const {
		palette: { colors }
	} = theme

	return (
		<>
			<Wrapper>
				<FlexRow align="center">
					<HeaderText
						variant={isMobile ? 'body2' : 'h6'}
						fontWeight={'medium'}
					>
						{headerText}
					</HeaderText>

					{numOfItems && (
						<NumberCapsule
							name={numOfItems.toString()}
							value="numOfItems"
						/>
					)}
				</FlexRow>

				{buttonText && (
					<StyledButton
						buttonColor={colors['yellow']['300'] as ColorsCodeType}
						onClick={onButtonClick}
					>
						<Add fontSize="small" />
						<MobileWrapper>
							<Gutter gap={0.5} />
							{buttonText}
						</MobileWrapper>
					</StyledButton>
				)}
			</Wrapper>
		</>
	)
}
