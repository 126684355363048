import React from 'react'
import { Typography, useTheme } from '@mui/material'
import type { InventoryItemType } from 'typings'
import { Abbr } from 'components/atoms/Abbr'
import { FlexRow } from 'components/atoms/Flex'
import { Capsule } from 'components'
import { truncate } from 'helpers'
import { styled } from '@mui/system'

const StyledCapsule = styled(Capsule)`
	padding: 2px 10px;
	font-size: 14px;
	background-color: ${({ theme }) => theme.palette.colors['gray']['100']};
`

export const Stock = ({ row }: { row: InventoryItemType }) => {
	const theme = useTheme()
	const item = row.stock[0]

	if (!item) {
		return <></>
	}

	if (item.quantity <= 0)
		return (
			<Typography
				variant={'caption'}
				fontWeight={'bold'}
				color={theme.palette.colors.red['600']}
			>
				Out of Stock
			</Typography>
		)

	return (
		<FlexRow align={'center'}>
			<Typography
				variant={'caption'}
				fontWeight={'bold'}
				display={'inline'}
			>
				<Abbr title={item.quantity.toLocaleString()} length={6} />{' '}
			</Typography>
			<StyledCapsule
				name={truncate(item.unit, 15)}
				value={item.unit}
				color={'gray'}
				isSelected
				disabled
			/>
		</FlexRow>
	)
}
