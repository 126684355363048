import React from 'react'
import { Button, FieldCurrencyInput, FieldTextInput, Gutter } from 'components'
import { FlexRow } from 'components/atoms/Flex'
import { AiOutlinePlus } from 'react-icons/ai'
import { RiDeleteBinLine } from 'react-icons/ri'
import { useFormikContext } from 'formik'
import {
	Paper,
	styled,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography
} from '@mui/material'
import analytics from 'utils/segment'
import { SCROLLBAR_STYLE, SEGMENT_EVENTS } from 'data'

const StyledButton = styled(Button)`
	position: absolute;
	bottom: 2px;
	left: 2px;
	padding: 5px 10px;
	border-radius: 7px;
	background-color: ${({ theme }) => theme.palette.background.default};
	border: 1px solid ${({ theme }) => theme.palette.colors['gray']['200']};

	:hover {
		background-color: ${({ theme }) => theme.palette.background.paper};
	}
`

const StyledTableRow = styled(TableRow)`
	background-color: ${({ theme }) => theme.palette.background.default};
`

const StyledPaper = styled(Paper)`
	width: 100%;
	height: 240px;
	overflow: scroll;
	position: relative;

	${SCROLLBAR_STYLE}
`

const TableWrapper = styled(TableContainer)`
	background-color: ${({ theme }) => theme.palette.background.default};
	max-height: 100%;
	${SCROLLBAR_STYLE}
`

const DeleteButton = styled(RiDeleteBinLine)`
	color: ${({ theme }) => theme.palette.colors.rose['500']};

	:hover {
		cursor: pointer;
	}
`

type Props = {
	values: any
}

export const ExtraCharges: React.ComponentType<Props> = ({ values }) => {
	const { setFieldValue } = useFormikContext()

	const handleDelete = (idx: number, setFieldValue: any) => {
		const newData = values.extraCharges.filter(
			(row: any, i: number) => i != idx
		)
		setFieldValue('extraCharges', newData)
	}

	const handleAddCharges = (values: any, setFieldValue: any) => {
		const charges = values.extraCharges
		charges.push({
			description: '',
			amount: '0'
		})
		setFieldValue('extraCharges', charges)
		analytics.track(SEGMENT_EVENTS.QUO_ADD_CHARGES)
	}

	return (
		<>
			<StyledPaper>
				<TableWrapper>
					<Table stickyHeader aria-label="sticky table">
						<TableHead>
							<StyledTableRow>
								<TableCell>
									<Typography variant={'subtitle2'}>
										Extra Charges (optional)
									</Typography>
								</TableCell>
								<TableCell>
									<Typography variant={'subtitle2'}>
										Amount
									</Typography>
								</TableCell>
								<TableCell width={30} />
							</StyledTableRow>
						</TableHead>
						<TableBody>
							{values.extraCharges.map(
								(row: any, idx: number) => (
									<StyledTableRow hover key={idx}>
										<TableCell>
											<FieldTextInput
												name={`extraCharges.${idx}.description`}
												size="small"
												sx={{ width: '200px' }}
											/>
										</TableCell>
										<TableCell>
											<FieldCurrencyInput
												name={`extraCharges.${idx}.amount`}
												size="small"
												sx={{ width: '110px' }}
											/>
										</TableCell>
										<TableCell>
											<DeleteButton
												color="red"
												onClick={() =>
													handleDelete(
														idx,
														setFieldValue
													)
												}
											/>
										</TableCell>
									</StyledTableRow>
								)
							)}
						</TableBody>
					</Table>
				</TableWrapper>
				<StyledButton
					onClick={() => handleAddCharges(values, setFieldValue)}
				>
					<FlexRow justify={'center'} align={'center'}>
						<AiOutlinePlus color={'black'} />
						<Gutter gap={0.3} />
						<Typography color={'black'} variant={'caption'}>
							Add Charge
						</Typography>
					</FlexRow>
				</StyledButton>
			</StyledPaper>
		</>
	)
}
