export type ColumnVisibilityProps = {
	id: number
	name: string
	value: string
	isSelected: boolean
	width?: number | string
	widthPx?: number
	eventName: string
	sortBy?: string
}

export const INVENTORY_STOCK = {
	code: '',
	unit: '',
	quantity: '',
	perItemCostPrice: '',
	perItemSellPrice: ''
}

export const INVENTORY_INITIAL_STATE = {
	business: '',
	name: '',
	code: '',
	icon: '',
	labels: [],
	comments: '',
	stock: [INVENTORY_STOCK]
}

export const INVENTORY_COLUMN_INITIAL_STATE: ColumnVisibilityProps[] = [
	{
		id: 0,
		name: 'Label',
		value: 'labels',
		isSelected: false,
		width: '2fr',
		widthPx: 250,
		eventName: 'TOGGLE_INV_COL_LABELS'
	},
	{
		id: 2,
		name: 'Stock',
		value: 'stock',
		isSelected: true,
		width: '1.5fr',
		widthPx: 200,
		eventName: 'TOGGLE_INV_COL_IN_STOCK'
	},
	{
		id: 3,
		name: 'Cost Price',
		value: 'perItemCostPrice',
		isSelected: true,
		width: '0.7fr',
		widthPx: 140,
		eventName: 'TOGGLE_INV_COL_COST_PRICE'
	},
	{
		id: 4,
		name: 'Selling Price',
		value: 'perItemSellPrice',
		isSelected: true,
		width: '0.7fr',
		widthPx: 150,
		eventName: 'TOGGLE_INV_COL_SELLING_PRICE'
	},
	{
		id: 5,
		name: 'Date Stocked',
		value: 'date',
		isSelected: true,
		width: '0.8fr',
		widthPx: 170,
		eventName: 'TOGGLE_INV_COL_DATE_STOCKED',
		sortBy: 'date'
	}
]

export const INVENTORY_RESTOCK_OPTIONS = [
	{ value: 'addStock', label: 'Add Stock' },
	{ value: 'removeStock', label: 'Remove Stock' }
]

export const ALPHABET = [
	'A',
	'B',
	'C',
	'D',
	'E',
	'F',
	'G',
	'H',
	'I',
	'J',
	'K',
	'L',
	'M',
	'N',
	'O',
	'P',
	'Q',
	'R',
	'S',
	'T',
	'U',
	'V',
	'W',
	'X',
	'Y',
	'Z'
]
