import { call, fork, put, take } from 'redux-saga/effects'
import {
	setCurrentBusiness,
	setCurrentUser,
	setToken
} from 'store/user/actions'
import { HYDRATE_SESSION } from 'store/user/types'
import { RESTORE_STATE } from './types'
import { restoreStateDone } from 'store/auth/actions'
import { fetchAllBusiness } from 'store/business/actions'
import { getRefreshToken } from 'api'
import type { IUser } from 'typings'
import { getProfileStatus } from 'api/cbfs'
import { setCBFSInfo } from 'store/cbfs/actions'

function* restoreAppState() {
	try {
		const token: string = yield call(
			async () => await localStorage.getItem('token')
		)
		const business: string = yield call(
			async () => await localStorage.getItem('business')
		)

		if (token) {
			yield put(setCurrentBusiness(business))
			yield put(setToken(token))
			const refreshToken: { token: string; user: IUser } = yield call(
				getRefreshToken
			)
			yield call(async () =>
				localStorage.setItem('token', refreshToken.token)
			)

			yield put(setToken(refreshToken.token))
			yield put(setCurrentUser(refreshToken.user))
			yield put(fetchAllBusiness())
			const res: { kyb: any; user: any } = yield call(
				async () => await getProfileStatus(JSON.parse(business))
			)
			yield put(setCBFSInfo(res))
		}
	} catch (e) {
		// Do something here
	} finally {
		yield put(restoreStateDone())
	}
}

function* restoreAppStateWatcher() {
	yield take([RESTORE_STATE])
	yield fork(restoreAppState)
}

// add typings later
function* authWatcher(): any {
	while (true) {
		const { type } = yield take([HYDRATE_SESSION])

		switch (type) {
			case HYDRATE_SESSION:
				// no need to do anything else here
				break
		}
	}
}

export default [restoreAppStateWatcher(), authWatcher()]
