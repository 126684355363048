import { Gutter } from 'components'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
import { setNavigation } from 'store/app/actions'
import { getAContact } from 'api/contacts'
import type { IContactProps } from 'typings/contacts'
import { useAppErrors } from 'hooks/useAppErrors'
import { styled } from '@mui/system'
import { FlexCol } from 'components/atoms/Flex'
import { StickyContainer } from 'react-sticky'
import { Transactions } from 'screen/Contact/Ledger/Transactions'
import { LedgerHeader } from 'screen/Contact/Ledger/LedgerHeader'
import { LAYOUT_PADDING, LEDGER_NAVIGATION } from 'data'
import { Header } from './Header'
import { POSITIVE_NUMBER } from 'helpers'
import type {
	DataResponseType,
	ResponsePageInfo,
	TransactionProps
} from 'typings'
import { getTransactionEntries } from 'api'

const Wrapper = styled(FlexCol)`
	flex: 1;
	width: 100%;
`

export const Ledger = () => {
	const [loading, setLoading] = useState<boolean>(true)
	const [dataSource, setDataSource] = useState<TransactionProps[]>([])
	const [pageInfo, setPageInfo] = useState<ResponsePageInfo>({
		totalPages: 1,
		currentPage: 1,
		edgesPerPage: 20
	})
	const [user, setUser] = useState<IContactProps>()
	const [numberOfTransactions, setNumberOfTransactions] = useState<number>()
	const [searchKey, setSearchKey] = useState<string>('')

	const { contactId } = useParams()
	const { state } = useLocation()
	const dispatch = useDispatch()
	const { setAppError } = useAppErrors()

	const getAContactDetails = useCallback(async () => {
		try {
			const res = await getAContact(contactId || '0')
			dispatch(
				setNavigation(
					LEDGER_NAVIGATION(
						res.name,
						`+${res.phone.countryCode} ${res.phone.number}`,
						res.id || '0'
					)
				)
			)
			setUser({
				...res
			})
		} catch (e: any) {
			setAppError(e)
		}
	}, [contactId, dispatch, setAppError])

	const onSearch = async (searchText: string) => {
		if (searchText.match(POSITIVE_NUMBER)) {
			setSearchKey(searchText)
		}
	}

	const getFinalValue = (arr: TransactionProps[]): TransactionProps[] => {
		return arr.map(item => {
			if (item.metaData) {
				return item
			} else {
				return {
					...item,
					metaData: {}
				}
			}
		})
	}

	const getTransactionList = useCallback(async () => {
		try {
			setLoading(true)
			const res: DataResponseType<TransactionProps> =
				await getTransactionEntries(contactId || '', { searchKey })

			setDataSource(getFinalValue(res.edges))
			setPageInfo(res.pageInfo)
		} catch (e: any) {
			setAppError(e)
		} finally {
			setLoading(false)
		}
	}, [contactId, searchKey, setAppError])

	useEffect(() => {
		getTransactionList()
	}, [getTransactionList])

	// fetch user details
	useEffect(() => {
		if (state?.currentUser) {
			const user = state?.currentUser
			dispatch(
				setNavigation(
					LEDGER_NAVIGATION(
						user.name,
						`+${user.phone.countryCode} ${user.phone.number}`,
						user.id
					)
				)
			)
			setUser(user)
		} else if (contactId) {
			getAContactDetails()
		}
	}, [dispatch, contactId, getAContactDetails, state?.currentUser])

	return (
		<>
			<Header
				numberOfTransactions={numberOfTransactions}
				onSearch={onSearch}
				user={user}
				onSuccess={() => {
					getAContactDetails()
					getTransactionList()
				}}
			/>
			<StickyContainer style={{ width: '100%', padding: LAYOUT_PADDING }}>
				<LedgerHeader user={user} />

				<Wrapper>
					<Gutter />
					<Transactions
						contactId={contactId || '0'}
						setNumberOfTransactions={setNumberOfTransactions}
						searchKey={searchKey}
						dataSource={dataSource}
					/>
				</Wrapper>
			</StickyContainer>
		</>
	)
}
