import React from 'react'
import { Typography, useTheme } from '@mui/material'
import { useSelector } from 'react-redux'
import type { RootState } from 'store/index-reducer'
import styled from '@emotion/styled'
import { FlexRow } from 'components/atoms/Flex'
import { useTranslate } from 'hooks'
import { HighLightMultipleWords } from 'components'
import { truncate } from 'helpers'

const Bar = styled(FlexRow)`
	display: flex;
	width: 100%;
	height: 60px;
	justify-content: space-between;
`

const Text = styled(Typography)`
	display: inline;
	font-weight: bold;
`

export const Header = () => {
	const fullName = useSelector(
		(state: RootState) => state.user.user?.fullName
	)
	const translate = useTranslate()
	const theme = useTheme()
	const {
		palette: { colors, mode }
	} = theme

	return (
		<Bar align={'center'}>
			<HighLightMultipleWords
				textToHighlight={translate('orderCenter.welcome', {
					name: truncate(fullName || '', 20) + '!'
				})}
				searchWords={[`${truncate(fullName || '', 20)}!`]}
				renderHighlighterComponent={text => (
					<Text variant={'h4'} color={'primary'}>
						{text}
					</Text>
				)}
				renderText={text => (
					<Text
						variant={'h4'}
						color={
							mode === 'light'
								? colors.gray['900']
								: colors.white['900']
						}
					>
						{text}
					</Text>
				)}
			/>
		</Bar>
	)
}
