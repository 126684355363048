import chalk from 'chalk'

export const LogSuccess = (...arg: any) => {
	return console.log(chalk.bold.green(...arg))
}

export const LogWarn = (...arg: any) => {
	return console.log(chalk.bold.yellow(...arg))
}

export const LogInfo = (...arg: any) => {
	return console.log(chalk.bold.cyan(...arg))
}

export const LogError = (...arg: any) => {
	return console.log(chalk.bold.redBright(...arg))
}
