import React, { useEffect } from 'react'
import { styled, Typography } from '@mui/material'
import { Button, Gutter } from 'components'
import { FlexCol, FlexRow } from 'components/atoms/Flex'
import { Formik } from 'formik'
import * as yup from 'yup'
import { IoIosClose } from 'react-icons/io'
import { useTranslate } from 'hooks/translate'
import { ItemDetails } from './ItemDetails'
import { ItemUnit } from './ItemUnit'
import { fetchAllStock } from 'store/settings/actions'
import { useDispatch } from 'react-redux'
import {
	addInventoryItem,
	createAnInventoryItem,
	deleteInventoryUnit,
	updateInventory,
	updateInventoryItem
} from 'api'
import type { InventoryItemType, InventoryStockType } from 'typings'
import { useAppErrors } from 'hooks/useAppErrors'
import { useSettings } from 'context/settings'
import { useSnackbar } from 'notistack'
import { detailedDiff } from 'deep-object-diff'
import { fetchAllLabel } from 'store/inventory/actions'
import { objectPick } from 'helpers/object'
import { StyledModal } from 'components/StyledModal'
import analytics from 'utils/segment'
import { ALPHABET, INVENTORY_INITIAL_STATE, SEGMENT_EVENTS } from 'data'
import { STOCK_UNIT_FORMAT } from 'helpers'

const CloseButtonWrapper = styled(FlexCol)`
	justify-content: center;
	align-items: center;
	height: 30px;
	width: 30px;
	right: 1.75em;
	&:hover {
		cursor: pointer;
		border-radius: 25%;
		background-color: ${({ theme }) => theme.palette.colors.gray['100']};
		display: flex;
	}
`

const Wrapper = styled('div')`
	display: grid;
	grid-template-columns: 1fr 1fr;
	column-gap: 10px;
	width: 900px;
`

const Background = styled('div')`
	padding: 30px;
	border-radius: 10px;
	background-color: ${({ theme }) => theme.palette.background.default};
	margin-top: 20px;
	margin-bottom: 20px;
	overflow-y: auto;
`

const FormikWrapper = styled('div')`
	position: relative;
`

const validationSchema = yup.object().shape({
	name: yup
		.string()
		.trim()
		.required('This is a mandatory field and cannot be left empty')
		.test(
			'len',
			'Name length should be between 3 and 300 characters',
			val => {
				const len = (val || '').replace(/\s+/g, '').length
				return len >= 3 && len <= 300
			}
		),
	code: yup
		.string()
		.trim()
		.required('This is a mandatory field and cannot be left empty')
		.test('len', 'Code needs to be 20 character long e.g. 00001', val => {
			if (val && val.match(STOCK_UNIT_FORMAT)) {
				return val.length <= 20
			}
			return false
		}),
	comments: yup.string().max(150),
	stock: yup.array().of(
		yup.object({
			unit: yup
				.string()
				.required('Required')
				.min(1, 'Cannot be less than 1')
				.max(999999999, 'Cannot be more than 999,999,999'),
			quantity: yup
				.string()
				.required('Required')
				.min(1, 'Cannot be less than 1')
				.max(999999, 'Cannot be more than 999,999'),
			perItemCostPrice: yup
				.string()
				.required('Required')
				.max(999999999, 'Cannot be more than 999,999,999'),
			perItemSellPrice: yup
				.string()
				.required('Required')
				.max(999999999, 'Cannot be more than 999,999,999')
		})
	),
	stockImage: yup.string()
})

type AddInventoryModalProps = {
	open: boolean
	inventory?: InventoryItemType
	onClose(): void
	onSuccess?: (inv?: InventoryItemType) => void
}

export const AddInventoryModal: React.ComponentType<AddInventoryModalProps> = ({
	onClose,
	open,
	inventory,
	onSuccess
}) => {
	const translate = useTranslate()
	const dispatch = useDispatch()
	const { setAppError } = useAppErrors()
	const { business } = useSettings()
	const { enqueueSnackbar } = useSnackbar()

	useEffect(() => {
		dispatch(fetchAllStock())
	}, [dispatch])

	const getAlphabet = (i: number) => {
		const repeat = Math.ceil(i / ALPHABET.length)
		if (i < ALPHABET.length - 1) {
			return ALPHABET[i]
		} else {
			return (
				ALPHABET[repeat].repeat(repeat) + ALPHABET[i % ALPHABET.length]
			)
		}
	}

	const onUpdate = async (values: InventoryItemType, actions: any) => {
		try {
			actions.setSubmitting(true)
			let updatedItem: InventoryStockType[] = []

			const stocks = detailedDiff(
				inventory?.stock as InventoryStockType[],
				values.stock
			)

			// only delete
			const deletedStk = inventory?.stock
				.filter(
					entry1 =>
						!values.stock.some(entry2 => entry1.id === entry2.id)
				)
				.map(m => m.id)

			if (deletedStk && deletedStk.length > 0) {
				await deleteInventoryUnit(inventory?.id || '', business, {
					stock: deletedStk as string[]
				})
			}

			// update or add
			for (const key in stocks.updated) {
				updatedItem = updatedItem.concat(
					Object.assign({}, values.stock[key as any], {
						code: values.code + getAlphabet(key as any)
					})
				)
			}

			// only for add
			for (const key in stocks.added) {
				updatedItem = updatedItem.concat(
					Object.assign({}, values.stock[key as any], {
						code: values.code + getAlphabet(key as any)
					})
				)
			}

			const updt = updatedItem.filter(d => d.id !== undefined)
			if (updt.length > 0) {
				for (const x of updt) {
					await updateInventoryItem(
						{ item: x },
						inventory?.id || '',
						x.id || ''
					)
				}
			}

			updatedItem = updatedItem.filter(d => d.id === undefined)

			if (updatedItem.length > 0) {
				await addInventoryItem(
					{ items: updatedItem },
					inventory?.id as string
				)
			}

			await updateInventory(inventory?.id || '', business, {
				...objectPick(values, 'name', 'comments', 'icon'),
				labels: values.labels.map(m => m.trim()).filter(d => d !== '')
			})

			enqueueSnackbar(translate('snackbar.successUpdate'), {
				variant: 'success'
			})
			dispatch(fetchAllLabel())
			onSuccess?.()

			onClose()
		} catch (e: any) {
			setAppError(e, actions)
		}
	}

	const onCreate = async (values: InventoryItemType, actions: any) => {
		const stock = values.stock.map((s, i) => {
			return {
				...s,
				code: s.code ? s.code : values.code + getAlphabet(i)
			}
		})

		try {
			actions.setSubmitting(true)
			const res = await createAnInventoryItem({
				...values,
				business,
				stock,
				labels: values.labels.filter(s => s !== '')
			})
			analytics.track(SEGMENT_EVENTS.INVENTORY_ADD, {
				screen: 'inventory',
				...res
			})
			enqueueSnackbar(translate('snackbar.successCreate'), {
				variant: 'success'
			})
			dispatch(fetchAllLabel())
			onSuccess?.(res)
			onClose()
		} catch (e: any) {
			setAppError(e, actions)
		} finally {
			actions.setSubmitting(false)
		}
	}

	const onSubmit = async (values: InventoryItemType, actions: any) => {
		try {
			actions.setSubmitting(true)
			if (inventory?.id) {
				onUpdate(values, actions)
			} else {
				onCreate(values, actions)
			}
		} catch (e: any) {
			setAppError(e, actions)
		} finally {
			actions.setSubmitting(false)
		}
	}

	const btnLabel = inventory?.id
		? 'Save Changes'
		: translate('inventory.addToInventory')

	return (
		<StyledModal open={open} onClose={onClose}>
			<Background>
				<FlexRow style={{ width: '100%' }} justify={'space-between'}>
					<Typography
						variant="subtitle1"
						fontWeight={500}
						fontFamily="Inter"
					>
						{inventory?.id
							? 'Update your Inventory'
							: translate('inventory.stock.addNewStock')}
					</Typography>
					<CloseButtonWrapper>
						<IoIosClose
							size={25}
							id={'close-button'}
							onClick={() => {
								onClose()
								analytics.track(
									SEGMENT_EVENTS.INVENTORY_CLOSE_ADD_NEW_ITEM,
									{
										screen: 'inventory'
									}
								)
							}}
						/>
					</CloseButtonWrapper>
				</FlexRow>
				<Gutter spacing={2} />
				<FormikWrapper>
					<Formik
						initialValues={inventory || INVENTORY_INITIAL_STATE}
						validationSchema={validationSchema}
						onSubmit={onSubmit}
					>
						{({ handleSubmit, dirty, values, isSubmitting }) => (
							<Wrapper>
								<FlexCol>
									<ItemDetails
										inventoryId={inventory?.id || ''}
									/>
								</FlexCol>
								<FlexCol justify={'space-between'}>
									<ItemUnit stocks={values.stock} />
									<FlexCol
										style={{ width: '100%' }}
										align={'flex-end'}
									>
										<Button
											buttonColor="#FEC84B"
											textColor="black"
											onClick={() => handleSubmit()}
											disabled={!dirty || isSubmitting}
										>
											{btnLabel}
										</Button>
									</FlexCol>
								</FlexCol>
							</Wrapper>
						)}
					</Formik>
				</FormikWrapper>
			</Background>
		</StyledModal>
	)
}
