import React from 'react'
import { Formik } from 'formik'
import * as yup from 'yup'
import {
	Button,
	FieldImageDropZone,
	FieldNumberInput,
	FieldTextInput,
	Gutter,
	ImagePlaceholder
} from 'components'
import { FlexCol } from 'components/atoms/Flex'
import { BiUser } from 'react-icons/bi'
import { styled } from '@mui/material'
import { useTranslate } from 'hooks/translate'
import { useDispatch, useSelector } from 'react-redux'
import type { RootState } from 'store/index-reducer'
import { useAppErrors } from 'hooks/useAppErrors'
import { updateUser, uploadProfilePic } from 'api'
import { setCurrentUser, setLogout } from 'store/user/actions'
import { useSnackbar } from 'notistack'
import type { IUpdateUser } from 'typings'
import analytics from 'utils/segment'
import { SEGMENT_EVENTS } from 'data'
import { objectPick, objectRemoveEmpty } from 'helpers/object'
import { TransparentButton } from 'components/atoms/Button/TransparentButton'

const Container = styled(FlexCol)`
	width: 45%;
	height: 100%;
	align-items: center;
	align-self: center;
`

const SaveProfileContainer = styled('div')`
	display: flex;
	justify-content: center;
	align-items: center;
`

const validationSchema = yup.object().shape({
	fullName: yup.string().trim().required('Required'),
	email: yup.string().email('Invalid email')
})

export const PersonalInformationForm = () => {
	const translate = useTranslate()
	const { user } = useSelector((state: RootState) => state.user)
	const { setAppError } = useAppErrors()
	const dispatch = useDispatch()
	const { enqueueSnackbar } = useSnackbar()

	const onSignOut = () => {
		localStorage.clear()
		dispatch(setLogout())
	}

	const onSubmit = async (values: IUpdateUser, actions: any) => {
		try {
			actions.setSubmitting(true)
			const res = await updateUser(objectRemoveEmpty(values, ['email']))
			dispatch(setCurrentUser(res))
			enqueueSnackbar('User Successfully updated', {
				variant: 'success'
			})
			actions.resetForm({ values: { ...res, phone: res.phone.number } })
		} catch (e: any) {
			setAppError(e, actions)
		} finally {
			actions.setSubmitting(false)
		}
	}

	return (
		<Container>
			<Formik
				initialValues={{
					...objectPick(user, 'fullName', 'profilePic', 'email'),
					...{ phone: user?.phone.number }
				}}
				onSubmit={async (values, actions) => {
					await onSubmit(values, actions)
				}}
				validationSchema={validationSchema}
			>
				{({ handleSubmit, dirty, isSubmitting, values }) => (
					<>
						<FieldImageDropZone
							name="profilePic"
							placeholder={ImagePlaceholder}
							icon={BiUser}
							text={values['fullName']}
							api={uploadProfilePic}
							onClick={() =>
								analytics.track(
									SEGMENT_EVENTS.PROFILE_UPLOAD_CLICKED
								)
							}
						/>
						<Gutter spacing={3} />

						<FieldTextInput
							placeholder="Enter Name"
							name={'fullName'}
							title="Full Name"
							style={{ width: '35ch' }}
							size="small"
						/>
						<Gutter />

						<FieldNumberInput
							title="Mobile"
							placeholder={'03001245679'}
							name={'phone'}
							style={{ width: '35ch' }}
							size="small"
							disabled
						/>
						<Gutter />

						<FieldTextInput
							title="Email"
							placeholder="Enter Email"
							name={'email'}
							style={{ width: '35ch' }}
							size="small"
						/>

						<Gutter spacing={2} />
						<SaveProfileContainer>
							<TransparentButton
								onClick={() => {
									onSignOut()
								}}
								variant={'contained'}
							>
								{translate('app.signOut')}
							</TransparentButton>
							<Gutter gap={0.5} />
							<Button
								onClick={() => {
									handleSubmit()
									analytics.track(
										SEGMENT_EVENTS.PRO_EDIT_COMPLETED,
										{
											email_entered: values.email,
											name: values.fullName
										}
									)
								}}
								variant={'contained'}
								disabled={!dirty || isSubmitting}
								buttonColor="#FEC84B"
								textColor="#101828"
							>
								{translate(
									'profile.personalInformation.saveProfile'
								)}
							</Button>
						</SaveProfileContainer>
					</>
				)}
			</Formik>
		</Container>
	)
}
