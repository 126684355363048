import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { styled } from '@mui/system'
import { FlexCol, FlexRow } from 'components/atoms/Flex'
import { Button, Drawer, Typography, useMediaQuery } from '@mui/material'
import { Gutter, SearchBar } from 'components'
import {
	LAYOUT_BREAKPOINT_WIDTH,
	SEGMENT_EVENTS,
	USER_PERMISSION_NAVIGATION
} from 'data'
import { AiOutlinePlus } from 'react-icons/ai'
import type {
	DataResponseType,
	InviteUserProps,
	ResponsePageInfo
} from 'typings'
import { UserRolesTable } from 'screen/Settting/UserRoles/UserRolesTable'
import { RolesFilter } from 'screen/Settting/UserRoles/RolesFilter'
import { USER_FILTER_OPTIONS } from 'data/settings'
import { motion } from 'framer-motion'
import { Search } from '@mui/icons-material'
import { AddUser } from 'screen/Settting/UserRoles/InviteUser'
import { getUserList } from 'api/inviteUser'
import { useSettings } from 'context/settings'
import { useAppErrors } from 'hooks'
import { useDispatch, useSelector } from 'react-redux'
import type { RootState } from 'store/index-reducer'
import analytics from 'utils/segment'
import { setNavigation } from 'store/app/actions'

const Wrapper = styled(FlexCol)`
	width: 100%;
	height: 100%;
`

const StyledSearchBar = styled(SearchBar)`
	width: 100%;
	.MuiOutlinedInput-root {
		border-radius: 5px;
		padding-right: 6px;
	}
`

const SearchIcon = styled(Search)`
	font-size: 20px;
	color: ${({ theme }) => theme.palette.colors.gray['700']};
`

const StyledButton = styled(Button)`
	background-color: ${({ theme }) => theme.palette.background.default};
	border: 1px solid ${({ theme }) => theme.palette.colors.gray[300]};
	align-items: center;
	min-width: 0;
	min-height: 32px;
	border-radius: 5px;
	padding: 0 9px;
`

const Header = styled(FlexRow)`
	justify-content: space-between;
	max-height: 56px;
	padding: 8px;
	width: 100%;
	height: 100%;
	align-items: center;
	border-bottom: 1px solid ${({ theme }) => theme.palette.colors.gray['200']};
`

const SaveButton = styled(Button)`
	background-color: ${({ theme }) => theme.palette.colors.yellow['300']};
	color: ${({ theme }) => theme.palette.colors.gray['900']};
	border-radius: 8px;
	min-width: 38px;
	min-height: 32px;
	padding: 0 12px;

	:hover {
		background-color: ${({ theme }) => theme.palette.colors.yellow['200']};
	}
`

const Text = styled(Typography)`
	@media (max-width: ${LAYOUT_BREAKPOINT_WIDTH}px) {
		display: none;
	}
`

type FilterObjProps = { [key: string]: string[] }

export const Roles = () => {
	const [state, setState] = React.useState(false)
	const [isActive, setIsActive] = useState<boolean>(false)
	const [loading, setLoading] = useState<boolean>(false)
	const [searchKey, setSearchKey] = useState('')
	const [dataSource, setDataSource] = useState<InviteUserProps[]>([])
	const [pageInfo, setPageInfo] = useState<ResponsePageInfo>({
		totalPages: 1,
		currentPage: 1,
		edgesPerPage: 20
	})
	const [filterObj, setFilterObj] = useState<FilterObjProps>({
		deleted: ['false']
	})
	const isMobile = useMediaQuery(`(max-width:${LAYOUT_BREAKPOINT_WIDTH}px)`)
	const { business } = useSettings()
	const { setAppError } = useAppErrors()
	const dispatch = useDispatch()
	const { user } = useSelector((state: RootState) => state.user)
	const currentRole = useMemo(() => {
		if (user?.userRoles && user?.userRoles.length > 0)
			return user?.userRoles.find(f => f.business === business)?.role
		return 'admin'
	}, [user?.userRoles, business])

	const SearchAnimation = {
		width: isMobile ? '100%' : '30ch',
		transition: {
			duration: 0.2,
			type: 'spring',
			damping: 14
		}
	}

	const { deleted } = filterObj

	const getInitialInvoiceList = useCallback(async () => {
		setDataSource([])
		try {
			setLoading(true)
			const res: DataResponseType<InviteUserProps> = await getUserList({
				page: 1,
				business,
				limit: pageInfo.edgesPerPage,
				...filterObj,
				searchKey
			})
			setDataSource(res.edges)
			setPageInfo(res.pageInfo)
		} catch (e: any) {
			setAppError(e)
		} finally {
			setLoading(false)
		}
	}, [business, filterObj, pageInfo.edgesPerPage, searchKey, setAppError])

	const fetchUserList = async () => {
		try {
			setLoading(true)
			if (
				!loading &&
				pageInfo?.totalPages &&
				pageInfo?.totalPages >= pageInfo.currentPage + 1
			) {
				const res: DataResponseType<InviteUserProps> =
					await getUserList({
						page: pageInfo.currentPage + 1,
						limit: pageInfo.edgesPerPage,
						business,
						...filterObj,
						searchKey
					})
				setDataSource([...dataSource, ...res.edges])
				setPageInfo(res.pageInfo)
			}
		} catch (e: any) {
			setAppError(e)
		} finally {
			setLoading(false)
		}
	}

	const MotionAnimation = {
		opacity: 1,
		transition: {
			duration: 0.5
		}
	}

	const onSearch = async (searchText: string) => {
		setDataSource([])
		setSearchKey(searchText)
	}

	const dispatchTitle = useCallback(() => {
		dispatch(setNavigation(USER_PERMISSION_NAVIGATION()))
	}, [dispatch])

	useEffect(() => {
		dispatchTitle()
		getInitialInvoiceList()
	}, [getInitialInvoiceList])

	return (
		<Wrapper>
			<Header>
				<motion.div
					initial={{ opacity: 0 }}
					animate={MotionAnimation}
					exit={{ opacity: 0 }}
					hidden={isMobile && isActive}
				>
					<RolesFilter
						dataSource={USER_FILTER_OPTIONS}
						filterObj={filterObj}
						total={dataSource.length}
						onSelect={item => {
							setFilterObj(item)
						}}
					/>
				</motion.div>
				<FlexRow
					align={'center'}
					sx={{ width: isMobile && isActive ? '100%' : null }}
				>
					{isMobile && !isActive ? (
						<StyledButton
							onClick={() => {
								setIsActive(true)
							}}
						>
							<SearchIcon />
						</StyledButton>
					) : (
						<motion.div animate={SearchAnimation}>
							<StyledSearchBar
								onSearch={onSearch}
								onFocus={() => setIsActive(true)}
								onBlur={text => {
									setIsActive(text !== '')
								}}
								autoFocus={isMobile}
								placeholder="Search using Contact Name"
								inputProps={{
									style: {
										padding: '4.5px 0 4.5px 14px'
									}
								}}
							/>
						</motion.div>
					)}
					<Gutter gap={0.5} />

					<motion.div
						initial={{ opacity: 0 }}
						animate={MotionAnimation}
						exit={{ opacity: 0 }}
						hidden={isMobile && isActive}
					>
						<SaveButton
							onClick={() => {
								analytics.track(
									SEGMENT_EVENTS.SETTINGS_ADD_NEW_USER
								)
								setState(true)
							}}
						>
							<AiOutlinePlus />
							<Gutter gap={0.5} />
							<Text variant={'caption'} fontWeight={600}>
								Add User
							</Text>
						</SaveButton>
					</motion.div>
				</FlexRow>
			</Header>
			<Gutter />

			<UserRolesTable
				searchKey={searchKey}
				dataSource={dataSource}
				isActive={JSON.stringify(deleted) === JSON.stringify(['false'])}
				fetchUserList={fetchUserList}
				onSuccess={() => {
					getInitialInvoiceList()
				}}
				role={currentRole}
			/>

			<React.Fragment key={'right'}>
				<Drawer
					anchor={'right'}
					open={state}
					onClose={() => setState(!state)}
				>
					<AddUser
						onClose={() => setState(!state)}
						onSuccess={() => {
							getInitialInvoiceList()
						}}
					/>
				</Drawer>
			</React.Fragment>
		</Wrapper>
	)
}
