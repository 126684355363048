import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { useSelector } from 'react-redux'
import type { RootState } from 'store/index-reducer'

type PrivateRoutesProps = {
	isRestoringState: boolean
}

export const PrivateRoute: React.ComponentType<PrivateRoutesProps> = ({
	isRestoringState
}) => {
	const { token } = useSelector((state: RootState) => state.user)
	const { loading, businesses } = useSelector(
		(state: RootState) => state.business
	)

	if (isRestoringState || loading) {
		return <></>
	}

	if (businesses.length === 0 && token && !loading) {
		return <Navigate to={'/login'} />
	}

	return token ? <Outlet /> : <Navigate to={'/login'} />
}
