import React, { useState } from 'react'
import { Typography, styled, useMediaQuery, useTheme } from '@mui/material'
import {
	LAYOUT_BREAKPOINT_WIDTH,
	PRIMARY_BUTTON_BREAKPOINT_WIDTH,
	SEGMENT_EVENTS
} from 'data'
import { FlexRow } from 'components/atoms/Flex'
import { motion } from 'framer-motion'
import { Button, Capsule, Gutter, SearchBar } from 'components'
import type { ColorsCodeType } from 'typings'
import analytics from 'utils/segment'
import { Add, Search } from '@mui/icons-material'
import {
	InventoryFilterButton,
	type InventoryFilterObjProps
} from './InventoryFilters'

const NumberCapsule = styled(Capsule)`
	background-color: ${({ theme }) => theme.palette.colors.gray['100']};
	color: ${({ theme }) => theme.palette.colors.black['700']};
	padding: 2px 10px;
	align-content: center;
	font-size: 14px;
	font-weight: 500;
`

const PrimaryButtonWrapper = styled(FlexRow)`
	@media (max-width: ${PRIMARY_BUTTON_BREAKPOINT_WIDTH}px) {
		display: none;
	}
`

const StyledButton = styled(Button)`
	background-color: ${({ theme }) => theme.palette.background.default};
	border: 1px solid ${({ theme }) => theme.palette.colors.gray[300]};
	align-items: center;
	min-width: 0;
	min-height: 32px;
	border-radius: 5px;
	padding: 0px 9px;
`

const StyledPrimaryButton = styled(Button)`
	min-width: 0;
	min-height: 32px;
	padding: 0px 10px;
	border-radius: 5px;
	color: ${({ theme }) => theme.palette.colors.gray['900']};
`

const Wrapper = styled(FlexRow)`
	width: 100%;
	flex-wrap: nowrap;
	align-items: center;
	justify-content: space-between;
	padding: 11px 16px 10px 16px;
	border-bottom: 1px solid ${({ theme }) => theme.palette.colors.gray['200']};
`

const StyledSearchBar = styled(SearchBar)`
	width: 100%;
	.MuiOutlinedInput-root {
		border-radius: 5px;
		padding-right: 6px;
	}
`

const SearchIcon = styled(Search)`
	font-size: 20px;
	color: ${({ theme }) => theme.palette.colors.gray['700']};
`

const HeaderText = styled(Typography)`
	margin-bottom: 4px;

	@media (max-width: ${LAYOUT_BREAKPOINT_WIDTH}px) {
		margin-bottom: 0;
	}
`

type HeaderProps = {
	numOfItems: number
	onSearch: (text: string) => void
	createNewClick: () => void
	filterObj: InventoryFilterObjProps
	setFilterObj: React.Dispatch<React.SetStateAction<InventoryFilterObjProps>>
}

export const Header: React.FC<HeaderProps> = ({
	numOfItems,
	onSearch,
	createNewClick,
	filterObj,
	setFilterObj
}) => {
	const [isActive, setIsActive] = useState<boolean>(false)

	const isMobile = useMediaQuery(`(max-width:${LAYOUT_BREAKPOINT_WIDTH}px)`)

	const theme = useTheme()
	const {
		palette: { colors }
	} = theme

	const MotionAnimation = {
		opacity: 1,
		transition: {
			duration: 0.5
		}
	}

	const SearchAnimation = {
		width: isMobile ? '100%' : '30ch',
		transition: {
			duration: 0.2,
			type: 'spring',
			damping: 14
		}
	}

	const searchButtonClicked = () => {
		setIsActive(true)
	}

	return (
		<Wrapper>
			<motion.div
				initial={{ opacity: 0 }}
				animate={MotionAnimation}
				exit={{ opacity: 0 }}
				hidden={isMobile && isActive}
			>
				<FlexRow align="center">
					<HeaderText
						variant={isMobile ? 'body2' : 'h6'}
						fontWeight={'medium'}
					>
						All Items
					</HeaderText>
					<NumberCapsule
						name={numOfItems.toString()}
						value="numOfItems"
					/>
				</FlexRow>
			</motion.div>

			<FlexRow
				align={'center'}
				sx={{ width: isMobile && isActive ? '100%' : null }}
			>
				{isMobile && !isActive ? (
					<StyledButton onClick={searchButtonClicked}>
						<SearchIcon />
					</StyledButton>
				) : (
					<motion.div animate={SearchAnimation}>
						<StyledSearchBar
							onSearch={onSearch}
							onFocus={() => setIsActive(true)}
							onBlur={text => {
								analytics.track(SEGMENT_EVENTS.QUO_SEARCH)
								setIsActive(text !== '')
							}}
							autoFocus={isMobile}
							placeholder="Search using Item Name"
							inputProps={{
								style: {
									padding: '4.5px 0 4.5px 14px'
								}
							}}
						/>
					</motion.div>
				)}

				<motion.div
					initial={{ opacity: 0 }}
					animate={MotionAnimation}
					exit={{ opacity: 0 }}
					hidden={isMobile && isActive}
				>
					<FlexRow align="center">
						<Gutter gap={isMobile ? 0.5 : 1} />
						<InventoryFilterButton
							filterObj={filterObj}
							onSelect={val => setFilterObj(val)}
						/>

						<Gutter gap={isMobile ? 0.5 : 1} />
						<StyledPrimaryButton
							buttonColor={colors.yellow['300'] as ColorsCodeType}
							onClick={createNewClick}
						>
							<Add fontSize="small" />
							<PrimaryButtonWrapper>
								<Gutter gap={0.5} />
								{'Add Item'}
							</PrimaryButtonWrapper>
						</StyledPrimaryButton>
					</FlexRow>
				</motion.div>
			</FlexRow>
		</Wrapper>
	)
}
