import * as React from 'react'
import { useCallback } from 'react'
import Autocomplete, {
	type AutocompleteProps
} from '@mui/material/Autocomplete'
import { useField } from 'formik'
import { FlexRow } from 'components/atoms/Flex'
import { Capsule, ErrorText, TextInput } from 'components'
import { MenuItem, styled, Typography } from '@mui/material'
import type { InventoryStockType } from 'typings'
import type { IStockUnits } from 'typings/setting/stockUnit'
import { Abbr } from 'components/atoms/Abbr'

const StyledCapsule = styled(Capsule)`
	font-size: 0.6rem;
	border-radius: 0.5em;
`

type RenderMenuItem = {
	onClick?(event: React.MouseEvent<HTMLLIElement>): void
} & Pick<IStockUnits, 'name'>

export type FieldUnitProps = {
	name: string
	stocks: InventoryStockType[]
	dataSource: { id?: number | string; name: string; value: string }[]
} & Omit<AutocompleteProps<any, any, any, any>, 'renderInput' | 'options'>

export const FieldUnit: React.ComponentType<FieldUnitProps> = ({
	name,
	dataSource,
	stocks,
	...props
}) => {
	const [, meta, helpers] = useField(name)

	const error = meta.touched && meta.error

	const RenderMenuItem = useCallback(
		({ name, onClick }: RenderMenuItem) => {
			const selectedStock = stocks.filter(d => d.unit === name).length > 0

			return (
				<MenuItem
					onClick={e => {
						helpers.setValue(name)
						onClick?.(e)
					}}
					disabled={selectedStock}
				>
					<FlexRow align="center" justify="space-around">
						<Typography variant="subtitle1" fontWeight={500}>
							<Abbr title={name} length={20} />
						</Typography>
						{selectedStock && (
							<StyledCapsule
								name="In Use"
								value="In Use"
								isSelected
								color="blue"
							/>
						)}
					</FlexRow>
				</MenuItem>
			)
		},
		[helpers, stocks]
	)

	return (
		<>
			<Autocomplete
				sx={{ width: 300 }}
				options={dataSource}
				autoHighlight
				value={{ name: meta.value, value: meta.value }}
				isOptionEqualToValue={(option, value) =>
					option.name === value.name
				}
				getOptionLabel={option => option.name}
				disableClearable
				renderInput={params => (
					<TextInput
						{...params}
						size={'small'}
						error={!!error}
						inputProps={{
							...params.inputProps,
							autoComplete: 'new-password' // disable autocomplete and autofill
						}}
					/>
				)}
				renderOption={(props, option) => (
					<RenderMenuItem
						key={option.name}
						name={option.name}
						onClick={props.onClick}
					/>
				)}
				{...props}
			/>
			<ErrorText text={error} />
		</>
	)
}
