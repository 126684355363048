import { TableCell, TableRow, Typography } from '@mui/material'
import * as React from 'react'
import { styled } from '@mui/system'

const Cell = styled(TableCell)`
	background-color: ${({ theme }) => theme.palette.colors.gray['50']};
	color: ${({ theme }) => theme.palette.colors.gray['500']};
	padding: 7px 7px 7px 24px;
	flex-wrap: nowrap;
`

const Text = styled(Typography)`
	color: ${({ theme }) => theme.palette.colors.gray['500']};
	font-weight: 500;
	white-space: nowrap;
`

export const MTableHeader = () => {
	return (
		<TableRow>
			<Cell variant="head" align={'left'}>
				<Text variant={'caption'}>INVOICE ID</Text>
			</Cell>
			<Cell variant="head" align={'right'} sx={{ paddingRight: '24px' }}>
				<Text variant={'caption'}>BOOKER</Text>
			</Cell>
			<Cell variant="head" align={'right'} sx={{ paddingRight: '24px' }}>
				<Text variant={'caption'}>DATE CREATED</Text>
			</Cell>
			<Cell variant="head" align={'left'}>
				<Text variant={'caption'}>DUE DATE</Text>
			</Cell>
			<Cell variant="head" align={'right'} sx={{ paddingRight: '24px' }}>
				<Text variant={'caption'}>CURRENT DETAILS</Text>
			</Cell>
			<Cell variant="head" align={'right'} sx={{ paddingRight: '24px' }}>
				<Text variant={'caption'}>PAYMENT (PKR)</Text>
			</Cell>
			<Cell variant="head" align={'right'} sx={{ paddingRight: '24px' }}>
				<Text variant={'caption'}>DETAILS AFTER PAYMENT</Text>
			</Cell>
		</TableRow>
	)
}
