import React, { useCallback, useEffect, useRef, useState } from 'react'
import {
	Pagination,
	Table,
	TableBody,
	TableCell,
	tableCellClasses,
	TableContainer,
	TableHead,
	TableRow,
	Typography
} from '@mui/material'
import { FlexCol, FlexRow } from 'components/atoms/Flex'
import { Abbr, Capsule, Gutter } from 'components'

import { styled } from '@mui/system'
import { MdModeEdit, MdOutlineAdUnits } from 'react-icons/md'
import moment from 'moment'
import type { IStockUnits } from 'typings'
import analytics from 'utils/segment'
import { dateFormat, LAYOUT_PADDING, SEGMENT_EVENTS } from 'data'
import { useDimension } from 'hooks'

const StyledTableRow = styled(TableRow)(() => ({
	// hide last border
	'&:last-child td, &:last-child th': {
		border: 0
	}
}))

const CircleWrapper = styled(FlexRow)`
	border-radius: 50%;
	background-color: ${({ theme }) => theme.palette.colors.green['50']};
	padding: 8px;
`

const StyledTableCell = styled(TableCell)(({ theme }) => ({
	padding: 7,
	verticalAlign: 'middle',
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: theme.palette.background.paper,
		color:
			theme.palette.mode === 'dark'
				? theme.palette.colors['gray']['400']
				: theme.palette.colors['gray']['500']
	},
	[`&.${tableCellClasses.body}`]: {
		fontSize: 14
	}
}))

const MUITable = styled(Table)`
	display: flex;
	flex-direction: column;
`

const MUIRow = styled(StyledTableRow)`
	display: grid;
	vertical-align: middle;
	justify-content: center;
	grid-template-columns: 3fr 1fr 1fr;
`

const Footer = styled(FlexRow)`
	position: absolute;
	bottom: 0;
	flex: 1;
	width: 100%;
	justify-content: flex-end;
	padding: 7px;
	align-items: center;
`

const Wrapper = styled(FlexCol)`
	position: relative;
	flex: 1;
	width: 100%;
	border: 1px ${({ theme }) => theme.palette.background.paper} solid;
`

const StyledCapsule = styled(Capsule)`
	border-radius: 5px;
	max-width: 80px;
	padding: 7px 10px;
`

const Center = styled(FlexRow)`
	align-items: center;
	height: 100%;
`

const StyledTableContainer = styled(TableContainer)<{ height: number }>`
	min-height: ${({ height }) => height}px;
	max-height: ${({ height }) => height}px;
	background-color: ${({ theme }) => theme.palette.background.default};
`

type InventoryTableProps = {
	isEditable?: boolean
	dataSource: IStockUnits[]
	setModalOpen: React.Dispatch<React.SetStateAction<boolean>>
	setEditId: React.Dispatch<React.SetStateAction<string | undefined>>
}
export const InventoryTable: React.ComponentType<InventoryTableProps> = ({
	isEditable = true,
	dataSource,
	setModalOpen: setOpen,
	setEditId
}) => {
	const ref = useRef<HTMLDivElement>(null)
	const [config, setConfig] = useState({
		page: 0,
		count: 0
	})
	const { height } = useDimension()

	useEffect(() => {
		if (config.count !== dataSource.length) {
			setConfig({ ...config, count: dataSource.length })
		}
	}, [config, dataSource])

	const { page, count } = config
	const rowsPerPage = ref?.current
		? Math.ceil(ref?.current?.clientHeight / 67) - 1
		: 5

	const paginationCount = Math.ceil(count / rowsPerPage)

	const onConfigChange = useCallback(
		(key: string, value: any) => {
			setConfig({ ...config, [key]: value })
		},
		[config]
	)

	const RenderRow = useCallback(
		({ name, createdAt, id }: IStockUnits) => {
			return (
				<MUIRow>
					<StyledTableCell component="th" scope="row">
						<Center>
							<CircleWrapper>
								<MdOutlineAdUnits color={'green'} size="14px" />
							</CircleWrapper>
							<Gutter gap={0.5} />
							<Typography variant={'caption'} fontWeight={500}>
								<Abbr title={name} length={40} />
							</Typography>
						</Center>
					</StyledTableCell>
					<StyledTableCell align="left" scope={'row'}>
						<Center>{moment(createdAt).format(dateFormat)}</Center>
					</StyledTableCell>
					{isEditable && (
						<StyledTableCell align="right" scope={'row'}>
							<StyledCapsule
								renderLeft={
									<FlexRow>
										<MdModeEdit />
										<Gutter gap={0.4} />
									</FlexRow>
								}
								name={'Edit'}
								value={'edit'}
								color={'green'}
								onItemClick={() => {
									analytics.track(
										SEGMENT_EVENTS.SETTINGS_INVENTORY_EDIT_UNIT
									)
									setEditId(id)
									setOpen(true)
								}}
								isSelected
							/>
						</StyledTableCell>
					)}
				</MUIRow>
			)
		},
		[isEditable, setOpen, setEditId]
	)

	return (
		<Wrapper ref={ref}>
			<StyledTableContainer height={height - LAYOUT_PADDING * 2 - 120}>
				<MUITable aria-label="customized table">
					<TableHead>
						<MUIRow>
							<StyledTableCell>Custom Unit</StyledTableCell>
							<StyledTableCell align="left">
								Created Date
							</StyledTableCell>
							{isEditable && <StyledTableCell align="right" />}
						</MUIRow>
					</TableHead>
					<TableBody>
						{dataSource
							.slice(
								page * rowsPerPage,
								page * rowsPerPage + rowsPerPage
							)
							.map(row => (
								<RenderRow key={row.id} {...row} />
							))}
					</TableBody>
				</MUITable>
				<Footer>
					<Pagination
						page={page + 1}
						count={
							paginationCount === Infinity ? 1 : paginationCount
						}
						variant="outlined"
						shape="rounded"
						onChange={(event, page) =>
							onConfigChange('page', page - 1)
						}
					/>
				</Footer>
			</StyledTableContainer>
		</Wrapper>
	)
}
