import type { ButtonProps, ModalProps } from '@mui/material'
import { InputAdornment, styled, Typography } from '@mui/material'
import { FlexRow } from 'components/atoms/Flex'
import {
	Button,
	FieldNumberInput,
	FieldRadioGroup,
	FieldTextInput,
	Gutter
} from 'components'
import * as Yup from 'yup'
import React from 'react'
import CloseIcon from '@mui/icons-material/Close'
import { Formik } from 'formik'
import type { InventoryItemType } from 'typings'
import { useSettings } from 'context/settings'
import { restockInventory } from 'api'
import { useAppErrors } from 'hooks/useAppErrors'
import { useTranslate } from 'hooks'
import { useSnackbar } from 'notistack'
import { StyledModal } from 'components/StyledModal'
import { Abbr } from 'components/atoms/Abbr'
import analytics from 'utils/segment'
import { dateFormat, INVENTORY_RESTOCK_OPTIONS, SEGMENT_EVENTS } from 'data'
import { POSITIVE_NUMBER } from 'helpers'
import moment from 'moment'

const Wrapper = styled('div')`
	background-color: ${({ theme }) => theme.palette.background.default};
	width: 600px;
	padding: 20px 30px;
	border-radius: 10px;
`

const CloseIconWrapper = styled(CloseIcon)`
	padding: 3px;

	&:hover {
		cursor: pointer;
		border-radius: 25%;
		background-color: ${({ theme }) => theme.palette.colors.gray['100']};
		display: flex;
	}
`

const Box = styled(FlexRow)`
	padding-left: 10px;
	border-left: 1px solid ${({ theme }) => theme.palette.colors.gray['300']};
`

const ButtonWrapper = styled(Button, {
	shouldForwardProp: props => props !== 'disabled'
})<Pick<ButtonProps, 'disabled'>>`
	background-color: ${({ theme, disabled }) =>
		disabled
			? theme.palette.colors.gray['200']
			: theme.palette.colors.yellow['300']};
	color: ${({ theme }) => theme.palette.colors.gray['900']};
	padding: 10px 15px;
`
const validationSchema = Yup.object().shape({
	restockType: Yup.string().required(),
	restockAmount: Yup.number().when('restockType', {
		is: 'removeStock',
		then: Yup.number().max(
			Yup.ref('amount'),
			'Restock quantity cannot be greater than current quantity'
		)
	})
})

type RestockModalProps = {
	open: boolean
	onClose(): void
	inventory: InventoryItemType
	onSuccess?: () => void
} & Omit<ModalProps, 'children'>

export const RestockModal: React.ComponentType<RestockModalProps> = ({
	open,
	onClose,
	inventory,
	onSuccess,
	...props
}) => {
	const { business } = useSettings()
	const { setAppError } = useAppErrors()
	const { enqueueSnackbar } = useSnackbar()
	const translate = useTranslate()
	const handleCloseModal = () => {
		onClose()
	}
	const item = inventory.stock[0]

	const reStock = (values: any) => {
		let amount = 0
		if (values.restockType === 'addStock') {
			amount = Number(values.restockAmount)
		}
		if (values.restockType === 'removeStock') {
			amount = Number(values.restockAmount) * -1
		}
		return {
			...values,
			amount
		}
	}

	const onSubmit = async (values: any, action: any) => {
		try {
			action.setSubmitting(true)
			if (inventory?.id) {
				const res = await restockInventory(
					inventory.id,
					reStock(values)
				)
				analytics.track(SEGMENT_EVENTS.INVENTORY_RESTOCK_DONE, {
					...res
				})
				enqueueSnackbar(translate('snackbar.updated'), {
					variant: 'success'
				})
			}
			onSuccess?.()
			onClose()
		} catch (e: any) {
			setAppError(e, action)
		} finally {
			action.setSubmitting(false)
		}
	}

	return (
		<StyledModal
			sx={{
				display: 'flex',
				flexDirection: 'column'
			}}
			open={open}
			onClose={() => onClose()}
			{...props}
		>
			<Formik
				initialValues={{
					business,
					name: inventory.name,
					unit: item.id,
					amount: item.quantity,
					restockType: 'addStock',
					restockAmount: 0,
					comments: inventory.comments
				}}
				validationSchema={validationSchema}
				onSubmit={onSubmit}
			>
				{({ isSubmitting, dirty, handleSubmit }) => (
					<form onSubmit={handleSubmit}>
						<Wrapper>
							<FlexRow justify="space-between" align="center">
								<Typography variant="h6" fontWeight={'bold'}>
									Update
								</Typography>
								<CloseIconWrapper onClick={handleCloseModal} />
							</FlexRow>

							<Typography variant="caption" fontSize={13}>
								Select the item you want to restock and specify
								the quantity you want to add for it
							</Typography>

							<Gutter spacing={2} />
							<FieldTextInput
								style={{ width: '30ch' }}
								name={'name'}
								size={'small'}
								disabled
							/>
							<Gutter spacing={0.5} />
							<FieldRadioGroup
								row
								name="restockType"
								options={INVENTORY_RESTOCK_OPTIONS}
							/>
							<Gutter spacing={0.5} />
							<FieldNumberInput
								regex={POSITIVE_NUMBER}
								name="restockAmount"
								size={'small'}
								style={{ width: '20ch' }}
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<Box>
												<Typography>
													<Abbr
														title={item.unit}
														length={10}
													/>
												</Typography>
											</Box>
										</InputAdornment>
									)
								}}
							/>
							<Gutter spacing={0.5} />
							<FieldTextInput
								name="comments"
								multiline
								style={{ width: '20ch' }}
								rows={2}
								placeholder={`Supplied on ${moment().format(
									dateFormat
								)} by Abdul Hafeez`}
								InputProps={{ style: { fontSize: 15 } }}
							/>
							<Gutter spacing={2} />
							<FlexRow justify="space-between">
								<div />
								<ButtonWrapper
									mode={'primary'}
									type="submit"
									disabled={!dirty || isSubmitting}
								>
									Update Stock
								</ButtonWrapper>
							</FlexRow>
						</Wrapper>
					</form>
				)}
			</Formik>
		</StyledModal>
	)
}
