import React, { useCallback } from 'react'
import type { SelectProps } from '@mui/material'
import { MenuItem, Select, type TextField } from '@mui/material'

import type { TextFieldProps } from '@mui/material/TextField'
import { useField } from 'formik'
import { ErrorText } from 'components/atoms'

type DataSourceProps = {
	name: string
	value: string
}

export type FieldSelectProps = Omit<
	SelectProps,
	'onBlur' | 'onChange' | 'onFocus' | 'placeholder'
> & {
	name: string
	placeholder?: string
	dataSource: DataSourceProps[]
	nextInputRef?: React.MutableRefObject<typeof TextField> | null
}

export const FieldSelect = React.forwardRef<TextFieldProps, FieldSelectProps>(
	({ name, dataSource, nextInputRef, ...props }, ref) => {
		const [field, meta, helpers] = useField(name as any)

		const error = meta.touched && meta.error

		const handleBlur = useCallback(() => {
			helpers.setTouched(true)
		}, [helpers])

		return (
			<>
				<Select
					value={field.value}
					onChange={value => helpers.setValue(value.target.value)}
					displayEmpty
					onBlur={handleBlur}
					inputProps={{ 'aria-label': 'Without label' }}
					{...props}
				>
					{dataSource.map(t => (
						<MenuItem key={t.name} value={t.value}>
							<em>{t.name}</em>
						</MenuItem>
					))}
				</Select>
				<ErrorText text={error} />
			</>
		)
	}
)

FieldSelect.displayName = 'FieldSelect'
