import React, { useState } from 'react'
import { styled } from '@mui/system'
import { Button, Drawer, Typography, useTheme } from '@mui/material'
import { FlexCol, FlexRow } from 'components/atoms/Flex'
import { FieldTextInput, Gutter } from 'components'
import { Close, KeyboardArrowRight } from '@mui/icons-material'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { useSettings } from 'context/settings'
import { useAppErrors } from 'hooks'
import { useSnackbar } from 'notistack'
import { TransparentButton } from 'components/atoms/Button/TransparentButton'
import { updateLoadSheetComment } from 'api'

const Wrapper = styled(FlexCol)`
	border: 1px solid ${({ theme }) => theme.palette.colors.gray['300']};
	border-radius: 8px;
	width: 95%;
	max-width: 95%;
`

const UpperWrapper = styled(Button)`
	width: 100%;
	padding: 16px;
	justify-content: space-between;
	align-items: center;
	color: ${({ theme }) => theme.palette.colors.black['900']};
`

const InformationText = styled(Typography)`
	color: ${({ theme }) => theme.palette.colors.gray['500']};
	font-size: 12px;
	line-height: 18px;
`

const StyledDrawer = styled(Drawer)`
	z-index: 100;
`

const DrawerContainer = styled(FlexCol)`
	flex: 1;
	padding: 16px 16px 24px 16px;
	max-width: 320px;
	justify-content: space-between;
	background-color: ${({ theme }) => theme.palette.background.default};
`

const TopRow = styled(FlexRow)`
	width: 100%;
	align-items: center;
	justify-content: space-between;
`

const ArrowRightIcon = styled(KeyboardArrowRight)`
	font-size: 30px;
`

const CloseIcon = styled(Close)`
	font-size: 16px;
`

const StyledButton = styled(Button)`
	border-radius: 8px;
	color: ${({ theme }) => theme.palette.background.default};
	width: 100%;
	font-weight: 600;
`

const validationSchema = Yup.object().shape({
	comment: Yup.string().min(1, '').max(300, '')
})

type Props = {
	loadSheetId: string
	comment?: string
}

export const InternalComment: React.ComponentType<Props> = ({
	loadSheetId,
	comment
}) => {
	const [drawerOpen, setDrawerOpen] = useState(false)
	const [isDisable, setIsDisable] = React.useState(!!comment)
	const theme = useTheme()
	const { business } = useSettings()
	const { setAppError } = useAppErrors()
	const { enqueueSnackbar } = useSnackbar()
	const { colors } = theme.palette

	const onSubmit = async (values: any, actions: any) => {
		const finalObj = {
			...values,
			business
		}
		try {
			actions.setSubmitting(true)
			await updateLoadSheetComment(loadSheetId, finalObj)
			enqueueSnackbar('Successfully saved comment', {
				variant: 'success'
			})

			actions.resetForm({ values: { ...values } })
		} catch (e: any) {
			setAppError(e, actions)
		} finally {
			actions.setSubmitting(false)
			setIsDisable(true)
		}
	}

	return (
		<Wrapper>
			<UpperWrapper onClick={() => setDrawerOpen(!drawerOpen)}>
				<FlexRow align="center">
					<Typography variant="subtitle2" color={colors.gray['700']}>
						Internal Comments
					</Typography>
				</FlexRow>
				<ArrowRightIcon />
			</UpperWrapper>
			<Formik
				initialValues={{
					comments: comment || ''
				}}
				validationSchema={validationSchema}
				onSubmit={onSubmit}
			>
				{({ handleSubmit, dirty, values, errors, isSubmitting }) => (
					<StyledDrawer
						anchor={'right'}
						open={drawerOpen}
						onClose={() => setDrawerOpen(false)}
						PaperProps={{
							style: {
								borderLeftColor: colors.gray['300']
							}
						}}
					>
						<DrawerContainer>
							<FlexCol>
								<TopRow>
									<Typography
										variant="body2"
										fontWeight={'medium'}
										color={colors.gray['700']}
									>
										Internal Comments
									</Typography>
									<CloseIcon
										onClick={() => setDrawerOpen(false)}
									/>
								</TopRow>
								<Gutter spacing={0.5} />
								<InformationText variant="caption">
									These comments will never be shown to the
									customer and are for your eyes only.
								</InformationText>

								<Gutter spacing={1.5} />
								<FieldTextInput
									disabled={isDisable}
									multiline
									rows={7.8}
									error={errors && errors.comments === ''}
									name={'comments'}
									placeholder={'Enter Comments'}
									style={{ width: '288px' }}
									InputProps={{
										style: {
											fontSize: 12,
											padding: 14,
											backgroundColor: isDisable
												? colors.gray['100']
												: colors.white['500']
										}
									}}
									inputProps={{ maxLength: 300 }}
								/>
								<Gutter spacing={0.75} />
								<FlexRow
									justify={'flex-end'}
									style={{ width: '100%' }}
								>
									{!isDisable && (
										<Typography
											variant={'caption'}
											color={
												errors && errors.comments === ''
													? colors.red['500']
													: colors.gray['900']
											}
											fontSize={10}
										>
											{values.comments.length}/300
										</Typography>
									)}
								</FlexRow>
							</FlexCol>

							<FlexCol align="center" style={{ width: '100%' }}>
								<Gutter spacing={0.75} />
								{isDisable && (
									<TransparentButton
										onClick={() => {
											setIsDisable(false)
										}}
										fullWidth
									>
										<Typography variant={'caption'}>
											Edit Comment
										</Typography>
									</TransparentButton>
								)}
								{!isDisable && (
									<StyledButton
										variant={'contained'}
										onClick={() => {
											if (!dirty) {
												setIsDisable(true)
											} else {
												handleSubmit()
											}
										}}
										disabled={isSubmitting}
									>
										Save Comment
									</StyledButton>
								)}
							</FlexCol>
						</DrawerContainer>
					</StyledDrawer>
				)}
			</Formik>
		</Wrapper>
	)
}
