import React from 'react'
import type { InventoryItemType } from 'typings'
import { Capsule } from 'components'
import { styled } from '@mui/system'
import { truncate } from 'helpers'
import { Typography } from '@mui/material'
import { FlexRow } from 'components/atoms/Flex'

const StyledCapsule = styled(Capsule)`
	padding: 2px 10px;
	margin: 0;
	font-size: 14px;
`

export const StockMob = ({ row }: { row: InventoryItemType }) => {
	const item = row.stock[0]

	if (!item) {
		return <></>
	}

	if (item.quantity <= 0)
		return (
			<StyledCapsule
				name={'Out of Stock'}
				value={'Out of Stock'}
				color={'red'}
				isSelected
				disabled
			/>
		)

	return (
		<FlexRow align={'center'}>
			<Typography variant={'caption'} fontWeight={'bold'}>
				{item.quantity}
			</Typography>
			<StyledCapsule
				isSelected
				disabled
				name={truncate(`${row.stock[0] ? row.stock[0].unit : '-'}`, 15)}
				value={truncate(
					`${row.stock[0] ? row.stock[0].unit : '-'}`,
					15
				)}
				color={'blue'}
			/>
		</FlexRow>
	)
}
