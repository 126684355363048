import React from 'react'
import { FlexCol, FlexRow } from 'components/atoms/Flex'
import { styled } from '@mui/system'
import { DatePicker, FormatAmount, Gutter, Label } from 'components'
import { useField, useFormikContext } from 'formik'
import { dateFormat, invoice_calculation, SEGMENT_EVENTS } from 'data'
import { Skeleton, Typography } from '@mui/material'
import type { InvoiceType } from 'typings'
import type { CurrentInvoiceProps } from 'screen/Invoices/NewCreate/Invoice'
import analytics from 'utils/segment'

const Wrapper = styled(FlexRow)`
	width: 100%;
	justify-content: space-between;
	flex-wrap: wrap;
`

type DateAmountProps = {
	name: string
}

export const DateAmount: React.ComponentType<DateAmountProps> = ({ name }) => {
	const [, meta, helpers] = useField(name)

	const { values }: { values: CurrentInvoiceProps } = useFormikContext()
	const { totalAmount } = invoice_calculation(values as InvoiceType)

	return (
		<Wrapper>
			{values.loading ? (
				<Skeleton variant="rectangular" width={210} height={80} />
			) : (
				<FlexCol>
					<Label>{'Invoice Date*'}</Label>
					<Gutter spacing={0.2} />
					<DatePicker
						slotProps={{
							textField: {
								placeholder: 'Start Date',
								style: { borderRadius: 10 }
							}
						}}
						format={dateFormat}
						value={meta.value}
						onChange={e => {
							helpers.setValue(e)
							analytics.track(
								SEGMENT_EVENTS.INVOICE_SELECT_INVOICE_DATE
							)
						}}
					/>
				</FlexCol>
			)}
			{values.loading ? (
				<Skeleton variant="rectangular" width={210} height={80} />
			) : (
				<FlexCol align={'flex-end'}>
					{totalAmount !== 0 && (
						<>
							<Label>Amount Due</Label>
							<FlexRow>
								<Typography variant={'h5'} fontWeight={700}>
									PKR
								</Typography>
								<Gutter gap={0.4} />
								<FormatAmount
									variant={'h5'}
									fontWeight={700}
									limit={15}
									amount={totalAmount}
									style={{
										color:
											totalAmount >= 0 ? 'black' : 'red'
									}}
								/>
							</FlexRow>
						</>
					)}
				</FlexCol>
			)}
		</Wrapper>
	)
}
