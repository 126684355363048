import React, { type CSSProperties } from 'react'
import type { CustomAutoCompleteProps } from 'components/app/CutomAutoComplete'
import { CustomAutoComplete } from 'components/app/CutomAutoComplete'
import { FlexCol, FlexRow } from 'components/atoms/Flex'
import { ErrorText } from 'components/atoms'
import { useField } from 'formik'

type FieldAutoCompleteProps = CustomAutoCompleteProps<any> & {
	name: string
	wrapperStyle?: CSSProperties
}

export const FieldAutoComplete: React.ComponentType<FieldAutoCompleteProps> = ({
	name,
	wrapperStyle,
	...props
}) => {
	const [, meta] = useField(name)
	const error = meta.touched && meta.error

	return (
		<FlexCol align={'flex-start'} style={wrapperStyle}>
			<CustomAutoComplete {...props} error={error as string} />
			<FlexRow style={{ width: '100%' }} align={'flex-start'}>
				<ErrorText text={error} />
			</FlexRow>
		</FlexCol>
	)
}
