import React from 'react'
import { styled } from '@mui/system'
import { FlexCol } from 'components/atoms/Flex'
import { Paper } from '@mui/material'
import { TableVirtuoso } from 'react-virtuoso'
import { useDimension } from 'hooks'
import type { InvoiceType } from 'typings'
import { AllInvoiceTableComponent } from 'screen/LoadSheet/Create/AllInvoiceTable/AllInvoiceTableComponents'
import { AllInvoiceHeader } from 'screen/LoadSheet/Create/AllInvoiceTable/AllInvoiceHeader'
import { AllInvoiceItem } from 'screen/LoadSheet/Create/AllInvoiceTable/AllInvoiceTableItem'
import { useFormikContext } from 'formik'
import type { LoadsheetType } from 'typings/loadsheet'

const StyledPaper = styled(Paper)`
	position: relative;
	width: 100%;
	height: 100%;
	background: ${({ theme }) => theme.palette.colors.white['500']};
	z-index: 0;
`

const Table = styled(TableVirtuoso)`
	width: 100%;
	background: ${({ theme }) => theme.palette.colors.white['500']};
`

const Wrapper = styled(FlexCol)`
	background-color: ${({ theme }) => theme.palette.background.default};
	width: 100%;
	border-radius: 16px;
	border: 1px solid ${({ theme }) => theme.palette.colors.gray['300']};
	box-shadow: 0 4px 6px -2px rgba(16, 24, 40, 0.03),
		0px 12px 16px -4px rgba(16, 24, 40, 0.08);
	overflow: hidden;
`

type AllInvoicesProps = {
	onItemSelect?(inv: InvoiceType): void
}

export const SelectedInvoice: React.ComponentType<AllInvoicesProps> = ({
	onItemSelect
}) => {
	const { values } = useFormikContext<LoadsheetType>()

	const { height } = useDimension()

	return (
		<Wrapper>
			<StyledPaper
				style={{
					height: height - 56 * 4 - 80
				}}
			>
				<Table
					data={values.invoiceItems}
					components={AllInvoiceTableComponent}
					fixedHeaderContent={AllInvoiceHeader}
					itemContent={(_index: number, row: any) => (
						<AllInvoiceItem
							key={row.id}
							onRowClick={() =>
								onItemSelect?.(row as InvoiceType)
							}
							invoice={row}
							{...row}
						/>
					)}
				/>
			</StyledPaper>
		</Wrapper>
	)
}
