import React, { type ChangeEvent, type CSSProperties, useCallback } from 'react'
import { useField } from 'formik'
import type { TextInputProps } from 'components'
import { ErrorText, TextInput } from 'components'
import { FlexCol, FlexRow } from 'components/atoms/Flex'
import { styled } from '@mui/system'
import { Typography, useTheme } from '@mui/material'

const StyledBox = styled(FlexRow)`
	position: relative;
	width: 100%;
`

const StyledDiv = styled('div')`
	position: absolute;
	bottom: 5px;
	right: 10px;
`

export type FieldMultipleLineInputProps = Omit<
	TextInputProps,
	'onFocus' | 'onBlur'
> & {
	name: string
	placeholder?: string
	nextInputRef?: React.MutableRefObject<typeof TextInput> | null
	limit?: number
	hideLimit?: boolean
	wrapperStyle?: CSSProperties
	onBlur?(): void
}

export const FieldMultipleLineInput = React.forwardRef<
	TextInputProps,
	FieldMultipleLineInputProps
>(
	(
		{
			name,
			onBlur,
			onChange,
			wrapperStyle,
			limit = 500,
			hideLimit,
			nextInputRef,
			...props
		},
		ref
	) => {
		const [field, meta, helpers] = useField(name)

		const error = meta.touched && meta.error
		const theme = useTheme()
		const { colors } = theme.palette
		const exceed = (field.value ? field.value.toString().length : 0) > limit

		const handleBlur = useCallback(() => {
			helpers.setValue(meta.value?.trim())
			helpers.setTouched(true)
			onBlur?.()
		}, [helpers, meta.value, onBlur])

		return (
			<FlexCol
				align={'flex-start'}
				style={{ width: '100%', ...wrapperStyle }}
			>
				<StyledBox>
					<TextInput
						id={name}
						value={field.value ? field.value.toString() : ''}
						onChange={(e: ChangeEvent<HTMLInputElement>) => {
							helpers.setValue(e.target.value)
							onChange?.(e)
						}}
						error={!!error}
						onBlur={handleBlur}
						style={{ width: '100%', backgroundColor: 'white' }}
						{...props}
					/>
					<StyledDiv>
						{limit > -1 && !hideLimit && (
							<Typography
								variant={'caption'}
								color={
									exceed
										? colors.red['500']
										: colors.gray['700']
								}
							>
								{field.value
									? field.value.toString().length
									: '0'}{' '}
								/ {limit}
							</Typography>
						)}
					</StyledDiv>
				</StyledBox>

				<FlexRow style={{ width: '100%' }} align={'flex-start'}>
					<ErrorText text={error} />
				</FlexRow>
			</FlexCol>
		)
	}
)

FieldMultipleLineInput.displayName = 'FieldMultipleLineInput'
