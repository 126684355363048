import React, { useCallback } from 'react'
import type { SelectProps } from '@mui/material'
import {
	Box,
	ListItemText,
	MenuItem,
	Select,
	type TextField
} from '@mui/material'
import type { TextFieldProps } from '@mui/material/TextField'
import { useField } from 'formik'
import { FlexRow } from 'components/atoms/Flex'
import { Capsule, CheckBox, ErrorText, Gutter, Label } from 'components'
import { RiArrowDropDownLine } from 'react-icons/ri'
import { SUPPLY_CHAIN_POSITION } from 'data'
import type { ColorsType } from 'typings'
import { styled } from '@mui/system'
import { pascalCase } from 'helpers'

const StyledCapsule = styled(Capsule)`
	border-radius: 25px;
	max-width: 80px;
	font-size: 12px;
	padding: 1px 10px;
`

type DataSourceProps = {
	name: string
	value: string
}

export type MultiSelectProps = Omit<
	SelectProps,
	'onBlur' | 'onChange' | 'onFocus' | 'placeholder'
> & {
	name: string
	placeholder?: string
	dataSource: DataSourceProps[]
	nextInputRef?: React.MutableRefObject<typeof TextField> | null
}

export const MultiSelect = React.forwardRef<TextFieldProps, MultiSelectProps>(
	({ name, nextInputRef, dataSource, ...props }, ref) => {
		const [field, meta, helpers] = useField(name as any)

		const error = meta.touched && meta.error

		const handleBlur = useCallback(() => {
			helpers.setTouched(true)
		}, [helpers])

		const Role = ({ role }: { role: string }) => {
			const obj = SUPPLY_CHAIN_POSITION.find(obj => obj.value == role)

			return (
				<StyledCapsule
					isSelected
					name={obj?.name || ''}
					value={obj?.value || ''}
					color={(obj?.color || 'green') as ColorsType}
				/>
			)
		}

		return (
			<>
				<Label>Supply Chain Role</Label>
				<Select
					size={'small'}
					value={field.value}
					onChange={value => helpers.setValue(value.target.value)}
					displayEmpty
					onBlur={handleBlur}
					inputProps={{
						'aria-label': 'Without label',
						size: 'small'
					}}
					multiple
					IconComponent={RiArrowDropDownLine}
					renderValue={selected => (
						<Box display={'flex'} flexWrap={'wrap'} gap={'0.5'}>
							{selected.map((value: string) => (
								<Role role={value} key={value} />
							))}
						</Box>
					)}
					{...props}
				>
					{dataSource.map(t => (
						<MenuItem key={t.name} value={t.value}>
							<FlexRow justify="center" align="center">
								<CheckBox
									checked={field.value.indexOf(t.value) > -1}
								/>
								<Gutter gap={1} />
								<ListItemText primary={pascalCase(t.value)} />
							</FlexRow>
						</MenuItem>
					))}
				</Select>
				<ErrorText text={error} />
			</>
		)
	}
)

MultiSelect.displayName = 'FieldSelect'
