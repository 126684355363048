import React, { useCallback } from 'react'
import type { CapsuleProps } from 'components'
import { Capsule, type DataSource, ErrorText } from 'components'
import { useField } from 'formik'
import { addOrRemoveArr } from 'helpers'
import analytics from 'utils/segment'
import { SEGMENT_EVENTS } from 'data'

type FieldCapsuleProps<T> = {
	datasource: T[]
	name: string
} & Omit<CapsuleProps, 'title' | 'onItemClick' | 'name' | 'value'>

export const FieldCapsule: React.ComponentType<
	FieldCapsuleProps<DataSource>
> = ({ datasource, name, renderRight, renderLeft, ...props }) => {
	const [, meta, helpers] = useField(name as any)

	const error = meta.touched && meta.error

	const onItemClick = useCallback(
		(title: string) => {
			analytics.track(SEGMENT_EVENTS.ONBOARDING_SUPPLY_CHAIN)
			helpers.setTouched(true)
			helpers.setValue(addOrRemoveArr(meta.value, title))
		},
		[helpers, meta.value]
	)

	const Render = useCallback(
		(item: DataSource) => {
			return (
				<>
					<Capsule
						isSelected={meta.value.includes(item.value)}
						onItemClick={onItemClick}
						renderRight={
							meta.value.includes(item.value) && renderRight
						}
						renderLeft={
							meta.value.includes(item.value) && renderLeft
						}
						{...item}
						{...props}
					/>
				</>
			)
		},
		[meta.value, props, onItemClick, renderRight, renderLeft]
	)

	return (
		<>
			{datasource.map(item => (
				<Render key={item.name} {...item} />
			))}
			<ErrorText text={error} />
		</>
	)
}
