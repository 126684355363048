import * as React from 'react'
import { styled, Typography } from '@mui/material'
import Success from 'assets/alerts/success.png'
import { Button, Gutter, StyledModal } from 'components'
import { FlexCol, FlexRow } from 'components/atoms/Flex'

const Container = styled(FlexRow)`
	max-width: 90%;
	width: auto;
	height: auto;
	border-radius: 8px;
	padding: 20px;
	background-color: ${({ theme }) => theme.palette.background.default};
`

type OrderBookerFormSubmittedModalProps = {
	businessName?: string
	open: boolean
	onClose(): void
}

export const OrderBookerFormSubmittedModal: React.ComponentType<
	OrderBookerFormSubmittedModalProps
> = ({ businessName, open, onClose }) => {
	const handleRecordNewOrder = () => {
		window.location.reload()
	}

	return (
		<StyledModal
			onClose={onClose}
			aria-labelledby="customized-dialog-title"
			open={open}
		>
			<Container>
				<img
					alt={'success'}
					src={Success}
					style={{ width: '50px', height: '50px' }}
				/>
				<Gutter spacing={4} />

				<FlexCol style={{ width: '100%' }}>
					<Typography fontWeight={'600'}>Order Submitted</Typography>
					<Gutter spacing={0.8} />
					<Typography variant="caption" fontSize={13}>
						Order details have been sent to{' '}
						<Typography
							variant="caption"
							fontSize={13}
							fontWeight={'500'}
						>
							{businessName}
						</Typography>
						. Thank you!
					</Typography>
					<Gutter spacing={2} />
					<Button
						style={{ width: '100%' }}
						onClick={handleRecordNewOrder}
					>
						Record New Order
					</Button>
				</FlexCol>
			</Container>
		</StyledModal>
	)
}
