import React, { useCallback, useState } from 'react'
import { type FieldHelperProps, useField } from 'formik'
import { styled } from '@mui/system'
import {
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography
} from '@mui/material'
import { FlexRow } from 'components/atoms/Flex'
import { Button, ErrorText, FieldNumberInput, Gutter } from 'components'
import { AiOutlinePlus } from 'react-icons/ai'
import { SelectInventoryModal } from 'components/app'
import type { ICart, InventoryItemType } from 'typings'
import { objectOmit, objectPick } from 'helpers/object'
import { RiDeleteBinLine } from 'react-icons/ri'
import { Abbr } from 'components/atoms/Abbr'
import { SCROLLBAR_STYLE, SEGMENT_EVENTS } from 'data'
import analytics from 'utils/segment'
import { currencyInput } from 'helpers'

const StyledTableRow = styled(TableRow)`
	background-color: ${({ theme }) => theme.palette.background.default};
`

const StyledPaper = styled(Paper)`
	width: 100%;
	overflow: hidden;
	position: relative;
`

const TableWrapper = styled(TableContainer)`
	background-color: ${({ theme }) => theme.palette.background.default};
	min-height: 330px;
	max-height: 330px;
	${SCROLLBAR_STYLE}
`

const StyledButton = styled(Button)`
	position: absolute;
	bottom: 2px;
	left: 2px;
	padding: 5px 10px;
	border-radius: 7px;
	background-color: ${({ theme }) => theme.palette.background.default};
	border: 1px solid ${({ theme }) => theme.palette.colors['gray']['200']};

	:hover {
		background-color: ${({ theme }) => theme.palette.background.paper};
	}
`

const DeleteButton = styled(RiDeleteBinLine)`
	color: ${({ theme }) => theme.palette.colors.rose['500']};

	:hover {
		cursor: pointer;
	}
`

type ItemProps = {
	id: string
	name: string
	code: string
	unit: string
	quantity: number
	perItemCostPrice: number
	perItemSellPrice: number
	stockId: string
}

type OrderTableProps = {
	name: string
	type?: string
}

export const OrderTable: React.ComponentType<OrderTableProps> = ({
	name,
	type
}) => {
	const [isModalOpen, setIsModalOpen] = useState(false)
	const [, meta, helpers] = useField(name)

	const onInventorySelect = (inventory: InventoryItemType) => {
		const item = inventory.stock[0]
		const exist = meta.value.find(
			(f: ItemProps) => f.stockId === item.stockId
		)

		if (exist) {
			helpers.setValue(
				meta.value.filter((f: ItemProps) => f.stockId !== item.stockId)
			)
		} else {
			if (inventory.stock.length > 0) {
				helpers.setValue([
					...meta.value,
					{
						...objectOmit(
							inventory.stock[0],
							'costCurrency',
							'lastStockedAt',
							'sellCurrency',
							'id'
						),
						...objectPick(inventory, 'name'),
						id: new Date().toISOString(),
						isFrontendKey: true,
						quantity: 0
					}
				])
			}
		}
	}

	const RenderRow = useCallback(
		({
			row,
			index,
			value,
			helpers
		}: {
			row: ICart
			index: number
			value: ItemProps[]
			helpers: FieldHelperProps<any>
		}) => {
			return (
				<StyledTableRow hover role="checkbox" tabIndex={-1}>
					<TableCell>
						<Abbr title={row.code} length={7} />
					</TableCell>
					<TableCell width={300} style={{ maxWidth: 300 }}>
						<Typography
							variant={'caption'}
							style={{
								overflowWrap: 'break-word'
							}}
							fontWeight={'500'}
							display={'block'}
						>
							{row.name}
						</Typography>
					</TableCell>
					<TableCell>
						<Abbr title={row.unit} length={10} />
					</TableCell>
					<TableCell>
						{currencyInput(`${row.perItemSellPrice}`)}
					</TableCell>
					<TableCell>
						<FieldNumberInput
							name={`items[${index}].quantity`}
							size={'small'}
							sx={{ width: '8ch' }}
						/>
					</TableCell>
					<TableCell width={30}>
						<DeleteButton
							onClick={() => {
								helpers.setValue(
									value.filter(
										(f: ItemProps) =>
											!(
												f.stockId === row.stockId &&
												f.unit === row.unit
											)
									)
								)
							}}
						/>
					</TableCell>
				</StyledTableRow>
			)
		},
		[]
	)

	return (
		<>
			<StyledPaper>
				<TableWrapper>
					<Table stickyHeader aria-label="sticky table">
						<TableHead>
							<StyledTableRow>
								<TableCell>
									<Typography variant={'subtitle2'}>
										Code
									</Typography>
								</TableCell>
								<TableCell width={300}>
									<Typography variant={'subtitle2'}>
										Items
									</Typography>
								</TableCell>
								<TableCell>
									<Typography variant={'subtitle2'}>
										Unit
									</Typography>
								</TableCell>
								<TableCell>
									<Typography variant={'subtitle2'}>
										Selling Price
									</Typography>
								</TableCell>
								<TableCell>
									<Typography variant={'subtitle2'}>
										Quantity
									</Typography>
								</TableCell>

								<TableCell width={30} />
							</StyledTableRow>
						</TableHead>
						<TableBody>
							{meta.value.map((row: any, index: number) => (
								<RenderRow
									key={`${row.id}-${row.unit}`}
									row={row}
									index={index}
									value={meta.value}
									helpers={helpers}
								/>
							))}
						</TableBody>
					</Table>
				</TableWrapper>
				<StyledButton
					onClick={() => {
						setIsModalOpen(true)
						analytics.track(SEGMENT_EVENTS.ADD_ITEMS, {
							screen: `record_${type}`
						})
					}}
				>
					<FlexRow justify={'center'} align={'center'}>
						<AiOutlinePlus color={'black'} />
						<Gutter gap={0.3} />
						<Typography color={'black'} variant={'caption'}>
							Add Items
						</Typography>
					</FlexRow>
				</StyledButton>
			</StyledPaper>
			{meta.touched && meta.error && meta.value.length === 0 && (
				<ErrorText text={'min 1 item is required'} />
			)}
			<SelectInventoryModal
				selectedInventory={meta.value}
				open={isModalOpen}
				onClose={() => setIsModalOpen(false)}
				onRowClick={onInventorySelect}
				segmentEventCall={() =>
					analytics.track(SEGMENT_EVENTS.ADD_NEW_ITEM_SUCCESS, {
						screen: 'new_order'
					})
				}
			/>
		</>
	)
}
