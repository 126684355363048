import { Form1, FormStep1Schema } from 'screen/Settting/Privacy/PinSetup/Form1'
import { Form2, FormStep2Schema } from 'screen/Settting/Privacy/PinSetup/Form2'

export const PIN_SETUP_FORM = [
	{
		screenForm: Form1,
		title: 'Enter New Pin',
		validationSchema: FormStep1Schema
	},
	{
		screenForm: Form2,
		title: 'Re-enter PIN to verify',
		validationSchema: FormStep2Schema
	}
]
