import React, { useCallback, useEffect, useState } from 'react'
import { AppLayout } from '../AppLayout'
import { Button, Gutter } from 'components'
import { FlexCol, FlexRow } from 'components/atoms/Flex'
import { styled } from '@mui/system'
import { Typography } from '@mui/material'
import { TutorialContent, TutorialImage } from './Modal'
import { useNavigate } from 'react-router-dom'
import { useTranslate } from 'hooks'
import { useDispatch, useSelector } from 'react-redux'
import checkmark from 'assets/onboarding/checkmark.png'
import { StyledModal } from 'components/StyledModal'
import { SEGMENT_EVENTS } from 'data'
import analytics from 'utils/segment'
import { RxCross2 } from 'react-icons/rx'
import Logo from 'assets/onboarding/tutorial.png'
import YouTube from 'react-youtube'
import { type RootState } from 'store/index-reducer'
import { getAllBusiness, getRefreshToken } from 'api'
import { setToken } from 'store/user/actions'
import type { IBusiness, IUser } from 'typings'
import { fetchAllBusinessSuccess } from 'store/business/actions'

const circle_diameter = 500

const Image = styled('img')`
	width: 75px;
	height: 75px;
`

const BackgroundImage = styled('div')`
	border-radius: 50%;
	background-color: ${({ theme }) => theme.palette.colors.green['500']};
	width: ${circle_diameter}px;
	height: ${circle_diameter}px;
	background-size: 100%;
	right: 2px;
	position: fixed;
	top: -${circle_diameter / 2}px;
`
const SquareWrapper = styled('div')`
	border-radius: 3px;
	background-color: ${({ theme }) => theme.palette.colors.white['50']};
	padding: 3px 10px 0 10px;
	cursor: pointer;
`

const WrapperCol = styled(FlexCol)`
	overflow: hidden;
	border-radius: 10px;
	background-color: white;
	width: min-content;
	height: inherit;
`
const TutorialImg = styled('img')`
	width: 340px;
	height: 200px;
`

type ImageProps = {
	openTutorial: boolean
	handleOpenTutorial: () => void
	handleCloseTutorial: () => void
}

const Tutorial: React.ComponentType<ImageProps> = ({
	openTutorial,
	handleOpenTutorial,
	handleCloseTutorial
}) => {
	const opts = {
		height: '400',
		width: '640',
		playerVars: {
			autoplay: 1
		}
	}

	const _onReady = (event: any) => {
		event.target.pauseVideo()
	}
	return (
		<>
			{openTutorial ? (
				<>
					<StyledModal
						open={openTutorial}
						onClose={handleCloseTutorial}
					>
						<TutorialContent>
							<FlexRow
								justify="flex-end"
								style={{ alignSelf: 'flex-end' }}
							>
								<SquareWrapper onClick={handleCloseTutorial}>
									<RxCross2 />
								</SquareWrapper>
							</FlexRow>
							<Gutter spacing={1} />
							<WrapperCol>
								<YouTube
									videoId="5JwXxvE6NLs"
									opts={opts}
									onReady={_onReady}
								/>
							</WrapperCol>
						</TutorialContent>
					</StyledModal>
				</>
			) : (
				<>
					<TutorialImage onClick={handleOpenTutorial}>
						<TutorialImg src={Logo} />
					</TutorialImage>
				</>
			)}
		</>
	)
}

const Row = styled(FlexRow)`
	flex: 1;
	align-self: center;
	align-items: flex-end;
`

type WrapperProps = {
	children: React.ReactNode
}

const StyledWrapper = styled(FlexRow)`
	position: relative;
	left: 10vw;
	top: 25vh;
	width: 55%;
`

const Wrapper: React.ComponentType<WrapperProps> = ({ children }) => {
	return (
		<StyledWrapper>
			<Row>
				<FlexCol>{children}</FlexCol>
			</Row>
			<Gutter spacing={10} />
		</StyledWrapper>
	)
}

export const SignUpComplete = () => {
	const [openTutorial, setOpenTutorial] = useState(false)
	const { user } = useSelector((state: RootState) => state.user)

	const dispatch = useDispatch()

	const navigate = useNavigate()
	const translate = useTranslate()
	const handleOnClick = async () => {
		try {
			const { token: tt } = await getRefreshToken()
			dispatch(setToken(tt))

			analytics.track(SEGMENT_EVENTS.ONBOARDING_START_WEBAPP, {
				user_type: 'new user'
			})
			if (user) {
				analytics.identify(user?.id, {
					name: user?.fullName,
					phone: `+${user?.phone?.countryCode}${user?.phone?.number}`
				})
			}
		} catch (e) {
			//
		}
		navigate('/home')
	}

	const getBusiness = useCallback(async () => {
		try {
			const tt: { token: string; user: IUser } = await getRefreshToken()
			localStorage.setItem('token', tt.token)
			dispatch(setToken(tt.token))
			const res: IBusiness[] = await getAllBusiness()
			dispatch(fetchAllBusinessSuccess(res))
		} catch (e) {
			//
		}
	}, [dispatch])

	const handleOpenTutorial = () => setOpenTutorial(true)
	const handleCloseTutorial = () => setOpenTutorial(false)

	// once business info save to our database then we need to fetch bz our landing page depend on total business
	useEffect(() => {
		getBusiness()
	}, [getBusiness])

	return (
		<>
			<BackgroundImage />
			<Tutorial
				openTutorial={openTutorial}
				handleOpenTutorial={handleOpenTutorial}
				handleCloseTutorial={handleCloseTutorial}
			/>
			<AppLayout>
				<Wrapper>
					<Gutter />
					<Image src={checkmark} />
					<Gutter />
					<Typography variant="h3" fontWeight="bold">
						{translate('onboarding.signup.webappReady')}
					</Typography>
					<Gutter />
					<Typography
						variant="h6"
						fontWeight="medium"
						sx={{
							color: theme => theme.palette.colors.gray['600']
						}}
					>
						{translate('onboarding.signup.customizedRequirements')}
					</Typography>
					<Gutter />
					<Button
						variant={'contained'}
						onClick={() => {
							handleOnClick()
						}}
					>
						<Typography variant="body2">
							{translate('onboarding.signup.startUsingWebApp')}
						</Typography>
					</Button>
				</Wrapper>
			</AppLayout>
		</>
	)
}
