import React from 'react'
import { InputAdornment } from '@mui/material'
import { FieldImageDropZone, FieldTextInput, Gutter } from 'components'
import { FlexRow } from 'components/atoms/Flex'
import { MdOutlineCloudUpload } from 'react-icons/md'
import { BsQuestionCircle } from 'react-icons/bs'
import { ImagePlaceholder } from './ImagePlaceHolder'
import { useFormikContext } from 'formik'
import { uploadInventoryPic } from 'api'

export const ItemDetails = ({ inventoryId }: { inventoryId: string }) => {
	const { setFieldValue } = useFormikContext()

	return (
		<>
			<FlexRow align="center">
				<FieldImageDropZone
					name="icon"
					placeholder={ImagePlaceholder}
					icon={MdOutlineCloudUpload}
					api={uploadInventoryPic}
					payload={
						inventoryId === '' ? {} : { inventoryId: inventoryId }
					}
				/>
			</FlexRow>
			<Gutter spacing={1.5} />
			<FieldTextInput
				name="name"
				size="small"
				title="Item Name"
				sx={{ width: '38ch' }}
			/>
			<Gutter spacing={0.75} />
			<FieldTextInput
				disabled={!!inventoryId}
				name="code"
				size="small"
				title="Code"
				sx={{ width: '25ch' }}
				inputProps={{ maxLength: 20 }}
			/>
			<Gutter spacing={0.75} />
			<FieldTextInput
				name="labels"
				size="small"
				title="Labels"
				placeholder="Label1, Label2, Label3, ..."
				sx={{
					width: '30ch',
					'& input::placeholder': {
						fontSize: '1.3ch'
					}
				}}
				InputProps={{
					endAdornment: (
						<InputAdornment position="end">
							<BsQuestionCircle color="gray" size={15} />
						</InputAdornment>
					)
				}}
				onChange={e => {
					setFieldValue('labels', e.target.value.split(','))
				}}
			/>
			<Gutter spacing={0.75} />
			<FieldTextInput
				name="comments"
				size="small"
				title="Comments"
				placeholder="Supplied on 9/11/12 by Abdul Hafeez"
				sx={{
					width: '35ch',
					'& input::placeholder': {
						fontSize: '1.3ch'
					}
				}}
				multiline
				maxRows={3}
				rows={3}
			/>
		</>
	)
}
