import { call, fork, put, take } from 'redux-saga/effects'
import { FETCH_ALL_STOCK_UNIT, FETCH_TAXES } from 'store/settings/types'
import {
	fetchAllStockUnitError,
	fetchAllStockUnitSuccess,
	fetchTaxesError,
	fetchTaxesSuccess,
	setStockLoading,
	setTaxLoading
} from 'store/settings/actions'
import { getAllStockUnit, getAllTaxes } from 'api'
import type { IStockUnits, ITaxUnit } from 'typings'

function* fetchStockUnits() {
	try {
		yield put(setStockLoading(true))
		const res: IStockUnits[] = yield call(getAllStockUnit)
		yield put(fetchAllStockUnitSuccess(res))
	} catch (e: any) {
		yield put(fetchAllStockUnitError(e.errors))
	} finally {
		yield put(setStockLoading(false))
	}
}

function* fetchTaxUnit() {
	try {
		yield put(setTaxLoading(true))
		const res: ITaxUnit[] = yield call(getAllTaxes)
		yield put(fetchTaxesSuccess(res))
	} catch (e: any) {
		yield put(fetchTaxesError(e.errors))
	} finally {
		yield put(setTaxLoading(false))
	}
}

function* settingWatcher(): any {
	while (true) {
		const everything = yield take([FETCH_ALL_STOCK_UNIT, FETCH_TAXES])
		if (!everything) {
			return
		}

		const { type } = everything

		switch (type) {
			case FETCH_ALL_STOCK_UNIT:
				yield fork(fetchStockUnits)
				break

			case FETCH_TAXES:
				yield fork(fetchTaxUnit)
				break
		}
	}
}

export default [settingWatcher()]
