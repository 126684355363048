import React, { type ChangeEvent, useCallback } from 'react'
import { useField } from 'formik'
import type { TextInputProps } from 'components'
import { ErrorText, TextInput } from 'components'
import { FlexCol, FlexRow } from 'components/atoms/Flex'
import { POSITIVE_NUMBER_WITH_DECILMAL, type Regex } from 'helpers'

export type FieldNumberInputProps = Omit<
	TextInputProps,
	'onBlur' | 'onChange' | 'onFocus'
> & {
	regex?: Regex
	name: string
	placeholder?: string
	nextInputRef?: React.MutableRefObject<typeof TextInput> | null
	onBlur?(text?: string): void
}

export const FieldNumberInput = React.forwardRef<
	TextInputProps,
	FieldNumberInputProps
>(({ name, regex, onBlur, nextInputRef, ...props }, ref) => {
	const [field, meta, helpers] = useField(name as any)

	const error = meta.touched && meta.error

	const handleBlur = useCallback(() => {
		helpers.setTouched(true)
		onBlur?.(meta.value)
	}, [helpers, meta.value, onBlur])

	const onChange = (e: ChangeEvent<HTMLInputElement>) => {
		if (e.target.value.match(regex || POSITIVE_NUMBER_WITH_DECILMAL)) {
			helpers.setValue(e.target.value)
		}
	}

	return (
		<FlexCol align={'flex-start'}>
			<TextInput
				id={name}
				value={field.value ? field.value.toString() : ''}
				onChange={onChange}
				type={'tel'}
				error={!!error}
				onBlur={handleBlur}
				{...props}
			/>
			<FlexRow style={{ width: '100%' }} align={'flex-start'}>
				<ErrorText text={error} />
			</FlexRow>
		</FlexCol>
	)
})

FieldNumberInput.displayName = 'FieldNumberInput'
