import { axios } from 'store'
import type { ISettings } from 'typings'
import type { IOrderBookerSettings } from 'typings'

export const getOrderFormSettings = async (businessId: string) => {
	const url = `/settings/order-form/${businessId}`
	try {
		const { data } = await axios.get(url)
		return data
	} catch (error: any) {
		throw error.response.data
	}
}

export const updateOrderFormSettings = async (
	payload: ISettings,
	id: string
) => {
	const url = `/settings/order-form/${id}`

	try {
		const { data } = await axios.patch(url, payload)

		return data
	} catch (error: any) {
		throw error.response.data
	}
}

export const getOrderBookerSettings = async (businessId: string) => {
	const url = `/settings/order-booker/${businessId}`
	try {
		const { data } = await axios.get(url)
		return data
	} catch (error: any) {
		throw error.response.data
	}
}

export const updateOrderBookerSettings = async (
	payload: IOrderBookerSettings,
	id: string
) => {
	const url = `/settings/order-booker/${id}`

	try {
		const { data } = await axios.patch(url, payload)

		return data
	} catch (error: any) {
		throw error.response.data
	}
}
