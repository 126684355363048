import React from 'react'
import { styled } from '@mui/system'
import type { IFlexProps } from 'components/atoms/Flex'
import { FlexCol, FlexRow } from 'components/atoms/Flex'
import Logo from 'assets/Logo.png'

const Container = styled(FlexCol)`
	background-color: ${({ theme }) => theme.palette.background.default};

	@media screen and (max-width: 400px) {
		padding: 5px;
	}
	height: 100vh;
	width: 100vw;
	flex: 1;
`

const Image = styled('img')`
	width: 140px;
	height: 50px;
	@media screen and (max-width: 400px) {
		margin-bottom: 15px;
	}
`

const Wrapper = styled(FlexCol)`
	width: 100%;
	height: 100%;
	flex: 1;
	overflow-y: scroll;

	::-webkit-scrollbar {
		display: none;
	}
	-ms-overflow-style: none;
	scrollbar-width: none;
`

const Row = styled(FlexRow)`
	justify-content: space-between;
	width: 100%;
	align-items: center;
	padding: 20px 40px;
`

type AppLayoutProps = {
	children?: React.ReactNode
	rightItem?: React.ReactNode
} & IFlexProps

const AppLayout: React.ComponentType<AppLayoutProps> = ({
	children,
	rightItem,
	...props
}) => {
	return (
		<Container>
			<Row>
				<FlexRow justify={'space-between'} align={'center'}>
					<Image src={Logo} />
				</FlexRow>
				{rightItem}
			</Row>
			<Wrapper {...props}>{children}</Wrapper>
		</Container>
	)
}

export { AppLayout }
