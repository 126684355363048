import React, { type MouseEvent, useCallback, useState } from 'react'
import { FlexRow } from 'components/atoms/Flex'
import { AntSwitch } from 'components'
import { Button, Popover, Typography, useTheme } from '@mui/material'
import { styled } from '@mui/system'
import type { ColumnVisibilityProps } from 'data'
import { SEGMENT_EVENTS } from 'data'
import analytics from 'utils/segment'
import { MoreVert } from '@mui/icons-material'

const OutlinedButton = styled(Button)`
	border-radius: 7px;
	padding: 6px;
	padding-right: 0;
	min-width: 0;
	color: ${({ theme }) => theme.palette.colors.black['300']};
`

const Text = styled(Typography)`
	font-family: Gilmer, sans-serif;
	font-weight: 500;
`

const Container = styled('div')`
	max-width: 300px;
	min-width: 300px;
	border-radius: 10px;
	overflow: hidden;
	width: 100%;
`

const Header = styled('div')`
	background-color: ${({ theme }) => theme.palette.colors.green['500']};
	width: 100%;
	padding: 10px 20px;
`

const Body = styled('div')`
	background-color: ${({ theme }) => theme.palette.background.default};
	width: 100%;
	padding: 20px 20px;
`

const Row = styled(FlexRow)`
	width: 100%;
	padding: 4px 0;

	:hover {
		cursor: pointer;
	}
`

type EditColumnProps = {
	columnVisibility: ColumnVisibilityProps[]
	onClick(name: ColumnVisibilityProps[]): void
}

export const EditColumn: React.ComponentType<EditColumnProps> = ({
	columnVisibility,
	onClick
}) => {
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
	const theme = useTheme()

	const handleClose = () => {
		setAnchorEl(null)
	}

	const handleOnCLick = (event: MouseEvent<HTMLButtonElement>) => {
		analytics.track(SEGMENT_EVENTS.INVENTORY_EDIT_COLUMN)
		setAnchorEl(event.currentTarget)
	}

	const open = Boolean(anchorEl)

	const onRowClick = useCallback(
		({ ...props }: ColumnVisibilityProps) => {
			const arr = columnVisibility.map(col => {
				if (col.id === props.id) {
					return props
				}
				return col
			})
			onClick(arr)
		},
		[onClick, columnVisibility]
	)

	const Item = useCallback(
		({ ...props }: ColumnVisibilityProps) => {
			const { name, isSelected } = props

			return (
				<Row
					justify={'space-between'}
					align={'center'}
					onClick={() => {
						onRowClick({
							...props,
							isSelected: !isSelected
						})
						// let e : string = eventName
						// analytics.track(SEGMENT_EVENTS[e], {
						// 	state : !isSelected
						// })
					}}
				>
					<Typography>{name}</Typography>
					<AntSwitch
						checked={isSelected}
						inputProps={{ 'aria-label': 'ant design' }}
					/>
				</Row>
			)
		},
		[onRowClick]
	)

	return (
		<>
			<OutlinedButton onClick={handleOnCLick}>
				<MoreVert fontSize="small" />
			</OutlinedButton>
			<Popover
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right'
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right'
				}}
				sx={{ boxShadow: 1000 }}
			>
				<Container>
					<Header>
						<Text
							variant={'body2'}
							color={theme.palette.background.default}
							fontWeight={500}
						>
							Turn off toggle to hide the column from inventory
						</Text>
					</Header>

					<Body>
						{columnVisibility.map(inventory => (
							<Item key={inventory.value} {...inventory} />
						))}
					</Body>
				</Container>
			</Popover>
		</>
	)
}
