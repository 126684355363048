export const convertNumber = (num: number | string = 0): number => {
	const amount = parseFloat(`${num}`)

	if (Number.isNaN(amount)) {
		return 0
	}

	if (Number.isInteger(amount)) {
		return amount
	}

	return parseFloat(amount.toFixed(2))
}

export const convertAmountToPercentage = (
	total: number,
	amount: number
): number => {
	if (total === 0) {
		return 0
	}
	return (amount * 100) / total
}

export const convertPercentageToAmount = (
	amount: number,
	percentage: number
): number => {
	return (amount * percentage) / 100
}
