import moment from 'moment'
import type { InvoiceItemProps } from 'typings'
import type { LoadsheetType } from 'typings/loadsheet'

export const LOADSHEET_INITIAL_VALUE: LoadsheetType = {
	deleted: {
		status: false,
		asOf: null,
		permanentDeletionDate: null
	},
	user: '',
	business: '',
	businessInfo: {
		phone: {
			number: '',
			countryCode: '',
			countryInitials: ''
		},
		address: '',
		businessName: '',
		email: '',
		profilePic: ''
	},
	invoiceItems: [],
	totalAmountDue: 0,
	totalQuantity: 0,
	totalDistinctStocks: 4,
	quantityStockMap: {},
	pdfKey: null,
	invoices: [],
	metaData: {
		additonalInfo: {
			driverName: '',
			vehicle: '',
			notes: ''
		},
		config: {
			showInvoiceSummary: true
		}
	},
	dispatchDate: moment().format('YYYY-MM-DD')
}

export const LoadsheetList = {
	edges: [
		{
			user: '65377ab1203e31af57c02b82',
			business: '65377ad1cf415908b05f8d7f',
			businessInfo: {
				address: 'This is my address',
				phone: {
					number: '3333333333',
					countryCode: '92',
					countryInitials: 'PK'
				},
				businessName: 'POC'
			},
			invoiceItems: [
				{
					user: '65377ab1203e31af57c02b82',
					business: '65377ad1cf415908b05f8d7f',
					contact: {
						phone: {
							number: '3434456565',
							countryCode: '92',
							countryInitials: 'PK'
						},
						id: '65377ae39f03e0416ed23fc9',
						name: 'Customer A',
						businessRelationship: ['supplier'],
						email: '',
						cnic: ''
					},
					items: [
						{
							stockId: '65377b110ced7472f7c7624f',
							name: 'Apple i phone 14',
							code: '434354A',
							unit: 'count',
							quantity: 1,
							perItemSellPrice: 455,
							perItemCostPrice: 30,
							sellCurrency: 'PKR',
							_id: '654c83f82af082510f4abe7c'
						},
						{
							stockId: '65377b110ced7472f7c7624f',
							name: 'Apple i phone 14',
							code: '434354A',
							unit: 'count',
							quantity: 17,
							perItemSellPrice: 455,
							perItemCostPrice: 30,
							sellCurrency: 'PKR',
							_id: '654c83f82af082510f4abe7c'
						}
					],
					status: 'publish',
					id: '654c83f82af082510f4abe7b',
					_id: '654c83f82af082510f4abe7b'
				}
			],
			deleted: {
				status: false,
				asOf: null,
				permanentDeletionDate: null
			},
			comments: '',
			friendlyId: '1',
			pdfKey: null,
			printingDetails: {
				isPrinted: false,
				lastPrintedOn: null
			},
			createdAt: '2023-11-09T08:05:27.172Z',
			updatedAt: '2023-11-09T08:05:27.172Z',
			__v: 0
		},
		{
			user: '65377ab1203e31af57c02b82',
			business: '65377ad1cf415908b05f8d7f',
			businessInfo: {
				address: 'This is my address',
				phone: {
					number: '3333333333',
					countryCode: '92',
					countryInitials: 'PK'
				},
				businessName: 'POC'
			},
			invoiceItems: [
				{
					user: '65377ab1203e31af57c02b82',
					business: '65377ad1cf415908b05f8d7f',
					contact: {
						phone: {
							number: '3434456565',
							countryCode: '92',
							countryInitials: 'PK'
						},
						id: '65377ae39f03e0416ed23fc9',
						name: 'Customer A',
						businessRelationship: ['supplier'],
						email: '',
						cnic: ''
					},
					items: [
						{
							stockId: '65377b110ced7472f7c7624f',
							name: 'Apple i phone 14',
							code: '434354A',
							unit: 'count',
							quantity: 1,
							perItemSellPrice: 455,
							perItemCostPrice: 30,
							sellCurrency: 'PKR',
							_id: '654c83f82af082510f4abe7c'
						}
					],
					status: 'publish',
					id: '654c83f82af082510f4abe7b',
					_id: '654c83f82af082510f4abe7b'
				}
			],
			deleted: {
				status: false,
				asOf: null,
				permanentDeletionDate: null
			},
			comments: '',
			friendlyId: '2',
			pdfKey: null,
			printingDetails: {
				isPrinted: false,
				lastPrintedOn: null
			},
			createdAt: '2023-11-09T08:05:27.172Z',
			updatedAt: '2023-11-09T08:05:27.172Z',
			__v: 0
		}
	],
	pageInfo: {
		currentPage: 1,
		edgesPerPage: 1,
		totalPages: 36
	}
}

export const sortLoadSheetInvoices = (loadSheet: LoadsheetType) => {
	let stocks: any = []
	const stockMap: any = {}
	if (loadSheet?.invoiceItems.length) {
		loadSheet.invoiceItems.forEach(invoice => {
			if (invoice?.items.length)
				invoice.items.forEach((item: InvoiceItemProps) => {
					const id = item.stockId || ''
					if (stockMap[id]) {
						stockMap[id].quantity += item.quantity
					} else {
						stockMap[id] = {
							...item,
							quantity: item.quantity
						}
					}
				})
			stocks = Object.values(stockMap)
		})
	}
	stocks.sort((a: any, b: any) => a.name.localeCompare(b.name))
	return stocks
}
