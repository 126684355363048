import { axios } from 'store'
import queryString from 'query-string'
import type {
	DataResponseType,
	QuotationStatusType,
	QuotationType
} from 'typings'

export const getQuotationList = async (
	businessId: string,
	payload: {
		page: number
		limit: number
		deleted?: boolean
		status?: QuotationStatusType[]
		searchKey?: string
	}
) => {
	const url = `/quotations/list?business=${businessId.replaceAll(
		'"',
		''
	)}&sortOrder=DESC&sortBy=initiationAt&${queryString.stringify(payload)}`

	try {
		const { data } = await axios.get<DataResponseType<QuotationType>>(url)

		return data
	} catch (error: any) {
		throw error.response.data
	}
}

export const getAQuotation = async (quotationId: string) => {
	const url = `/quotations/${quotationId}`

	try {
		const { data } = await axios.get<QuotationType>(url)

		return data
	} catch (error: any) {
		throw error.response.data
	}
}

export const recycleRestoreQuotation = async (quotationId: string[]) => {
	const url = `/quotations/mark-deleted?${queryString.stringify({
		quotationId
	})}`

	try {
		const { data } = await axios.patch(url)

		return data
	} catch (error: any) {
		throw error.response.data
	}
}

export const updateAnQuotation = async (
	quotationId: string,
	payload: Partial<QuotationType>
) => {
	const url = `/quotations/${quotationId}`

	try {
		const { data } = await axios.patch(url, payload)

		return data
	} catch (error: any) {
		throw error.response.data
	}
}

export const createAQuotation = async (payload: QuotationType) => {
	const url = '/quotations'

	try {
		const { data } = await axios.post(url, payload)

		return data
	} catch (error: any) {
		throw error.response.data
	}
}

export const deleteQuotation = async (
	businessId: string,
	payload: {
		quotationId: string[]
	}
) => {
	const url = `/quotations?${queryString.stringify(payload)}`

	try {
		const { data } = await axios.delete(url)

		return data
	} catch (error: any) {
		throw error.response.data
	}
}
