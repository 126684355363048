import { Fade, Popper, TableCell, TableRow, Typography } from '@mui/material'
import type { Dispatch, SetStateAction } from 'react'
import * as React from 'react'
import { styled } from '@mui/system'
import { HiOutlinePlusCircle } from 'react-icons/hi'
import PopupState, { bindPopper, bindToggle } from 'material-ui-popup-state'
import { FlexCol, FlexRow } from 'components/atoms/Flex'
import { CheckBox, Gutter } from 'components'

const StyledCell = styled(TableCell)`
	background-color: ${({ theme }) => theme.palette.colors.gray['100']};
	padding: 8px 16px;
	width: 100%;
`

const Text = styled(Typography)`
	color: ${({ theme }) => theme.palette.colors.gray['500']};
	font-weight: 500;
`

const SmallCell = styled(StyledCell)`
	color: ${({ theme }) => theme.palette.colors.gray['500']};
	min-width: 150px;
	max-width: 200px;
`

const Amount = styled(StyledCell)<{ isVoid?: boolean }>`
	color: ${({ theme }) => theme.palette.colors.gray['500']};
`

const Option = styled(FlexRow)`
	padding: 8px 14px;
	width: 100%;

	:hover {
		cursor: pointer;
		background-color: ${({ theme }) => theme.palette.colors['gray']['50']};
	}
`

const Wrapper = styled(FlexCol)`
	background-color: ${({ theme }) => theme.palette.background.default};
	border: 1px solid ${({ theme }) => theme.palette.background.paper};
	border-radius: 8px;
	margin-top: 5px;
	box-shadow: 0 12px 16px -4px rgba(16, 24, 40, 0.08),
		0 4px 6px -2px rgba(16, 24, 40, 0.03);
`

type Props = {
	isOpen: boolean
	setIsDiscountVisible: Dispatch<SetStateAction<boolean>>
}

export const TableHeader: React.ComponentType<Props> = ({
	isOpen,
	setIsDiscountVisible
}) => {
	return (
		<TableRow>
			<SmallCell variant="head" align={'left'}>
				<Text variant={'caption'}>ITEM DETAILS</Text>
			</SmallCell>
			<SmallCell variant="head" align={'left'} width={150}>
				<Text variant={'caption'}>UNIT</Text>
			</SmallCell>
			<SmallCell variant="head" align={'right'} width={120}>
				<Text variant={'caption'}>QUANTITY</Text>
			</SmallCell>
			<SmallCell variant="head" align={'right'} width={120}>
				<Text variant={'caption'}>SELLING PRICE</Text>
			</SmallCell>
			{isOpen && (
				<SmallCell variant="head" align={'right'} width={120}>
					<Text variant={'caption'}>DISCOUNT</Text>
				</SmallCell>
			)}
			<SmallCell variant="head" align={'right'} width={120}>
				<Text variant={'caption'}>LINE TOTAL</Text>
			</SmallCell>
			<Amount variant="head" align={'right'} width={50}>
				<PopupState
					variant="popper"
					popupId="demo-popup-popper"
					disableAutoFocus={true}
				>
					{popupState => (
						<>
							<HiOutlinePlusCircle {...bindToggle(popupState)} />
							<Popper
								{...bindPopper(popupState)}
								transition
								placement="bottom-end"
							>
								{({ TransitionProps }) => (
									<Fade {...TransitionProps} timeout={350}>
										<Wrapper>
											<FlexCol
												style={{
													minWidth: 220
												}}
											>
												<Option
													align={'center'}
													onClick={() => {
														localStorage.setItem(
															'isDiscountVisible',
															JSON.stringify(
																!isOpen
															)
														)
														setIsDiscountVisible(
															!isOpen
														)

														popupState.close()
													}}
													key={'pi'}
												>
													<CheckBox
														checked={isOpen}
													/>
													<Gutter gap={0.5} />
													<Typography
														variant={'body2'}
													>
														In Line Discount
													</Typography>
												</Option>
											</FlexCol>
										</Wrapper>
									</Fade>
								)}
							</Popper>
						</>
					)}
				</PopupState>
			</Amount>
		</TableRow>
	)
}
