import { Form1, FormStep1Schema } from 'screen/Onboarding/Form/Form1'
import { Form2, FormStep2Schema } from 'screen/Onboarding/Form/Form2'
import { Form3, FormStep3Schema } from 'screen/Onboarding/Form/Form3'
import { Form4, FormStep4Schema } from 'screen/Onboarding/Form/Form4'

export const ONBOARDINGFORM = [
	{
		screenForm: Form1,
		name: 'fullName',
		validationSchema: FormStep1Schema
	},
	{
		screenForm: Form2,
		name: 'name',
		validationSchema: FormStep2Schema
	},
	{
		screenForm: Form3,
		name: 'businessType',
		validationSchema: FormStep3Schema
	},
	{
		screenForm: Form4,
		name: 'supplyChainRole',
		validationSchema: FormStep4Schema
	}
]

export const SUPPLY_CHAIN_POSITION = [
	{ id: 0, name: 'Manufacturer', value: 'manufacturer', color: 'orange' },
	{ id: 1, name: 'Retailer', value: 'retailer', color: 'green' },
	{ id: 2, name: 'Supplier', value: 'supplier', color: 'purple' },
	{ id: 3, name: 'Distributor', value: 'distributor', color: 'blue' }
]
