import {
	styled,
	Typography,
	useMediaQuery,
	useTheme,
	Tooltip,
	tooltipClasses,
	type TooltipProps
} from '@mui/material'
import { Button, FieldTextInput, Gutter, InitiationDate } from 'components'
import { FlexRow } from 'components/atoms/Flex'
import { CalculatorModal } from 'components/app'
import { LAYOUT_BREAKPOINT_WIDTH, LAYOUT_PADDING, SEGMENT_EVENTS } from 'data'
import { useFormikContext } from 'formik'
import { objectPick } from 'helpers/object'
import React, { useState } from 'react'
import { AiOutlineCalculator } from 'react-icons/ai'
import { MdOutlineKeyboardArrowDown } from 'react-icons/md'
import analytics from 'utils/segment'
import { TitleBar } from '../Tables'
import { SelectContactModal } from 'components/app/ContactsModal/SelectContactModal'
import { InfoOutlined, NoteOutlined, SaveOutlined } from '@mui/icons-material'
import type { ColorsCodeType } from 'typings'

const CalculatorDiv = styled(FlexRow)`
	padding: 3px 5px;
	border: 1px solid ${({ theme }) => theme.palette.colors.gray['300']};
	font-size: 15px;
	border-radius: 5px;
	align-items: center;
	color: ${({ theme }) => theme.palette.colors.gray['700']};
	font-weight: 500;

	:hover {
		cursor: pointer;
	}
`

const HeaderText = styled(Typography)`
	margin-bottom: 3px;

	@media (max-width: ${LAYOUT_BREAKPOINT_WIDTH}px) {
		margin-bottom: 0;
	}
`

const Header = styled('div')`
	position: sticky;
	top: 0;
	z-index: 10;
	background-color: ${({ theme }) => theme.palette.background.default};
`

const MobileWrapper = styled(FlexRow)`
	@media (max-width: ${LAYOUT_BREAKPOINT_WIDTH}px) {
		display: none;
	}
`

const DraftButton = styled(Button)`
	background-color: ${({ theme }) => theme.palette.background.default};
	border: 1px solid ${({ theme }) => theme.palette.colors.gray[300]};
	color: ${({ theme }) => theme.palette.colors.gray[700]};
	min-width: 0;
	min-height: 32px;
	border-radius: 5px;
	padding: 0px 9px;

	:hover {
		background-color: ${({ theme }) => theme.palette.colors.gray[100]};
	}
`

const StyledPrimaryButton = styled(Button)`
	min-width: 0;
	min-height: 32px;
	padding: 0px 10px;
	border-radius: 5px;
	color: ${({ theme }) => theme.palette.colors.gray['900']};
`

const Wrapper = styled(FlexRow)`
	width: 100%;
	flex-wrap: nowrap;
	align-items: center;
	justify-content: space-between;
	padding: 9px 16px 8px 16px;
	border-bottom: 1px solid ${({ theme }) => theme.palette.colors.gray['200']};
`

const StyledFlexRow = styled(FlexRow)`
	padding: ${LAYOUT_PADDING}px;
	align-items: center;
	justify-content: space-between;
`

const PrivateModeWrapper = styled(FlexRow)`
	align-items: center;
	background-color: ${({ theme }) => theme.palette.colors.gray['100']};
	padding: 2.67px 8px;
	border-radius: 11px;
	color: ${({ theme }) => theme.palette.colors.gray['700']};
`

const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
	<Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
	[`& .${tooltipClasses.arrow}`]: {
		color: theme.palette.common.black
	},
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: theme.palette.common.black,
		textAlign: 'center',
		minWidth: 418
	}
}))

export const QuotationHeader = ({
	isQuotation,
	id
}: {
	isQuotation: boolean
	id?: string
}) => {
	const [isCalculatorOpen, setIsCalculatorOpen] = useState(false)

	const theme = useTheme()
	const isMobile = useMediaQuery(`(max-width:${LAYOUT_BREAKPOINT_WIDTH}px)`)

	const { colors } = theme.palette

	const [openSelectContactModal, setOpenSelectContactModal] =
		useState<boolean>(false)

	const handleCloseSelectContactModal = () => setOpenSelectContactModal(false)

	const { setFieldValue, handleSubmit, isSubmitting, dirty } =
		useFormikContext()

	const onSaveDraft = () => {
		setFieldValue('isDraft', true)
		setFieldValue('status', 'private')
		analytics.track(SEGMENT_EVENTS.QUO_SAVE_DRAFT)
		handleSubmit()
	}

	const onPreview = () => {
		setFieldValue('isDraft', false)
		setFieldValue('status', 'approved')
		analytics.track(SEGMENT_EVENTS.QUO_PREVIEW)
		handleSubmit()
	}

	return (
		<>
			<Header>
				<Wrapper>
					<FlexRow align="center">
						<HeaderText
							variant={isMobile ? 'body2' : 'h6'}
							fontWeight={'medium'}
						>
							{id ? 'Edit' : 'Create New'} Quotation
						</HeaderText>
						<Gutter gap={0.5} />
						<BootstrapTooltip
							title={
								'Only you can view the cost calculation for quotations. Preview the Quotation to see what your customers will be shown.'
							}
						>
							<PrivateModeWrapper>
								<InfoOutlined fontSize="inherit" />
								<Gutter gap={0.2} />
								<Typography
									variant="caption"
									fontWeight={'medium'}
								>
									Private Mode
								</Typography>
							</PrivateModeWrapper>
						</BootstrapTooltip>
					</FlexRow>

					<FlexRow>
						<DraftButton
							onClick={onSaveDraft}
							disabled={
								(isQuotation
									? !dirty && !isQuotation
									: !dirty) || isSubmitting
							}
						>
							<NoteOutlined fontSize="small" />
							<MobileWrapper>
								<Gutter gap={0.5} />
								{'Save Draft'}
							</MobileWrapper>
						</DraftButton>
						<Gutter gap={isMobile ? 0.5 : 1} />
						<StyledPrimaryButton
							buttonColor={colors.yellow['300'] as ColorsCodeType}
							disabled={
								(isQuotation
									? !dirty && !isQuotation
									: !dirty) || isSubmitting
							}
							onClick={onPreview}
						>
							<SaveOutlined fontSize="small" />
							<MobileWrapper>
								<Gutter gap={0.5} />
								{'Save and View Quotation'}
							</MobileWrapper>
						</StyledPrimaryButton>
					</FlexRow>
				</Wrapper>

				<StyledFlexRow>
					<FieldTextInput
						name="contact.name"
						onClick={() => {
							if (id === undefined || id === '0') {
								analytics.track(SEGMENT_EVENTS.SELECT_CONTACT, {
									screen: 'new_quotation'
								})
								setOpenSelectContactModal(true)
							}
						}}
						InputProps={{
							endAdornment: (
								<MdOutlineKeyboardArrowDown
									style={{ cursor: 'pointer' }}
								/>
							),
							startAdornment: (
								<Typography
									style={{ marginRight: '5px' }}
									color={colors.green['900']}
								>
									To:
								</Typography>
							)
						}}
						size="small"
						placeholder="Select Contact"
						disabled={!(id === undefined || id === '0')}
					/>
					<SelectContactModal
						onContactSelect={contact =>
							setFieldValue(
								'contact',
								objectPick(
									contact,
									'name',
									'id',
									'businessName',
									'phone',
									'address',
									'businessRelationship'
								)
							)
						}
						segmentEventCall={() =>
							analytics.track(
								SEGMENT_EVENTS.SELECT_CONTACT_SUCCESS,
								{
									screen: 'new_quotation'
								}
							)
						}
						onClose={handleCloseSelectContactModal}
						open={openSelectContactModal}
					/>

					<TitleBar>
						<InitiationDate
							name={'initiationAt'}
							sx={{ marginTop: '5px' }}
						/>
						<CalculatorDiv
							style={{ margin: '0px 0px 10px 5px' }}
							onClick={() => {
								analytics.track(SEGMENT_EVENTS.QUO_CALCULATOR)
								setIsCalculatorOpen(true)
							}}
						>
							<AiOutlineCalculator color="green" />
							<Gutter gap={0.3} />
							Calculator
						</CalculatorDiv>
					</TitleBar>
				</StyledFlexRow>
			</Header>
			<CalculatorModal
				open={isCalculatorOpen}
				onClose={() => setIsCalculatorOpen(false)}
			/>
		</>
	)
}
