import React, { useState } from 'react'
import { styled } from '@mui/system'
import { Button, Drawer, Typography, useTheme } from '@mui/material'
import { FlexCol, FlexRow } from 'components/atoms/Flex'
import { FieldTextInput, Gutter } from 'components'
import { Close, KeyboardArrowRight } from '@mui/icons-material'
import { Formik } from 'formik'
import type { internalCommentType } from 'typings'
import * as Yup from 'yup'
import { createInternalComment, editInternalComment } from 'api/invoice'
import { useSettings } from 'context/settings'
import { useAppErrors } from 'hooks'
import { useSnackbar } from 'notistack'
import { UploadComponent } from './UploadComponent'
import { TransparentButton } from 'components/atoms/Button/TransparentButton'

const Wrapper = styled(FlexCol)`
	border: 1px solid ${({ theme }) => theme.palette.colors.gray['300']};
	border-radius: 8px;
	width: 95%;
	max-width: 95%;
`

const UpperWrapper = styled(Button)`
	width: 100%;
	padding: 16px;
	justify-content: space-between;
	align-items: center;
	color: ${({ theme }) => theme.palette.colors.black['900']};
`

const InformationText = styled(Typography)`
	color: ${({ theme }) => theme.palette.colors.gray['500']};
	font-size: 12px;
	line-height: 18px;
`

const StyledDrawer = styled(Drawer)`
	z-index: 100;
`

const DrawerContainer = styled(FlexCol)`
	flex: 1;
	padding: 16px 16px 24px 16px;
	max-width: 320px;
	justify-content: space-between;
	background-color: ${({ theme }) => theme.palette.background.default};
`

const TopRow = styled(FlexRow)`
	width: 100%;
	align-items: center;
	justify-content: space-between;
`

const ArrowRightIcon = styled(KeyboardArrowRight)`
	font-size: 30px;
`

const CloseIcon = styled(Close)`
	font-size: 16px;
`

const StyledButton = styled(Button)`
	border-radius: 8px;
	color: ${({ theme }) => theme.palette.background.default};
	width: 100%;
	font-weight: 600;
`

const validationSchema = Yup.object().shape({
	comment: Yup.string().min(1, '').max(300, '')
})

type Props = {
	invoiceId: string
	internalComment?: internalCommentType
	onSuccess?(comment: internalCommentType): void
}

export const InternalComment: React.ComponentType<Props> = ({
	invoiceId,
	internalComment,
	onSuccess
}) => {
	const [drawerOpen, setDrawerOpen] = useState(false)
	const [loading, setLoading] = React.useState(false)
	const [isDisable, setIsDisable] = React.useState(!!internalComment)
	const [uploadError, setUploadError] = React.useState(false)
	const [maxSizeError, setMaxSizeError] = React.useState('')
	const theme = useTheme()
	const { business } = useSettings()
	const { setAppError } = useAppErrors()
	const { enqueueSnackbar } = useSnackbar()
	const { colors } = theme.palette

	const onSubmit = async (values: any, actions: any) => {
		let finalValue = values.file
		if (uploadError && internalComment) {
			finalValue = internalComment?.file
		}
		const finalObj = {
			...values,
			file: finalValue,
			business
		}
		try {
			actions.setSubmitting(true)
			if (values.id) {
				await editInternalComment(invoiceId, values.id, finalObj)
				enqueueSnackbar('Successfully saved edited comment', {
					variant: 'success'
				})
				onSuccess?.(finalObj)
			} else {
				await createInternalComment(invoiceId, finalObj)
				enqueueSnackbar('Successfully saved comment', {
					variant: 'success'
				})
				onSuccess?.(finalObj)
			}
			actions.resetForm({ values: { ...values } })
		} catch (e: any) {
			setAppError(e, actions)
		} finally {
			actions.setSubmitting(false)
			setIsDisable(true)
		}
	}

	return (
		<Wrapper>
			<UpperWrapper onClick={() => setDrawerOpen(!drawerOpen)}>
				<FlexRow align="center">
					<Typography variant="subtitle2" color={colors.gray['700']}>
						Internal Comments
					</Typography>
				</FlexRow>
				<ArrowRightIcon />
			</UpperWrapper>
			<Formik
				initialValues={
					internalComment || {
						comment: '',
						file: { url: '', name: '' }
					}
				}
				validationSchema={validationSchema}
				onSubmit={onSubmit}
			>
				{({ handleSubmit, dirty, values, errors, isSubmitting }) => (
					<StyledDrawer
						anchor={'right'}
						open={drawerOpen}
						onClose={() => setDrawerOpen(false)}
						PaperProps={{
							style: {
								borderLeftColor: colors.gray['300']
							}
						}}
					>
						<DrawerContainer>
							<FlexCol>
								<TopRow>
									<Typography
										variant="body2"
										fontWeight={'medium'}
										color={colors.gray['700']}
									>
										Internal Comments
									</Typography>
									<CloseIcon
										onClick={() => setDrawerOpen(false)}
									/>
								</TopRow>
								<Gutter spacing={0.5} />
								<InformationText variant="caption">
									These comments will never be shown to the
									customer and are for your eyes only.
								</InformationText>

								<Gutter spacing={1.5} />
								<FieldTextInput
									disabled={isDisable}
									multiline
									rows={7.8}
									error={errors && errors.comment === ''}
									name={'comment'}
									placeholder={'Enter Comments'}
									style={{ width: '288px' }}
									InputProps={{
										style: {
											fontSize: 12,
											padding: 14,
											backgroundColor: isDisable
												? colors.gray['100']
												: colors.white['500']
										}
									}}
									inputProps={{ maxLength: 300 }}
								/>
								<Gutter spacing={0.75} />
								<FlexRow
									justify={'space-between'}
									style={{ width: '100%' }}
								>
									<UploadComponent
										disabled={isDisable}
										fieldName={'file'}
										invoiceId={invoiceId}
										setMaxSizeError={setMaxSizeError}
										uploadError={uploadError}
										setUploadError={setUploadError}
										loading={loading}
										setLoading={setLoading}
									/>

									{!isDisable && (
										<Typography
											variant={'caption'}
											color={
												errors && errors.comment === ''
													? colors.red['500']
													: colors.gray['900']
											}
											fontSize={10}
										>
											{values.comment.length}/300
										</Typography>
									)}
								</FlexRow>
								{!isDisable && (
									<Typography
										variant={'caption'}
										fontSize={14}
										color={'error'}
									>
										{maxSizeError}
									</Typography>
								)}
							</FlexCol>

							<FlexCol align="center" style={{ width: '100%' }}>
								{/* ToDo: Put Last Comment Date Saved */}
								{/* {internalComment && (
									<Typography
										variant="caption"
										color={colors.gray['500']}
										textAlign={'center'}
										fontSize={12}
									>
										Last comment saved on 26/06/2023
									</Typography>
								)} */}
								<Gutter spacing={0.75} />
								{isDisable && (
									<TransparentButton
										onClick={() => {
											setIsDisable(false)
											setMaxSizeError('')
										}}
									>
										<Typography variant={'caption'}>
											Edit Comment
										</Typography>
									</TransparentButton>
								)}
								{!isDisable && (
									<StyledButton
										variant={'contained'}
										onClick={() => {
											if (!dirty) {
												setIsDisable(true)
												setMaxSizeError('')
											} else {
												handleSubmit()
											}
										}}
										disabled={loading || isSubmitting}
									>
										Save Comment
									</StyledButton>
								)}
							</FlexCol>
						</DrawerContainer>
					</StyledDrawer>
				)}
			</Formik>
		</Wrapper>
	)
}
