import { Typography } from '@mui/material'
import { Button, Gutter } from 'components'
import { FlexCol } from 'components/atoms/Flex'
import { AiOutlinePlus } from 'react-icons/ai'
import React, { useState } from 'react'
import { styled } from '@mui/system'
import NoOrderImage from 'assets/orders/NoOrder.png'
import { NewOrderModal } from 'screen/OrderCenter/NewOrder/NewOrderModal'
import analytics from 'utils/segment'
import { SEGMENT_EVENTS } from 'data'
import { useSnackbar } from 'notistack'
import { IoMdLink } from 'react-icons/io'
import { useSettings } from 'context/settings'
import { TransparentButton } from 'components/atoms/Button/TransparentButton'

const Image = styled('img')`
	width: 250px;
`
const NoOrderContainer = styled(FlexCol)`
	height: 100%;
	width: 100%;
	background-color: ${({ theme }) => theme.palette.background.default};
`

const Btn = styled(Button)`
	padding: 8px 15px;
	color: ${({ theme }) =>
		theme.palette.mode === 'dark'
			? theme.palette.colors.white['300']
			: theme.palette.colors.black['300']};
	background-color: ${({ theme }) => theme.palette.colors.yellow['300']};
	:hover {
		background-color: ${({ theme }) => theme.palette.colors.yellow['200']};
	}
`

type NoOrderProps = {
	getInitialOrderList: () => void
}

export const NoOrder: React.ComponentType<NoOrderProps> = ({
	getInitialOrderList
}) => {
	const [isAddModalOpen, setIsAddModalOpen] = useState(false)
	const { enqueueSnackbar } = useSnackbar()
	const { business } = useSettings()

	const onSuccess = () => {
		setIsAddModalOpen(false)
		getInitialOrderList()
	}

	const onCopy = async () => {
		await navigator.clipboard.writeText(
			`${window.location.origin}/order-form/${business}`
		)
		enqueueSnackbar('Order Form link copied', {
			variant: 'success'
		})
	}

	const handleRecordNewOrder = () => {
		setIsAddModalOpen(true)
		analytics.track(SEGMENT_EVENTS.NEW_ORDER)
	}

	return (
		<>
			<NoOrderContainer align="center" justify="center">
				<Image src={NoOrderImage} />
				<Typography variant="caption">
					Add your first Order or Inquiry here and start managing your
					business requests with ease!
				</Typography>
				<Gutter spacing={1} />
				<Btn
					onClick={handleRecordNewOrder}
					type={'submit'}
					startIcon={<AiOutlinePlus />}
				>
					Record new Order/Inquiry
				</Btn>
				<Gutter spacing={1} />
				<TransparentButton onClick={onCopy}>
					<IoMdLink size={20} />
					<Typography variant={'caption'} style={{ marginLeft: 5 }}>
						Share Order Form Link
					</Typography>
				</TransparentButton>
			</NoOrderContainer>
			<NewOrderModal
				open={isAddModalOpen}
				onClose={() => setIsAddModalOpen(false)}
				onSuccess={onSuccess}
			/>
		</>
	)
}
