import React from 'react'
import {
	FieldNumberInput,
	FormatAmount,
	Gutter,
	ZigZagContainer
} from 'components'
import { FlexRow } from 'components/atoms/Flex'
import { Divider, styled, Typography, useTheme } from '@mui/material'
import { AiOutlinePercentage } from 'react-icons/ai'
import { FieldSelectTaxes } from 'components/molecules/FieldSelectTaxes'
import {
	calculateQuotationSubTotal,
	calculateQuotationTotal,
	SEGMENT_EVENTS
} from 'data'
import analytics from 'utils/segment'
import { POSITIVE_NUMBER_WITH_DECILMAL } from 'helpers'

const Label = styled(Typography)`
	color: ${({ theme }) => theme.palette.colors.green['900']};
	font-weight: 500;
`
const Row = styled(FlexRow)`
	justify-content: space-between;
	margin-top: 7px;
	align-items: center;
`

const InputWrapper = styled('div')`
	max-width: 150px;
	border-radius: 8px;
	background-color: ${({ theme }) => theme.palette.background.default}; ;
`

type Props = {
	values: any
}

export const Receipt: React.ComponentType<Props> = ({ values }) => {
	const theme = useTheme()
	const { colors } = theme.palette

	return (
		<ZigZagContainer backgroundColor={colors.blue['50']}>
			<Row>
				<Label variant="body2">Total items</Label>
				<Typography variant="body2">{values.items.length}</Typography>
			</Row>
			<Row>
				<Label variant="body2">Subtotal</Label>
				<Typography variant="body2">
					<FormatAmount
						variant={'body2'}
						limit={20}
						amount={calculateQuotationSubTotal(values)}
						style={{ color: 'black' }}
					/>
				</Typography>
			</Row>
			<Row>
				<Label variant="body2">Discount</Label>
				<InputWrapper>
					<FieldNumberInput
						name="discount.amount"
						placeholder="0.00"
						InputProps={{
							endAdornment: <AiOutlinePercentage />
						}}
						regex={POSITIVE_NUMBER_WITH_DECILMAL}
						type={'tel'}
						size="small"
						onClick={() =>
							analytics.track(SEGMENT_EVENTS.QUO_DISCOUNT)
						}
					/>
				</InputWrapper>
			</Row>
			<Row>
				<Label variant="body2">Tax</Label>
				<FieldSelectTaxes name={'taxes'} />
			</Row>
			<Gutter spacing={0.5} />
			<Divider />
			<Row>
				<Label variant="body2">Total</Label>
				<Typography variant="body2">
					<FormatAmount
						variant={'body2'}
						limit={20}
						amount={calculateQuotationTotal(values)}
						style={{ color: 'black' }}
					/>
				</Typography>
			</Row>
			<Gutter spacing={0.5} />
		</ZigZagContainer>
	)
}
