import React from 'react'
import type { DiscountType, InvoiceItemProps } from 'typings'
import {
	ClickAwayListener,
	Fade,
	InputAdornment,
	Popper,
	TableCell,
	Typography,
	useTheme
} from '@mui/material'
import { styled } from '@mui/system'
import {
	amountToLocal,
	convertAmountToPercentage,
	convertNumber,
	convertPercentageToAmount,
	POSITIVE_NUMBER
} from 'helpers'
import {
	FieldCurrencyInput,
	FieldNumberInput,
	FieldTextInput,
	Gutter
} from 'components'
import { InventoryAutoComplete } from 'screen/Invoices/NewCreate/Components/Table/InventoryAutoComplete'
import CloseIcon from '@mui/icons-material/Close'
import { useFormikContext } from 'formik'
import type { CurrentInvoiceProps } from 'screen/Invoices/NewCreate/Invoice'
import analytics from 'utils/segment'
import { DISCOUNT_OPTION, SEGMENT_EVENTS } from 'data'
import PopupState, { bindPopper, bindToggle } from 'material-ui-popup-state'
import {
	MdOutlineKeyboardArrowDown,
	MdOutlineKeyboardArrowUp
} from 'react-icons/md'
import { FlexCol, FlexRow } from 'components/atoms/Flex'
import { calculateDiscount, calculateDiscountPercentage } from 'helpers/mixed'

const StyledCell = styled(TableCell)`
	padding: 8px 16px;
`

const SmallCell = styled(StyledCell)`
	color: ${({ theme }) => theme.palette.colors.gray['500']};
	min-width: 150px;
	max-width: 200px;
	border-right: 1px solid ${({ theme }) => theme.palette.colors.gray['300']};
`

const ItemCell = styled(StyledCell)`
	color: ${({ theme }) => theme.palette.colors.gray['500']};
	min-width: 200px;

	border-right: 1px solid ${({ theme }) => theme.palette.colors.gray['300']};
`

const Box = styled(FlexRow)`
	padding-left: 10px;
	border-left: 1px solid ${({ theme }) => theme.palette.colors.gray['300']};
	flex-wrap: nowrap;
	align-items: center;

	:hover {
		cursor: pointer;
	}
`

const Wrapper = styled(FlexCol)`
	background-color: ${({ theme }) => theme.palette.background.default};
	border: 1px solid ${({ theme }) => theme.palette.background.paper};
	border-radius: 8px;
	margin-top: 5px;
	box-shadow: 0 12px 16px -4px rgba(16, 24, 40, 0.08),
		0 4px 6px -2px rgba(16, 24, 40, 0.03);
`

const Option = styled(FlexRow)`
	padding: 8px 14px;
	width: 100%;

	:hover {
		cursor: pointer;
		background-color: ${({ theme }) => theme.palette.colors['gray']['50']};
	}
`

const Amount = styled(StyledCell)<{ isVoid?: boolean }>`
	color: ${({ theme }) => theme.palette.colors.gray['500']};
`

type TableItemProps = {
	item: InvoiceItemProps
	index: number
	isOpen: boolean
}

export const TableItem: React.ComponentType<TableItemProps> = ({
	item,
	index,
	isOpen
}) => {
	const { values, setFieldValue } = useFormikContext<CurrentInvoiceProps>()

	const currentItem = values.items && values.items[index].name === ''
	const itemName = values.items && values.items[index].name
	const currentUnit = values.items && values.items[index].unit

	const seletedDiscount = DISCOUNT_OPTION.filter(
		f =>
			f.value ===
			(values.items ? values.items[index]?.discount?.type : 'percent')
	)

	const theme = useTheme()
	const {
		palette: { colors }
	} = theme

	const onValueChange = (type: string, amount: string) => {
		setFieldValue(
			`${values.items ? `items[${index}].discount` : 'random'}`,
			{
				...values.discount,
				type: type,
				amount: amount
			}
		)
	}

	const onTypeChange = (type: DiscountType) => {
		if (
			type === 'percent' && values.items
				? values.items[index].discount?.type !== 'percent'
				: false
		) {
			onValueChange(
				type,
				convertNumber(
					convertAmountToPercentage(
						parseFloat(
							`${
								values.items
									? values.items[index].perItemSellPrice
									: '0'
							}`
						),
						parseFloat(
							`${
								values.items
									? values.items[index].discount?.amount
									: '0'
							}`
						)
					)
				).toString()
			)
		} else if (type === 'flat' && values.discount?.type !== 'flat') {
			onValueChange(
				type,
				convertNumber(
					convertPercentageToAmount(
						parseFloat(
							`${
								values.items
									? values.items[index].perItemSellPrice
									: '0'
							}`
						),
						parseFloat(
							`${
								values.items
									? values.items[index].discount?.amount
									: '0'
							}`
						)
					)
				).toString()
			)
		}
	}

	const lineTotal = calculateDiscount(
		convertNumber(item.quantity || 0) *
			convertNumber(item.perItemSellPrice || 0),
		item.discount?.amount || 0,
		item.discount?.type || 'percent'
		// eslint-disable-next-line no-mixed-spaces-and-tabs
	)

	const onBlur = () => {
		if (
			values.items &&
			values.items[index].discount?.type === 'percent' &&
			(values.items[index].discount?.amount || 0) > 100
		) {
			setFieldValue(`items[${index}].discount.amount`, 100)
		} else if (
			values.items &&
			values.items[index].discount?.type !== 'percent' &&
			(values.items[index].discount?.amount || 0) >
				convertNumber(item.quantity || 0) *
					convertNumber(item.perItemSellPrice || 0)
		) {
			setFieldValue(`items[${index}].discount.amount`, '0')
		}
	}

	const totalPkr = calculateDiscountPercentage(
		convertNumber(item.quantity || 0) *
			convertNumber(item.perItemSellPrice || 0),
		item.discount?.amount || 0,
		item.discount?.type
	)

	return (
		<>
			<ItemCell variant="head" align={'left'}>
				{!currentItem && (
					<>
						<Typography
							color={colors.gray['900']}
							style={{ overflowWrap: 'break-word' }}
						>
							{itemName}
						</Typography>
						<Gutter spacing={0.2} />{' '}
					</>
				)}
				{currentItem && (
					<InventoryAutoComplete
						name={`items[${index}]`}
						item={item}
						index={index}
					/>
				)}
				{!currentItem && (
					<FieldTextInput
						name={`items[${index}].description`}
						style={{ width: '100%' }}
						placeholder={
							'Add a description to your item (Optional)'
						}
						size={'small'}
						onBlurCapture={(event: any) => {
							analytics.track(
								SEGMENT_EVENTS.INVOICE_DESCRIPTIoN_ADDED,
								{
									description: event.target?.value || '',
									module: 'invoice'
								}
							)
						}}
					/>
				)}
			</ItemCell>
			<SmallCell variant="head" align={'right'} width={150}>
				{!currentItem ? (
					<>
						<Gutter spacing={0.4} />
						<Typography color={colors.black['900']}>
							{currentUnit}
						</Typography>
					</>
				) : (
					<>-</>
				)}
			</SmallCell>
			<SmallCell variant="head" align={'right'} width={120}>
				<FieldNumberInput
					name={`items[${index}.quantity]`}
					size="small"
					placeholder="0"
					regex={POSITIVE_NUMBER}
					inputProps={{ style: { textAlign: 'end' } }}
				/>
			</SmallCell>
			<SmallCell variant="head" align={'left'}>
				<FieldCurrencyInput
					name={`items[${index}.perItemSellPrice]`}
					size="small"
					placeholder="0"
					regex={POSITIVE_NUMBER}
					inputProps={{ style: { textAlign: 'end' } }}
				/>
			</SmallCell>
			{isOpen && (
				<SmallCell
					variant="head"
					align={'right'}
					style={{ minWidth: 180 }}
				>
					<PopupState
						variant="popper"
						popupId="demo-popup-popper"
						disableAutoFocus={true}
					>
						{popupState => (
							<>
								<FieldNumberInput
									name={`items[${index}].discount.amount`}
									size={'small'}
									placeholder={'Amount'}
									style={{
										maxWidth: 200,
										width: '100%'
									}}
									onBlur={onBlur}
									InputProps={{
										endAdornment: (
											<InputAdornment
												position="end"
												{...bindToggle(popupState)}
											>
												<ClickAwayListener
													onClickAway={() =>
														popupState.close()
													}
												>
													<Box>
														<Typography>
															{seletedDiscount.length >
															0
																? seletedDiscount[0]
																		.name
																: 'PKR'}
														</Typography>
														<Gutter gap={0.2} />

														{popupState.isOpen ? (
															<MdOutlineKeyboardArrowUp />
														) : (
															<MdOutlineKeyboardArrowDown />
														)}
													</Box>
												</ClickAwayListener>
											</InputAdornment>
										)
									}}
								/>
								<Popper
									{...bindPopper(popupState)}
									transition
									placement="bottom-end"
								>
									{({ TransitionProps }) => (
										<Fade
											{...TransitionProps}
											timeout={350}
										>
											<Wrapper>
												<FlexCol
													style={{
														minWidth: 220
													}}
												>
													<Gutter spacing={0.5} />
													{DISCOUNT_OPTION.map(d => (
														<Option
															align={'center'}
															onClick={() => {
																onTypeChange(
																	d.value as DiscountType
																)

																popupState.close()
															}}
															key={d.name}
														>
															<Typography
																variant={
																	'body2'
																}
															>
																{d.name}
															</Typography>
														</Option>
													))}
												</FlexCol>
											</Wrapper>
										</Fade>
									)}
								</Popper>
							</>
						)}
					</PopupState>
					<Typography
						style={{ overflowWrap: 'break-word' }}
						variant={'caption'}
						color={colors.gray[600]}
					>
						{item.discount?.type === 'percent'
							? `PKR ${totalPkr.discountedAmount}`
							: `${totalPkr.discountPercentage}%`}
					</Typography>
				</SmallCell>
			)}
			<SmallCell variant="head" align={'right'} width={120}>
				<Typography
					style={{ overflowWrap: 'break-word' }}
					variant={'subtitle2'}
					fontWeight={600}
					color={colors.gray[900]}
				>
					{amountToLocal(convertNumber(lineTotal))}
				</Typography>
			</SmallCell>
			<Amount variant="head" align={'right'} width={50}>
				{/*{!currentItem && (*/}
				<CloseIcon
					onClick={() =>
						setFieldValue(
							'items',
							values.items?.filter((f, p) => {
								return index !== p
							})
						)
					}
				/>
				{/*)}*/}
			</Amount>
		</>
	)
}
