import type { PaletteMode } from '@mui/material'
import {
	colors,
	type secondaryColorOptions,
	type secondaryColors
} from './colors'
import { fontWeights, variants } from './typography'
import { breakpoints } from 'theme/breakpoints'

declare module '@mui/material/styles' {
	interface Palette {
		colors: Palette['primary']
	}

	interface PaletteOptions {
		colors: PaletteOptions['primary']
	}

	interface PaletteColor extends secondaryColors {
		darker?: string
	}

	interface SimplePaletteColorOptions extends secondaryColorOptions {
		darker?: string
	}
}

const lightPalette = {
	primary: {
		main: '#2C8B63',
		contrastText: '#000000',
		darker: '#267755'
	},
	secondary: {
		main: '#8b4747',
		light: '#267755'
	},
	divider: '#555',
	background: {
		default: '#FFFFFF',
		paper: '#F2F4F7'
	},

	text: {
		primary: '#000',
		secondary: '#2C8B63'
	},
	colors: {
		main: '#2C8B63',
		...colors
	}
}

const darkPalette = {
	primary: {
		main: '#2C8B63',
		contrastText: '#ffffff'
	},
	secondary: {
		main: '#8b4747'
	},
	divider: '#999',
	background: {
		default: '#222',
		paper: '#333'
	},
	text: {
		primary: '#fff',
		secondary: '#9e9e9e'
	},
	colors: {
		main: '#2C8B63',
		...colors
	}
}

const getDesignTokens = (mode: PaletteMode) => ({
	palette: {
		mode,
		...(mode === 'light' ? lightPalette : darkPalette)
	},
	typography: {
		button: { textTransform: undefined },
		fontFamily: ['Gilmer'].join(','),
		...variants,
		...fontWeights,
		letterSpacing: '-0.02em'
	},
	breakpoints
})

export { getDesignTokens }
