import React, {
	type Dispatch,
	type SetStateAction,
	useCallback,
	useEffect,
	useState
} from 'react'
import {
	Button,
	ClickAwayListener,
	Paper,
	styled,
	Typography,
	useTheme
} from '@mui/material'
import PopupState, { bindToggle } from 'material-ui-popup-state'
import { TextInput } from 'components/atoms'
import { KeyboardArrowDown } from '@mui/icons-material'
import { getContactInvoices } from 'api/invoice'
import { useAppErrors } from 'hooks'
import type { DataResponseType, InvoiceType, ResponsePageInfo } from 'typings'
import { convertNumber, formatId, objectOmit } from 'helpers'
import { useBottomScrollListener } from 'react-bottom-scroll-listener'
import { useFormikContext } from 'formik'
import {
	calculateInvoiceAdvanceAmount,
	calculateInvoiceDiscountAmount,
	calculateInvoiceExtraCharges,
	calculateInvoiceSubTotal,
	calculateInvoiceTaxes,
	SCROLLBAR_STYLE
} from 'data'
import { FlexRow } from 'components/atoms/Flex'
import { useDebouncedCallback } from 'use-debounce'

const InvText = styled(Typography)`
	color: ${({ theme }) => theme.palette.colors.gray['900']};
	font-weight: 500;
	font-size: 12px;
	padding-right: 6px;
`

const MenuDropDown = styled(Paper)`
	background-color: ${({ theme }) => theme.palette.background.default};
	border: 1px solid ${({ theme }) => theme.palette.background.paper};
	border-radius: 8px;
	box-shadow: 0 12px 16px -4px rgba(16, 24, 40, 0.08),
		0px 4px 6px -2px rgba(16, 24, 40, 0.03);
	width: 240px;
	max-height: 145px;
	overflow: auto;
	position: absolute;
	bottom: unset;
	left: 0;
	z-index: 1000;

	${SCROLLBAR_STYLE}
`

const NoInvoiceText = styled(Typography)`
	color: ${({ theme }) => theme.palette.colors.gray['500']};
	font-size: 14px;
`

interface InvoicePickerProps {
	contactId: string
	currentInvoice?: InvoiceType
	setCurrentInvoice: Dispatch<SetStateAction<InvoiceType | undefined>>
	setDueAmount: Dispatch<SetStateAction<number>>
}

export const InvoicePicker: React.ComponentType<InvoicePickerProps> = ({
	contactId,
	currentInvoice,
	setCurrentInvoice,
	setDueAmount
}) => {
	const [loading, setLoading] = useState<boolean>(true)
	const [dataSource, setDataSource] = useState<InvoiceType[]>([])
	const [pageInfo, setPageInfo] = useState<ResponsePageInfo>({
		totalPages: 1,
		currentPage: 1,
		edgesPerPage: 10
	})
	const [searchKey, setSearchKey] = useState<string>('')
	const [searchText, setSearchText] = useState<string>('')
	const { setFieldValue } = useFormikContext()

	const { setAppError } = useAppErrors()
	const debouncedSearch = useDebouncedCallback(
		text => setSearchText?.(text),
		500
	)

	const scrollRef = useBottomScrollListener(
		() => {
			fetchInvoiceList()
		},
		{
			offset: 10,
			debounce: 200
		}
	)

	const theme = useTheme()
	const {
		palette: { colors }
	} = theme

	const totalAmount = (invoice: InvoiceType) => {
		const totalItemCost = calculateInvoiceSubTotal(invoice)
		const totalExtraCharges = calculateInvoiceExtraCharges(invoice)
		const totalDiscount = calculateInvoiceDiscountAmount(
			objectOmit(invoice, 'id')
		)
		const baseAmt = totalItemCost + totalExtraCharges
		const baseAmtAfterDiscount = baseAmt - totalDiscount

		const advance = calculateInvoiceAdvanceAmount(objectOmit(invoice, 'id'))

		const totalTax = calculateInvoiceTaxes(invoice, baseAmtAfterDiscount)

		return Number((baseAmtAfterDiscount + totalTax - advance).toFixed(2))
	}

	const onSearch = (text: string) => {
		setSearchKey(text)
		debouncedSearch(text)
	}

	const getInitialInvoiceList = useCallback(async () => {
		try {
			setLoading(true)
			const res: DataResponseType<InvoiceType> = await getContactInvoices(
				contactId,
				{
					page: 1,
					limit: pageInfo.edgesPerPage,
					searchKey: searchText
						? convertNumber(searchText).toString()
						: searchText
				}
			)
			setDataSource(res.edges)
			setPageInfo(res.pageInfo)
		} catch (e: any) {
			setAppError(e)
		} finally {
			setLoading(false)
		}
	}, [contactId, pageInfo.edgesPerPage, setAppError, searchText])

	const fetchInvoiceList = async () => {
		try {
			setLoading(true)
			if (
				!loading &&
				pageInfo?.totalPages &&
				pageInfo?.totalPages >= pageInfo.currentPage + 1
			) {
				const res: DataResponseType<InvoiceType> =
					await getContactInvoices(contactId, {
						page: pageInfo.currentPage + 1,
						limit: pageInfo.edgesPerPage,
						searchKey: searchKey
							? convertNumber(searchKey).toString()
							: searchKey
					})
				setDataSource([...dataSource, ...res.edges])
				setPageInfo(res.pageInfo)
			}
		} catch (e: any) {
			setAppError(e)
		} finally {
			setLoading(false)
		}
	}

	const RenderRow = useCallback(
		({ invoice, onClick }: { invoice: InvoiceType; onClick: any }) => {
			return (
				<Button
					key={invoice.id}
					sx={{
						padding: '10px 16px',
						width: '100%',
						justifyContent: 'flex-start'
					}}
					onClick={onClick}
				>
					<Typography
						variant="body2"
						sx={{
							color: colors.gray['700']
						}}
					>
						{`INV-${formatId(invoice?.friendlyId)} (${
							invoice?.paymentStatus
						})`}
					</Typography>
				</Button>
			)
		},
		[colors.gray]
	)

	useEffect(() => {
		getInitialInvoiceList()
	}, [getInitialInvoiceList])

	return (
		<PopupState
			variant="popper"
			popupId="demo-popup-popper"
			disableAutoFocus={true}
		>
			{popupState => (
				<ClickAwayListener
					onClickAway={() => {
						onSearch('')
						popupState.close()
					}}
				>
					<div style={{ position: 'relative' }}>
						<TextInput
							{...bindToggle(popupState)}
							inputProps={{
								style: {
									fontSize: 14,
									padding: '10px 0px'
								}
							}}
							placeholder="00001"
							value={
								currentInvoice
									? formatId(currentInvoice?.friendlyId)
									: searchKey
							}
							sx={{ width: 240 }}
							type="tel"
							onChange={e => {
								setCurrentInvoice(undefined)
								onSearch(e.target.value)
							}}
							InputProps={{
								startAdornment: (
									<InvText variant="caption">INV</InvText>
								),
								endAdornment: (
									<KeyboardArrowDown
										fontSize="medium"
										sx={{
											color: colors.gray['500']
										}}
									/>
								)
							}}
						/>

						<MenuDropDown ref={scrollRef as any}>
							{popupState.isOpen &&
								!!dataSource.length &&
								dataSource.map(invoice => (
									<RenderRow
										key={invoice.id}
										invoice={invoice}
										onClick={() => {
											const amount = totalAmount(invoice)
											setCurrentInvoice(invoice)
											setFieldValue('amount', amount)
											setDueAmount(amount)
											onSearch('')
											popupState.close()
										}}
									/>
								))}

							{!dataSource.length && (
								<FlexRow
									sx={{
										padding: '10px 16px'
									}}
								>
									<NoInvoiceText
										variant="subtitle2"
										fontWeight={'regular'}
									>
										No Active Invoices Found
									</NoInvoiceText>
								</FlexRow>
							)}
						</MenuDropDown>
					</div>
				</ClickAwayListener>
			)}
		</PopupState>
	)
}
