import React from 'react'
import Table from '@mui/material/Table'
import { styled } from '@mui/material'
import TableContainer from '@mui/material/TableContainer'
import { useBottomScrollListener } from 'react-bottom-scroll-listener'
import { ContactsTable } from 'screen/Contact/ContactsTable'
import { SCROLLBAR_STYLE } from 'data'

const StyledTableContainer = styled(TableContainer)<{ height: number }>`
	min-height: ${({ height }) => height}px;
	max-height: ${({ height }) => height}px;
	background-color: ${({ theme }) => theme.palette.background.default};
	overflow: auto;
	${SCROLLBAR_STYLE}
`

export const ScrollableTable = ({
	tableHeight,
	fetchContacts,
	...props
}: any) => {
	const scrollRef = useBottomScrollListener(() => fetchContacts(), {
		offset: 10,
		debounce: 200
	})

	return (
		<StyledTableContainer ref={scrollRef as any} height={tableHeight}>
			<Table stickyHeader aria-label="sticky table">
				<ContactsTable isEditable={false} {...props} />
			</Table>
		</StyledTableContainer>
	)
}
