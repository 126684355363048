import React from 'react'
import { Typography } from '@mui/material'
import { styled } from '@mui/system'
import { FlexCol, FlexRow } from 'components/atoms/Flex'
import {
	calculateQuotationDiscountAmount,
	calculateQuotationExtraCharges,
	calculateQuotationSubTotal,
	calculateQuotationTaxes
} from 'data'
import { amountToLocal } from 'helpers'
import { objectOmit } from 'helpers/object'
import { convertNumber } from 'helpers/number'
import type { QuotationType } from 'typings'

const Wrapper = styled(FlexCol)`
	padding: 14px;
	grid-gap: 10px;
	margin-bottom: 1rem;
	border-radius: 7px;
	height: min-content;
	background-color: ${({ theme }) => theme.palette.colors.blue['50']};
`

const Row = styled(FlexRow)`
	display: grid;
	grid-template-columns: 2fr 1fr;
	justify-content: space-between;
	width: 100%;
	column-gap: 10px;
`

const Col = styled(FlexCol)`
	align-items: flex-end;
	flex: 1;
	width: 100%;
	height: 100%;
`

const TaxWrapper = styled(FlexCol)`
	margin-left: 10px;
	width: 100%;
	padding-right: 10px;
`

const Line = styled('div')`
	width: 100%;
	height: 1px;
	background-color: ${({ theme }) => theme.palette.colors.gray['300']};
	margin: 10px 0 0 0;
`

type Props = {
	title?: string
	currentQuotation: QuotationType
}

export const AmountDetails: React.ComponentType<Props> = ({
	currentQuotation
}) => {
	const { taxes, extraCharges } = currentQuotation
	const totalExtraCharges = calculateQuotationExtraCharges(
		objectOmit(currentQuotation, 'id')
	)

	const totalItemCost = calculateQuotationSubTotal(currentQuotation)

	const baseAmt = totalItemCost + totalExtraCharges
	const totalDiscount = calculateQuotationDiscountAmount(
		objectOmit(currentQuotation, 'id')
	)
	const baseAmtAfterDiscount = baseAmt - totalDiscount

	const totalTax = calculateQuotationTaxes(
		currentQuotation,
		baseAmtAfterDiscount
	)

	const totalAmount = baseAmtAfterDiscount + totalTax

	const Text = ({ txt }: { txt: string }) => {
		return <Typography variant={'caption'}>{txt}</Typography>
	}
	const Title = ({ txt }: { txt: string }) => {
		return <Typography variant={'subtitle2'}>{txt}</Typography>
	}

	return (
		<Wrapper>
			<Row>
				<Title txt={'Total items'} />
				<Col>
					<Text txt={currentQuotation.items.length.toString()} />
				</Col>
			</Row>
			<Row>
				<Title txt={'SubTotal (PKR):'} />
				<Col>
					<Text txt={amountToLocal(totalItemCost)} />
				</Col>
			</Row>
			{extraCharges &&
				extraCharges.map(e => (
					<Row key={`${e.description}-${e.amount}`}>
						<Title txt={`${e.description}:`} />
						<Col>
							<Text txt={amountToLocal(e.amount)} />
						</Col>
					</Row>
				))}
			{totalDiscount !== 0 && (
				<Row>
					<Title txt={'Discount (PKR):'} />
					<Col>
						<Text
							txt={amountToLocal(convertNumber(totalDiscount))}
						/>
					</Col>
				</Row>
			)}
			{taxes && taxes.length > 0 && (
				<>
					<Row>
						<Title txt={'Taxes (PKR):'} />
					</Row>
					<TaxWrapper>
						{taxes.map(d => (
							<Row key={`${d.name}${d.rate}`}>
								<Title txt={`${d.name} (${d.rate}%)`} />
								<Col>
									<Text txt={amountToLocal(totalTax)} />
								</Col>
							</Row>
						))}
					</TaxWrapper>
				</>
			)}
			<Line />
			<Row>
				<Typography variant={'caption'} fontWeight={600}>
					{'Grand Total (PKR):'}{' '}
				</Typography>
				<FlexRow />
			</Row>
			<Row>
				<FlexRow />
				<Typography
					variant={'subtitle2'}
					fontWeight={600}
					fontSize={'30px'}
				>
					{amountToLocal(convertNumber(totalAmount))}
				</Typography>
			</Row>
		</Wrapper>
	)
}
