import React, { type Dispatch, type SetStateAction, useCallback } from 'react'
import { styled } from '@mui/system'
import { FlexCol, FlexRow } from 'components/atoms/Flex'
import { Gutter } from 'components/atoms'
import { MdOutlineMenuOpen } from 'react-icons/md'
import { Tooltip, Typography, useTheme } from '@mui/material'
import { AnimatePresence, motion } from 'framer-motion'
import { SidebarItem } from 'components/app/SideBar/SideBarItem'
import {
	SEGMENT_EVENTS,
	SIDEBAR_FOOTER_DATASOURCE,
	type SideBarDataSourcesProps
} from 'data'
import { SidebarProfile } from 'components/app/SideBar/SideBarProfile'
import analytics from 'utils/segment'
import { WHITELIST } from 'data/whitelist'
import { useSelector } from 'react-redux'
import type { RootState } from 'store/index-reducer'
import { GiGreekTemple } from 'react-icons/gi'

const Footer = styled(FlexCol)`
	width: 100%;
`

const LinkText = styled(motion.div)`
	white-space: nowrap;
	height: min-content;
`

const Divider = styled(FlexRow)`
	border-bottom: 2px solid
		${({ theme }) => theme.palette.colors['gray']['200']};
	width: 100%;
`

const Item = styled(FlexRow, {
	shouldForwardProp: props => props !== 'isSideBarOpen'
})<{ isSideBarOpen: boolean }>`
	align-items: center;
	justify-content: flex-start;
	column-gap: ${props => (props.isSideBarOpen ? '8px' : '0')};
	border-radius: 6px;
	row-gap: 10px;
	width: 100%;
	height: 36px;
	margin: 4px 0;
	padding: 8px;

	:hover {
		cursor: pointer;
		background-color: ${({ theme }) => theme.palette.colors['white']['50']};
		color: ${({ theme }) => theme.palette.colors['gray']['600']};
	}
`

const ShowAnimation = {
	hidden: {
		width: 0,
		opacity: 0,
		transition: {
			duration: 0.2
		}
	},
	show: {
		width: 'auto',
		opacity: 1,
		transition: {
			duration: 0.2
		}
	}
}

type SideBarFooterProps = {
	isOpen?: boolean
	onClick: Dispatch<SetStateAction<boolean>>
}

export const SideBarFooter: React.ComponentType<SideBarFooterProps> = ({
	isOpen,
	onClick
}) => {
	const { user } = useSelector((state: RootState) => state.user)
	const isAllowedNumber = WHITELIST.includes(user?.phone.number || '')
	const theme = useTheme()

	const {
		palette: { colors }
	} = theme

	const RenderItem = useCallback(
		({ item }: { item: SideBarDataSourcesProps }) => {
			return <SidebarItem item={item} isOpen={isOpen} />
		},
		[isOpen]
	)

	return (
		<Footer>
			<Tooltip title={!isOpen && 'Expand'} placement="right">
				<Item
					isSideBarOpen={!!isOpen}
					onClick={() => {
						onClick(!isOpen)
						analytics.track(SEGMENT_EVENTS.SIDEBAR_COLLAPSE, {
							Collapsed: !isOpen
						})

						localStorage.setItem(
							'isSidebarOpen',
							JSON.stringify(!isOpen)
						)
					}}
				>
					<MdOutlineMenuOpen
						size={20}
						color={colors['grayGreen']['600']}
					/>
					<AnimatePresence>
						{isOpen && (
							<LinkText
								variants={ShowAnimation}
								initial={'hidden'}
								animate={'show'}
								exit={'hidden'}
							>
								<Typography
									variant={'subtitle2'}
									color={colors['grayGreen']['600']}
								>
									Collapse
								</Typography>
							</LinkText>
						)}
					</AnimatePresence>
				</Item>
			</Tooltip>
			<Gutter />
			<Divider />
			<Gutter />
			{isAllowedNumber &&
				[
					{
						path: '/ordr-financing',
						name: 'orderFinancing',
						icons: GiGreekTemple,
						selectedIcons: GiGreekTemple,
						segmentEvent: SEGMENT_EVENTS.SIDEBAR_WHATS_NEW,
						activePath: '/ordr-financing'
					}
				].map((d: any) => <RenderItem key={d.activePath} item={d} />)}
			{SIDEBAR_FOOTER_DATASOURCE.map(d => (
				<RenderItem key={d.activePath} item={d} />
			))}
			<Gutter spacing={0.5} />
			<SidebarProfile isOpen={!!isOpen} />
			<Gutter spacing={1.5} />
		</Footer>
	)
}
