import produce from 'immer'
import { type ISession } from 'typings/auth'

import {
	HYDRATE_SESSION,
	LOGOUT,
	SET_CURRENT_BUSINESS,
	SET_CURRENT_USER,
	SET_TOKEN,
	type UserActionsTypes
} from 'store/user/types'

type Name =
	| 'partnerCnic'
	| 'partnershipDeed'
	| 'incorporationCertificate'
	| 'authorityLetter'
	| 'bankStatement'

type FDT = {
	name: Name
	resourceURL: string
	size: number
	verified: boolean
}

type Kyb = {
	id: '65a799fd888d21a7b4b60037'
	businessType: string
	user_id: string
	fullName: string
	contactNumber: string
	businessName: string
	businessAddress: string
	ntn: string
	status: 'approved' | 'reject'
	statuses: [{}]
	partnerCnic: FDT[]
	partnershipDeed: FDT[]
	incorporationCertificate: FDT[]
	authorityLetter: FDT[]
	bankStatement: FDT[]
	pastPurchaseOrders: []
	pastInvoices: []
	facilities: []
}

type UserState = {
	cbfs?: {
		cbfsUserIds: string[]
		details: {
			[key: string]: {
				user: {
					id: string
					email: string
					emailVerified: false
					personalDetails: {
						fullName: string
						contactNumber: string
						businessName: string
						businessType: string
					}
					partner: {
						id: string
						name: string
					}
				}
				kyb: Kyb
				event_data?: {
					user_id: string
					status: string
					contract_url: string
					credit_limit: number
				}
			}
		}
	}
} & Partial<ISession>

const initialState: UserState = {
	token: undefined,
	user: undefined,
	currentBusiness: '',
	cbfs: undefined
}

const reducer = produce((draft: UserState, action: UserActionsTypes) => {
	switch (action.type) {
		case HYDRATE_SESSION:
			draft.user = action.user
			draft.token = action.token
			return

		case LOGOUT:
			draft.user = undefined
			draft.token = undefined
			return

		case SET_TOKEN:
			draft.token = action.payload
			return

		case SET_CURRENT_USER:
			draft.user = action.payload
			return

		case SET_CURRENT_BUSINESS:
			draft.currentBusiness = action.payload
			return

		default:
			return
	}
}, initialState)

export default reducer
