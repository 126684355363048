/* eslint-disable no-mixed-spaces-and-tabs */
import React from 'react'
import { styled, Typography } from '@mui/material'
import { FlexRow } from 'components/atoms/Flex'
import {
	FieldCurrencyInput,
	FieldDatePicker,
	FieldRadioGroup,
	Gutter
} from 'components'
import { ToPayToReceiveOptions } from 'data'
import type { ContactInitialStateProps } from 'screen/Contact/AddContact/CreateContactModal'
import { currencyInput } from 'helpers/currency'
import { useTranslate } from 'hooks'
import { truncate } from 'helpers'

export const ContactContainer = styled('div')`
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 10px 20px;
	align-items: flex-start;

	@media screen and (max-width: 750px) {
		grid-template-columns: 1fr;
	}
`

export const FieldRadioGroupWrapper = styled(FieldRadioGroup)(() => ({
	'& .MuiSvgIcon-root': {
		fontSize: 15
	}
}))

type Props = { isUser?: boolean } & ContactInitialStateProps

export const OpeningBalance: React.ComponentType<Props> = ({
	isUser,
	...values
}) => {
	const translate = useTranslate()
	const {
		balance: { to, amount, currency }
	} = values
	const formattedAmount = currencyInput(amount == '' ? '0' : `${amount}`)

	return (
		<>
			<Gutter spacing={1} />

			<ContactContainer>
				<FieldCurrencyInput
					name="balance.amount"
					placeholder={translate(
						'contacts.form.openingBalance.placeholder.balance'
					)}
					size="small"
					style={{ width: '100%' }}
					disabled={isUser}
				/>
				<FieldDatePicker name={'balance.asOf'} disabled={isUser} />
			</ContactContainer>
			<Gutter spacing={1.2} />
			<FlexRow>
				<FieldRadioGroupWrapper
					row
					name="balance.to"
					options={ToPayToReceiveOptions.map(item => {
						return {
							...item,
							disabled: isUser
						}
					})}
				/>
			</FlexRow>
			<Typography variant="subtitle2" fontStyle={'italic'}>
				{to == 'toPay'
					? translate('contacts.form.openingBalance.credit') +
					  `: ${currency} ${truncate(formattedAmount, 14)}`
					: translate('contacts.form.openingBalance.debit') +
					  ':' +
					  ` ${currency} ${truncate(formattedAmount, 14)}`}
			</Typography>
		</>
	)
}
