import { axios } from 'store'
import type { InvoiceSettingsType } from 'typings'

export const getInvoiceSettings = async (businessId: string) => {
	const url = `/settings/invoice/${businessId}`

	try {
		const { data } = await axios.get<InvoiceSettingsType>(url)

		return data
	} catch (error: any) {
		throw error.response.data
	}
}

export const updateInvoiceSettings = async (
	payload: Partial<InvoiceSettingsType>,
	id: string
) => {
	const url = `/settings/invoice/${id}`

	try {
		const { data } = await axios.patch(url, payload)

		return data
	} catch (error: any) {
		throw error.response.data
	}
}
