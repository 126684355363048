import React, { useEffect, useMemo } from 'react'
import { FlexCol, FlexRow } from 'components/atoms/Flex'
import { styled } from '@mui/system'
import { OutlinedInput, type Theme, Typography, useTheme } from '@mui/material'
import type { ColorsCodeType } from 'typings'
import {
	Button,
	FieldNumberInput,
	FieldTextInput,
	Gutter,
	Label
} from 'components'
import { Formik } from 'formik'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import {
	BUSINESS_TYPE,
	BusinessType,
	CBFS_INITIAL_DATA
} from 'data/initialData'
import {
	filterArrays,
	getArrayKeys,
	objectOmit,
	objectPick,
	removeArrays
} from 'helpers'
import { UploadAttachment } from 'screen/OrderFinancing/UploadAttachment'
import { convertCamelCaseToTitleCase } from 'helpers/mixed'
import { useSettings } from 'context/settings'
import { useDispatch, useSelector } from 'react-redux'
import type { RootState } from 'store/index-reducer'
import * as Yup from 'yup'
import * as yup from 'yup'
import { getProfileStatus, registerInCBFS, updateCBFS } from 'api/cbfs'
import { useNavigate } from 'react-router-dom'
import { useAppErrors } from 'hooks'
import { setCBFSInfo } from 'store/cbfs/actions'
import { WHITELIST } from 'data/whitelist'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8

const Wrapper = styled(FlexCol)`
	padding: 16px;
	width: 100%;
	height: 100%;
	flex-wrap: nowrap;
`

const Row = styled(FlexRow)`
	width: 100%;
	justify-content: space-between;
	align-items: center;
`

const Grid = styled('div')`
	display: grid;
	grid-template-columns: 1fr 1fr;
	width: min-content;
	column-gap: 20px;
	row-gap: 20px;
`

const PrimaryButtonWrapper = styled(FlexRow)``

const StyledPrimaryButton = styled(Button)`
	min-width: 0;
	min-height: 32px;
	padding: 0 10px;
	border-radius: 5px;
	color: ${({ theme }) => theme.palette.colors.white['900']};
`

function getStyles(name: string, theme: Theme) {
	return {
		fontWeight: theme.typography.fontWeightRegular
	}
}

const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250
		}
	}
}

const validationSchema = Yup.object().shape({
	business: Yup.string().required('Business is required'),
	fullName: Yup.string().required('fullName is required'),
	email: Yup.string().required('email is required'),
	businessName: Yup.string().required('Business Name is required'),
	businessAddress: Yup.string().required('Business Address is required'),
	ntn: Yup.string().required('NTN is required'),
	contactNumber: yup
		.string()
		.required('Required')
		.min(10, 'Phone number should be 10 digits long.')
		.max(10, 'Enter the valid Phone no'),
	partnerCnic: Yup.array().when('businessType', {
		is: 'partnership',
		then: Yup.array()
			.min(1, 'Partner CNIC is required')
			.required('Partner CNIC is required')
	}),
	partnershipDeed: Yup.array().when('businessType', {
		is: 'partnership',
		then: Yup.array()
			.min(1, 'Partnership deed is required')
			.required('Partnership deed is required')
	}),
	incorporationCertificate: Yup.array().when('businessType', {
		is: 'partnership',
		then: Yup.array()
			.min(1, 'Incorporation certificate is required')
			.required('Incorporation certificate is required')
	}),
	authorityLetter: Yup.array().when('businessType', {
		is: 'partnership',
		then: Yup.array()
			.min(1, 'Authority letter is required')
			.required('Authority letter is required')
	}),
	directorsResolution: Yup.array().when('businessType', {
		is: 'company',
		then: Yup.array()
			.min(1, 'Directors resolution is required')
			.required('Directors resolution is required')
	}),
	memorandumArticlesAssociation: Yup.array().when('businessType', {
		is: 'company',
		then: Yup.array()
			.min(1, 'Memorandum articles association is required')
			.required('Memorandum articles association is required')
	}),
	boardMemberCnic: Yup.array().when('businessType', {
		is: 'company',
		then: Yup.array()
			.min(1, 'Board member CNIC is required')
			.required('Board member CNIC is required')
	}),
	pocCnic: Yup.array().when('businessType', {
		is: 'company',
		then: Yup.array()
			.min(1, 'POC CNIC is required')
			.required('POC CNIC is required')
	}),
	beneficialOwnerCnic: Yup.array().when('businessType', {
		is: 'company',
		then: Yup.array()
			.min(1, 'Beneficial owner CNIC is required')
			.required('Beneficial owner CNIC is required')
	}),
	cnicFront: Yup.array().when('businessType', {
		is: 'soleProprietorship',
		then: Yup.array()
			.min(1, 'CNIC front is required')
			.required('CNIC front is required')
	}),
	cnicBack: Yup.array().when('businessType', {
		is: 'soleProprietorship',
		then: Yup.array()
			.min(1, 'CNIC back is required')
			.required('CNIC back is required')
	}),
	proprietorshipDeclaration: Yup.array().when('businessType', {
		is: 'soleProprietorship',
		then: Yup.array()
			.min(1, 'Proprietorship declaration is required')
			.required('Proprietorship declaration is required')
	}),
	ntnCertificate: Yup.array().when('businessType', {
		is: 'soleProprietorship',
		then: Yup.array()
			.min(1, 'NTN certificate is required')
			.required('NTN certificate is required')
	}),
	bankStatement: Yup.array().when('businessType', {
		is: 'soleProprietorship',
		then: Yup.array()
			.min(1, 'Bank statement is required')
			.required('Bank statement is required')
	})
})

export const RegisterForFinance = () => {
	const { kyb, user: usage } = useSelector(
		(state: RootState) => state.cbfs.business
	)
	const { business } = useSettings()
	const user = useSelector((state: RootState) => state.user.user)
	const { businesses } = useSelector((state: RootState) => state.business)
	const currentBusiness = useMemo(() => {
		return businesses.filter(dx => dx.id === business)[0]
	}, [businesses, business])
	const navigate = useNavigate()
	const { setAppError } = useAppErrors()
	const dispatch = useDispatch()

	const theme = useTheme()
	const {
		palette: { colors }
	} = theme

	const initial = useMemo(() => {
		if (kyb) {
			return {
				...objectPick(usage, 'email'),
				...objectPick(
					kyb,
					'fullName',
					'businessName',
					'businessAddress',
					'ntn'
				),
				contactNumber: kyb.contactNumber.replace('+92', ''),
				businessType: usage.personalDetails.businessType,
				...objectOmit(filterArrays(kyb), 'statuses'),
				business
			}
		} else {
			return {
				...CBFS_INITIAL_DATA,
				...objectPick(user, 'phone', 'fullName', 'email'),
				businessName: currentBusiness.name,
				businessAddress: currentBusiness.address,
				business,
				contactNumber: user?.phone.number
			}
		}
	}, [
		business,
		currentBusiness.address,
		currentBusiness.name,
		kyb,
		usage,
		user
	])

	const onSubmit = async (value: any, action: any) => {
		try {
			action.setSubmitting(true)
			if (!kyb) {
				await registerInCBFS({
					...value,
					contactNumber: '+92' + value.contactNumber
				})
				const res = await getProfileStatus(business)
				dispatch(setCBFSInfo(res))
				navigate('/ordr-financing')
			} else if (kyb) {
				await updateCBFS({
					...value,
					contactNumber: '+92' + value.contactNumber
				})
				const res = await getProfileStatus(business)
				dispatch(setCBFSInfo(res))
				navigate('/ordr-financing')
			}
		} catch (e: any) {
			setAppError(e)
		} finally {
			action.setSubmitting(false)
		}
	}

	useEffect(() => {
		if (!WHITELIST.includes(user?.phone.number || '')) {
			navigate('/home')
		}
	}, [navigate, user?.phone.number])

	return (
		<Wrapper>
			<Formik
				initialValues={initial}
				validationSchema={validationSchema}
				onSubmit={onSubmit}
			>
				{({
					values,
					handleSubmit,
					isSubmitting,
					setValues,
					isValid
				}) => (
					<>
						<Row>
							<FlexCol>
								<Typography variant={'h6'} fontWeight={600}>
									Register for Financing
								</Typography>
								<Typography variant={'body2'}>
									For helping us understand your business
									better, please provide the required detail
								</Typography>
							</FlexCol>
							<StyledPrimaryButton
								buttonColor={
									colors['green']['600'] as ColorsCodeType
								}
								onClick={() => handleSubmit()}
								disabled={isSubmitting || !isValid}
							>
								<PrimaryButtonWrapper>
									Submit
								</PrimaryButtonWrapper>
							</StyledPrimaryButton>
						</Row>
						<FlexCol>
							<Gutter spacing={2} />
						</FlexCol>
						<FlexCol>
							<Grid>
								<FieldTextInput
									placeholder="Enter Name"
									name={'fullName'}
									title="Full Name"
									style={{ width: '35ch' }}
									size="small"
									disabled
								/>

								<FieldNumberInput
									title="Contact Number"
									placeholder={'03001XXXXXX'}
									name={'contactNumber'}
									style={{ width: '35ch' }}
									size="small"
									InputProps={{
										startAdornment: (
											<Typography
												sx={{
													marginRight: 1.1,
													marginTop: -0.1
												}}
											>
												+92
											</Typography>
										)
									}}
									disabled
								/>
								<FieldTextInput
									placeholder="Enter Name"
									name={'email'}
									title="Email"
									style={{ width: '35ch' }}
									size="small"
								/>
								<FieldTextInput
									placeholder="Business Name"
									name={'businessName'}
									title="Business Name"
									wrapperStyle={{ width: '100%' }}
									style={{ width: '100%', maxWidth: '35ch' }}
									size="small"
									disabled
								/>
								<FlexCol>
									<Label>{'Business Type'}</Label>
									<Select
										labelId="demo-multiple-name-label"
										id="demo-multiple-name"
										value={values.businessType}
										onChange={event => {
											setValues({
												...(removeArrays(
													values
												) as any),
												businessType:
													event.target.value,
												...BusinessType[
													event.target.value as
														| 'partnership'
														| 'company'
														| 'soleProprietorship'
												]
											} as any)
										}}
										input={
											<OutlinedInput
												size={'small'}
												sx={{
													width: '100%',
													borderRadius: '8px'
												}}
											/>
										}
										MenuProps={MenuProps}
									>
										{BUSINESS_TYPE.map(name => (
											<MenuItem
												key={name.name}
												value={name.value}
												style={getStyles(
													name.name,
													theme
												)}
											>
												{name.name}
											</MenuItem>
										))}
									</Select>
								</FlexCol>
								<FieldTextInput
									placeholder="Ntn"
									name={'ntn'}
									title="NTN"
									wrapperStyle={{ width: '100%' }}
									style={{ width: '100%', maxWidth: '35ch' }}
									size="small"
								/>
							</Grid>
							<Gutter />
							<Label>{'Business Address'}</Label>
							<FieldTextInput
								multiline
								rows={2}
								name="businessAddress"
								placeholder={'Business Address'}
								size="small"
								style={{ width: '100%' }}
								wrapperStyle={{ width: '100%' }}
							/>
							<Gutter spacing={1.5} />
						</FlexCol>

						<Typography variant={'body1'} fontWeight={600}>
							Upload Attachment
						</Typography>
						<Gutter spacing={0.7} />
						{getArrayKeys(values).map(m => {
							return (
								<FlexCol key={m}>
									<UploadAttachment
										name={m}
										title={`${convertCamelCaseToTitleCase(
											m
										)}*`}
									/>
								</FlexCol>
							)
						})}
					</>
				)}
			</Formik>
		</Wrapper>
	)
}
