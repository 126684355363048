import React, { useCallback } from 'react'
import {
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
	useTheme
} from '@mui/material'
import { styled } from '@mui/system'
import type { InvoiceItemProps, InvoiceType } from 'typings'
import { convertNumber } from 'helpers/number'
import { amountToLocal } from 'helpers'
import { FlexRow } from 'components/atoms/Flex'
import { SCROLLBAR_STYLE } from 'data'
import { calculateDiscount, calculateDiscountPercentage } from 'helpers/mixed'

const Wrapper = styled('div')``

const StyledTableContainer = styled(TableContainer)`
	background-color: ${({ theme }) => theme.palette.background.default};
	${SCROLLBAR_STYLE}
`

const ProductCell = styled(TableCell)<{ width?: number }>`
	min-width: ${({ width }) => (width ? `${width}px` : '300px')};
	max-width: ${({ width }) => (width ? `${width}px` : '300px')};
	background-color: ${({ theme }) => theme.palette.colors.gray['50']};
	color: ${({ theme }) => theme.palette.colors.gray['500']};
	vertical-align: top;
`

const MedCell = styled(TableCell)<{ width?: number }>`
	min-width: ${({ width }) => (width ? `${width}px` : '180px')};
	max-width: 200px;
	background-color: ${({ theme }) => theme.palette.colors.gray['50']};
	color: ${({ theme }) => theme.palette.colors.gray['500']};
	vertical-align: top;
`

const Product = styled(ProductCell)`
	background-color: ${({ theme }) => theme.palette.background.default};
	vertical-align: top;
`

const Info = styled(MedCell)`
	background-color: ${({ theme }) => theme.palette.background.default};
	vertical-align: top;
`

const DescriptionWrapper = styled(FlexRow)`
	width: 100%;
	max-width: 100%;
	margin-top: 5px;
	padding: 4px 10px;
	background-color: ${({ theme }) => theme.palette.colors['gray']['50']};

	align-items: center;
	justify-content: flex-start;
`

const StyledTableBody = styled(TableBody)`
	height: 100%;
`

const StyledTableRow = styled(TableRow)(() => ({
	backgroundColor: '#fff',
	alignItems: 'flex-start',
	justifyContent: 'space-between'
}))

type Props = {
	title?: string
	currentInvoice: InvoiceType
}

export const Cart: React.ComponentType<Props> = ({ currentInvoice }) => {
	const theme = useTheme()
	const { colors } = theme.palette
	const { items } = currentInvoice

	const isDiscount =
		items.reduce(
			(accumulator, item) => (item.discount?.amount || 0) + accumulator,
			0
		) > 0

	const Item = useCallback(
		({ row }: { row: InvoiceItemProps }) => {
			const tempDis = calculateDiscountPercentage(
				row.perItemSellPrice || 0,
				row.discount?.amount || 0,
				row.discount?.type || 'percent'
			)

			const lineTotal = calculateDiscount(
				convertNumber(row.quantity || 0) *
					convertNumber(row.perItemSellPrice || 0),
				row.discount?.amount || 0,
				row.discount?.type || 'percent'
				// eslint-disable-next-line no-mixed-spaces-and-tabs
			)

			return (
				<StyledTableRow>
					<Product id={'name'}>
						<Typography
							variant={'caption'}
							style={{
								color: colors.gray['700'],
								overflowWrap: 'break-word'
							}}
							fontWeight={'500'}
							display={'block'}
						>
							{row.name}
						</Typography>
						{row.description && (
							<DescriptionWrapper>
								<Typography
									variant={'caption'}
									style={{
										color: colors.gray['700'],
										overflowWrap: 'break-word',
										overflowX: 'auto'
									}}
									fontWeight={'500'}
								>
									{row.description}
								</Typography>
							</DescriptionWrapper>
						)}
					</Product>
					<Info align="left" width={100}>
						<Typography
							variant={'caption'}
							style={{
								color: colors.gray['700'],
								overflowWrap: 'break-word',
								overflowX: 'auto'
							}}
							fontWeight={'500'}
						>
							{row.unit}
						</Typography>
					</Info>
					<Info align="left" width={120}>
						<Typography
							variant={'caption'}
							fontWeight={'500'}
							style={{
								overflowWrap: 'break-word'
							}}
						>
							{row.perItemSellPrice
								? amountToLocal(row.perItemSellPrice)
								: '-'}
						</Typography>
					</Info>
					<Info align="left" width={100}>
						<Typography variant={'caption'} fontWeight={'500'}>
							{row.quantity ? amountToLocal(row.quantity) : '-'}
						</Typography>
					</Info>
					{isDiscount && (
						<Info align="left" width={100}>
							<Typography variant={'caption'} fontWeight={'500'}>
								{tempDis.discountedAmount}
							</Typography>
							<br />
							<Typography variant={'caption'} fontWeight={'500'}>
								{tempDis.discountPercentage}%
							</Typography>
						</Info>
					)}
					<Info align="left" width={100}>
						<Typography
							variant={'caption'}
							fontWeight={'500'}
							color={colors.green['700']}
							style={{
								overflowWrap: 'break-word'
							}}
						>
							{amountToLocal(convertNumber(lineTotal))}
						</Typography>
					</Info>
				</StyledTableRow>
			)
		},
		[colors.gray, colors.green, isDiscount]
	)

	return (
		<Wrapper>
			<Paper sx={{ width: '100%' }}>
				<StyledTableContainer>
					<Table aria-label="sticky table">
						<TableHead>
							<ProductCell id={'name'}>
								<Typography
									variant={'caption'}
									style={{ color: colors.gray['700'] }}
									fontWeight={'500'}
								>
									Items
								</Typography>
							</ProductCell>
							<MedCell align="left" width={100}>
								<Typography
									variant={'caption'}
									fontWeight={'500'}
								>
									Unit
								</Typography>
							</MedCell>
							<MedCell align="left" width={120}>
								<Typography
									variant={'caption'}
									fontWeight={'500'}
								>
									Selling Price
								</Typography>
							</MedCell>
							<MedCell align="left" width={100}>
								<Typography
									variant={'caption'}
									fontWeight={'500'}
								>
									Quantity
								</Typography>
							</MedCell>
							{isDiscount && (
								<MedCell align="left" width={100}>
									<Typography
										variant={'caption'}
										fontWeight={'500'}
									>
										Discount
									</Typography>
								</MedCell>
							)}
							<MedCell align="left" width={100}>
								<Typography
									variant={'caption'}
									fontWeight={'500'}
								>
									Line Total
								</Typography>
							</MedCell>
						</TableHead>
						<StyledTableBody>
							{items.map(row => (
								<Item key={`${row.id}`} row={row} />
							))}
						</StyledTableBody>
					</Table>
				</StyledTableContainer>
			</Paper>
		</Wrapper>
	)
}
