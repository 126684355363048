import React from 'react'
import { FieldTextInput } from 'components'
import { styled } from '@mui/material'

const ContactContainer = styled('div')`
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 10px 20px;
	align-items: flex-start;
`

export const CnicTab = () => {
	return (
		<ContactContainer>
			<FieldTextInput
				name={'cnic'}
				size={'small'}
				placeholder={'Enter Contact’s NTN/CNIC Number'}
				style={{ width: '100%' }}
			/>
		</ContactContainer>
	)
}
