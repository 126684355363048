import {
	Badge,
	Paper,
	styled,
	Table,
	TableBody,
	TableContainer,
	Typography,
	useTheme
} from '@mui/material'
import React, { useCallback, useRef } from 'react'
import { FlexCol, FlexRow } from 'components/atoms/Flex'
import { Capsule, Gutter } from 'components'
import { TableHeadings } from './TableHeadings'
import type { QuotationStatusType, QuotationType } from 'typings'
import NoDraftQuotations from 'assets/quotations/Quotations_Draft.png'
import NoSearchQuotations from 'assets/quotations/SearchQuotation.png'
import { QuotationItem } from './QuotationItem'
import { SCROLLBAR_STYLE } from 'data'

const Wrapper = styled('div')`
	border: 1px solid ${({ theme }) => theme.palette.background.paper};
	border-radius: 7px;
	overflow: hidden;
	height: 30vh;
	margin-top: 8px;
`

export const WrapperCapsule = styled(Capsule)`
	padding: 7px;
	border-radius: 6px;
	width: auto;
`

const GreenBadge = styled(Badge)(({ theme }) => ({
	'& .MuiBadge-badge': {
		backgroundColor: theme.palette.colors.yellow['400']
	},
	margin: '0px 15px'
}))

const GrayDot = styled(Badge)(({ theme }) => ({
	'& .MuiBadge-badge': {
		backgroundColor: theme.palette.colors.gray['400']
	},
	margin: '0px 10px'
}))

const Container = styled('div')`
	margin-top: 20px;
	width: 100%;
`

const StyledTableContainer = styled(TableContainer)`
	${SCROLLBAR_STYLE}
`

const StyledPaper = styled(Paper)`
	width: 100%;
	height: 100%;
	overflow: hidden;
	position: relative;
	z-index: 0;
	background-color: ${({ theme }) => theme.palette.background.default};
`

const StyledTable = styled(Table)`
	min-width: 700px;
	background-color: ${({ theme }) => theme.palette.background.default};
`

const Image = styled('img')`
	min-width: 100px;
	max-width: 100px;
`

const Center = styled(FlexCol)`
	width: 100%;
	height: 100%;
	position: absolute;
	align-self: center;
	align-items: center;
	justify-content: center;
`

type DraftsTableProps<U> = {
	dataSource: U[]
	total: number
	onStatusChange?: (row: U, status: QuotationStatusType) => void
	searchText: string
}

export const DraftsTable: React.ComponentType<
	DraftsTableProps<QuotationType>
> = ({ dataSource, total = 0, onStatusChange, searchText }) => {
	const theme = useTheme()
	const { colors } = theme.palette
	const ref = useRef<HTMLDivElement>(null)

	const RenderRow = useCallback(
		({ row }: { row: QuotationType }) => {
			return (
				<QuotationItem
					row={row}
					status={'private'}
					onStatusChange={onStatusChange}
				/>
			)
		},
		[onStatusChange]
	)

	return (
		<Container>
			<FlexRow align="center">
				<GreenBadge badgeContent={''} />
				<Typography variant="body2" fontWeight={'600'}>
					Drafts
				</Typography>
				<GrayDot badgeContent={' '} variant="dot" />
				<Typography variant="caption" color={colors.green['900']}>
					{'Displaying'} {total}{' '}
					{total == 1 ? 'Quotation' : 'Quotations'}
				</Typography>
			</FlexRow>
			<Wrapper>
				<StyledPaper ref={ref}>
					<StyledTableContainer
						style={{
							minWidth: 700,
							maxHeight: ref.current?.clientHeight || 120
						}}
					>
						<StyledTable stickyHeader aria-label="sticky table">
							<TableHeadings seeAll />
							<TableBody>
								{dataSource.length < 1 && (
									<Center>
										<Image
											src={
												searchText === ''
													? NoDraftQuotations
													: NoSearchQuotations
											}
										/>
										<Gutter spacing={0.3} />
										<Typography variant={'body2'}>
											{searchText === ''
												? 'You have created no draft quotations yet'
												: `No quotation found for "${searchText}"`}
										</Typography>
									</Center>
								)}
								{dataSource.map(row => (
									<RenderRow row={row} key={row.id} />
								))}
							</TableBody>
						</StyledTable>
					</StyledTableContainer>
				</StyledPaper>
			</Wrapper>
		</Container>
	)
}
