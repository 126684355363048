import React, { type FC, useCallback } from 'react'
import { RadioButton } from './RadioButton'
import {
	Radio,
	RadioGroup,
	type RadioGroupProps as MuiRadioGroupProps,
	type RadioProps
} from '@mui/material'

export type RadioOptionProps = {
	value: string | boolean | number
	label: string
}

export type RadioGroupProps = {
	options: RadioOptionProps[]
	radioProps?: RadioProps
} & MuiRadioGroupProps

export const CustomRadioGroup: FC<RadioGroupProps> = ({
	options,
	radioProps,
	...props
}) => {
	const RenderButton = useCallback(
		({ ...item }: RadioOptionProps) => {
			return <RadioButton control={<Radio {...radioProps} />} {...item} />
		},
		[radioProps]
	)

	return (
		<RadioGroup {...props}>
			{options.map(item => (
				<RenderButton {...item} key={`${item.value}`} />
			))}
		</RadioGroup>
	)
}
