import React, { useState } from 'react'
import { Button, Gutter } from 'components'
import { FlexCol, FlexRow } from 'components/atoms/Flex'
import { styled } from '@mui/system'
import { Typography, useTheme } from '@mui/material'
import type { ColorsCodeType } from 'typings'
import { IoMdClose } from 'react-icons/io'
import { LockModal } from 'screen/Settting/Privacy/PinSetup/LockModal'

const Wrapper = styled(FlexCol)``

const CloseIcon = styled(FlexCol)`
	justify-content: center;
	align-items: center;
	height: 30px;
	width: 30px;
	border-radius: 15px;

	background-color: ${({ theme }) => theme.palette.colors.red['100']};
`

const StyledButton = styled(Button)`
	padding: 10px 15px;
	border-radius: 10px;
`

export const Privacy = () => {
	const [isModalOpen, setIsModalOpen] = useState(false)

	const theme = useTheme()
	const { colors } = theme.palette
	return (
		<>
			<Wrapper>
				<Typography variant={'h5'} fontWeight={600}>
					Screen Lock
				</Typography>
				<Gutter spacing={0.4} />
				<Typography
					variant={'subtitle1'}
					fontWeight={500}
					lineHeight={'1.4'}
					color={theme.palette.colors.gray['500']}
					fontFamily={'Inter'}
				>
					Setting up Screen Lock allows you to instantly lock and hide
					your WebApp screen, so you can keep your confidential data
					secure. You can enable it from the side navigation.
				</Typography>
				<Gutter spacing={2} />
				<FlexRow align={'center'}>
					<CloseIcon>
						<IoMdClose color={colors.red['900']} />
					</CloseIcon>
					<Gutter gap={1} />
					<Typography
						variant={'subtitle1'}
						fontWeight={500}
						lineHeight={'1.4'}
						color={theme.palette.colors.gray['600']}
						fontFamily={'Inter'}
					>
						You do not have a Screen Lock PIN
					</Typography>
				</FlexRow>
				<Gutter spacing={2} />
				<StyledButton
					textColor={colors['gray']['900'] as ColorsCodeType}
					mode={'primary'}
					buttonColor={colors['yellow']['300'] as ColorsCodeType}
					onClick={() => setIsModalOpen(true)}
				>
					<Typography variant={'body2'} fontWeight={500}>
						Set up Pin
					</Typography>
				</StyledButton>
			</Wrapper>
			<LockModal
				open={isModalOpen}
				onClose={() => setIsModalOpen(false)}
			/>
		</>
	)
}
