import React from 'react'
import { styled } from '@mui/system'
import { FlexCol, FlexRow } from 'components/atoms/Flex'
import { Gutter, Label } from 'components'
import { Box, Typography, useTheme } from '@mui/material'
import { useTranslate } from 'hooks'

const Container = styled(FlexCol)`
	position: relative;
	width: 12ch;
	height: 15ch;
	background-color: ${({ theme }) => theme.palette.colors['gray']['50']};
	border-radius: 0.6rem;
	justify-content: center;
	align-items: center;
	overflow: hidden;
`

const Square = styled('div')`
	width: 12ch;
	height: 15ch;
	z-index: 2;
`

const StyledFlexCol = styled(FlexCol)`
	position: absolute;
	z-index: 1;
	width: 100%;
	height: 100%;
	align-items: center;
	justify-content: center;
`

interface R {
	size?: number
	children: React.ReactNode
	rest?: any
	icon?: any
	text?: string
}

export const ImagePlaceholder = ({ icon, children, ...rest }: R) => {
	const theme = useTheme()
	const translate = useTranslate()
	const ShowIcon = icon

	return (
		<FlexCol>
			<Label>Upload Image</Label>
			<FlexRow align={'center'}>
				<Container {...rest}>
					<StyledFlexCol>
						<ShowIcon
							size={50}
							color={theme.palette.colors.green['600']}
						/>
					</StyledFlexCol>
					<Square>{children}</Square>
				</Container>
				<Gutter gap={1.5} />
				<Typography
					variant="caption"
					color={theme.palette.colors.gray['500']}
					fontWeight={500}
				>
					{translate('inventory.imagePlaceHolder.dragDropFile')}{' '}
					<Box display="inline" sx={{ cursor: 'pointer' }} {...rest}>
						<Typography
							variant="caption"
							color={theme.palette.colors.blue['600']}
							fontWeight={500}
						>
							{translate('inventory.imagePlaceHolder.browse')}
						</Typography>
					</Box>
				</Typography>
			</FlexRow>
		</FlexCol>
	)
}
