import {
	Dialog,
	DialogContent,
	DialogTitle,
	IconButton,
	styled
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import React from 'react'
import { FlexCol, FlexRow } from 'components/atoms/Flex'
import { StyledModal } from 'components'

type ImageModalProps = {
	open: boolean
	imageUrl: string
	handleClose: () => void
}

const Container = styled(FlexCol)`
	background-color: ${({ theme }) => theme.palette.background.default};
	width:500px;
	padding: 15px;
	z-index: 0;
	border-radius: 10px;

	@media (max-width: 937px) {
		width: 90%;
	}
`
export const ImageModal: React.ComponentType<ImageModalProps> = ({
	open,
	imageUrl,
	handleClose
}) => {
	return (
		<StyledModal open={open} onClose={handleClose}>
			<Container>
				<FlexRow justify="flex-end" style={{width:'100%'}}>
					<IconButton
						edge="end"
						color="inherit"
						onClick={handleClose}
						aria-label="close"
					>
						<CloseIcon />
					</IconButton>
				</FlexRow>
				<img src={imageUrl} alt="Expanded Image" style={{width: '100%'}}/>
			</Container>
		</StyledModal>
	)
}
