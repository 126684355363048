import React, {
	type ChangeEvent,
	useCallback,
	useEffect,
	useState
} from 'react'
import type { PaymentMethods, PaymentTerms, QuotationType } from 'typings'
import type { ModalProps } from '@mui/material'
import {
	Button,
	InputAdornment,
	styled,
	Typography,
	useTheme
} from '@mui/material'
import { Capsule, Gutter, TextInput } from 'components/atoms'
import { FlexCol, FlexRow } from 'components/atoms/Flex'
import PaymentsIcon from '@mui/icons-material/Payments'
import {
	CREATE_INVOICE_INITIAL,
	PAYMENT_TABS,
	PaymentMethodOptions
} from 'data'
import { MdOutlineFileCopy } from 'react-icons/md'
import { RxCrossCircled } from 'react-icons/rx'
import { DesktopDatePicker } from '@mui/x-date-pickers'
import { objectUpdateDeeply } from 'helpers/object'
import { BrowserInput } from 'components/molecules'
import { StyledModal } from 'components/StyledModal'
import { CustomRadioGroup } from 'components/atoms/RadioGroup'
import { convertNumber, isBeforeToday, POSITIVE_NUMBER } from 'helpers'
import moment from 'moment'
import { WarningModal } from 'components/app/Payments/WarningModal'

const Container = styled(FlexCol)`
	align-self: center;
	justify-self: center;
	padding: 25px 35px;
	min-height: 450px;
	min-width: 500px;
	max-width: 510px;
	width: 100%;
	height: min-content;
	background-color: ${({ theme }) => theme.palette.background.default};
	border-radius: 10px;
	margin-top: 20px;
	margin-bottom: 20px;
	overflow-y: scroll;
	justify-content: space-between;
`

const WrapperCapsule = styled(Capsule)`
	padding: 5px 10px;
	font-size: 12px;
`

const WrapperDiv = styled('div')`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`
const TextField = styled(TextInput)(({ theme }) => ({
	'& .MuiOutlinedInput-root': {
		paddingLeft: 0
	},
	'& .MuiInputAdornment-root': {
		padding: '22px 8px',
		borderTopLeftRadius: theme.shape.borderRadius + 'px',
		borderBottomLeftRadius: theme.shape.borderRadius + 'px',
		border: `1px solid ${theme.palette.colors.gray['300']}`
	}
}))

const WrapperFieldRadioGroup = styled(CustomRadioGroup)(() => ({
	'& .MuiSvgIcon-root': {
		fontSize: 12
	},
	padding: '8px 0 8px 35px'
}))

const WrapperButton = styled(Button)(({ theme }) => ({
	backgroundColor: theme.palette.colors.green['500'],
	color: theme.palette.colors.white['900'],
	'&:hover': {
		background: theme.palette.colors.green['700']
	},
	'&:disabled': {
		backgroundColor: theme.palette.colors.gray['200'],
		color: theme.palette.colors.gray['500']
	}
}))

const StyledCapsule = styled(Capsule)`
	padding: 2px 8px;
	font-size: 12px;
	font-weight: 500;
	border-radius: 8px;
	margin: 0;
`

type Props = {
	onSuccess?(
		response: Pick<QuotationType, 'paymentTerms' | 'paymentMethod'>
	): void
} & Pick<ModalProps, 'onClose' | 'open'> &
	Pick<QuotationType, 'paymentTerms' | 'paymentMethod'>

export const PaymentsModal: React.ComponentType<Props> = ({
	onSuccess,
	onClose,
	open,
	...props
}) => {
	const [paymentTerms, setPaymentTerms] = useState<PaymentTerms>(
		props.paymentTerms
	)
	const [paymentMethod, setPaymentMethod] = useState(props.paymentMethod)
	const [paymentTermsSelected, setPaymentTermsSelected] = useState<string>('')
	const [isTermsOpen, setIsTermsOpen] = useState(false)
	const [error, setError] = useState(false)
	const [openWarningModal, setOpenWarningModal] = useState(false)

	const theme = useTheme()
	const { colors } = theme.palette

	const resetPaymentTerms = () => {
		setIsTermsOpen(false)
		setPaymentTerms({ dueDate: '', numDays: '', custom: [] })
	}

	const onReset = () => {
		setPaymentTerms(paymentTerms)
		setError(false)
		onClose?.({}, 'escapeKeyDown')
	}

	const handleCustomTerms = (
		e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		setError(false)
		if (e.target.value.length > 100) {
			setError(true)
		}
		setPaymentTerms(
			objectUpdateDeeply(paymentTerms, 'custom', [e.target.value])
		)
	}

	const onDayChange = (
		e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		if (e.target.value.match(POSITIVE_NUMBER)) {
			if (convertNumber(e.target.value) > 1095) {
				setPaymentTerms(
					objectUpdateDeeply(paymentTerms, 'numDays', 1095)
				)
				return
			}

			setPaymentTerms(
				objectUpdateDeeply(paymentTerms, 'numDays', e.target.value)
			)
		}
	}

	const onStateChange = (value: Partial<PaymentTerms>) => {
		setPaymentTerms({
			...CREATE_INVOICE_INITIAL.paymentTerms,
			...value
		})
	}

	const onPaymentTermsValueChange = (value: Partial<PaymentTerms>) => {
		onStateChange(value)

		return {
			paymentTerms: {
				...CREATE_INVOICE_INITIAL.paymentTerms,
				...value
			},
			paymentMethod
		}
	}

	const onValidate = () => {
		onSuccess?.(
			onPaymentTermsValueChange({
				[paymentTermsSelected]:
					paymentTerms[paymentTermsSelected as keyof PaymentTerms]
			})
		)
	}

	const handleSaveTerms = () => {
		if (isTermsOpen) {
			if (paymentTermsSelected == 'numDays') {
				onSuccess?.(
					onPaymentTermsValueChange({
						dueDate: paymentTerms.numDays
							? moment
									.utc(
										moment().add(
											paymentTerms.numDays,
											'days'
										)
									)
									.format()
							: '',
						numDays: paymentTerms.numDays
					})
				)
			} else {
				if (
					paymentTermsSelected === 'dueDate' &&
					isBeforeToday(paymentTerms.dueDate)
				) {
					setOpenWarningModal(true)
					return
				} else {
					onValidate()
				}
			}
		} else {
			onSuccess?.(onPaymentTermsValueChange({}))
		}

		onClose?.({}, 'escapeKeyDown')
	}

	const onChange = (e: moment.Moment | null) => {
		if (e) {
			setPaymentTerms(
				objectUpdateDeeply(
					paymentTerms,
					'dueDate',
					moment.utc(e).format().toString()
				)
			)
		}
	}

	const getInitialData = useCallback(() => {
		if (!!props.paymentTerms.numDays && props.paymentTerms.dueDate) {
			setPaymentTermsSelected('numDays')
		} else if (props.paymentTerms.custom.length > 0) {
			setPaymentTermsSelected('custom')
		} else {
			setPaymentTermsSelected('dueDate')
		}

		if (
			props.paymentTerms &&
			(props.paymentTerms.dueDate || props.paymentTerms.custom.length > 0)
		) {
			setIsTermsOpen(true)
		} else {
			setIsTermsOpen(false)
		}
	}, [props.paymentTerms])

	const RenderTabs = useCallback(
		({ name, value }: { name: string; value: string }) => {
			return (
				<WrapperCapsule
					key={`${value}${name}`}
					name={name}
					value={value}
					isSelected={paymentTermsSelected == value}
					color="green"
					onItemClick={() => {
						/*if (value === 'dueDate') {
							onStateChange({ dueDate: moment().utc().format() })
						}*/
						setPaymentTermsSelected(value)
					}}
				/>
			)
		},
		[paymentTermsSelected]
	)

	useEffect(() => {
		getInitialData()
	}, [getInitialData, open])

	useEffect(() => {
		setPaymentTerms(props.paymentTerms)
	}, [open])

	return (
		<StyledModal open={open} onClose={() => onClose?.({}, 'escapeKeyDown')}>
			<Container>
				<WrapperDiv>
					<div>
						<Typography variant="h6" fontWeight={'600'}>
							Payment information
						</Typography>
						<Gutter spacing={2} />
						<FlexRow align="center">
							<PaymentsIcon color="disabled" />
							<Gutter gap={0.5} />
							<Typography color={colors.green['800']}>
								Payment method
							</Typography>
							{paymentMethod !== '' &&
								paymentMethod !== 'unpaid' && (
									<>
										<Gutter gap={0.7} />
										<StyledCapsule
											isSelected
											name={'Clear Selection'}
											value={'clear selection'}
											color={'red'}
											onItemClick={() =>
												setPaymentMethod('')
											}
										/>
									</>
								)}
						</FlexRow>
						<WrapperFieldRadioGroup
							row
							options={PaymentMethodOptions}
							value={paymentMethod}
							onChange={(
								event: React.ChangeEvent<HTMLInputElement>
							) => {
								setPaymentMethod(
									event?.target.value as PaymentMethods
								)
							}}
						/>
						<Gutter gap={1} />

						<FlexRow align="center">
							<MdOutlineFileCopy
								color={colors.gray['400']}
								size={25}
							/>
							<Gutter gap={0.5} />

							{!isTermsOpen && (
								<WrapperButton
									style={{
										backgroundColor: colors.green['700']
									}}
									onClick={() => {
										setPaymentTerms({
											...paymentTerms
											//dueDate: moment().utc().format()
										})
										setIsTermsOpen(true)
									}}
								>
									Add Payment Terms
								</WrapperButton>
							)}

							{isTermsOpen && (
								<FlexRow
									align="center"
									style={{ minHeight: 36 }}
								>
									<Typography color={colors.green['800']}>
										Payment Terms
									</Typography>
									<Gutter gap={0.5} />
									<RxCrossCircled
										size={15}
										style={{ cursor: 'pointer' }}
										onClick={resetPaymentTerms}
									/>
								</FlexRow>
							)}
						</FlexRow>

						<Gutter spacing={0.3} />

						{isTermsOpen && (
							<>
								<FlexRow>
									<Gutter gap={2} />

									{PAYMENT_TABS.map(option => (
										<RenderTabs
											name={option.name}
											value={option.value}
											key={`${option.value}${option.name}`}
										/>
									))}
								</FlexRow>
								<Gutter spacing={1} />

								<FlexRow align="center" justify="flex-start">
									<Gutter gap={2.5} />
									{paymentTermsSelected == 'numDays' && (
										<TextField
											size="small"
											value={paymentTerms.numDays}
											onChange={onDayChange}
											type={'tel'}
											style={{ width: '300px' }}
											placeholder="Enter days"
											InputProps={{
												startAdornment: (
													<InputAdornment position="start">
														<Typography
															fontSize={13}
															variant="caption"
															color={
																colors.green[
																	'600'
																]
															}
														>
															Number of days:
														</Typography>
													</InputAdornment>
												)
											}}
										/>
									)}
									{paymentTermsSelected == 'custom' && (
										<TextInput
											style={{ width: '300px' }}
											multiline
											rows={2}
											error={error}
											value={paymentTerms?.custom[0]}
											placeholder={'Enter terms'}
											onChange={handleCustomTerms}
										/>
									)}
									{paymentTermsSelected == 'dueDate' && (
										<DesktopDatePicker
											closeOnSelect={true}
											onChange={onChange}
											slots={{ textField: BrowserInput }}
											maxDate={moment().add(3, 'years')}
											value={moment(paymentTerms.dueDate)}
										/>
									)}
								</FlexRow>
							</>
						)}
					</div>
					<Gutter gap={2.5} />
				</WrapperDiv>
				<FlexRow justify="flex-end" style={{ width: '100%' }}>
					<Button
						style={{ color: colors.gray['500'] }}
						onClick={onReset}
					>
						Cancel
					</Button>
					<WrapperButton onClick={handleSaveTerms} disabled={error}>
						Done
					</WrapperButton>
				</FlexRow>
				<WarningModal
					open={openWarningModal}
					onClose={() => {
						setOpenWarningModal(false)
						onValidate()
						onClose?.({}, 'escapeKeyDown')
					}}
					onCancel={() => setOpenWarningModal(false)}
				/>
			</Container>
		</StyledModal>
	)
}
