import React from 'react'
import { Formik } from 'formik'
import { Button, FieldOtpTextInput, Gutter } from 'components'
import { FlexCol } from 'components/atoms/Flex'
import { MdLockOutline } from 'react-icons/md'
import type { ModalProps } from '@mui/material'
import { Typography, useTheme } from '@mui/material'
import { styled } from '@mui/system'
import * as yup from 'yup'

const Wrapper = styled(FlexCol)`
	width: 320px;
	align-items: center;
	justify-content: center;
`

const initialState = {
	pin: ''
}

export const validationSchema = yup.object().shape({
	pin: yup.string().trim().length(4, '4 Digit required').required('Required')
})

type UnlockProps = {} & Pick<ModalProps, 'onClose'>

export const UnlockScreen: React.ComponentType<UnlockProps> = ({ onClose }) => {
	const theme = useTheme()
	const lockPin = localStorage.getItem('pin')

	return (
		<Formik
			initialValues={initialState}
			onSubmit={async (values, actions) => {
				actions.setSubmitting(true)
				if (lockPin === values.pin) {
					onClose?.({}, 'escapeKeyDown')
				} else {
					actions.setErrors({ pin: 'incorrect pin' })
				}
				actions.setSubmitting(false)
			}}
			validateOnBlur={false}
			validateOnChange={false}
			validationSchema={validationSchema}
		>
			{({ handleSubmit, dirty, isSubmitting }) => (
				<>
					<Wrapper>
						<MdLockOutline
							size={60}
							color={theme.palette.colors.green[900]}
						/>
						<Gutter />
						<Typography fontWeight={500}>
							Enter your pin to unlock
						</Typography>
						<Gutter spacing={2} />
						<FieldOtpTextInput name={'pin'} length={4} />
						<Gutter spacing={2} />
					</Wrapper>
					<Button
						onClick={() => handleSubmit()}
						variant={'contained'}
						type={'submit'}
						disabled={!dirty || isSubmitting}
					>
						Unlock
					</Button>
				</>
			)}
		</Formik>
	)
}
