import React from 'react'
import { styled } from '@mui/system'
import { Button, Gutter } from 'components'
import { FlexCol } from 'components/atoms/Flex'
import type { ProgressbarProps } from 'screen/Onboarding/Form/ProgressBar'
import { useTranslate } from 'hooks'
import FormBg from 'assets/onboarding/form-bg.png'

const Container = styled(FlexCol)`
	padding: 0;
	width: 100%;
	height: 100%;
`

const BackButton = styled('div')`
	position: absolute;
	bottom: 60px;
	left: 50px;
`
const Image = styled('img')`
	width: 100%;
	height: 400px;
	bottom: 0;
	left: 0;
	position: absolute;
`

type FormLayoutProps = {
	children?: React.ReactNode
	step: number
	onBackPress?(): void
} & ProgressbarProps

export const FormLayout: React.ComponentType<FormLayoutProps> = ({
	children,
	onBackPress,
	step
}) => {
	const translate = useTranslate()
	return (
		<>
			<Image src={FormBg} />

			<Container align={'center'} justify={'space-around'}>
				<FlexCol align={'center'} justify={'center'}>
					{children}
				</FlexCol>
				{step != 0 && (
					<BackButton>
						<Button
							mode="secondary"
							textColor={'green'}
							onClick={onBackPress}
						>
							{translate('app.button.back')}
						</Button>
					</BackButton>
				)}
				<Gutter />
			</Container>
		</>
	)
}
