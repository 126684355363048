import React, { useMemo, useState } from 'react'
import type { IUserPostProps } from 'typings'
import { FlexCol, FlexRow } from 'components/atoms/Flex'
import { styled } from '@mui/system'
import {
	FormControlLabel,
	Radio,
	RadioGroup,
	Typography,
	useTheme
} from '@mui/material'
import { IoIosClose } from 'react-icons/io'
import { Formik } from 'formik'
import { FieldNumberInput, FieldTextInput, Gutter, Label } from 'components'
import {
	INVITE_USER_BUSINESS_TYPE,
	INVITE_USER_INITIAL_VALUE
} from 'data/InviteUser'
import { TransparentButton } from 'components/atoms/Button/TransparentButton'
import * as Yup from 'yup'
import { useSettings } from 'context/settings'
import { validatePhoneNo } from 'helpers/validatePhoneNo'
import { truncate } from 'helpers'
import { useSnackbar } from 'notistack'
import { useAppErrors, useDimension } from 'hooks'
import { useSelector } from 'react-redux'
import type { RootState } from 'store/index-reducer'
import { postInviteUser, updateUserInvite } from 'api/inviteUser'
import analytics from 'utils/segment'
import { SEGMENT_EVENTS } from 'data'

const Wrapper = styled(FlexCol)<{ height: number }>`
	min-width: 350px;
	max-width: 400px;
	height: 100%;
	flex-wrap: nowrap;
	background-color: ${({ theme }) => theme.palette.background.default};

	@media screen and (max-width: 500px) {
		min-width: ${({ height }) => height}px;
		max-width: ${({ height }) => height}px;
	}
`

const Col = styled(FlexCol)`
	height: 100%;
	min-width: 350px;
	max-width: 400px;
`

const Header = styled(FlexRow)`
	align-items: center;
	justify-content: space-between;
	padding: 16px;
	width: 100%;
	border-bottom: 1px solid ${({ theme }) => theme.palette.colors.gray['300']};
`

const StyledButton = styled(TransparentButton)`
	background-color: ${({ theme }) => theme.palette.colors.green['500']};

	:hover {
		background-color: ${({ theme }) => theme.palette.colors.green['600']};
	}

	:disabled {
		opacity: 0.5;
	}
`

const Cont = styled(FlexCol)`
	width: 100%;
	flex-wrap: nowrap;
	height: -webkit-fill-available;
	justify-content: space-between;
	padding: 16px;
`

const Grid = styled('div')`
	display: grid;
	grid-template-columns: 1fr;
	column-gap: 20px;
	align-items: center;
	width: 100%;
`

const Row = styled(FlexRow)`
	width: 100%;
`

const validationSchema = Yup.object().shape({
	phone: Yup.object().shape({
		number: Yup.string()
			.required('Phone no is required')
			.test('number', (num, values) => {
				const {
					parent: { number, countryCode, countryInitials }
				} = values

				if (!number) {
					return true
				}

				const res = validatePhoneNo(
					number,
					countryCode,
					countryInitials
				)

				if (!res.isValid) {
					return values.createError({
						path: 'phone.number',
						message: truncate(res.message, 47)
					})
				}
				return true
			})
			.min(4, 'Phone number should be 4 digits long.')
	}),
	fullName: Yup.string().required('Full name is required')
})

type Props = {
	inactive?: boolean
	user?: { id: string } & IUserPostProps
	onClose?(): void
	onSuccess?(): void
}

export const AddUser: React.ComponentType<Props> = ({
	user,
	inactive,
	onSuccess,
	onClose
}) => {
	const [isReactivate, setIsReactive] = useState(false)
	const [isSave, setIsSave] = useState(true)
	const theme = useTheme()
	const { enqueueSnackbar } = useSnackbar()
	const { business } = useSettings()
	const { setAppError } = useAppErrors()
	const { width } = useDimension()
	const { colors } = theme.palette
	const { user: stateUser } = useSelector((state: RootState) => state.user)
	const currentRole = useMemo(() => {
		if (stateUser?.userRoles && stateUser?.userRoles.length > 0)
			return stateUser?.userRoles.find(f => f.business === business)?.role
		return 'admin'
	}, [stateUser?.userRoles, business])

	const onSubmit = async (values: IUserPostProps, actions: any) => {
		try {
			actions.setSubmitting(true)
			if (values.id) {
				await updateUserInvite(business, user?.id || '', {
					...values,
					activate: isReactivate
				})
				enqueueSnackbar('Successfully Updated', {
					variant: 'success'
				})
			} else {
				await postInviteUser(values)
				enqueueSnackbar('Successfully Saved', {
					variant: 'success'
				})
			}

			onSuccess?.()

			if (!isSave) {
				actions.resetForm({
					values: { ...INVITE_USER_INITIAL_VALUE, business }
				})
			} else {
				onClose?.()
			}
		} catch (e: any) {
			setAppError(e)
		} finally {
			actions.setSubmitting(false)
		}
	}

	return (
		<Wrapper height={width}>
			<Header>
				<Typography fontWeight={700} variant={'h6'}>
					{user ? 'Edit User' : 'Add User'}
				</Typography>
				<IoIosClose size={25} onClick={() => onClose?.()} />
			</Header>
			<Formik
				initialValues={
					user || { ...INVITE_USER_INITIAL_VALUE, business }
				}
				onSubmit={onSubmit}
				validationSchema={validationSchema}
			>
				{({
					values,
					handleSubmit,
					isSubmitting,
					setFieldValue,
					isValid
				}) => (
					<Cont>
						<Col>
							<FieldTextInput
								name={'fullName'}
								size={'small'}
								title={'Full Name'}
								wrapperStyle={{ width: '100%' }}
								style={{ width: '100%' }}
							/>
							<Gutter />

							<Grid>
								<FieldNumberInput
									title="Phone"
									placeholder={'Phone number'}
									name={'phone.number'}
									style={{
										width: '100%',
										verticalAlign: 'baseline'
									}}
									size="small"
									inputProps={{ maxLength: 10 }}
									InputProps={{
										startAdornment: (
											<Typography
												sx={{
													marginRight: 1,
													marginTop: -0.1
												}}
											>
												+92
											</Typography>
										)
									}}
								/>
							</Grid>
							<Gutter />
							<FlexRow
								style={{ width: '100%' }}
								align={'flex-start'}
							>
								<Label>Role</Label>
							</FlexRow>
							<RadioGroup
								aria-labelledby="demo-radio-buttons-group-label"
								defaultValue={values.businessRole}
								name="radio-buttons-group"
								onChange={e =>
									setFieldValue(
										'businessRole',
										e.target.value
									)
								}
							>
								{INVITE_USER_BUSINESS_TYPE(
									currentRole !== 'owner'
								).map(d => (
									<FlexCol key={d.label}>
										<FormControlLabel
											value={d.value}
											control={<Radio size={'small'} />}
											label={d.label}
											disabled={d.disabled}
										/>
										<Typography
											color={colors.gray['600']}
											variant={'caption'}
											style={{
												marginLeft: 28,
												display: 'flex',
												flex: 1,
												overflowWrap: 'break-word'
											}}
										>
											{d.detail}
										</Typography>
									</FlexCol>
								))}
							</RadioGroup>
						</Col>
						<Row justify={'flex-end'}>
							{!values.id && (
								<TransparentButton
									onClick={() => {
										setIsSave(false)
										handleSubmit()
									}}
									disabled={isSubmitting || !isValid}
								>
									<Typography
										fontWeight={500}
										variant={'caption'}
										fontSize={14}
									>
										Save & Add Another
									</Typography>
								</TransparentButton>
							)}
							<Gutter gap={0.5} />
							{!inactive && (
								<StyledButton
									onClick={() => {
										analytics.track(
											values.id
												? SEGMENT_EVENTS.SETTINGS_COMPLETE_UPDATE_USER
												: SEGMENT_EVENTS.SETTINGS_COMPLETE_NEW_USER,
											{
												businessRole:
													values.businessRole,
												fullName: values.fullName,
												business: values.business
											}
										)
										setIsSave(true)
										handleSubmit()
									}}
									disabled={isSubmitting || !isValid}
								>
									<Typography
										fontWeight={500}
										variant={'caption'}
										fontSize={14}
										color={colors.white['200']}
									>
										{values.id
											? 'Save Changes'
											: 'Save User'}
									</Typography>
								</StyledButton>
							)}
							{inactive && (
								<>
									<TransparentButton
										onClick={() => {
											analytics.track(
												values.id
													? SEGMENT_EVENTS.SETTINGS_COMPLETE_UPDATE_USER
													: SEGMENT_EVENTS.SETTINGS_COMPLETE_NEW_USER,
												{
													businessRole:
														values.businessRole,
													fullName: values.fullName,
													business: values.business
												}
											)
											setIsSave(true)
											handleSubmit()
										}}
										disabled={isSubmitting || !isValid}
									>
										<Typography
											fontWeight={500}
											variant={'caption'}
											fontSize={14}
										>
											Save Changes
										</Typography>
									</TransparentButton>
									<Gutter gap={0.5} />
									<StyledButton
										onClick={() => {
											setIsReactive(true)
											handleSubmit()
										}}
										disabled={isSubmitting || !isValid}
									>
										<Typography
											fontWeight={500}
											variant={'caption'}
											fontSize={14}
											color={colors.white['200']}
										>
											Save and Reactivate
										</Typography>
									</StyledButton>
								</>
							)}
						</Row>
					</Cont>
				)}
			</Formik>
		</Wrapper>
	)
}
