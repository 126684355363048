import * as React from 'react'
import { useMemo } from 'react'
import {
	Button as MUIButton,
	InputAdornment,
	styled,
	Typography
} from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import type { RootState } from 'store/index-reducer'
import { Formik } from 'formik'
import {
	Button,
	FieldNumberInput,
	FieldTextInput,
	Gutter,
	StyledModal
} from 'components'
import { FlexCol, FlexRow } from 'components/atoms/Flex'
import * as Yup from 'yup'
import { useAppErrors } from 'hooks/useAppErrors'
import { useTranslate } from 'hooks'
import { createTaxUnit, deleteTaxUnit, updateTaxUnit } from 'api'
import { useSnackbar } from 'notistack'
import { fetchTaxes } from 'store/settings/actions'
import type { ITaxUnit } from 'typings'
import analytics from 'utils/segment'
import { SEGMENT_EVENTS } from 'data'
import { convertNumber } from 'helpers'
import { IoIosClose } from 'react-icons/io'

const validationSchema = Yup.object().shape({
	name: Yup.string().required('Required'),
	rate: Yup.string().required('Required')
})

const Wrapper = styled(FlexCol)`
	width: 100%;
	height: 100%;
	max-width: 350px;
`

const Btn = styled(MUIButton)`
	padding: 8px 20px;
`

const Container = styled(FlexRow)`
	align-self: center;
	justify-self: center;
	padding: 10px 15px;
	height: auto;
	background-color: ${({ theme }) => theme.palette.background.default};
	border-radius: 10px;
	justify-content: space-between;
`

const CloseButtonWrapper = styled(FlexCol)`
	justify-content: center;
	align-items: center;
	height: 30px;
	width: 30px;
	right: 1.75em;

	&:hover {
		cursor: pointer;
		border-radius: 25%;
		background-color: ${({ theme }) => theme.palette.colors.gray['100']};
		display: flex;
	}
`

type AddTaxModalProps = {
	id?: string
	open: boolean
	onClose(): void
	onSuccess?(tax: ITaxUnit): void
}

export const AddTaxModal: React.ComponentType<AddTaxModalProps> = ({
	open,
	onClose,
	id,
	onSuccess
}) => {
	const dispatch = useDispatch()
	const { setAppError } = useAppErrors()
	const { enqueueSnackbar } = useSnackbar()
	const translate = useTranslate()
	const { dataSource } = useSelector(
		(state: RootState) => state.settings.taxUnits
	)

	const initialValue = useMemo(() => {
		return id
			? dataSource.filter(f => f.id === id)[0]
			: { name: '', rate: '' }
	}, [dataSource, id])

	const onSubmit = async (
		payload: Pick<ITaxUnit, 'name' | 'rate'>,
		actions: any
	) => {
		try {
			actions.setSubmitting(true)
			if (id) {
				await updateTaxUnit(id, payload)
				enqueueSnackbar(translate('snackbar.successUpdate'), {
					variant: 'success'
				})
			} else {
				const res = await createTaxUnit(payload)
				enqueueSnackbar(translate('snackbar.successCreate'), {
					variant: 'success'
				})
				onSuccess?.(res)
			}
			dispatch(fetchTaxes())
			onClose()
		} catch (e: any) {
			setAppError(e, actions)
		} finally {
			actions.setSubmitting(false)
		}
	}

	const onDelete = async (actions: any) => {
		try {
			actions.setSubmitting(true)
			await deleteTaxUnit(id || '')
			enqueueSnackbar(translate('snackbar.successDelete'), {
				variant: 'success'
			})
			dispatch(fetchTaxes())
			onClose()
		} catch (e: any) {
			setAppError(e, actions)
		} finally {
			actions.setSubmitting(true)
		}
	}

	return (
		<StyledModal
			onClose={onClose}
			aria-labelledby="customized-dialog-title"
			open={open}
		>
			<Container>
				<FlexRow
					align="center"
					justify="space-between"
					sx={{ width: '100%' }}
				>
					<Typography variant={'h6'} fontWeight={600}>
						{id ? 'Edit Tax' : 'Add Tax'}
					</Typography>
					<CloseButtonWrapper>
						<IoIosClose size={25} onClick={onClose} />
					</CloseButtonWrapper>
				</FlexRow>
				<Formik
					initialValues={initialValue}
					validationSchema={validationSchema}
					onSubmit={(values, actions) => {
						const finalValue = Object.assign({}, values, {
							rate: convertNumber(values.rate)
						})
						onSubmit(finalValue, actions)
					}}
				>
					{({ handleSubmit, values, ...actions }) => (
						<Wrapper>
							<FieldTextInput
								name={'name'}
								sx={{ width: '250px', marginTop: 1 }}
								size={'small'}
								placeholder={'Tax Name'}
							/>
							<Gutter spacing={0.8} />
							<FieldNumberInput
								name={'rate'}
								size={'small'}
								placeholder={'Tax Percentage'}
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<Typography variant={'body2'}>
												%
											</Typography>
										</InputAdornment>
									)
								}}
								sx={{ width: '250px' }}
							/>

							<Gutter spacing={4} />
							<FlexRow
								justify={'flex-end'}
								align={'center'}
								style={{
									width: '100%'
								}}
							>
								{id && (
									<Btn
										variant="outlined"
										color="error"
										onClick={() => {
											analytics.track(
												SEGMENT_EVENTS.SETTINGS_DELETE_TAX
											)
											onDelete(actions)
										}}
										disabled={actions.isSubmitting}
									>
										<Typography
											fontWeight={600}
											variant={'subtitle1'}
										>
											{'Delete'}
										</Typography>
									</Btn>
								)}
								{!id && (
									<Btn
										variant={'text'}
										onClick={() => {
											onClose()
										}}
										disabled={actions.isSubmitting}
									>
										Cancel
									</Btn>
								)}
								<Gutter gap={1} />
								<Button
									type="submit"
									disabled={
										actions.isSubmitting || !actions.dirty
									}
									onClick={() => {
										analytics.track(
											id
												? SEGMENT_EVENTS.SETTINGS_TAX_EDIT_SUCCESS
												: SEGMENT_EVENTS.SETTINGS_TAX_NEW_SUCCESS,
											{
												tax_name: values.name,
												tax_percentage: values.rate,
												screen: 'settings'
											}
										)
										handleSubmit()
									}}
									style={{ padding: '10px 20px' }}
								>
									{id ? 'Done' : 'Save'}
								</Button>
							</FlexRow>
						</Wrapper>
					)}
				</Formik>
			</Container>
		</StyledModal>
	)
}
