import * as React from 'react'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { useTheme } from '@mui/material'
import { AllInvoices } from 'screen/LoadSheet/Create/AllInvoices'
import { Gutter } from 'components'
import type { InvoiceType } from 'typings'
import { useFormikContext } from 'formik'
import type { LoadsheetType } from 'typings/loadsheet'
import { SelectedInvoice } from 'screen/LoadSheet/Create/SelectedInvoice'

interface TabPanelProps {
	children?: React.ReactNode
	index: number
	value: number
}

function CustomTabPanel(props: TabPanelProps) {
	const { children, value, index, ...other } = props

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box>
					<Gutter spacing={0.5} />
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	)
}

function a11yProps(index: number) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`
	}
}

type LoadSheetTabsProps = {
	onItemSelect?(inv: InvoiceType): void
	showIncludedInvoice: boolean
	searchKey?: string
	filterObj: { [key: string]: string }
	value: number
	setValue: React.Dispatch<React.SetStateAction<number>>
}

export const LoadSheetTabs: React.ComponentType<LoadSheetTabsProps> = ({
	onItemSelect,
	showIncludedInvoice,
	searchKey,
	filterObj,
	value,
	setValue
}) => {
	const { values } = useFormikContext<LoadsheetType>()

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setValue(newValue)
	}
	const theme = useTheme()
	const {
		palette: { colors }
	} = theme

	return (
		<Box sx={{ width: '100%' }}>
			<Box sx={{ borderBottom: 1, borderColor: colors.gray['300'] }}>
				<Tabs
					value={value}
					onChange={handleChange}
					aria-label="basic tabs example"
				>
					<Tab label={'All Invoices'} {...a11yProps(0)} />
					<Tab
						label={`Selected Invoices (${values.invoiceItems.length})`}
						{...a11yProps(1)}
					/>
				</Tabs>
			</Box>
			<CustomTabPanel value={value} index={0}>
				<AllInvoices
					searchKey={searchKey || ''}
					onItemSelect={onItemSelect}
					showIncludedInvoice={showIncludedInvoice}
					filterObj={filterObj}
				/>
			</CustomTabPanel>
			<CustomTabPanel value={value} index={1}>
				<SelectedInvoice onItemSelect={onItemSelect} />
			</CustomTabPanel>
			<Gutter spacing={0.8} />
			<Typography
				variant="caption"
				style={{ backgroundColor: colors.gray['200'], padding: '6px', borderRadius: '4px' }}
			>
				{'* Invoice included in the previous load sheet'}
			</Typography>
		</Box>
	)
}
