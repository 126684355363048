export function currencyToLocal(price: number) {
	return `${Math.abs(price)}`.toLocaleString()
}
export function currencyInvoice(price: number) {
	return price.toLocaleString()
}

export function amountToLocal(price: number | string = 0) {
	return price.toLocaleString()
}

export function currencyInput(price: string) {
	const amount = price.toString()
	if (amount.search('.') !== -1 && amount.charAt(amount.length - 1) === '.') {
		return amount
	}

	return parseFloat(amount).toLocaleString()
}
