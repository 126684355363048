import React, { useState } from 'react'
import { Button, styled, Typography, useTheme } from '@mui/material'
import { StyledModal } from 'components/StyledModal'
import { FlexCol, FlexRow } from 'components/atoms/Flex'
import Warning from 'assets/alerts/warning.png'
import { Gutter } from 'components'
import { formatId } from 'helpers'
import analytics from 'utils/segment'
import { SEGMENT_EVENTS } from 'data'

const Wrapper = styled('div')`
	width: 520px;
`

const Background = styled('div')`
	padding: 20px;
	border-radius: 10px;
	background-color: ${({ theme }) => theme.palette.background.default};
	margin-top: 20px;
	margin-bottom: 20px;
	overflow-y: auto;
`
const WrapperButton = styled(Button)(({ theme }) => ({
	backgroundColor: theme.palette.colors.green['600'],
	color: theme.palette.colors.white['900'],
	padding: '8px 12px',
	borderRadius: '8px',
	height: '40px',
	'&:hover': {
		background: theme.palette.colors.green['700']
	}
}))

type BulkPublishErrorModalProps = {
	open: boolean
	onClose(): void
	invoices: any[]
}

export const BulkPublishErrorModal: React.ComponentType<
	BulkPublishErrorModalProps
> = ({ onClose, open, invoices }) => {
	const theme = useTheme()
	const { colors } = theme.palette

	const commaSeparatedInvoiceIds = invoices.map((invoice, idx) => {
		return (
			<Typography
				key={invoice?.invoiceId}
				variant="caption"
				style={{ display: 'inline' }}
			>
				{idx !== 0 && ', '}
				{`INV-${formatId(invoice?.friendlyId)}`}
			</Typography>
		)
	})

	return (
		<StyledModal open={open} onClose={onClose}>
			<Background>
				<Wrapper>
					<FlexCol justify="space-between">
						<FlexRow>
							<img
								alt={'warning'}
								src={Warning}
								style={{
									width: '50px',
									height: '50px'
								}}
							/>
							<Gutter gap={2} />
							<FlexCol style={{ width: '420px' }}>
								<Typography variant={'h6'} fontWeight={600}>
									Unable to Publish Some Invoices
								</Typography>
								<Gutter spacing={0.3} />

								<Typography
									variant="body2"
									color={colors.gray['500']}
								>
									{`The following draft invoices are incomplete and cannot be published:`}
								</Typography>
								<Gutter spacing={2} />

								<Typography
									variant="body2"
									color={colors.gray['500']}
								>
									{commaSeparatedInvoiceIds}
								</Typography>
								<Gutter spacing={2.5} />
								<FlexRow
									justify="flex-end"
									style={{ width: '100%' }}
								>
									<WrapperButton
										onClick={() => {
											analytics.track(
												SEGMENT_EVENTS.INVOICE_INCOMPLETE_BULK_PUBLISH, {
													invoices_selected_count: invoices.length
												}
											)
											onClose?.()
										}}
									>
										Okay
									</WrapperButton>
								</FlexRow>
							</FlexCol>
						</FlexRow>
					</FlexCol>
				</Wrapper>
			</Background>
		</StyledModal>
	)
}
