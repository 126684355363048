import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
	Button,
	ClickAwayListener,
	Fade,
	Popper,
	styled,
	Typography
} from '@mui/material'
import PopupState, { bindPopper, bindToggle } from 'material-ui-popup-state'
import { FlexCol, FlexRow } from 'components/atoms/Flex'
import { addOrRemoveArr } from 'helpers'
import { KeyboardArrowLeft, Person2Outlined } from '@mui/icons-material'
import ArrowDropDown from '@mui/icons-material/ArrowDropDown'
import { CheckBox, Gutter } from 'components'
import { useSettings } from 'context/settings'
import { useAppErrors } from 'hooks'
import { getAllBusinessBookers } from 'api'
import type { IBookerProps } from 'typings'
import Close from '@mui/icons-material/Close'

const StyledButton = styled(Button, {
	shouldForwardProp: props => props !== 'selected'
})<{ selected?: boolean }>`
	background-color: ${({ theme, selected }) =>
		selected
			? theme.palette.colors.green['50']
			: theme.palette.background.default};
	border: 0.67px solid
		${({ theme, selected }) =>
			selected
				? theme.palette.colors.green[300]
				: theme.palette.colors.gray[300]};
	border-radius: 5.33px;
	min-height: 32px;
	padding: 0 12px;
	color: ${({ theme }) => theme.palette.colors.gray['700']};

	:hover {
		background-color: ${({ theme }) => theme.palette.colors.green['100']};
	}
`

const Wrapper = styled(FlexCol)`
	background-color: ${({ theme }) => theme.palette.background.default};
	border: 1px solid ${({ theme }) => theme.palette.background.paper};
	border-radius: 5.33px;
	box-shadow: 0 12px 16px -4px rgba(16, 24, 40, 0.08),
		0px 4px 6px -2px rgba(16, 24, 40, 0.03);
	overflow: hidden;
	margin-left: 8px;
`

const Option = styled(FlexRow)`
	padding: 6.67px 10.67px;
	align-items: center;

	:hover {
		cursor: pointer;
	}
`

const OptionHeader = styled(FlexRow)`
	align-items: center;
	padding: 6.67px 10.67px;
	width: 100%;
	background-color: ${({ theme }) => theme.palette.background.default};
	border-bottom: 1px solid ${({ theme }) => theme.palette.colors.gray['100']};
`

const Col = styled(FlexCol)`
	min-width: 160px;
`

const StyledTypography = styled(Typography)`
	font-size: 13.33px;
`

const StyledBackArrow = styled(KeyboardArrowLeft)`
	font-size: 16px;
	color: ${({ theme }) => theme.palette.colors.green['700']};
`

export type BookerFilterObjProps = { [key: string]: string }

export type FilterButtonProps = {
	filterObj?: BookerFilterObjProps
	onSelect?(filterObj: BookerFilterObjProps): void
	disabled?: boolean
}

export const BookerFilter: React.ComponentType<FilterButtonProps> = ({
	filterObj,
	onSelect,
	disabled
}) => {
	const { business } = useSettings()
	const { setAppError } = useAppErrors()
	const [orderBookers, setOrderBookers] = useState<IBookerProps[]>([])

	const onValueChange = useCallback(
		(value: string[]) => {
			onSelect?.({ ...filterObj, orderBooker: value.join(',') })
		},
		[onSelect, filterObj]
	)

	const filterArray = useMemo(() => {
		if (filterObj?.orderBooker === '') return []
		return filterObj?.orderBooker?.split(',')
	}, [filterObj])

	const isFilter = useMemo(() => {
		return filterArray && filterArray.length !== 0
	}, [filterArray])

	const RenderFilter = useCallback(
		({ name, id }: IBookerProps) => {
			return (
				<Col>
					<Option
						onClick={() => {
							onValueChange(
								addOrRemoveArr(filterArray || [], id || '')
							)
						}}
					>
						<CheckBox
							checked={
								filterArray &&
								filterArray.indexOf(id || '') !== -1
							}
						/>
						<Gutter gap={0.6} />
						<StyledTypography variant={'body1'}>
							{name.split(' ')[0]}
						</StyledTypography>
					</Option>
				</Col>
			)
		},
		[onValueChange, filterArray]
	)

	const getInitialBookersList = useCallback(async () => {
		try {
			const res = await getAllBusinessBookers(business)
			setOrderBookers(res)
		} catch (e: any) {
			setAppError(e)
		}
	}, [business, setAppError])

	useEffect(() => {
		getInitialBookersList()
	}, [getInitialBookersList])

	return (
		<PopupState
			variant="popper"
			popupId="demo-popup-popper"
			disableAutoFocus={true}
		>
			{popupState => (
				<ClickAwayListener onClickAway={() => popupState.close()}>
					<div>
						<StyledButton
							{...bindToggle(popupState)}
							selected={isFilter}
							disabled={disabled}
						>
							<Person2Outlined sx={{ fontSize: '16px' }} />
							<Gutter gap={0.4} />
							<Typography
								variant={'caption'}
								fontWeight={'medium'}
							>
								Booker{isFilter && `(${filterArray?.length})`}
							</Typography>
							<Gutter gap={0.3} />
							{!isFilter && (
								<ArrowDropDown sx={{ fontSize: '12px' }} />
							)}
							{isFilter && (
								<Close
									sx={{ fontSize: '16px' }}
									onClick={e => {
										e.stopPropagation()
										onSelect?.({
											...filterObj,
											orderBooker: ''
										})
									}}
								/>
							)}
						</StyledButton>
						<Popper
							{...bindPopper(popupState)}
							transition
							placement="bottom-start"
						>
							{({ TransitionProps }) => (
								<Fade {...TransitionProps} timeout={350}>
									<Wrapper>
										<Col>
											<OptionHeader>
												<StyledBackArrow />
												<Gutter gap={0.2} />
												<Typography
													variant={'body1'}
													fontSize={'14px'}
												>
													Filter by Booker
												</Typography>
											</OptionHeader>
											<Option
												onClick={() => {
													onValueChange(
														addOrRemoveArr(
															filterArray || [],
															'-1'
														)
													)
												}}
											>
												<CheckBox
													checked={
														filterArray &&
														filterArray.indexOf(
															'-1'
														) !== -1
													}
												/>
												<Gutter gap={0.6} />
												<StyledTypography
													variant={'body1'}
												>
													Unassigned
												</StyledTypography>
											</Option>
										</Col>
										{orderBookers.map(d => (
											<React.Fragment key={d.id}>
												<RenderFilter {...d} />
											</React.Fragment>
										))}
									</Wrapper>
								</Fade>
							)}
						</Popper>
					</div>
				</ClickAwayListener>
			)}
		</PopupState>
	)
}
