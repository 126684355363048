import * as React from 'react'
import { type Dispatch, type SetStateAction, useEffect, useState } from 'react'

import Paper from '@mui/material/Paper'
import { TableVirtuoso } from 'react-virtuoso'
import { styled } from '@mui/system'
import { useDimension } from 'hooks'
import type { TransactionProps } from 'typings'
import { LedgerItem } from 'screen/Contact/Ledger/LedgerItem'
import { TableHeader } from 'screen/Contact/Ledger/TableHeader'
import { LedgerTableComponent } from 'screen/Contact/Ledger/LedgerTableComponents'
import { FlexCol, FlexRow } from 'components/atoms/Flex'
import { LedgerTransactionModal } from 'screen/Contact/Ledger/Components/LedgerTransactionModal'
import { Typography } from '@mui/material'

const Wrapper = styled(FlexCol)`
	display: flex;
	flex-direction: column;
	background-color: ${({ theme }) => theme.palette.background.default};
	width: 100%;
	border-radius: 8px;
	border: 1px solid ${({ theme }) => theme.palette.colors.gray['300']};
	box-shadow: 0 4px 6px -2px rgba(16, 24, 40, 0.03),
		0px 12px 16px -4px rgba(16, 24, 40, 0.08);
	overflow: hidden;
`

const Table = styled(TableVirtuoso)`
	width: 100%;
	background: ${({ theme }) => theme.palette.colors.white['500']};
`

const StyledPaper = styled(Paper)`
	position: relative;
	width: 100%;
	height: 100%;
	background: ${({ theme }) => theme.palette.colors.white['500']};
	z-index: 0;
`

const AbsoluteWrapper = styled(FlexRow)`
	width: 100%;
	z-index: 1;
	align-items: center;
	justify-content: center;
	position: absolute;
	height: 100%;
`

type Props = {
	contactId: string
	setNumberOfTransactions: Dispatch<SetStateAction<number | undefined>>
	searchKey: string
	dataSource: TransactionProps[]
}

export const Transactions: React.ComponentType<Props> = ({
	setNumberOfTransactions,
	searchKey,
	dataSource
}) => {
	const [selectedTransaction, setSelectedTransaction] = useState<
		TransactionProps | undefined
	>(undefined)
	const { height } = useDimension()

	useEffect(() => {
		setNumberOfTransactions(dataSource.length)
	}, [dataSource, setNumberOfTransactions])

	return (
		<Wrapper style={{ height: height - 220 }}>
			<StyledPaper>
				{searchKey && dataSource.length === 0 && (
					<AbsoluteWrapper>
						<Typography>
							No entry found for &quot;{`${searchKey}`}&quot;
						</Typography>
					</AbsoluteWrapper>
				)}
				<Table
					data={dataSource}
					components={LedgerTableComponent}
					fixedHeaderContent={TableHeader}
					itemContent={(_index: number, row: any) => (
						<LedgerItem
							key={row.id}
							row={row}
							onItemSelect={setSelectedTransaction}
							totalItem={dataSource.length}
						/>
					)}
					/*endReached={() => {
						//fetchInvoiceList()
					}}*/
					// onScroll={onScroll}
				/>
			</StyledPaper>
			<LedgerTransactionModal
				open={!!selectedTransaction}
				onClose={() => setSelectedTransaction(undefined)}
				selectedTransaction={selectedTransaction as TransactionProps}
			/>
		</Wrapper>
	)
}
