import React from 'react'
import { styled } from '@mui/system'
import type { ColorsCodeType } from 'typings/color'
import { FiUser } from 'react-icons/fi'
import { FlexCol } from 'components/atoms/Flex'

const Wrapper = styled(FlexCol, {
	shouldForwardProp: props =>
		props !== 'borderWidth' && props !== 'borderColor' && props !== 'width'
})<Pick<UserAvatarProps, 'borderWidth' | 'borderColor' | 'width'>>`
	position: relative;
	align-items: center;
	justify-content: center;
	aspect-ratio: 1;
	border-radius: 50%;
	padding: ${({ borderWidth }) => borderWidth ?? 2}px;
	width: ${({ width }) => (width ? `${width}px` : '35px')};
	height: ${({ width }) => (width ? `${width}px` : '35px')};
	background-color: ${({ theme, borderColor }) =>
		borderColor ? borderColor : theme.palette.colors.green['700']};
	border: 1px solid ${({ theme }) => theme.palette.colors.green['300']};
`

type UserAvatarProps = {
	borderWidth?: number
	borderColor?: ColorsCodeType
	width?: number
	title?: string
}

export const UserAvatar: React.ComponentType<UserAvatarProps> = ({
	borderWidth,
	borderColor,
	title,
	width
}) => {
	return (
		<Wrapper
			borderWidth={borderWidth}
			borderColor={borderColor}
			width={width}
		>
			{!title && <FiUser />}
			{title}
		</Wrapper>
	)
}
