import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { styled } from '@mui/material'
import { FieldTextInput, Gutter } from 'components'
import { FlexCol } from 'components/atoms/Flex'
import { Formik } from 'formik'
import { ExtraCharges } from './ExtraCharges'
import { QuotationHeader } from './QuotationHeader'
import { useDimension } from 'hooks'
import { SelectItems } from './SelectItems'
import { Receipt } from './Receipt'
import type { QuotationItemProps, QuotationType } from 'typings'
import { useAppErrors } from 'hooks/useAppErrors'
import { useSnackbar } from 'notistack'
import {
	createAQuotation,
	getAQuotation,
	updateAnQuotation
} from 'api/quotation'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useSettings } from 'context/settings'
import { detailedDiff } from 'deep-object-diff'
import * as Yup from 'yup'
import {
	objectFindKey,
	objectOmit,
	objectPick,
	objectRemoveEmptyDeeply
} from 'helpers/object'
import { useDispatch, useSelector } from 'react-redux'
import type { RootState } from 'store/index-reducer'
import type { InvoiceType } from 'typings/invoices'
import analytics from 'utils/segment'
import {
	CREATE_QUOTATION_INITIAL,
	GENERATE_QUOTATION_DATA,
	SCROLLBAR_STYLE,
	QUOTATION_EDIT_NAVIGATION,
	SEGMENT_EVENTS
} from 'data'
import { PaymentTerms } from 'components/app/Payments/PaymentTerms'
import moment from 'moment'
import { setNavigation } from 'store/app/actions'

const Wrapper = styled('div')<{ height: number }>`
	position: relative;
	display: grid;
	max-height: 100%;
	height: ${({ height }) => (height ? `${height}px` : '100%')};
	width: 100%;
	overflow-y: scroll;

	::-webkit-scrollbar {
		display: none;
	}
	-ms-overflow-style: none;
	scrollbar-width: none;
`
const Container = styled('div')`
	width: 100%;
	height: 100%;
	max-height: 100%;
`
const TableWrapper = styled(FlexCol)`
	width: 100%;
	height: fit-content;
	min-height: 100px;
	overflow: scroll;

	${SCROLLBAR_STYLE}
`

const Row = styled('div')`
	display: grid;
	width: 100%;
	height: fit-content;
	grid-template-columns: 1fr 1fr 1fr;
	column-gap: 10px;
`

const Col = styled('div')`
	display: grid;
	grid-template-rows: 1.5fr 1fr;
	width: 100%;
	height: 100%;
`

const BodyContainer = styled('div')`
	width: 100%;
	padding: 16px;
	padding-top: 0;
`

const validationSchema = Yup.object().shape({
	contact: Yup.object().shape({
		name: Yup.string().required('Required')
	}),
	items: Yup.array()
		.of(
			Yup.object().shape({
				perItemSellPrice: Yup.number()
					.positive('Error')
					.nullable()
					.min(0.0001, 'Required')
					.typeError('Required'),
				quantity: Yup.number()
					.positive('Error')
					.integer()
					.min(1, 'Required')
					.test('maxQuantity', (quantity, values) => {
						const { parent } = values
						const maxQuantity = parent?.maxQuantity

						if ((quantity || 0) > maxQuantity) {
							return values.createError({
								path: values.path,
								message: `${maxQuantity} allowed`
							})
						}
						return true
					})
					.required('Required')
					.typeError('Required'),
				description: Yup.mixed().test(
					'description',
					(description, values) => {
						const { from } = values.options as any
						if (
							from &&
							objectFindKey(from[0].value, 'description')
						) {
							if (description === undefined) {
								return values.createError({
									path: values.path,
									message: 'Description is required'
								})
							}

							if (description === '') {
								return values.createError({
									path: values.path,
									message: 'Description is required'
								})
							}

							if (description.length > 500) {
								return values.createError({
									path: values.path,
									message:
										'Description should be less than 500 characters'
								})
							}
						}
						return true
					}
				)
			})
		)
		.min(1, 'min 1 item is required')
		.required('Required'),
	extraCharges: Yup.array().of(
		Yup.object().shape({
			description: Yup.string().required('Required'),
			amount: Yup.string().required('Required')
		})
	),
	discount: Yup.object().shape({
		amount: Yup.number()
			.typeError('Number Required')
			.max(100, 'should be less than 100')
	})
})

type CreateQuotationProps = {
	isPaymentTerms?: boolean
	quotationId?: string
	subTotal?: number
	total?: number
	includeCostPrice?: boolean
	isDraft?: boolean
} & QuotationType

export const CreateQuotation = () => {
	const [quotation, setQuotation] = useState<CreateQuotationProps>()
	const { height } = useDimension()
	const { enqueueSnackbar } = useSnackbar()
	const navigate = useNavigate()
	const { setAppError } = useAppErrors()
	const { business } = useSettings()
	const { state } = useLocation()
	const { id } = useParams()
	const dispatch = useDispatch()
	const { businesses } = useSelector((state: RootState) => state.business)

	const currentBusiness = useMemo(() => {
		return businesses.find(bus => bus.id === business)
	}, [business, businesses])

	const businessInfo = useMemo(() => {
		return {
			address: state?.quotation.businessInfo
				? state?.quotation.businessInfo.address
				: currentBusiness?.address || ''
		}
	}, [state, currentBusiness])

	const dispatchTitle = useCallback(
		(quo: QuotationType) => {
			dispatch(
				setNavigation(
					QUOTATION_EDIT_NAVIGATION(
						quo?.friendlyId || '0',
						quo.id || '0'
					)
				)
			)
		},
		[dispatch]
	)

	const onCreate = async (values: QuotationType, actions: any) => {
		let newValue: CreateQuotationProps = {
			...values,
			discount: {
				...values.discount,
				amount: values.discount?.amount || '0'
			},
			business
		}
		newValue = objectRemoveEmptyDeeply(newValue)
		try {
			actions.setSubmitting(true)
			const res = await createAQuotation(newValue)

			enqueueSnackbar('Successfully Created', {
				variant: 'success'
			})

			if (newValue.isDraft) {
				navigate('/quotation', {
					replace: true
				})
			} else {
				navigate(`/quotation/view/${res.id}`, {
					replace: true,
					state: { data: res }
				})
			}
		} catch (e: any) {
			setAppError(e, actions)
		} finally {
			actions.setSubmitting(false)
		}
	}

	const onUpdate = async (values: CreateQuotationProps, actions: any) => {
		try {
			actions.setSubmitting(true)
			const res1: Partial<InvoiceType> = detailedDiff(
				objectOmit(quotation, 'taxes', 'items'),
				objectOmit(values, 'taxes', 'items')
			).updated

			const res = await updateAnQuotation(values?.id || '', {
				...res1,
				...objectPick(values, 'extraCharges', 'taxes', 'items'),
				discount: {
					...values.discount,
					amount: values.discount?.amount || '0'
				},
				paymentTerms: values?.paymentTerms
			})

			enqueueSnackbar('Successfully Updated', {
				variant: 'success'
			})

			if (res.status === 'private') {
				navigate('/quotation', {
					replace: true
				})
			} else {
				navigate(`/quotation/view/${res.id}`, {
					replace: true,
					state: { data: res }
				})
			}
		} catch (e: any) {
			setAppError(e, actions)
		} finally {
			actions.setSubmitting(false)
		}
	}

	const onSubmit = (values: QuotationType, actions: any) => {
		values.items.forEach((item: QuotationItemProps, index: number) => {
			values.items[index] = objectOmit(item, 'id', 'isFrontendKey')
		})
		if (id === undefined || id === '0') {
			onCreate(values, actions)
		} else {
			onUpdate(values, actions)
		}
	}

	const getQuotation = useCallback(
		async (quotationId: string) => {
			try {
				const res = await getAQuotation(quotationId)

				dispatchTitle(res)
				setQuotation(GENERATE_QUOTATION_DATA(res))
			} catch (e: any) {
				setAppError(e)
			}
		},
		[dispatchTitle, setAppError]
	)

	useEffect(() => {
		if (state?.quotation) {
			dispatchTitle(state?.quotation)
			setQuotation(
				GENERATE_QUOTATION_DATA({
					...state?.quotation,
					businessInfo
				})
			)
		} else if (id === '0' || id === undefined) {
			dispatchTitle(CREATE_QUOTATION_INITIAL)
			setQuotation({
				...CREATE_QUOTATION_INITIAL,
				initiationAt: moment.utc(moment()).format(),
				businessInfo,
				isPaymentTerms: false,
				subTotal: 0,
				total: 0,
				includeCostPrice: false,
				isDraft: true,
				taxes: [],
				extraCharges: []
			})
		} else {
			getQuotation(id || '')
		}
	}, [businessInfo, getQuotation, id, state?.quotation])

	return (
		<>
			{quotation && (
				<Formik
					initialValues={quotation}
					onSubmit={onSubmit}
					validationSchema={validationSchema}
				>
					{({ values }) => (
						<>
							<Wrapper height={height - 60}>
								<Container>
									<QuotationHeader
										isQuotation={
											!!state?.quotation ||
											id !== '0' ||
											id !== undefined
										}
										id={id}
									/>
									<BodyContainer>
										<TableWrapper>
											<SelectItems
												values={values}
												name="items"
											/>
										</TableWrapper>
										<Gutter spacing={2} />
										<Row>
											<ExtraCharges values={values} />
											<Col>
												<PaymentTerms
													paymentTerms={
														values.paymentTerms
													}
													paymentMethod={
														values.paymentMethod
													}
												/>
												<FieldTextInput
													name="comments"
													multiline
													placeholder="Some comments entered"
													rows={2}
													style={{
														width: '100%'
													}}
													onClick={() =>
														analytics.track(
															SEGMENT_EVENTS.QUO_COMMENTS
														)
													}
												/>
											</Col>
											<Receipt values={values} />
										</Row>
									</BodyContainer>
								</Container>
							</Wrapper>
						</>
					)}
				</Formik>
			)}
		</>
	)
}
