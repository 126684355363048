import React from 'react'
import { keyframes } from '@emotion/react'
import { styled } from '@mui/material'

const flashingAnimation = keyframes`
  0% {
    background-color: #ffffff;
  }
  50%, 100% {
    background-color: rgba(152, 128, 255, 0.2);
  }
`

const DotFlashing = styled('div')`
	position: relative;
	width: 10px;
	height: 10px;
	border-radius: 5px;
	background-color: #ffffff;
	color: #ffffff;
	animation: ${flashingAnimation} 1s infinite linear alternate;
	animation-delay: 0.5s;

	&::before,
	&::after {
		content: '';
		display: inline-block;
		position: absolute;
		top: 0;
	}

	&::before {
		left: -15px;
		width: 10px;
		height: 10px;
		border-radius: 5px;
		background-color: #ffffff;
		color: #ffffff;
		animation: ${flashingAnimation} 1s infinite alternate;
		animation-delay: 0s;
	}

	&::after {
		left: 15px;
		width: 10px;
		height: 10px;
		border-radius: 5px;
		background-color: #ffffff;
		color: #ffffff;
		animation: ${flashingAnimation} 1s infinite alternate;
		animation-delay: 1s;
	}
`

export const BouncingDotsLoader = () => {
	return <DotFlashing />
}
