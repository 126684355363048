import React from 'react'
import { styled } from '@mui/system'
import { FlexCol } from 'components/atoms/Flex'
import { Typography, useTheme } from '@mui/material'
import { getNameChar } from 'helpers'
import { useFormikContext } from 'formik'
import { HiPlusSm } from 'react-icons/hi'

const Relative = styled(FlexCol)`
	position: relative;
`

const Wrapper = styled(FlexCol)<Pick<R, 'size'>>`
	position: relative;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	background: ${({ theme }) => theme.palette.colors['green']['100']} no-repeat
		center;
	width: ${({ size }) => (size ? `${size}px` : '100px')};
	height: ${({ size }) => (size ? `${size}px` : '100px')};
	overflow: hidden;
	border: 4px solid ${({ theme }) => theme.palette.colors.white['100']};
	box-shadow: 0 12px 16px -4px rgba(16, 24, 40, 0.08),
		0px 4px 6px -2px rgba(16, 24, 40, 0.03);
`

const Tag = styled(FlexCol)`
	position: absolute;
	bottom: 0;
	height: 25px;
	width: 100%;
	background-color: rgba(0, 0, 0, 0.7);
	align-items: center;
	justify-content: center;
	z-index: 10;

	:hover {
		cursor: pointer;
	}
`

const Container = styled(FlexCol)<Pick<R, 'size'>>`
	border-radius: 50%;
	flex: 1;
	background-size: cover;
	outline: none;
	align-items: center;
	justify-content: center;

	:hover {
		cursor: pointer;
	}
`

const Circle = styled(FlexCol)`
	z-index: 2;
	width: 100%;
	height: 100%;
	border-radius: 50%;
	overflow: hidden;
`
const plusIconDiameter = 30

const PlusIconContainer = styled(FlexCol)`
	position: absolute;
	justify-content: center;
	align-items: center;
	bottom: 0;
	right: 1px;
	width: ${plusIconDiameter}px;
	height: ${plusIconDiameter}px;
	border-radius: 50%;
	background: ${({ theme }) => theme.palette.colors.white['100']} no-repeat;
`

const StyledFlexCol = styled(FlexCol)`
	position: absolute;
	z-index: 1;
	width: 100%;
	height: 100%;
	align-items: center;
	justify-content: center;
`

const ShowTitle = styled(FlexCol)`
	justify-content: center;
	align-items: center;
`

const Text = styled('span')`
	color: ${({ theme }) => theme.palette.colors.red['400']};
	font-weight: 500;
	font-size: 12px;
`

interface R {
	size?: number
	children: React.ReactNode
	rest?: any
	icon?: any
	text?: string
	name: string
	image: string
}

const ImagePlaceholder = ({
	children,
	icon,
	size,
	name,
	text,
	image,
	...rest
}: R) => {
	const ShowIcon = icon
	const theme = useTheme()
	const { setFieldValue } = useFormikContext()

	return (
		<FlexCol>
			<Relative>
				<Wrapper size={size}>
					<Container size={size} {...rest}>
						<StyledFlexCol>
							{text && (
								<ShowTitle>
									<Typography
										variant={'h4'}
										fontWeight={600}
										color={
											theme.palette.colors.green['600']
										}
									>
										{getNameChar(text)}
									</Typography>
								</ShowTitle>
							)}
							{!text && (
								<ShowIcon
									style={{
										width: '45%',
										height: '45%',
										color: 'green'
									}}
								/>
							)}
						</StyledFlexCol>
						<Circle>{children}</Circle>
					</Container>
					{image && (
						<Tag onClick={() => setFieldValue(name, '')}>
							<Text>Remove</Text>
						</Tag>
					)}
				</Wrapper>
				{!image && (
					<PlusIconContainer>
						<HiPlusSm size={plusIconDiameter} color="#6CE9A6" />
					</PlusIconContainer>
				)}
			</Relative>
		</FlexCol>
	)
}

export { ImagePlaceholder }
