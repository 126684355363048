import React, { useMemo, type Dispatch, type SetStateAction } from 'react'
import { Typography } from '@mui/material'
import { Gutter } from 'components'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import { updateInvoiceSettings } from 'api/setting'
import { useSettings } from 'context/settings'
import type { InvoiceSettingsType } from 'typings'
import { useAppErrors } from 'hooks'
import { BusinessDetailButton } from './BusinessDetailButton'
import { useSelector } from 'react-redux'
import type { RootState } from 'store/index-reducer'
import { CnicEditModal } from './CnicEditModal'
import { StrnEditModal } from './StrnEditModal'

type BusinessSettingsProps = {
	invoiceSettings: InvoiceSettingsType
	setInvoiceSettings: Dispatch<
		SetStateAction<InvoiceSettingsType | undefined>
	>
}

export const BusinessSettings: React.ComponentType<BusinessSettingsProps> = ({
	invoiceSettings,
	setInvoiceSettings
}) => {
	const [cnicModal, setCnicModal] = React.useState(false)
	const [strnModal, setStrnModal] = React.useState(false)

	const { business } = useSettings()
	const { setAppError } = useAppErrors()
	const { businesses } = useSelector((state: RootState) => state.business)

	const selectedBusiness = useMemo(() => {
		return businesses.find(bus => bus.id === business)
	}, [business, businesses])

	const settings = invoiceSettings.settings

	const handleChange = async (state: boolean, property: string) => {
		try {
			const settingsObj = {
				settings: {
					...settings,
					[property]: state
				}
			}
			const updatedInvocieSettings = await updateInvoiceSettings(
				settingsObj,
				invoiceSettings.id
			)
			setInvoiceSettings(updatedInvocieSettings)
		} catch (err: any) {
			setAppError(err)
		}
	}

	return (
		<>
			<FormControlLabel
				control={
					<Switch
						checked={settings.displayBusinessCnic}
						color="primary"
						onChange={e => {
							if (!selectedBusiness?.cnic) {
								setCnicModal(true)
								return
							}
							handleChange(
								e.target.checked,
								'displayBusinessCnic'
							)
						}}
					/>
				}
				label="Display Business NTN/CNIC details on Invoice"
			/>
			{settings.displayBusinessCnic ? (
				<BusinessDetailButton
					title="NTN/CNIC"
					value={selectedBusiness?.cnic || ''}
					setModal={setCnicModal}
				/>
			) : (
				<Typography variant="subtitle2" fontWeight={'regular'}>
					Not displaying business NTN/CNIC details on the invoice.
				</Typography>
			)}

			<Gutter spacing={1} />

			<FormControlLabel
				control={
					<Switch
						checked={settings.displayBusinessStrn}
						color="primary"
						onChange={e => {
							if (!selectedBusiness?.strn) {
								setStrnModal(true)
								return
							}
							handleChange(
								e.target.checked,
								'displayBusinessStrn'
							)
						}}
					/>
				}
				label="Display Business STRN details on Invoice"
			/>
			{settings.displayBusinessStrn ? (
				<BusinessDetailButton
					title="STRN"
					value={selectedBusiness?.strn || ''}
					setModal={setStrnModal}
				/>
			) : (
				<Typography variant="subtitle2" fontWeight={'regular'}>
					Not displaying business STRN details on the invoice.
				</Typography>
			)}

			<CnicEditModal
				open={cnicModal}
				onClose={() => {
					setCnicModal(false)
				}}
				onSuccess={() =>
					settings.displayBusinessCnic
						? undefined
						: handleChange(true, 'displayBusinessCnic')
				}
				existingValue={selectedBusiness?.cnic || ''}
			/>
			<StrnEditModal
				open={strnModal}
				onClose={() => {
					setStrnModal(false)
				}}
				onSuccess={() =>
					settings.displayBusinessStrn
						? undefined
						: handleChange(true, 'displayBusinessStrn')
				}
				existingValue={selectedBusiness?.strn || ''}
			/>
		</>
	)
}
