import { useEffect, useState } from 'react'
import { LogInfo } from 'utils/log'

const initialState = {
	width: 0,
	height: 0,
	pixelRatio: 1
}

type Dimension = {
	width: number
	height: number
	pixelRatio: number
}

export const useDimension = () => {
	const [dimension, setDimension] = useState<Dimension>(initialState)

	useEffect(() => {
		window.addEventListener(
			'resize',
			() => {
				setDimension({
					width: window.innerWidth,
					height: window.innerHeight,
					pixelRatio: window.devicePixelRatio
				})
			},
			{ passive: true }
		)

		return () => {
			window.removeEventListener('resize', () => {
				LogInfo('Removed Listener')
			})
		}
	}, [])

	if (
		typeof window !== 'undefined' &&
		(dimension.width === 0 || dimension.height === 0)
	) {
		setDimension({
			width: window.innerWidth,
			height: window.innerHeight,
			pixelRatio: window.devicePixelRatio
		})
	}

	return dimension
}
