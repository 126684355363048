import type { IUserPostProps } from 'typings'

export const INVITE_USER_INITIAL_VALUE: IUserPostProps = {
	phone: {
		countryCode: '92',
		number: '',
		countryInitials: 'PK'
	},
	businessRole: 'admin',
	business: '',
	fullName: ''
}

export const INVITE_USER_BUSINESS_TYPE = (disabled: boolean) => {
	return [
		{
			value: 'co_owner',
			label: 'Admin',
			detail: 'They have access to all modules except business management settings. They can manage staff users.',
			disabled: disabled
		},
		{
			value: 'admin',
			label: 'Staff',
			detail: 'They have access to all modules except user and business management settings.',
			disabled: false
		}
	]
}
