import React from 'react'
import { styled } from '@mui/system'
import { FlexCol, FlexRow } from 'components/atoms/Flex'
import type { BusinessRelations, IContactProps } from 'typings'
import {
	Button,
	FieldPhoneNumberInput,
	FieldTextInput,
	Gutter,
	StyledModal
} from 'components'
import type { ModalProps } from '@mui/material'
import { Typography, useTheme } from '@mui/material'
import { Formik } from 'formik'
import { CONTACTS_INITIAL_VALUE, SEGMENT_EVENTS } from 'data'
import * as Yup from 'yup'
import { validatePhoneNo } from 'helpers/validatePhoneNo'
import { CNIC_FORMAT, objectRemoveEmpty, STRINGS, truncate } from 'helpers'
import { TransparentButton } from 'components/atoms/Button/TransparentButton'
import { useAppErrors, useTranslate } from 'hooks'
import { IoIosClose } from 'react-icons/io'
import { createContact } from 'api/contacts'
import analytics from 'utils/segment'
import { useSettings } from 'context/settings'
import { useSnackbar } from 'notistack'

const Wrapper = styled(FlexCol)`
	border-radius: 12px;
	background-color: ${({ theme }) => theme.palette.background.default};
	padding: 24px;
	min-width: 400px;
	max-width: 600px;
`

const Container = styled(FlexCol)`
	background-color: ${({ theme }) => theme.palette.background.paper};
	width: 100%;
	padding: 16px;
	border-radius: 8px;
`

const Row = styled('div')`
	display: grid;
	width: 100%;
	grid-template-columns: 1fr 1fr;
	column-gap: 10px;
`

const Grid = styled('div')`
	display: grid;
	grid-template-columns: 0.5fr 1fr;
	column-gap: 20px;
	align-items: center;
	width: 100%;
`

const Txt = styled(Typography)`
	color: ${({ theme }) => theme.palette.colors.blue['600']};

	:hover {
		cursor: pointer;
	}
`

export const validationSchema = Yup.object().shape({
	phone: Yup.object().shape({
		number: Yup.string()
			.test('number', (num, values) => {
				const {
					parent: { number, countryCode, countryInitials }
				} = values

				if (!number) {
					return true
				}

				const res = validatePhoneNo(
					number,
					countryCode,
					countryInitials
				)

				if (!res.isValid) {
					return values.createError({
						path: 'phone.number',
						message: truncate(res.message, 47)
					})
				}
				return true
			})
			.min(4, 'Phone number should be 4 digits long.')
	}),
	name: Yup.string()
		.matches(STRINGS, 'Name cannot contain special characters')
		.max(100, 'Too Long!')
		.matches(STRINGS, 'Name can only contain alphabetical letters')
		.test(
			'len',
			'Name length should be between 1 and 100 characters',
			val => {
				// count only non-white space characters
				const len = (val || '').replace(/\s+/g, '').length
				return len >= 1 && len <= 100
			}
		),

	email: Yup.string().email(
		'Incorrect email address format, please try again'
	),
	address: Yup.string().max(
		80,
		'Address length should be less than 80 characters'
	),
	cnic: Yup.string()
		.matches(
			CNIC_FORMAT,
			'NTN/CNIC can only contain alphanumeric characters and hyphen'
		)
		.max(25, 'NTN/CNIC length should be less than 25 characters')
		.min(4, 'NTN/CNIC length should be greater than 4 characters')
})

type ContactT = IContactProps & { moreOpt?: boolean }

export type ContactCreateModalInvProps = {
	user?: ContactT

	onSuccess?: (res: ContactT) => void
} & Omit<ModalProps, 'children'>

export const ContactCreateModalInv: React.ComponentType<
	ContactCreateModalInvProps
> = ({ onClose, user, onSuccess, ...props }) => {
	const theme = useTheme()
	const translate = useTranslate()
	const { setAppError } = useAppErrors()
	const { business } = useSettings()
	const { enqueueSnackbar } = useSnackbar()

	const handleModalClose = (
		event: {},
		reason: 'backdropClick' | 'escapeKeyDown'
	) => {
		if (reason === 'backdropClick') {
			return
		}
		onClose?.(event, reason)
	}

	const getBusinessRelationShip = (values: ContactT) => {
		const businessRelationship: BusinessRelations[] =
			values.businessRelationship.indexOf('both' as BusinessRelations) !==
			-1
				? ['supplier', 'customer']
				: values.businessRelationship

		return businessRelationship
	}

	const onCreate = async (values: ContactT, actions: any) => {
		try {
			actions.setSubmitting(true)
			const businessRelationship = getBusinessRelationShip(values)

			const obj = objectRemoveEmpty(
				{
					...values,
					businessRelationship
				},
				['email']
			)

			const res = await createContact({
				...obj,
				business: business,
				balance: {
					...obj.balance,
					amount:
						obj.balance.to === 'toPay'
							? obj.balance.amount * -1
							: obj.balance.amount
				}
			})
			analytics.track(SEGMENT_EVENTS.CONTACT_ADD, {
				...res,
				screen: 'contact_book'
			})
			enqueueSnackbar(translate('snackbar.successCreate'), {
				variant: 'success'
			})
			onSuccess?.(res)
			onClose?.({}, 'escapeKeyDown')
		} catch (error: any) {
			setAppError(error, actions)
		} finally {
			actions.setSubmitting(false)
		}
	}

	const onSubmit = async (payload: ContactT, actions: any) => {
		try {
			actions.setSubmitting(true)
			onCreate(payload, actions)
		} catch (e: any) {
			setAppError(e, actions)
		} finally {
			actions.setSubmitting(false)
		}
	}

	return (
		<StyledModal
			sx={{
				display: 'flex',
				flexDirection: 'column'
			}}
			onClose={handleModalClose}
			{...props}
		>
			<Formik
				initialValues={user || CONTACTS_INITIAL_VALUE}
				validationSchema={validationSchema}
				onSubmit={onSubmit}
			>
				{({ values, handleSubmit, isSubmitting, setFieldValue }) => (
					<Wrapper>
						<Typography variant={'h6'} fontWeight={700}>
							Create New Contact
						</Typography>
						<Gutter />
						<FieldTextInput
							title={'Customer Name*'}
							name={'name'}
							size={'small'}
							placeholder={translate(
								'contacts.form.placeholder.name'
							)}
							style={{ width: '100%' }}
							wrapperStyle={{ width: '100%' }}
						/>
						<Gutter spacing={0.2} />
						<Container>
							<Grid>
								<Typography
									variant={'subtitle2'}
									fontWeight={600}
								>
									Phone
								</Typography>

								<FieldPhoneNumberInput
									name={'phone.number'}
									dialCode={'phone.countryCode'}
									countryInitials={'phone.countryInitials'}
								/>
							</Grid>
							<Gutter spacing={0.5} />
							<FlexRow
								justify={'space-between'}
								style={{ width: '100%' }}
							>
								{!values.moreOpt && (
									<Txt
										variant={'subtitle2'}
										onClick={() =>
											setFieldValue('moreOpt', true)
										}
									>
										Add More Info
									</Txt>
								)}
								{values.moreOpt && (
									<Typography
										variant={'subtitle2'}
										color={theme.palette.colors.gray['600']}
									>
										More Info
									</Typography>
								)}
								{values.moreOpt && (
									<IoIosClose
										size={25}
										onClick={() =>
											setFieldValue('moreOpt', false)
										}
									/>
								)}
							</FlexRow>
							{values.moreOpt && (
								<FlexCol style={{ width: '100%' }}>
									<Gutter spacing={0.5} />
									<Grid>
										<Typography
											variant={'subtitle2'}
											fontWeight={600}
										>
											Email
										</Typography>

										<FieldTextInput
											name={'email'}
											size={'small'}
											style={{ width: '100%' }}
										/>
									</Grid>
									<Gutter spacing={0.5} />
									<Grid>
										<Typography
											variant={'subtitle2'}
											fontWeight={600}
										>
											Location
										</Typography>

										<FieldTextInput
											name={'address'}
											size={'small'}
											style={{ width: '100%' }}
										/>
									</Grid>
									<Gutter spacing={0.5} />
									<Grid>
										<Typography
											variant={'subtitle2'}
											fontWeight={600}
										>
											NTN
										</Typography>

										<FieldTextInput
											name={'cnic'}
											size={'small'}
											style={{ width: '100%' }}
										/>
									</Grid>
									<Gutter spacing={0.5} />
								</FlexCol>
							)}
						</Container>
						<Gutter spacing={1.5} />
						<Row>
							<TransparentButton
								onClick={() =>
									handleModalClose({}, 'escapeKeyDown')
								}
								style={{ width: '100%' }}
							>
								Cancel
							</TransparentButton>
							<Button
								style={{ height: 38, width: '100%' }}
								onClick={() => handleSubmit()}
								disabled={isSubmitting}
							>
								Submit
							</Button>
						</Row>
					</Wrapper>
				)}
			</Formik>
		</StyledModal>
	)
}
