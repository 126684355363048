import React from 'react'
import { Capsule, FormatAmount } from 'components'
import { FlexCol, FlexRow } from 'components/atoms/Flex'
import { StyledTableCell } from 'screen/Quotations/Tables/ReadyToShareTable'
import type { QuotationType } from 'typings'
import { dateFormat, QUOTATION_STATUS_OPTIONS, totalAmount } from 'data'
import { styled, TableRow } from '@mui/material'
import moment from 'moment'
import { formatId } from 'helpers'

const StyledTableRow = styled(TableRow)`
	justify-content: center;
	padding: 10px 0;

	:hover {
		cursor: pointer;
		background-color: ${({ theme }) => theme.palette.colors.blue['100']};
	}
`

const StyledCapsule = styled(Capsule)`
	width: min-content;
	padding: 5px 10px;
	font-size: 12px;
	font-weight: 500;
	margin: 0;
`

const Row = styled(FlexRow)`
	color: ${({ theme }) => theme.palette.colors.gray['500']};
	font-size: 12px;
`

type QuotationItemProps = {
	row: QuotationType
	onSelect?: (quotation: QuotationType) => void
}

export const QuotationModalItem: React.ComponentType<QuotationItemProps> = ({
	row,
	onSelect
}) => {
	return (
		<StyledTableRow onClick={() => onSelect?.(row as QuotationType)}>
			<StyledTableCell>
				<FlexCol>
					<FlexRow align={'center'}>{row.contact.name}</FlexRow>
					<Row align={'center'}>
						{row.contact.businessRelationship
							.toString()
							.replace(',', ', ')}
					</Row>
				</FlexCol>
			</StyledTableCell>
			<StyledTableCell align="left">
				{formatId(row?.friendlyId || 1)}
			</StyledTableCell>

			<StyledTableCell align="left">
				<FormatAmount
					variant={'caption'}
					limit={10}
					amount={totalAmount(row)}
					style={{ color: 'black' }}
				/>
			</StyledTableCell>
			<StyledTableCell align="left">
				<StyledCapsule
					name={
						QUOTATION_STATUS_OPTIONS.find(
							d => row.status === d.value
						)?.label || 'Private'
					}
					value={row.status}
					color={
						QUOTATION_STATUS_OPTIONS.find(
							d => row.status === d.value
						)?.color || 'blue'
					}
					disabled
					isSelected
				/>
			</StyledTableCell>
			<StyledTableCell align="left">
				{moment(row.createdAt).format(dateFormat)}
			</StyledTableCell>
		</StyledTableRow>
	)
}
