import React, { useCallback, useEffect, useState } from 'react'
import { CheckBox, Gutter } from 'components'
import { FlexCol, FlexRow } from 'components/atoms/Flex'
import { styled } from '@mui/system'
import { ButtonBase, Typography, useTheme } from '@mui/material'
import { useDimension } from 'hooks'
import { useSettings } from 'context/settings'
import { deleteOrder, getOrderCenterList, updateOrder } from 'api/orderCenter'
import { useAppErrors } from 'hooks/useAppErrors'
import type {
	DataResponseType,
	IOrderCenterAttrs,
	IOrderCenterProps,
	ResponsePageInfo
} from 'typings'
import { UserItem } from 'screen/OrderCenter/UserItem'
import { OrderCenterDetails } from 'screen/OrderCenter/Details'
import { EmptyScreen } from 'screen/OrderCenter/Components/EmptyScreen'
import { useBottomScrollListener } from 'react-bottom-scroll-listener'
import NoOrders from 'assets/orders/NoArchivedOrders.png'
import { addOrRemoveArr, objectOmit, replaceItemInArray } from 'helpers'
import { LAYOUT_PADDING, SCROLLBAR_STYLE } from 'data'
import { Header } from './Header'
import { Close } from '@mui/icons-material'

const Wrapper = styled('div')`
	display: grid;
	grid-template-columns: minmax(400px, 450px) 2fr;
	width: 100%;
	background-color: ${({ theme }) => theme.palette.background.default};
`

const Col = styled(FlexCol)`
	flex: 1;
	width: 100%;
	height: 100%;
`

const UserList = styled('div')<{ height?: number | null }>`
	height: ${({ height }) => `${height}px` || '100%'};
	width: 100%;
	min-height: ${({ height }) => `${height}px` || '100%'};
	overflow-x: hidden;
	display: block;
	padding-top: 16px;

	${SCROLLBAR_STYLE}
`
const Image = styled('img')`
	width: 150px;
`

const StyledRow = styled(FlexRow)`
	width: 100%;
	justify-content: space-between;
	padding: 0 16px;
	align-items: center;
`

export const RecyclebinOrderCenter = () => {
	const [isSelectVisible, setIsSelectVisible] = useState(false)
	const [loading, setLoading] = useState<boolean>(true)
	const [selectedItem, setSelectedItem] = useState<
		IOrderCenterProps | undefined
	>(undefined)
	const [dataSource, setDataSource] = useState<IOrderCenterProps[]>([])
	const [pageInfo, setPageInfo] = useState<Partial<ResponsePageInfo>>({
		totalPages: 1
	})
	const [isChecked, setIsChecked] = useState(false)
	const [selectedItems, setSelectedItems] = useState<string[]>([])
	const [page, setPage] = useState(1)
	const [searchKey, setSearchKey] = useState('')

	const { height } = useDimension()
	const { business } = useSettings()
	const { setAppError } = useAppErrors()
	const scrollRef = useBottomScrollListener(() => getOrderList(), {
		offset: 10,
		debounce: 200
	})

	const theme = useTheme()
	const {
		palette: { colors }
	} = theme

	const onSearch = async (searchText: string) => {
		setDataSource([])
		setSearchKey(searchText)
		setPage(1)
		getInitialOrderList(searchText)
	}

	// get initial value, onsearch or on screen load
	const getInitialOrderList = useCallback(
		async (searchText = '') => {
			try {
				setLoading(true)
				const res: DataResponseType<IOrderCenterProps> =
					await getOrderCenterList({
						business,
						deleted: true,
						searchKey: searchText,
						page: 1
					})
				setDataSource(res.edges)
				setPageInfo(res.pageInfo)
			} catch (e: any) {
				setAppError(e)
			} finally {
				setLoading(false)
			}
		},
		[business, setAppError]
	)

	const getOrderList = async () => {
		try {
			setLoading(true)
			if (
				!loading &&
				pageInfo?.totalPages &&
				pageInfo?.totalPages >= page + 1
			) {
				const res: DataResponseType<IOrderCenterProps> =
					await getOrderCenterList({
						business,
						searchKey,
						deleted: true,
						page: page + 1
					})
				setPage(page + 1)
				setDataSource([...dataSource, ...res.edges])
				setPageInfo(res.pageInfo)
			}
		} catch (e: any) {
			setAppError(e)
		} finally {
			setLoading(false)
		}
	}

	const onDelete = useCallback(
		async (orderId: string[]) => {
			try {
				await deleteOrder({ orderId: orderId })
				setSelectedItem(undefined)
				getInitialOrderList()
			} catch (e: any) {
				setAppError(e)
			}
		},
		[getInitialOrderList, setAppError]
	)

	const onCheckBoxClick = (
		event: React.ChangeEvent<HTMLInputElement>,
		checked: boolean
	) => {
		if (checked) {
			const arr: string[] = dataSource.map(m => {
				return m.id || ''
			})
			setSelectedItems(arr)
		} else {
			setSelectedItems([])
		}

		setIsChecked(event.target.checked)
	}

	const onSelectClick = () => {
		setIsSelectVisible(!isSelectVisible)
		if (isSelectVisible) {
			setSelectedItems([])
		}
	}

	const onRead = useCallback(
		async ({ item }: { item: IOrderCenterAttrs }) => {
			try {
				const res = await updateOrder(item?.id || '', {
					...objectOmit(item, 'type', 'friendlyId'),
					read: {
						status: true
					}
				})

				// do something here to update the read status

				const updatedArr = replaceItemInArray(
					dataSource,
					res,
					res.id as string
				)

				setDataSource(updatedArr as IOrderCenterProps[])
			} catch (e: any) {
				//
			}
		},
		[dataSource]
	)

	const RenderRow = useCallback(
		({ item }: { item: IOrderCenterAttrs }) => {
			return (
				<UserItem
					isSelected={selectedItem?.id == item.id}
					selectedItem={isSelectVisible ? selectedItems : undefined}
					onDelete={onDelete}
					item={item}
					onClick={order => setSelectedItem(order)}
					onItemClick={id => {
						if (item.read?.status === false) {
							onRead({ item })
						}
						setSelectedItems(addOrRemoveArr([...selectedItems], id))
					}}
					page={'recyclebin'}
				/>
			)
		},
		[isSelectVisible, onDelete, selectedItem?.id, selectedItems, onRead]
	)

	const onRestore = () => {
		onDelete(selectedItems)
		setIsSelectVisible(false)
		setSelectedItems([])
	}

	useEffect(() => {
		getInitialOrderList()
	}, [getInitialOrderList])

	return (
		<>
			<Header
				numOfOrders={dataSource.length}
				onSearch={onSearch}
				onSelectClick={onSelectClick}
				isSelectVisible={isSelectVisible}
				onRestore={selectedItems.length > 0 ? onRestore : undefined}
			/>
			<Wrapper>
				<Col>
					<UserList ref={scrollRef as any} height={height - 111}>
						{isSelectVisible && (
							<StyledRow>
								<FlexRow align="center">
									<CheckBox
										checked={isChecked}
										onChange={onCheckBoxClick}
									/>
									<Gutter gap={0.5} />
									<Typography
										variant="subtitle2"
										fontWeight={'regular'}
									>
										Select All
									</Typography>
								</FlexRow>

								<ButtonBase onClick={onSelectClick}>
									<Close sx={{ color: colors.gray['700'] }} />
								</ButtonBase>
							</StyledRow>
						)}
						{dataSource.length < 1 && (
							<FlexCol
								style={{ height: '100%' }}
								align="center"
								justify="center"
							>
								<Image src={NoOrders} />
								<Typography variant="caption">
									No Deleted orders or inquiries
								</Typography>
							</FlexCol>
						)}
						{dataSource.map(d => (
							<RenderRow key={d.id} item={d} />
						))}
					</UserList>
				</Col>
				<Col>
					{!selectedItem && <EmptyScreen />}
					{selectedItem && (
						<OrderCenterDetails
							item={selectedItem}
							maxHeight={height - 400}
							page={'recyclebin'}
						/>
					)}
				</Col>
			</Wrapper>
		</>
	)
}
