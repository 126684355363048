import React, { useCallback, useEffect, useState } from 'react'
import { CheckBox, Gutter } from 'components'
import { FlexCol, FlexRow } from 'components/atoms/Flex'
import { styled } from '@mui/system'
import { ButtonBase, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useDimension } from 'hooks'
import { useSettings } from 'context/settings'
import { deleteOrder, getOrderCenterList, updateOrder } from 'api/orderCenter'
import { useAppErrors } from 'hooks/useAppErrors'
import type {
	CreateOrderType,
	DataResponseType,
	IOrderCenterAttrs,
	IOrderCenterProps,
	ResponsePageInfo
} from 'typings'
import { UserItem } from 'screen/OrderCenter/UserItem'
import { OrderCenterDetails } from 'screen/OrderCenter/Details'
import { EmptyScreen } from 'screen/OrderCenter/Components'
import { useBottomScrollListener } from 'react-bottom-scroll-listener'
import { NewOrderModal } from 'screen/OrderCenter/NewOrder/NewOrderModal'
import { NoOrder } from 'screen/OrderCenter/Components/NoOrder'
import { addOrRemoveArr, objectOmit, replaceItemInArray } from 'helpers'
import NoOrders from 'assets/orders/NoArchivedOrders.png'
import analytics from 'utils/segment'
import {
	GENERATE_ORDER_DATA,
	LAYOUT_BREAKPOINT_WIDTH,
	LAYOUT_PADDING,
	SCROLLBAR_STYLE,
	SEGMENT_EVENTS
} from 'data'
import { Header } from './Header'
import { Close } from '@mui/icons-material'

const Wrapper = styled('div')`
	display: grid;
	grid-template-columns: minmax(400px, 450px) 2fr;
	width: 100%;
	background-color: ${({ theme }) => theme.palette.background.default};
`

const Col = styled(FlexCol)`
	flex: 1;
	width: 100%;
	height: 100%;
`

const Image = styled('img')`
	width: 150px;
`

const UserList = styled('div')<{ height?: number | null }>`
	height: ${({ height }) => `${height}px` || '100%'};
	width: 100%;
	// overflow-y: scroll;
	overflow-x: hidden;
	display: block;
	padding-top: 16px;

	${SCROLLBAR_STYLE}
`

const StyledRow = styled(FlexRow)`
	width: 100%;
	justify-content: space-between;
	padding: 0 16px;
	align-items: center;
`

export const OrderCenter = () => {
	const [editInitialOrder, setEditInitialOrder] = useState<
		CreateOrderType | undefined
	>(undefined)
	const [isAddModalOpen, setIsAddModalOpen] = useState(false)
	const [noData, setNoData] = useState(true)
	const [loading, setLoading] = useState<boolean>(true)
	const [selectedItem, setSelectedItem] = useState<
		IOrderCenterProps | undefined
	>(undefined)
	const [isChecked, setIsChecked] = useState(false)
	const [isSelectVisible, setIsSelectVisible] = useState(false)
	const [selectedItems, setSelectedItems] = useState<string[]>([])
	const [dataSource, setDataSource] = useState<IOrderCenterProps[]>([])
	const [recyclebinData, setRecyclebinData] = useState<IOrderCenterProps[]>(
		[]
	)
	const [pageInfo, setPageInfo] = useState<ResponsePageInfo>({
		totalPages: 1,
		currentPage: 1,
		edgesPerPage: 20
	})
	const [searchKey, setSearchKey] = useState('')
	const { height } = useDimension()
	const { business } = useSettings()
	const { setAppError } = useAppErrors()
	const scrollRef = useBottomScrollListener(() => getOrderList(), {
		offset: 10,
		debounce: 200
	})
	const isMobile = useMediaQuery(`(max-width:${LAYOUT_BREAKPOINT_WIDTH}px)`)
	const theme = useTheme()
	const {
		palette: { colors }
	} = theme

	const onSelectClick = () => {
		setIsSelectVisible(!isSelectVisible)
		if (isSelectVisible) {
			setSelectedItems([])
		}
		if (!isSelectVisible) {
			analytics.track(SEGMENT_EVENTS.ORDER_CENTER_KEBAB_MENU_OPTION, {
				option_selected: 'select'
			})
		}
	}

	const handleRecordNewOrder = () => {
		setIsAddModalOpen(true)
		analytics.track(SEGMENT_EVENTS.NEW_ORDER_INQUIRY)
	}

	//
	const onSearch = async (searchText: string) => {
		setDataSource([])
		setSearchKey(searchText)
		setSelectedItem(undefined)
		getInitialOrderList(searchText)
	}

	const getDeletedOrders = useCallback(
		async (searchText = '') => {
			try {
				const res: DataResponseType<IOrderCenterProps> =
					await getOrderCenterList({
						business,
						deleted: true,
						searchKey: searchText,
						page: 1
					})
				setRecyclebinData(res.edges)
			} catch (e: any) {
				setAppError(e)
			} finally {
				setLoading(false)
			}
		},
		[business, setAppError]
	)

	// get initial value, onsearch or on screen load
	const getInitialOrderList = useCallback(
		async (searchText = '') => {
			try {
				setLoading(true)
				const res: DataResponseType<IOrderCenterProps> =
					await getOrderCenterList({
						business,
						searchKey: searchText,
						page: 1
					})

				if (res.edges.length === 0) {
					getDeletedOrders()
				}

				setDataSource(res.edges)
				setPageInfo(res.pageInfo)
			} catch (e: any) {
				setAppError(e)
			} finally {
				setLoading(false)
				setNoData(false)
			}
		},
		[business, getDeletedOrders, setAppError]
	)

	const getOrderList = async () => {
		try {
			setLoading(true)
			if (
				!loading &&
				pageInfo?.totalPages &&
				pageInfo?.totalPages >= pageInfo.currentPage + 1
			) {
				const res: DataResponseType<IOrderCenterProps> =
					await getOrderCenterList({
						business,
						searchKey,
						page: pageInfo.currentPage + 1
					})
				setDataSource([...dataSource, ...res.edges])
				setPageInfo(res.pageInfo)
			}
		} catch (e: any) {
			setAppError(e)
		} finally {
			setLoading(false)
		}
	}

	const onDelete = useCallback(
		async (orderId: string[]) => {
			try {
				await deleteOrder({ orderId: orderId })
				if (orderId.length < 2)
					analytics.track(SEGMENT_EVENTS.DELETE_ORDER)
				setSelectedItem(undefined)
				getInitialOrderList()
			} catch (e: any) {
				setAppError(e)
			}
		},
		[getInitialOrderList, setAppError]
	)

	const onRead = useCallback(
		async ({ item }: { item: IOrderCenterAttrs }) => {
			try {
				const res = await updateOrder(item?.id || '', {
					...objectOmit(item, 'type', 'friendlyId'),
					read: {
						status: true
					}
				})

				// do something here to update the read status

				const updatedArr = replaceItemInArray(
					dataSource,
					res,
					res.id as string
				)

				setDataSource(updatedArr as IOrderCenterProps[])
			} catch (e: any) {
				//
			}
		},
		[dataSource]
	)

	const RenderRow = useCallback(
		({ item }: { item: IOrderCenterAttrs }) => {
			return (
				<UserItem
					isSelected={selectedItem?.id == item.id}
					selectedItem={isSelectVisible ? selectedItems : undefined}
					onDelete={onDelete}
					item={item}
					onClick={order => setSelectedItem(order)}
					page={'default'}
					onItemClick={id => {
						if (item.read?.status === false) {
							onRead({ item })
						}
						setSelectedItems(addOrRemoveArr([...selectedItems], id))
					}}
					onEditClick={value => {
						analytics.track(SEGMENT_EVENTS.ORDER_KEBAB_MENU, {
							option_selected: 'edit'
						})
						setEditInitialOrder(GENERATE_ORDER_DATA(value as any))
						setIsAddModalOpen(true)
					}}
				/>
			)
		},
		[isSelectVisible, onDelete, onRead, selectedItem?.id, selectedItems]
	)

	const onCheckBoxClick = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.checked) {
			const arr: string[] = dataSource.map(m => {
				return m.id || ''
			})
			setSelectedItems(arr)
		} else {
			setSelectedItems([])
		}

		setIsChecked(event.target.checked)
	}

	const onSuccess = () => {
		setIsAddModalOpen(false)
		setEditInitialOrder(undefined)
		setSelectedItem(undefined)
		getInitialOrderList()
	}

	const onDeletedClicked = () => {
		analytics.track(SEGMENT_EVENTS.ORDER_MULTIPLE_DELETE, {
			count: selectedItems.length
		})
		onDelete(selectedItems)
		setIsSelectVisible(false)
		setSelectedItems([])
	}

	useEffect(() => {
		getInitialOrderList()
	}, [getInitialOrderList])

	if (
		!loading &&
		recyclebinData.length === 0 &&
		!noData &&
		dataSource.length === 0 &&
		searchKey === ''
	) {
		return <NoOrder getInitialOrderList={getInitialOrderList} />
	}

	return (
		<>
			{!noData && (
				<>
					<Header
						numOfOrders={dataSource.length}
						recordOrder={handleRecordNewOrder}
						onSearch={onSearch}
						onSelectClick={onSelectClick}
						isSelectVisible={isSelectVisible}
						onDelete={
							selectedItems.length > 0
								? onDeletedClicked
								: undefined
						}
					/>
					<Wrapper>
						<Col>
							<UserList
								ref={scrollRef as any}
								height={height - 111}
							>
								{isSelectVisible && (
									<StyledRow>
										<FlexRow align="center">
											<CheckBox
												checked={isChecked}
												onChange={onCheckBoxClick}
											/>
											<Gutter gap={0.5} />
											<Typography
												variant="subtitle2"
												fontWeight={'regular'}
											>
												Select All
											</Typography>
										</FlexRow>

										<ButtonBase onClick={onSelectClick}>
											<Close
												sx={{
													color: colors.gray['700']
												}}
											/>
										</ButtonBase>
									</StyledRow>
								)}
								{dataSource.length < 1 && (
									<FlexCol
										style={{ height: '100%' }}
										align="center"
										justify="center"
									>
										<Image src={NoOrders} />
										<Typography variant="caption">
											No Orders or Inquiries
										</Typography>
									</FlexCol>
								)}
								{dataSource.map(d => (
									<RenderRow key={d.id} item={d} />
								))}
							</UserList>
						</Col>
						<Col>
							{!selectedItem && <EmptyScreen />}
							{selectedItem && (
								<OrderCenterDetails
									item={selectedItem}
									maxHeight={height - 420}
								/>
							)}
						</Col>
					</Wrapper>
				</>
			)}
			<NewOrderModal
				initialValue={editInitialOrder}
				open={isAddModalOpen}
				onClose={() => {
					setIsAddModalOpen(false)
					setEditInitialOrder(undefined)
				}}
				onSuccess={onSuccess}
			/>
		</>
	)
}
