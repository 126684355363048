import React from 'react'
import { FlexRow } from 'components/atoms/Flex'
import { Abbr, Avatar, Capsule, Gutter } from 'components'
import { getNameChar, truncate } from 'helpers'
import {
	TableCell,
	tableCellClasses,
	TableRow,
	Typography
} from '@mui/material'
import { AiOutlineEye } from 'react-icons/ai'
import { styled } from '@mui/system'
import { FiUser } from 'react-icons/fi'
import type { IBookerProps } from 'typings/orderbooker'
import { CreateOrderBookerModal } from 'screen/OrderBooker/AddOrderBooker'
import { IoMdLink } from 'react-icons/io'
import { enqueueSnackbar } from 'notistack'
import { TransparentButton } from 'components/atoms/Button/TransparentButton'

const CircleWrapper = styled(FlexRow)`
	border-radius: 50%;
	background-color: ${({ theme }) => theme.palette.colors.green['50']};
	padding: 10px;
`

const StyledTableCell = styled(TableCell)(({ theme }) => ({
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
	padding: 10,
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: theme.palette.colors.gray['50'],
		fontSize: 17,
		color:
			theme.palette.mode === 'dark'
				? theme.palette.colors['gray']['400']
				: theme.palette.colors['gray']['500']
	},
	[`&.${tableCellClasses.body}`]: {
		fontSize: 14
	}
}))

const WrapperCapsule = styled(Capsule)`
	width: auto;
	padding: 7px;
	border: 1px solid ${({ theme }) => theme.palette.colors.gray['300']};
	border-radius: 9px;
`

const StyledTableRow = styled(TableRow)(() => ({
	// hide last border
	'&:last-child td, &:last-child th': {
		border: 0
	}
}))

const MUIRow = styled(StyledTableRow, {
	shouldForwardProp: props => props !== 'isEditable' && props !== 'cursor'
})<{ isEditable: boolean; cursor?: 'pointer' | 'auto' }>`
	:hover {
		cursor: ${({ cursor }) => cursor || 'auto'};
	}
`

type Props<I> = {
	row: I
	isEditable?: boolean
	onUpdate?(): void
}

export const OrderBookerRow: React.ComponentType<Props<IBookerProps>> = ({
	row,
	isEditable,
	onUpdate
}) => {
	const [open, setOpen] = React.useState(false)

	const onCopy = async () => {
		await navigator.clipboard.writeText(
			`${window.location.href}-form/${row.id}`
		)
		enqueueSnackbar(
			`Order Booker Form link copied for ${truncate(row.name, 30)}`,
			{
				variant: 'success'
			}
		)
	}

	return (
		<MUIRow isEditable={!isEditable}>
			<StyledTableCell component="th" scope="row">
				<FlexRow align="center">
					{row?.profilePic ? (
						<Avatar
							borderWidth={1}
							title={getNameChar(row.name || '')}
							src={row?.profilePic}
						/>
					) : (
						<CircleWrapper>
							<FiUser color={'green'} size="23px" />
						</CircleWrapper>
					)}
					<Gutter gap={0.5} />
					<Typography>
						<Abbr length={25} title={row.name} />
					</Typography>
				</FlexRow>
			</StyledTableCell>
			<StyledTableCell>
				+{row.phone.countryCode} {row.phone.number}
			</StyledTableCell>
			<StyledTableCell align="left">
				<TransparentButton onClick={onCopy}>
					<FlexRow align="center">
						<Typography variant="caption">
							Copy form link
						</Typography>
						<Gutter gap={0.7} />
						<IoMdLink size={15} />
					</FlexRow>
				</TransparentButton>
			</StyledTableCell>
			<StyledTableCell align="left">
				<FlexRow align={'center'} justify={'center'}>
					<WrapperCapsule
						name="View Details"
						value="View Details"
						color="green"
						isSelected
						renderLeft={<AiOutlineEye />}
						onItemClick={() => {
							setOpen(true)
						}}
					/>
				</FlexRow>
			</StyledTableCell>
			<CreateOrderBookerModal
				open={open}
				onClose={() => setOpen(false)}
				onSuccess={() => {
					onUpdate?.()
				}}
				bookerToEdit={row}
			/>
		</MUIRow>
	)
}
