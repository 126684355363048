export const notionData = {
	'995a9dfc-0827-40a4-87e6-dfc24b8bcc98': {
		value: {
			id: '995a9dfc-0827-40a4-87e6-dfc24b8bcc98',
			version: 123,
			type: 'page',
			properties: {
				title: [['']]
			},
			content: [],
			permissions: [
				{
					role: 'reader',
					type: 'public_permission',
					added_timestamp: 1693470758261,
					allow_duplicate: false
				}
			],
			created_time: 1693470577163,
			last_edited_time: 1693996922228,
			parent_id: '43d68acb-4e04-4931-bbb0-e0befcac1421',
			parent_table: 'block',
			alive: true,
			created_by_table: 'notion_user',
			created_by_id: 'ec90c365-aae5-4bce-a53b-102ad16b00cf',
			last_edited_by_table: 'notion_user',
			last_edited_by_id: '3e38fc6c-f282-41e8-b6ca-86c470480b61',
			space_id: '882cba86-d3bd-432f-9bd6-6c2e886165bd'
		},
		role: 'reader'
	}
}
