export const LAYOUT_PADDING = 16

export const LAYOUT_BREAKPOINT_WIDTH = 750
export const SECONDARY_BUTTON_BREAKPOINT_WIDTH = 950
export const PRIMARY_BUTTON_BREAKPOINT_WIDTH = 1100


export const KEYBOARD_KEY = {
	left: 'ArrowLeft',
	right: 'ArrowRight',
	backspace: 'Backspace'
} as const

export const SCROLLBAR_STYLE = `  ::-webkit-scrollbar {
    visibility: hidden;
    width: 6px;
    height:6px;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;

  :hover {
    ::-webkit-scrollbar {
      display: block;
      
    }

    ::-webkit-scrollbar-track {
      background-color: transparent;
    }

    ::-webkit-scrollbar-thumb {
      background-color: #888;
      border-radius: 8px;
    }
    scrollbar-width: thin;
    scrollbar-track-color: transparent;
    scrollbar-thumb-color: #b3b3b3;`
