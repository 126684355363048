import React, { type MouseEvent, useCallback, useState } from 'react'
import { styled } from '@mui/system'
import { useDropzone } from 'react-dropzone'
import { Modal } from '@mui/material'
import { useField } from 'formik'
import { ImageCrop } from 'components/molecules/ImageCrop'
import * as Axios from 'axios'
import { useAppErrors } from 'hooks'

const Image = styled('img')`
	width: 100%;
	height: 100%;
	object-fit: cover;
	border: none;
`

const StyledModal = styled(Modal)`
	display: flex;
	justify-content: center;
	align-items: center;
`

export type FieldImageDropZoneProps = {
	name: string
	description?: string
	cropperProps?: any
	placeholder: any
	onReceived?: Function
	icon: any
	text?: string
	api: Function
	payload?: any
	onSuccess?(res: any): void
	onClick?(e: MouseEvent<HTMLElement>): void
}

export const FieldImageDropZone: React.ComponentType<
	FieldImageDropZoneProps
> = ({
	name,
	cropperProps,
	description,
	placeholder,
	onReceived,
	icon,
	text,
	api,
	onSuccess,
	payload = {},
	onClick
}) => {
	const [, meta, helpers] = useField(name)
	const [droppedFile, setDroppedFile] = useState<any>(null)
	const [showCropper, setShowCropper] = useState(false)
	const { setAppError } = useAppErrors()

	const handleChange = useCallback(
		(result: any) => {
			onReceived && onReceived(result)
			helpers.setValue(result)
			// onsuccess result
			onSuccess?.(result)
		},
		[helpers, onReceived, onSuccess]
	)

	const onImageComplete = useCallback(
		async (image: File) => {
			setShowCropper(false)
			// once image cropper sends us back the image
			try {
				const fileExtension = image.name.split('.').pop()

				const data = await api(fileExtension, payload)

				const reader = new FileReader()
				reader.onload = async evt => {
					await Axios.default.put(
						data.signedRequest,
						evt.target?.result,
						{
							headers: {
								'Content-Type': image.type
							}
						}
					)

					handleChange(data.url)
				}
				reader.readAsArrayBuffer(image)
			} catch (error: any) {
				setAppError(error)
			}
		},
		[api, handleChange, payload, setAppError]
	)

	const onDrop = useCallback(async (acceptedFiles: any) => {
		if (acceptedFiles && acceptedFiles[0]) {
			// get the webkit URL of the blob
			const imageDataUrl = await readFile(acceptedFiles[0])

			// save in local state to be used later
			setDroppedFile(imageDataUrl)

			// open the cropper Modal
			setShowCropper(true)

			// now we wait for the user to finish the crop process
		}
	}, [])

	const { getRootProps, getInputProps } = useDropzone({
		onDrop,
		accept: {
			'image/png': ['.png'],
			'image/jpeg': ['.jpeg'],
			'image/jpg': ['.jpg']
		}
	})

	const onCropCancelled = () => {
		setDroppedFile(null)
		setShowCropper(false)
	}

	const Wrapper = placeholder

	return (
		<>
			<Wrapper
				id={'image'}
				icon={icon}
				text={text}
				name={name}
				image={meta.value}
				{...getRootProps()}
				onClick={(e: MouseEvent<HTMLElement>) => {
					onClick?.(e)
					getRootProps().onClick?.(e)
				}}
			>
				<input {...getInputProps()} />
				{meta.value && (
					<Image
						src={meta.value}
						alt={description || 'Image Description'}
					/>
				)}
			</Wrapper>
			<StyledModal
				open={showCropper}
				onClose={onCropCancelled}
				aria-labelledby="StyledModal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<>
					<ImageCrop
						{...cropperProps}
						image={droppedFile}
						onImageCropped={onImageComplete}
						onCropCancelled={onCropCancelled}
					/>
				</>
			</StyledModal>
		</>
	)
}

function readFile(file: File) {
	return new Promise(resolve => {
		const reader = new FileReader()
		reader.addEventListener('load', () => resolve(reader.result), false)
		reader.readAsDataURL(file)
	})
}
