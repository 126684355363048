import React from 'react'
import type { InventoryItemType } from 'typings'
import { Typography, useTheme } from '@mui/material'
import { FlexRow } from 'components/atoms/Flex'
import { Avatar, Gutter } from 'components'
import { getNameChar } from 'helpers'
import { Abbr } from 'components/atoms/Abbr'

export const ProductName = ({ row }: { row: InventoryItemType }) => {
	const theme = useTheme()

	return (
		<FlexRow align={'center'}>
			{row.icon ? (
				<img
					src={row.icon}
					alt={row.name}
					loading="lazy"
					height={30}
					width={30}
					style={{
						objectFit: 'cover'
					}}
				/>
			) : (
				<Avatar
					title={getNameChar(row.name)}
					borderWidth={0}
					sx={{
						backgroundColor: theme.palette.colors.green['50'],
						color: theme.palette.colors.green['900'],
						fontSize: 14,
						width: 30,
						height: 30
					}}
				/>
			)}
			<Gutter gap={1} />
			<Typography variant={'caption'} fontWeight={500}>
				<Abbr title={row.name} length={25} />
			</Typography>
		</FlexRow>
	)
}
