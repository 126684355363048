import React, { useCallback, useState } from 'react'
import {
	InputAdornment,
	MenuItem,
	Popover,
	styled,
	Typography
} from '@mui/material'
import { Button, Capsule, TextInput } from 'components'
import { FlexCol, FlexRow } from 'components/atoms/Flex'
import { fetchAllStock } from 'store/settings/actions'
import { useDispatch, useSelector } from 'react-redux'
import { createStockUnit } from 'api'
import { useAppErrors } from 'hooks/useAppErrors'
import { useFormikContext } from 'formik'
import type { RootState } from 'store/index-reducer'
import { useTranslate } from 'hooks'
import type { InventoryStockType, IStockUnits } from 'typings'
import { INVENTORY_STOCK, SCROLLBAR_STYLE } from 'data'
import { Abbr } from 'components/atoms/Abbr'

const StyledCapsule2 = styled(Capsule)`
	font-size: 1rem;
	border-radius: 0.5em;
`

const StyledCapsule = styled(Capsule)`
	font-size: 0.6rem;
	border-radius: 0.5em;
`

const AddUnitButton = styled(Button)`
	border: 1px solid ${({ theme }) => theme.palette.colors.gray['300']};
	width: 18ch;
	height: 4.5ch;
	border-radius: 0.5em;
	padding: 0;
`

const MenuWrapper = styled('div')`
	height: 250px;
	width: 100%;
	overflow: scroll;

	${SCROLLBAR_STYLE}
`

const AddInventoryWrapper = styled(FlexRow)`
	margin: 0 16px;
	padding: 7px 0;
`

const Wrapper = styled(FlexCol)`
	width: 100%;
	background-color: ${({ theme }) => theme.palette.background.default};
`

export const AddUnitMenu = ({ stock }: { stock: InventoryStockType[] }) => {
	const [value, setValue] = useState('')
	const translate = useTranslate()
	const { setFieldValue } = useFormikContext()
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

	const { dataSource } = useSelector(
		(state: RootState) => state.settings.stockUnit
	)
	const dispatch = useDispatch()
	const { setAppError } = useAppErrors()

	const onClick = async (
		payload: Pick<IStockUnits, 'name'> | { name: string }
	) => {
		try {
			await createStockUnit(payload)
			dispatch(fetchAllStock())
			setValue('')
		} catch (e: any) {
			setAppError(e)
		}
	}

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget)
	}

	const handleClose = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(null)
	}

	const onSelect = useCallback(
		(name: string, event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
			setFieldValue(
				'stock',
				stock.concat({ ...INVENTORY_STOCK, unit: name })
			)
			handleClose(event as any)
		},
		[setFieldValue, stock]
	)
	const open = Boolean(anchorEl)

	const RenderMenuItem = useCallback(
		({ name }: IStockUnits) => {
			const selectedStock = stock.filter(d => d.unit === name).length > 0
			return (
				<MenuItem
					onClick={event => onSelect(name, event)}
					disabled={selectedStock}
				>
					<FlexRow align="center" justify="space-around">
						<Typography variant="subtitle1" fontWeight={500}>
							<Abbr title={name} length={20} />
						</Typography>
						{selectedStock && (
							<StyledCapsule
								name="In Use"
								value="In Use"
								isSelected
								color="blue"
							/>
						)}
					</FlexRow>
				</MenuItem>
			)
		},
		[stock, onSelect]
	)

	return (
		<>
			<AddUnitButton
				textColor="black"
				buttonColor="white"
				size="small"
				onClick={handleClick}
			>
				{translate('inventory.units.addUnit')}
			</AddUnitButton>

			<Popover
				elevation={2}
				id="basic-menu"
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right'
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right'
				}}
			>
				<Wrapper>
					{dataSource.length > 0 && (
						<MenuWrapper>
							{dataSource.map(d => (
								<RenderMenuItem key={d.id} {...d} />
							))}
						</MenuWrapper>
					)}
					{dataSource.length === 0 && (
						<FlexCol
							justify={'center'}
							align={'center'}
							style={{
								alignSelf: 'center',
								height: 200
							}}
						>
							<Typography>Add a unit</Typography>
						</FlexCol>
					)}

					<AddInventoryWrapper>
						<TextInput
							onChange={e => setValue(e.target.value)}
							size="small"
							sx={{
								'& input::placeholder': {
									fontSize: '1.3ch'
								}
							}}
							value={value}
							placeholder="Create Unit"
							InputProps={{
								endAdornment: (
									<InputAdornment
										position="end"
										sx={{ marginRight: '-0.5em' }}
									>
										<StyledCapsule2
											isSelected
											name="+"
											value="+"
											color="green"
											disabled={value.length === 0}
											onItemClick={() =>
												onClick({ name: value })
											}
										/>
									</InputAdornment>
								)
							}}
						/>
					</AddInventoryWrapper>
				</Wrapper>
			</Popover>
		</>
	)
}
