import React, { useCallback, useEffect, useState } from 'react'
import { COUNTRIES_LIST, type CountryListProps } from 'data'
import { styled } from '@mui/system'
import { Typography } from '@mui/material'
import { Gutter } from 'components/atoms/Gutter'
import type { PopupState } from 'material-ui-popup-state/hooks'
import { FlexRow } from 'components/atoms/Flex'

const Col = styled('div')`
	width: 100%;
	height: 100%;
	max-height: 270px;
	overflow-y: scroll;
	overflow-x: hidden;
`

const MenuItem = styled(FlexRow, {
	shouldForwardProp: props => props !== 'isMenuActive'
})<{ isMenuActive?: boolean }>`
	justify-content: center;
	align-items: center;
	padding: 0 0 0 10px;
	width: 100%;
	max-height: 50px;
	height: 100%;
	font-weight: bold;
	margin-right: 10px;
`

const Img = styled('img')`
	width: 32px;
	height: 32px;
`

const DropDownItem = styled(MenuItem, {
	shouldForwardProp: props => props !== 'isSelected'
})<{ isSelected?: boolean }>`
	padding: 8px;
	margin: 0 0 2px 0;
	border-radius: 8px;
	justify-content: flex-start;
	color: ${({ theme }) => theme.palette.primary.contrastText};
	background-color: ${({ theme, isSelected }) =>
		isSelected ? theme.palette.colors.green['100'] : 'transparent'};

	:hover {
		cursor: pointer;
		background-color: ${({ theme }) => theme.palette.colors.green['200']};
	}
`

type CountryDropdownProps = {
	selectedCountry: CountryListProps
	popupState: PopupState
	onCountryChange(country: CountryListProps): void
	searchCountry?: string
}

export const CountryDropdown: React.ComponentType<CountryDropdownProps> = ({
	selectedCountry,
	popupState,
	onCountryChange,
	searchCountry = ''
}) => {
	const [dataSource, setDataSource] = useState<CountryListProps[]>([])

	useEffect(() => {
		setDataSource(
			COUNTRIES_LIST.filter(
				d =>
					d.dial_code
						.toLowerCase()
						.includes(searchCountry.toLowerCase()) ||
					d.name
						.toLowerCase()
						.toLowerCase()
						.includes(searchCountry.toLowerCase())
			).slice(0, 10)
		)
	}, [searchCountry])

	const handleScroll = (event: any) => {
		const { scrollTop, scrollHeight, clientHeight } = event.target

		if (
			scrollHeight - scrollTop - 100 <= clientHeight &&
			dataSource.length < COUNTRIES_LIST.length
		) {
			setDataSource(prev => [
				...prev,
				...COUNTRIES_LIST.slice(prev.length, COUNTRIES_LIST.length)
			])
		}
	}

	const Item = useCallback(
		({
			country,
			onClick
		}: {
			country: CountryListProps
			onClick(): void
		}) => {
			return (
				<DropDownItem
					isSelected={
						country.dial_code === selectedCountry?.dial_code
					}
					onClick={() => onClick()}
				>
					<Img src={country.image} alt={country.name} />
					<Gutter gap={1} />
					<Typography variant={'subtitle2'}>
						+{country.dial_code}
					</Typography>
					<Gutter gap={0.5} />
					<Typography
						variant={'subtitle2'}
						noWrap
						align={'left'}
						style={{ flex: 1 }}
					>
						{country.name}
					</Typography>
				</DropDownItem>
			)
		},
		[selectedCountry?.dial_code]
	)

	return (
		<Col onScroll={handleScroll}>
			{dataSource.map(country => (
				<Item
					key={country.name}
					country={country}
					onClick={() => {
						onCountryChange(country)
						popupState.close()
					}}
				/>
			))}
		</Col>
	)
}
