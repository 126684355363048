import React, { useCallback } from 'react'
import { useField } from 'formik'
import type { TextInputProps } from 'components'
import {
	ErrorText,
	PhoneNumber,
	type PhoneNumberProps,
	type TextInput
} from 'components'
import { FlexCol } from 'components/atoms/Flex'
import { useTranslate } from 'hooks'
import { type CountryListProps } from 'data'

export type FieldPhoneNumberInputProps = Omit<
	PhoneNumberProps,
	'onBlur' | 'onFocus' | 'selectedCode' | 'phoneNumber' | 'code'
> & {
	name: string
	dialCode: string
	countryInitials: string
	placeholder?: string
	nextInputRef?: React.MutableRefObject<typeof TextInput> | null
}

export const FieldPhoneNumberInput = React.forwardRef<
	TextInputProps,
	FieldPhoneNumberInputProps
>(({ name, dialCode, countryInitials, nextInputRef, ...props }, ref) => {
	const [, nameMeta, nameHelpers] = useField(name)
	const [, dialCodeMeta, dialCodeHelpers] = useField(dialCode)
	const [, countryInitialsMeta, countryInitialHelpers] =
		useField(countryInitials)
	const translate = useTranslate()

	const error = nameMeta.touched && nameMeta.error

	const handleBlur = useCallback(() => {
		nameHelpers.setTouched(true)
	}, [nameHelpers])

	const onChange = (
		value: string,
		formattedValue?: string,
		country?: CountryListProps
	) => {
		nameHelpers.setValue(value)
		dialCodeHelpers.setValue(country?.dial_code || '')
		countryInitialHelpers.setValue(country?.code || '')
	}

	return (
		<FlexCol align={'flex-start'}>
			<PhoneNumber
				type={'tel'}
				selectedCode={dialCodeMeta.value}
				phoneNumber={nameMeta.value}
				onChange={onChange}
				error={!!error}
				onBlur={handleBlur}
				code={countryInitialsMeta.value}
				placeholder={translate(
					'contacts.form.placeholder.mobileNumber'
				)}
				{...props}
			/>
			<ErrorText text={error} />
		</FlexCol>
	)
})

FieldPhoneNumberInput.displayName = 'FieldPhoneNumberInput'
