import React from 'react'
import { styled } from '@mui/material/styles'
import type { ButtonProps } from '@mui/material'
import { Button } from '@mui/material'

const TransparentBtn = styled(Button)`
	background-color: ${({ theme }) => theme.palette.background.default};
	border: 1px solid ${({ theme }) => theme.palette.colors.gray['300']};
	color: ${({ theme }) => theme.palette.colors.gray['700']};
	border-radius: 8px;
	min-width: 38px;
	min-height: 38px;

	:hover {
		background-color: ${({ theme }) => theme.palette.background.paper};
	}
`

export const TransparentButton: React.ComponentType<ButtonProps> = ({
	children,
	...props
}) => {
	return <TransparentBtn {...props}>{children}</TransparentBtn>
}
