import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { styled } from '@mui/system'
import { FlexCol } from 'components/atoms/Flex'
import { Gutter } from 'components'
import { useDimension, useLocalStorage } from 'hooks'
import {
	type ColumnVisibilityProps,
	INVENTORY_COLUMN_INITIAL_STATE,
	LAYOUT_PADDING,
	SCROLLBAR_STYLE,
	SEGMENT_EVENTS
} from 'data'
import { useSettings } from 'context/settings'
import { useAppErrors } from 'hooks/useAppErrors'
import { useBottomScrollListener } from 'react-bottom-scroll-listener'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import Paper from '@mui/material/Paper'
import { InventoryTable } from 'screen/Inventory/InventoryTable'
import { getInventoryList } from 'api'
import type {
	DataResponseType,
	InventoryItemType,
	ResponsePageInfo
} from 'typings'
import { type InventoryFilterObjProps } from 'screen/Inventory/InventoryFilters'
import { fetchAllLabel } from 'store/inventory/actions'
import { Typography } from '@mui/material'
import { AddInventoryModal } from 'screen/Inventory/AddStock'
import AddContact from 'assets/contacts/add_contact.png'
import NoContacts from 'assets/contacts/no_contact_found.png'
import analytics from 'utils/segment'
import { mergeArrayFromKey, pickKeysFromArray } from 'helpers'
import { Header } from './Header'

const Container = styled(FlexCol)<{ height: number }>`
	width: 100%;
	height: ${({ height }) => (height ? `${height}px` : '100%')};
	max-height: ${({ height }) => (height ? `${height}px` : '100%')};
	padding: ${LAYOUT_PADDING}px;
`

const StyledTableContainer = styled(TableContainer)<{ height: number }>`
	min-height: ${({ height }) => height}px;
	max-height: ${({ height }) => height}px;
	background-color: ${({ theme }) => theme.palette.background.default};
	${SCROLLBAR_STYLE}
`
const Image = styled('img')`
	min-width: 100px;
	max-width: 250px;
`
const Center = styled(FlexCol)`
	position: absolute;
	top: 50%;
	left: 42%;
	align-self: center;
	align-items: center;
	justify-content: center;
`

export type InventorySortType = { sortOrder: 'asc' | 'desc'; sortBy: string }
export type InventoryColProps = {
	[key: string]: { name: string; width?: number | string; widthPx?: number }
}

const Inventory = () => {
	const [inventoryVisibility, setInventoryVisibility] = useLocalStorage(
		'visibility',
		INVENTORY_COLUMN_INITIAL_STATE
	)
	const [isModalOpen, setIsModalOpen] = useState(false)

	const [columnVisibility, setColumnVisibility] = useState<
		ColumnVisibilityProps[]
	>(
		mergeArrayFromKey(
			INVENTORY_COLUMN_INITIAL_STATE,
			inventoryVisibility,
			'value'
		) as ColumnVisibilityProps[]
	)
	const [columnObj, setColumnObj] = useState<InventoryColProps>({})
	const [filterObj, setFilterObj] = useState<InventoryFilterObjProps>({})
	const [loading, setLoading] = useState<boolean>(true)
	const [dataSource, setDataSource] = useState<InventoryItemType[]>([])
	const [transformedData, setTransformedData] = useState<InventoryItemType[]>(
		[]
	)
	const [pageInfo, setPageInfo] = useState<ResponsePageInfo>({
		totalPages: 1,
		currentPage: 1,
		edgesPerPage: 20
	})
	const [searchKey, setSearchKey] = useState('')
	const [sortObj, setSortObj] = useState<InventorySortType>({
		sortOrder: 'asc',
		sortBy: 'name'
	})
	const { height } = useDimension()
	const { business } = useSettings()
	const dispatch = useDispatch()
	const { setAppError } = useAppErrors()
	const scrollRef = useBottomScrollListener(() => fetchInventory(), {
		offset: 10,
		debounce: 200
	})

	const onSearch = async (searchText: string) => {
		setDataSource([])
		setSearchKey(searchText)
	}

	const getTransformedData = (res: InventoryItemType[]) => {
		const arr: InventoryItemType[] = []
		res.map(m =>
			m.stock.map(d => {
				return arr.push(Object.assign({}, { ...m, stock: [d] }))
			})
		)

		return arr
	}

	const onVisibilityClick = (Column: ColumnVisibilityProps[]) => {
		setColumnVisibility(Column)
		setInventoryVisibility(
			pickKeysFromArray(Column, ['value', 'isSelected'])
		)
	}

	const getInitialInventoryList = useCallback(async () => {
		try {
			setLoading(true)
			const res: DataResponseType<InventoryItemType> =
				await getInventoryList({
					business,
					page: 1,
					limit: pageInfo.edgesPerPage,
					searchKey,
					...sortObj,
					sortOrder: sortObj.sortOrder.toUpperCase() as
						| 'ASC'
						| 'DESC',
					...filterObj
				})

			setDataSource(res.edges)
			setTransformedData(getTransformedData(res.edges))
			setPageInfo(res.pageInfo)
		} catch (e: any) {
			setAppError(e)
		} finally {
			setLoading(false)
		}
	}, [
		business,
		filterObj,
		pageInfo.edgesPerPage,
		searchKey,
		setAppError,
		sortObj
	])

	const fetchInventory = async () => {
		try {
			setLoading(true)
			if (
				!loading &&
				pageInfo?.totalPages &&
				pageInfo?.totalPages >= pageInfo.currentPage + 1
			) {
				const res: DataResponseType<InventoryItemType> =
					await getInventoryList({
						business,
						page: pageInfo.currentPage + 1,
						limit: pageInfo.edgesPerPage,
						searchKey,
						...sortObj,
						sortOrder: sortObj.sortOrder.toUpperCase() as
							| 'ASC'
							| 'DESC',
						...filterObj
					})

				setTransformedData([
					...transformedData,
					...getTransformedData(res.edges)
				])
				setDataSource([...dataSource, ...res.edges])
				setPageInfo(res.pageInfo)
			}
		} catch (e: any) {
			setAppError(e)
		} finally {
			setLoading(false)
		}
	}

	const onSort = useCallback(
		(name: string) => {
			const order =
				sortObj.sortBy === name
					? sortObj.sortOrder === 'asc'
						? 'desc'
						: 'asc'
					: 'asc'

			setSortObj(() => {
				return {
					sortOrder: order,
					sortBy: name
				}
			})
		},
		[sortObj.sortBy, sortObj.sortOrder]
	)

	const createNewClick = () => {
		setIsModalOpen(true)
		analytics.track(SEGMENT_EVENTS.INVENTORY_ADD_NEW_ITEM, {
			screen: 'inventory'
		})
	}

	useEffect(() => {
		dispatch(fetchAllLabel())
	}, [dispatch])

	useEffect(() => {
		getInitialInventoryList()
	}, [getInitialInventoryList])

	useEffect(() => {
		const obj: InventoryColProps = {}
		columnVisibility
			.filter(d => d.isSelected)
			.map(col => {
				obj[col.value] = {
					name: col.value,
					width: col.width,
					widthPx: col.widthPx
				}
			})

		setColumnObj(obj)
	}, [columnVisibility])

	return (
		<>
			<Header
				numOfItems={dataSource.length}
				onSearch={onSearch}
				createNewClick={createNewClick}
				filterObj={filterObj}
				setFilterObj={setFilterObj}
			/>

			<Container height={height - 110}>
				{/* Table work start from here */}
				<Paper sx={{ width: '100%', overflow: 'hidden', zIndex: 0 }}>
					{dataSource.length === 0 && !loading && (
						<Center>
							<Image
								src={searchKey === '' ? AddContact : NoContacts}
								width={250}
								height={150}
							/>
							<Gutter spacing={0.3} />
							<Typography variant={'body2'}>
								{searchKey === ''
									? 'Your Inventory is empty, add items to it to track your stock better'
									: `No items titled "${searchKey}" found`}
							</Typography>
						</Center>
					)}

					<StyledTableContainer
						ref={scrollRef as any}
						height={height}
					>
						<Table stickyHeader aria-label="sticky table">
							<InventoryTable
								columnObj={columnObj}
								columnVisibility={columnVisibility}
								onSort={onSort}
								sortObj={sortObj}
								searchKey={searchKey}
								onSuccess={() => getInitialInventoryList()}
								inventoryState={{
									dataSource: dataSource,
									loading: loading,
									transformedData: transformedData
								}}
								onVisibilityClick={onVisibilityClick}
							/>
						</Table>
					</StyledTableContainer>
				</Paper>
			</Container>
			<AddInventoryModal
				onSuccess={() => getInitialInventoryList()}
				open={isModalOpen}
				onClose={() => setIsModalOpen(false)}
			/>
		</>
	)
}

export default Inventory
