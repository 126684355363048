import { FlexCol, FlexRow } from 'components/atoms/Flex'
import { Button, Gutter } from 'components'
import { GiGreekTemple } from 'react-icons/gi'
import { RxClock } from 'react-icons/rx'
import { Typography, useTheme } from '@mui/material'
import type { ColorsCodeType, ColorsType } from 'typings'
import { IoIosClose } from 'react-icons/io'
import React, { useState } from 'react'
import { styled } from '@mui/system'
import { useLocation, useNavigate } from 'react-router-dom'
import { PRIMARY_BUTTON_BREAKPOINT_WIDTH } from 'data'
import { RiEditLine, RiErrorWarningLine } from 'react-icons/ri'
import { LOCAL_STORAGE_KEY } from 'data/localstorage'
import { useSelector } from 'react-redux'
import type { RootState } from 'store/index-reducer'

const FinanceContainer = styled(FlexRow)<{
	color: ColorsType
}>`
	width: 100%;
	flex-wrap: nowrap;
	align-items: center;
	justify-content: space-between;
	min-height: 60px;
	max-height: 60px;
	background: ${({ theme, color }) => theme.palette.colors[color]['50']};
`

const PrimaryButtonWrapper = styled(FlexRow)`
	/*@media (max-width: ${PRIMARY_BUTTON_BREAKPOINT_WIDTH}px) {
		display: none;
	}*/
`

const StyledPrimaryButton = styled(Button)`
	min-width: 0;
	min-height: 32px;
	padding: 0 10px;
	border-radius: 5px;
	color: ${({ theme }) => theme.palette.colors.white['900']};
`

type OFHeaderProps = {
	visiblity?: boolean
}

export const OFHeader: React.ComponentType<OFHeaderProps> = ({ visiblity }) => {
	const { kyb, user } = useSelector((state: RootState) => state.cbfs.business)
	const [state, setState] = useState<any>({})
	const theme = useTheme()
	const navigate = useNavigate()
	const location = useLocation()
	const isOF = location.pathname === '/ordr-financing'
	const reason = kyb?.statuses.filter((d: any) => d.status === 'rejected')
	const contractUrl = kyb?.facilities.filter(
		(d: any) => d.status === 'contract_pending'
	)[0]?.envelope_url
	const contractValid = kyb?.facilities.filter(
		(d: any) => d.status === 'contract_valid'
	)

	const {
		palette: { colors }
	} = theme

	const NotRegister = () => {
		const color: ColorsType = 'blue'
		return (
			<>
				<FinanceContainer color={color}>
					<FlexRow align={'center'}>
						<Gutter gap={1} />
						<GiGreekTemple
							fontSize={35}
							color={colors[color]['400']}
						/>
						<Gutter gap={0.5} />
						<FlexCol>
							<Typography variant={'body1'} fontWeight={500}>
								Register now to set up OrdrFinance.
							</Typography>
							<Typography variant={'caption'} fontWeight={400}>
								Please complete your profile to set up your
								account for financing.
							</Typography>
						</FlexCol>
					</FlexRow>
					<FlexRow align={'center'}>
						<StyledPrimaryButton
							buttonColor={
								colors['green']['600'] as ColorsCodeType
							}
							onClick={() => {
								navigate('/ordr-finance/register')
							}}
						>
							<PrimaryButtonWrapper>
								Register for Financing
							</PrimaryButtonWrapper>
						</StyledPrimaryButton>
						<Gutter gap={0.5} />
						{!isOF && (
							<IoIosClose
								size={25}
								id={'close-button'}
								onClick={() =>
									localStorage.setItem(
										LOCAL_STORAGE_KEY.notRegisterVisibility,
										JSON.stringify(false)
									)
								}
							/>
						)}
						<Gutter gap={1} />
					</FlexRow>
				</FinanceContainer>
			</>
		)
	}

	const Approved = () => {
		const color: ColorsType = 'blue'
		return (
			<FinanceContainer color={color}>
				<FlexRow align={'center'}>
					<Gutter gap={1} />
					<RiEditLine fontSize={35} color={colors.blue['400']} />
					<Gutter gap={0.5} />
					<FlexCol>
						<Typography variant={'body1'} fontWeight={500}>
							Your signatures are required on the financing
							contract.
						</Typography>
						<Typography variant={'caption'} fontWeight={400}>
							Please review and sign the contract to finalize the
							terms of future financing.
						</Typography>
					</FlexCol>
				</FlexRow>
				<FlexRow align={'center'}>
					<StyledPrimaryButton
						buttonColor={colors['green']['600'] as ColorsCodeType}
						onClick={() => {
							window.open(contractUrl, '_blank')
						}}
						disabled={!contractUrl}
					>
						<PrimaryButtonWrapper>
							{contractUrl
								? 'Review and sign contract'
								: 'Contract will available soon'}
						</PrimaryButtonWrapper>
					</StyledPrimaryButton>
					<Gutter gap={0.5} />
					{!isOF && (
						<IoIosClose
							size={25}
							id={'close-button'}
							onClick={() =>
								localStorage.setItem(
									LOCAL_STORAGE_KEY.approvedVisibility,
									JSON.stringify(false)
								)
							}
						/>
					)}
					<Gutter gap={1} />
				</FlexRow>
			</FinanceContainer>
		)
	}

	const ApplyForFinancing = () => {
		const color: ColorsType = 'blue'
		return (
			<FinanceContainer color={color}>
				<FlexRow align={'center'}>
					<Gutter gap={1} />
					<RiEditLine fontSize={35} color={colors.blue['400']} />
					<Gutter gap={0.5} />
					<FlexCol>
						<Typography variant={'body1'} fontWeight={500}>
							Need Proper integration
						</Typography>
						<Typography variant={'caption'} fontWeight={400}>
							Api pending
						</Typography>
					</FlexCol>
				</FlexRow>
				<FlexRow align={'center'}>
					<StyledPrimaryButton
						buttonColor={colors['green']['600'] as ColorsCodeType}
						onClick={() => {
							navigate('/ordr-financing/new-finance-application')
						}}
					>
						<PrimaryButtonWrapper>
							Apply for Financing
						</PrimaryButtonWrapper>
					</StyledPrimaryButton>
					<Gutter gap={0.5} />
					{!isOF && (
						<IoIosClose
							size={25}
							id={'close-button'}
							onClick={() =>
								localStorage.setItem(
									LOCAL_STORAGE_KEY.approvedVisibility,
									JSON.stringify(false)
								)
							}
						/>
					)}
					<Gutter gap={1} />
				</FlexRow>
			</FinanceContainer>
		)
	}

	const Pending = () => {
		const color: ColorsType = 'yellow'
		return (
			<FinanceContainer color={color}>
				<FlexRow align={'center'}>
					<Gutter gap={1} />
					<RxClock fontSize={35} color={colors[color]['400']} />
					<Gutter gap={0.5} />
					<FlexCol>
						<Typography variant={'body1'} fontWeight={500}>
							Your submitted business information is currently
							being verified. We&apos;ll get back to you shortly.
						</Typography>
						<Typography variant={'caption'} fontWeight={400}>
							You will be notified via email about your profile
							status.
						</Typography>
					</FlexCol>
				</FlexRow>
				<FlexRow align={'center'}>
					{!isOF && (
						<IoIosClose
							size={25}
							id={'close-button'}
							onClick={() =>
								localStorage.setItem(
									LOCAL_STORAGE_KEY.pendingVisibility,
									JSON.stringify(false)
								)
							}
						/>
					)}
					<Gutter gap={1} />
				</FlexRow>
			</FinanceContainer>
		)
	}

	const KybRejected = () => {
		const color: ColorsType = 'red'
		return (
			<FinanceContainer color={color}>
				<FlexRow align={'center'}>
					<Gutter gap={1} />
					<RiErrorWarningLine
						fontSize={35}
						color={colors[color]['400']}
					/>
					<Gutter gap={0.5} />
					<FlexCol>
						<Typography variant={'body1'} fontWeight={500}>
							Your business registration application was
							unsuccessful.
						</Typography>
						<Typography variant={'caption'} fontWeight={400}>
							{reason[reason.length - 1]?.reason}
						</Typography>
					</FlexCol>
				</FlexRow>
				<FlexRow align={'center'}>
					<StyledPrimaryButton
						buttonColor={colors['green']['600'] as ColorsCodeType}
						onClick={() => {
							navigate('/ordr-finance/register')
						}}
					>
						<PrimaryButtonWrapper>
							Register Again
						</PrimaryButtonWrapper>
					</StyledPrimaryButton>
					<Gutter gap={0.5} />
					{!isOF && (
						<IoIosClose
							size={25}
							id={'close-button'}
							onClick={() =>
								localStorage.setItem(
									LOCAL_STORAGE_KEY.kybRejectedVisibility,
									JSON.stringify(false)
								)
							}
						/>
					)}
					<Gutter gap={1} />
				</FlexRow>
			</FinanceContainer>
		)
	}

	return (
		<>
			{!kyb && !user && <NotRegister />}
			{kyb && kyb.status === 'pending' && <Pending />}
			{kyb && kyb.status === 'approved' && contractValid.length === 0 && (
				<Approved />
			)}
			{kyb && kyb.status === 'rejected' && <KybRejected />}
			{kyb && contractValid.length > 0 && <ApplyForFinancing />}
		</>
	)
}
