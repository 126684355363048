import React, { useEffect, useState } from 'react'
import { styled } from '@mui/system'
import { SideBar } from 'components'
import { useDimension } from 'hooks'
import { FlexCol } from 'components/atoms/Flex'
import { AppBar } from 'components/app/AppBar'
import { SCROLLBAR_STYLE } from 'data'
import { useDispatch, useSelector } from 'react-redux'
import type { RootState } from 'store/index-reducer'
import { useSnackbar } from 'notistack'
import { usePusher } from 'PusherJsProvider'
import { setLogout } from 'store/user/actions'

const Wrapper = styled('div')`
	display: flex;
	width: 100%;
	background-color: ${({ theme: { palette } }) => palette.background.default};
`

const Col = styled(FlexCol)<{ height: number }>`
	flex: 1;
	overflow: scroll;
	width: 100%;
	flex-wrap: nowrap;

	max-height: ${({ height }) => height}px;

	${SCROLLBAR_STYLE}
`

const Padding = styled(FlexCol)<{ height: number }>`
	flex: 1;
	padding: 0;
	height: 100%;
	width: 100%;

	max-height: ${({ height }) => height}px;
`

const Div = styled(FlexCol)`
	width: 100%;
	height: 100%;
`

const ColBox = styled(FlexCol)`
	flex: 1;
	overflow: auto;
	width: 100%;
	height: 100%;

	::-webkit-scrollbar {
		display: none;
	}
	-ms-overflow-style: none;
	scrollbar-width: none;
`

type SideBarProps = {
	children?: React.ReactNode
}

const Layout: React.ComponentType<SideBarProps> = ({ children }) => {
	const isSidebarOpen = localStorage.getItem('isSidebarOpen')
	const isMenuOpen = isSidebarOpen ? JSON.parse(isSidebarOpen) : true
	const [isOpen, setIsOpen] = useState<boolean>(isMenuOpen)
	const { height } = useDimension()
	const { pusher } = usePusher()
	const dispatch = useDispatch()
	const { enqueueSnackbar } = useSnackbar()
	const { user } = useSelector((state: RootState) => state.user)

	useEffect(() => {
		if (user && user.id) {
			const channel = pusher?.subscribe(`invoice-created-${user?.id}`)
			const access = pusher?.subscribe(`invite-update-${user.id}`)
			const cbfs = pusher?.subscribe(user.id)

			const handler = function (data: any) {
				window.open(data?.data?.signedUrl, '_blank')
			}

			const cbfsHandler = function (data: any) {
				console.log('handler', data)
			}

			const accessHandler = async function () {
				enqueueSnackbar('Account role updated', {
					variant: 'success'
				})
				localStorage.removeItem('token')
				localStorage.clear()
				dispatch(setLogout())
			}
			channel?.bind('invoice-creation-event', handler)
			access?.bind('token-update', accessHandler)
			cbfs?.bind('cbfs-profile-updates', cbfsHandler)

			return () => {
				channel?.unbind('invoice-creation-event', handler)
				access?.unbind('token-update', accessHandler)
				cbfs?.unbind('cbfs-profile-updates', cbfsHandler)
			}
		}
	}, [user, dispatch, pusher, enqueueSnackbar])

	return (
		<Wrapper>
			<SideBar isOpen={isOpen} setIsOpen={setIsOpen} />
			<Col height={height}>
				<AppBar setIsOpen={setIsOpen} isOpen={isOpen} />
				<Padding height={height}>
					<ColBox id={'layout-id'}>
						<Div>{children}</Div>
					</ColBox>
				</Padding>
			</Col>
		</Wrapper>
	)
}

export { Layout }
