import React from 'react'
import { styled, Typography } from '@mui/material'
import { FlexCol } from 'components/atoms/Flex'
import type { LoadsheetType } from 'typings'
import { Gutter } from 'components'
import { amountToLocal, convertNumber } from 'helpers'
import { InternalComment } from './InternalComment'

const Wrapper = styled(FlexCol)`
	padding: 24px;
	height: max-content;
`

const AmountDueText = styled(Typography)`
	color: ${({ theme }) => theme.palette.colors.gray['700']};
	font-weight: 500;
`

const AmountText = styled(Typography)`
	line-height: 25px;
`

type PdfRightWrapperProps = {
	loadSheet: LoadsheetType
}

export const RightViewOptions: React.ComponentType<PdfRightWrapperProps> = ({
	loadSheet
}) => {
	const { comments } = loadSheet

	return (
		<Wrapper>
			<AmountDueText variant="caption">Amount Due</AmountDueText>
			<AmountText variant="h6" fontWeight={'bold'}>
				{`PKR ${amountToLocal(
					convertNumber(loadSheet.totalAmountDue || 0)
				)}`}
			</AmountText>

			<Gutter spacing={1.5} />

			<InternalComment
				loadSheetId={loadSheet?.id || ''}
				comment={
					comments && comments?.length > 0
						? comments[comments.length - 1].comment
						: undefined
				}
			/>
		</Wrapper>
	)
}
