import axios from 'axios'

const API_BASE_URL = process.env.REACT_APP_API_URL

const axiosClient = axios.create({
	// headers: {
	//     'Access-Control-Allow-Origin': '*',
	// },
	baseURL: API_BASE_URL,
	responseType: 'json',
	timeout: 30 * 1000 // 20 seconds
})

export default axiosClient
