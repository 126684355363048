import { CircularProgress, Typography, useTheme } from '@mui/material'
import React, { useCallback, useState } from 'react'
import { styled } from '@mui/system'
import { useDropzone } from 'react-dropzone'
import { FlexRow } from 'components/atoms/Flex'
import * as Axios from 'axios'
import { useAppErrors } from 'hooks'
import { useField } from 'formik'
import { getFileExtension, isImageFileExtension } from 'helpers/mixed'
import { ImageModal } from 'screen/Invoices/View/ImageModal'
import { useSnackbar } from 'notistack'
import { CompressImage } from 'utils/image-compression'
import { TransparentButton } from 'components/atoms/Button/TransparentButton'
import { uploadADoc } from 'api'
import { useSettings } from 'context/settings'
import { FaCamera } from 'react-icons/fa'
import { MdClose } from 'react-icons/md'
import { addOrRemoveArr } from 'helpers'
import { ImFilePdf } from 'react-icons/im'
import { Gutter } from 'components'

const Box = styled(FlexRow)`
	min-height: 100px;
	min-width: 100px;
	border-radius: 8px;
	align-items: center;
	justify-content: center;
	max-height: 113px;
	max-width: 116px;
	border: 1px solid ${({ theme }) => theme.palette.colors.gray['300']};

	:hover {
		cursor: pointer;
	}

	:disabled {
		background-color: ${({ theme }) => theme.palette.colors.gray['50']};
	}
`

const Wrapper = styled(FlexRow)`
	width: 100%;
`

const Grid = styled('div')`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
`

const Box2 = styled(Box)`
	position: relative;
	border: 1px solid ${({ theme }) => theme.palette.colors.gray['300']};
	min-height: 113px;
	min-width: 116px;

	//margin: 10px;
`

const Box3 = styled(Box2)`
	margin-right: 10px;
	margin-bottom: 10px;
`

const Btn = styled(FlexRow)`
	position: absolute;
	top: -8px;
	right: -10px;
	z-index: 20000;

	:hover {
		cursor: pointer;
		opacity: 0.5;
	}
`

const Circle = styled(FlexRow)`
	padding: 2px;
	border-radius: 50%;
	background-color: ${({ theme }) => theme.palette.colors.gray['100']};
`

type Props = {
	name: string
	disabled?: boolean
	title: string
}

export const UploadAttachment: React.ComponentType<Props> = ({
	disabled,
	name,
	title
}) => {
	const [setUrl, setOpenUrl] = useState('')
	const [isOpen, setIsOpen] = useState(false)
	const [loading, setLoading] = useState(false)
	const [tempFile, setTempFile] = useState({ url: '', name: '' })
	const [, meta, helpers] = useField(name)
	const { setAppError } = useAppErrors()
	const { enqueueSnackbar } = useSnackbar()
	const theme = useTheme()
	const { business } = useSettings()
	const { colors } = theme.palette

	const isImage = (url: string = '') => {
		const urlParts = url.split('.')
		const lastPart = urlParts[urlParts.length - 1]
		const Fext = lastPart.split('?')[0]

		return (
			Fext.toLowerCase() === 'png' ||
			Fext.toLowerCase() === 'jpeg' ||
			Fext.toLowerCase() === 'jpg'
		)
	}

	const onDrop = useCallback(
		async (acceptedFiles: any) => {
			try {
				if (acceptedFiles && acceptedFiles[0]) {
					const selectedFile = acceptedFiles[0]
					const getExt = getFileExtension(selectedFile.name)
					const isImage = isImageFileExtension(getExt)

					setLoading(true)
					setTempFile({ url: '', name: selectedFile.name })

					const data = await uploadADoc(
						business,
						isImage ? 'png' : getExt,
						isImage ? 'image' : 'application',
						name
					)
					let res: any = undefined

					if (isImage) {
						res = await CompressImage(selectedFile)
					}

					const reader = new FileReader()
					reader.onload = async evt => {
						await Axios.default
							.put(data.signedRequest, evt.target?.result, {
								headers: {
									'Content-Type': isImage
										? res.type
										: selectedFile.type
								}
							})
							.then(() => {
								helpers.setValue([...meta.value, data.url])

								enqueueSnackbar('Upload Successful', {
									variant: 'success'
								})
								setLoading(false)
								setTempFile({ url: '', name: '' })
							})
							.catch(() => {
								enqueueSnackbar('Upload Failed', {
									variant: 'error'
								})
								setLoading(false)
							})
					}

					reader.readAsArrayBuffer(isImage ? res : selectedFile)
				} else {
					setLoading(false)
				}
			} catch (error: any) {
				setAppError(error)
				setLoading(false)
			}
		},
		[business, enqueueSnackbar, helpers, meta.value, name, setAppError]
	)

	const { getRootProps, getInputProps } = useDropzone({
		onDrop,
		accept: {
			'image/png': ['.png'],
			'image/jpeg': ['.jpeg'],
			'image/jpg': ['.jpg'],
			'application/pdf': ['.pdf']
		}
		//maxSize: 1000000
	})

	return (
		<>
			<Typography variant={'body1'} fontWeight={400}>
				{title}
			</Typography>
			<Gutter spacing={0.5} />
			<Wrapper>
				<Grid>
					{meta.value.map((m: string) => (
						<Box3 key={m}>
							<Btn
								onClick={() =>
									helpers.setValue(
										addOrRemoveArr(meta.value, m)
									)
								}
							>
								<Circle>
									<MdClose
										color={colors.red['500']}
										size={20}
									/>
								</Circle>
							</Btn>
							<Box2 style={{ overflow: 'hidden' }}>
								{isImage(m) ? (
									<img
										width={116}
										height={113}
										src={m}
										onClick={() => {
											setOpenUrl(m)
											setIsOpen(true)
										}}
									/>
								) : (
									<ImFilePdf
										onClick={() => window.open(m, '_blank')}
										size={40}
										color={colors.red['500']}
									/>
								)}
							</Box2>
						</Box3>
					))}
					{(tempFile.name || tempFile.url) && (
						<Box3 style={{ overflow: 'hidden' }}>
							<CircularProgress color="secondary" />
						</Box3>
					)}

					<Box>
						<TransparentButton
							{...getRootProps()}
							style={{
								borderWidth: 0,
								width: '100%',
								height: '100%'
							}}
							disabled={disabled || loading}
						>
							<input
								{...getInputProps()}
								disabled={disabled || loading}
							/>
							<FaCamera size={30} />
						</TransparentButton>
					</Box>
				</Grid>
			</Wrapper>
			<Gutter spacing={2} />
			<ImageModal
				url={setUrl}
				open={isOpen}
				onClose={() => setIsOpen(false)}
			/>
		</>
	)
}
