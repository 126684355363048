import {
	Button,
	Popover,
	styled,
	TableHead,
	TableRow,
	Typography
} from '@mui/material'
import { FlexRow } from 'components/atoms/Flex'
import React, { useState } from 'react'
import { BiDotsVerticalRounded } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom'
import { ReadyToShareModal } from '../Modals/ReadyToShareModal'
import { StyledTableCell } from './ReadyToShareTable'
import { PrivateQuotationModal } from 'screen/Quotations/Modals/PrivateQuotationModal'
import analytics from 'utils/segment'
import { SEGMENT_EVENTS } from 'data'

const WrapperButton = styled(Button)(() => ({
	backgroundColor: 'white',
	border: '1px solid gray',
	padding: '0px',
	color: 'black'
}))

export const VerticalThreeDots = styled(BiDotsVerticalRounded)`
	cursor: pointer;
`

export const PopoverElement = styled(Typography)(({ theme }) => ({
	backgroundColor: theme.palette.background.default,
	padding: '10px 50px 10px 10px',
	cursor: 'pointer',
	'&:hover': {
		backgroundColor: theme.palette.background.paper
	}
}))

type Props = {
	seeAll?: boolean
	readyToShare?: boolean
}

export const TableHeadings: React.ComponentType<Props> = ({
	readyToShare,
	seeAll
}) => {
	const [openModal, setOpenModal] = useState<boolean>(false)
	const [openDraft, setDraft] = useState<boolean>(false)
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

	const handleClick = (event: any) => {
		analytics.track(SEGMENT_EVENTS.QUO_KEBAB_MENU)
		setAnchorEl(event.currentTarget)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	const open = Boolean(anchorEl)

	const handleOpenModal = () => {
		if (readyToShare) setOpenModal(true)
		else {
			setDraft(true)
		}
	}

	const handleCloseModal = () => {
		if (readyToShare) setOpenModal(false)
		else {
			setDraft(false)
		}
	}

	const navigate = useNavigate()

	return (
		<TableHead>
			<TableRow>
				<StyledTableCell style={{ width: '30%', paddingLeft: '5px' }}>
					Contacts
				</StyledTableCell>
				<StyledTableCell align="left" style={{ width: '10%' }}>
					Quotation ID
				</StyledTableCell>
				<StyledTableCell align="left" style={{ width: '10%' }}>
					Total(PKR)
				</StyledTableCell>
				<StyledTableCell align="left" style={{ width: '10%' }}>
					Status
				</StyledTableCell>
				<StyledTableCell align="left" style={{ width: '15%' }}>
					Date created
				</StyledTableCell>
				<StyledTableCell align="right" style={{ width: '25%' }}>
					<FlexRow justify="flex-end" align="center">
						{seeAll && (
							<>
								<WrapperButton onClick={handleOpenModal}>
									See All
								</WrapperButton>
								<ReadyToShareModal
									openModal={openModal}
									closeModal={handleCloseModal}
								/>
								<PrivateQuotationModal
									openModal={openDraft}
									closeModal={handleCloseModal}
								/>
							</>
						)}
						{readyToShare && (
							<>
								<VerticalThreeDots
									size={20}
									color="black"
									onClick={handleClick}
								/>
								<Popover
									elevation={2}
									open={open}
									anchorEl={anchorEl}
									onClose={handleClose}
									anchorOrigin={{
										vertical: 'bottom',
										horizontal: 'right'
									}}
									transformOrigin={{
										vertical: 'top',
										horizontal: 'right'
									}}
								>
									<PopoverElement
										variant="body2"
										onClick={() => {
											analytics.track(
												SEGMENT_EVENTS.RECYCLEBIN,
												{
													screen: 'quotation_book'
												}
											)
											navigate('/quotation/recyclebin')
										}}
									>
										Open Recycle Bin
									</PopoverElement>
								</Popover>
							</>
						)}
					</FlexRow>
				</StyledTableCell>
			</TableRow>
		</TableHead>
	)
}
