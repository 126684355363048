import React, { useState } from 'react'
import { styled } from '@mui/system'
import { Button, Capsule, ErrorText, Gutter, InitiationDate } from 'components'
import { FlexCol, FlexRow } from 'components/atoms/Flex'
import { Typography, useTheme } from '@mui/material'
import { useField } from 'formik'
import { SelectContactModal } from 'components/app/ContactsModal/SelectContactModal'
import { objectPick } from 'helpers/object'
import { truncate } from 'helpers'
import analytics from 'utils/segment'
import { SEGMENT_EVENTS } from 'data'

const Wrapper = styled('div')`
	display: grid;
	grid-template-columns: 3fr 1fr;
	width: 100%;
	min-width: 500px;
	background-color: ${({ theme }) => theme.palette.background.paper};
	padding: 10px 15px;
	min-height: 150px;
	border-radius: 5px;
	justify-content: space-between;
`

const StyledCapsule = styled(Capsule)`
	font-weight: 500;
	font-size: 12px;
	padding: 2px 10px;
`

const StyledButton = styled(Button)`
	border-radius: 10px;
	padding: 8px 15px;
	background-color: ${({ theme }) => theme.palette.colors.yellow['300']};

	:hover {
		background-color: ${({ theme }) => theme.palette.colors.yellow['200']};
	}
`

type OrderContactProps = {
	name: string
	type?: string
}

export const OrderContact: React.ComponentType<OrderContactProps> = ({
	name,
	type
}) => {
	// I don't want to use state inside formik TODO:// @avinash
	const [isModalOpen, setIsModalOpen] = useState(false)
	const [, meta, helpers] = useField(name)
	const theme = useTheme()
	const { value } = meta
	const { colors, mode } = theme.palette

	return (
		<>
			<Wrapper>
				<FlexCol>
					<FlexRow align={'center'} justify={'center'}>
						<Typography variant={'body2'} fontWeight={500}>
							Contact details
						</Typography>
						<Gutter gap={0.2} />
						{meta.value && (
							<StyledCapsule
								isSelected
								color={'blue'}
								name={'Edit'}
								value={'edit'}
								onItemClick={() => setIsModalOpen(true)}
							/>
						)}
					</FlexRow>
					{!value && (
						<>
							<Gutter spacing={0.5} />
							<StyledButton
								onClick={() => {
									setIsModalOpen(true)
									analytics.track(
										SEGMENT_EVENTS.SELECT_CONTACT,
										{
											screen: `record_${type}`
										}
									)
								}}
							>
								<Typography
									variant={'subtitle2'}
									fontWeight={500}
									color={
										mode === 'dark'
											? colors.white['100']
											: colors.black['100']
									}
								>
									Select a Contact
								</Typography>
							</StyledButton>
						</>
					)}
					{value && (
						<>
							<Typography typography={'body1'} fontWeight={500}>
								{truncate(value.name, 30)}
							</Typography>
							<Typography variant={'caption'}>
								{value.businessName} - 0{value.phone.number}
							</Typography>
							<Gutter spacing={2} />
							<Typography variant={'subtitle2'}>
								{value.address}
							</Typography>
						</>
					)}
				</FlexCol>
				<InitiationDate title={`${type} Date`} name={'initiationAt'} />
			</Wrapper>
			<SelectContactModal
				onContactSelect={contact =>
					helpers.setValue(
						objectPick(
							contact,
							'name',
							'id',
							'businessName',
							'phone',
							'address',
							'email',
							'businessRelationship',
							'profilePic'
						)
					)
				}
				segmentEventCall={() =>
					analytics.track(SEGMENT_EVENTS.SELECT_CONTACT_SUCCESS, {
						screen: 'new_order'
					})
				}
				onClose={() => setIsModalOpen(false)}
				open={isModalOpen}
			/>
			{meta.touched && meta.error && (
				<ErrorText text={'Contact is required'} />
			)}
		</>
	)
}
