import * as React from 'react'
import { useCallback } from 'react'
import {
	Paper,
	styled,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
	useTheme
} from '@mui/material'
import {
	dateFormat,
	LEDGER_INVOICE_TYPE,
	PaymentMethodOptions,
	TRANSACTION_MAPPER
} from 'data'
import moment from 'moment'
import { amountToLocal } from 'helpers'
import { FlexRow } from 'components/atoms/Flex'
import type { ColorsType, TransactionProps } from 'typings'
import { Gutter } from 'components'

const StyledTableRow = styled(TableRow)(({ theme }) => ({
	height: 'min-content',

	'&:nth-of-type(even)': {
		backgroundColor: theme.palette.colors.gray['50']
	},
	'&:nth-of-type(odd)': {
		backgroundColor: theme.palette.colors.white['50']
	},
	// hide last border
	'&:last-child td, &:last-child th': {
		border: 0
	}
}))

const StyledTableCellHead = styled(TableCell)(({ theme }) => ({
	color: theme.palette.colors.gray['500'],
	backgroundColor: theme.palette.colors.gray['50']
}))

const StyledTableCellRow = styled(TableCell)(({ theme }) => ({
	color: '#1C1C1C',
	fontSize: '15px'
}))

const Container = styled('div')`
	max-height: 250px;
	overflow-y: auto;
`

const Text = styled(Typography)`
	font-weight: 500;
`

const DetailsSubText = styled(Typography)`
	font-size: 10px;
	color: ${({ theme }) => theme.palette.colors.gray['600']};
`

type TransactionType = keyof typeof TRANSACTION_MAPPER

type TransactionTableProps = {
	entries: TransactionProps[]
}

export const TransactionTable: React.ComponentType<TransactionTableProps> = ({
	entries
}) => {
	const theme = useTheme()
	const { colors } = theme.palette

	const getPaymentMethodText = useCallback((row: TransactionProps) => {
		if (!row.metaData.payment) return undefined
		if (!row.metaData.payment.method) return undefined
		return PaymentMethodOptions.find(
			d => row.metaData.payment?.method === d.value
		)?.label
	}, [])

	const RenderRow = useCallback(
		({ row }: { row: TransactionProps }) => {
			const PaymentMethodText = getPaymentMethodText(row)
			const PaymentCreationDate = row.metaData?.recordedDate
			const amountColor: ColorsType = LEDGER_INVOICE_TYPE[row.type]

			return (
				<StyledTableRow
					key={row.id}
					style={{
						backgroundColor:
							row.metaData.invoice?.status === 'void'
								? colors.red['50']
								: undefined
					}}
				>
					<StyledTableCellRow component="th" scope="row">
						{moment(row.createdAt).format(dateFormat)}
					</StyledTableCellRow>
					<StyledTableCellRow align="left">
						<FlexRow align="center">
							{TRANSACTION_MAPPER[row.type as TransactionType]}
						</FlexRow>
						<FlexRow align={'center'}>
							{PaymentMethodText && (
								<DetailsSubText>
									{PaymentMethodText}
								</DetailsSubText>
							)}
							{PaymentMethodText && PaymentCreationDate && (
								<>
									<DetailsSubText>{','}</DetailsSubText>
									<Gutter gap={0.2} />
								</>
							)}
							{PaymentCreationDate && (
								<DetailsSubText>
									{moment(PaymentCreationDate).format(
										'DD MMM YYYY'
									)}
								</DetailsSubText>
							)}
						</FlexRow>
					</StyledTableCellRow>
					<StyledTableCellRow align="left">
						<FlexRow align="center" justify="flex-end">
							<Text variant={'caption'} color={amountColor}>
								{/*TODO:// Add currency here */}
								PKR {amountToLocal(row.amount)}
							</Text>
						</FlexRow>
					</StyledTableCellRow>
				</StyledTableRow>
			)
		},
		[colors.red, getPaymentMethodText]
	)

	return (
		<TableContainer component={Paper}>
			<Container>
				<Table size="small" aria-label="a dense table">
					<TableHead style={{ position: 'sticky', top: 0 }}>
						<TableRow>
							<StyledTableCellHead>Date</StyledTableCellHead>
							<StyledTableCellHead align="left">
								Details
							</StyledTableCellHead>
							<StyledTableCellHead align="right">
								Amount
							</StyledTableCellHead>
						</TableRow>
					</TableHead>
					<TableBody>
						{entries.map((row: any) => (
							<RenderRow row={row} key={row.id} />
						))}
					</TableBody>
				</Table>
			</Container>
		</TableContainer>
	)
}
