import React, { useEffect, useState } from 'react'
import {
	Button,
	DatePicker,
	FieldCurrencyInput,
	Gutter,
	Label,
	StyledModal
} from 'components'
import {
	InputAdornment,
	type ModalProps,
	Typography,
	useTheme
} from '@mui/material'
import { styled } from '@mui/system'
import { FlexCol, FlexRow } from 'components/atoms/Flex'
import { Formik } from 'formik'
import { IoIosClose } from 'react-icons/io'
import { dateFormat } from 'data'
import { CustomAutoComplete } from 'components/app/CutomAutoComplete'
import { pascalCase } from 'helpers'
import type { ColorsCodeType, PaymentMethod } from 'typings'
import { TRANSACTION_METHOD } from 'data/initialData'
import { getABank, loanRePayment } from 'api/cbfs'
import { CiCircleInfo } from 'react-icons/ci'
import { TransparentButton } from 'components/atoms/Button/TransparentButton'
import { useSettings } from 'context/settings'

const Container = styled(FlexCol)`
	align-items: flex-end;
	background-color: ${({ theme }) => theme.palette.background.default};
	padding: 16px;
	z-index: 0;
	border-radius: 10px;
	min-width: 500px;
	max-width: 500px;
`

const Row = styled(FlexRow)`
	width: 100%;
`

const WRow = styled(Row)`
	padding: 10px;

	:hover {
		background-color: ${({ theme }) => theme.palette.colors.blue['50']};
	}
`

const Box = styled(FlexCol)`
	width: 100%;
	background-color: ${({ theme }) => theme.palette.colors.gray['100']};
	padding: 10px;
	border-radius: 5px;
`

const Box2 = styled(FlexCol)`
	width: 100%;
	border: 1px solid ${({ theme }) => theme.palette.colors.gray['100']};
	padding: 10px;
	border-radius: 5px;
`

const CloseButtonWrapper = styled(FlexCol)`
	justify-content: center;
	align-items: center;
	height: 30px;
	width: 30px;
	right: 1.75em;
	&:hover {
		cursor: pointer;
		border-radius: 25%;
		background-color: ${({ theme }) => theme.palette.colors.gray['100']};
		display: flex;
	}
`

const PrimaryButtonWrapper = styled(FlexRow)``

const StyledPrimaryButton = styled(Button)`
	min-width: 0;
	min-height: 32px;
	padding: 0 10px;
	border-radius: 5px;
	color: ${({ theme }) => theme.palette.colors.white['900']};
`

type initialValue = {
	loanId: string
	amount: number | string
	type: 'repayment'
	paymentDate: string
	paymentMethod: string
	userId: string
}

type RepaymentModalProps = {
	onSuccess: () => void

	initialValue: initialValue
} & Omit<ModalProps, 'children'>

type PaymentMethodT = { value: PaymentMethod; label: string }

export const RepaymentModal: React.ComponentType<RepaymentModalProps> = ({
	onClose,
	onSuccess,
	initialValue,
	...props
}) => {
	const [detail, setDetail] = useState<any>({})
	const [isModalOpen, setIsModalOpen] = useState(false)
	const theme = useTheme()
	const { business } = useSettings()
	const { colors } = theme.palette

	const getDetail = async () => {
		try {
			const res = await getABank(business)
			setDetail(res)
		} catch (e) {
			console.log('error aya re', e)
		}
	}

	const RenderOption = ({
		opt,
		setFieldValue
	}: {
		opt: PaymentMethodT
		setFieldValue: any
	}) => {
		return (
			<WRow
				onClick={() => {
					setFieldValue('paymentMethod', opt.value)
				}}
			>
				{opt.label}
			</WRow>
		)
	}

	const onSubmit = async (values: any, actions: any) => {
		try {
			actions.setSubmitting(true)
			await loanRePayment(values)
			onSuccess?.()
			onClose?.({}, 'escapeKeyDown')
		} catch (e) {
			//
		} finally {
			actions.setSubmitting(false)
		}
	}

	useEffect(() => {
		getDetail()
	}, [])

	const Form = () => {
		return (
			<Formik initialValues={initialValue} onSubmit={onSubmit}>
				{({
					values,
					handleSubmit,
					isSubmitting,
					isValid,
					setFieldValue
				}) => (
					<FlexCol style={{ width: '100%' }}>
						<FlexRow
							align={'center'}
							justify={'space-between'}
							style={{ width: '100%' }}
						>
							<Typography fontWeight={600} variant={'body1'}>
								Record Payment
							</Typography>
							<CloseButtonWrapper>
								<IoIosClose
									size={25}
									onClick={() => {
										setIsModalOpen(false)
										onClose?.({}, 'escapeKeyDown')
									}}
								/>
							</CloseButtonWrapper>
						</FlexRow>
						<Gutter />
						<FieldCurrencyInput
							title={'Amount'}
							name="amount"
							placeholder={'Amount'}
							size="small"
							style={{ width: '35ch' }}
							inputProps={{ style: { textAlign: 'end' } }}
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<Typography>PKR</Typography>
									</InputAdornment>
								)
							}}
						/>
						<Gutter spacing={0.5} />
						<FlexCol>
							<Label>{'Payment Date*'}</Label>
							<Gutter spacing={0.2} />
							<DatePicker
								slotProps={{
									textField: {
										placeholder: 'Start Date',
										style: {
											borderRadius: 10,
											width: '35ch'
										}
									}
								}}
								format={dateFormat}
								value={values.paymentDate}
								onChange={e => {
									setFieldValue('paymentDate', e)
								}}
							/>
						</FlexCol>
						<Gutter spacing={0.5} />
						<FlexCol>
							<Label>Transaction Method</Label>
							<Gutter spacing={0.1} />

							<CustomAutoComplete
								options={TRANSACTION_METHOD}
								renderOption={(props, option) => (
									<RenderOption
										opt={option}
										setFieldValue={setFieldValue}
										{...props}
									/>
								)}
								size={'small'}
								style={{ width: '35ch' }}
								value={pascalCase(
									TRANSACTION_METHOD.find(
										d => d.value === values.paymentMethod
									)?.label
								)}
								filterOptions={(i, p) => {
									return i.filter(r =>
										r.label
											.toLowerCase()
											.includes(
												(
													p.inputValue || ''
												).toLowerCase()
											)
									)
								}}
								onClick={() => {
									//
								}}
								placeholder={'Method'}
							/>
						</FlexCol>
						<Gutter spacing={2} />
						<FlexRow
							align={'center'}
							justify={'space-between'}
							style={{ width: '100%' }}
						>
							<Typography variant={'body1'}></Typography>
							<StyledPrimaryButton
								buttonColor={
									colors['green']['600'] as ColorsCodeType
								}
								onClick={() => handleSubmit()}
								disabled={!isValid || isSubmitting}
							>
								<PrimaryButtonWrapper>
									Save
								</PrimaryButtonWrapper>
							</StyledPrimaryButton>
						</FlexRow>
					</FlexCol>
				)}
			</Formik>
		)
	}

	const Information = () => {
		return (
			<FlexCol style={{ width: '100%' }}>
				<FlexRow
					align={'center'}
					justify={'space-between'}
					style={{ width: '100%' }}
				>
					<Typography fontWeight={600} variant={'body1'}>
						Make Repayment
					</Typography>
					<CloseButtonWrapper>
						<IoIosClose
							size={25}
							onClick={() => {
								setIsModalOpen(false)
								onClose?.({}, 'escapeKeyDown')
							}}
						/>
					</CloseButtonWrapper>
				</FlexRow>
				<Gutter />
				{detail.repayment_address && (
					<>
						<Box>
							<Typography
								variant={'caption'}
								color={theme.palette.colors.gray['600']}
							>
								Bank Name
							</Typography>
							<Typography variant={'body1'} fontWeight={500}>
								{detail.repayment_address.bank_name}
							</Typography>
							<Gutter spacing={0.5} />
							<Typography
								variant={'caption'}
								color={theme.palette.colors.gray['600']}
							>
								Account Title
							</Typography>
							<Typography variant={'body1'} fontWeight={500}>
								{detail.repayment_address.account_title}
							</Typography>
							<Gutter spacing={0.5} />
							<Typography
								variant={'caption'}
								color={theme.palette.colors.gray['600']}
							>
								Account Number
							</Typography>
							<Typography variant={'body1'} fontWeight={500}>
								{detail.repayment_address.account_number}
							</Typography>
							<Gutter spacing={0.5} />
							<Typography
								variant={'caption'}
								color={theme.palette.colors.gray['600']}
							>
								IBAN
							</Typography>
							<Typography variant={'body1'} fontWeight={500}>
								{detail.repayment_address.iban}
							</Typography>
						</Box>
						<Gutter />
					</>
				)}

				<Box2>
					<FlexRow align={'center'} justify={'center'}>
						<CiCircleInfo />
						<Gutter gap={0.2}></Gutter>
						<Typography variant={'body1'} fontWeight={500}>
							Repayment Instructions
						</Typography>
					</FlexRow>
					<Typography
						variant={'caption'}
						color={theme.palette.colors.gray['600']}
					>
						A step by step guide on how to repay your financing
						amount.
					</Typography>
					<Gutter />
					<Typography
						variant={'caption'}
						color={theme.palette.colors.gray['600']}
						style={{
							overflowWrap: 'break-word',
							whiteSpace: 'pre-line'
						}}
					>
						{detail.instructions}
					</Typography>
				</Box2>
				<Gutter />
				<TransparentButton
					style={{ alignSelf: 'flex-end' }}
					onClick={() => {
						setIsModalOpen(true)
					}}
				>
					Record Repayment
				</TransparentButton>
			</FlexCol>
		)
	}

	return (
		<StyledModal {...props}>
			<Container>
				{isModalOpen && <Form />}
				{!isModalOpen && <Information />}
			</Container>
		</StyledModal>
	)
}
