import type { TableComponents } from 'react-virtuoso'
import * as React from 'react'
import {
	Table,
	TableBody,
	TableContainer,
	TableHead,
	TableRow
} from '@mui/material'
import Paper from '@mui/material/Paper'
import { Gutter } from 'components'

export const AllInvoiceTableComponent: TableComponents<any> = {
	Scroller: React.forwardRef<HTMLDivElement>((props, ref) => (
		<TableContainer component={Paper} {...props} ref={ref} />
	)),
	Table: props => (
		<Table
			{...props}
			sx={{
				borderCollapse: 'separate',
				tableLayout: 'fixed'
			}}
		/>
	),
	TableHead,
	TableRow: ({ item: _item, ...props }) => (
		<>
			<TableRow
				{...props}
				onClick={() => {
					const { children } = props as any
					if (children && children.props)
						children?.props?.onRowClick?.()
				}}
				style={{
					backgroundColor: _item.hasLoadSheet ? '#F0F0F0' : 'white',
					paddingBottom: 30
				}}
			/>
		</>
	),
	TableBody: React.forwardRef<HTMLTableSectionElement>((props, ref) => (
		<TableBody {...props} ref={ref} />
	)),
	TableFoot: () => <Gutter />
}

if (AllInvoiceTableComponent.Scroller)
	AllInvoiceTableComponent.Scroller.displayName = 'Scroller'
if (AllInvoiceTableComponent.Table)
	AllInvoiceTableComponent.Table.displayName = 'Table'
if (AllInvoiceTableComponent.TableRow)
	AllInvoiceTableComponent.TableRow.displayName = 'TableRow'
if (AllInvoiceTableComponent.TableBody)
	AllInvoiceTableComponent.TableBody.displayName = 'TableBody'
