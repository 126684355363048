import React, { type LegacyRef, useCallback } from 'react'
import {
	calculateInvoiceDiscountAmount,
	calculateInvoiceSubTotal,
	invoice_calculation
} from 'data'
import { Gutter } from 'components'
import { FlexRow } from 'components/atoms/Flex'
import { styled, Typography } from '@mui/material'
import { amountToLocal, convertNumber, formatId, pascalCase } from 'helpers'
import type { InvoiceType } from 'typings'
import moment from 'moment'
import { calculateDiscount, calculateDiscountPercentage } from 'helpers/mixed'

const TypographyWrapper = styled(Typography)`
	font-size: 12px;
	margin: 2px 10%;
	font-family: Helvetica;
`
const TextWrapper = styled(Typography)`
	font-size: 14px;
	font-family: Helvetica;
`
const SmallTextWrapper = styled(Typography)`
	font-size: 11px;
	font-family: Helvetica;
`
const Container = styled('div')`
	width: 376px;
	text-transform: uppercase;
`

const Header = styled('div')`
	text-align: center;
`

const Grid = styled('div')<{ isDiscount?: boolean }>`
	display: grid;
	grid-template-columns: ${({ isDiscount }) =>
		isDiscount
			? '20px 1fr 40px 0.7fr 1fr 1fr'
			: '20px 1fr 40px 0.7fr 0.8fr'};
	grid-column-gap: 5px;
`

const RightSmallWrapper = styled(SmallTextWrapper)`
	width: 60%;
	overflow-wrap: break-word;
	text-align: right;
	font-size: 13px;
`
type Props = {
	invoice: InvoiceType
}
export const ThermalReceipt = React.forwardRef((props: Props, propRef) => {
	const { invoice } = props
	const { totalAmount, baseAmtAfterDiscount } = invoice_calculation(invoice)

	const totalQuantityOfItems = (invoice: InvoiceType) => {
		let qty = 0
		invoice.items.map(item => {
			qty += parseInt(item.quantity.toString())
		})
		return qty
	}

	const isDiscount =
		invoice.items.reduce(
			(accumulator, item) => (item.discount?.amount || 0) + accumulator,
			0
		) > 0

	const Items = useCallback(
		({
			invoice,
			item,
			idx
		}: {
			invoice: InvoiceType
			item: any
			idx: number
		}) => {
			const tempDis = calculateDiscountPercentage(
				item.perItemSellPrice || 0,
				item.discount?.amount || 0,
				item.discount?.type || 'percent'
			)

			const lineTotal = calculateDiscount(
				convertNumber(item.quantity || 0) *
					convertNumber(item.perItemSellPrice || 0),
				item.discount?.amount || 0,
				item.discount?.type || 'percent'
				// eslint-disable-next-line no-mixed-spaces-and-tabs
			)

			return (
				<Grid
					isDiscount={isDiscount}
					style={{
						padding: '2px 0px',
						width: '100%',
						borderBottom:
							idx + 1 < invoice.items.length
								? '1px solid black'
								: undefined
					}}
				>
					<TextWrapper
						variant="subtitle2"
						style={{
							paddingLeft: '3px'
						}}
					>
						{idx + 1}
					</TextWrapper>
					<TextWrapper
						variant="subtitle2"
						style={{ overflowWrap: 'break-word' }}
					>
						{item.name}
					</TextWrapper>
					<TextWrapper
						variant="subtitle2"
						style={{ overflowWrap: 'break-word' }}
					>
						{amountToLocal(item.quantity).toString()}
					</TextWrapper>
					<TextWrapper
						variant="subtitle2"
						style={{ overflowWrap: 'break-word' }}
					>
						{amountToLocal(item.perItemSellPrice).toString()}
					</TextWrapper>
					{isDiscount && (
						<TextWrapper variant={'caption'} fontWeight={'500'}>
							{tempDis.discountPercentage}%
						</TextWrapper>
					)}

					<TextWrapper
						variant="subtitle2"
						style={{ overflowWrap: 'break-word' }}
					>
						{amountToLocal(convertNumber(lineTotal))}
					</TextWrapper>
				</Grid>
			)
		},
		[isDiscount]
	)

	const ExtraCharges = useCallback(({ charge }: { charge: any }) => {
		return (
			<FlexRow
				justify="space-between"
				style={{
					borderBottom: '1px solid black',
					marginTop: '5px'
				}}
			>
				<TextWrapper variant="subtitle2">
					{charge.description}
				</TextWrapper>
				<RightSmallWrapper variant="subtitle2">
					{amountToLocal(charge.amount)}
				</RightSmallWrapper>
			</FlexRow>
		)
	}, [])

	const Taxes = useCallback(
		({ tax }: { tax: any }) => {
			return (
				<FlexRow
					justify="space-between"
					style={{
						borderBottom: '1px solid black',
						marginTop: '5px'
					}}
				>
					<TextWrapper variant="subtitle2">
						{`${tax.name} (${tax.rate}%)`}
					</TextWrapper>
					<RightSmallWrapper variant="subtitle2">
						{amountToLocal((baseAmtAfterDiscount * tax.rate) / 100)}
					</RightSmallWrapper>
				</FlexRow>
			)
		},
		[invoice.extraCharges, baseAmtAfterDiscount]
	)

	return (
		<Container ref={propRef as LegacyRef<HTMLDivElement>}>
			{/* HEADERR */}
			<Header>
				<Typography
					variant="h5"
					fontWeight={600}
					style={{ fontFamily: 'Helvetica' }}
				>
					{invoice.businessInfo.businessName}
				</Typography>
				<TypographyWrapper variant="subtitle2">
					{invoice.businessInfo.address}
				</TypographyWrapper>
				{invoice.businessInfo.email && (
					<TypographyWrapper variant="subtitle2">{`Email # ${invoice.businessInfo.email}`}</TypographyWrapper>
				)}
				{invoice.businessInfo.phone.number && (
					<TypographyWrapper variant="subtitle2">{`Phone # ${invoice.businessInfo.phone.countryCode}-${invoice.businessInfo.phone.number}`}</TypographyWrapper>
				)}
				{invoice.businessInfo.cnic && (
					<TypographyWrapper variant="subtitle2">{`NTN/CNIC # ${invoice.businessInfo.cnic}`}</TypographyWrapper>
				)}
				{invoice.businessInfo.strn && (
					<TypographyWrapper variant="subtitle2">{`STRN # ${invoice.businessInfo.strn}`}</TypographyWrapper>
				)}
				<Gutter spacing={0.4} />
				<Typography
					variant="subtitle1"
					fontWeight={500}
					style={{ fontFamily: 'Helvetica' }}
				>
					Sale Invoice
				</Typography>
			</Header>
			<Gutter spacing={0.3} />

			<FlexRow justify="space-between">
				<TextWrapper variant="subtitle2">
					{`Invoice #: INV-${formatId(invoice.friendlyId)}`}
				</TextWrapper>
				<TextWrapper variant="subtitle2">
					{moment().format('ddd DD-MMM-YYYY')}
				</TextWrapper>
			</FlexRow>
			<Gutter spacing={0.5} />
			<FlexRow justify="space-between">
				{invoice.contact.name && (
					<TextWrapper variant="subtitle2">
						{`For: ${invoice.contact.name}`}
					</TextWrapper>
				)}
				{invoice.contact.cnic && (
					<TextWrapper variant="subtitle2">
						NTN/CNIC: {invoice.contact.cnic}
					</TextWrapper>
				)}
			</FlexRow>
			<Gutter spacing={0.3} />

			{/* TABLEE */}
			<div style={{ border: '2px solid black' }}>
				<Grid
					isDiscount={isDiscount}
					style={{
						width: '100%',
						borderBottom: '1.5px solid black'
					}}
				>
					<TextWrapper
						variant="subtitle2"
						style={{ width: '10%', paddingLeft: '3px' }}
					>
						SN
					</TextWrapper>
					<TextWrapper variant="subtitle2">Item name</TextWrapper>
					<TextWrapper variant="subtitle2" style={{ width: '15%' }}>
						Qty
					</TextWrapper>
					<TextWrapper variant="subtitle2" style={{ width: '15%' }}>
						Price
					</TextWrapper>
					{isDiscount && (
						<TextWrapper
							variant="subtitle2"
							style={{ width: '15%' }}
						>
							Discount
						</TextWrapper>
					)}
					<TextWrapper variant="subtitle2" style={{ width: '20%' }}>
						Amount
					</TextWrapper>
				</Grid>
				{invoice.items.map((item, idx) => {
					return (
						<Items
							invoice={invoice}
							idx={idx}
							item={item}
							key={item.id}
						/>
					)
				})}
				<FlexRow
					style={{
						padding: '2px 0px',
						borderTop: '1.5px solid black'
					}}
				>
					<Gutter gap={0.5} />

					<SmallTextWrapper variant="subtitle2" fontStyle={'italic'}>
						{`Total item(s): ${invoice.items.length}`}
					</SmallTextWrapper>
					<Gutter gap={0.5} />
					<SmallTextWrapper variant="subtitle2" fontStyle={'italic'}>
						{`Total Qty: ${totalQuantityOfItems(invoice)}`}
					</SmallTextWrapper>
				</FlexRow>
			</div>
			<Gutter spacing={0.3} />

			{/* RECEIPT */}
			<FlexRow
				justify="space-between"
				style={{
					borderBottom: '1px solid black',
					marginTop: '5px'
				}}
			>
				<TextWrapper variant="subtitle2">Subtotal Amount</TextWrapper>
				<RightSmallWrapper variant="subtitle2">
					{amountToLocal(calculateInvoiceSubTotal(invoice))}
				</RightSmallWrapper>
			</FlexRow>
			{invoice.extraCharges.map(charge => (
				<ExtraCharges charge={charge} key={charge.id} />
			))}
			{invoice.taxes.map(tax => (
				<Taxes tax={tax} key={tax.name} />
			))}
			{invoice.advance?.amount != '0' && (
				<FlexRow
					justify="space-between"
					style={{
						borderBottom: '1px solid black',
						marginTop: '5px'
					}}
				>
					<TextWrapper variant="subtitle2">Payment Made</TextWrapper>
					<RightSmallWrapper variant="subtitle2">
						-{amountToLocal(invoice.advance?.amount || '0')}
					</RightSmallWrapper>
				</FlexRow>
			)}
			{invoice.discount?.amount != '0' && (
				<FlexRow
					justify="space-between"
					style={{
						borderBottom: '1px solid black',
						marginTop: '5px'
					}}
				>
					<TextWrapper variant="subtitle2">Discount</TextWrapper>
					<RightSmallWrapper variant="subtitle2">
						-
						{amountToLocal(
							convertNumber(
								calculateInvoiceDiscountAmount({
									...invoice
								} as Required<InvoiceType>)
							)
						)}
					</RightSmallWrapper>
				</FlexRow>
			)}
			<FlexRow
				justify="space-between"
				style={{
					borderBottom: '1px solid black',
					marginTop: '5px',
					width: '100%'
				}}
			>
				<Typography
					variant="body1"
					fontSize={13}
					fontWeight={600}
					style={{ width: '40%', fontFamily: 'Helvetica' }}
				>
					Grand Total
				</Typography>
				<Typography
					variant="body1"
					fontSize={15}
					fontWeight={600}
					style={{
						width: '55%',
						overflowWrap: 'break-word',
						textAlign: 'right',
						fontFamily: 'Helvetica'
					}}
				>
					{`PKR ${amountToLocal(totalAmount)}`}
				</Typography>
			</FlexRow>
			{invoice.ledgerBalance?.amount && (
				<FlexRow
					justify="space-between"
					style={{
						borderBottom: '1px solid black',
						marginTop: '5px',
						width: '100%'
					}}
				>
					<Typography
						variant="body1"
						fontSize={13}
						fontWeight={500}
						style={{ width: '40%', fontFamily: 'Helvetica' }}
					>
						Previous Balance
					</Typography>
					<Typography
						variant="body1"
						fontSize={15}
						fontWeight={600}
						style={{
							width: '55%',
							overflowWrap: 'break-word',
							textAlign: 'right',
							fontFamily: 'Helvetica'
						}}
					>
						{`PKR ${amountToLocal(invoice.ledgerBalance?.amount)}`}
					</Typography>
				</FlexRow>
			)}
			{invoice.paymentMethod !== '' &&
				invoice.paymentMethod !== 'unpaid' && (
					<FlexRow
						justify="space-between"
						style={{
							borderBottom: '1px solid black',
							marginTop: '5px',
							paddingBottom: '5px'
						}}
					>
						<TextWrapper variant="subtitle2">
							Payment Via
						</TextWrapper>
						<RightSmallWrapper variant="subtitle2">
							{pascalCase(invoice.paymentMethod)}
						</RightSmallWrapper>
					</FlexRow>
				)}
			{invoice.comments && (
				<FlexRow
					justify="space-between"
					style={{
						borderBottom: '1px solid black',
						marginTop: '5px',
						paddingBottom: '5px'
					}}
				>
					<Typography
						variant="subtitle2"
						fontSize={10}
						style={{ fontFamily: 'Helvetica' }}
					>
						{invoice.comments}
					</Typography>
				</FlexRow>
			)}
			<Gutter spacing={0.1} />

			<FlexRow justify="center">
				<TextWrapper variant="subtitle2">
					Thank you for shopping with us!
				</TextWrapper>
			</FlexRow>
			<Gutter spacing={0.5} />

			<FlexRow
				justify="space-between"
				style={{
					margin: '5px 0px'
				}}
			>
				<TextWrapper variant="subtitle2" fontStyle={'italic'}>
					Generated using Ordr
				</TextWrapper>
				<TextWrapper variant="subtitle2" fontStyle={'italic'}>
					{`${process.env.REACT_APP_LANDING_URL}`}
				</TextWrapper>
			</FlexRow>
		</Container>
	)
})

ThermalReceipt.displayName = 'ThermalReceipt'
