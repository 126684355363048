import type {
	ContactListPayloadType,
	IContactProps,
	ResponsePageInfo
} from 'typings'

export const SET_CONTACT_LIST = 'SET_CONTACT_LIST'
export const FETCH_CONTACT_LIST = 'FETCH_CONTACT_LIST'
export const RESET_CONTACT_LIST = 'RESET_CONTACT_LIST'
export const SET_CONTACT_LOADING = 'SET_CONTACT_LOADING'
export const SET_CONTACT_PAGE_INFO = 'SET_CONTACT_PAGE_INFO'
export const SET_CONTACT_PAGE_NUMBER = 'SET_CONTACT_PAGE_NUMBER'

interface IFetchContactList {
	type: typeof FETCH_CONTACT_LIST
	payload: ContactListPayloadType
}

interface ISetContactList {
	type: typeof SET_CONTACT_LIST
	payload: IContactProps[]
}

interface ISetPageInfo {
	type: typeof SET_CONTACT_PAGE_INFO
	payload: ResponsePageInfo
}

interface IContactLoading {
	type: typeof SET_CONTACT_LOADING
	payload: boolean
}

interface IResetContactList {
	type: typeof RESET_CONTACT_LIST
}

interface IContactPageNumber {
	type: typeof SET_CONTACT_PAGE_NUMBER
	payload: number
}

export type ContactActionsTypes =
	| IFetchContactList
	| ISetContactList
	| IContactLoading
	| ISetPageInfo
	| IContactPageNumber
	| IResetContactList
