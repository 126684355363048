import React from 'react'
import * as yup from 'yup'
import { Typography, useTheme } from '@mui/material'
import { FlexRow } from 'components/atoms/Flex'
import {
	FieldCapsule,
	FieldImageDropZone,
	Gutter,
	ImagePlaceholder
} from 'components'
import { SUPPLY_CHAIN_POSITION } from 'data'
import { MdOutlineStoreMallDirectory } from 'react-icons/md'
import { RiCloseFill } from 'react-icons/ri'
import { LogInfo } from 'utils/log'
import { useTranslate } from 'hooks'
import { uploadBusinessPic } from 'api'
import { useFormikContext } from 'formik'

export const FormStep4Schema = yup.object().shape({})

type Props = {
	name: string
	handleSubmit: any
	values: any
}

export const Form4: React.ComponentType<Props> = ({ name }) => {
	const theme = useTheme()
	const translate = useTranslate()
	const { values }: any = useFormikContext()

	return (
		<>
			<FieldImageDropZone
				name={'bProfilePic'}
				placeholder={ImagePlaceholder}
				text={values['name']}
				icon={MdOutlineStoreMallDirectory}
				api={uploadBusinessPic}
			/>
			<Gutter spacing={2} />
			<Typography
				variant={'h4'}
				sx={{ color: theme => theme.palette.colors.gray['600'] }}
				fontWeight="medium"
			>
				{translate('onboarding.form.supplyChainPosition')}
			</Typography>
			<Gutter spacing={1.5} />
			<FlexRow
				justify={'center'}
				align={'center'}
				wrap={'wrap'}
				style={{ zIndex: 100 }}
			>
				<FieldCapsule
					renderRight={
						<RiCloseFill
							onClick={() => LogInfo('clok')}
							color={theme.palette.colors.green['700']}
							style={{ marginTop: 5 }}
						/>
					}
					name={name}
					color={'green'}
					datasource={SUPPLY_CHAIN_POSITION}
				/>
			</FlexRow>
		</>
	)
}
