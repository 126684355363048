import React, { useCallback, useMemo, useState } from 'react'
import {
	Button,
	ClickAwayListener,
	Fade,
	Popper,
	styled,
	Typography,
	useTheme
} from '@mui/material'
import PopupState, { bindPopper, bindToggle } from 'material-ui-popup-state'
import { FlexCol, FlexRow } from 'components/atoms/Flex'
import { BiCheck } from 'react-icons/bi'
import { KeyboardArrowDown } from '@mui/icons-material'
import { SETTING_SIDEBAR } from 'data'
import { NavLink, useLocation } from 'react-router-dom'
import analytics from 'utils/segment'
import { useTranslate } from 'hooks'
import { useSelector } from 'react-redux'
import type { RootState } from 'store/index-reducer'
import { useSettings } from 'context/settings'

const Wrapper = styled(FlexCol)`
	background-color: ${({ theme }) => theme.palette.background.default};
	border: 1px solid ${({ theme }) => theme.palette.background.paper};
	border-radius: 8px;
	margin-top: 5px;
	box-shadow: 0 12px 16px -4px rgba(16, 24, 40, 0.08),
		0 4px 6px -2px rgba(16, 24, 40, 0.03);
	overflow: hidden;
	width: 100%;
	min-width: max-content;
`

const StyledButton = styled(Button)`
	color: ${({ theme }) => theme.palette.colors.black['900']};
	border: 1px solid ${({ theme }) => theme.palette.colors.gray['300']};
	justify-content: space-between;
	width: 100%;
	border-radius: 8px;
	padding: 6px 12px;
`

const WrapperDiv = styled('div')`
	width: 100%;
	padding: 7px 16px;
`

const LinkText = styled(FlexRow)`
	padding: 12px 20px;
	align-items: center;
	width: 200px;
	justify-content: space-between;
`

const StyledNavLink = styled(NavLink, {})`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	width: 100%;
	color: ${({ theme }) => theme.palette.colors.gray['600']};
`

type ProfileButtonProps = {}

export const SettingsBar: React.ComponentType<ProfileButtonProps> = () => {
	const theme = useTheme()
	const {
		palette: { colors }
	} = theme
	const { user } = useSelector((state: RootState) => state.user)
	const { business } = useSettings()

	const [activeItem, setActiveItem] = useState<string | undefined>(undefined)

	const location = useLocation()

	const translate = useTranslate()

	const isActiveStyle = useMemo(() => {
		return {
			backgroundColor: theme.palette.colors.green['50']
		}
	}, [theme.palette])

	const currentRole = user?.userRoles.find(f => f.business === business)

	const activeStyle = useCallback(
		(isActive: boolean, path?: string) => {
			return activeItem
				? activeItem === path
					? isActiveStyle
					: {}
				: isActive
				? isActiveStyle
				: {}
		},
		[activeItem, isActiveStyle]
	)

	const screenName = useMemo(() => {
		return SETTING_SIDEBAR(currentRole?.role === 'owner').find(
			item => item.path === location.pathname
		)?.name
	}, [currentRole?.role, location.pathname])

	return (
		<PopupState variant="popper" popupId="demo-popup-popper">
			{popupState => (
				<ClickAwayListener onClickAway={() => popupState.close()}>
					<WrapperDiv>
						<StyledButton {...bindToggle(popupState)}>
							<Typography variant={'caption'}>
								{screenName && translate(screenName)}
							</Typography>

							<KeyboardArrowDown
								sx={{ color: colors.gray['500'] }}
								fontSize="small"
							/>
						</StyledButton>
						<Popper
							transition
							placement="bottom-start"
							{...bindPopper(popupState)}
						>
							{({ TransitionProps }) => (
								<Fade {...TransitionProps} timeout={350}>
									<Wrapper>
										{SETTING_SIDEBAR(
											currentRole?.role === 'admin'
										).map(item => (
											<StyledNavLink
												style={({ isActive }) =>
													activeStyle(
														isActive,
														item.path
													)
												}
												onMouseOver={() => {
													setActiveItem(item.path)
												}}
												onMouseOut={() => {
													setActiveItem(undefined)
												}}
												to={item.path}
												key={item.name}
												onClick={() => {
													analytics.track(
														item.segmentEvent
													)
													popupState.close()
												}}
											>
												<LinkText>
													<Typography
														variant={'caption'}
													>
														{translate(item.name)}
													</Typography>
													{screenName ===
														item.name && (
														<BiCheck
															color={
																colors.green[
																	'600'
																]
															}
														/>
													)}
												</LinkText>
											</StyledNavLink>
										))}
									</Wrapper>
								</Fade>
							)}
						</Popper>
					</WrapperDiv>
				</ClickAwayListener>
			)}
		</PopupState>
	)
}
