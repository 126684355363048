import React, { useState } from 'react'
import { Gutter, StyledModal } from 'components'
import type { ModalProps } from '@mui/material'
import { Typography, useTheme } from '@mui/material'
import { styled } from '@mui/system'
import { FlexCol, FlexRow } from 'components/atoms/Flex'
import Warning from 'assets/alerts/warning.png'
import { useSnackbar } from 'notistack'
import { useAppErrors } from 'hooks'
import { TransparentButton } from 'components/atoms/Button/TransparentButton'
import type { InviteUserProps } from 'typings'
import { updateUserInvite } from 'api/inviteUser'
import { objectPick } from 'helpers'
import analytics from 'utils/segment'
import { SEGMENT_EVENTS } from 'data'

const Container = styled(FlexCol)`
	align-items: flex-end;
	background-color: ${({ theme }) => theme.palette.background.default};
	padding: 20px 40px 20px 30px;
	z-index: 0;
	border-radius: 10px;
`

const Col = styled(FlexCol)`
	min-width: 300px;
	max-width: 400px;
`

const Row = styled(FlexRow)`
	width: 100%;
`

const StyledButton = styled(TransparentButton)`
	background-color: ${({ theme }) => theme.palette.colors.green['500']};

	:hover {
		background-color: ${({ theme }) => theme.palette.colors.green['600']};
	}

	:disabled {
		opacity: 0.5;
	}
`

type Props = { onSuccess?(): void } & Omit<ModalProps, 'children'> &
	InviteUserProps

export const ReactivateModal: React.ComponentType<Props> = ({
	onClose,
	onSuccess,
	...props
}) => {
	const [loading, setLoading] = useState(false)
	const theme = useTheme()
	const { enqueueSnackbar } = useSnackbar()
	const { setAppError } = useAppErrors()
	const { colors } = theme.palette

	const onReactivate = async () => {
		try {
			setLoading(true)
			await updateUserInvite(props.business.id, props.id || '', {
				...objectPick(props, 'phone', 'id', 'businessRole', 'fullName'),
				business: props.business.id,
				activate: true
			})
			analytics.track(
				SEGMENT_EVENTS.SETTINGS_SUCCESS_REACTIVATE_IN_ACTIVE_USER
			)
			enqueueSnackbar('Reactivated Successfully', {
				variant: 'success'
			})
			onSuccess?.()
			onClose?.({}, 'escapeKeyDown')
		} catch (e: any) {
			setAppError(e)
		} finally {
			setLoading(false)
		}
	}

	return (
		<StyledModal {...props}>
			<Container>
				<FlexRow>
					<img src={Warning} alt="warning" />
					<Gutter gap={1} />
					<Col>
						<FlexRow align={'center'}>
							<Typography
								variant="body1"
								fontSize={14}
								fontWeight={500}
							>
								Reactivate User
							</Typography>
						</FlexRow>
						<Gutter spacing={0.5} />
						<Typography
							color={colors.gray['700']}
							fontWeight={500}
							variant={'caption'}
						>
							This user will be able to log in to this account
							again:
						</Typography>
						<Gutter spacing={0.5} />
						<Typography
							color={colors.gray['700']}
							fontWeight={600}
							variant={'caption'}
						>{`${props.fullName} ${
							props.phone.number
								? `(${props.phone.countryCode} ${props.phone.number})`
								: ''
						}`}</Typography>
						<Gutter spacing={0.5} />
						<Typography
							color={colors.gray['700']}
							fontWeight={500}
							variant={'caption'}
						>
							{' '}
							Are you sure you want to reactivate this user?
						</Typography>
						<Gutter />
						<Row justify={'flex-end'}>
							<TransparentButton
								onClick={() => onClose?.({}, 'escapeKeyDown')}
								disabled={loading}
							>
								<Typography
									fontWeight={500}
									variant={'caption'}
									fontSize={14}
								>
									Cancel
								</Typography>
							</TransparentButton>
							<Gutter gap={0.5} />
							<StyledButton
								onClick={onReactivate}
								disabled={loading}
							>
								<Typography
									fontWeight={500}
									variant={'caption'}
									fontSize={14}
									color={colors.white['200']}
								>
									Confirm
								</Typography>
							</StyledButton>
						</Row>
					</Col>
				</FlexRow>
			</Container>
		</StyledModal>
	)
}
