import React from 'react'
import { styled } from '@mui/system'
import { Gutter } from 'components'
import { Header } from 'screen/Quotations/View/Component/Header'
import { BusinessInfo } from 'screen/Quotations/View/Component/BusinessInfo'
import { Cart } from 'screen/Quotations/View/Component/Cart'
import { AmountDetails } from 'screen/Quotations/View/Component/AmountDetails'
import { FlexCol, FlexRow } from 'components/atoms/Flex'
import { Typography, useTheme } from '@mui/material'
import Logo from 'assets/Logo.png'
import type { QuotationType } from 'typings'

const Wrapper = styled('div')`
	padding: 30px;
	width: 100%;
	height: 100%;
	background: #fff;
`

const Grid = styled('div')`
	display: grid;
	grid-template-columns: 2fr 1.3fr;
	grid-gap: 10px;
	margin-bottom: 1rem;
`

const Card = styled(FlexRow)`
	background-color: ${({ theme }) => theme.palette.colors.gray['50']};
	border-radius: 7px;
	padding: 10px;
`

const Row = styled(FlexRow)`
	width: 100%;
`

type Props = {
	title?: string
	currentQuotation: QuotationType
}

export const QuotationPdf = React.forwardRef<HTMLDivElement, Props>(
	({ currentQuotation }, ref) => {
		const theme = useTheme()
		const { colors } = theme.palette

		return (
			<Wrapper ref={ref}>
				<Header currentQuotation={currentQuotation} />
				<Gutter spacing={0.5} />
				<hr />
				<Gutter spacing={1.5} />
				<BusinessInfo currentQuotation={currentQuotation} />
				<Gutter spacing={1.5} />
				<Cart currentQuotation={currentQuotation} />
				<Gutter spacing={1.5} />
				<Grid>
					<FlexRow>
						{currentQuotation.comments && (
							<Card>
								<Typography
									variant={'subtitle2'}
									fontWeight={400}
								>
									{currentQuotation.comments}
								</Typography>
							</Card>
						)}
					</FlexRow>
					<AmountDetails currentQuotation={currentQuotation} />
				</Grid>
				<Gutter spacing={4} />
				<Row>
					<FlexCol>
						<Typography
							variant={'subtitle2'}
							color={colors.green['700']}
						>
							Generated using
						</Typography>
						<Gutter spacing={0.2} />
						<img src={Logo} alt={'logo'} height={30} />
					</FlexCol>
				</Row>
			</Wrapper>
		)
	}
)

QuotationPdf.displayName = 'QuotationPdf'
