import React, { useCallback, useMemo, useState } from 'react'
import { styled } from '@mui/system'
import { motion } from 'framer-motion'
import { NavLink } from 'react-router-dom'
import { useTranslate } from 'hooks'
import { Typography, useTheme } from '@mui/material'
import { Gutter } from 'components'
import analytics from 'utils/segment'
import { SETTING_SIDEBAR } from 'data'
import { useSelector } from 'react-redux'
import type { RootState } from 'store/index-reducer'
import { useSettings } from 'context/settings'

const MainContainer = styled('div')`
	display: flex;
	height: 100%;
`

const Sections = styled('section')`
	display: flex;
	width: 100%;
	flex-direction: column;
	align-items: flex-start;
`

const LinkText = styled(motion.div)`
	white-space: nowrap;
	font-size: 12px;
	padding: 10px 8px;
`

const StyledNavLink = styled(NavLink, {})`
	text-decoration-line: none;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	column-gap: 25px;
	border-radius: 4px;
	row-gap: 10px;
	width: 100%;
	margin: 3px 0;
	color: ${({ theme }) => theme.palette.colors.gray['600']};
`

const MotionDiv = styled('div')(({ theme }) => ({
	color: theme.palette.primary.contrastText,
	display: 'flex',
	flex: 1,
	width: '230px',
	height: '100%',
	alignItems: 'stretch',
	flexDirection: 'column',
	borderRight: `1px solid ${theme.palette.colors.gray['200']}`,
	paddingRight: 10
}))

const SettingSideBar = () => {
	const [activeItem, setActiveItem] = useState<string | undefined>(undefined)
	const translate = useTranslate()
	const theme = useTheme()
	const { user } = useSelector((state: RootState) => state.user)
	const { business } = useSettings()
	const currentRole = user?.userRoles.find(f => f.business === business)

	const isActiveStyle = useMemo(() => {
		return {
			fontWeight: '600',
			backgroundColor: theme.palette.colors.green['25'],
			color: theme.palette.colors.green['900']
		}
	}, [theme.palette])

	const activeStyle = useCallback(
		(isActive: boolean, path?: string) => {
			return activeItem
				? activeItem === path
					? isActiveStyle
					: {}
				: isActive
				? isActiveStyle
				: {}
		},
		[activeItem, isActiveStyle]
	)

	return (
		<MainContainer>
			<MotionDiv>
				<Typography
					variant={'caption'}
					fontWeight={'600'}
					fontSize={20}
					style={{ padding: '10px 8px' }}
				>
					Settings
				</Typography>
				<Gutter spacing={0.1} />
				<Sections>
					{SETTING_SIDEBAR(currentRole?.role === 'admin').map(
						item => (
							<StyledNavLink
								style={({ isActive }) =>
									activeStyle(isActive, item.path)
								}
								onMouseOver={() => {
									setActiveItem(item.path)
								}}
								onMouseOut={() => {
									setActiveItem(undefined)
								}}
								to={item.path}
								key={item.name}
								onClick={() => {
									analytics.track(item.segmentEvent)
								}}
							>
								<LinkText
									initial={'hidden'}
									animate={'show'}
									exit={'hidden'}
								>
									{translate(item.name)}
								</LinkText>
							</StyledNavLink>
						)
					)}
				</Sections>
			</MotionDiv>
		</MainContainer>
	)
}

export { SettingSideBar }
