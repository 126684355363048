import React from 'react'
import { styled } from '@mui/system'
import { Gutter } from './Gutter'
import { StyledModal } from 'components'
import { Button } from 'components/atoms/Button/Button'
import { FlexCol, FlexRow } from 'components/atoms/Flex'
import { Typography } from '@mui/material'
import Delete from 'assets/alerts/delete.png'

const StyledAlertBox = styled(FlexRow)`
	width: auto;
	height: auto;
	border-radius: 8px;
	padding: 20px;
	background-color: ${({ theme }) => theme.palette.background.default};
`

const StyledCancelButton = styled(Button)`
	background-color: ${({ theme }) => theme.palette.background.default};
	width: 90px;
	height: 44px;
	color: ${({ theme }) => theme.palette.colors.gray['700']};
	border: 1px solid ${({ theme }) => theme.palette.colors.gray['300']};
	border-radius: 8px;
	padding: 0;
`
const StyledDeleteButton = styled(StyledCancelButton)`
	background-color: ${({ theme }) => theme.palette.colors.red['600']};
	color: ${({ theme }) => theme.palette.colors.white['100']};
	border: 1px solid;
`

type AlertModalProps = {
	title: string
	message: string
	messageLine2?: string
	open: boolean
	setOpen: (open: boolean) => void
	onDelete: (id?: string) => void
	onCancel?: (id?: string) => void
	deleteItemId?: string
	cancelItemId?: string
}
export const AlertModal: React.ComponentType<AlertModalProps> = ({
	onDelete,
	onCancel,
	deleteItemId,
	cancelItemId,
	open,
	setOpen,
	title,
	message,
	messageLine2
}) => {
	const handleCancel = () => {
		setOpen(false)
		onCancel?.(cancelItemId)
	}
	return (
		<StyledModal
			sx={{
				display: 'flex',
				flexDirection: 'column'
			}}
			open={open}
			onClose={() => setOpen(false)}
		>
			<StyledAlertBox>
				<img
					alt={'delete'}
					src={Delete}
					style={{ width: '50px', height: '50px' }}
				/>
				<Gutter gap={1} />
				<FlexCol style={{ width: '400px' }}>
					<Gutter spacing={0.25} />
					<FlexRow>
						<Typography fontWeight={'medium'} variant="body2">
							{title}
						</Typography>
					</FlexRow>
					<Gutter spacing={0.5} />
					<FlexCol style={{ width: '100%' }}>
						<Typography variant="caption">{message}</Typography>
						{messageLine2 && (
							<>
								<Gutter spacing={1} />
								<Typography variant="caption">
									{messageLine2}
								</Typography>
							</>
						)}
					</FlexCol>
					<Gutter spacing={2.5} />
					<FlexRow
						justify="flex-end"
						align="center"
						style={{ width: '100%' }}
					>
						<FlexRow>
							<StyledCancelButton
								autoFocus
								onClick={handleCancel}
							>
								Cancel
							</StyledCancelButton>
							<Gutter gap={0.25} />
							<StyledDeleteButton
								onClick={() => onDelete(deleteItemId)}
								autoFocus
							>
								Delete
							</StyledDeleteButton>
						</FlexRow>
					</FlexRow>
				</FlexCol>
			</StyledAlertBox>
		</StyledModal>
	)
}
