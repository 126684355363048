import React, { useEffect, useState } from 'react'
import { FlexCol, FlexRow } from 'components/atoms/Flex'
import { styled } from '@mui/system'
import { Button } from 'components'
import { OFTabs } from 'screen/OrderFinancing/OFTabs'
import { useDimension } from 'hooks'
import { useNavigate } from 'react-router-dom'
import { OFHeader } from 'screen/OrderFinancing/OFHeader'
import { WHITELIST } from 'data/whitelist'
import { useSelector } from 'react-redux'
import type { RootState } from 'store/index-reducer'

const Wrapper = styled(FlexCol)`
	padding: 16px;
	width: -webkit-fill-available;
	height: 100%;
	margin: 10px;
	border-radius: 8px;
	border: 1px solid ${({ theme }) => theme.palette.colors.gray['300']}; ;
`

const Header = styled(FlexRow)`
	max-height: 70px;
	width: 100%;
	box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.08);
	padding: 8px 16px;
	justify-content: space-between;
	align-items: center;
`

const Row = styled(FlexCol)`
	padding-right: 20px;
`

const PrimaryButtonWrapper = styled(FlexRow)``

const StyledPrimaryButton = styled(Button)`
	min-width: 0;
	min-height: 32px;
	padding: 0 10px;
	border-radius: 5px;
	color: ${({ theme }) => theme.palette.colors.white['900']};
`

export const OrderFinancing = () => {
	const user = useSelector((state: RootState) => state.user.user)
	const [tab, setTab] = useState(0)
	const navigate = useNavigate()
	const { height } = useDimension()

	useEffect(() => {
		if (!WHITELIST.includes(user?.phone.number || '')) {
			navigate('/home')
		}
	}, [navigate, user?.phone.number])

	return (
		<>
			<OFHeader />

			{/*<Header>
				<FlexRow>
					<Row align={'flex-start'}>
						<Typography
							variant={'caption'}
							color={colors.gray['600']}
						>
							Active Loans
						</Typography>
						<Typography variant={'subtitle2'}>1</Typography>
					</Row>
					<Row align={'flex-start'}>
						<Typography
							variant={'caption'}
							color={colors.gray['600']}
						>
							Applications
						</Typography>
						<Typography variant={'subtitle2'}>1</Typography>
					</Row>
					<Row align={'flex-start'}>
						<Typography
							variant={'caption'}
							color={colors.gray['600']}
						>
							Amount Due
						</Typography>
						<Typography variant={'subtitle2'}>
							PKR 100,000
						</Typography>
					</Row>
					<Row align={'flex-start'}>
						<Typography
							variant={'caption'}
							color={colors.gray['600']}
						>
							Credit Limit Left
						</Typography>
						<Typography variant={'subtitle2'}>
							PKR 900,000
						</Typography>
					</Row>
				</FlexRow>
				<StyledPrimaryButton
					buttonColor={colors['green']['600'] as ColorsCodeType}
					onClick={() =>
						navigate('/ordr-financing/new-finance-application')
					}
				>
					<PrimaryButtonWrapper>
						Apply for Financing
					</PrimaryButtonWrapper>
				</StyledPrimaryButton>
			</Header>*/}

			<Wrapper
				style={{
					maxHeight: height - 140
				}}
			>
				<OFTabs
					onItemSelect={inv => {
						//
					}}
					value={tab}
					setValue={setTab}
				/>
			</Wrapper>
		</>
	)
}
