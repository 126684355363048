import type { FilterButtonDataSourceProps } from 'screen/Invoices/Components/InvoiceFilter'

export const USER_FILTER_OPTIONS: FilterButtonDataSourceProps[] = [
	{
		id: 1,
		label: 'Active Users',
		value: { deleted: ['false'] }
	},
	{
		id: 1,
		label: 'Inactive Users',
		value: {
			deleted: ['true']
		}
	}
]
