import { all } from 'redux-saga/effects'

import authSaga from 'store/auth/saga'
import businessSaga from 'store/business/saga'
import settingsSaga from 'store/settings/saga'
import inventorySaga from 'store/inventory/saga'
import contactSaga from 'store/contact/saga'
import cbfsWatcher from 'store/cbfs/saga'

export default function* () {
	yield all([
		...authSaga,
		...businessSaga,
		...settingsSaga,
		...inventorySaga,
		...contactSaga,
		...cbfsWatcher
	])
}
