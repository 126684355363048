import React from 'react'
import { StyledModal } from 'components/StyledModal'
import type { ModalProps } from '@mui/material'
import { Button, Typography, useTheme } from '@mui/material'
import { styled } from '@mui/system'
import { FlexCol, FlexRow } from 'components/atoms/Flex'
import { Gutter } from 'components/atoms'
import Warning from 'assets/alerts/warning.png'
import { TransparentButton } from 'components/atoms/Button/TransparentButton'

const Container = styled(FlexCol)`
	background-color: ${({ theme }) => theme.palette.background.default};
	padding: 25px;
	border-radius: 10px;
	max-width: 550px;
`

const WrapperButton = styled(Button)(({ theme }) => ({
	backgroundColor: theme.palette.colors.green['500'],
	fontWeight: 600,
	color: theme.palette.colors.white['900'],
	borderRadius: '7px',

	'&:hover': {
		background: theme.palette.colors.green['700']
	}
}))

type WarningModalProps = {
	onCancel: () => void
} & Omit<ModalProps, 'children'>

export const EditWarningModal: React.ComponentType<WarningModalProps> = ({
	onCancel,
	...props
}) => {
	const theme = useTheme()
	const { colors } = theme.palette

	return (
		<StyledModal {...props}>
			<Container>
				<FlexRow align={'flex-start'}>
					<img
						alt={'warning'}
						src={Warning}
						style={{ width: '56px', height: '56px' }}
					/>
					<Gutter gap={1} />
					<FlexCol style={{ flex: 1 }}>
						<Typography
							variant={'h6'}
							fontWeight={600}
							fontFamily={'Inter'}
						>
							Cannot Publish Incomplete Invoice
						</Typography>
						<Gutter spacing={0.5} />
						<Typography
							variant={'body2'}
							color={colors.gray['500']}
							fontWeight={500}
						>
							The invoice cannot be published because some
							mandatory information is missing. Please ensure the
							following information is provided before publishing:
						</Typography>
						<Gutter spacing={1} />
						<Typography
							variant={'body2'}
							color={colors.gray['500']}
							fontWeight={500}
						>
							Item Details: Please ensure that all items in the
							invoice have complete details, including the selling
							price and quantity.
						</Typography>
						<Gutter spacing={2} />
						<FlexRow
							align={'center'}
							style={{ alignSelf: 'flex-end' }}
						>
							<TransparentButton onClick={onCancel}>
								Close
							</TransparentButton>
							<Gutter gap={0.5} />
							<WrapperButton
								onClick={() =>
									props.onClose?.({}, 'escapeKeyDown')
								}
							>
								Edit Invoice
							</WrapperButton>
						</FlexRow>
					</FlexCol>
				</FlexRow>
			</Container>
		</StyledModal>
	)
}
