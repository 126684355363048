import {
	type AuthActionsTypes,
	LOGOUT,
	RESTORE_STATE,
	RESTORE_STATE_DONE
} from 'store/auth/types'

export const restoreState = (): AuthActionsTypes => ({
	type: RESTORE_STATE
})

export const restoreStateDone = (): AuthActionsTypes => ({
	type: RESTORE_STATE_DONE
})

export const logout = (): AuthActionsTypes => ({
	type: LOGOUT
})
