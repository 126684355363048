import React, { createContext, useContext, useEffect, useState } from 'react'
import Pusher from 'pusher-js'

interface PusherContextProps {
	pusher: Pusher | undefined
}

const PusherContext = createContext<PusherContextProps>({
	pusher: undefined
})

const PusherProvider = ({ children }: any) => {
	const [pusher, setPusher] = useState<Pusher | undefined>(undefined)

	useEffect(() => {
		const pusherInstance = new Pusher(
			process.env.REACT_APP_PUSHER_APP_ID || '',
			{
				cluster: 'ap1'
			}
		)

		setPusher(pusherInstance)

		return () => {
			pusherInstance.disconnect()
		}
	}, [])

	return (
		<PusherContext.Provider value={{ pusher }}>
			{children}
		</PusherContext.Provider>
	)
}

const usePusher = (): PusherContextProps => {
	return useContext(PusherContext)
}

export { PusherProvider, usePusher }
