import React from 'react'
import { type ModalProps, Typography } from '@mui/material'
import { Button, FieldTextInput, Gutter } from 'components'
import { FlexCol, FlexRow } from 'components/atoms/Flex'
import { styled } from '@mui/system'
import { Formik } from 'formik'
import { useSettings } from 'context/settings'
import {
	CapsuleGroup,
	OrderContact,
	OrderTable,
	SocialChannel
} from 'screen/OrderCenter/Components'
import type { OrderItemTypes } from '../../../typings/orderCenter'
import * as Yup from 'yup'
import { pascalCase } from 'helpers'
import type { CreateOrderType } from 'typings'
import { createNewOrder, updateOrder } from 'api/orderCenter'
import { useAppErrors } from 'hooks/useAppErrors'
import { useTranslate } from 'hooks'
import { useSnackbar } from 'notistack'
import { StyledModal } from 'components/StyledModal'
import { IoIosClose } from 'react-icons/io'
import { detailedDiff } from 'deep-object-diff'
import { objectOmit, objectPick } from 'helpers/object'
import analytics from 'utils/segment'
import { ORDER_INITIAL_VALUE, SEGMENT_EVENTS } from 'data'
import moment from 'moment'

const Wrapper = styled(FlexCol)`
	position: relative;
	padding: 20px 30px;
	background-color: ${({ theme }) => theme.palette.background.default};
	border-radius: 10px;
`

export const TextInputWrapper = styled(FieldTextInput)`
	width: 270px;
`

const Row = styled(FlexRow)`
	width: 100%;
	align-items: center;
	justify-content: space-between;
`

const MainContainer = styled('div')`
	display: grid;
	width: 100%;
	max-width: 1000px;
	grid-template-columns: 3fr 1fr;
	gap: 10px;
`

const SubmitButton = styled(Button)`
	position: absolute;
	bottom: 20px;
	right: 30px;

	padding: 6px 10px;
`

const CloseButtonWrapper = styled(FlexCol)`
	justify-content: center;
	align-items: center;
	height: 30px;
	width: 30px;
	right: 1.75em;
	&:hover {
		cursor: pointer;
		border-radius: 25%;
		background-color: ${({ theme }) => theme.palette.colors.gray['100']};
		display: flex;
	}
`

export const validationSchema = Yup.object().shape({
	contact: Yup.object().shape({
		name: Yup.string().required('Required')
	}),
	items: Yup.array()
		.of(
			Yup.object().shape({
				quantity: Yup.number()
					.positive('Error')
					.integer()
					.min(1, 'Required')
					.test('maxQuantity', (quantity, values) => {
						const { parent } = values
						const maxQuantity = parent?.maxQuantity

						if ((quantity || 0) > maxQuantity) {
							return values.createError({
								path: values.path,
								message: `${maxQuantity} allowed`
							})
						}
						return true
					})
					.required('Required')
					.typeError('Required')
			})
		)
		.min(1, 'min 1 item is required')
		.max(50, 'Cannot add more than 50 items in one order')
		.required('min 1 item is required')
})

type NewOrderModalProps = {
	initialValue?: CreateOrderType
	onSuccess?(): void
} & Omit<ModalProps, 'children'>

export const NewOrderModal: React.ComponentType<NewOrderModalProps> = ({
	initialValue,
	onSuccess,
	...props
}) => {
	const { business } = useSettings()
	const { setAppError } = useAppErrors()
	const { enqueueSnackbar } = useSnackbar()
	const translate = useTranslate()

	const onCreate = async (value: CreateOrderType, actions: any) => {
		actions.setSubmitting(true)
		try {
			await createNewOrder(value)
			enqueueSnackbar(translate('snackbar.created'), {
				variant: 'success'
			})
			analytics.track(SEGMENT_EVENTS.COMPLETE_NEW_ORDER_INQUIRY)
			onSuccess?.()
		} catch (error: any) {
			setAppError(error, actions)
		} finally {
			actions.setSubmitting(false)
		}
	}

	const onUpdate = async (values: CreateOrderType, actions: any) => {
		try {
			actions.setSubmitting(true)

			const res1: Partial<CreateOrderType> = detailedDiff(
				objectOmit(initialValue, 'items'),
				objectOmit(values, 'items')
			).updated

			await updateOrder(initialValue?.id || '', {
				...res1,
				...objectPick(values, 'items')
			})
			analytics.track(SEGMENT_EVENTS.COMPLETE_NEW_ORDER_INQUIRY)
			enqueueSnackbar(translate('snackbar.successUpdate'), {
				variant: 'success'
			})
			onSuccess?.()
		} catch (e: any) {
			setAppError(e, actions)
		} finally {
			actions.setSubmitting(false)
		}
	}

	const onSubmit = (value: CreateOrderType, actions: any) => {
		if (initialValue) {
			onUpdate(value, actions)
		} else {
			onCreate(value, actions)
		}
	}

	return (
		<StyledModal
			sx={{
				display: 'flex',
				flexDirection: 'column'
			}}
			{...props}
		>
			<Wrapper>
				<Row>
					<Typography fontWeight={500}>
						Record new Inquiry received from Customer
					</Typography>
					<CloseButtonWrapper>
						<IoIosClose
							size={25}
							onClick={() => props.onClose?.({}, 'escapeKeyDown')}
						/>
					</CloseButtonWrapper>
				</Row>
				<Gutter />
				<Formik
					initialValues={
						initialValue || {
							...ORDER_INITIAL_VALUE,
							business,
							initiationAt: moment.utc(moment()).format()
						}
					}
					validationSchema={validationSchema}
					onSubmit={(values, action) => {
						values.items.forEach(
							(item: OrderItemTypes, index: number) => {
								values.items[index] = objectOmit(
									item,
									'id',
									'isFrontendKey'
								)
							}
						)
						onSubmit(values as CreateOrderType, action)
					}}
				>
					{({
						values,
						handleSubmit,
						isSubmitting,
						dirty,
						isValid
					}) => (
						<>
							<CapsuleGroup
								name={'type'}
								disabled={!!initialValue}
							/>
							<MainContainer>
								<FlexCol>
									<Gutter />
									<OrderContact
										name={'contact'}
										type={values.type}
									/>
									<Gutter />
									<OrderTable
										name={'items'}
										type={values.type}
									/>
								</FlexCol>
								<FlexCol>
									<Gutter />
									<SocialChannel name={'channel'} />
									<Gutter />
									<TextInputWrapper
										multiline
										rows={4}
										name="comments"
										placeholder="Comments"
										size="small"
										onClick={() => {
											analytics.track(
												SEGMENT_EVENTS.ORDER_COMMENT
											)
										}}
									/>
									<Gutter spacing={3} />

									<SubmitButton
										mode={'primary'}
										style={{ padding: '6px 10px' }}
										onClick={() => handleSubmit()}
										disabled={
											!dirty || isSubmitting || !isValid
										}
									>
										Save {pascalCase(values.type)}
									</SubmitButton>
								</FlexCol>
							</MainContainer>
						</>
					)}
				</Formik>
			</Wrapper>
		</StyledModal>
	)
}
