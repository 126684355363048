import React from 'react'
import { Avatar, Gutter } from 'components'
import { FlexCol, FlexRow } from 'components/atoms/Flex'
import { Typography, useTheme } from '@mui/material'
import { truncate } from 'helpers'
import { styled } from '@mui/system'
import type { ColorsCodeType, InvoiceType } from 'typings'

const Col = styled(FlexCol)`
	padding: 1px 0;
	// flex: 1;
	align-items: flex-start;
	margin-right: 10px;
`

type UserProfileProps = Pick<InvoiceType, 'contact'> & {
	children?: React.ReactNode
}

export const UserProfile: React.ComponentType<UserProfileProps> = ({
	contact,
	children
}) => {
	const theme = useTheme()
	const {
		palette: { colors }
	} = theme

	return (
		<FlexRow style={{ flex: 1 }} align={'center'} justify={'flex-start'}>
			<Avatar
				src={contact.profilePic}
				borderWidth={2}
				borderColor={colors.green['100'] as ColorsCodeType}
				sx={{ width: 36, height: 36 }}
			/>
			<Gutter gap={0.6} />
			<FlexRow align={'flex-start'}>
				<Col>
					<Typography variant={'body2'}>
						{truncate(contact.name, 14)}
					</Typography>
					<Typography
						variant={'caption'}
						fontSize={12}
						color={colors.gray['500']}
					>
						{contact.businessRelationship
							.toString()
							.replace(',', ', ')}
					</Typography>
				</Col>
				{children}
			</FlexRow>
		</FlexRow>
	)
}
