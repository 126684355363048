import {
	type BusinessActionTypes,
	FETCH_ALL_BUSINESS,
	FETCH_ALL_BUSINESS_ERROR,
	FETCH_ALL_BUSINESS_SUCCESS,
	SET_BUSINESS_LOADING,
	SET_BUSINESS_TERMS_CONDITION
} from './types'
import type { IBusiness } from 'typings/business'
import type { IAPIError } from 'typings'

export const fetchAllBusiness = (): BusinessActionTypes => ({
	type: FETCH_ALL_BUSINESS
})

export const fetchAllBusinessSuccess = (
	payload: IBusiness[]
): BusinessActionTypes => ({
	type: FETCH_ALL_BUSINESS_SUCCESS,
	payload
})

export const businessError = (payload: IAPIError[]): BusinessActionTypes => ({
	type: FETCH_ALL_BUSINESS_ERROR,
	payload
})

export const setBusinessLoading = (loading: boolean): BusinessActionTypes => ({
	type: SET_BUSINESS_LOADING,
	payload: loading
})

export const updateBusinessTerms = (
	businessId: string,
	terms: string
): BusinessActionTypes => ({
	type: SET_BUSINESS_TERMS_CONDITION,
	businessId,
	terms
})
