import { useSettings } from 'context/settings'
import React from 'react'
import { createTheme } from '@mui/material/styles'
import { getDesignTokens } from 'theme'
import { Provider as StateProvider } from 'react-redux'
import { CssBaseline, responsiveFontSizes, ThemeProvider } from '@mui/material'
import * as locales from 'locales'
import { store } from 'store'
import AppRoutes from './routes'
import { SnackBarStackWrapper } from 'components'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'

const Root = () => {
	const { theme, locale, direction } = useSettings()

	const themeMode = React.useMemo(
		() => createTheme(getDesignTokens(theme), locales[locale]),
		[theme, locale]
	)

	return (
		<StateProvider store={store}>
			<ThemeProvider
				theme={responsiveFontSizes(themeMode, {
					breakpoints: ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'],
					factor: 5
				})}
			>
				<LocalizationProvider dateAdapter={AdapterMoment}>
					<div dir={direction}>
						<SnackBarStackWrapper>
							<CssBaseline />
							<AppRoutes />
						</SnackBarStackWrapper>
					</div>
				</LocalizationProvider>
			</ThemeProvider>
		</StateProvider>
	)
}

export { Root }
