import { type AppActionsTypes, SET_NAVIGATION } from 'store/app/types'
import type { NavigationType, RightMenuType } from 'store/app/index'

export const setNavigation = (
	payload?: NavigationType[],
	rightMenu?: RightMenuType
): AppActionsTypes => ({
	type: SET_NAVIGATION,
	payload,
	rightMenu
})
