import React from 'react'
import type { InvoiceSettingsType, InvoiceType } from 'typings'
import { styled } from '@mui/system'
import { Gutter } from 'components'
import { Header } from 'screen/Invoices/View/Component/Header'
import { BusinessInfo } from 'screen/Invoices/View/Component/BusinessInfo'
import { Cart } from 'screen/Invoices/View/Component/Cart'
import { AmountDetails } from 'screen/Invoices/View/Component/AmountDetails'
import { FlexCol, FlexRow } from 'components/atoms/Flex'
import { Typography, useTheme } from '@mui/material'
import Logo from 'assets/Logo.png'
import type { LoadsheetType } from 'typings/loadsheet'
import { LoadSheetHeader } from './Header'
import { Summary } from './Summary'
import { StockTable } from './StockTable'
import { InvoiceTable } from './InvoicesTable'
import { NotesAmountBox } from './NotesAmountBox'

const Wrapper = styled('div')`
	padding: 30px;
	width: 100%;
	height: 100%;
	background: #fff;
`

const Grid = styled('div')`
	display: grid;
	grid-template-columns: 2fr 1.3fr;
	grid-gap: 10px;
	margin-bottom: 1rem;
`

const Card = styled(FlexRow)`
	background-color: ${({ theme }) => theme.palette.colors.gray['50']};
	border-radius: 7px;
	padding: 10px;
`

type Props = {
	title?: string
	loadSheet: LoadsheetType
}

export const LoadSheetPdf = React.forwardRef<HTMLDivElement, Props>(
	({ loadSheet }, ref) => {
		const theme = useTheme()
		const { colors } = theme.palette

		return (
			<Wrapper ref={ref}>
				<LoadSheetHeader
					businessInfo={loadSheet?.businessInfo}
					id={loadSheet?.friendlyId || ''}
					date={loadSheet?.dispatchDate}
				/>
				<Summary loadSheet={loadSheet} />
				<StockTable loadSheet={loadSheet} />
				{loadSheet?.metaData?.config?.showInvoiceSummary && (
					<InvoiceTable loadSheet={loadSheet} />
				)}
				<NotesAmountBox loadSheet={loadSheet} />
			</Wrapper>
		)
	}
)

LoadSheetPdf.displayName = 'LoadSheetPdf'
