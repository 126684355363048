import {
	MdAutoGraph,
	MdCalculate,
	MdHelp,
	MdHelpOutline,
	MdInventory2,
	MdNewReleases,
	MdOutlineAutoGraph,
	MdOutlineCalculate,
	MdOutlineInventory2,
	MdOutlineNewReleases,
	MdOutlinePersonOutline,
	MdOutlineReceipt,
	MdOutlineSettings,
	MdOutlineShoppingCart,
	MdPerson,
	MdReceipt,
	MdSettings,
	MdShoppingCart
} from 'react-icons/md'
import { IoIosPeople, IoMdHome } from 'react-icons/io'
import type { IconType } from 'react-icons'
import { SEGMENT_EVENTS } from 'data'
import { HiOutlineTruck, HiTruck } from 'react-icons/hi'
import { VscHome } from 'react-icons/vsc'

type NameProps =
	| 'invoices'
	| 'quotation'
	| 'inventory'
	| 'analytics'
	| 'ordercenter'
	| 'settings'
	| 'contacts'
	| 'orderbookers'
	| 'help'
	| 'profile.profile'
	| 'whatsNew'
	| 'loadSheet'
	| 'orderFinancing'
	| 'home'

export type SideBarDataSourcesProps = {
	path: string
	name: NameProps
	icons: IconType
	selectedIcons: IconType
	segmentEvent: string
	activePath: string
}

type SidebarArr = {
	path: string
	name:
		| 'privacy'
		| 'inventory'
		| 'taxes'
		| 'orderForm'
		| 'userRoles'
		| 'invoice'

	segmentEvent: string
}

export const SETTING_SIDEBAR = (isAdmin: boolean): SidebarArr[] => {
	let arr: SidebarArr[] = [
		{
			path: '/settings/inventory',
			name: 'inventory',
			segmentEvent: SEGMENT_EVENTS.SETTINGS_INVENTORY
		},
		{
			path: '/settings/taxes',
			name: 'taxes',
			segmentEvent: SEGMENT_EVENTS.SETTINGS_TAXES
		},
		{
			path: '/settings/invoice',
			name: 'invoice',
			segmentEvent: SEGMENT_EVENTS.SETTINGS_INVOICE
		},
		{
			path: '/settings/OrderForm',
			name: 'orderForm',
			segmentEvent: SEGMENT_EVENTS.SETTINGS_ORDER_FORM
		}
	]
	if (!isAdmin) {
		arr = [
			...arr,
			{
				path: '/settings/userRoles',
				name: 'userRoles',
				segmentEvent: SEGMENT_EVENTS.SETTINGS_USER_AND_ROLES
			}
		]
	}
	return arr
}

export const SIDEBAR_DATASOURCE: SideBarDataSourcesProps[] = [
	{
		path: '/home',
		name: 'home',
		icons: VscHome,
		selectedIcons: IoMdHome,
		segmentEvent: SEGMENT_EVENTS.SIDEBAR_ORDER,
		activePath: '/home'
	},
	{
		path: '/orders',
		name: 'ordercenter',
		icons: MdOutlineShoppingCart,
		selectedIcons: MdShoppingCart,
		segmentEvent: SEGMENT_EVENTS.SIDEBAR_ORDER,
		activePath: '/orders'
	},
	{
		path: '/orderbookers',
		name: 'orderbookers',
		icons: IoIosPeople,
		segmentEvent: SEGMENT_EVENTS.SIDEBAR_ORDER_BOOKERS,
		selectedIcons: IoIosPeople,
		activePath: '/orderbookers'
	},
	{
		path: '/contacts',
		name: 'contacts',
		icons: MdOutlinePersonOutline,
		selectedIcons: MdPerson,
		segmentEvent: SEGMENT_EVENTS.SIDEBAR_CONTACTS,
		activePath: '/contacts'
	},
	{
		path: '/Inventory',
		name: 'inventory',
		icons: MdOutlineInventory2,
		selectedIcons: MdInventory2,
		segmentEvent: SEGMENT_EVENTS.SIDEBAR_INVENTORY,
		activePath: '/Inventory'
	},
	{
		path: '/quotation',
		name: 'quotation',
		icons: MdOutlineCalculate,
		selectedIcons: MdCalculate,
		segmentEvent: SEGMENT_EVENTS.SIDEBAR_QUOTATION,
		activePath: '/quotation'
	},
	{
		path: '/invoices',
		name: 'invoices',
		icons: MdOutlineReceipt,
		selectedIcons: MdReceipt,
		segmentEvent: SEGMENT_EVENTS.SIDEBAR_INVOICE,
		activePath: '/invoice'
	},
	{
		path: '/load-sheet',
		name: 'loadSheet',
		icons: HiOutlineTruck,
		selectedIcons: HiTruck,
		segmentEvent: SEGMENT_EVENTS.SIDEBAR_LOAD_SHEET,
		activePath: '/load-sheet'
	},
	{
		path: '/analytics',
		name: 'analytics',
		icons: MdAutoGraph,
		selectedIcons: MdOutlineAutoGraph,
		segmentEvent: SEGMENT_EVENTS.SIDEBAR_ANALYTICS,
		activePath: '/analytics'
	}
]

export const SIDEBAR_FOOTER_DATASOURCE: SideBarDataSourcesProps[] = [
	{
		path: '/whats-new',
		name: 'whatsNew',
		icons: MdOutlineNewReleases,
		selectedIcons: MdNewReleases,
		segmentEvent: SEGMENT_EVENTS.SIDEBAR_WHATS_NEW,
		activePath: '/whats-new'
	},
	/*{
		path: '/ordr-financing',
		name: 'orderFinancing',
		icons: GiGreekTemple,
		selectedIcons: GiGreekTemple,
		segmentEvent: SEGMENT_EVENTS.SIDEBAR_WHATS_NEW,
		activePath: '/ordr-financing'
	},*/
	{
		path: '/help',
		name: 'help',
		icons: MdHelpOutline,
		selectedIcons: MdHelp,
		segmentEvent: SEGMENT_EVENTS.SIDEBAR_HELP,
		activePath: '/help'
	},
	{
		path: '/settings/inventory',
		name: 'settings',
		icons: MdOutlineSettings,
		selectedIcons: MdSettings,
		segmentEvent: SEGMENT_EVENTS.SIDEBAR_SETTING,
		activePath: '/settings'
	}
]

export const SIDEBAR_PROFILE: SideBarDataSourcesProps = {
	path: '/profile',
	name: 'profile.profile',
	icons: MdOutlineSettings,
	selectedIcons: MdSettings,
	segmentEvent: SEGMENT_EVENTS.SIDEBAR_PROFILE,
	activePath: '/profile'
}
