import React, { useCallback } from 'react'
import { styled } from '@mui/material'
import { FlexRow } from 'components/atoms/Flex'
import { Capsule, Gutter } from 'components'
import type { TContactTabsProps } from 'data'
import { CONTACTS_TABS } from 'data'
import { useField } from 'formik'
import type { ContactInitialStateProps } from 'screen/Contact/AddContact/CreateContactModal'
import {
	BankingInformationTab,
	CnicTab,
	CommentsTab,
	OpeningBalance
} from 'screen/Contact/AddContact/Tabs'

const StyledCapsule = styled(Capsule)<{ isSelected: boolean }>`
	padding: 3px 10px;
	border-radius: 15px;
	background-color: ${({ isSelected, theme }) =>
		isSelected ? theme.palette.colors['green']['50'] : 'transparent'};
	margin: 5px 10px 5px 0;
`

type ContactTabsProps = {
	name: string
	values: ContactInitialStateProps
	isUser?: boolean
}

export const ContactTabs: React.ComponentType<ContactTabsProps> = ({
	name,
	values,
	isUser
}) => {
	const [, meta, helpers] = useField(name as any)

	const RenderTab = useCallback(
		({ value, name }: TContactTabsProps) => {
			return (
				<StyledCapsule
					isSelected={meta.value === value}
					color={'green'}
					name={name}
					value={value}
					onItemClick={title => helpers.setValue(title)}
				/>
			)
		},
		[meta.value, helpers]
	)

	return (
		<>
			<FlexRow>
				{CONTACTS_TABS.map(tab => (
					<RenderTab key={tab.id} {...tab} />
				))}
			</FlexRow>
			<Gutter />
			{values.selectedTab === 'openingBalance' && (
				<OpeningBalance isUser={isUser} {...values} />
			)}
			{values.selectedTab === 'cnic' && <CnicTab />}
			{values.selectedTab === 'bankingInformation' && (
				<BankingInformationTab {...values} />
			)}
			{values.selectedTab === 'comments' && <CommentsTab />}
		</>
	)
}
