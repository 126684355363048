import React, { useCallback, useEffect, useRef, useState } from 'react'
import { type FieldHelperProps, useField, useFormikContext } from 'formik'
import { styled } from '@mui/system'
import {
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Tooltip,
	tooltipClasses,
	type TooltipProps,
	Typography,
	useTheme
} from '@mui/material'
import type { ICart } from 'typings/orderCenter'
import {
	AntSwitch,
	Button,
	ErrorText,
	FieldCurrencyInput,
	FieldMultipleLineInput,
	FieldNumberInput,
	FormatAmount,
	Gutter
} from 'components'
import { FlexCol, FlexRow } from 'components/atoms/Flex'
import { AiOutlinePlus } from 'react-icons/ai'
import { SelectInventoryModal } from 'components/app'
import type { InventoryItemType } from 'typings'
import { objectFindKey, objectOmit, objectPick } from 'helpers/object'
import { RiDeleteBinLine } from 'react-icons/ri'
import { Abbr } from 'components/atoms/Abbr'
import analytics from 'utils/segment'
import { SCROLLBAR_STYLE, SEGMENT_EVENTS } from 'data'
import { POSITIVE_NUMBER } from 'helpers'
import { MdOutlineNote } from 'react-icons/md'

const StyledTableRow = styled(TableRow)`
	background-color: ${({ theme }) => theme.palette.background.default};

	:hover {
		background-color: ${({ theme }) => theme.palette.colors.blue['100']};
	}
`

const StyledPaper = styled(Paper)`
	width: 100%;
	height: 100%;
	overflow: hidden;
	position: relative;
	z-index: 0;
`

const TableWrapper = styled(TableContainer)`
	background-color: ${({ theme }) => theme.palette.background.default};
	max-height: 100%;
	min-height: 200px;
	padding-bottom: 50px;
	${SCROLLBAR_STYLE}
`

const StyledButton = styled(Button)`
	position: absolute;
	bottom: 2px;
	left: 2px;
	padding: 5px 10px;
	border-radius: 7px;
	background-color: ${({ theme }) => theme.palette.background.default};
	border: 1px solid ${({ theme }) => theme.palette.colors['gray']['200']};

	:hover {
		background-color: ${({ theme }) => theme.palette.background.default};
	}
`

const DescriptionWrapper = styled(FlexRow)`
	margin-top: 5px;
	padding: 2px 8px;
	background-color: ${({ theme }) => theme.palette.colors['green']['50']};
	border-radius: 7px;
	align-items: center;
	justify-content: center;

	:hover {
		cursor: pointer;
	}
`

const Cell = styled(TableCell)`
	vertical-align: top;
`

const DeleteButton = styled(RiDeleteBinLine)`
	color: ${({ theme }) => theme.palette.colors.rose['500']};

	:hover {
		cursor: pointer;
	}
`

const Box = styled('div')`
	display: grid;
	grid-template-columns: 1fr auto;
	column-gap: 12px;
	align-items: center;
	margin-top: 20px;
	width: 100%;
	padding: 10px;
	background-color: ${({ theme }) => theme.palette.colors.gray['100']};
	border-radius: 7px;
`

const Row = styled(FlexRow)`
	min-height: 43px;
	align-items: center;
`

const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
	<Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
	[`& .${tooltipClasses.arrow}`]: {
		color: theme.palette.common.black
	},
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: theme.palette.common.black,
		maxWidth: 420
	}
}))

type ItemProps = {
	id: string
	name: string
	stockId: string
	code: string
	unit: string
	quantity: number
	perItemCostPrice: number
	perItemSellPrice: number
}

type SelectItemsProps = {
	name: string
	values: any
}

export const SelectItems: React.ComponentType<SelectItemsProps> = ({
	name,
	values
}) => {
	const [dummyState, setDummyState] = useState(false)
	const [isModalOpen, setIsModalOpen] = useState(false)
	const [, meta, helpers] = useField(name)
	const { setFieldValue } = useFormikContext()
	const ref = useRef<HTMLDivElement>(null)
	const theme = useTheme()
	const { colors } = theme.palette

	const onInventorySelect = (inventory: InventoryItemType) => {
		const item = inventory.stock[0]
		const exist = meta.value.find(
			(f: ItemProps) => f.stockId === item.stockId
		)

		if (exist) {
			helpers.setValue(
				meta.value.filter((f: ItemProps) => f.stockId !== item.stockId)
			)
		} else {
			if (inventory.stock.length > 0) {
				helpers.setValue([
					...meta.value,
					{
						...objectOmit(
							inventory.stock[0],
							'costCurrency',
							'lastStockedAt',
							'sellCurrency',
							'id'
						),
						...objectPick(inventory, 'name'),
						id: new Date().toISOString(),
						isFrontendKey: true,
						quantity: 0
					}
				])
			}
		}
	}

	useEffect(() => {
		setDummyState(true)
	}, [])

	const RenderRow = useCallback(
		({
			row,
			index,
			value,
			helpers
		}: {
			row: ICart
			index: number
			value: ItemProps[]
			helpers: FieldHelperProps<any>
		}) => {
			return (
				<StyledTableRow hover role="checkbox" tabIndex={-1}>
					<Cell>
						<Row>
							<Abbr title={row.code} length={5} />
						</Row>
					</Cell>
					<Cell>
						<FlexCol>
							<Row>
								<Abbr title={row.name} length={40} />
							</Row>
							{!objectFindKey(row, 'description') && (
								<DescriptionWrapper
									onClick={() =>
										setFieldValue(`items[${index}]`, {
											...row,
											description: ''
										})
									}
								>
									<MdOutlineNote color={colors.gray['700']} />
									<Gutter gap={0.2} />
									<Typography
										variant={'caption'}
										color={colors.gray['700']}
									>
										Add Description
									</Typography>
								</DescriptionWrapper>
							)}
							{objectFindKey(row, 'description') && (
								<Box>
									<FieldMultipleLineInput
										multiline
										rows={3}
										name={`items[${index}].description`}
										placeholder={'Description'}
									/>
									<DeleteButton
										size={25}
										onClick={() => {
											setFieldValue(
												`items[${index}]`,
												objectOmit(row, 'description')
											)
										}}
									/>
								</Box>
							)}
						</FlexCol>
					</Cell>
					<Cell>
						<Row>
							{values.includeCostPrice && row.perItemCostPrice}
						</Row>
					</Cell>
					<Cell>
						<Row>
							<Abbr title={row.unit} length={10} />
						</Row>
					</Cell>
					<Cell>
						<FieldCurrencyInput
							name={`items[${index}].perItemSellPrice`}
							size={'small'}
							sx={{ width: '8ch' }}
							type={'tel'}
						/>
					</Cell>
					<Cell>
						<FieldNumberInput
							name={`items[${index}].quantity`}
							size={'small'}
							sx={{ width: '8ch' }}
							type={'tel'}
							regex={POSITIVE_NUMBER}
						/>
					</Cell>
					<Cell>
						<Row>
							<FormatAmount
								variant={'body2'}
								limit={15}
								amount={
									parseInt(
										(row.perItemSellPrice || 0).toString()
									) * parseInt((row.quantity || 0).toString())
								}
								style={{ color: 'black' }}
							/>
						</Row>
					</Cell>
					<Cell width={30}>
						<DeleteButton
							onClick={() => {
								helpers.setValue(
									value.filter(
										(f: ItemProps) =>
											!(
												f.stockId === row.stockId &&
												f.unit === row.unit
											)
									)
								)
							}}
						/>
					</Cell>
				</StyledTableRow>
			)
		},
		[colors.gray, setFieldValue, values.includeCostPrice]
	)

	return (
		<>
			<StyledPaper ref={ref}>
				<TableWrapper>
					<Table stickyHeader aria-label="sticky table">
						<TableHead>
							<StyledTableRow>
								<TableCell>
									<Typography variant={'subtitle2'}>
										Code
									</Typography>
								</TableCell>
								<TableCell>
									<Typography variant={'subtitle2'}>
										Items
									</Typography>
								</TableCell>
								<TableCell>
									<BootstrapTooltip
										title={
											'Cost Price is never included in the quotations. This is only for your reference.'
										}
									>
										<FlexRow align="center">
											Cost Price
											<Gutter gap={0.4} />
											<AntSwitch
												checked={
													values.includeCostPrice
												}
												onClick={() => {
													analytics.track(
														SEGMENT_EVENTS.TOGGLE_QUO_COST_PRICE,
														{
															set_value:
																!values.includeCostPrice
														}
													)
													setFieldValue(
														'includeCostPrice',
														!values.includeCostPrice
													)
												}}
											/>
										</FlexRow>
									</BootstrapTooltip>
								</TableCell>
								<TableCell>
									<Typography variant={'subtitle2'}>
										Unit
									</Typography>
								</TableCell>
								<TableCell>
									<Typography variant={'subtitle2'}>
										Selling Price
									</Typography>
								</TableCell>
								<TableCell>
									<Typography variant={'subtitle2'}>
										Quantity
									</Typography>
								</TableCell>
								<TableCell>
									<Typography variant={'subtitle2'}>
										Line Total
									</Typography>
								</TableCell>
								<TableCell width={30} />
							</StyledTableRow>
						</TableHead>
						<TableBody>
							{meta.value.map((row: ICart, index: number) => (
								<RenderRow
									key={row.id}
									row={row}
									index={index}
									value={meta.value}
									helpers={helpers}
								/>
							))}
						</TableBody>
					</Table>
				</TableWrapper>
				<StyledButton
					onClick={() => {
						analytics.track(SEGMENT_EVENTS.QUO_ADD_ITEMS)
						setIsModalOpen(true)
					}}
				>
					<FlexRow justify={'center'} align={'center'}>
						<AiOutlinePlus color={'black'} />
						<Gutter gap={0.3} />
						<Typography color={'black'} variant={'caption'}>
							Add Items
						</Typography>
					</FlexRow>
				</StyledButton>
			</StyledPaper>
			{meta.touched && meta.error && meta.value.length === 0 && (
				<ErrorText text={'min 1 item is required'} />
			)}
			<SelectInventoryModal
				selectedInventory={meta.value}
				open={isModalOpen}
				onClose={() => setIsModalOpen(false)}
				onRowClick={onInventorySelect}
				segmentEventCall={() =>
					analytics.track(SEGMENT_EVENTS.ADD_NEW_ITEM_SUCCESS, {
						screen: 'new_quotation'
					})
				}
			/>
		</>
	)
}
