import { calculateDueDate } from 'data'

export const getTermsText = (dueDate?: string): string => {
	if (!dueDate) return ''

	const { days, date } = calculateDueDate(dueDate)
	return `Due ${days === 0 ? 'Today' : ''} ${
		days > 0
			? `after ${days} ${days > 1 ? 'days' : 'day'} ( ${date} )`
			: `${days < 0 ? 'on' : ''}  ${date} `
	}`
}
