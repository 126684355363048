import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Avatar, Capsule, Gutter } from 'components'
import type { IBusiness, InvoiceSettingsType, InvoiceType } from 'typings'
import { useSelector } from 'react-redux'
import type { RootState } from 'store/index-reducer'
import { useSettings } from 'context/settings'
import { formatId, getNameChar, truncate } from 'helpers'
import { FlexCol, FlexRow } from 'components/atoms/Flex'
import { CircularProgress, Typography, useTheme } from '@mui/material'
import { styled } from '@mui/system'
import moment from 'moment/moment'
import { dateFormat } from 'data'
import axios from 'axios'
import { Buffer } from 'buffer'

const GridWrapper = styled('div')`
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 10px;
	margin-bottom: 1rem;
`

const Col = styled(FlexCol)`
	flex: 1;
`

const StyledCapsule = styled(Capsule)`
	font-size: 12px;
	width: 100%;
	margin: 0;
`

const Box = styled('div')`
	position: relative;
	border-radius: 50%;
	overflow: hidden;
`

const TransparentBox = styled(FlexRow)`
	position: absolute;
	align-items: center;
	justify-content: center;
	top: 0;
	left: 0;
	flex: 1;
	z-index: 100;
	width: 100%;
	height: 100%;

	background-color: rgba(0, 0, 0, 0.5);
`

type Props = {
	title?: string
	currentInvoice: InvoiceType
	invoiceSettings?: InvoiceSettingsType
}

export const Header: React.ComponentType<Props> = ({
	currentInvoice,
	invoiceSettings
}) => {
	const [base64Image, setBase64Image] = useState<string | undefined>(
		undefined
	)
	const { user } = useSelector((state: RootState) => state.user)
	const { businesses } = useSelector((state: RootState) => state.business)
	const { business } = useSettings()
	const theme = useTheme()
	const { colors } = theme.palette

	const activeBusiness = businesses.find(
		(biz: IBusiness) => biz.id === business
	)
	const { email, phone, businessName, address, businessLogo } =
		useMemo(() => {
			return {
				userName: user?.fullName || '',
				email: user?.email || '',
				phone: user?.phone || { countryCode: '', number: '' },
				businessName: activeBusiness?.name || '',
				address: activeBusiness?.address || '',
				businessLogo: activeBusiness?.profilePic
			}
		}, [user, activeBusiness])

	const {
		businessInfo: { cnic, strn }
	} = currentInvoice

	const fetchImage = useCallback(async () => {
		axios
			.get(businessLogo!, {
				responseType: 'arraybuffer',
				headers: {
					'Cache-Control': 'no-cache',
					Pragma: 'no-cache',
					Expires: '0'
				}
			})
			.then(response => {
				const buffer = Buffer.from(response.data, 'binary').toString(
					'base64'
				)
				setBase64Image(`data:image/png;base64,${buffer}`)
			})
			.catch(() => {
				//
			})
	}, [businessLogo])

	useEffect(() => {
		if (businessLogo) {
			fetchImage()
		}
	}, [businessLogo, fetchImage])

	return (
		<GridWrapper>
			<FlexRow align={'flex-start'}>
				<Box>
					{!base64Image && businessLogo && (
						<TransparentBox>
							<CircularProgress color={'warning'} />
						</TransparentBox>
					)}
					<Avatar
						borderWidth={2}
						src={base64Image}
						title={getNameChar(businessName)}
						sx={{ width: 65, height: 65 }}
					/>
				</Box>

				<Gutter gap={1} />
				<Col>
					<Typography fontWeight={500} variant={'subtitle1'}>
						{businessName}
					</Typography>
					<Typography variant={'caption'}>
						+{phone.countryCode} {phone.number}
					</Typography>
					<Typography variant={'caption'}>{email}</Typography>
					<Typography variant={'caption'}>{address}</Typography>
					{cnic &&
						(invoiceSettings?.settings.displayBusinessCnic ||
							currentInvoice.status !== 'draft') && (
							<Typography variant={'caption'}>
								NTN/CNIC: {cnic}
							</Typography>
						)}
					{strn &&
						(invoiceSettings?.settings.displayBusinessStrn ||
							currentInvoice.status !== 'draft') && (
							<Typography variant={'caption'}>
								STRN: {strn}
							</Typography>
						)}
				</Col>
			</FlexRow>
			<Col align={'flex-end'} justify={'space-between'}>
				<Typography
					fontWeight={500}
					variant={'subtitle2'}
					// this is just for pdf view you can use h5, h6 etc
					fontSize={'30px'}
				>
					Invoice
				</Typography>

				<FlexRow>
					{currentInvoice?.orderBooker?.name && (
						<FlexCol align={'center'}>
							<Typography
								variant={'caption'}
								color={colors.gray['600']}
							>
								Order Booker
							</Typography>
							<Typography
								variant={'subtitle2'}
								color={colors.gray['900']}
							>
								{truncate(
									currentInvoice?.orderBooker?.name,
									20
								)}
							</Typography>
						</FlexCol>
					)}
					<Gutter gap={1} />
					<FlexCol align={'center'}>
						<Typography variant={'caption'}>Invoice ID</Typography>
						<StyledCapsule
							name={`INV-${formatId(currentInvoice?.friendlyId)}`}
							value={`INV-${formatId(
								currentInvoice?.friendlyId
							)}`}
							isSelected
							color={'green'}
						/>
					</FlexCol>
					<Gutter gap={1} />
					<FlexCol align={'center'}>
						<Typography variant={'caption'}>
							Invoice Date
						</Typography>
						<StyledCapsule
							name={moment(currentInvoice?.initiationAt).format(
								dateFormat
							)}
							value={moment(currentInvoice?.initiationAt).format(
								dateFormat
							)}
							isSelected
							color={'blue'}
						/>
					</FlexCol>
				</FlexRow>
			</Col>
		</GridWrapper>
	)
}
