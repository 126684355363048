import React, { useState } from 'react'
import {
	Button,
	InputAdornment,
	styled,
	Typography,
	useTheme
} from '@mui/material'
import { Gutter } from 'components/atoms'
import { FlexCol, FlexRow } from 'components/atoms/Flex'
import { PaymentMethodOptions } from 'data'
import {
	FieldCurrencyInput,
	FieldDatePicker,
	FieldTextInput
} from 'components/molecules'
import { StyledModal } from 'components/StyledModal'
import { CustomRadioGroup } from 'components/atoms/RadioGroup'
import { convertNumber, formatId } from 'helpers'

import { CloseOutlined, NoteOutlined } from '@mui/icons-material'
import { Formik } from 'formik'
import type { InvoiceType, PaymentMethod } from 'typings'
import { useAppErrors } from 'hooks'
import moment from 'moment'
import * as Yup from 'yup'
import { recordPayment } from 'api/invoice'
import { enqueueSnackbar } from 'notistack'

const Container = styled(FlexCol)`
	align-self: center;
	justify-self: center;
	padding: 25px 35px;
	min-height: 450px;
	min-width: 500px;
	max-width: 510px;
	width: 100%;
	height: auto;
	background-color: ${({ theme }) => theme.palette.background.default};
	border-radius: 10px;
	margin-top: 20px;
	margin-bottom: 20px;
	overflow-y: scroll;
	justify-content: space-between;
`

const WrapperDiv = styled(FlexCol)`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`

const WrapperFieldRadioGroup = styled(CustomRadioGroup)(({ theme }) => ({
	'& .MuiSvgIcon-root': {
		fontSize: 15,
		color: theme.palette.colors.gray['300']
	},
	'& .MuiFormControlLabel-label': {
		fontSize: 12,
		color: theme.palette.colors.gray['500']
	},
	'& .MuiRadio-root': {
		'&.Mui-checked': {
			'& .MuiSvgIcon-root': {
				color: theme.palette.colors.green['600']
			}
		}
	}
}))

const WrapperButton = styled(Button)(({ theme }) => ({
	backgroundColor: theme.palette.colors.green['500'],
	color: theme.palette.colors.white['900'],
	'&:hover': {
		background: theme.palette.colors.green['700']
	},
	'&:disabled': {
		backgroundColor: theme.palette.colors.gray['200'],
		color: theme.palette.colors.gray['500']
	}
}))

const HeadingText = styled(Typography)`
	color: ${({ theme }) => theme.palette.colors.gray['700']};
	font-size: 12px;
	font-weight: 500;
`

const StyledFieldNumberInput = styled(FieldCurrencyInput)`
	align-self: center;
	align-items: center;
	max-width: 190px;
	width: 100%;
	input {
		font-size: 14px;
	}
`

const StyledFieldDatePicker = styled(FieldDatePicker)`
	align-self: center;
	align-items: center;
	max-width: 190px;
	width: 100%;
	input {
		font-size: 14px;
	}
`

const NoteButton = styled(Button)`
	justify-content: flex-start;
	border-radius: 8px;
	padding: 8px 14px;
	border: ${({ theme }) => `1px solid ${theme.palette.colors.gray['300']}`};
	color: ${({ theme }) => theme.palette.colors.black['300']};
`

const NoteButtonNoteIcon = styled(NoteOutlined)`
	font-size: 17.5px;
`

const NoteButtonCrossIcon = styled(CloseOutlined)`
	font-size: 14px;
`

const NoteButtonText = styled(Typography)`
	color: ${({ theme }) => theme.palette.colors.gray['700']};
	font-size: 12px;
	font-weight: 500;
`

interface Props {
	currentInvoice: InvoiceType
	amountDue: number
	open: boolean
	onClose?: () => void
	onSuccess?: () => void
}

export const RecordPaymentModal: React.ComponentType<Props> = ({
	currentInvoice,
	amountDue,
	open,
	onClose,
	onSuccess
}) => {
	const [expandNote, setExpandNote] = useState(false)
	const { setAppError } = useAppErrors()

	const theme = useTheme()
	const { colors } = theme.palette

	const { friendlyId, id, business: businessId, contact } = currentInvoice

	const onSubmit = async (values: any, actions: any) => {
		const amountNumber = convertNumber(values.paymentAmount)

		const finalObj = {
			...values,
			paymentAmount: amountNumber,
			paymentNote: expandNote ? values.paymentNote : '',
			businessId,
			contactId: contact.id,
			paymentStatus: amountNumber === amountDue ? 'paid' : 'partial'
		}
		try {
			actions.setSubmitting(true)
			await recordPayment(id, finalObj)
			enqueueSnackbar('Payment Recorded Successfully', {
				variant: 'success'
			})
			onSuccess?.()
			onClose?.()
		} catch (e: any) {
			setAppError(e, actions)
		} finally {
			actions.setSubmitting(false)
		}
	}

	const validationSchema = Yup.object().shape({
		paymentAmount: Yup.number()
			.positive('Should be greater than 0')
			.typeError('Number Required')
			.nullable()
			.max(amountDue, `Cannot be more than ${amountDue}`),
		paymentDate: Yup.date().required('Date is required')
	})

	return (
		<StyledModal open={open} onClose={onClose}>
			<Formik
				initialValues={{
					paymentAmount: amountDue,
					paymentDate: moment().format(),
					paymentNote: '',
					paymentMethod: 'cash'
				}}
				onSubmit={onSubmit}
				validationSchema={validationSchema}
			>
				{({ values, handleSubmit, isSubmitting, setFieldValue }) => (
					<Container>
						<WrapperDiv>
							<Typography
								variant="subtitle1"
								fontWeight={'bold'}
								color={colors.gray['700']}
							>
								{`Record Payment for INV-${formatId(
									friendlyId
								)}`}
							</Typography>
							<Gutter spacing={1} />
							<FlexRow>
								<FlexCol>
									<HeadingText variant="caption">
										Amount
									</HeadingText>
									<StyledFieldNumberInput
										size="small"
										name="paymentAmount"
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<Typography>PKR</Typography>
												</InputAdornment>
											)
										}}
									/>
								</FlexCol>
								<Gutter gap={1} />
								<FlexCol>
									<HeadingText variant="caption">
										Payment Date
									</HeadingText>
									<StyledFieldDatePicker
										name={'paymentDate'}
										disableFuture
									/>
								</FlexCol>
							</FlexRow>
							<Gutter spacing={1} />
							<FlexRow align="center">
								<HeadingText variant="caption">
									Payment Method
								</HeadingText>
							</FlexRow>
							<WrapperFieldRadioGroup
								row
								options={PaymentMethodOptions}
								value={values.paymentMethod}
								onChange={(
									event: React.ChangeEvent<HTMLInputElement>
								) => {
									setFieldValue(
										'paymentMethod',
										event?.target.value as PaymentMethod
									)
								}}
							/>
							<Gutter spacing={0.6} />

							{!expandNote && (
								<NoteButton onClick={() => setExpandNote(true)}>
									<NoteButtonNoteIcon />
									<Gutter gap={0.3} />
									<NoteButtonText variant={'caption'}>
										Add Note
									</NoteButtonText>
								</NoteButton>
							)}
							{expandNote && (
								<>
									<FlexRow align="center">
										<NoteButtonText variant={'caption'}>
											Note
										</NoteButtonText>
										<Gutter gap={0.3} />
										<NoteButtonCrossIcon
											onClick={() => setExpandNote(false)}
										/>
									</FlexRow>
									<Gutter spacing={0.3} />
								</>
							)}
							{expandNote && (
								<FieldTextInput
									multiline
									rows={4}
									name={'paymentNote'}
									placeholder={'Enter description'}
									style={{ width: '240px' }}
									InputProps={{
										style: {
											fontSize: 12,
											padding: 14
										}
									}}
									inputProps={{ maxLength: 300 }}
								/>
							)}
						</WrapperDiv>
						<FlexRow justify="flex-end" style={{ width: '100%' }}>
							<Button
								style={{ color: colors.gray['500'] }}
								onClick={onClose}
							>
								Cancel
							</Button>
							<WrapperButton
								onClick={() => handleSubmit()}
								disabled={isSubmitting}
							>
								Done
							</WrapperButton>
						</FlexRow>
					</Container>
				)}
			</Formik>
		</StyledModal>
	)
}
