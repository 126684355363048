import { type ModalProps, styled, Typography } from '@mui/material'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Button, Gutter, SearchBar } from 'components/index'
import { FlexCol, FlexRow } from 'components/atoms/Flex'
import type {
	DataResponseType,
	InventoryItemType,
	ResponsePageInfo
} from 'typings'
import type { InventorySortType } from 'screen'
import { StyledModal } from 'components/StyledModal'
import { IoIosClose } from 'react-icons/io'
import { useAppErrors, useDimension } from 'hooks'
import AddContact from 'assets/contacts/add_contact.png'
import NoContacts from 'assets/contacts/no_contact_found.png'
import Paper from '@mui/material/Paper'
import { ScrollableTable } from './ScrollableTable'
import { getInventoryList } from 'api'
import { useSettings } from 'context/settings'
import { AddInventoryModal } from 'screen/Inventory/AddStock'
import analytics from 'utils/segment'
import { SEGMENT_EVENTS } from 'data'
import { AiOutlinePlus } from 'react-icons/ai'
import { TransparentButton } from 'components/atoms/Button/TransparentButton'
import { addOrRemoveArrObj } from 'helpers'

const Container = styled(FlexCol)`
	height: 80%;
	width: 75%;
	background-color: ${({ theme }) => theme.palette.background.default};
	padding: 15px;
	z-index: 0;
	border-radius: 10px;
`

const CloseButtonWrapper = styled(FlexCol)`
	justify-content: center;
	align-items: center;
	height: 30px;
	width: 30px;
	right: 1.75em;

	&:hover {
		cursor: pointer;
		border-radius: 25%;
		background-color: ${({ theme }) => theme.palette.colors.gray['100']};
		display: flex;
	}
`

const Center = styled(FlexCol)`
	position: absolute;
	width: 100%;
	height: 100%;
	align-self: center;
	align-items: center;
	justify-content: center;
`

const Image = styled('img')`
	min-width: 100px;
	max-width: 250px;
`

const StyledPaper = styled(Paper)`
	width: 100%;
	overflow: hidden;
	z-index: 0;
	position: relative;
`

type Props = {
	onRowClick?(inventory: InventoryItemType): void
	selectedInventory?: InventoryItemType[]
	segmentEventCall?(): void
	onClickContinue?(): void
	multipleSelect?: boolean
	onMultipleSelect?(selectedItem: InventoryItemType[]): void
} & Omit<ModalProps, 'children'>

export const SelectInventoryModal: React.ComponentType<Props> = ({
	onClose,
	segmentEventCall,
	multipleSelect,
	onMultipleSelect,
	...props
}) => {
	const [open, setOpen] = useState<boolean>(false)
	const [loading, setLoading] = useState<boolean>(true)
	const [dataSource, setDataSource] = useState<InventoryItemType[]>([])
	const [transformedData, setTransformedData] = useState<InventoryItemType[]>(
		[]
	)
	const [selectedItem, setSelectedItem] = useState<InventoryItemType[]>([])
	const [pageInfo, setPageInfo] = useState<ResponsePageInfo>({
		totalPages: 1,
		currentPage: 1,
		edgesPerPage: 20
	})
	const [searchKey, setSearchKey] = useState('')
	const [sortObj, setSortObj] = useState<InventorySortType>({
		sortOrder: 'asc',
		sortBy: 'name'
	})
	const ref = useRef<HTMLDivElement>(null)
	const { height } = useDimension()
	const { setAppError } = useAppErrors()
	const { business } = useSettings()

	const tableHeight = useMemo(() => {
		if (ref?.current) {
			return ref?.current?.clientHeight - 200
		}

		return height * 0.8 - 240 // note here 0.8 is the modal height
	}, [height])

	const onSearch = async (searchText: string) => {
		setDataSource([])
		setSearchKey(searchText)
	}

	const getTransformedData = (res: InventoryItemType[]) => {
		const arr: InventoryItemType[] = []
		res.map(m =>
			m.stock.map(d => {
				return arr.push(Object.assign({}, { ...m, stock: [d] }))
			})
		)

		return arr
	}

	const getInitialInventoryList = useCallback(async () => {
		try {
			setLoading(true)
			const res: DataResponseType<InventoryItemType> =
				await getInventoryList({
					business,
					page: 1,
					limit: pageInfo.edgesPerPage,
					searchKey,
					...sortObj,
					sortOrder: sortObj.sortOrder.toUpperCase() as 'ASC' | 'DESC'
				})
			setDataSource(res.edges)
			setTransformedData(getTransformedData(res.edges))
			setPageInfo(res.pageInfo)
		} catch (e: any) {
			setAppError(e)
		} finally {
			setLoading(false)
		}
	}, [business, pageInfo.edgesPerPage, searchKey, setAppError, sortObj])

	const fetchInventory = async () => {
		try {
			setLoading(true)
			if (
				!loading &&
				pageInfo?.totalPages &&
				pageInfo?.totalPages >= pageInfo.currentPage + 1
			) {
				const res: DataResponseType<InventoryItemType> =
					await getInventoryList({
						business,
						page: pageInfo.currentPage + 1,
						limit: pageInfo.edgesPerPage,
						searchKey,
						...sortObj,
						sortOrder: sortObj.sortOrder.toUpperCase() as
							| 'ASC'
							| 'DESC'
					})

				setTransformedData([
					...transformedData,
					...getTransformedData(res.edges)
				])
				setDataSource([...dataSource, ...res.edges])
				setPageInfo(res.pageInfo)
			}
		} catch (e: any) {
			setAppError(e)
		} finally {
			setLoading(false)
		}
	}

	const onClick = () => {
		segmentEventCall?.()
		setSearchKey('')
		onClose?.({}, 'escapeKeyDown')
		setSelectedItem([])
		if (props.onClickContinue) {
			props.onClickContinue()
		}
	}

	const onSort = useCallback(
		(name: string) => {
			const order =
				sortObj.sortBy === name
					? sortObj.sortOrder === 'asc'
						? 'desc'
						: 'asc'
					: 'asc'

			setSortObj(() => {
				return {
					sortOrder: order,
					sortBy: name
				}
			})
		},
		[sortObj.sortBy, sortObj.sortOrder]
	)

	useEffect(() => {
		getInitialInventoryList()
	}, [getInitialInventoryList])

	return (
		<>
			<StyledModal onClose={onClose} open={props.open}>
				<Container ref={ref}>
					<FlexRow
						style={{ width: '100%' }}
						justify={'space-between'}
						align={'center'}
					>
						<Typography variant={'body1'} fontWeight={600}>
							Select items
						</Typography>
						<CloseButtonWrapper>
							<IoIosClose
								size={25}
								onClick={() => {
									setSelectedItem([])
									onClick()
								}}
							/>
						</CloseButtonWrapper>
					</FlexRow>
					<Gutter />
					<FlexRow
						align={'center'}
						justify={'space-between'}
						style={{ width: '100%' }}
					>
						<FlexRow align={'center'}>
							<SearchBar
								onSearch={onSearch}
								sx={{ width: '35ch' }}
							/>
						</FlexRow>

						<TransparentButton
							onClick={() => {
								analytics.track(SEGMENT_EVENTS.ADD_NEW_ITEM)
								setOpen(true)
							}}
						>
							<AiOutlinePlus />
							<Gutter gap={0.5} />
							{'Add a new Item'}
						</TransparentButton>
					</FlexRow>
					<Gutter spacing={1.4} />
					<StyledPaper>
						{dataSource.length === 0 && !loading && (
							<Center>
								<Image
									src={
										searchKey === ''
											? AddContact
											: NoContacts
									}
								/>
								<Gutter spacing={0.3} />
								<Typography variant={'body2'}>
									{searchKey === ''
										? 'Your Inventory is empty, add items to it to track your stock better'
										: `No items titled "${searchKey}" found`}
								</Typography>
							</Center>
						)}
						<ScrollableTable
							tableHeight={tableHeight}
							fetchInventory={fetchInventory}
							selectedInventory={
								multipleSelect
									? selectedItem
									: props.selectedInventory || []
							}
							onSort={onSort}
							sortObj={sortObj}
							searchKey={searchKey}
							inventoryState={{
								dataSource: dataSource,
								loading: loading,
								transformedData: transformedData
							}}
							multipleSelect={multipleSelect}
							onRowClick={(inv: InventoryItemType) => {
								if (!multipleSelect) {
									props.onRowClick?.(inv)
								} else {
									setSelectedItem(
										addOrRemoveArrObj(
											[...selectedItem],
											inv,
											'id'
										)
									)
								}
							}}
						/>
					</StyledPaper>
					<Gutter spacing={0.4} />
					<FlexRow style={{ width: '100%' }} justify={'flex-end'}>
						<Button
							mode={'primary'}
							style={{ padding: '6px 10px' }}
							onClick={() => {
								onMultipleSelect?.(selectedItem)
								onClick()
							}}
						>
							Continue
						</Button>
					</FlexRow>
				</Container>
			</StyledModal>
			<AddInventoryModal
				open={open}
				onClose={() => setOpen(false)}
				onSuccess={() => {
					getInitialInventoryList()
				}}
			/>
		</>
	)
}
