import React from 'react'
import {
	DesktopDatePicker,
	type DesktopDatePickerProps
} from '@mui/x-date-pickers/DesktopDatePicker'
import { useField } from 'formik'
import moment from 'moment/moment'
import { TextInput, type TextInputProps } from 'components/atoms'
import { Box } from '@mui/system'
import { dateFormat } from 'data'

type FieldDatePickerProps = {
	name: string
} & DesktopDatePickerProps<any>

type BrowserInputProps = TextInputProps & {
	ownerState?: any
}

export const BrowserInput = function BrowserInput(props: BrowserInputProps) {
	const { inputProps, InputProps, inputRef, ...other } = props

	return (
		<Box
			sx={{ display: 'flex', alignItems: 'center' }}
			ref={InputProps?.ref}
		>
			<TextInput
				ref={inputRef}
				{...inputProps}
				{...(other as any)}
				size={'small'}
				InputProps={{
					endAdornment: InputProps?.endAdornment,
					readOnly: true
				}}
				style={{ width: '100%' }}
			/>
		</Box>
	)
}

export const FieldDatePicker: React.ComponentType<FieldDatePickerProps> = ({
	name,
	...props
}) => {
	const [, meta, helpers] = useField(name as any)

	return (
		<DesktopDatePicker
			closeOnSelect={true}
			value={moment(meta.value)}
			onChange={e => helpers.setValue(moment.utc(e).format())}
			format={dateFormat}
			{...props}
			slots={{ textField: BrowserInput }}
		/>
	)
}
