import React, { useCallback } from 'react'
import { styled, Typography } from '@mui/material'
import { FlexRow } from 'components/atoms/Flex'
import { Capsule, FieldTextInput, Gutter } from 'components'
import CloseIcon from '@mui/icons-material/Close'
import { DummyBankDetail } from 'data'
import type { IBankInfo } from 'typings/contacts'
import type { ContactInitialStateProps } from 'screen/Contact/AddContact/CreateContactModal'
import { useFormikContext } from 'formik'
import { useTranslate } from 'hooks'

const CloseIconWrapper = styled(CloseIcon)`
	width: 12px;
	margin-left: 2px;
`

const StyledFieldTextInput = styled(FieldTextInput)`
	width: 100%;
`

const StyledCapsule = styled(Capsule)`
	padding: 2px 20px;
	border-radius: 15px;
`

const Container = styled('div')`
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 10px 20px;

	@media screen and (max-width: 750px) {
		grid-template-columns: 1fr;
	}
`

export const BankingInformationTab: React.ComponentType<
	ContactInitialStateProps
> = ({ banking, selectedBank }) => {
	const translate = useTranslate()
	const { setFieldValue } = useFormikContext()

	const addNewBank = () => {
		const newBanks = banking.map((bank: IBankInfo) => bank)
		newBanks.push(DummyBankDetail)
		setFieldValue('banking', newBanks)
		setFieldValue('selectedBank', newBanks.length - 1)
	}

	const removeBank = useCallback(
		(idx: number) => {
			const filteredBanks = banking.filter(
				(bank: IBankInfo, i: number) => i != idx
			)
			setFieldValue('banking', filteredBanks)

			let select
			const previouslySelected = selectedBank

			if (previouslySelected == 0) select = 0
			else if (previouslySelected < filteredBanks.length)
				select = previouslySelected
			else select = filteredBanks.length - 1

			setFieldValue('selectedBank', select)
		},
		[banking, selectedBank, setFieldValue]
	)

	const RenderTab = useCallback(
		({ idx }: { idx: number }) => {
			return (
				<StyledCapsule
					name={''}
					value={''}
					isSelected={selectedBank == idx}
					color={'blue'}
					onItemClick={() => setFieldValue('selectedBank', idx)}
					renderLeft={
						<Typography variant="caption">
							{`Bank ${idx + 1}`}
						</Typography>
					}
					renderRight={
						<CloseIconWrapper onClick={() => removeBank(idx)} />
					}
				/>
			)
		},
		[removeBank, setFieldValue, selectedBank]
	)

	return (
		<>
			<FlexRow>
				{banking.map((bank: any, idx: number) => (
					<RenderTab idx={idx} key={idx} />
				))}
				{banking.length < 4 && (
					<StyledCapsule
						name={
							banking.length
								? '+'
								: translate(
										'contacts.form.bankingInformation.addBank'
										// eslint-disable-next-line no-mixed-spaces-and-tabs
								  )
						}
						value={
							banking.length
								? '+'
								: translate(
										'contacts.form.bankingInformation.addBank'
										// eslint-disable-next-line no-mixed-spaces-and-tabs
								  )
						}
						onItemClick={addNewBank}
					/>
				)}
			</FlexRow>
			<Gutter spacing={0.5} />
			{banking.length > 0 && (
				<Container>
					<StyledFieldTextInput
						name={`banking.${selectedBank}.accountNumber`}
						placeholder={translate(
							'contacts.form.bankingInformation.placeholder.accNumber'
						)}
						size="small"
						onChange={e =>
							setFieldValue(
								`banking.${selectedBank}.IBAN`,
								e.target.value
							)
						}
					/>

					<StyledFieldTextInput
						name={`banking.${selectedBank}.bankName`}
						placeholder={translate(
							'contacts.form.bankingInformation.placeholder.bankName'
						)}
						size="small"
					/>
					<StyledFieldTextInput
						name={`banking.${selectedBank}.title`}
						placeholder={translate(
							'contacts.form.bankingInformation.placeholder.accTitle'
						)}
						size="small"
					/>
					<StyledFieldTextInput
						name={`banking.${selectedBank}.branch`}
						placeholder={translate(
							'contacts.form.bankingInformation.placeholder.branch'
						)}
						size="small"
					/>
				</Container>
			)}
		</>
	)
}
