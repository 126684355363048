import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { styled } from '@mui/system'
import { motion } from 'framer-motion'
import {
	LAYOUT_BREAKPOINT_WIDTH,
	SIDEBAR_DATASOURCE,
	type SideBarDataSourcesProps
} from 'data'
import { SidebarHeader } from 'components/app/SideBar/SidebarHeader'
import type { IBusiness } from 'typings'
import { useSettings } from 'context/settings'
import { useSelector } from 'react-redux'
import type { RootState } from 'store/index-reducer'
import { Gutter } from 'components/atoms'
import { SidebarItem } from 'components/app/SideBar/SideBarItem'
import { SideBarFooter } from 'components/app/SideBar/SIdeBarFooter'
import { useAppErrors, useDimension } from 'hooks'
import { getOrderBookerSettings } from 'api'

const Wrapper = styled('div')`
	top: 0;
	min-width: 52px;
	float: left;
	z-index: 100;

	@media (max-width: ${LAYOUT_BREAKPOINT_WIDTH}px) {
		min-width: 0;
	}
`

const MainContainer = styled('div')`
	display: flex;
	z-index: 200;
`

const Sections = styled('section')`
	display: flex;
	width: 100%;
	flex-direction: column;
	align-items: flex-start;
`

const MotionDiv = styled(motion.div, {
	shouldForwardProp: props => props !== 'isOpen'
})<{ isOpen: boolean }>`
	background-color: ${({ theme }) => theme.palette.colors['white']['400']};
	color: ${({ theme }) => theme.palette.text.secondary};
	height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	overflow-y: auto;
	overflow-x: hidden;

	::-webkit-scrollbar {
		display: none;
	}
	-ms-overflow-style: none;
	scrollbar-width: none;

	:hover {
		::-webkit-scrollbar {
			display: block;
			width: ${({ isOpen }) => (isOpen ? '6px' : '0')};
		}

		::-webkit-scrollbar-track {
			background-color: transparent;
		}

		::-webkit-scrollbar-thumb {
			background-color: #888;
			border-radius: 8px;
		}
		scrollbar-width: thin;
		scrollbar-track-color: transparent;
		scrollbar-thumb-color: #b3b3b3;
	}
`

type SideBarProps = {
	isOpen: boolean
	setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const SideBar: React.ComponentType<SideBarProps> = ({ isOpen, setIsOpen }) => {
	const [displayOrderBookerModule, setDisplayOrderBookerModule] =
		useState<boolean>(false)
	const { businesses } = useSelector((state: RootState) => state.business)
	const { setAppError } = useAppErrors()
	const { width } = useDimension()
	const { business } = useSettings()
	const selectedBusiness = businesses.find(
		(buz: IBusiness) => buz.id === business
	)

	const fetchOrderBookerSettings = useCallback(async () => {
		try {
			const res = await getOrderBookerSettings(business)
			setDisplayOrderBookerModule(
				res ? res.settings.displayOrderBookerModule : false
			)
		} catch (e: any) {
			setAppError(e)
		}
	}, [setAppError, business])

	const RenderItem = useCallback(
		({ item }: { item: SideBarDataSourcesProps }) => (
			<SidebarItem key={item.path} item={item} isOpen={isOpen} />
		),
		[isOpen, displayOrderBookerModule]
	)

	useEffect(() => {
		fetchOrderBookerSettings()
	}, [business, displayOrderBookerModule])

	const desktopAnimation = useMemo(() => {
		return {
			width: isOpen ? '180px' : '52px',
			padding: isOpen ? '0 8px' : '0 8px',
			transition: {
				duration: 0.2,
				type: 'linear'
			},
			maxHeight: '100vh'
		}
	}, [isOpen])

	const mobileAnimation = useMemo(() => {
		return {
			width: isOpen ? '180px' : '0',
			padding: isOpen ? '0 8px' : '0',
			transition: {
				duration: 0.2,
				type: 'linear'
			},
			maxHeight: '100vh'
		}
	}, [isOpen])

	const animate = useMemo(
		() =>
			width < LAYOUT_BREAKPOINT_WIDTH
				? mobileAnimation
				: desktopAnimation,
		[desktopAnimation, mobileAnimation, width]
	)

	return (
		<Wrapper>
			<MainContainer>
				<MotionDiv animate={animate} isOpen={isOpen}>
					<Sections>
						<Gutter spacing={1.5} />
						<SidebarHeader isOpen={isOpen} />
						<Gutter spacing={3} />

						{SIDEBAR_DATASOURCE.filter(item => {
							if (item.name === 'analytics')
								return selectedBusiness?.id
							else if (item.name === 'orderbookers') {
								return displayOrderBookerModule
							}
							return true
						}).map(item => (
							<RenderItem item={item} key={item.path} />
						))}
					</Sections>

					<Sections>
						<Gutter spacing={3} />
						<SideBarFooter isOpen={isOpen} onClick={setIsOpen} />
					</Sections>
				</MotionDiv>
			</MainContainer>
		</Wrapper>
	)
}

export { SideBar }
