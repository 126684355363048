import React from 'react'
import { Gutter, StyledModal } from 'components'
import type { ModalProps } from '@mui/material'
import { Typography, useTheme } from '@mui/material'
import { styled } from '@mui/system'
import { FlexCol, FlexRow } from 'components/atoms/Flex'
import { CustomRadioGroup } from 'components/atoms/RadioGroup'
import { INVOICE_RETURN_INVENTORY_MODAL } from 'data'
import { useAppErrors } from 'hooks'
import { useNavigate } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { markVoidInvoice } from 'api/invoice'
import Warning from 'assets/alerts/warning.png'
import { TransparentButton } from 'components/atoms/Button/TransparentButton'

const Container = styled(FlexCol)`
	align-items: flex-end;
	background-color: ${({ theme }) => theme.palette.background.default};
	padding: 20px 40px 20px 30px;
	z-index: 0;
	border-radius: 10px;
`

const Col = styled(FlexCol)`
	min-width: 300px;
	max-width: 400px;
`

const Row = styled(FlexRow)`
	width: 100%;
`

const StyledButton = styled(TransparentButton)`
	background-color: ${({ theme }) => theme.palette.colors.green['600']};

	:hover {
		background-color: ${({ theme }) => theme.palette.colors.green['700']};
	}
`

type ReturnPaymentModalProps = {
	invoiceId?: string
	returnInventorySelected: string
} & Omit<ModalProps, 'children'>

export const ReturnPaymentModal: React.ComponentType<
	ReturnPaymentModalProps
> = ({ onClose, invoiceId, returnInventorySelected, ...props }) => {
	const [selected, setSelected] = React.useState<string>('yes')
	const [loading, setLoading] = React.useState<boolean>(false)
	const theme = useTheme()
	const { setAppError } = useAppErrors()
	const navigate = useNavigate()
	const { enqueueSnackbar } = useSnackbar()
	const { colors } = theme.palette

	const onReturn = async () => {
		try {
			setLoading(true)
			await markVoidInvoice({
				invoiceId: invoiceId || '',
				restoreInventory: returnInventorySelected === 'yes',
				paymentRefunded: selected === 'yes'
			})
			if (returnInventorySelected === 'yes') {
				enqueueSnackbar(
					'Your inventory has been updated and items from the void invoice have been returned to it.',
					{
						variant: 'success'
					}
				)
			}

			enqueueSnackbar('The invoice status has been set to void.', {
				variant: 'success'
			})
			navigate(`/invoice/view/${invoiceId}`)
		} catch (e: any) {
			setAppError(e)
		} finally {
			setLoading(false)
			onClose?.({}, 'escapeKeyDown')
		}
	}

	return (
		<StyledModal {...props}>
			<Container>
				<FlexRow>
					<img
						alt={'warning'}
						src={Warning}
						style={{ width: '50px', height: '50px' }}
					/>
					<Gutter gap={1} />
					<Col>
						<FlexRow align={'center'}>
							<Typography variant="body1" fontWeight={'medium'}>
								Refund Recorded Payments
							</Typography>
						</FlexRow>
						<Gutter spacing={0.5} />
						<Typography
							color={colors.gray['600']}
							fontWeight={'regular'}
							variant={'body2'}
						>
							Would you like to refund all payments recorded for
							this invoice?
						</Typography>

						<CustomRadioGroup
							options={INVOICE_RETURN_INVENTORY_MODAL}
							defaultValue={selected}
							onChange={(
								event: React.ChangeEvent<HTMLInputElement>
							) => {
								setSelected(event?.target.value)
							}}
							style={{
								display: 'flex',
								flexDirection: 'row'
							}}
						/>
						<Gutter spacing={2} />
						<Row justify={'flex-end'}>
							<StyledButton
								onClick={() => {
									onReturn()
								}}
								disabled={loading}
							>
								<Typography
									fontWeight={500}
									variant={'caption'}
									color={colors.white['200']}
								>
									Confirm
								</Typography>
							</StyledButton>
						</Row>
					</Col>
				</FlexRow>
			</Container>
		</StyledModal>
	)
}
