import { axios } from 'store'
import type { DataResponseType, InviteUserProps, IUserPostProps } from 'typings'
import queryString from 'query-string'

export const postInviteUser = async (payload: IUserPostProps) => {
	const url = '/auth/invite'

	try {
		const { data } = await axios.post<InviteUserProps>(url, payload)

		return data
	} catch (error: any) {
		throw error.response.data
	}
}

export const getUserList = async (payload: {
	business: string
	deleted?: boolean
	limit: string | number
	page: string | number
	searchKey: string
}) => {
	const url = `/auth/invite/list?${queryString.stringify({
		...payload
	})}`

	try {
		const { data } = await axios.get<DataResponseType<InviteUserProps>>(url)

		return data
	} catch (error: any) {
		throw error.response.data
	}
}

export const getAUserRecord = async (business: string, id: string) => {
	const url = `/auth/invite/${business}/${id}`

	try {
		const { data } = await axios.get<InviteUserProps>(url)

		return data
	} catch (error: any) {
		throw error.response.data
	}
}

export const deActivateUser = async (businessId: string, id: string) => {
	const url = `/auth/invite/${businessId}/${id}`

	try {
		const { data } = await axios.delete(url)

		return data
	} catch (error: any) {
		throw error.response.data
	}
}

export const updateUserInvite = async (
	business: string,
	id: string,
	payload: IUserPostProps
) => {
	const url = `/auth/invite/${business}/${id}`

	try {
		const { data } = await axios.patch<InviteUserProps>(url, payload)

		return data
	} catch (error: any) {
		throw error.response.data
	}
}
