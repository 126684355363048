import React from 'react'
import type { InventoryItemType } from 'typings'
import { styled } from '@mui/system'
import { CheckBox } from 'components/index'
import { TableCell, TableRow, Typography, useTheme } from '@mui/material'
import type { InventoryTableProps } from 'components/app/InventoryModal/InventoryTable'
import { objectOmit } from 'helpers'
import { Unit } from 'screen/OrderBooker/Components/Unit'

const StyledTableRow = styled(TableRow)(() => ({
	// hide last border
	'&:last-child td, &:last-child th': {
		border: 0
	}
}))

const MUIRow = styled(StyledTableRow, {
	shouldForwardProp: props => props !== 'isSelected' && props !== 'disabled'
})<{ isSelected?: boolean; disabled?: boolean }>`
	min-width: max-content;
	background-color: ${({ theme, isSelected, disabled }) =>
		disabled
			? theme.palette.colors.gray['100']
			: isSelected
			? theme.palette.mode === 'dark'
				? theme.palette.colors.gray['600']
				: theme.palette.colors.blue['50']
			: theme.palette.background.default};
	opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};

	:hover {
		cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
		background-color: ${({ theme, disabled }) =>
			disabled
				? theme.palette.colors.gray['100']
				: theme.palette.mode === 'dark'
				? theme.palette.colors.gray['700']
				: theme.palette.colors.blue['100']};
	}
`

const CustomTableCell = styled(TableCell)<{ width?: number }>`
	min-width: ${({ width }) => (width ? `${width}px` : '200px')};
	max-width: 350px;
	vertical-align: top;
`

export type InventoryRowProps<T> = {
	row: T
	setActiveInventory?(id?: string): void
	multipleSelect?: boolean
} & Pick<InventoryTableProps<T>, 'selectedInventory' | 'onRowClick'>

export const InventoryRow: React.ComponentType<
	InventoryRowProps<InventoryItemType>
> = ({ row, onRowClick, selectedInventory, multipleSelect }) => {
	const theme = useTheme()
	const { colors } = theme.palette
	const item = row.stock[0]
	const isSelected = !!selectedInventory?.find((e: any) => {
		return e.stockId === item.id
	})

	const isSelect = !!selectedInventory?.find((e: any) => {
		if (multipleSelect) return e.stock[0].stockId === item.id
		return false
	})

	const onRowClickHandler = () => {
		if (item.quantity > 0) {
			const obj: InventoryItemType = {
				...objectOmit(row, 'stock'),

				stock: row.stock.map(d => {
					return objectOmit(
						{
							...d,
							quantity: d.quantity,
							stockId: row.stock[0].id
						},
						'id'
					)
				})
			}
			onRowClick?.(obj)
		}
	}

	return (
		<MUIRow
			onClick={onRowClickHandler}
			isSelected={isSelected}
			disabled={item.quantity <= 0}
		>
			<CustomTableCell component="th" scope="row" width={50}>
				<CheckBox
					checked={multipleSelect ? isSelect : isSelected}
					id={row.stock[0].id}
				/>
			</CustomTableCell>
			<CustomTableCell component="th" scope="row">
				<Typography
					variant={'caption'}
					style={{
						color: colors.gray['700'],
						overflowWrap: 'break-word'
					}}
					fontWeight={'500'}
					display={'block'}
				>
					{row.name}
				</Typography>
			</CustomTableCell>
			<CustomTableCell align="left">
				<Typography
					variant={'caption'}
					style={{
						color: colors.gray['700'],
						overflowWrap: 'break-word'
					}}
					fontWeight={'500'}
					display={'block'}
				>
					{row.stock[0] ? row.stock[0].code : 'N/A'}
				</Typography>
			</CustomTableCell>

			{/*<CustomTableCell align="left">
				<Typography
					variant={'caption'}
					style={{
						color: colors.gray['700'],
						overflowWrap: 'break-word'
					}}
					fontWeight={'500'}
					display={'block'}
				>
					{row.stock[0] ? row.stock[0].unit : 'N/A'}
				</Typography>
			</CustomTableCell>*/}
			<CustomTableCell align="left" hidden={true}>
				<Unit row={row} displayStockQuantity={true} />
			</CustomTableCell>
		</MUIRow>
	)
}
