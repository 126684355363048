import React from 'react'
import { styled } from '@mui/system'
import { FlexCol } from 'components/atoms/Flex'
import { SOCIAL } from 'data'
import type { ColorsCodeType, IOrderCenterAttrs } from 'typings'
import { Avatar } from 'components'

const Item = styled(FlexCol)`
	position: relative;
`

const Circle = styled(FlexCol)`
	position: absolute;
	background-color: ${({ theme }) => theme.palette.background.default};
	bottom: -2px;
	right: -4px;
	border-radius: 50%;
	padding: 4px;
	box-shadow: 0 1px 2px ${({ theme }) => theme.palette.colors.gray['200']};
`

type ItemAvatarProps = {
	borderWidth?: number
	borderColor?: ColorsCodeType
	width?: number
	title?: string
	src?: string
} & Pick<IOrderCenterAttrs, 'channel'>

export const ItemAvatar: React.ComponentType<ItemAvatarProps> = ({
	borderColor,
	title,
	channel,
	src,
	borderWidth
}) => {
	const Component = SOCIAL[channel || 'sms'].icon
	return (
		<Item>
			<Avatar
				borderWidth={borderWidth}
				borderColor={borderColor}
				src={src}
				title={title}
			/>
			{channel && (
				<Circle>
					<Component color={SOCIAL[channel].color} size={14} />
				</Circle>
			)}
		</Item>
	)
}
