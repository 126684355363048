import React, { useCallback, useEffect, useState } from 'react'
import type {
	QuotationStatusType,
	QuotationType,
	ResponsePageInfo
} from 'typings'
import { ReadyToShareTable } from 'screen/Quotations/Tables'
import { DraftsTable } from './Tables/DraftsTable'
import { getQuotationList, updateAnQuotation } from 'api/quotation'
import { useSettings } from 'context/settings'
import { useAppErrors } from 'hooks/useAppErrors'
import analytics from 'utils/segment'
import { LAYOUT_PADDING, SEGMENT_EVENTS } from 'data'
import { Header } from './Header'
import { styled } from '@mui/material'
import { FlexCol } from 'components/atoms/Flex'
import { useDimension } from 'hooks'

const Container = styled(FlexCol)<{ height: number }>`
	width: 100%;
	height: ${({ height }) => (height ? `${height}px` : '100%')};
	max-height: ${({ height }) => (height ? `${height}px` : '100%')};
	padding: ${LAYOUT_PADDING}px;
`

const Quotations = () => {
	const [searchText, setSearchText] = useState<string>('')
	const [privateDataSource, setPrivateDataSource] = useState<QuotationType[]>(
		[]
	)
	const [readyToShareDataSource, setReadyToShareDataSource] = useState<
		QuotationType[]
	>([])
	const [privatePageInfo, setPrivatePageInfo] = useState<ResponsePageInfo>({
		currentPage: 1,
		edgesPerPage: 0,
		totalPages: 1
	})
	const [readyToShareInfo, setreadyToShareInfo] = useState<ResponsePageInfo>({
		currentPage: 1,
		edgesPerPage: 0,
		totalPages: 1
	})
	const { business } = useSettings()
	const { setAppError } = useAppErrors()
	const { height } = useDimension()

	const { totalPages, edgesPerPage } = readyToShareInfo
	const { totalPages: PTotalPages, edgesPerPage: PEdgesPerPage } =
		privatePageInfo

	const getPrivateInitialData = useCallback(async () => {
		try {
			const res = await getQuotationList(business, {
				page: 1,
				limit: 20,
				status: ['private'],
				searchKey: searchText
			})
			setPrivateDataSource(res.edges)
			setPrivatePageInfo(res.pageInfo)
		} catch (e: any) {
			setAppError(e)
		}
	}, [business, searchText, setAppError])

	const getReadyToShareInitialData = useCallback(async () => {
		try {
			const res = await getQuotationList(business, {
				page: 1,
				limit: 20,
				status: ['approved', 'closed'],
				searchKey: searchText
			})
			setReadyToShareDataSource(res.edges)
			setreadyToShareInfo(res.pageInfo)
		} catch (e: any) {
			setAppError(e)
		}
	}, [business, searchText, setAppError])

	const onRStatusChange = async (
		row: QuotationType,
		status: QuotationStatusType
	) => {
		try {
			const res = await updateAnQuotation(row.id as string, { status })
			analytics.track(SEGMENT_EVENTS.QUO_STATUS_CHANGED, {
				new_status: status
			})
			const index = readyToShareDataSource.findIndex(
				(item: QuotationType) => item.id === row.id
			)
			const newReadyToShareDataSource = [...readyToShareDataSource]
			newReadyToShareDataSource[index] = res
			setReadyToShareDataSource(newReadyToShareDataSource)
		} catch (e: any) {
			setAppError(e)
		}
	}

	useEffect(() => {
		getPrivateInitialData()
		getReadyToShareInitialData()
	}, [getPrivateInitialData, getReadyToShareInitialData])

	return (
		<>
			<Header
				numOfQuotations={
					privateDataSource.length + readyToShareDataSource.length
				}
				setSearchText={setSearchText}
			/>
			<Container height={height - 110}>
				<ReadyToShareTable
					dataSource={readyToShareDataSource}
					total={
						totalPages > 1
							? totalPages * edgesPerPage
							: readyToShareDataSource.length
					}
					onStatusChange={onRStatusChange}
					searchText={searchText}
				/>
				<DraftsTable
					dataSource={privateDataSource}
					total={
						PTotalPages > 1
							? PTotalPages * PEdgesPerPage
							: privateDataSource.length
					}
					searchText={searchText}
				/>
			</Container>
		</>
	)
}

export { Quotations }
