import { axios } from 'store'
import type {
	DataResponseType,
	InventoryItemType,
	InventoryListPayloadType,
	IOrderFormProps
} from 'typings'
import queryString from 'query-string'

export const getBusinessInfo = async (businessId: string) => {
	const url = `/open-api/business?businessId=${businessId}`

	try {
		const { data } = await axios.get(url)

		return data
	} catch (error: any) {
		throw error.response.data
	}
}

export const getOrderFormSettingsInfo = async (businessId: string) => {
	const url = `/open-api/order-form-settings?businessId=${businessId}`
	try {
		const { data } = await axios.get(url)
		return data
	} catch (error: any) {
		throw error.response.data
	}
}

export const postOrderForm = async (business: IOrderFormProps) => {
	const url = '/open-api/process-order-form'

	try {
		const { data } = await axios.post(url, business)

		return data
	} catch (error: any) {
		throw error.response.data
	}
}

export const getOpenInventoryList = async (
	businessId: string,
	payload: Partial<InventoryListPayloadType>
) => {
	const url = `/open-api/inventory?business=${businessId}&${queryString.stringify(
		payload
	)}`

	try {
		const { data } = await axios.get<DataResponseType<InventoryItemType>>(
			url
		)

		return data
	} catch (error: any) {
		throw error.response.data
	}
}
