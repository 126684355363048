const LOOKER_REPORT_URL =
	process.env.REACT_APP_NODE_ENV === 'production'
		? 'https://lookerstudio.google.com/embed/reporting/c7cf40cd-a561-4b9d-9095-8b954323b83f/page/PN0dD?params=%7B%22dsor1.businessid%22:%22<BIZ_ID>%22,%22dsor2.businessid%22:%22<BIZ_ID>%22,%22dsor3.businessid%22:%22<BIZ_ID>%22,%22dsor4.businessid%22:%22<BIZ_ID>%22%7D'
		: 'https://lookerstudio.google.com/embed/reporting/358c20be-8943-48bb-a9f1-3e8e5ea2e67c/page/PN0dD?params=%7B%22dsor1.businessid%22:%22<BIZ_ID>%22,%22dsor2.businessid%22:%22<BIZ_ID>%22,%22dsor3.businessid%22:%22<BIZ_ID>%22,%22dsor4.businessid%22:%22<BIZ_ID>%22,%22dsor5.businessid%22:%22<BIZ_ID>%22%7D'

const LOOKER_REPORT_URL_OB =
	process.env.REACT_APP_NODE_ENV === 'production'
		? 'https://lookerstudio.google.com/embed/reporting/e6096b9a-6b9c-4803-b2ba-e48f16bf31b0/page/PN0dD?params=%7B%22dsor1.businessid%22:%22<BIZ_ID>%22,%22dsor2.businessid%22:%22<BIZ_ID>%22,%22dsor3.businessid%22:%22<BIZ_ID>%22,%22dsor4.businessid%22:%22<BIZ_ID>%22%7D'
		: ' https://lookerstudio.google.com/embed/reporting/b7763b6c-e433-4cdc-ae0d-6220d5a40cd8/page/PN0dD?params=%7B%22dsor1.businessid%22:%22<BIZ_ID>%22,%22dsor2.businessid%22:%22<BIZ_ID>%22,%22dsor3.businessid%22:%22<BIZ_ID>%22,%22dsor4.businessid%22:%22<BIZ_ID>%22,%22dsor5.businessid%22:%22<BIZ_ID>%22%7D'

export const getLookerReport = (
	BIZ_ID: any,
	displayOrderBookerModule: boolean
) => {
	return displayOrderBookerModule
		? LOOKER_REPORT_URL_OB.replace(/<BIZ_ID>/g, BIZ_ID)
		: LOOKER_REPORT_URL.replace(/<BIZ_ID>/g, BIZ_ID)
}
