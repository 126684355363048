import { TableCell, TableRow, Typography } from '@mui/material'
import * as React from 'react'
import { styled } from '@mui/system'

const Cell = styled(TableCell)`
	background-color: ${({ theme }) => theme.palette.colors.gray['50']};
	color: ${({ theme }) => theme.palette.colors.gray['500']};
	padding: 7px 7px 7px 24px;
`

const Text = styled(Typography)`
	color: ${({ theme }) => theme.palette.colors.gray['500']};
	font-weight: 500;
`

export const HTableHeader = () => {
	return (
		<TableRow>
			<Cell variant="head" align={'left'}>
				<Text variant={'caption'}>Contact</Text>
			</Cell>
			<Cell variant="head" align={'right'} sx={{ paddingRight: '24px' }}>
				<Text variant={'caption'}>Net Balance</Text>
			</Cell>
			<Cell variant="head" align={'right'} sx={{ paddingRight: '24px' }}>
				<Text variant={'caption'} />
			</Cell>
		</TableRow>
	)
}
