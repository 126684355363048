type ObjectWithKeys = { [key: string]: any }

export function getFilledArray<
	T extends (v: number, k: number) => unknown,
	Y extends ReturnType<T>
>(range: number, mapfn: T): Y[] {
	return range <= 0 ? [] : (Array.from({ length: range }, mapfn) as Y[])
}

export function updateIndex<T extends unknown[]>(
	array: T,
	indexItem: number,
	item: T[keyof T]
): T {
	return array.map((chipItem, index) => {
		return indexItem === index ? item : chipItem
	}) as T
}

export function joinArrayStrings(array: readonly string[]): string {
	return array.join('')
}

export function append<T extends unknown[]>(array: T, item: T[keyof T]): T {
	return [...array, item] as T
}

export function mergeArrayStringFromIndex(
	array: readonly string[],
	arrayToMerge: readonly string[],
	fromIndex: number
): string[] {
	return array.reduce(
		(obj, currentValue, idx) => {
			const { characters, restArrayMerged } = obj
			if (idx < fromIndex) {
				return {
					restArrayMerged,
					characters: append(characters, currentValue)
				}
			}

			const [firstCharacter, ...restArrayWithoutFirstCharacter] =
				restArrayMerged
			return {
				restArrayMerged: restArrayWithoutFirstCharacter,
				characters: append(characters, firstCharacter || '')
			}
		},
		{
			restArrayMerged: arrayToMerge,
			characters: [] as string[]
		}
	).characters
}

export const addOrRemoveArr = (array: any[], value: any) => {
	const index = array.indexOf(value)
	if (index === -1) {
		array.push(value)
	} else {
		array.splice(index, 1)
	}
	return array
}

export const addOrRemoveArrObj = (array: any[], value: any, key: string) => {
	const index = array.findIndex(item => item[key] === value[key])
	if (index === -1) {
		array.push(value)
	} else {
		array.splice(index, 1)
	}
	return array
}

export const pickArrayIndex = (array: any[], indexes: number[]) => {
	return indexes.map(index => array[index])
}

export const pickKeysFromArray = (
	arr: ObjectWithKeys[],
	keysToRemove: string[]
): ObjectWithKeys[] => {
	return arr.map(obj => {
		const newObj: ObjectWithKeys = {}

		for (const key in obj) {
			if (keysToRemove.includes(key)) {
				newObj[key] = obj[key]
			}
		}

		return newObj
	})
}

export const mergeArrayFromKey = (
	arr1: ObjectWithKeys[],
	arr2: ObjectWithKeys[],
	key: string
): ObjectWithKeys[] => {
	return arr1.map(obj1 => {
		const newObj: ObjectWithKeys = {}

		for (const obj2 of arr2) {
			if (obj1[key] === obj2[key]) {
				newObj[key] = obj1[key]
				return { ...obj1, ...obj2 }
			}
		}

		return newObj
	})
}

export const replaceItemInArray = (
	array: ObjectWithKeys[],
	item: ObjectWithKeys,
	id: string
): ObjectWithKeys[] => {
	return array.map(obj => {
		if (obj.id === id) {
			return item
		}
		return obj
	})
}
