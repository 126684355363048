import React, { useEffect, useState } from 'react'
import type { ModalProps } from '@mui/material'
import { Tooltip, Typography } from '@mui/material'
import { FlexCol, FlexRow } from 'components/atoms/Flex'
import {
	AlertModal,
	Button,
	FieldImageDropZone,
	Gutter,
	ImagePlaceholder
} from 'components'
import { styled } from '@mui/system'
import { Formik } from 'formik'
import {
	addBankDetails,
	createContact,
	deleteBankDetails,
	softDeleteContact,
	updateContacts,
	updateContactsBank,
	uploadContactPic
} from 'api/contacts'
import { useAppErrors } from 'hooks/useAppErrors'
import { useTranslate } from 'hooks'
import type {
	BusinessRelations,
	IBankInfo,
	IContactProps
} from 'typings/contacts'
import PersonOutlineIcon from '@mui/icons-material/PersonOutline'
import { UserForm } from 'screen/Contact/AddContact/UserForm'
import { ContactTabs } from 'screen/Contact/AddContact/ContactTabs'
import * as Yup from 'yup'
import { CNIC_FORMAT, STRINGS, truncate } from 'helpers'
import { useSettings } from 'context/settings'
import { useSnackbar } from 'notistack'
import { detailedDiff, diff } from 'deep-object-diff'
import { StyledModal } from 'components/StyledModal'
import { IoIosClose } from 'react-icons/io'
import analytics from 'utils/segment'
import { CONTACTS_INITIAL_VALUE, SCROLLBAR_STYLE, SEGMENT_EVENTS } from 'data'
import { objectRemoveEmpty } from 'helpers/object'
import { convertNumber } from 'helpers/number'
import { validatePhoneNo } from 'helpers/validatePhoneNo'
import zIndex from '@mui/material/styles/zIndex'
import { useNavigate } from 'react-router-dom'

const Container = styled(FlexRow)`
	align-self: center;
	justify-self: center;
	padding: 25px 35px;
	min-width: 800px;
	max-width: 1100px;
	width: 100%;
	height: auto;
	background-color: ${({ theme }) => theme.palette.background.default};
	border-radius: 10px;
	margin-top: 20px;
	margin-bottom: 20px;
	overflow-y: scroll;

	${SCROLLBAR_STYLE}
`

const Wrapper = styled('div')`
	max-width: 740px;
	width: 100%;
`
const DeleteButton = styled(Button)`
	background-color: ${({ theme }) => theme.palette.colors.red['50']};
	color: ${({ theme }) => theme.palette.colors.red['700']};
	&:hover {
		cursor: pointer;
		background-color: ${({ theme }) => theme.palette.colors.red['100']};
	}
`
const CloseButtonWrapper = styled(FlexCol)`
	justify-content: center;
	align-items: center;
	height: 30px;
	width: 30px;
	right: 1.75em;
	&:hover {
		cursor: pointer;
		border-radius: 25%;
		background-color: ${({ theme }) => theme.palette.colors.gray['100']};
		display: flex;
	}
`

export const validationSchema = Yup.object().shape({
	phone: Yup.object().shape({
		number: Yup.string()
			//.required('Required')
			.test('number', (num, values) => {
				const {
					parent: { number, countryCode, countryInitials }
				} = values

				if (!number) {
					return true
				}

				const res = validatePhoneNo(
					number,
					countryCode,
					countryInitials
				)

				if (!res.isValid) {
					return values.createError({
						path: 'phone.number',
						message: truncate(res.message, 47)
					})
				}
				return true
			})
			.min(4, 'Phone number should be 4 digits long.')
	}),
	name: Yup.string()
		.matches(STRINGS, 'Name cannot contain special characters')
		.max(100, 'Too Long!')
		.matches(STRINGS, 'Name can only contain alphabetical letters')
		.test(
			'len',
			'Name length should be between 1 and 100 characters',
			val => {
				// count only non-white space characters
				const len = (val || '').replace(/\s+/g, '').length
				return len >= 1 && len <= 100
			}
		),

	banking: Yup.array().of(
		Yup.object().shape({
			accountNumber: Yup.string().required('Required'),
			bankName: Yup.string().required('Required'),
			title: Yup.string().required('Required'),
			branch: Yup.string().required('Required'),
			IBAN: Yup.string().required('Required')
		})
	),
	email: Yup.string().email(
		'Incorrect email address format, please try again'
	),
	address: Yup.string().max(
		80,
		'Address length should be less than 80 characters'
	),
	balance: Yup.object().shape({
		amount: Yup.number()
			.typeError('Amount must be a number')
			.max(
				999999999999,
				'The amount cannot be more than PKR 999,999,999,999'
			)
	}),
	cnic: Yup.string()
		.matches(
			CNIC_FORMAT,
			'NTN/CNIC can only contain alphanumeric characters and hyphen'
		)
		.max(25, 'NTN/CNIC length should be less than 25 characters')
		.min(4, 'NTN/CNIC length should be greater than 4 characters')
})

type CreateContactModalProps = Omit<ModalProps, 'children'> & {
	user?: IContactProps
	onSuccess?: (res: IContactProps) => void
}

export type ContactInitialStateProps = IContactProps & {
	selectedTab: string
	selectedBank: number
}

export const CreateContactModal: React.ComponentType<
	CreateContactModalProps
> = ({ onClose, user, onSuccess, ...props }) => {
	const [isDeleteable, setIsDeleteable] = useState(false)
	const [openAlert, setOpenAlert] = React.useState<boolean>(false)
	const navigate = useNavigate()
	const { setAppError } = useAppErrors()
	const { business } = useSettings()
	const { enqueueSnackbar } = useSnackbar()
	const translate = useTranslate()

	const userAmount = user ? user.balance.amount : 0
	const defaultValue = Object.assign(
		{},
		{
			selectedBank: 0,
			selectedTab: 'openingBalance'
		},
		CONTACTS_INITIAL_VALUE
	)

	const userInfo = user && {
		selectedBank: 0,
		selectedTab: 'openingBalance',
		...user,
		balance: {
			...user.balance,
			to: userAmount < 0 ? 'toPay' : 'toReceive',
			amount: userAmount < 0 ? convertNumber(userAmount) * -1 : userAmount
		}
	}

	const initialState: ContactInitialStateProps = user
		? (userInfo as ContactInitialStateProps)
		: defaultValue

	const handleModalClose = (
		event: {},
		reason: 'backdropClick' | 'escapeKeyDown'
	) => {
		if (reason === 'backdropClick') {
			return
		}
		onClose?.(event, reason)
	}

	const getBusinessRelationShip = (values: ContactInitialStateProps) => {
		const businessRelationship: BusinessRelations[] =
			values.businessRelationship.indexOf('both' as BusinessRelations) !==
			-1
				? ['supplier', 'customer']
				: values.businessRelationship

		return businessRelationship
	}

	const onModalClose = () => {
		onClose?.({}, 'escapeKeyDown')
	}

	const onCreate = async (values: ContactInitialStateProps, actions: any) => {
		try {
			actions.setSubmitting(true)
			const businessRelationship = getBusinessRelationShip(values)

			const obj = objectRemoveEmpty(
				{
					...values,
					businessRelationship
				},
				['email']
			)

			const res = await createContact({
				...obj,
				business: business,
				balance: {
					...obj.balance,
					amount:
						obj.balance.to === 'toPay'
							? obj.balance.amount * -1
							: obj.balance.amount
				}
			})
			analytics.track(SEGMENT_EVENTS.CONTACT_ADD, {
				...res,
				screen: 'contact_book'
			})
			enqueueSnackbar(translate('snackbar.successCreate'), {
				variant: 'success'
			})
			onSuccess?.(res)
			onModalClose()
		} catch (error: any) {
			setAppError(error, actions)
		} finally {
			actions.setSubmitting(false)
		}
	}

	const addOrUpdate = async (
		values: ContactInitialStateProps,
		key: number
	) => {
		const bankObj = values.banking[key]

		if (bankObj.id) {
			//Update existing bank
			await updateContactsBank(
				values?.id || '',
				bankObj.id || '',
				bankObj
			)
		} else {
			// Add new bank
			await addBankDetails(values?.id || '', [bankObj])
		}
	}

	const deleteBank = async (deletedBank: IBankInfo[]) => {
		return await Promise.all(
			deletedBank.map(async d => {
				try {
					return await deleteBankDetails(
						initialState?.id as string,
						d.id
					)
				} catch (err: any) {
					setAppError(err)
				}
			})
		)
	}

	const onUpdate = async (values: ContactInitialStateProps, actions: any) => {
		try {
			actions.setSubmitting(true)
			// Find the differences
			const jsonDiff: Partial<ContactInitialStateProps> = diff(
				initialState as ContactInitialStateProps,
				values
			)

			// handle when user click on both
			const buzzR = getBusinessRelationShip(values)
			const Obj = { ...jsonDiff, businessRelationship: buzzR }

			// delete banking from obj
			delete Obj.banking

			const banking = detailedDiff(
				initialState?.banking as IBankInfo[],
				values.banking
			)

			const deletedBank = initialState.banking.filter(
				entry1 =>
					!values.banking.some(entry2 => entry1.id === entry2.id)
			)

			// deleteBankDetail
			if (deletedBank.length > 0) {
				await deleteBank(deletedBank)
			}

			// update or add
			for (const key in banking.updated) {
				await addOrUpdate(values, key as any)
			}

			// only for add
			for (const key in banking.added) {
				await addOrUpdate(values, key as any)
			}

			const res: IContactProps = await updateContacts(values?.id || '', {
				...Obj,
				phone: values.phone,
				business: business
			})
			enqueueSnackbar(translate('snackbar.successUpdate'), {
				variant: 'success'
			})
			onSuccess?.(res)
			onModalClose()
		} catch (error: any) {
			setAppError(error, actions)
		} finally {
			actions.setSubmitting(false)
		}
	}

	const deleteContact = async (id: string) => {
		const deletedContact = await softDeleteContact(id)
		analytics.track(SEGMENT_EVENTS.CONTACT_DELETE_SUCCESS)
		setOpenAlert(false)
		onSuccess?.(deletedContact)
		onModalClose()
		navigate(`/contacts/`)
		enqueueSnackbar('Your contact has been deleted', {
			variant: 'success'
		})
	}

	const onSubmit = (values: ContactInitialStateProps, actions: any) => {
		if (user) {
			onUpdate(values, actions)
		} else {
			onCreate(values, actions)
		}
	}

	useEffect(() => {
		if (user?.id) {
			if (
				user?.invoiceExists ||
				user?.orderExists ||
				user?.transactionExists ||
				user?.quotationExists
			) {
				setIsDeleteable(false)
			} else {
				setIsDeleteable(true)
			}
		}
	}, [user])

	return (
		<>
			<StyledModal
				sx={{
					display: 'flex',
					flexDirection: 'column'
				}}
				onClose={handleModalClose}
				{...props}
			>
				<Container>
					<FlexRow
						justify="space-between"
						align={'center'}
						style={{ width: '100%' }}
					>
						<Typography variant="h6" fontWeight={'600'}>
							{user
								? translate('contacts.form.viewContact')
								: translate('contacts.form.addContact')}
						</Typography>
						<CloseButtonWrapper>
							<IoIosClose
								size={25}
								onClick={() => {
									analytics.track(
										SEGMENT_EVENTS.CONTACT_CLOSE_ADD_NEW,
										{
											screen: 'contact_book'
										}
									)
									handleModalClose({}, 'escapeKeyDown')
								}}
							/>
						</CloseButtonWrapper>
					</FlexRow>
					{initialState && (
						<Formik
							initialValues={initialState}
							validationSchema={validationSchema}
							onSubmit={(values, actions) => {
								onSubmit(values, actions)
							}}
						>
							{({
								values,
								handleSubmit,
								isSubmitting,
								dirty
							}) => (
								<FlexCol style={{ width: '100%' }}>
									<Wrapper>
										<Gutter spacing={1.6} />
										<FieldImageDropZone
											name={'profilePic'}
											placeholder={ImagePlaceholder}
											icon={PersonOutlineIcon}
											api={uploadContactPic}
											text={values.name}
											payload={
												user
													? { contactId: user?.id }
													: {}
											}
										/>
										<Gutter spacing={1.6} />
										<UserForm />
										<Gutter spacing={2.5} />
										<ContactTabs
											isUser={!!user}
											name={'selectedTab'}
											values={values}
										/>
									</Wrapper>
									<Gutter spacing={0.2} />

									<FlexRow
										style={{
											width: '100%'
										}}
										justify={'flex-end'}
									>
										<Tooltip
											placement="top"
											title={
												!isDeleteable &&
												'This contact cannot be deleted due to previous recorded orders, quotations, payments, or invoices.'
											}
										>
											<div>
												{user?.id && (
													<DeleteButton
														disabled={!isDeleteable}
														onClick={() => {
															analytics.track(
																SEGMENT_EVENTS.CONTACT_DELETE_CLICK
															)
															setOpenAlert(true)
														}}
													>
														Delete Contact
													</DeleteButton>
												)}
											</div>
										</Tooltip>
										<Gutter gap={0.5} />
										<Button
											type="submit"
											disabled={!dirty || isSubmitting}
											onClick={() => handleSubmit()}
										>
											{translate(
												'contacts.form.saveContact'
											)}
										</Button>
									</FlexRow>
								</FlexCol>
							)}
						</Formik>
					)}
				</Container>
			</StyledModal>
			<AlertModal
				open={openAlert}
				setOpen={setOpenAlert}
				title="Delete Contact"
				message="Are you sure you want to delete this contact? This action cannot be undone."
				onDelete={() => {
					deleteContact(user?.id || '')
				}}
			/>
		</>
	)
}
