import React, { useState } from 'react'
import { Box, styled } from '@mui/system'
import { Paper, TableBody, TableRow } from '@mui/material'
import { useFormikContext } from 'formik'
import type { CurrentInvoiceProps } from 'screen/Invoices/NewCreate/Invoice'
import { FlexCol, FlexRow } from 'components/atoms/Flex'
import { ErrorText, Label } from 'components'
import { TableHeader } from 'screen/Invoices/NewCreate/Components/Table/TableHeader'
import { TableItem } from 'screen/Invoices/NewCreate/Components/Table/TableItem'
import { CreateInvoicesButton } from 'screen/Invoices/NewCreate/Components/AddItemButton'

const Wrapper = styled(FlexCol)`
	display: block;
	position: relative;
	padding: 5px;
	width: 100%;
	//height: 100%;
`

const TableWrapper = styled(Box)`
	grid-area: Table;
	min-height: 100px;
	height: fit-content;

	::-webkit-scrollbar {
		display: none;
	}
	-ms-overflow-style: none;
	scrollbar-width: none;
`

const StyledTableRow = styled(TableRow)`
	vertical-align: top;
`

const StyledPaper = styled(Paper)`
	width: 100%;
	height: 100%;
	overflow: auto;
	position: relative;
	background-color: ${({ theme }) => theme.palette.background.default};
`

const Row = styled(FlexRow)`
	width: 100%;
	padding: 8px 16px;
`

export const ItemTable = () => {
	const [isDiscountVisible, setIsDiscountVisible] = useState<boolean>(
		JSON.parse(localStorage.getItem('isDiscountVisible') || 'true')
	)
	const { values, errors } = useFormikContext<CurrentInvoiceProps>()

	const isDiscount =
		(values.items || []).reduce(
			(accumulator, item) => (item.discount?.amount || 0) + accumulator,
			0
		) > 0

	const isOpen = isDiscount ? true : isDiscountVisible

	return (
		<Wrapper>
			<Label>Names*</Label>
			<TableWrapper>
				<StyledPaper>
					<TableHeader
						isOpen={isOpen}
						setIsDiscountVisible={setIsDiscountVisible}
					/>
					<TableBody>
						{values.items &&
							values.items.map((row, _index) => (
								<StyledTableRow
									key={row.id}
									hover
									role="checkbox"
									tabIndex={-1}
								>
									<TableItem
										item={row}
										index={_index}
										isOpen={isOpen}
									/>
								</StyledTableRow>
							))}
					</TableBody>
					<Row>
						<CreateInvoicesButton />
					</Row>
				</StyledPaper>
			</TableWrapper>
			<ErrorText
				text={
					typeof errors?.items === 'string'
						? errors?.items
						: undefined
				}
			/>
		</Wrapper>
	)
}
