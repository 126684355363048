import React, { useCallback, useMemo } from 'react'
import { Typography, styled, useMediaQuery, useTheme } from '@mui/material'
import {
	INVOICE_STATUS_OPTIONS,
	LAYOUT_BREAKPOINT_WIDTH,
	PAYMENT_STATUS_OPTIONS,
	SEGMENT_EVENTS
} from 'data'
import { FlexRow } from 'components/atoms/Flex'
import { AlertModal, Gutter, type StatusOptionProps } from 'components'
import type { ColorsType, InvoiceSettingsType, InvoiceType } from 'typings'
import analytics from 'utils/segment'
import { useNavigate } from 'react-router-dom'
import { formatId } from 'helpers'
import { ShareButton } from './Component/ShareButton'
import { ActionButton } from './Component/ActionButton'
import { PublishShareWarningModal } from './Component/PublishShareWarningModal'
import { sendWhatsapp } from 'helpers/share'
import { DownloadInvoice } from './Component/DownloadInvoice'
import { recycleRestoreInvoice } from 'api/invoice'
import { useAppErrors } from 'hooks'
import { useSnackbar } from 'notistack'
import { ReturnInventoryModal } from './ReturnInventoryModal'
import { ReturnPaymentModal } from './ReturnPaymentModal'
import { ReissueInvoiceModal } from './ReissueInvoiceModal'

const Wrapper = styled(FlexRow)`
	width: 100%;
	flex-wrap: nowrap;
	align-items: center;
	justify-content: space-between;
	padding: 11px 16px 10px 16px;
	border-bottom: 1px solid ${({ theme }) => theme.palette.colors.gray['200']};
`

const HeaderText = styled(Typography)`
	margin-bottom: 4px;

	@media (max-width: ${LAYOUT_BREAKPOINT_WIDTH}px) {
		margin-bottom: 0;
	}
`

const StyledCapsule = styled(FlexRow, {
	shouldForwardProp: props => props !== 'color'
})<Pick<StatusOptionProps, 'color'>>`
	align-items: center;
	background-color: ${({ theme, color }) => theme.palette.colors[color][50]};
	border-radius: 30px;
	padding: 5px 12px;
	width: fit-content;
	flex-wrap: nowrap;

	:hover {
		cursor: not-allowed;
	}
`

type HeaderProps = {
	currentInvoice: InvoiceType
	publishInvoice: () => void
	pdfRef: React.RefObject<HTMLDivElement>
	invoiceSettings?: InvoiceSettingsType
}

export const Header: React.FC<HeaderProps> = ({
	currentInvoice,
	publishInvoice,
	pdfRef,
	invoiceSettings
}) => {
	const [openPublishShareWarningModal, setOpenPublishShareWarningModal] =
		React.useState(false)
	const [openDownload, setOpenDownload] = React.useState<boolean>(false)
	const [openAlert, setOpenAlert] = React.useState<boolean>(false)
	const [returnInventoryOpen, setReturnInventoryOpen] =
		React.useState<boolean>(false)
	const [returnInventorySelected, setReturnInventorySelected] =
		React.useState<string>('yes')
	const [returnPaymentsOpen, setReturnPaymentsOpen] =
		React.useState<boolean>(false)
	const [isReissueModalOpen, setIsReissueModalOpen] =
		React.useState<boolean>(false)

	const isMobile = useMediaQuery(`(max-width:${LAYOUT_BREAKPOINT_WIDTH}px)`)

	const theme = useTheme()
	const {
		palette: { colors }
	} = theme

	const navigate = useNavigate()
	const { setAppError } = useAppErrors()
	const { enqueueSnackbar } = useSnackbar()

	const onDelete = useCallback(
		async (id: string) => {
			try {
				await recycleRestoreInvoice([id])
				enqueueSnackbar('Successfully Deleted', {
					variant: 'success'
				})
				navigate('/invoices')
			} catch (e: any) {
				setAppError(e)
			}
		},
		[enqueueSnackbar, navigate, setAppError]
	)

	const status = useMemo(() => {
		if (currentInvoice.status && currentInvoice.status !== 'publish') {
			return INVOICE_STATUS_OPTIONS.find(
				p => p.value === currentInvoice.status
			)
		} else {
			return PAYMENT_STATUS_OPTIONS.find(
				p => p.value === currentInvoice.paymentStatus
			)
		}
	}, [currentInvoice.paymentStatus, currentInvoice.status])

	return (
		<Wrapper>
			<FlexRow align="center">
				<HeaderText
					variant={isMobile ? 'body2' : 'h6'}
					fontWeight={'medium'}
				>
					{`INV-${formatId(currentInvoice?.friendlyId)}`}
				</HeaderText>
				<Gutter gap={isMobile ? 0.3 : 0.5} />
				<StyledCapsule color={status?.color as ColorsType}>
					<Typography
						variant={'caption'}
						color={colors[status?.color as ColorsType]['500']}
					>
						{status?.label}
					</Typography>
				</StyledCapsule>
			</FlexRow>

			<FlexRow align={'center'}>
				{currentInvoice.status !== 'void' && (
					<ShareButton
						whatsappButtonClick={() => {
							analytics.track(SEGMENT_EVENTS.PDF_INV_SHARE, {
								channel_selected: 'WhatsApp'
							})
							if (currentInvoice.status === 'draft') {
								setOpenPublishShareWarningModal(true)
								return
							}
							sendWhatsapp(
								currentInvoice?.contact.phone.countryCode +
									currentInvoice?.contact.phone.number
							)
						}}
						printButtonClick={() => {
							analytics.track(SEGMENT_EVENTS.PDF_INV_SHARE, {
								channel_selected: 'Print'
							})
						}}
						pdfRef={pdfRef}
						currentInvoice={currentInvoice}
					/>
				)}
				<Gutter gap={isMobile ? 0.5 : 1} />
				{/* ToDo: Rework this component ActionButton */}
				<ActionButton
					currentInvoice={currentInvoice}
					editButtonClick={() => {
						analytics.track(SEGMENT_EVENTS.INVOICE_EDIT)
						navigate(
							`/invoice/create-invoice/${currentInvoice.id || 0}`,
							{
								state: { currentInvoice: currentInvoice }
							}
						)
					}}
					downloadButtonClick={() => setOpenDownload(true)}
					deleteButtonClick={() => {
						analytics.track(SEGMENT_EVENTS.INVOICE_DELETE)
						setOpenAlert(true)
					}}
					voidButtonClick={() => {
						setReturnInventoryOpen(true)
					}}
					reissueButtonClick={() => {
						setIsReissueModalOpen(true)
					}}
				/>
			</FlexRow>
			<PublishShareWarningModal
				open={openPublishShareWarningModal}
				onClose={() => {
					setOpenPublishShareWarningModal(false)
					publishInvoice()
				}}
				onCancel={() => setOpenPublishShareWarningModal(false)}
			/>
			<DownloadInvoice
				open={openDownload}
				onClose={() => setOpenDownload(false)}
				divRef={pdfRef}
				invoice={currentInvoice}
				invoiceSettings={invoiceSettings}
			/>
			<AlertModal
				open={openAlert}
				setOpen={setOpenAlert}
				title="Delete Invoice"
				message="Are you sure you want to delete this invoice?"
				messageLine2="This action is irreversible and will permanently delete the invoice and return referenced items to the inventory."
				onDelete={() => {
					onDelete(currentInvoice?.id || '0')
				}}
				deleteItemId={currentInvoice?.id || '0'}
			/>
			<ReturnInventoryModal
				open={returnInventoryOpen}
				onClose={() => {
					setReturnInventoryOpen(false)
					setReturnPaymentsOpen(true)
				}}
				onCancel={() => {
					setReturnInventoryOpen(false)
				}}
				returnInventorySelected={returnInventorySelected}
				setReturnInventorySelected={setReturnInventorySelected}
			/>
			<ReturnPaymentModal
				invoiceId={currentInvoice?.id || '0'}
				open={returnPaymentsOpen}
				onClose={() => {
					setReturnPaymentsOpen(false)
				}}
				returnInventorySelected={returnInventorySelected}
			/>
			<ReissueInvoiceModal
				invoiceId={currentInvoice?.id}
				open={isReissueModalOpen}
				onClose={() => setIsReissueModalOpen(false)}
			/>
		</Wrapper>
	)
}
