import React from 'react'
import type { CheckboxProps } from '@mui/material/Checkbox'
import Checkbox, { checkboxClasses } from '@mui/material/Checkbox'
import { styled } from '@mui/material/styles'

const StyledCheckBox = styled(Checkbox)`
	box-sizing: border-box;
	width: 16px;
	height: 16px;
	background-color: ${({ theme }) => theme.palette.colors.green['50']};
	border-radius: 4px;
	border-width: 1px;
	border-style: solid;
	border-color: ${({ theme, disabled }) =>
		disabled
			? theme.palette.colors.gray['400']
			: theme.palette.colors.green['600']};
	padding: 0;
	overflow: hidden;
	&:hover {
		border-color: ${({ theme }) => theme.palette.colors.green['300']};
		box-shadow: 0 0 0 4px ${({ theme }) => theme.palette.colors.gray['100']};
	}
	&.${checkboxClasses.checked} {
		color: ${({ theme, disabled }) =>
			disabled
				? theme.palette.colors.gray['100']
				: theme.palette.colors.green['50']};
		background-color: ${({ theme, disabled }) =>
			disabled
				? theme.palette.colors.gray['400']
				: theme.palette.colors.green['600']};
	}
`

export const CheckBox: React.ComponentType<CheckboxProps> = ({ ...props }) => {
	return <StyledCheckBox {...props} />
}
