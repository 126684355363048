import React from 'react'
import type { ChunkArg } from 'highlight-multiple-words'
import { findAll } from 'highlight-multiple-words'

type HighLightMultipleWordsProps = {
	textToHighlight: string
	searchWords: string[]
	renderHighlighterComponent?(text: string): void
	renderText?(text: string): void
}

/* This is not final version, it's only demo we'll update it later */
export const HighLightMultipleWords: React.ComponentType<
	HighLightMultipleWordsProps
> = ({
	searchWords,
	textToHighlight,
	renderHighlighterComponent,
	renderText
}) => {
	const chunks: ChunkArg[] = findAll({
		searchWords,
		textToHighlight
	})

	const HighLightText = ({ text }: { text: string }) => {
		return (
			<>
				{renderHighlighterComponent ? (
					renderHighlighterComponent(text)
				) : (
					<span
						key={text}
						style={{
							color: 'green' /* highlight style */,
							fontWeight: '600'
						}}
					>
						{text}
					</span>
				)}
			</>
		)
	}

	const RenderText = ({ text }: { text: string }) => {
		return (
			<>
				{renderText ? renderText(text) : <span key={text}>{text}</span>}
			</>
		)
	}

	return (
		<span>
			{chunks.map((chunk, index) => {
				const text: string = textToHighlight.substr(
					chunk.start,
					chunk.end - chunk.start
				)
				if (renderHighlighterComponent && renderText) {
					chunk.isHighlight
						? renderHighlighterComponent(text)
						: renderText(text)
				}

				return chunk.isHighlight ? (
					<HighLightText text={text} key={text + index} />
				) : (
					<RenderText key={text + index} text={text} />
				)
			})}
		</span>
	)
}
