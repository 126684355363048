import React, { type CSSProperties, useState } from 'react'
import { useField, useFormikContext } from 'formik'
import { Gutter, type TextInput } from 'components'
import { FlexCol, FlexRow } from 'components/atoms/Flex'
import { MdAddCircle } from 'react-icons/md'
import { Typography, useTheme } from '@mui/material'
import { type CustomAutoCompleteProps } from 'components/app/CutomAutoComplete'
import type { ITaxUnit } from 'typings'
import { useSelector } from 'react-redux'
import type { RootState } from 'store/index-reducer'
import { styled } from '@mui/system'
import { AddTaxModal } from 'screen/Settting/Taxes'
import { objectOmit } from 'helpers'
import type { CurrentInvoiceProps } from 'screen/Invoices/NewCreate/Invoice'
import { FieldAutoComplete } from 'components/molecules/FieldAutoComplete'
import analytics from 'utils/segment'
import { SEGMENT_EVENTS } from 'data'

const BottomBar = styled(FlexRow)`
	border-top: 1px solid ${({ theme }) => theme.palette.colors.gray['100']};
	padding: 10px 16px;
	align-items: center;
`

const Item = styled(FlexCol)<{ isDisabled?: boolean }>`
	padding: 10px 16px;
	width: 100%;
	${({ isDisabled, theme }) =>
		isDisabled && `background-color:  ${theme.palette.colors.gray['100']}`};

	:hover {
		background-color: ${({ theme, isDisabled }) =>
			isDisabled
				? theme.palette.colors.gray['100']
				: theme.palette.colors.blue['50']};
	}
`

export type TaxAutoCompleteProps = Omit<
	CustomAutoCompleteProps<ITaxUnit>,
	'onBlur' | 'onFocus' | 'options'
> & {
	name: string
	placeholder?: string
	wrapperStyle?: CSSProperties
	nextInputRef?: React.MutableRefObject<typeof TextInput> | null
}

export const TaxAutoComplete: React.ComponentType<TaxAutoCompleteProps> = ({
	name,
	wrapperStyle,
	...props
}) => {
	const [open, setOpen] = useState(false)
	const [, meta, helpers] = useField(name)
	const { taxUnits } = useSelector((state: RootState) => state.settings)
	const theme = useTheme()
	const {
		palette: { colors }
	} = theme
	const { values } = useFormikContext<CurrentInvoiceProps>()

	const RenderOption = ({ tax, ...props }: { tax: ITaxUnit }) => {
		const isDisabled = values.taxes?.some(
			obj2 => obj2.name === tax.name && obj2.rate === tax.rate
		)

		return (
			<Item {...props} isDisabled={isDisabled}>
				<Typography
					variant={'subtitle1'}
					fontWeight={500}
					color={isDisabled ? colors.gray['400'] : colors.gray['900']}
				>
					{tax.name} ({tax.rate}%)
				</Typography>
			</Item>
		)
	}

	return (
		<FlexCol align={'flex-start'} style={wrapperStyle}>
			<FieldAutoComplete
				name={`${name}.name`}
				onBlur={() => helpers.setTouched(true)}
				options={taxUnits.dataSource}
				renderOption={(props, option) => {
					return <RenderOption tax={option} {...props} />
				}}
				onChange={(e, value) => {
					const isDisabled = values.taxes?.some(
						obj2 =>
							obj2.name === value.name && obj2.rate === value.rate
					)
					if (e.type === 'click' && value !== null) {
						if (!isDisabled) {
							helpers.setValue(objectOmit(value, 'id'))
							analytics.track(SEGMENT_EVENTS.INVOICE_SELECT_TAX)
						}
					}
				}}
				size={'small'}
				footer={
					<BottomBar
						onClick={() => {
							setOpen(true)
							analytics.track(SEGMENT_EVENTS.INVOICE_ADD_TAX)
						}}
					>
						<MdAddCircle color={colors.green['600']} size={20} />
						<Gutter gap={1} />
						<Typography variant={'subtitle2'}>New Tax</Typography>
					</BottomBar>
				}
				sx={{ width: 200 }}
				value={
					meta.value.name
						? `${meta.value.name} (${meta.value.rate}%)`
						: ' '
				}
				filterOptions={(opt, state) => {
					return taxUnits.dataSource.filter(
						f =>
							f.name
								.toLowerCase()
								.search(state.inputValue.toLowerCase()) !== -1
					)
				}}
				noOptionsText={<div>No data found</div>}
				{...props}
			/>
			<AddTaxModal
				open={open}
				onClose={() => setOpen(false)}
				onSuccess={res => helpers.setValue(objectOmit(res, 'id'))}
			/>
		</FlexCol>
	)
}
