import type { ISession, IUser } from 'typings/auth'

export const HYDRATE_SESSION = 'HYDRATE_SESSION'
export const LOGOUT = 'LOGOUT'
export const FETCH_CURRENT_USER = 'FETCH_CURRENT_USER'
export const SET_TOKEN = 'SET_TOKEN'
export const SET_CURRENT_USER = 'SET_CURRENT_USER'
export const SET_CURRENT_BUSINESS = 'SET_CURRENT_BUSINESS'

type HydrateSession = {
	type: typeof HYDRATE_SESSION
} & ISession

interface ILogout {
	type: typeof LOGOUT
}

interface IFetchCurrentUser {
	type: typeof FETCH_CURRENT_USER
}

interface ISetCurrentUser {
	type: typeof SET_CURRENT_USER
	payload: IUser
}

interface ISetToken {
	type: typeof SET_TOKEN
	payload: string
}

interface ISetCurrentBusiness {
	type: typeof SET_CURRENT_BUSINESS
	payload: string
}

export type UserActionsTypes =
	| ISetCurrentUser
	| ISetToken
	| HydrateSession
	| ILogout
	| IFetchCurrentUser
	| ISetCurrentBusiness
