import { useDimension } from 'hooks'
import { BsWhatsapp } from 'react-icons/bs'
import {
	MdOutlineCloudDownload,
	MdOutlineKeyboardArrowDown,
	MdOutlinePrint
} from 'react-icons/md'
import { Button, Popover, styled, Typography } from '@mui/material'
import React, { useCallback } from 'react'
import { FlexCol, FlexRow } from 'components/atoms/Flex'
import { AlertModal, Gutter, TextInput } from 'components'
import { useNavigate } from 'react-router-dom'
import type { QuotationType } from 'typings'
import { recycleRestoreQuotation, updateAnQuotation } from 'api/quotation'
import { useAppErrors } from 'hooks/useAppErrors'
import { objectOmit, pascalCase } from 'helpers'
import { useSnackbar } from 'notistack'
import { sendWhatsapp } from 'helpers/share'
import analytics from 'utils/segment'
import { LAYOUT_PADDING, SEGMENT_EVENTS, StatusOptions } from 'data'
import ReactToPrint from 'react-to-print'
import { DownloadQuotation } from 'screen/Quotations/View/Component/DownloadQuotation'

const IconContainer = styled(FlexCol)`
	background-color: ${({ theme }) => theme.palette.colors.green['50']};
	border-radius: 50%;
	height: 110px;
	width: 110px;
	justify-content: center;
	align-items: center;
	margin-left: 10px;
	text-decoration: none;

	:hover {
		cursor: pointer;
	}
`

const TypographyWrapper = styled(Typography)`
	color: ${({ theme }) => theme.palette.colors.green['900']};
	font-weight: 500;
	padding-top: 5px;
	text-decoration: none;
`
const SaveButton = styled(Button)(({ theme }) => ({
	'&:disabled': {
		backgroundColor: theme.palette.colors.gray['200'],
		color: theme.palette.colors.gray['500']
	},
	backgroundColor: theme.palette.colors.yellow['300'],
	color: theme.palette.colors.gray['900'],
	fontWeight: 600,
	borderRadius: 5,
	width: '210px',
	margin: '10px'
}))

const DeleteButton = styled(Button)`
	background-color: ${({ theme }) => theme.palette.colors.red['50']};
	color: ${({ theme }) => theme.palette.colors.red['700']};
	font-weight: 600;
	border-radius: 8px;
	width: 210px;
	margin: 10px;
`
const Item = styled(FlexRow)(({ theme }) => ({
	'&:hover': {
		backgroundColor: theme.palette.colors.gray['50']
	},
	backgroundColor: theme.palette.background.default,
	width: '230px',
	cursor: 'pointer',
	padding: '5px 7px'
}))

type PdfRightWrapperProps = {
	currentQuotation: QuotationType
	pdfRef: React.RefObject<HTMLDivElement>
}

type PaymentStatus = 'approved' | 'closed' | 'private'

export const RightOption: React.ComponentType<PdfRightWrapperProps> = ({
	currentQuotation,
	pdfRef
}) => {
	const [modalOpen, setModalOpen] = React.useState(false)
	const [openAlert, setOpenAlert] = React.useState(false)
	const { height } = useDimension()
	const navigate = useNavigate()
	const { setAppError } = useAppErrors()
	const { enqueueSnackbar } = useSnackbar()

	const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
		null
	)

	const handleClick = (event: any) => {
		setAnchorEl(event.currentTarget)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}
	const open = Boolean(anchorEl)

	const onStatusChange = useCallback(
		async (opt: PaymentStatus, quotationId: string) => {
			try {
				const res = await updateAnQuotation(quotationId, {
					status: opt
				})
				handleClose()

				navigate(`/quotation/view/${res.id}`, {
					replace: true,
					state: { data: res }
				})
			} catch (e: any) {
				setAppError(e)
			}
		},
		[navigate, setAppError]
	)

	const onDelete = useCallback(
		async (id: string) => {
			try {
				await recycleRestoreQuotation([id])
				analytics.track(SEGMENT_EVENTS.QUO_DELETE_SUCCESS)
				enqueueSnackbar('Successfully Deleted', {
					variant: 'success'
				})
				navigate('/quotation')
			} catch (e: any) {
				setAppError(e)
			}
		},
		[enqueueSnackbar, setAppError]
	)

	return (
		<FlexCol
			style={{ height: height - LAYOUT_PADDING * 2 - 60 }}
			justify="space-between"
			align="center"
		>
			<FlexCol>
				<Typography variant="h6" fontWeight={'500'}>
					Share Quotation
				</Typography>
				<Gutter spacing={1} />

				<FlexRow align="center" justify="space-around">
					<IconContainer
						onClick={() => {
							analytics.track(SEGMENT_EVENTS.PDF_QUO_SHARE, {
								channel_selected: 'WhatsApp'
							})
							sendWhatsapp(
								currentQuotation?.contact.phone.countryCode +
									currentQuotation?.contact.phone.number
							)
						}}
					>
						<BsWhatsapp size={'30px'} color={'green'} />
						<Gutter spacing={0.2} />
						<TypographyWrapper variant="caption">
							Whatsapp
						</TypographyWrapper>
					</IconContainer>
					<IconContainer
						onClick={() => {
							setModalOpen(true)
						}}
					>
						<MdOutlineCloudDownload size={'30px'} color={'green'} />
						<Gutter spacing={0.2} />
						<TypographyWrapper variant="caption">
							Download
						</TypographyWrapper>
					</IconContainer>
					<ReactToPrint
						trigger={() => (
							<IconContainer
								onClick={() => {
									analytics.track(
										SEGMENT_EVENTS.PDF_QUO_SHARE,
										{
											channel_selected: 'Print'
										}
									)
								}}
							>
								<MdOutlinePrint size={'30px'} color={'green'} />
								<Gutter spacing={0.2} />
								<TypographyWrapper variant="caption">
									Print
								</TypographyWrapper>
							</IconContainer>
						)}
						content={() => pdfRef.current}
					/>
				</FlexRow>
				<Gutter spacing={2} />
				<Typography variant="body2" fontWeight={'500'}>
					Status
				</Typography>

				<TextInput
					value={pascalCase(currentQuotation?.status)}
					onClick={handleClick}
					size={'small'}
					sx={{ width: '230px' }}
					InputProps={{
						endAdornment: <MdOutlineKeyboardArrowDown />
					}}
				/>
				<Popover
					open={open}
					anchorEl={anchorEl}
					elevation={2}
					onClose={handleClose}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'left'
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'left'
					}}
				>
					{StatusOptions.map(option => {
						return (
							<Item
								align="center"
								key={option.value}
								onClick={() =>
									onStatusChange(
										option.value as PaymentStatus,
										currentQuotation?.id as string
									)
								}
							>
								<Typography variant="body2">
									{option.label}
								</Typography>
							</Item>
						)
					})}
				</Popover>
			</FlexCol>
			<FlexCol>
				<SaveButton
					onClick={() => {
						navigate('/invoice/create-invoice', {
							state: {
								currentInvoice: objectOmit(
									currentQuotation,
									'id'
								)
							}
						})
					}}
				>
					Generate Invoice
				</SaveButton>
				<DeleteButton
					onClick={() => {
						analytics.track(SEGMENT_EVENTS.QUO_DELETE_FROM_PDF)
						setOpenAlert(true)
					}}
				>
					Delete Quotation
				</DeleteButton>
			</FlexCol>
			<AlertModal
				open={openAlert}
				setOpen={setOpenAlert}
				title="Move to Recycle Bin?"
				message="This quotation will be moved to the recycle bin and you can restore it later."
				onDelete={() => {
					onDelete(currentQuotation?.id as string)
				}}
				deleteItemId={currentQuotation?.id as string}
			/>
			<DownloadQuotation
				open={modalOpen}
				onClose={() => setModalOpen(false)}
				divRef={pdfRef}
				id={currentQuotation.id}
			/>
		</FlexCol>
	)
}
