import React, { useState } from 'react'
import { styled } from '@mui/system'
import { InputAdornment, Typography, useTheme } from '@mui/material'
import { Abbr, FieldCurrencyInput, Gutter } from 'components'
import { FlexRow } from 'components/atoms/Flex'
import { useFormikContext } from 'formik'
import type { CurrentInvoiceProps } from 'screen/Invoices/NewCreate/Invoice'
import { IoIosClose } from 'react-icons/io'

const Grid = styled('div')`
	display: grid;

	width: 100%;

	grid-template-columns: 1fr 1fr;
	justify-content: space-between;
	align-items: center;
`

const Row = styled(FlexRow)`
	width: 100%;
	justify-content: flex-end;
	flex-wrap: nowrap;
	align-items: center;
`

const Button = styled(Typography)`
	color: ${({ theme }) => theme.palette.colors['blue']['600']};

	:hover {
		cursor: pointer;
	}
`

const Box = styled(FlexRow)`
	padding-left: 10px;
	border-left: 1px solid ${({ theme }) => theme.palette.colors.gray['300']};
`

const Icon = styled(IoIosClose)`
	:hover {
		cursor: pointer;
	}
`

export const AdvancePayment = () => {
	const { values, setFieldValue } = useFormikContext<CurrentInvoiceProps>()
	const [isAdvance, setIsAdvance] = useState(
		!isNaN(parseFloat(values.advance ? values.advance?.amount : ''))
	)
	const theme = useTheme()
	const {
		palette: { colors }
	} = theme

	return (
		<>
			{isAdvance && (
				<Grid>
					<Typography
						variant={'subtitle2'}
						color={colors['gray']['900']}
					>
						Advance Payment
					</Typography>
					<Row>
						<FieldCurrencyInput
							name={'advance.amount'}
							size={'small'}
							style={{ maxWidth: 200, width: '100%' }}
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<Box>
											<Typography>
												<Abbr
													title={'PKR'}
													length={10}
												/>
											</Typography>
										</Box>
									</InputAdornment>
								)
							}}
						/>
						<Gutter gap={0.2} />
						<Icon
							size={25}
							onClick={() => {
								setFieldValue('advance.amount', '')
								setIsAdvance(false)
							}}
						/>
					</Row>
				</Grid>
			)}
			{!isAdvance && (
				<Button onClick={() => setIsAdvance(true)}>
					<Typography variant={'subtitle2'}>
						Advance Payment
					</Typography>
				</Button>
			)}
		</>
	)
}
