import { axios } from 'store'
import type { DataResponseType } from 'typings'
import queryString from 'query-string'
import moment from 'moment/moment'
import type { LoadsheetType } from 'typings/loadsheet'

export const fetchLoadSheetList = async (
	businessId: string,
	payload: {
		page: number
		limit: number
		searchKey?: string
		startDate?: string
		endDate?: string
	}
) => {
	const { startDate, endDate } = payload

	const convertedStartDate = startDate
		? moment(startDate).utc(true).valueOf().toString()
		: undefined
	const convertedEndDate = endDate
		? moment(endDate).utc(true).valueOf().toString()
		: undefined

	const url = `/invoices/loadsheet/list?business=${businessId}&sortOrder=DESC&sortBy=initiationAt&${queryString.stringify(
		{
			...payload,
			startDate: convertedStartDate,
			endDate: convertedEndDate
		}
	)}`

	try {
		const { data } = await axios.get<DataResponseType<LoadsheetType>>(url)

		return data
	} catch (error: any) {
		throw error.response.data
	}
}

export const createLoadSheet = async (payload: LoadsheetType) => {
	const url = '/invoices/loadsheet'

	try {
		const { data } = await axios.post(url, payload)

		return data
	} catch (error: any) {
		throw error.response.data
	}
}

export const getALoadSheet = async (business: string, loadsheetId: string) => {
	try {
		const { data } = await axios.get<LoadsheetType>(
			`/invoices/loadsheet/${business}/${loadsheetId}`
		)

		return data
	} catch (error: any) {
		throw error.response.data
	}
}

export const updateLoadSheet = async (
	loadSheetId: string,
	payload: LoadsheetType
) => {
	const url = `/invoices/loadsheet/${loadSheetId}`

	try {
		const { data } = await axios.patch(url, payload)

		return data
	} catch (error: any) {
		throw error.response.data
	}
}

export const deleteLoadSheet = async (
	businessId: string,
	loadSheetId: string[]
) => {
	const url = '/invoices/loadsheet'
	const payload = {
		business: businessId,
		loadSheetId
	}

	try {
		const { data } = await axios.delete(url, { data: payload })

		return data
	} catch (error: any) {
		throw error.response.data
	}
}

export const getSignedUrlForLoadSheetPdf = async (payload: LoadsheetType[]) => {
	const url = '/pdf-maker/generate/loadsheet'

	try {
		const { data } = await axios.post(url, payload)
		return data
	} catch (error: any) {
		throw error.response.data
	}
}

export const updateLoadSheetComment = async (
	loadSheetId: string,
	payload: {
		business: string
		comments: string
	}
) => {
	const url = `/invoices/loadsheet/comment/${loadSheetId}`

	try {
		const { data } = await axios.patch(url, payload)

		return data
	} catch (error: any) {
		throw error.response.data
	}
}
