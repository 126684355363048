import React from 'react'
import { FlexRow } from 'components/atoms/Flex'
import { styled } from '@mui/system'
import SmallLogo from 'assets/ordr-small.svg'
import Logo from 'assets/logo.svg'

const Wrapper = styled(FlexRow)`
	max-height: 60px;
	padding: 10px 0;
	width: 100%;
	border-radius: 4px;
	overflow: hidden;
`

const Image = styled('img')`
	height: 21px;
`

export const SidebarHeader = ({ isOpen }: { isOpen: boolean }) => {
	return (
		<>
			<Wrapper
				justify={isOpen ? 'flex-start' : 'center'}
				align={'center'}
			>
				<Image
					src={!isOpen ? SmallLogo : Logo}
					alt={'logo'}
					style={{ marginLeft: isOpen ? 8 : 0 }}
				/>
			</Wrapper>
		</>
	)
}
