export interface DiscountResult {
	discountedAmount: number
	discountPercentage: number | string
}

export const getFileExtension = (filename: string = ''): string => {
	return filename.split('.').pop() || ''
}

export const isImageFileExtension = (filename: string = ''): boolean => {
	const extension = getFileExtension(filename)
	return ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'].includes(extension)
}

export const calculateDiscount = (
	totalAmount: number | string,
	discount: string | number,
	type: 'percent' | 'flat'
): number => {
	// Convert totalAmount and discount to numbers if they are strings
	const amount =
		typeof totalAmount === 'string' ? parseFloat(totalAmount) : totalAmount
	const discountValue =
		typeof discount === 'string' ? parseFloat(discount) : discount

	if (isNaN(amount) || isNaN(discountValue)) {
		throw new Error(
			'Invalid input. Please provide valid numeric values for totalAmount and discount.'
		)
	}

	if (type === 'percent') {
		// Calculate discount percentage
		const discountPercentage = discountValue / 100
		const discountedAmount = amount * discountPercentage
		return amount - discountedAmount
	} else if (type === 'flat') {
		// Apply flat discount
		return amount - discountValue
	} else {
		throw new Error(
			'Invalid discount type. Please choose either "percent" or "flat".'
		)
	}
}

export const calculateDiscountPercentage = (
	totalAmount: number | string = 0,
	discount: string | number = 0,
	type: 'percent' | 'flat' = 'percent'
): DiscountResult => {
	// Convert totalAmount and discount to numbers if they are strings
	const amount =
		typeof totalAmount === 'string' ? parseFloat(totalAmount) : totalAmount
	const discountValue =
		typeof discount === 'string' ? parseFloat(discount) : discount

	if (isNaN(amount) || isNaN(discountValue)) {
		throw new Error(
			'Invalid input. Please provide valid numeric values for totalAmount and discount.'
		)
	}

	let discountedAmount: number
	let discountPercentage: number

	if (type === 'percent') {
		discountPercentage = discountValue
		discountedAmount = (amount * discountValue) / 100
	} else if (type === 'flat') {
		discountedAmount = discountValue
		discountPercentage = (discountValue / amount) * 100 //(discountValue / amount) * 100
	} else {
		throw new Error(
			'Invalid discount type. Please choose either "percent" or "flat".'
		)
	}

	return {
		discountedAmount,
		discountPercentage: parseFloat(
			`${isNaN(discountPercentage) ? 0 : discountPercentage}`
		).toFixed(2)
	}
}

export const convertCamelCaseToTitleCase = (inputString: string) => {
	// Replace camelCase with space-separated words
	const spacedWords = inputString.replace(/([a-z])([A-Z])/g, '$1 $2')

	// Capitalize the first letter of each word
	return spacedWords.replace(/\b\w/g, function (match) {
		return match.toUpperCase()
	})
}

