import React, { useCallback, useMemo } from 'react'
import {
	Button,
	ClickAwayListener,
	Fade,
	Popper,
	styled,
	Typography,
	useMediaQuery,
	useTheme
} from '@mui/material'
import PopupState, { bindPopper, bindToggle } from 'material-ui-popup-state'
import { FlexCol, FlexRow } from 'components/atoms/Flex'
import { BiCheck } from 'react-icons/bi'
import { KeyboardArrowDown } from '@mui/icons-material'
import { Capsule } from 'components'
import { LAYOUT_BREAKPOINT_WIDTH } from 'data'
import type { ProfileFilterDataSourceProps, ProfileTypes } from 'typings'

const Wrapper = styled(FlexCol)`
	background-color: ${({ theme }) => theme.palette.background.default};
	border: 1px solid ${({ theme }) => theme.palette.background.paper};
	border-radius: 8px;
	margin-top: 5px;
	box-shadow: 0 12px 16px -4px rgba(16, 24, 40, 0.08),
		0px 4px 6px -2px rgba(16, 24, 40, 0.03);
	overflow: hidden;
`

const Option = styled(FlexRow, {
	shouldForwardProp: props => props !== 'selected'
})<{ selected: boolean }>`
	justify-content: space-between;
	align-items: center;
	padding: 12px 20px;
	width: 100%;
	background-color: ${({ theme, selected }) =>
		selected
			? theme.palette.colors.green['50']
			: theme.palette.background.default};

	:hover {
		cursor: pointer;
		background-color: ${({ theme }) => theme.palette.colors.green['100']};
	}
`

const NumberCapsule = styled(Capsule)`
	background-color: ${({ theme }) => theme.palette.colors.gray['100']};
	color: ${({ theme }) => theme.palette.colors.black['700']};
	padding: 2px 10px;
	align-content: center;
	font-size: 14px;
	font-weight: 500;
`

const Col = styled(FlexCol)`
	min-width: 270px;
`

const HeaderText = styled(Typography)`
	margin-bottom: 4px;
	color: ${({ theme }) => theme.palette.colors.black['900']};

	@media (max-width: ${LAYOUT_BREAKPOINT_WIDTH}px) {
		margin-bottom: 0;
	}
`

const ButtonWrapper = styled(Button)`
	padding-left: 0;
`

type ProfileButtonProps = {
	dataSource: ProfileFilterDataSourceProps[]
	capsuleNumber?: number
	onSelect?(text: ProfileTypes): void
	filterObj: ProfileTypes
}

export const ProfileFilter: React.ComponentType<ProfileButtonProps> = ({
	dataSource,
	onSelect,
	filterObj,
	capsuleNumber
}) => {
	const theme = useTheme()
	const {
		palette: { colors }
	} = theme
	const isMobile = useMediaQuery(`(max-width:${LAYOUT_BREAKPOINT_WIDTH}px)`)

	const RenderFilter = useCallback(
		({ label, value }: ProfileFilterDataSourceProps) => {
			const isFilter = value === filterObj

			return (
				<Col>
					<Option
						align={'flex-start'}
						selected={isFilter}
						onClick={() => {
							onSelect?.(value)
						}}
					>
						<Typography variant={'body1'}>{label}</Typography>
						{isFilter && <BiCheck color={colors.green['600']} />}
					</Option>
				</Col>
			)
		},
		[colors.green, onSelect, filterObj]
	)

	const label = useMemo(() => {
		let text: string | undefined = undefined

		dataSource.forEach(d => {
			d.value === filterObj && (text = d.label)
		})

		return text
	}, [dataSource, filterObj])

	return (
		<PopupState
			variant="popper"
			popupId="demo-popup-popper"
			disableAutoFocus={true}
		>
			{popupState => (
				<ClickAwayListener onClickAway={() => popupState.close()}>
					<div>
						<ButtonWrapper {...bindToggle(popupState)}>
							<HeaderText
								variant={isMobile ? 'body2' : 'h6'}
								fontWeight={'medium'}
							>
								{label ? label : 'Filter'}
							</HeaderText>
							{capsuleNumber &&
								filterObj === 'businessManagement' && (
									<NumberCapsule
										name={capsuleNumber.toString()}
										value="numOfInvoices"
									/>
								)}
							<KeyboardArrowDown
								sx={{ color: colors.black['900'] }}
								fontSize="small"
							/>
						</ButtonWrapper>
						<Popper
							{...bindPopper(popupState)}
							transition
							placement="bottom-start"
						>
							{({ TransitionProps }) => (
								<Fade {...TransitionProps} timeout={350}>
									<Wrapper>
										{dataSource.map(d => (
											<React.Fragment key={d.id}>
												<RenderFilter {...d} />
											</React.Fragment>
										))}
									</Wrapper>
								</Fade>
							)}
						</Popper>
					</div>
				</ClickAwayListener>
			)}
		</PopupState>
	)
}
