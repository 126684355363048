import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { styled } from '@mui/system'
import { Button, Gutter } from 'components'
import { FlexCol, FlexRow } from 'components/atoms/Flex'
import { Typography } from '@mui/material'
import Logo from 'assets/Logo.png'
import checkmark from 'assets/onboarding/checkmark.png'

const Wrapper = styled(FlexCol)`
	width: 100%;
	height: 100%;
	min-height: 100vh;
	padding: 40px;
	align-items: center;
`

const Box = styled(FlexCol)`
	width: 100%;
	height: 100%;
	flex: 1;
	justify-content: space-between;
`

const Image = styled('img')`
	height: 34px;
	width: 90px;
`

const CheckBoxImg = styled('img')`
	width: 75px;
	height: 75px;
`

const StyledCancelButton = styled(Button)`
	background-color: ${({ theme }) => theme.palette.background.default};
	width: 90px;
	height: 44px;
	color: ${({ theme }) => theme.palette.colors.gray['700']};
	border: 1px solid ${({ theme }) => theme.palette.colors.gray['300']};
	border-radius: 8px;
	padding: 0 40px;

	:hover {
		background-color: #f1f1f1;
	}
`

export const OrderConfirmation = () => {
	const navigate = useNavigate()
	const { state } = useLocation()
	return (
		<Wrapper>
			<Box>
				<div />
				<FlexCol
					align={'center'}
					justify={'center'}
					style={{ width: '100%' }}
				>
					<CheckBoxImg src={checkmark} />
					<Gutter spacing={2} />
					<Typography fontWeight={600} variant={'h6'}>
						Your request has been sent to {state.business?.name}!
					</Typography>
					<Gutter spacing={1.7} />
					<StyledCancelButton
						onClick={() => navigate(`/order-form/${state.id}`)}
					>
						Okay
					</StyledCancelButton>
				</FlexCol>
				<FlexRow
					align={'center'}
					style={{ marginTop: 20, alignSelf: 'flex-start' }}
				>
					<Typography>Powered by</Typography>
					<Gutter gap={0.3} />
					<Image src={Logo} />
				</FlexRow>
			</Box>
		</Wrapper>
	)
}
