import React from 'react'
import { styled } from '@mui/system'
import { FlexRow } from 'components/atoms/Flex'
import { Capsule } from 'components'
import type { ColorsType } from 'typings'
import { useField } from 'formik'
import analytics from 'utils/segment'
import { ORDER_TYPE, SEGMENT_EVENTS } from 'data'

const StyledCapsule = styled(Capsule, {
	shouldForwardProp: props => props !== 'isSelected' && props !== 'color'
})<{ isSelected?: boolean; color?: ColorsType }>`
	padding: 7px 25px;
	font-size: 14px;
	font-weight: 500;
	background-color: ${({ color, isSelected, theme }) =>
		isSelected
			? theme.palette.colors[color || 'gray']['100']
			: 'transparent'};
	border: 1px
		${({ color, isSelected, theme }) =>
			!isSelected
				? theme.palette.colors[color || 'gray']['300']
				: 'transparent'}
		solid;

	color: ${({ color, isSelected, theme }) =>
		isSelected
			? theme.palette.colors[color || 'gray']['700']
			: theme.palette.colors['gray']['700']};
`

type CapsuleGroupProps = {
	name: string
	disabled?: boolean
}

export const CapsuleGroup: React.ComponentType<CapsuleGroupProps> = ({
	name,
	disabled
}) => {
	const [, meta, helpers] = useField(name)

	const handleClick = (value: string) => {
		helpers.setValue(value)
		if (value == 'inquiry') analytics.track(SEGMENT_EVENTS.NEW_INQUIRY)
		else analytics.track(SEGMENT_EVENTS.NEW_ORDER)
	}

	return (
		<FlexRow>
			{ORDER_TYPE.map(type => (
				<StyledCapsule
					key={type.id}
					name={type.name}
					value={type.value}
					color={type.color as ColorsType}
					isSelected={meta.value === type.value}
					onItemClick={value => handleClick(value)}
					disabled={disabled}
				/>
			))}
		</FlexRow>
	)
}
