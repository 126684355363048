import React from 'react'
import { Gutter } from 'components/atoms/Gutter'
import { Typography } from '@mui/material'
import { styled } from '@mui/system'
import { FlexCol } from 'components/atoms/Flex'

const Image = styled('img')`
	min-width: 100px;
	max-width: 300px;
`

const Center = styled(FlexCol)`
	width: 100%;
	height: 100%;
	position: absolute;
	align-self: center;
	align-items: center;
	justify-content: center;
`

type CenterImageProps = {
	text?: string
	image: string
}

export const CenterImage: React.ComponentType<CenterImageProps> = ({
	text,
	image
}) => {
	return (
		<Center>
			<Image src={image} />
			<Gutter spacing={0.5} />
			{text && <Typography variant={'body1'}>{text}</Typography>}
		</Center>
	)
}
