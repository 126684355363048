import { type ModalProps, styled } from '@mui/material'
import React from 'react'
import { Gutter } from 'components'
import { FlexCol } from 'components/atoms/Flex'
import type { IContactProps } from 'typings'
import { Calculator } from 'react-mac-calculator'
import { StyledModal } from 'components/StyledModal'
import { IoIosClose } from 'react-icons/io'

const Container = styled(FlexCol)`
	align-items: flex-end;
	background-color: ${({ theme }) => theme.palette.background.default};
	padding: 20px;
	z-index: 0;
	border-radius: 10px;
`

const CloseButtonWrapper = styled(FlexCol)`
	justify-content: center;
	align-items: center;
	height: 30px;
	width: 30px;
	right: 1.75em;

	&:hover {
		cursor: pointer;
		border-radius: 25%;
		background-color: ${({ theme }) => theme.palette.colors.gray['100']};
		display: flex;
	}
`

type Props = {
	onContactSelect?(contact: IContactProps): void
} & Omit<ModalProps, 'children'>

export const CalculatorModal: React.ComponentType<Props> = ({
	onClose,
	...props
}) => {
	return (
		<StyledModal onClose={() => onClose?.({}, 'escapeKeyDown')} {...props}>
			<Container>
				<CloseButtonWrapper>
					<IoIosClose
						size={25}
						onClick={() => onClose?.({}, 'escapeKeyDown')}
					/>
				</CloseButtonWrapper>
				<Gutter spacing={2} />
				<Calculator />
			</Container>
		</StyledModal>
	)
}
