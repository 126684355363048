import React, { useState } from 'react'
import { Button, styled, Typography, useTheme } from '@mui/material'
import { StyledModal } from 'components/StyledModal'
import { FlexCol, FlexRow } from 'components/atoms/Flex'
import { useAppErrors } from 'hooks'
import Warning from 'assets/alerts/warning.png'
import { BouncingDotsLoader, Gutter } from 'components'
import type { InvoiceType } from 'typings'
import { enqueueSnackbar } from 'notistack'
import { TransparentButton } from 'components/atoms/Button/TransparentButton'
import { useSettings } from 'context/settings'
import { publishBulkInvoices } from 'api/invoice'
import { BulkPublishErrorModal } from './BulkPublishingErrorModal'
import analytics from 'utils/segment'
import { SEGMENT_EVENTS } from 'data'

const Wrapper = styled('div')`
	width: 520px;
`

const Background = styled('div')`
	padding: 20px;
	border-radius: 10px;
	background-color: ${({ theme }) => theme.palette.background.default};
	margin-top: 20px;
	margin-bottom: 20px;
	overflow-y: auto;
`
const WrapperButton = styled(Button)(({ theme }) => ({
	backgroundColor: theme.palette.colors.green['600'],
	color: theme.palette.colors.white['900'],
	padding: '8px 12px',
	borderRadius: '8px',
	height: '40px',
	'&:hover': {
		background: theme.palette.colors.green['700']
	}
}))

type BulkPublishModalProps = {
	open: boolean
	onClose(): void
	invoices: InvoiceType[]
	onSuccess?: () => void
}

export const BulkPublishModal: React.ComponentType<BulkPublishModalProps> = ({
	onClose,
	open,
	invoices,
	onSuccess
}) => {
	const { setAppError } = useAppErrors()
	const theme = useTheme()
	const { colors } = theme.palette
	const [isDownloading, setIsDownloading] = useState<boolean>(false)
	const [erroredInvoices, setErroredInvoices] = useState<any[]>([])

	const [openErrorModal, setOpenErrorModal] = useState<boolean>(false)
	const { business } = useSettings()

	const handlePublishBulkInvoices = async (invoices: InvoiceType[]) => {
		try {
			setIsDownloading(true)
			const invoiceIds = invoices.map((obj: InvoiceType) => obj.id)
			const res = await publishBulkInvoices({ invoiceIds, business })

			setIsDownloading(false)

			// close modal
			onClose()
			res?.invoicesError.length < 1 &&
				analytics.track(SEGMENT_EVENTS.INVOICE_SUCCESS_BULK_PUBLISH, {
					invoices_selected_count: invoices.length,
					selection_made: 'publish'
				})
			enqueueSnackbar(`${res.message}`, {
				variant: res?.invoicesError.length > 0 ? 'error' : 'success'
			})
			setErroredInvoices(res?.invoicesError)

			if (res?.invoicesError.length > 0) {
				setOpenErrorModal(true)
			}
		} catch (e: any) {
			setIsDownloading(false)
			// close modal
			onClose()
			setErroredInvoices(e?.invoicesError)
			analytics.track(SEGMENT_EVENTS.INVOICE_FAILURE_BULK_PUBLISH, {
				invoices_selected_count: e?.invoicesError.length
			})
			enqueueSnackbar(
				`Unable to publish invoices. Please attempt again`,
				{
					variant: 'error'
				}
			)
			if (e?.invoicesError.length > 0) {
				setOpenErrorModal(true)
			}
			setAppError(e)
		}
		onSuccess?.()
	}

	return (
		<>
			<StyledModal open={open} onClose={onClose}>
				<Background>
					<Wrapper>
						<FlexCol justify="space-between">
							<FlexRow>
								<img
									alt={'warning'}
									src={Warning}
									style={{
										width: '50px',
										height: '50px'
									}}
								/>
								<Gutter gap={2} />
								<FlexCol style={{ width: '420px' }}>
									<Typography variant={'h6'} fontWeight={600}>
										Bulk Publish Invoices
									</Typography>
									<Gutter spacing={0.3} />

									<Typography
										variant="body2"
										color={colors.gray['500']}
									>
										{`You are about to publish multiple invoices. Invoices cannot be edited after publishing.`}
									</Typography>
									<Gutter spacing={2} />

									<Typography
										variant="body2"
										color={colors.gray['500']}
									>
										{'Do you want to proceed?'}
									</Typography>
									<Gutter spacing={2.5} />
									<FlexRow
										justify="flex-end"
										style={{ width: '100%' }}
									>
										<TransparentButton
											onClick={() => {
												analytics.track(
													SEGMENT_EVENTS.INVOICE_CANCEL_BULK_PUBLISH,
													{
														invoices_selected_count:
															invoices.length,
														selection_made:
															'publish'
													}
												)
												onClose?.()
											}}
										>
											Cancel
										</TransparentButton>
										<Gutter gap={0.5} />

										<WrapperButton
											onClick={() =>
												handlePublishBulkInvoices(
													invoices
												)
											}
											disabled={isDownloading}
										>
											{!isDownloading ? (
												'Publish'
											) : (
												<BouncingDotsLoader />
											)}
										</WrapperButton>
									</FlexRow>
								</FlexCol>
							</FlexRow>
						</FlexCol>
					</Wrapper>
				</Background>
			</StyledModal>
			<BulkPublishErrorModal
				open={openErrorModal}
				onClose={() => setOpenErrorModal(false)}
				invoices={erroredInvoices}
			/>
		</>
	)
}
