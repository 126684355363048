import { dateFormat } from 'data/dateFormat'
import moment from 'moment'

export const PaymentOptions = [
	{ value: 'received', label: 'Received' },
	{ value: 'sent', label: 'Sent' }
]

export const BulKPaymentInitialValue = {
	automatic: true,
	pageInfo: { totalPages: 1, currentPage: 1, edgesPerPage: 20 },
	invoices: [],
	loading: true,
	totalAmount: '0',
	businessId: '',
	contactId: '',
	paymentMethod: '',
	paymentNote: '',
	paymentDate: moment().format(dateFormat),
	payments: [
		/*{
			invoiceId: '654e0da0dea315e687eeca14',
			amount: 100,
			paymentStatus: 'partial'
		}*/
	]
}

export const BulkPaymentOption = [
	{ value: true, label: 'Automatic' },
	{ value: false, label: 'Manual' }
]
