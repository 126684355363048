import type { IBusiness } from 'typings/business'
import type { IAPIError } from 'typings'

export const FETCH_ALL_BUSINESS = 'FETCH_ALL_BUSINESS'
export const FETCH_ALL_BUSINESS_SUCCESS = 'FETCH_ALL_BUSINESS_SUCCESS'
export const FETCH_ALL_BUSINESS_ERROR = 'FETCH_ALL_BUSINESS_ERROR'
export const SET_BUSINESS_LOADING = 'SET_BUSINESS_LOADING'
export const UPDATE_BUSINESS_PROFILE_PIC = 'UPDATE_BUSINESS_PROFILE_PIC'
export const SET_BUSINESS_TERMS_CONDITION = 'SET_BUSINESS_TERMS_CONDITION'

interface IFetchBusiness {
	type: typeof FETCH_ALL_BUSINESS
}

interface IFetchBusinessSuccess {
	type: typeof FETCH_ALL_BUSINESS_SUCCESS
	payload: IBusiness[]
}

interface IFetchBusinessError {
	type: typeof FETCH_ALL_BUSINESS_ERROR
	payload: IAPIError[]
}

interface IFetchBusinessLoading {
	type: typeof SET_BUSINESS_LOADING
	payload: boolean
}

interface ISetTermsCondition {
	type: typeof SET_BUSINESS_TERMS_CONDITION
	businessId: string
	terms: string
}

export type BusinessActionTypes =
	| IFetchBusiness
	| IFetchBusinessSuccess
	| IFetchBusinessError
	| IFetchBusinessLoading
	| ISetTermsCondition
