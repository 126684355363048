import React, { useState } from 'react'
import { FlexRow } from 'components/atoms/Flex'
import { Button, styled, Typography, useMediaQuery } from '@mui/material'
import { CONTACT_FILTER_OBJ, LAYOUT_BREAKPOINT_WIDTH } from 'data'
import { type IBusiness } from 'typings'
import {
	FilterButton,
	type FilterObjProps,
	Gutter,
	SearchBar
} from 'components'
import { motion } from 'framer-motion'
import { Search } from '@mui/icons-material'
import { useSelector } from 'react-redux'
import type { RootState } from 'store/index-reducer'
import { useSettings } from 'context/settings'
import { truncate } from 'helpers'

const StyledButton = styled(Button)`
	background-color: ${({ theme }) => theme.palette.background.default};
	border: 1px solid ${({ theme }) => theme.palette.colors.gray[300]};
	align-items: center;
	min-width: 0;
	min-height: 32px;
	border-radius: 5px;
	padding: 0 9px;
`

const Wrapper = styled(FlexRow)`
	width: 100%;
	flex-wrap: nowrap;
	align-items: center;
	justify-content: space-between;
	padding: 11px 16px 10px 16px;
	border-bottom: 1px solid ${({ theme }) => theme.palette.colors.gray[200]};
`

const StyledSearchBar = styled(SearchBar)`
	width: 100%;
	.MuiOutlinedInput-root {
		border-radius: 5px;
		padding-right: 6px;
	}
`

const SearchIcon = styled(Search)`
	font-size: 20px;
	color: ${({ theme }) => theme.palette.colors.gray['700']};
`

type HomeHeaderProps = {
	onSearch: (text: string) => void
	onFilterSelect: (filterObj: { [key: string]: string[] }) => void
	filterObj: FilterObjProps
}

export const HomeHeader: React.FC<HomeHeaderProps> = ({
	onSearch,
	filterObj,
	onFilterSelect
}) => {
	const [isActive, setIsActive] = useState<boolean>(false)
	const isMobile = useMediaQuery(`(max-width:${LAYOUT_BREAKPOINT_WIDTH}px)`)
	const { businesses } = useSelector((state: RootState) => state.business)
	const { business } = useSettings()

	const activeBusiness = businesses.find(
		(biz: IBusiness) => biz.id === business
	)

	const SearchAnimation = {
		width: isMobile ? '100%' : '30ch',
		transition: {
			duration: 0.2,
			type: 'spring',
			damping: 14
		}
	}

	const searchButtonClicked = () => {
		setIsActive(true)
	}

	return (
		<Wrapper>
			<Typography variant={'h6'} fontWeight={600}>
				{truncate(activeBusiness?.name || '', 50)}
			</Typography>
			<FlexRow
				align={'center'}
				sx={{ width: isMobile && isActive ? '100%' : null }}
			>
				{isMobile && !isActive ? (
					<StyledButton onClick={searchButtonClicked}>
						<SearchIcon />
					</StyledButton>
				) : (
					<motion.div animate={SearchAnimation}>
						<StyledSearchBar
							onSearch={onSearch}
							onFocus={() => setIsActive(true)}
							autoFocus={isMobile}
							onBlur={(text: string) => {
								setIsActive(text !== '')
							}}
							placeholder="Search using Contact Name"
							inputProps={{
								style: {
									padding: '4.5px 0 4.5px 14px'
								}
							}}
						/>
					</motion.div>
				)}
				<Gutter gap={1} />
				<FilterButton
					filterObj={filterObj}
					dataSource={CONTACT_FILTER_OBJ}
					onSelect={filterObj => onFilterSelect(filterObj)}
				/>
			</FlexRow>
		</Wrapper>
	)
}
