import React from 'react'
import { styled } from '@mui/system'
import { FieldTextInput } from 'components'
import { BusinessRelationShip } from 'screen/Contact/AddContact/BusinessRelationship'
import { Typography, useTheme } from '@mui/material'
import { BusinessRelationshipOptions } from 'data'
import { useTranslate } from 'hooks'
import { FieldPhoneNumberInput } from 'components/molecules/FieldPhoneNumberInput'

const StyledFieldTextInput = styled(FieldTextInput)`
	width: 100%;
`

export const ContactContainer = styled('div')`
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 10px 20px;
	align-items: flex-start;

	@media screen and (max-width: 750px) {
		grid-template-columns: 1fr;
	}
`

export const UserForm = () => {
	const theme = useTheme()
	const translate = useTranslate()
	const { colors } = theme.palette

	return (
		<ContactContainer>
			<FieldTextInput
				name={'name'}
				size={'small'}
				placeholder={translate('contacts.form.placeholder.name')}
				style={{ width: '100%' }}
			/>
			<FieldTextInput
				name={'businessName'}
				size={'small'}
				placeholder={translate(
					'contacts.form.placeholder.businessName'
				)}
				style={{ width: '100%' }}
			/>
			<FieldPhoneNumberInput
				name={'phone.number'}
				dialCode={'phone.countryCode'}
				countryInitials={'phone.countryInitials'}
			/>
			<StyledFieldTextInput
				name="email"
				placeholder={translate(
					'contacts.form.placeholder.emailAddress'
				)}
				size="small"
				style={{ width: '100%' }}
			/>
			<StyledFieldTextInput
				multiline
				rows={2}
				name="address"
				placeholder={translate('contacts.form.placeholder.mailAddress')}
				size="small"
				style={{ width: '100%' }}
			/>
			<div>
				<Typography
					variant="caption"
					style={{ color: colors.gray['500'], fontSize: '15px' }}
				>
					{translate('contacts.form.businessRelationShip')}
				</Typography>
				<BusinessRelationShip
					row
					name="businessRelationship"
					options={BusinessRelationshipOptions}
				/>
			</div>
		</ContactContainer>
	)
}
