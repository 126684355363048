import React, { useMemo, useState } from 'react'
import { Formik } from 'formik'
import { ONBOARDINGFORM, PIN_SETUP_FORM } from 'data'
import { Button } from 'components'
import type { ModalProps } from '@mui/material'

const initialState = {
	pin: '',
	confirmPin: ''
}

type PinSetupProps = {} & Pick<ModalProps, 'onClose'>

export const PinSetup: React.ComponentType<PinSetupProps> = ({ onClose }) => {
	const [step, setStep] = useState(0)

	const multiStepForm = useMemo(() => {
		return PIN_SETUP_FORM
	}, [])

	const FormFragment = multiStepForm[step].screenForm

	return (
		<Formik
			initialValues={initialState}
			onSubmit={async (values, actions) => {
				actions.setSubmitting(true)
				if (step !== multiStepForm.length - 1) {
					setStep(step + 1)
				} else {
					localStorage.setItem('pin', values.pin)
					onClose?.({}, 'escapeKeyDown')
				}
				actions.setSubmitting(false)
			}}
			validateOnBlur={false}
			validateOnChange={false}
			validationSchema={multiStepForm[step].validationSchema}
		>
			{({ handleSubmit, dirty, isSubmitting }) => (
				<>
					<FormFragment title={multiStepForm[step].title} />
					<Button
						onClick={() => handleSubmit()}
						variant={'contained'}
						type={'submit'}
						disabled={!dirty || isSubmitting}
					>
						{ONBOARDINGFORM.length - 1 === step
							? 'Submit'
							: 'Continue'}
					</Button>
				</>
			)}
		</Formik>
	)
}
