import type { NavigationType, RightMenuType } from 'store/app/index'

export const SET_NAVIGATION = 'SET_NAVIGATION'

type ISetNavigation = {
	type: typeof SET_NAVIGATION
	payload?: NavigationType[]
	rightMenu?: RightMenuType
}

export type AppActionsTypes = ISetNavigation
