import React from 'react'
import { Paper, Typography, useTheme } from '@mui/material'
import { styled } from '@mui/system'
import { TableVirtuoso } from 'react-virtuoso'
import { FlexCol, FlexRow } from 'components/atoms/Flex'
import { LoadSheetTableComponent } from 'screen/LoadSheet/Table/LoadSheetTableComponents'
import { useDimension } from 'hooks'
import NoLoadsheet from 'assets/loadsheets/No_Loadsheet.png'
import { RPHeader } from 'screen/OrderFinancing/RepaymentLoan/RPHeader'
import { RePaymentItem } from 'screen/OrderFinancing/RepaymentLoan/RePaymentItem'

const StyledPaper = styled(Paper)`
	position: relative;
	width: 100%;
	height: 100%;
	background: ${({ theme }) => theme.palette.colors.white['500']};
	z-index: 0;
`

const Table = styled(TableVirtuoso)`
	width: 100%;
	background: ${({ theme }) => theme.palette.colors.white['500']};
`

const AbsoluteWrapper = styled(FlexRow)`
	width: 100%;
	z-index: 1;
	align-items: center;
	justify-content: center;
	position: absolute;
	height: 100%;
`

const Wrapper = styled(FlexCol)`
	background-color: ${({ theme }) => theme.palette.background.default};
	width: 100%;
	border-radius: 16px;
	overflow: hidden;
`

const Image = styled('img')`
	min-width: 100px;
	max-width: 300px;
`

type LoadSheetTableProps = { dataSource: any[] }

export const RepaymentLoan: React.ComponentType<LoadSheetTableProps> = ({
	dataSource
}) => {
	const { height } = useDimension()
	const theme = useTheme()
	const { colors } = theme.palette

	return (
		<Wrapper style={{ minHeight: height - 250 }}>
			<StyledPaper>
				{dataSource.length === 0 && (
					<AbsoluteWrapper>
						<FlexCol justify="center" align="center">
							<Image src={NoLoadsheet} />
							<Typography
								variant="subtitle2"
								color={colors.gray['600']}
							>
								No Repayment Found
							</Typography>
						</FlexCol>
					</AbsoluteWrapper>
				)}
				<Table
					data={dataSource}
					components={LoadSheetTableComponent}
					fixedHeaderContent={RPHeader}
					itemContent={(_index: number, row: any) => (
						<RePaymentItem {...row} />
					)}
					endReached={() => {
						//
					}}
					style={{ minHeight: height - 250 }}
					//onScroll={onScroll}
				/>
			</StyledPaper>
		</Wrapper>
	)
}
