import produce from 'immer'
import {
	type ContactActionsTypes,
	RESET_CONTACT_LIST,
	SET_CONTACT_LIST,
	SET_CONTACT_LOADING,
	SET_CONTACT_PAGE_INFO,
	SET_CONTACT_PAGE_NUMBER
} from './types'
import type { IContactProps, ResponsePageInfo } from 'typings'

interface ContactState {
	loading: boolean
	dataSource: IContactProps[]
	pageInfo: ResponsePageInfo
	activePageNumber: number
}

const initialState: ContactState = {
	loading: true,
	dataSource: [],
	activePageNumber: 1,
	pageInfo: { currentPage: 1, edgesPerPage: 0, totalPages: 1 }
}

const reducer = produce((draft: ContactState, action: ContactActionsTypes) => {
	switch (action.type) {
		case SET_CONTACT_LOADING:
			draft.loading = action.payload
			return

		case SET_CONTACT_LIST:
			draft.dataSource = action.payload
			return

		case RESET_CONTACT_LIST:
			draft.dataSource = []
			return

		case SET_CONTACT_PAGE_INFO:
			draft.pageInfo = action.payload
			return

		case SET_CONTACT_PAGE_NUMBER:
			draft.activePageNumber = action.payload
			return

		default:
			return
	}
}, initialState)

export default reducer
