import React from 'react'
import { Button, Paper, Typography, useTheme } from '@mui/material'
import { styled } from '@mui/system'
import { TableVirtuoso } from 'react-virtuoso'
import { FlexCol, FlexRow } from 'components/atoms/Flex'
import type { LoadsheetType } from 'typings/loadsheet'
import { TableHeader } from 'screen/LoadSheet/Table/TableHeader'
import { LoadSheetTableComponent } from 'screen/LoadSheet/Table/LoadSheetTableComponents'
import { useDimension } from 'hooks'
import { LoadItem } from 'screen/LoadSheet/Table/TableItem'
import NoLoadsheet from 'assets/loadsheets/No_Loadsheet.png'
import { Gutter } from 'components'
import { AiOutlinePlus } from 'react-icons/ai'
import { useNavigate } from 'react-router-dom'

const StyledPaper = styled(Paper)`
	position: relative;
	width: 100%;
	height: 100%;
	background: ${({ theme }) => theme.palette.colors.white['500']};
	z-index: 0;
`

const Table = styled(TableVirtuoso)`
	width: 100%;
	background: ${({ theme }) => theme.palette.colors.white['500']};
`

const AbsoluteWrapper = styled(FlexRow)`
	width: 100%;
	z-index: 1;
	align-items: center;
	justify-content: center;
	position: absolute;
	height: 100%;
`

const Wrapper = styled(FlexCol)`
	background-color: ${({ theme }) => theme.palette.background.default};
	width: 100%;
	border-radius: 16px;
	border: 1px solid ${({ theme }) => theme.palette.colors.gray['300']};
	box-shadow: 0 4px 6px -2px rgba(16, 24, 40, 0.03),
		0px 12px 16px -4px rgba(16, 24, 40, 0.08);
	overflow: hidden;
`

const Image = styled('img')`
	min-width: 100px;
	max-width: 300px;
`

const SaveButton = styled(Button)`
	background-color: ${({ theme }) => theme.palette.colors.yellow['300']};
	color: ${({ theme }) => theme.palette.colors.gray['900']};
	border-radius: 8px;
	min-width: 38px;
	min-height: 38px;
	padding: 0 12px;

	:hover {
		background-color: ${({ theme }) => theme.palette.colors.yellow['200']};
	}
`

type LoadSheetTableProps = {
	searchKey: string
	dataSource: LoadsheetType[]
	fetchLoadSheetList(): void
}

export const LoadSheetTable: React.ComponentType<LoadSheetTableProps> = ({
	searchKey,
	dataSource,
	fetchLoadSheetList
}) => {
	const { height } = useDimension()
	const navigate = useNavigate()
	const theme = useTheme()
	const {colors} = theme.palette

	return (
		<Wrapper style={{ minHeight: height - 170 }}>
			<StyledPaper>
				{searchKey && dataSource.length === 0 && (
					<AbsoluteWrapper>
						<Typography>
							No entry found for &quot;{`${searchKey}`}&quot;
						</Typography>
					</AbsoluteWrapper>
				)}
				{!searchKey && dataSource.length === 0 && (
					<AbsoluteWrapper>
						<FlexCol justify="center" align="center">
							<Image src={NoLoadsheet} />
							<Typography variant="subtitle2" color={colors.gray['600']}>
								Streamline Your Deliveries:
							</Typography>
							<Typography variant="subtitle2" color={colors.gray['600']}>
								Create a load sheet to organize your vehicle
								loading
							</Typography>
							<Gutter spacing={0.8} />

							<SaveButton
								onClick={() => {
									navigate('/load-sheet/create-load-sheet')
								}}
							>
								<AiOutlinePlus />
								<Gutter gap={0.5} />
								<Typography
									variant={'caption'}
									fontWeight={600}
								>
									Create New Load Sheet
								</Typography>
							</SaveButton>
						</FlexCol>
					</AbsoluteWrapper>
				)}
				<Table
					data={dataSource}
					components={LoadSheetTableComponent}
					fixedHeaderContent={TableHeader}
					itemContent={(_index: number, row: any) => (
						<LoadItem {...row} />
					)}
					endReached={() => {
						fetchLoadSheetList()
					}}
					//onScroll={onScroll}
				/>
			</StyledPaper>
		</Wrapper>
	)
}
