import React from 'react'
import { styled } from '@mui/system'
import { FlexRow } from 'components/atoms/Flex'
import type { ColorsType } from 'typings/color'

const Item = styled(FlexRow, {
	shouldForwardProp: props =>
		props !== 'disabled' && props !== 'isSelected' && props !== 'color'
})<Pick<CapsuleProps, 'isSelected' | 'color' | 'disabled'>>`
	background-color: ${({ theme, color, isSelected }) =>
		isSelected
			? theme.palette.colors[color || 'gray']['50']
			: theme.palette.colors['gray']['50']};
	padding: 5px 10px;
	border-radius: 25px;
	margin: 5px;
	color: ${({ theme, color, isSelected }) =>
		isSelected
			? theme.palette.colors[color || 'gray']['700']
			: theme.palette.colors['gray']['700']};
	align-items: center;
	justify-content: center;

	white-space: nowrap;

	:hover {
		cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};
	}
`

export type DataSource = {
	id?: number | string
	name: string
	value: string
}

export type CapsuleProps = {
	name: string
	value: string
	item?: DataSource
	color?: ColorsType
	isSelected?: boolean
	disabled?: boolean
	onItemClick?(title: string, item?: DataSource): void
	renderLeft?: JSX.Element | React.ReactNode
	renderRight?: JSX.Element | React.ReactNode
	children?: React.ReactNode
}

export const Capsule: React.ComponentType<CapsuleProps> = ({
	name,
	onItemClick,
	item,
	disabled = false,
	renderLeft,
	renderRight,
	value,
	children,
	...props
}) => {
	return (
		<>
			<Item
				onClick={() => {
					if (!disabled) {
						onItemClick?.(value, item)
					}
				}}
				disabled={disabled}
				{...props}
			>
				<>
					{renderLeft}
					{name}
					{children}
					{renderRight}
				</>
			</Item>
		</>
	)
}
