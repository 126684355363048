import React from 'react'
import { Typography, useTheme } from '@mui/material'
import type { InventoryItemType } from 'typings'
import { FlexRow } from 'components/atoms/Flex'
import { Capsule } from 'components'
import { truncate } from 'helpers'
import { styled } from '@mui/system'

const StyledCapsule = styled(Capsule)`
	padding: 2px 10px;
	font-size: 14px;
	background-color: ${({ theme }) => theme.palette.colors['blue']['50']};
`
export type UnitProps = {
	row: InventoryItemType
	displayStockQuantity?: boolean
}

export const Unit: React.ComponentType<UnitProps> = ({
	row,
	displayStockQuantity
}) => {
	const theme = useTheme()
	const item = row.stock[0]

	if (!item) {
		return <></>
	}

	if (item.quantity <= 0)
		return (
			<Typography
				variant={'body2'}
				fontWeight={'500'}
				color={theme.palette.colors.red['700']}
			>
				Out of Stock
			</Typography>
		)

	return (
		<FlexRow align={'center'}>
			{displayStockQuantity && (
				<Typography variant={'caption'} fontWeight={'bold'}>
					{item.quantity}
				</Typography>
			)}
			{!displayStockQuantity && (
				<Typography
					variant={'body2'}
					fontWeight={'regular'}
					color={theme.palette.colors.green['900']}
				>
					Available
				</Typography>
			)}
			{displayStockQuantity && (
				<StyledCapsule
					name={truncate(item.unit, 15)}
					value={truncate(item.unit, 15)}
					color={'gray'}
					isSelected
					disabled
				/>
			)}
		</FlexRow>
	)
}
