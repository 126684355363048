import React from 'react'
import { Box, Typography } from '@mui/material'
import { styled } from '@mui/system'
import LinearProgress, {
	linearProgressClasses
} from '@mui/material/LinearProgress'
import { FlexCol, FlexRow } from 'components/atoms/Flex'
import { Gutter } from 'components'
import { useTranslate } from 'hooks'

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
	height: 5,
	borderRadius: 5,
	[`&.${linearProgressClasses.colorPrimary}`]: {
		backgroundColor:
			theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800]
	},
	[`& .${linearProgressClasses.bar}`]: {
		borderRadius: 5,
		backgroundColor: theme.palette.mode === 'light' ? '#339F71' : '#339F71'
	}
}))

export type ProgressbarProps = {
	progress: number
}

export const Progressbar: React.ComponentType<ProgressbarProps> = ({
	progress
}) => {
	const translate = useTranslate()

	return (
		<FlexCol style={{ width: 250 }}>
			<Box sx={{ width: '100%' }}>
				<BorderLinearProgress
					variant="determinate"
					value={progress | 0}
				/>
			</Box>
			<Gutter spacing={0.5} />
			<FlexRow
				align={'center'}
				justify={'space-between'}
				style={{ width: '100%' }}
			>
				{' '}
				{progress === 80 ? (
					<Typography variant={'body2'} fontWeight={500}>
						{translate('onboarding.form.almostDone')}
					</Typography>
				) : (
					<Typography variant={'body2'} fontWeight={500}>
						{translate('onboarding.form.settingUpWebApp')}
					</Typography>
				)}
				<Typography variant={'body2'}>{progress | 0}%</Typography>
			</FlexRow>
		</FlexCol>
	)
}
