import { COUNTRIES_LIST } from 'data'

const msg = (
	isValid: boolean = false,
	message: string = 'Invalid mobile no'
) => ({ isValid, message })

const getMobileNoMsg = (mobile_begin_with: string[], phoneNo = '') => {
	if (mobile_begin_with.length > 10) {
		const arr = mobile_begin_with
			.filter(d => d.includes(phoneNo))
			.toString()
		return arr.length > 0 ? arr : mobile_begin_with.toString()
	} else return mobile_begin_with.toString()
}

export const validatePhoneNo = (
	phoneNo: string = '',
	dialCode: string = '',
	countryCode: string = 'PK'
) => {
	const selectedCountry = COUNTRIES_LIST.find(
		d => d.dial_code === dialCode && d.code === countryCode
	)

	if (!selectedCountry) return msg(false, 'The phone number is invalid.')

	const {
		phone: { phone_number_lengths, mobile_begin_with }
	} = selectedCountry

	if (mobile_begin_with !== null) {
		const isValid = mobile_begin_with.some(
			(d: string) => phoneNo.indexOf(d) === 0
		)

		if (!isValid) {
			return msg(
				false,
				`The phone number should begin with ${getMobileNoMsg(
					mobile_begin_with,
					phoneNo
				)}.`
			)
		}
	}

	if (phone_number_lengths !== null) {
		const isValid = phone_number_lengths.some(
			(d: string) => phoneNo.length === parseInt(d)
		)

		if (!isValid) {
			return msg(
				false,
				`The phone number should be exactly ${phone_number_lengths} digits long.`
			)
		}
	}

	return msg(true, 'The phone number is invalid.')
}
