import React from 'react'
import { FlexCol, FlexRow } from 'components/atoms/Flex'
import { styled } from '@mui/system'
import type { IContactProps } from 'typings'
import { convertNumber } from 'helpers'
import { FormatAmount, Gutter } from 'components'
import { Typography } from '@mui/material'

const Wrapper = styled(FlexCol)`
	display: flex;
	flex-direction: column;
	background-color: ${({ theme }) => theme.palette.background.default};
	padding: 13px 12px;
	width: 100%;
	border-radius: 8px;
	border: 1px solid ${({ theme }) => theme.palette.colors.gray['300']};

	:hover {
		cursor: pointer;
	}
`

type HeaderProps = {
	user?: IContactProps
}

export const LedgerHeader: React.ComponentType<HeaderProps> = ({ user }) => {
	const balance =
		user?.balances?.filter(f => f.accountName === 'accounts_receivables')[0]
			?.balance || 0

	return (
		<>
			<Wrapper>
				<FlexRow
					justify="flex-end"
					align={'flex-end'}
					style={{ width: '100%' }}
				>
					<Typography
						variant={'subtitle2'}
						sx={{ marginBottom: '1px' }}
					>
						Account Balance
					</Typography>
					<Gutter gap={0.2} />
					{user && (
						<FormatAmount
							limit={24}
							amount={convertNumber(parseFloat(`${balance}`))}
							prefixCurrency={user?.balance.currency}
							variant={'h6'}
							fontWeight={600}
						/>
					)}
				</FlexRow>
			</Wrapper>
		</>
	)
}
