import React, { useCallback, useEffect, useState } from 'react'
import { Header } from 'screen/Invoices/NewCreate/Components/Header'
import { checkAvailableStock, getInvoiceSettings } from 'api'
import {
	CREATE_INVOICE_INITIAL,
	GENERATE_INVOICE_DATA,
	INVOICE_EDIT_NAVIGATION,
	LAYOUT_PADDING
} from 'data'
import moment from 'moment'
import { getAInvoice } from 'api/invoice'
import type { BalancesProps, InvoiceSettingsType, InvoiceType } from 'typings'
import { setNavigation } from 'store/app/actions'
import { useDispatch } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
import { useSettings } from 'context/settings'
import { useAppErrors } from 'hooks'
import { useFormikContext } from 'formik'
import { DateAmount } from 'screen/Invoices/NewCreate/Components/DateAmount'
import { Gutter } from 'components'
import { ContactDetails } from 'screen/Invoices/NewCreate/Components/ContactDetails'
import { ItemTable } from 'screen/Invoices/NewCreate/Components/ItemTable'
import { styled } from '@mui/system'
import { PaymentTerms } from 'screen/Invoices/NewCreate/Components/PaymentTerms'
import { Summary } from 'screen/Invoices/NewCreate/Components/Summary'
import { fetchTaxes } from 'store/settings/actions'
import { FlexCol } from 'components/atoms/Flex'
import { getAContact } from 'api/contacts'

const StyledGutter = styled(Gutter)`
	display: block;
`

const Grid = styled('div')`
	width: 100%;
	display: grid;
	grid-template-columns: 1fr 1fr;
	column-gap: 20px;

	@media (max-width: 800px) {
		grid-template-columns: 1fr;
	}
`

const Wrapper = styled(FlexCol)`
	height: 100%;
	width: 100%;
	flex-wrap: nowrap;
	padding: 0 ${LAYOUT_PADDING}px;
`

export const InvoiceForm = ({ businessInfo }: { businessInfo: any }) => {
	const [invoiceSettings, setInvoiceSettings] =
		useState<InvoiceSettingsType>()
	const dispatch = useDispatch()
	const { business } = useSettings()
	const { state } = useLocation()
	const { id } = useParams()
	const { setAppError } = useAppErrors()
	const { resetForm, values } = useFormikContext()

	const dispatchTitle = useCallback(
		(inv: InvoiceType) => {
			dispatch(
				setNavigation(
					INVOICE_EDIT_NAVIGATION(
						inv?.friendlyId || '0',
						inv.id || '0'
					)
				)
			)
		},
		[dispatch]
	)

	const getAvailableStock = async (inv: InvoiceType) => {
		try {
			const stockIds = inv.items.map(itm => {
				return itm.stockId
			})
			const stockValues = await checkAvailableStock(stockIds as string[])

			inv.items.map(d => {
				stockValues[`${d?.stockId}`] =
					parseInt(!inv.id ? '0' : `${d.quantity}`) +
					stockValues[d.stockId as string]
			})
			return stockValues
		} catch (e) {
			//
		}
	}

	const fetchLedgerInformation = async (id: string) => {
		try {
			const contactResponse = await getAContact(id || '')
			const filterD = `${
				contactResponse.balances?.find(
					(f: BalancesProps) =>
						f.accountName === 'accounts_receivables'
				)?.balance || 0
			}`

			return {
				ledgerBalance: {
					currency: 'PKR',
					amount: parseFloat(parseFloat(filterD).toFixed(2)) || 0
				}
			}
		} catch (e) {
			//
		}
	}

	const getAInvoiceDetails = useCallback(
		async (id: string) => {
			try {
				const res = await getAInvoice(id || '0')
				const ledgerInformation = await fetchLedgerInformation(
					res.contact.id || ''
				)
				const stockValues = await getAvailableStock(res)

				dispatchTitle(res)
				resetForm({
					values: {
						...GENERATE_INVOICE_DATA(res),
						loading: false,
						initialValue: stockValues,
						termsAndConditions: res.isCustomTermsAndCondition
							? res.termsAndConditions
							: businessInfo?.termsAndConditions,
						status: res.status,
						includeBalance:
							!!ledgerInformation?.ledgerBalance.amount,
						...ledgerInformation
					}
				})
			} catch (e: any) {
				setAppError(e)
			}
		},
		[
			businessInfo?.termsAndConditions,
			dispatchTitle,
			resetForm,
			setAppError
		]
	)

	const fetchInvoiceSettings = useCallback(async () => {
		try {
			const res = await getInvoiceSettings(business)
			setInvoiceSettings(res)
		} catch (e: any) {
			setAppError(e)
		}
	}, [setAppError, business])

	const initialisingInvoice = useCallback(async () => {
		fetchInvoiceSettings()
		if (state?.currentInvoice) {
			const ledgerInformation = await fetchLedgerInformation(
				state.currentInvoice.contact.id || ''
			)
			const stockValues = await getAvailableStock(state?.currentInvoice)
			dispatchTitle(state?.currentInvoice)
			resetForm({
				values: {
					...GENERATE_INVOICE_DATA({
						...state?.currentInvoice,
						businessInfo,
						initialValue: stockValues,
						...ledgerInformation,
						includeBalance: state.currentInvoice.ledgerBalance
							? !!state.currentInvoice.ledgerBalance.amount
							: false,
						status: state?.currentInvoice.status
					}),

					termsAndConditions: state?.currentInvoice
						.isCustomTermsAndCondition
						? state?.currentInvoice.termsAndConditions
						: businessInfo?.termsAndConditions,
					loading: false
				}
			})
		} else if (id === undefined || id === '0') {
			dispatchTitle(CREATE_INVOICE_INITIAL)
			resetForm({
				values: {
					...CREATE_INVOICE_INITIAL,
					initiationAt: moment.utc(moment()).format(),
					business,
					businessInfo,
					termsAndConditions: state?.currentInvoice
						.isCustomTermsAndCondition
						? state?.currentInvoice.termsAndConditions
						: businessInfo?.termsAndConditions,
					isPaymentTerms: false,
					paymentTermsSelected: '',
					extraCharges: [],
					percentageDiscount: 'percentage',
					loading: false
				}
			})
		} else {
			getAInvoiceDetails(id)
		}
	}, [
		business,
		businessInfo,
		dispatchTitle,
		fetchInvoiceSettings,
		getAInvoiceDetails,
		id,
		resetForm,
		state?.currentInvoice
	])

	useEffect(() => {
		initialisingInvoice()
	}, [initialisingInvoice])

	useEffect(() => {
		dispatch(fetchTaxes())
	}, [dispatch])

	return (
		<>
			<Header businessInfo={businessInfo} />
			<Wrapper>
				<StyledGutter spacing={1.125} />
				<DateAmount name={'initiationAt'} />
				<StyledGutter spacing={1.5} />
				<ContactDetails invoiceSettings={invoiceSettings} />
				<StyledGutter spacing={1.5} />
				<ItemTable />
				<StyledGutter spacing={2} />
				<Grid>
					<PaymentTerms />
					<Summary />
				</Grid>
			</Wrapper>
		</>
	)
}
