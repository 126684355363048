import React, { useState } from 'react'
import { styled } from '@mui/system'
import { FlexCol, FlexRow } from 'components/atoms/Flex'
import { InputAdornment, Typography, useTheme } from '@mui/material'
import type { ColorsCodeType } from 'typings'
import {
	Button,
	FieldCurrencyInput,
	FieldNumberInput,
	Gutter
} from 'components'
import { UploadAttachment } from 'screen/OrderFinancing/UploadAttachment'
import { Formik } from 'formik'
import { loanCalculator, registerLoan } from 'api/cbfs'
import { currencyInput } from 'helpers'
import * as Yup from 'yup'
import { useLocation, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import type { RootState } from 'store/index-reducer'

const Wrapper = styled(FlexCol)`
	padding: 16px;
	width: 100%;
	height: 100%;
`

const Row = styled(FlexRow)`
	width: 100%;
	justify-content: space-between;
	align-items: center;
`

const Grid = styled('div')`
	display: grid;
	grid-template-columns: 1fr 1fr;
	width: min-content;
	column-gap: 20px;
	row-gap: 20px;
`

const PrimaryButtonWrapper = styled(FlexRow)``

const StyledPrimaryButton = styled(Button)`
	min-width: 0;
	min-height: 32px;
	padding: 0 10px;
	border-radius: 5px;
	color: ${({ theme }) => theme.palette.colors.white['900']};
`

const validationSchema = Yup.object().shape({
	invoiceDoc: Yup.array()
		.min(1, 'Invoice is required')
		.required('Invoice is required'),
	loanAmount: Yup.number().min(1, 'fill valid detail').required('Required'),
	days: Yup.string().min(1, 'fill valid detail').required('Required')
})

const Detail = {
	amountPending: 0,
	amountRepaid: 0,
	lateFeesPending: 0,
	lateFeesRepaid: 0,
	markupPending: 0,
	markupRepaid: 0
}

const initialValue = {
	invoiceDoc: [],
	loanAmount: 0,
	days: 0
}

export const NewFinanceApplication = () => {
	const { user } = useSelector((state: RootState) => state.cbfs.business)
	const [loanDetail, setLoanDetail] = useState(Detail)
	const theme = useTheme()
	const { state } = useLocation()
	const navigate = useNavigate()
	const {
		palette: { colors }
	} = theme

	const inV = state ? state.initialState : initialValue

	const getLoanDetail = async (principal: string, tenure: string) => {
		try {
			const res = await loanCalculator(principal, tenure, user.id)
			setLoanDetail(res.balances)
		} catch (e) {
			//
		}
	}

	const onSubmit = async (values: any, actions: any) => {
		try {
			actions.setSubmitting(true)
			await registerLoan(values)
			actions.resetForm({
				values: {
					invoiceDoc: [],
					loanAmount: 0,
					days: 0
				}
			})
			navigate('/ordr-financing')
			setLoanDetail(Detail)
		} catch (e) {
			//
		} finally {
			actions.setSubmitting(false)
		}
	}

	return (
		<Wrapper>
			<Formik
				initialValues={inV}
				validationSchema={validationSchema}
				onSubmit={onSubmit}
			>
				{({
					values,
					handleSubmit,
					isSubmitting,

					isValid
				}) => (
					<>
						<Row>
							<FlexCol>
								<Typography variant={'h6'} fontWeight={600}>
									New Finance Application
								</Typography>
								<Typography variant={'body2'}>
									Please provide us details of your
									inventory/purchase order/invoice.
								</Typography>
							</FlexCol>
							<StyledPrimaryButton
								buttonColor={
									colors['green']['600'] as ColorsCodeType
								}
								onClick={() => handleSubmit()}
								disabled={isSubmitting || !isValid}
							>
								<PrimaryButtonWrapper>
									Apply
								</PrimaryButtonWrapper>
							</StyledPrimaryButton>
						</Row>
						<Gutter spacing={2} />
						<UploadAttachment
							name={'invoiceDoc'}
							title={
								'Upload Documents (invoice/purchase order/inventory) '
							}
						/>
						<FlexCol style={{ width: '100%' }}>
							<Grid>
								<FieldCurrencyInput
									title={'Finance Amount'}
									name="loanAmount"
									placeholder={''}
									style={{ width: '35ch' }}
									size="small"
									inputProps={{ style: { textAlign: 'end' } }}
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<Typography>PKR</Typography>
											</InputAdornment>
										)
									}}
									onBlur={amount =>
										getLoanDetail(amount, `${values.days}`)
									}
								/>
								<FieldNumberInput
									title="Tenture"
									placeholder={'56'}
									name={'days'}
									style={{ width: '35ch' }}
									size="small"
									inputProps={{ style: { textAlign: 'end' } }}
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<Typography>Days</Typography>
											</InputAdornment>
										)
									}}
									onBlur={amount =>
										getLoanDetail(
											`${values.loanAmount}`,
											`${amount}`
										)
									}
								/>
							</Grid>
						</FlexCol>
						<Gutter spacing={2} />
						<FlexCol>
							<FlexRow>
								<Typography fontWeight={500}>
									Principal Amount :
								</Typography>
								<Gutter gap={0.2} />
								<Typography>
									PKR {currencyInput(`${values.loanAmount}`)}
								</Typography>
							</FlexRow>
							<Gutter spacing={0.4} />
							<FlexRow>
								<Typography fontWeight={500}>
									Total Markup Pending :
								</Typography>
								<Gutter gap={0.2} />
								<Typography>
									PKR{' '}
									{currencyInput(
										`${loanDetail.markupPending}`
									)}
								</Typography>
							</FlexRow>
							<Gutter spacing={0.4} />
							<FlexRow
								style={{
									borderTop: '1px solid red',
									paddingTop: 10
								}}
							>
								<Typography fontWeight={500}>
									Total Net Payment Due :
								</Typography>
								<Gutter gap={0.2} />
								<Typography>
									PKR{' '}
									{currencyInput(
										`${
											parseFloat(`${values.loanAmount}`) +
											loanDetail.markupPending
										}`
									)}
								</Typography>
							</FlexRow>
						</FlexCol>
					</>
				)}
			</Formik>
		</Wrapper>
	)
}
