import { axios } from 'store'
import type {
	DataResponseType,
	PaymentMethod,
	PaymentType,
	TransactionPaymentType,
	TransactionProps
} from 'typings'
import queryString from 'query-string'

export const getPaymentByInvoiceId = async (invoiceId: string) => {
	const url = `/transactions/payments/invoices/${invoiceId}`

	try {
		const { data } = await axios.get<TransactionPaymentType[]>(url)

		return data
	} catch (error: any) {
		throw error.response.data
	}
}

export const createPayment = async (payload: {
	business: string
	contact: string
	amount: number
	method: PaymentMethod
	note: string
	date: string
	type: PaymentType
}) => {
	const url = '/transactions/payments'

	try {
		const { data } = await axios.post(url, payload)

		return data
	} catch (error: any) {
		throw error.response.data
	}
}
export const getTransactionByInvoiceId = async (invoiceId: string) => {
	const url = `/transactions/invoices/${invoiceId}`

	try {
		const { data } = await axios.get<DataResponseType<TransactionProps>>(
			url
		)

		return data
	} catch (error: any) {
		throw error.response.data
	}
}

export const getTransactionEntries = async (
	contactId: string,
	payload: {
		//page: number
		//limit: number
		//paymentStatus?: string
		searchKey?: string
	}
) => {
	const url = `/transactions/contacts/${contactId}?&${queryString.stringify({
		...payload
	})}`

	try {
		const { data } = await axios.get<DataResponseType<TransactionProps>>(
			url
		)

		return data
	} catch (error: any) {
		throw error.response.data
	}
}

export const getTransactionSummary = async () => {
	const url = '/transactions/summary'

	try {
		const { data } = await axios.get<
			{ _id: string; totalBalance: number | string }[]
		>(url)

		return data
	} catch (error: any) {
		throw error.response.data
	}
}
