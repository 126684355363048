export const NUMBER = !/!^(-|\+)?(([1-9][0-9]*)|(0))(?:\.[0-9]+)?$/g
export const STRINGS = /^'?\p{L}+(?:[' ]\p{L}+)*'?$/u
export const phoneNumPatternForLogin = /[3]{1}[0-9]{2}[0-9]{7}/
export const POSITIVE_NUMBER_WITH_DECILMAL = /^\d*\.?\d{0,2}$/
export const POSITIVE_NUMBER = /^\d*$/
export type Regex = typeof POSITIVE_NUMBER | typeof NUMBER
export const STOCK_UNIT_FORMAT = /^[a-zA-Z0-9]+([.-]?[a-zA-Z0-9]+)*$/
export const CNIC_FORMAT = /^[a-zA-Z0-9-]*$/
export const CONTACT_NAME_FORMAT = /^[A-Za-z0-9 ]+$/
export const CONTACT_NUMBER_FORMAT = /^[3][0-4]\d{8}$/

export const ALPHA_NUMERIC_WITH_HYPHEN = /^[a-zA-Z0-9-]*$/
