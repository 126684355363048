import { axios } from 'store'
import queryString from 'query-string'
import type { CreateOrderType, IOrderCenterProps } from 'typings/orderCenter'
import type { DataResponseType } from 'typings'

type getOrderCenterListProps = {
	page: number
	sortOrder?: 'ASC' | 'DESC'
	searchKey?: string
	deleted?: boolean
	business: string
}

export const getOrderCenterList = async (payload: getOrderCenterListProps) => {
	const url = `/orders/list?sortOrder=DESC&sortBy=initiationAt&${queryString.stringify(
		payload
	)}`

	try {
		const { data } = await axios.get<DataResponseType<IOrderCenterProps>>(
			url
		)

		return data
	} catch (error: any) {
		throw error.response.data
	}
}

export const deleteOrder = async (payload: { orderId: string[] }) => {
	const url = `/orders/mark-deleted?${queryString.stringify(payload)}`

	try {
		const { data } = await axios.patch(url)
		return data
	} catch (error: any) {
		throw error.response.data
	}
}

export const createNewOrder = async (payload: CreateOrderType) => {
	const url = '/orders'

	try {
		const { data } = await axios.post(url, payload)

		return data
	} catch (error: any) {
		throw error.response.data
	}
}

export const updateOrder = async (
	id: string,
	payload: Omit<CreateOrderType, 'type' | 'friendlyId'>
) => {
	const url = `/orders/${id}`

	try {
		const { data } = await axios.patch(url, payload)

		return data
	} catch (error: any) {
		throw error.response.data
	}
}
