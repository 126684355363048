import React, { useCallback, useEffect, useMemo } from 'react'
import { Button, Typography, useTheme } from '@mui/material'
import { styled } from '@mui/system'
import { FieldMultipleLineInput, Gutter } from 'components'
import { FlexCol, FlexRow } from 'components/atoms/Flex'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import { getInvoiceSettings, updateInvoiceSettings } from 'api/setting'
import { useSettings } from 'context/settings'
import type { InvoiceSettingsType } from 'typings'
import { useAppErrors } from 'hooks'
import { BusinessSettings } from './BusinessSettings'
import { Header } from '../Header'
import { LAYOUT_PADDING } from 'data'
import { Formik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import type { RootState } from 'store/index-reducer'
import { updateBusiness } from 'api'
import { useSnackbar } from 'notistack'
import { updateBusinessTerms } from 'store/business/actions'

const Wrapper = styled(FlexCol)`
	width: 100%;
	height: 100%;
`
const Container = styled(FlexCol)`
	width: 100%;
	padding: ${LAYOUT_PADDING}px;
`
const WrapperButton = styled(Button)(({ theme }) => ({
	backgroundColor: theme.palette.colors.green['500'],
	color: theme.palette.colors.white['900'],
	borderRadius: '8px',
	width: '40%',
	'&:hover': {
		background: theme.palette.colors.green['700']
	},
	'&:disabled': {
		background: theme.palette.colors.gray['300']
	},
	fontWeight: 600
}))

export const SettingInvoice = () => {
	const [invoiceSettings, setInvoiceSettings] =
		React.useState<InvoiceSettingsType>()
	const { setAppError } = useAppErrors()
	const { businesses } = useSelector((state: RootState) => state.business)
	const { business } = useSettings()
	const { enqueueSnackbar } = useSnackbar()
	const dispatch = useDispatch()
	const theme = useTheme()
	const { colors } = theme.palette

	const settings = useMemo(() => {
		return (
			invoiceSettings?.settings || {
				displayCustomerCnic: false,
				displayBusinessCnic: false,
				displayBusinessStrn: false,
				displayTermsAndConditions: false
			}
		)
	}, [invoiceSettings])

	const selectedBusiness = useMemo(() => {
		return businesses.find(bus => bus.id === business)
	}, [business, businesses])

	const getInitialInvoiceSettings = useCallback(async () => {
		try {
			const invoiceSettings = await getInvoiceSettings(business)
			setInvoiceSettings(invoiceSettings)
		} catch (err: any) {
			setAppError(err)
		}
	}, [business, setAppError])

	useEffect(() => {
		getInitialInvoiceSettings()
	}, [getInitialInvoiceSettings])

	const handleChange = async (
		prop: string,
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		try {
			const settingsObj = {
				settings: {
					...settings,
					[prop]: event.target.checked
				}
			}
			const updatedInvoiceSettings = await updateInvoiceSettings(
				settingsObj,
				invoiceSettings?.id || ''
			)
			setInvoiceSettings(updatedInvoiceSettings)
		} catch (err: any) {
			setAppError(err)
		}
	}

	const onSubmit = async (values: any, actions: any) => {
		try {
			actions.setSubmitting(true)
			const res = await updateBusiness(values, business)
			dispatch(updateBusinessTerms(business, res.termsAndConditions))
			enqueueSnackbar('Terms and Conditions Updated Successfully', {
				variant: 'success'
			})
			actions.setSubmitting(false)
		} catch (err: any) {
			setAppError(err, actions)
		}
	}

	return (
		<Wrapper>
			<Header headerText="Manage Invoice Information" />
			{invoiceSettings && (
				<Container>
					<FormGroup>
						<BusinessSettings
							invoiceSettings={invoiceSettings}
							setInvoiceSettings={setInvoiceSettings}
						/>

						<Gutter spacing={1} />

						<FormControlLabel
							control={
								<Switch
									checked={settings.displayCustomerCnic}
									color="primary"
									onChange={e =>
										handleChange('displayCustomerCnic', e)
									}
								/>
							}
							label="Display Customer NTN/CNIC details on Invoice"
						/>
						<Typography variant="subtitle2" fontWeight={'regular'}>
							{settings.displayCustomerCnic ? 'D' : 'Not d'}
							isplaying customer NTN/CNIC details on the invoice.
						</Typography>

						<Gutter spacing={1} />

						<FormControlLabel
							control={
								<Switch
									checked={settings.displayTermsAndConditions}
									color="primary"
									onChange={e =>
										handleChange(
											'displayTermsAndConditions',
											e
										)
									}
								/>
							}
							label="Display Terms and Conditions on Invoice"
						/>
						<Typography variant="subtitle2" fontWeight={'regular'}>
							{settings.displayTermsAndConditions ? 'D' : 'Not d'}
							isplaying terms and conditions on the invoice.
						</Typography>
						{settings.displayTermsAndConditions && (
							<div>
								<Formik
									initialValues={{
										termsAndConditions:
											selectedBusiness?.termsAndConditions ||
											''
									}}
									onSubmit={onSubmit}
								>
									{({
										handleSubmit,
										isSubmitting,
										dirty,
										initialValues
									}) => (
										<FlexCol>
											<FieldMultipleLineInput
												name="termsAndConditions"
												size="small"
												fullWidth
												multiline
												rows={3}
												limit={2000}
												wrapperStyle={{ width: '100%' }}
												placeholder="Terms and Conditions"
											/>
											<Typography
												variant="caption"
												color={colors.gray['500']}
											>
												Enter the terms and conditions
												you want displayed on your
												invoice
											</Typography>
											<Gutter spacing={1.5} />
											<FlexRow
												align={'center'}
												sx={{ width: '100%' }}
												justify={'flex-end'}
											>
												<WrapperButton
													disabled={
														isSubmitting || !dirty
													}
													onClick={() =>
														handleSubmit()
													}
												>
													Save
													{!initialValues.termsAndConditions
														? ''
														: ' Changes'}
												</WrapperButton>
											</FlexRow>
										</FlexCol>
									)}
								</Formik>
							</div>
						)}
					</FormGroup>
				</Container>
			)}
		</Wrapper>
	)
}
