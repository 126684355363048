import React, { useCallback, useEffect } from 'react'
import { Capsule, FieldTextInput, Gutter, StyledModal } from 'components'
import type { ModalProps } from '@mui/material'
import { Typography, useTheme } from '@mui/material'
import { styled } from '@mui/system'
import { FlexCol, FlexRow } from 'components/atoms/Flex'
import Warning from 'assets/alerts/warning.png'
import { isReissueInvoice, reIssueInvoice } from 'api/invoice'
import { useNavigate } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { useAppErrors } from 'hooks'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { TransparentButton } from 'components/atoms/Button/TransparentButton'

const Container = styled(FlexCol)`
	align-items: flex-end;
	background-color: ${({ theme }) => theme.palette.background.default};
	padding: 20px 40px 20px 30px;
	z-index: 0;
	border-radius: 10px;
`

const StyledCapsule = styled(Capsule)`
	font-size: 12px;
	font-weight: 500;
`

const Col = styled(FlexCol)`
	min-width: 300px;
	max-width: 400px;
`

const Row = styled(FlexRow)`
	width: 100%;
`

const StyledButton = styled(TransparentButton)`
	background-color: ${({ theme }) => theme.palette.colors.green['500']};

	:hover {
		background-color: ${({ theme }) => theme.palette.colors.green['600']};
	}
`

const validationSchema = Yup.object().shape({
	reissueReason: Yup.string().min(1, '').max(500, '')
})

type Props = Omit<ModalProps, 'children'> & { invoiceId?: string }

export const ReissueInvoiceModal: React.ComponentType<Props> = ({
	onClose,
	invoiceId,
	...props
}) => {
	const [isStockAvailable, setIsStockAvailable] = React.useState<
		undefined | boolean
	>(undefined)
	const theme = useTheme()
	const navigate = useNavigate()
	const { enqueueSnackbar } = useSnackbar()
	const { setAppError } = useAppErrors()
	const { colors } = theme.palette

	const checkIsStockAvailable = useCallback(async () => {
		try {
			const { available } = await isReissueInvoice(invoiceId || '')
			setIsStockAvailable(available)
		} catch (e: any) {
			//
		}
	}, [invoiceId])

	const onContinue = async (values: any, actions: any) => {
		try {
			const res = await reIssueInvoice(values)
			enqueueSnackbar('Void invoice has been reissued.', {
				variant: 'success'
			})
			onClose?.({}, 'escapeKeyDown')
			navigate(`/invoice/view/${res.id}`)
		} catch (e: any) {
			setAppError(e, actions)
		}
	}

	useEffect(() => {
		if (props.open) {
			checkIsStockAvailable()
		}
	}, [checkIsStockAvailable, props.open])

	return (
		<StyledModal {...props}>
			<Formik
				initialValues={{ reissueReason: '', invoiceId }}
				onSubmit={(values, actions) => onContinue(values, actions)}
				validationSchema={validationSchema}
			>
				{({ handleSubmit, values, errors }) => (
					<Container>
						<FlexRow>
							<img src={Warning} alt="warning" />
							<Gutter gap={1} />
							<Col>
								<FlexRow align={'center'}>
									<Typography
										variant="body1"
										fontSize={14}
										fontWeight={500}
									>
										Reissue Invoice
									</Typography>
									{isStockAvailable && (
										<StyledCapsule
											isSelected
											name={'Stock Available'}
											value={'Stock Available'}
											color={'green'}
											disabled
										/>
									)}
									{isStockAvailable === false && (
										<StyledCapsule
											isSelected
											name={'Stock Not Available'}
											value={'Stock Not Available'}
											color={'red'}
											disabled
										/>
									)}
								</FlexRow>
								<Gutter spacing={0.5} />
								<Typography
									color={colors.gray['700']}
									fontSize={14}
									fontWeight={500}
									variant={'caption'}
								>
									Reissuing this invoice will create a
									duplicate version of it in your invoice
									book. Note down the reason for this action.
								</Typography>
								<Gutter spacing={1} />
								<FlexCol style={{ width: '100%' }}>
									<FieldTextInput
										name={'reissueReason'}
										error={
											errors &&
											errors.reissueReason === ''
										}
										wrapperStyle={{
											width: '100%'
										}}
										style={{
											width: '100%'
										}}
										multiline
										rows={4}
										placeholder={
											'Enter the reason (Optional)'
										}
										InputProps={{
											style: {
												fontSize: 14
											}
										}}
									/>
									<FlexRow
										justify={'flex-end'}
										style={{ width: '100%' }}
									>
										<Typography
											variant={'caption'}
											color={
												errors &&
												errors.reissueReason === ''
													? colors.red['500']
													: colors.black['500']
											}
										>
											{values.reissueReason.length}/500
										</Typography>
									</FlexRow>
								</FlexCol>
								<Gutter />
								<Row justify={'flex-end'}>
									<TransparentButton
										onClick={() =>
											onClose?.({}, 'escapeKeyDown')
										}
									>
										<Typography
											fontWeight={500}
											variant={'caption'}
											fontSize={14}
										>
											Cancel
										</Typography>
									</TransparentButton>
									<StyledButton
										onClick={() => handleSubmit()}
									>
										<Typography
											fontWeight={500}
											variant={'caption'}
											fontSize={14}
											color={colors.white['200']}
										>
											Continue
										</Typography>
									</StyledButton>
								</Row>
							</Col>
						</FlexRow>
					</Container>
				)}
			</Formik>
		</StyledModal>
	)
}
