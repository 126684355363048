import React, { useCallback, useEffect, useMemo } from 'react'
import { styled } from '@mui/system'
import { FlexRow } from 'components/atoms/Flex'
import { Typography, useTheme } from '@mui/material'
import { LAYOUT_BREAKPOINT_WIDTH, LAYOUT_PADDING } from 'data'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import type { RootState } from 'store/index-reducer'
import { setNavigation } from 'store/app/actions'
import type { NavigationType } from 'store/app'
import { pascalCase } from 'helpers'
import { useDimension } from 'hooks'
import { MdMenu, MdMenuOpen, MdNavigateNext } from 'react-icons/md'
import { Gutter } from 'components/atoms'

const Container = styled(FlexRow)`
	position: sticky;
	top: 0;
	width: 100%;
	height: 48px;
	min-height: 48px;
	max-height: 48px;
	align-items: center;
	padding: 0 ${LAYOUT_PADDING}px;
	background-color: ${({ theme }) => theme.palette.background.default};
	border-bottom: 1px solid var(--gray-200, #eaecf0);
	z-index: 100;
`

const Text = styled(Typography)<{ disabled: boolean }>`
	&:hover {
		cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};
		color: ${({ theme, disabled }) =>
			disabled
				? theme.palette.colors.gray['900']
				: theme.palette.colors.green['600']};
	}
`

const Icon = styled(FlexRow)`
	align-items: center;

	:hover {
		cursor: pointer;
	}
`

type AppBarProps = {
	setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
	isOpen: boolean
}

export const AppBar: React.ComponentType<AppBarProps> = ({
	setIsOpen,
	isOpen
}) => {
	const { navigations, rightMenu } = useSelector(
		(state: RootState) => state.app
	)
	const location = useLocation()
	const { width } = useDimension()
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const {
		palette: { colors }
	} = useTheme()

	const options: NavigationType[] = useMemo(() => {
		let url = ''
		return navigations
			? navigations
			: location.pathname
					.split('/')
					.filter(item => item !== '')
					.map(path => {
						url = url ? `${url}/${path}` : `/${path}`
						return {
							activeUrl: url,
							title: pascalCase(path.replaceAll('-', ' '))
						}
					})
	}, [location.pathname, navigations])

	const finalValue = width < LAYOUT_BREAKPOINT_WIDTH ? [options[0]] : options

	const RenderTitle = useCallback(
		({ activeUrl, title, index }: NavigationType & { index: number }) => {
			return (
				<FlexRow align={'center'}>
					<Text
						variant={'caption'}
						onClick={() => {
							if (finalValue.length - 1 !== index) {
								navigate(activeUrl)
							}
						}}
						color={colors['gray']['900']}
						fontWeight={finalValue.length - 1 === index ? 500 : 400}
						disabled={finalValue.length - 1 === index}
					>
						{title}
					</Text>
					{finalValue.length - 1 !== index && (
						<FlexRow align={'center'}>
							<Gutter gap={0.2} />
							<MdNavigateNext color={colors['gray']['800']} />
							<Gutter gap={0.2} />
						</FlexRow>
					)}
				</FlexRow>
			)
		},
		[colors, finalValue.length, location.pathname, navigate]
	)

	const RenderMenu = useCallback(() => {
		return (
			<Icon>
				{isOpen ? (
					<MdMenuOpen onClick={() => setIsOpen(!isOpen)} />
				) : (
					<MdMenu onClick={() => setIsOpen(!isOpen)} />
				)}
				<Gutter gap={1} />
			</Icon>
		)
	}, [isOpen, setIsOpen])

	useEffect(() => {
		return () => {
			dispatch(setNavigation(undefined, undefined))
		}
	}, [dispatch, location])

	return (
		<Container justify={'space-between'}>
			<FlexRow>
				{width < LAYOUT_BREAKPOINT_WIDTH && <RenderMenu />}
				{finalValue.map((option, index) => (
					<RenderTitle
						key={option.activeUrl}
						index={index}
						{...option}
					/>
				))}
			</FlexRow>

			{/*<FlexRow onClick={() => rightMenu?.ref?.current.click()}>
				<Typography>{rightMenu?.title}</Typography>
			</FlexRow>*/}
		</Container>
	)
}
