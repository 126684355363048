import React from 'react'
import { StyledModal } from 'components'
import { styled } from '@mui/system'
import { type ModalProps } from '@mui/material'
import { FlexCol, FlexRow } from 'components/atoms/Flex'
import { IoIosClose } from 'react-icons/io'

const Wrapper = styled(FlexCol)`
	min-width: 300px;
	background-color: ${({ theme }) => theme.palette.colors.white['900']};
	border-radius: 15px;
	padding: 1em;
	align-items: center;
	justify-content: center;
	margin: auto;
`

const CloseButtonWrapper = styled(FlexCol)`
	justify-content: center;
	align-items: center;
	height: 30px;
	width: 30px;
	right: 1.75em;
	&:hover {
		cursor: pointer;
		border-radius: 25%;
		background-color: ${({ theme }) => theme.palette.colors.gray['100']};
		display: flex;
	}
`

const Image = styled('img')`
	width: 100%;
	height: 100%;
	object-fit: contain;
`

const Box = styled(FlexCol)`
	width: 80%;
	height: 80%;
	max-width: 80vw;
	max-height: 80vh;
	margin: auto;
	position: relative;
	overflow: hidden;
`

type Props = {
	url: string
} & Omit<ModalProps, 'children'>

export const ImageModal: React.ComponentType<Props> = ({
	open,
	onClose,
	url
}) => {
	return (
		<StyledModal open={open} onClose={onClose}>
			<Wrapper>
				<FlexRow justify={'flex-end'} style={{ width: '100%' }}>
					<CloseButtonWrapper>
						<IoIosClose
							size={25}
							id={'close-button'}
							onClick={() => onClose?.({}, 'escapeKeyDown')}
						/>
					</CloseButtonWrapper>
				</FlexRow>
				<Box>
					<Image src={url} />
				</Box>
			</Wrapper>
		</StyledModal>
	)
}
