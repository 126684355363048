import React, { useCallback, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import styled from '@emotion/styled'
import { Typography } from '@mui/material'
import { FlexCol } from 'components/atoms/Flex'
import Logo from 'assets/brand/order_logo.png'

const Wrapper = styled('div')`
	position: relative;
	display: flex;
	width: 100vw;
	flex: 1;
	height: 100vh;
	background-color: #ffffe7;
	overflow: hidden;
`

const PositionAbsolute = styled('div')`
	position: absolute;
	bottom: -10px;
	right: 0;
`

const NotFound = () => {
	const navigate = useNavigate()
	const location = useLocation()

	const redirect = useCallback(() => {
		if (location.pathname === '/') {
			navigate('/home', { replace: true })
		} else {
			setTimeout(() => {
				navigate('/home', { replace: true })
			}, 3000)
		}
	}, [location.pathname, navigate])

	// if user lands on the main page it auto redirect to the order
	useEffect(() => {
		redirect()
	}, [redirect])

	return (
		<Wrapper>
			<FlexCol
				align={'flex-start'}
				justify={'center'}
				style={{ marginLeft: 40, zIndex: 1 }}
			>
				<Typography variant={'h2'} fontWeight={700}>
					Error 404
				</Typography>
				<Typography>
					It looks like you’ve reached a URL that doesn’t exist.
					Redirecting you back to home...
				</Typography>
			</FlexCol>
			<PositionAbsolute>
				<img src={Logo} alt={'logo'} />
			</PositionAbsolute>
		</Wrapper>
	)
}

export { NotFound }
