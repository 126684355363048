export const FETCH_ALL_LABEL = 'FETCH_ALL_LABEL'
export const SET_ALL_LABEL = 'SET_ALL_LABEL'
export const FETCH_INVENTORY_LIST = 'FETCH_INVENTORY_LIST'

interface IFetchAllLabel {
	type: typeof FETCH_ALL_LABEL
}

interface ISetAllLabel {
	type: typeof SET_ALL_LABEL
	payload: string[]
}

export type InventoryActionsTypes = IFetchAllLabel | ISetAllLabel
