import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import type { InvoiceType, PaymentStatus } from 'typings/invoices'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useAppErrors, useDimension } from 'hooks'
import { Button, Divider, styled, Typography } from '@mui/material'
import { Capsule, Gutter } from 'components'
import { FlexCol, FlexRow } from 'components/atoms/Flex'
import ArrowBackIos from '@mui/icons-material/ArrowBackIos'
import { LoadSheetHeader } from './Component/LoadSheetHeader'
import { formatId, objectFindKey, objectOmit } from 'helpers'

import * as Yup from 'yup'
import { enqueueSnackbar } from 'notistack'
import { SCROLLBAR_STYLE } from 'data'

import { useDispatch } from 'react-redux'
import { setNavigation } from 'store/app/actions'
import { INVOICE_VIEW_NAVIGATION } from 'data/navigation'
import { LoadSheetPdf } from './LoadSheetPdf'
import { getALoadSheet } from 'api/loadSheet'
import { useSettings } from 'context/settings'
import { RightViewOptions } from './RightViewOptions'
import type { LoadsheetType } from 'typings/loadsheet'

const Wrapper = styled('div')<{ height: number }>`
	display: grid;
	border: 1px solid ${({ theme }) => theme.palette.colors.gray['300']};
	border-radius: 8px;
	grid-template-columns: auto 1px minmax(400px, auto);
	max-height: ${({ height }) => height}px;
	//height: max-content;
	width: 100%;
	overflow: auto;

	${SCROLLBAR_STYLE}
`

const DividerWrapper = styled(Divider)`
	width: 0;
	border: 0.5px solid ${({ theme }) => theme.palette.colors.gray['300']};
`

const Col = styled(FlexCol)<{ height: number }>`
	height: ${({ height }) => (height ? `${height}px` : '100%')};
	overflow: auto;

	box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.33);
	-webkit-box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.33);
	-moz-box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.33);

	${SCROLLBAR_STYLE}
`

const BackButton = styled(Button)`
	border-radius: 8px;
	padding: 6px 12px;
	border: 1px solid ${({ theme }) => theme.palette.colors.gray['300']};
	color: ${({ theme }) => theme.palette.colors.black['300']};
	box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.33);
`

const BackButtonIcon = styled(ArrowBackIos)`
	font-size: 17px;
`

const BackButtonText = styled(Typography)`
	color: ${({ theme }) => theme.palette.colors.gray['700']};
	font-weight: 500;
`

const InvoiceNumberCapsule = styled(Capsule)`
	padding: 3px 13px;
	font-size: 15px;
	font-weight: 500;
	line-height: 29px;
	border-radius: 26px;
`

const LeftViewOptions = styled(FlexCol)`
	padding: 24px 30px 0 24px;
`

const LeftViewHeader = styled(FlexRow)`
	align-items: center;
	justify-content: space-between;
	width: 100%;
	margin-bottom: 20px;
`

const validationSchema = Yup.object().shape({
	discount: Yup.object().shape({
		amount: Yup.number()
			.positive('Should be positive')
			.typeError('Number Required')
			.nullable()
			.min(0, 'should be greater than 0')
	}),
	contact: Yup.object().shape({
		name: Yup.string().required('Required')
	}),
	extraCharges: Yup.array().of(
		Yup.object().shape({
			description: Yup.string().required('Required'),
			amount: Yup.string().required('Required')
		})
	),
	items: Yup.array()
		.of(
			Yup.object().shape({
				perItemSellPrice: Yup.number()
					.positive('Error')
					.min(1, 'Required')
					.required('Required')
					.typeError('Required'),
				quantity: Yup.number()
					.positive('Error')
					.integer()
					.min(1, 'Required')
					.test('maxQuantity', (quantity, values) => {
						const { parent } = values
						let maxQuantity = parent?.maxQuantity

						if ((quantity || 0) > maxQuantity) {
							if (maxQuantity < 0) {
								maxQuantity = 0
							}
							return values.createError({
								path: values.path,
								message: `${maxQuantity} allowed`
							})
						}
						return true
					})
					.required('Required')
					.typeError('Required'),
				description: Yup.mixed().test(
					'description',
					(description, values) => {
						const { from } = values.options as any
						if (
							from &&
							objectFindKey(from[0].value, 'description')
						) {
							if (description === undefined) {
								return values.createError({
									path: values.path,
									message: 'Description is required'
								})
							}

							if (description === '') {
								return values.createError({
									path: values.path,
									message: 'Description is required'
								})
							}

							if (description.length > 500) {
								return values.createError({
									path: values.path,
									message:
										'Description should be less than 500 characters'
								})
							}
						}
						return true
					}
				)
			})
		)
		.min(1, 'min 1 item is required')
		.required('Required')
})

export const LoadSheetView = () => {
	const [currentLoadSheet, setCurrentLoadSheet] = useState<LoadsheetType>()
	const { state } = useLocation()
	const { id } = useParams()
	const { setAppError } = useAppErrors()
	const { height } = useDimension()
	const dispatch = useDispatch()
	const componentRef = useRef(null)
	const { business } = useSettings()

	const navigate = useNavigate()

	const dispatchTitle = useCallback(
		(loadSheet: LoadsheetType) => {
			dispatch(
				setNavigation(
					INVOICE_VIEW_NAVIGATION(
						loadSheet?.friendlyId || '0',
						loadSheet?.id || ''
					)
				)
			)
		},
		[dispatch]
	)

	const getLoadsheetDetails = useCallback(async () => {
		try {
			const res = await getALoadSheet(business, id || '0')

			setCurrentLoadSheet(res)
		} catch (e: any) {
			setAppError(e)
		}
	}, [id, dispatchTitle, setAppError])

	useEffect(() => {
		if (state?.data) {
			setCurrentLoadSheet(state?.data)
			dispatchTitle(state?.data)
		} else {
			getLoadsheetDetails()
		}
	}, [dispatchTitle, getLoadsheetDetails, id, state])

	useEffect(() => {
		getLoadsheetDetails()
	}, [getLoadsheetDetails])

	return (
		<>
			{currentLoadSheet && (
				<>
					<LoadSheetHeader loadSheet={currentLoadSheet} />
					<Gutter gap={2} />

					<Wrapper height={height - 150}>
						<LeftViewOptions>
							<Col height={height}>
								<LoadSheetPdf
									ref={componentRef}
									loadSheet={currentLoadSheet}
								/>
							</Col>
						</LeftViewOptions>
						<DividerWrapper orientation="vertical" flexItem />
						<RightViewOptions loadSheet={currentLoadSheet} />
					</Wrapper>
				</>
			)}
		</>
	)
}
