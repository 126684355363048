import { TableCell, TableRow, Typography, useTheme } from '@mui/material'
import * as React from 'react'
import { styled } from '@mui/system'

const Cell = styled(TableCell)`
	background-color: ${({ theme }) => theme.palette.colors.gray['50']};
	color: ${({ theme }) => theme.palette.colors.gray['500']};
	padding: 7px 7px 7px 24px;
`

const Text = styled(Typography)`
	color: ${({ theme }) => theme.palette.colors.gray['500']};
	font-weight: 500;
`

const SmallCell = styled(TableCell, {
	shouldForwardProp: props => props !== 'isVoid'
})`
	background-color: ${({ theme }) => theme.palette.colors.gray['50']};
	color: ${({ theme }) => theme.palette.colors.gray['500']};
	padding: 7px 7px 7px 24px;
	min-width: 150px;
	max-width: 200px;
`

const Amount = styled(TableCell)<{ isVoid?: boolean }>`
	background-color: ${({ theme }) => theme.palette.colors.gray['500']};
	color: ${({ theme }) => theme.palette.colors.gray['500']};
	padding: 7px 24px 7px 7px;
`

export const TableHeader = () => {
	const theme = useTheme()
	const {
		palette: { colors }
	} = theme

	return (
		<TableRow>
			<SmallCell variant="head" align={'left'} width={150}>
				<Text variant={'caption'}>Entry Date</Text>
			</SmallCell>
			<SmallCell variant="head" align={'left'} width={170}>
				<Text variant={'caption'}>Invoice</Text>
			</SmallCell>
			<Cell variant="head" align={'left'}>
				<Text variant={'caption'}>Details</Text>
			</Cell>
			<Cell variant="head" align={'right'} sx={{ paddingRight: '24px' }}>
				<Text variant={'caption'}>Amount</Text>
			</Cell>
			<Amount variant="head" align={'right'} width={260}>
				<Text variant={'caption'} sx={{ color: colors.white['500'] }}>
					Account Balance
				</Text>
			</Amount>
		</TableRow>
	)
}
