import { axios } from 'store'
import type { IAddUser, IUser, IUpdateUser } from 'typings'

export const getCurrentUser = async () => {
	const url = '/auth/current-user'

	try {
		const { data } = await axios.get<IUser>(url)

		return data
	} catch (error: any) {
		throw error.response.data
	}
}

export const updateUser = async (payload: IUpdateUser) => {
	const url = '/auth/user'

	try {
		const { data } = await axios.patch<IUser>(url, payload)

		return data
	} catch (error: any) {
		throw error.response.data
	}
}

export const addUser = async (payload: IAddUser) => {
	const url = '/auth/user'
	const { secretKey, ...rest } = payload
	const headers = {
		secret: secretKey
	}

	try {
		const { data } = await axios.post<any>(url, rest, {
			headers
		})

		return data
	} catch (error: any) {
		throw error.response.data
	}
}
