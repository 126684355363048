import React from 'react'
import type { LoadsheetType } from 'typings/loadsheet'
import { TableCell, Typography, useTheme } from '@mui/material'
import { styled } from '@mui/system'
import { formatId } from 'helpers'
import moment from 'moment'
import { dateFormat } from 'data'
import { TransparentButton } from 'components/atoms/Button/TransparentButton'
import { AiOutlineEye } from 'react-icons/ai'
import { Gutter } from 'components'
import { useNavigate } from 'react-router-dom'

const Cell = styled(TableCell)`
	color: ${({ theme }) => theme.palette.colors.gray['500']};
	padding: 7px 7px 7px 24px;
`

const Text = styled(Typography)`
	color: #1c1c1c;

	font-weight: 500;
`

const SmallCell = styled(TableCell, {
	shouldForwardProp: props => props !== 'isVoid'
})`
	color: ${({ theme }) => theme.palette.colors.gray['500']};
	padding: 7px 7px 7px 24px;
	min-width: 150px;
	max-width: 200px;
`

type LoadItemProps = {} & LoadsheetType

export const LoadItem: React.ComponentType<LoadItemProps> = ({
	friendlyId,
	invoiceItems,
	createdAt,
	dispatchDate,
	totalAmountDue,
	...props
}) => {
	const navigate = useNavigate()
	const theme = useTheme()
	const {
		palette: { colors }
	} = theme

	const quantity = invoiceItems.reduce((acc, invoiceItem) => {
		const itemsQuantity = invoiceItem.items.reduce((itemAcc, item) => {
			return itemAcc + parseInt(`${item.quantity}`)
		}, 0)

		return acc + itemsQuantity
	}, 0)

	return (
		<>
			<SmallCell variant="head" align={'left'}>
				<Text variant={'subtitle2'} fontWeight={400}>
					{`LS-${formatId(friendlyId)}`}
				</Text>
			</SmallCell>
			<SmallCell variant="head" align={'left'}>
				<Text variant={'subtitle2'} fontWeight={400}>
					{invoiceItems.length}
				</Text>
			</SmallCell>
			<Cell variant="head" align={'left'}>
				<Text variant={'subtitle2'} fontWeight={400}>
					{quantity} Qty
				</Text>
				<Text
					variant={'caption'}
					fontWeight={400}
					style={{ color: colors.gray['600'] }}
				>
					3 items
				</Text>
			</Cell>
			<Cell variant="head" align={'right'} sx={{ paddingRight: '24px' }}>
				<Text variant={'subtitle2'} fontWeight={400}>
					{totalAmountDue}
				</Text>
			</Cell>
			<Cell variant="head" align={'right'} sx={{ paddingRight: '24px' }}>
				<Text variant={'subtitle2'} fontWeight={400}>
					{moment(dispatchDate).format(dateFormat)}
				</Text>
			</Cell>
			<Cell variant="head" align={'right'} sx={{ paddingRight: '24px' }}>
				<Text variant={'subtitle2'} fontWeight={400}>
					{moment(createdAt).format(dateFormat)}
				</Text>
			</Cell>
			<Cell variant="head" align={'right'} sx={{ paddingRight: '24px' }}>
				<TransparentButton
					onClick={() =>
						navigate(`/load-sheet/view/${props.id}`)
					}
				>
					<AiOutlineEye color={colors.gray['700']} />
					<Gutter gap={0.25} />
					<Typography variant={'caption'} fontWeight={400}>
						View
					</Typography>
				</TransparentButton>
			</Cell>
		</>
	)
}
