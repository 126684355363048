import React, { useCallback } from 'react'
import { useField } from 'formik'
import { OtpInput, type OtpProps } from 'components/molecules/OtpInput/OtpInput'
import { ErrorText } from 'components/atoms'
import { FlexCol } from 'components/atoms/Flex'

type FieldOtpTextInputProps = {
	name: string
	handleChange?(value?: string): void
} & Omit<OtpProps, 'onChange'>

export const FieldOtpTextInput: React.ComponentType<FieldOtpTextInputProps> = ({
	name,
	handleChange,
	...props
}) => {
	const [field, meta, helpers] = useField(name as any)

	const error = meta.touched && meta.error

	const handleBlur = useCallback(() => {
		helpers.setTouched(true)
	}, [helpers])

	const onChange = (value?: string) => {
		const str = value?.replace(/^[a-z, A-Z]+$/, '')
		helpers.setValue(str)
		handleChange?.(str)
	}

	return (
		<FlexCol align={'flex-start'}>
			<OtpInput
				value={field.value ? field.value.toString() : ''}
				onChange={onChange}
				onBlur={handleBlur}
				error={!!error}
				{...props}
			/>
			<ErrorText text={error} />
		</FlexCol>
	)
}
