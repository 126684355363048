import React from 'react'
import type { AvatarProps as MuiAvatarProps } from '@mui/material'
import { Avatar as MuiAvatar, useTheme } from '@mui/material'
import { styled } from '@mui/system'
import type { ColorsCodeType } from 'typings'
import { FiUser } from 'react-icons/fi'

const Wrapper = styled('div', {
	shouldForwardProp: props =>
		props !== 'borderWidth' && props !== 'borderColor'
})<Pick<AvatarProps, 'borderWidth' | 'borderColor'>>`
	display: inline-block;
	width: auto;
	height: auto;
	aspect-ratio: 1;
	border-radius: 50%;
	background-color: ${({ theme, borderColor }) =>
		borderColor ? borderColor : theme.palette.colors.green['700']};
	border: ${({ borderWidth }) => borderWidth}px solid
		${({ theme }) => theme.palette.colors.green['200']};
`

type AvatarProps = {
	borderWidth?: number
	borderColor?: ColorsCodeType
	title?: string
} & MuiAvatarProps

export const Avatar: React.ComponentType<AvatarProps> = ({
	borderWidth,
	borderColor,
	title,
	sx,
	...props
}) => {
	const theme = useTheme()
	return (
		<Wrapper borderWidth={borderWidth} borderColor={borderColor}>
			<MuiAvatar
				sx={{
					backgroundColor: theme.palette.colors.green['100'],
					color: theme.palette.colors.green['900'],
					...sx
				}}
				{...props}
			>
				{!title && <FiUser />}
				{title}
			</MuiAvatar>
		</Wrapper>
	)
}
