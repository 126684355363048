const createImage = (url: string) =>
	new Promise<CanvasImageSource>((resolve, reject) => {
		const image = new Image()
		image.addEventListener('load', () => resolve(image))
		image.addEventListener('error', error => reject(error))
		image.setAttribute('crossOrigin', 'anonymous')
		image.src = url
	})

export default async function getCroppedImg(imageSrc: any, pixelCrop: any) {
	const image = await createImage(imageSrc)
	const canvas = document.createElement('canvas')
	const context = canvas.getContext('2d')

	const imageWidth = image.width as number
	const imageHeight = image.height as number

	// Set canvas size to match image size
	canvas.width = imageWidth
	canvas.height = imageHeight

	// Draw the image on the canvas

	context?.drawImage(
		image,
		0,
		0,
		imageWidth,
		imageHeight,
		0,
		0,
		canvas.width,
		canvas.height
	)

	const croppedImageData = context?.getImageData(
		pixelCrop.x,
		pixelCrop.y,
		pixelCrop.width,
		pixelCrop.height
	)

	const croppedCanvas = document.createElement('canvas')
	const croppedContext = croppedCanvas.getContext('2d')
	croppedCanvas.width = pixelCrop.width
	croppedCanvas.height = pixelCrop.height

	if (croppedImageData) croppedContext?.putImageData(croppedImageData, 0, 0)

	return new Promise<Blob | null>(resolve => {
		croppedCanvas.toBlob(
			blob => {
				resolve(blob)
			},
			'image/jpeg',
			1
		)
	})
}
