import React from 'react'
import type { InventoryItemType } from 'typings'
import { styled } from '@mui/system'
import { CheckBox, Gutter } from 'components'
import { TableCell, TableRow, Typography } from '@mui/material'
import type { InventoryTableProps } from 'screen/OrderForm/Inventory/OrderFormInventoryTable'
import { currencyInput, objectOmit } from 'helpers'
import { StockMob } from 'screen/OrderForm/Inventory/StockMob'
import { FlexRow } from 'components/atoms/Flex'
import { Unit } from './Unit'

const StyledTableRow = styled(TableRow)(() => ({
	'&:last-child td, &:last-child th': {
		border: 0
	}
}))

const MUIRow = styled(StyledTableRow, {
	shouldForwardProp: props => props !== 'isSelected' && props !== 'disabled'
})<{ isSelected?: boolean; disabled?: boolean }>`
	min-width: max-content;
	background-color: ${({ theme, isSelected, disabled }) =>
		disabled
			? theme.palette.colors.gray['100']
			: isSelected
			? theme.palette.mode === 'dark'
				? theme.palette.colors.gray['600']
				: theme.palette.colors.blue['50']
			: theme.palette.background.default};
	opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};

	:hover {
		cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
		background-color: ${({ theme, disabled }) =>
			disabled
				? theme.palette.colors.gray['100']
				: theme.palette.mode === 'dark'
				? theme.palette.colors.gray['700']
				: theme.palette.colors.blue['100']};
	}
`

const Row = styled(FlexRow)`
	display: none;
	width: 100%;

	@media (max-width: 650px) {
		display: flex;
	}
`

const CustomTableCell = styled(TableCell, {
	shouldForwardProp: props => props !== 'width' && props !== 'hidden'
})<{ width?: number; hidden?: boolean }>`
	min-width: ${({ width }) => (width ? `${width}px` : '200px')};
	max-width: 350px;

	@media (max-width: 650px) {
		display: ${({ hidden }) => (hidden ? 'none' : 'table-cell')};
		visibility: ${({ hidden }) => (hidden ? 'hidden' : 'visible')};
	}
`

export type InventoryRowProps<O> = {
	row: O
	setActiveInventory?(id?: string): void
	disabled?: boolean
} & Pick<InventoryTableProps<O>, 'selectedInventory' | 'onRowClick'>

export const InventoryRow: React.ComponentType<
	InventoryRowProps<InventoryItemType>
> = ({ row, onRowClick, selectedInventory, disabled }) => {
	const item = row.stock[0]
	const isSelected = !!selectedInventory?.find(
		(e: any) => e.stockId === item.id
	)

	const onRowClickHandler = () => {
		if (!disabled) {
			const obj: InventoryItemType = {
				...objectOmit(row, 'stock'),

				stock: row.stock.map(d => {
					return objectOmit(
						{
							...d,
							quantity: 0,
							stockId: row.stock[0].id,
							maxQuantity: d.quantity
						},
						'id'
					)
				})
			}

			onRowClick?.(obj)
		}
	}

	return (
		<MUIRow
			onClick={onRowClickHandler}
			isSelected={isSelected}
			disabled={disabled}
		>
			<CustomTableCell
				component="th"
				scope="row"
				width={50}
				hidden={true}
			>
				<CheckBox checked={isSelected} />
			</CustomTableCell>
			<CustomTableCell component="th" scope="row">
				<Typography
					variant={'subtitle2'}
					style={{ wordWrap: 'break-word' }}
				>
					{row.name}
				</Typography>
				<Gutter spacing={0.2} />
				<Row align={'center'}>
					<StockMob row={row} />
				</Row>
			</CustomTableCell>
			<CustomTableCell component="th" scope="row">
				<Typography
					variant={'subtitle2'}
					style={{ wordWrap: 'break-word' }}
				>
					{currencyInput(`${row.stock[0]?.perItemSellPrice}`)}
				</Typography>
			</CustomTableCell>
			<CustomTableCell align="left" hidden={true}>
				<Unit row={row} displayStockQuantity={true} />
			</CustomTableCell>
		</MUIRow>
	)
}
