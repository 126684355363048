import React, { type FC, type ReactElement } from 'react'
import { useField } from 'formik'
import { ErrorText } from 'components/atoms'
import type { RadioGroupProps } from '@mui/material'
import { CustomRadioGroup } from 'components/atoms/RadioGroup'

export type RadioGroupFieldProps = {
	name: string
	options: {
		value: string
		label: string
	}[]
} & Omit<RadioGroupProps, 'onChange' | 'defaultValue' | 'name'>

export const BusinessRelationShip: FC<RadioGroupFieldProps> = ({
	name,
	options,
	...props
}): ReactElement => {
	const [, meta, helpers] = useField(name as any)

	const onChange = (str: string) => {
		helpers.setValue([str])
	}

	return (
		<>
			<CustomRadioGroup
				radioProps={{ size: 'small' }}
				options={options}
				// this is special case
				defaultValue={meta.value.length > 1 ? ['both'] : meta.value}
				onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
					onChange(event?.target.value)
				}
				{...props}
			/>

			{meta.touched && meta.error && <ErrorText text={meta.error} />}
		</>
	)
}
