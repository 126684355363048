import React, { useState } from 'react'
import { styled } from '@mui/system'
import { Capsule, CheckBox, Gutter } from 'components'
import { FlexCol, FlexRow } from 'components/atoms/Flex'
import { Popover, Typography, useTheme } from '@mui/material'
import type { ColorsCodeType, IOrderCenterProps } from 'typings'
import { pascalCase } from 'helpers'
import moment from 'moment'
import { BiDotsVerticalRounded } from 'react-icons/bi'
import { ItemAvatar } from 'screen/OrderCenter/Components'
import { dateFormat } from 'data'
import { Abbr } from 'components/atoms/Abbr'
import { GiPlainCircle } from 'react-icons/gi'

export const VerticalThreeDots = styled(BiDotsVerticalRounded, {
	shouldForwardProp: props => props !== 'isVisible'
})<{
	isVisible?: boolean
}>`
	cursor: pointer;
	visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
`

const Item = styled(FlexRow, {
	shouldForwardProp: props => props !== 'isSelected'
})<{ isSelected?: boolean }>`
	background-color: ${({ theme, isSelected }) =>
		isSelected ? theme.palette.colors.blue['50'] : 'transparent'};
	width: 100%;
	padding: 10px 16px;

	:hover {
		cursor: pointer;
		background-color: ${({ theme }) => theme.palette.colors.blue['100']};
	}
`

const Col = styled(FlexCol)`
	padding: 1px 0;
	flex: 1;
	align-items: flex-start;
`

const Box = styled(FlexRow)`
	width: 16px;
	height: 16px;
`

const StyledCapsule = styled(Capsule)`
	padding: 2px 5px;
	font-size: 12px;
	font-weight: 500;
	margin: 0;
`

const Text = styled(Typography)`
	font-size: 12px;
`
const PopoverElement = styled(Typography)(({ theme }) => ({
	backgroundColor: theme.palette.background.default,
	padding: '10px 80px 10px 10px',
	cursor: 'pointer',

	'&:hover': {
		backgroundColor: theme.palette.background.paper
	}
}))

type UserItemProps = {
	item: IOrderCenterProps
	selectedItem?: string[]
	onClick?(item: IOrderCenterProps): void
	page: string
	isSelected?: boolean
	onDelete?(orderId: string[]): void
	onItemClick?(id: string): void
	onEditClick?(value: IOrderCenterProps): void
}

export const UserItem: React.ComponentType<UserItemProps> = ({
	item,
	onClick,
	page,
	onDelete,
	isSelected,
	selectedItem,
	onItemClick,
	onEditClick
}) => {
	const [isVisible, setIsVisible] = useState<boolean>(false)
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
	const theme = useTheme()
	const {
		palette: { colors }
	} = theme
	const {
		contact: { name },
		type,
		items,
		initiationAt,
		read
	} = item

	const isSel = selectedItem?.findIndex(i => i === item.id) !== -1
	const isRecycleBin = page === 'recyclebin'

	const open = Boolean(anchorEl)

	return (
		<>
			<Item
				isSelected={isSelected || selectedItem ? isSel : false}
				align="center"
				onMouseOver={() => setIsVisible(true)}
				onMouseOut={() => setIsVisible(false)}
			>
				{selectedItem && (
					<FlexRow>
						<CheckBox
							checked={isSelected || selectedItem ? isSel : false}
							onClick={() => {
								onItemClick?.(item.id as string)
							}}
						/>
						<Gutter gap={0.5} />
					</FlexRow>
				)}
				<FlexRow
					style={{ flex: 1 }}
					onClick={() => {
						onClick?.(item)
						onItemClick?.(item.id as string)
					}}
				>
					<ItemAvatar
						src={item.contact?.profilePic}
						channel={item.channel}
						borderWidth={2}
						borderColor={colors.green['100'] as ColorsCodeType}
					/>
					<Gutter gap={0.6} />
					<Col>
						<FlexRow
							justify={'space-between'}
							align={'center'}
							style={{ width: '100%' }}
						>
							<FlexRow>
								<Typography variant={'body2'}>
									<Abbr
										length={isRecycleBin ? 8 : 14}
										title={name}
									/>
								</Typography>
								<Gutter gap={0.4} />
								<StyledCapsule
									isSelected
									name={pascalCase(type)}
									value={type}
									color={type === 'order' ? 'yellow' : 'blue'}
								/>
								{isRecycleBin && (
									<StyledCapsule
										isSelected
										name={`${
											30 -
											moment(moment()).diff(
												moment(
													moment(item.deleted?.asOf)
												),
												'days'
											)
										} days until deletion`}
										value={type}
										color={'red'}
									/>
								)}
							</FlexRow>
							<Typography variant={'caption'} fontSize={12}>
								{moment(initiationAt).format(dateFormat)}
							</Typography>
						</FlexRow>
						<FlexRow
							justify={'space-between'}
							align={'center'}
							style={{ width: '100%' }}
						>
							<Text variant={'caption'}>
								{type === 'order'
									? `Placed an order for ${items.length} items.`
									: `Made an inquiry for ${items.length} items.`}
							</Text>
							<Box>
								{!read?.status && (
									<GiPlainCircle
										color={colors['red']['500']}
										size={14}
									/>
								)}
							</Box>
						</FlexRow>
					</Col>
				</FlexRow>
				<VerticalThreeDots
					isVisible={isVisible || open}
					size={20}
					color="black"
					onClick={(e: any) => setAnchorEl(e.currentTarget)}
				/>
			</Item>
			<Popover
				elevation={2}
				open={open}
				anchorEl={anchorEl}
				onClose={() => setAnchorEl(null)}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right'
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right'
				}}
			>
				{page == 'default' && (
					<>
						<PopoverElement
							variant="body2"
							onClick={() => {
								onEditClick?.(item)
								setAnchorEl(null)
							}}
						>
							<Typography color={'black'} variant={'caption'}>
								Edit
							</Typography>
						</PopoverElement>
						<PopoverElement
							variant="body2"
							onClick={() => {
								onDelete?.([item.id || ''])
								setAnchorEl(null)
							}}
						>
							<Typography
								color={'red'}
								variant={'caption'}
								fontWeight={600}
							>
								Delete
							</Typography>
						</PopoverElement>
					</>
				)}
				{isRecycleBin && (
					<>
						<PopoverElement
							variant="body2"
							onClick={() => {
								onDelete?.([item.id || ''])
								setAnchorEl(null)
							}}
						>
							<Typography color={'black'} variant={'caption'}>
								Restore
							</Typography>
						</PopoverElement>
					</>
				)}
			</Popover>
		</>
	)
}
