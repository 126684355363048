import React, { useState } from 'react'
import { Popover, Stack, Typography } from '@mui/material'
import { styled } from '@mui/system'
import { Gutter } from 'components'
import { FlexRow } from 'components/atoms/Flex'
import { PopoverElement } from 'screen/Quotations'
import ReceiptIcon from '@mui/icons-material/Receipt'
import CalculateIcon from '@mui/icons-material/Calculate'
import type { IOrderCenterProps } from 'typings'
import { useNavigate } from 'react-router-dom'
import analytics from 'utils/segment'
import { SEGMENT_EVENTS } from 'data'
import { objectOmit } from 'helpers'

const CapsuleButton = styled(FlexRow)`
	background-color: ${({ theme }) => theme.palette.colors['green']['50']};
	padding: 5px 10px;
	align-items: center;
	justify-content: center;

	:hover {
		cursor: pointer;
	}
`

type GenerateButtonProps = {
	item: IOrderCenterProps
}

export const GenerateButton: React.ComponentType<GenerateButtonProps> = ({
	item
}) => {
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
	const navigate = useNavigate()

	const handleClick = (event: any) => {
		analytics.track(SEGMENT_EVENTS.ORDER_GENERATE)
		setAnchorEl(event.currentTarget)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	const open = Boolean(anchorEl)

	return (
		<Stack direction="row" spacing={2}>
			<div>
				<CapsuleButton id="composition-button" onClick={handleClick}>
					<Typography
						color={'primary'}
						fontWeight={500}
						variant={'body2'}
					>
						Generate
					</Typography>
				</CapsuleButton>
				<Popover
					elevation={1}
					open={open}
					anchorEl={anchorEl}
					onClose={handleClose}
					anchorOrigin={{
						vertical: 'top',
						horizontal: 'right'
					}}
					transformOrigin={{
						vertical: 'bottom',
						horizontal: 'right'
					}}
				>
					<PopoverElement
						onClick={() => {
							analytics.track(
								SEGMENT_EVENTS.ORDER_GENERATE_SUCCESS,
								{
									option_selected: 'quotation',
									label: item.type
								}
							)
							navigate('/quotation/create-quotation', {
								state: { quotation: item }
							})
						}}
					>
						<FlexRow align="center">
							<CalculateIcon color="info" />
							<Gutter gap={0.5} />
							<Typography>Quotation</Typography>
						</FlexRow>
					</PopoverElement>
					<PopoverElement
						onClick={() => {
							analytics.track(
								SEGMENT_EVENTS.ORDER_GENERATE_SUCCESS,
								{
									option_selected: 'invoice',
									label: item.type
								}
							)

							navigate('/invoice/create-invoice', {
								state: {
									currentInvoice: objectOmit(item, 'id')
								}
							})
						}}
					>
						<FlexRow align="center">
							<ReceiptIcon color="warning" />
							<Gutter gap={0.5} />
							<Typography>Invoice</Typography>
						</FlexRow>
					</PopoverElement>
				</Popover>
			</div>
		</Stack>
	)
}
