import React, { useCallback, useMemo, useState } from 'react'
import {
	TableBody,
	TableCell,
	TableHead,
	TableSortLabel,
	Typography,
	useTheme
} from '@mui/material'
import { styled } from '@mui/system'
import type { ColumnVisibilityProps } from 'data'
import type { InventoryItemType } from 'typings'
import { InventoryRow } from 'screen/Inventory/TableComponents/Inventory/InventoryRow'
import { AddInventoryModal } from 'screen/Inventory/AddStock'
import type {
	InventoryColProps,
	InventorySortType
} from 'screen/Inventory/Inventory'
import { RestockModal } from 'screen/Inventory/RestockModal'
import { useDimension } from 'hooks'
import { EditColumn } from './EditColumn'

const ProductCell = styled(TableCell)<{ width?: number }>`
	min-width: ${({ width }) => (width ? `${width}px` : '300px')};
	background-color: ${({ theme }) => theme.palette.colors.gray['50']};
	color: ${({ theme }) => theme.palette.colors.gray['500']};
`

const MedCell = styled(TableCell)<{ width?: number }>`
	min-width: ${({ width }) => (width ? `${width}px` : '180px')};
	max-width: 200px;
	background-color: ${({ theme }) => theme.palette.colors.gray['50']};
	color: ${({ theme }) => theme.palette.colors.gray['500']};
`

const CustomTableCell = styled(TableCell)<{ width?: number }>`
	min-width: ${({ width }) => (width ? `${width}px` : '200px')};
	max-width: 350px;
	background-color: ${({ theme }) => theme.palette.colors.gray['50']};
	color: ${({ theme }) => theme.palette.colors.gray['500']};
`

const StyledTableBody = styled(TableBody)<{ height: number }>`
	height: 100%;
	max-height: ${({ height }) => `${height}px`};
`

export type InventoryTableProps<T> = {
	columnObj: InventoryColProps
	gridTemplateColumns?: string
	searchKey?: string
	onSort(name: string): void
	sortObj: InventorySortType
	onSuccess?: () => void
	columnVisibility?: ColumnVisibilityProps[]
	editable?: boolean
	inventoryState: {
		dataSource: T[]
		transformedData: T[]
		loading: boolean
	}
	onVisibilityClick: (value: ColumnVisibilityProps[]) => void
}

export const InventoryTable: React.ComponentType<
	InventoryTableProps<InventoryItemType>
> = ({
	columnVisibility = [],
	sortObj,
	onSort,
	columnObj,
	onSuccess,
	inventoryState = { dataSource: [], loading: false, transformedData: [] },
	onVisibilityClick
}) => {
	const [activeInventory, setActiveInventory] = useState<
		InventoryItemType | undefined
	>(undefined)
	const [isRestockOpen, setIsRestockOpen] = useState(false)
	const [onRestock, setOnRestock] = useState<InventoryItemType | undefined>(
		undefined
	)
	const { height } = useDimension()
	const theme = useTheme()
	const { colors } = theme.palette

	const visibleColumn = useMemo(() => {
		return columnVisibility.filter(d => d.isSelected)
	}, [columnVisibility])

	const gridTemplateColumns = useMemo(() => {
		let res = 'minmax(300px,2fr) 0.5fr'
		for (const [, value] of Object.entries(columnObj)) {
			res += ' ' + value.width
		}
		res += ' 1.2fr'

		return res
	}, [columnObj])

	const RenderRow = useCallback(
		({ row }: { row: InventoryItemType }) => (
			<InventoryRow
				row={row}
				setRestockOpen={value => {
					setIsRestockOpen(true)
					setOnRestock(value)
				}}
				setActiveInventory={i => {
					setActiveInventory(
						inventoryState.dataSource.filter(o => o.id === i)[0]
					)
				}}
				columnObj={columnObj}
				gridTemplateColumns={gridTemplateColumns}
			/>
		),
		[columnObj, gridTemplateColumns, inventoryState.dataSource]
	)

	return (
		<>
			<TableHead>
				<ProductCell
					id={'name'}
					sortDirection={sortObj.sortOrder}
					onClick={() => onSort('name')}
				>
					<TableSortLabel
						active={sortObj.sortBy === 'name'}
						direction={sortObj.sortOrder}
					>
						<Typography
							variant={'body2'}
							style={{ color: colors.gray['500'] }}
							fontWeight={'500'}
						>
							Item Name
						</Typography>
					</TableSortLabel>
				</ProductCell>
				<MedCell align="left" width={100}>
					<Typography variant={'body2'} fontWeight={'500'}>
						Code
					</Typography>
				</MedCell>
				{visibleColumn.map(item => (
					<CustomTableCell
						align="left"
						key={item.id}
						width={item?.widthPx}
					>
						<Typography variant={'body2'} fontWeight={'500'}>
							{item.name}
						</Typography>
					</CustomTableCell>
				))}
				<MedCell align="right" width={100}>
					<EditColumn
						columnVisibility={columnVisibility}
						onClick={onVisibilityClick}
					/>
				</MedCell>
			</TableHead>
			<StyledTableBody height={height - 230}>
				{inventoryState.transformedData.map(row => (
					<RenderRow key={`${row.stock[0].id}`} row={row} />
				))}
			</StyledTableBody>

			<AddInventoryModal
				open={!!activeInventory}
				onClose={() => setActiveInventory(undefined)}
				inventory={activeInventory}
				onSuccess={onSuccess}
			/>

			{onRestock && (
				<RestockModal
					inventory={onRestock}
					open={isRestockOpen}
					onSuccess={onSuccess}
					onClose={() => {
						setIsRestockOpen(false)
						setOnRestock(undefined)
					}}
				/>
			)}
		</>
	)
}
