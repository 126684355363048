import {
	type ModalProps,
	Paper,
	styled,
	Table,
	TableBody,
	TableContainer,
	Typography
} from '@mui/material'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import {
	FilterButton,
	type FilterObjProps,
	Gutter,
	SearchBar
} from 'components'
import { FlexCol, FlexRow } from 'components/atoms/Flex'
import { useSettings } from 'context/settings'
import { getQuotationList } from 'api/quotation'
import type { QuotationType, ResponsePageInfo } from 'typings'
import { useBottomScrollListener } from 'react-bottom-scroll-listener'
import { useAppErrors, useDimension } from 'hooks'
import { QuotationModalItem } from 'components/app/QuotationModal/QuotationItem'
import { QuotationTableHeader } from 'components/app/QuotationModal/QuotationTableHeader'
import { StyledModal } from 'components/StyledModal'
import { IoIosClose } from 'react-icons/io'
import analytics from 'utils/segment'
import {
	LAYOUT_PADDING,
	QUOTATION_FILTER_OBJ,
	SCROLLBAR_STYLE,
	SEGMENT_EVENTS
} from 'data'

const CONTAINER_PADDING = 15

const StyledPaper = styled(Paper)`
	width: 100%;
	height: 100%;
	overflow: hidden;
	position: relative;
	z-index: 0;
	background-color: ${({ theme }) => theme.palette.background.default};
`

const Wrapper = styled('div')<{ height: number }>`
	border: 1px solid ${({ theme }) => theme.palette.background.paper};
	border-radius: 7px;
	overflow: scroll;
	height: 90%;
	margin-top: 8px;
	width: 100%;
	max-height: ${({ height }) => `${height}px`};
	min-height: ${({ height }) => `${height}px`};

	${SCROLLBAR_STYLE}
`

const StyledTable = styled(Table)`
	min-width: 700px;
	background-color: ${({ theme }) => theme.palette.background.default};
`

const CloseButtonWrapper = styled(FlexCol)`
	justify-content: center;
	align-items: center;
	height: 30px;
	width: 30px;
	right: 1.75em;
	&:hover {
		cursor: pointer;
		border-radius: 25%;
		background-color: ${({ theme }) => theme.palette.colors.gray['100']};
		display: flex;
	}
`

const Container = styled(FlexCol)`
	width: 75%;
	background-color: ${({ theme }) => theme.palette.background.default};
	padding: ${CONTAINER_PADDING}px;
	z-index: 0;
	border-radius: 10px;
`

type Props = {
	onQuotationSelect?(quotation: QuotationType): void
} & Omit<ModalProps, 'children'>

export const SelectQuotationModal: React.ComponentType<Props> = ({
	onClose,
	onQuotationSelect,
	...props
}) => {
	const [filterObj, setFilterObj] = useState<FilterObjProps>({})
	const [dataSource, setDataSource] = useState<QuotationType[]>([])
	const [pageInfo, setPageInfo] = useState<ResponsePageInfo>({
		currentPage: 1,
		edgesPerPage: 10,
		totalPages: 1
	})
	const [tHeight, setTHeight] = useState<number>()
	const [loading, setLoading] = useState<boolean>(true)
	const [searchText, setSearchText] = useState<string>('')
	const [isActive, setIsActive] = useState<boolean>(false)
	const { business } = useSettings()
	/*const [sortObj, setSortObj] = useState<ContactSortType>({
		sortOrder: 'asc',
		sortBy: 'name'
	})*/

	const { height } = useDimension()
	const scrollRef = useBottomScrollListener(() => getDataSource(), {
		offset: 10,
		debounce: 200
	})
	const ref = useRef<HTMLDivElement>(null)
	const { setAppError } = useAppErrors()

	const getDataSource = async () => {
		try {
			setLoading(true)
			if (
				!loading &&
				pageInfo?.totalPages &&
				pageInfo?.totalPages >= pageInfo.currentPage + 1
			) {
				const res = await getQuotationList(business, {
					page: pageInfo.currentPage + 1,
					limit: 20,
					searchKey: searchText,
					...filterObj
				})
				setDataSource([...dataSource, ...res.edges])
				setPageInfo(res.pageInfo)
			}
		} catch (e: any) {
			setAppError(e)
		} finally {
			setLoading(false)
		}
	}

	const getInitialDataSource = useCallback(async () => {
		try {
			setLoading(true)
			const res = await getQuotationList(business, {
				page: 1,
				limit: 20,
				searchKey: searchText,
				...filterObj
			})
			setDataSource(res.edges)
			setPageInfo(res.pageInfo)
		} catch (e: any) {
			setAppError(e)
		} finally {
			setLoading(false)
		}
	}, [business, filterObj, searchText, setAppError])

	const RenderRow = useCallback(
		({ row }: { row: QuotationType }) => {
			return (
				<QuotationModalItem
					row={row}
					onSelect={value => {
						onQuotationSelect?.(value)
						analytics.track(SEGMENT_EVENTS.INVOICE_IMPORT_QUOTATION)
						analytics.track(
							SEGMENT_EVENTS.INVOICE_IMPORT_QUOTATION_SUCCESS
						)
						onClose?.({}, 'escapeKeyDown')
					}}
				/>
			)
		},
		[onClose, onQuotationSelect]
	)

	useEffect(() => {
		getInitialDataSource()
	}, [getInitialDataSource])

	useEffect(() => {
		setTimeout(() => {
			setTHeight(ref.current?.clientHeight || 0)
		}, 100)
	})

	return (
		<>
			<StyledModal onClose={onClose} {...props}>
				<Container style={{ height: height * 0.8 }}>
					<FlexRow
						justify="space-between"
						align="center"
						style={{ width: '100%' }}
					>
						<FlexRow align="center">
							<Typography fontWeight={'600'}>
								Select Quotation
							</Typography>
						</FlexRow>

						<CloseButtonWrapper>
							<IoIosClose
								size={25}
								onClick={() => onClose?.({}, 'escapeKeyDown')}
							/>
						</CloseButtonWrapper>
					</FlexRow>
					<Gutter spacing={1} />

					<FlexRow
						align={'center'}
						justify="space-between"
						style={{ width: '100%' }}
					>
						<FlexRow align={'center'}>
							<SearchBar
								onSearch={text => setSearchText(text)}
								onFocus={() => setIsActive(true)}
								onBlur={text => setIsActive(text !== '')}
								sx={{ width: isActive ? '45ch' : '35ch' }}
							/>
							<Gutter gap={2} />
							{!isActive && (
								<FilterButton
									filterObj={filterObj}
									dataSource={QUOTATION_FILTER_OBJ}
									onSelect={filterObj =>
										setFilterObj(filterObj)
									}
								/>
							)}
							{isActive && <div />}
						</FlexRow>
					</FlexRow>

					<Gutter spacing={1} />

					<Wrapper
						height={
							height * 0.8 -
							LAYOUT_PADDING * 2 -
							CONTAINER_PADDING * 2 -
							130
						}
					>
						<StyledPaper ref={ref}>
							<TableContainer
								ref={scrollRef as any}
								style={{
									minWidth: 700,
									width: '100%',
									maxHeight: tHeight
								}}
							>
								<StyledTable
									stickyHeader
									aria-label="sticky table"
								>
									<QuotationTableHeader />
									<TableBody>
										{dataSource.map(row => (
											<RenderRow row={row} key={row.id} />
										))}
									</TableBody>
								</StyledTable>
							</TableContainer>
						</StyledPaper>
					</Wrapper>
				</Container>
			</StyledModal>
		</>
	)
}
