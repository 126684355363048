import React, { useCallback, useState } from 'react'
import { FlexRow } from 'components/atoms/Flex'
import { Abbr, Avatar, Capsule, FormatAmount, Gutter } from 'components'
import { convertNumber, getNameChar, pascalCase } from 'helpers'
import {
	TableCell,
	tableCellClasses,
	TableRow,
	Typography
} from '@mui/material'
import { AiOutlineEye } from 'react-icons/ai'
import analytics from 'utils/segment'
import { SEGMENT_EVENTS } from 'data'
import { styled } from '@mui/system'
import type { IContactProps } from 'typings'
import { useNavigate } from 'react-router-dom'
import { FiUser } from 'react-icons/fi'
import { KababMenu } from 'components/atoms/KababMenu'
import { BsCashStack } from 'react-icons/bs'
import { CreateContactModal } from './AddContact/CreateContactModal'

const CircleWrapper = styled(FlexRow)`
	border-radius: 50%;
	background-color: ${({ theme }) => theme.palette.colors.green['50']};
	padding: 10px;
`

const StyledTableCell = styled(TableCell)(({ theme }) => ({
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
	padding: 10,
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: theme.palette.colors.gray['50'],
		fontSize: 17,
		color:
			theme.palette.mode === 'dark'
				? theme.palette.colors['gray']['400']
				: theme.palette.colors['gray']['500']
	},
	[`&.${tableCellClasses.body}`]: {
		fontSize: 14
	}
}))

const WrapperCapsule = styled(Capsule)`
	width: auto;
	padding: 7px;
	background-color: ${({ theme }) => theme.palette.background.default};
	border: 1px solid ${({ theme }) => theme.palette.colors.gray['300']};
	border-radius: 9px;
`

const StyledTableRow = styled(TableRow)(() => ({
	// hide last border
	'&:last-child td, &:last-child th': {
		border: 0
	}
}))

const MUIRow = styled(StyledTableRow, {
	shouldForwardProp: props => props !== 'isEditable' && props !== 'cursor'
})<{ isEditable: boolean; cursor?: 'pointer' | 'auto' }>`
	:hover {
		cursor: ${({ cursor }) => cursor || 'auto'};
	}
`

type Props<I> = {
	row: I
	isEditable?: boolean
	onRowClick?: (row: I) => void
	onRecordPaymentClick?: (row: I) => void
	openContactModal?: () => void
	onSuccess?: () => void
}

export const ContactRow: React.ComponentType<Props<IContactProps>> = ({
	row,
	isEditable,
	onRowClick,
	onRecordPaymentClick,
	openContactModal,
	onSuccess
}) => {
	const [open, setOpen] = useState(false)
	const [user, setUser] = useState<IContactProps>()
	const navigate = useNavigate()
	const balance =
		row?.balances?.filter(f => f.accountName === 'accounts_receivables')[0]
			?.balance || 0

	const RenderType = useCallback(({ title }: { title: string }) => {
		return (
			<Capsule
				isSelected
				name={pascalCase(title)}
				value={title}
				color={title === 'customer' ? 'yellow' : 'blueLight'}
				disabled
			/>
		)
	}, [])

	// const getAContactDetails = useCallback(async () => {
	// 	try {
	// 		const res = await getAContact(row?.id || '0')
	// 		setUser({
	// 			...res
	// 		})
	// 	} catch (e: any) {
	// 		setAppError(e)
	// 	}
	// }, [setAppError, row?.id])

	const openLedger = useCallback(
		(id: string, user: IContactProps) => {
			navigate(`/contacts/ledger/${id}`, {
				state: { currentUser: user }
			})
		},
		[navigate]
	)

	const openViewDetails = async (id: string) => {
		// await getAContactDetails()
		setOpen(true)
	}

	return (
		<>
			<MUIRow
				isEditable={!isEditable}
				onClick={() => onRowClick?.(row)}
				cursor={onRowClick ? 'pointer' : 'auto'}
			>
				<StyledTableCell component="th" scope="row">
					<FlexRow align="center">
						{row?.profilePic ? (
							<Avatar
								borderWidth={1}
								title={getNameChar(row.name || '')}
								src={row?.profilePic}
							/>
						) : (
							<CircleWrapper>
								<FiUser color={'green'} size="23px" />
							</CircleWrapper>
						)}
						<Gutter gap={0.5} />
						<Typography>
							<Abbr length={25} title={row.name} />
						</Typography>
					</FlexRow>
				</StyledTableCell>
				<StyledTableCell>
					+{row.phone.countryCode} {row.phone.number}
				</StyledTableCell>
				<StyledTableCell align="left">
					<FlexRow>
						{row.businessRelationship.map(type => (
							<RenderType key={type} title={type} />
						))}
					</FlexRow>
				</StyledTableCell>
				{isEditable && (
					<StyledTableCell align="left">
						<FormatAmount
							limit={10}
							amount={convertNumber(parseFloat(`${balance}`))}
							prefixCurrency={row.balance.currency}
						/>
					</StyledTableCell>
				)}
				{isEditable && (
					<StyledTableCell align="left">
						<FlexRow align={'center'} justify={'center'}>
							<WrapperCapsule
								name="View Details"
								value="View Details"
								color="gray"
								isSelected
								renderLeft={
									<>
										<AiOutlineEye />
										<Gutter gap={0.3} />
									</>
								}
								onItemClick={() => {
									openViewDetails(row?.id || '')
									analytics.track(
										SEGMENT_EVENTS.CONTACT_VIEW_DETAILS
									)
								}}
							/>
							<Gutter gap={0.5} />
							<WrapperCapsule
								name="Ledger"
								value="Ledger"
								color="gray"
								isSelected
								renderLeft={
									<>
										<AiOutlineEye />
										<Gutter gap={0.3} />
									</>
								}
								onItemClick={() => {
									openLedger(row?.id || '', row)
									analytics.track(
										SEGMENT_EVENTS.CONTACT_VIEW_DETAILS
									)
								}}
							/>
							<Gutter gap={0.5} />
							<KababMenu
								dataSource={[
									{
										id: 0,
										icon: BsCashStack,
										name: 'Record Payment',
										value: row.id || '',
										iconColor: 'gray',
										onClick: () => {
											onRecordPaymentClick?.(row)
											analytics.track(
												SEGMENT_EVENTS.LEDGER_MANUAL_PAYMENT_OPTION
											)
										}
									}
								]}
							/>
						</FlexRow>
					</StyledTableCell>
				)}
			</MUIRow>
			<CreateContactModal
				open={open}
				user={row}
				onClose={() => {
					setOpen(false)
				}}
				onSuccess={response => onSuccess?.()}
			/>
		</>
	)
}
