import React, { useState } from 'react'
import { Button, styled, Typography, useTheme } from '@mui/material'
import { Abbr } from 'components/atoms'
import { FlexCol, FlexRow } from 'components/atoms/Flex'
import { PaymentMethodOptions, SEGMENT_EVENTS } from 'data'
import type { QuotationType } from 'typings'
import analytics from 'utils/segment'
import { PaymentsModal } from 'components/app/Payments/PaymentsModal'
import { getTermsText } from 'helpers'

const WrapperButton = styled(Button)(({ theme }) => ({
	backgroundColor: theme.palette.colors.green['500'],
	color: theme.palette.colors.white['900'],
	'&:hover': {
		background: theme.palette.colors.green['700']
	}
}))

const Text = styled(Typography, {
	shouldForwardProp: props => props !== 'mode'
})<{ mode?: 'styledText' }>`
	font-weight: 600;
	color: ${({ theme: { palette }, mode }) =>
		mode ? palette.colors.green['900'] : palette.colors.gray['900']};
`

type Props = {
	onSuccess(
		response: Pick<QuotationType, 'paymentMethod' | 'paymentTerms'>
	): void
} & Pick<QuotationType, 'paymentMethod' | 'paymentTerms'>

export const PaymentStatus: React.ComponentType<Props> = ({ ...props }) => {
	const [isPaymentModalVisible, setIsPaymentModalVisible] = useState(false)
	const theme = useTheme()
	const { colors } = theme.palette

	const {
		paymentTerms: { dueDate, custom },
		paymentMethod
	} = props
	const selectedMethod = PaymentMethodOptions.find(
		d => props.paymentMethod === d.value
	)?.label
	const isTermVisible = !dueDate && custom.length === 0
	const isVisible =
		(!paymentMethod || paymentMethod === 'unpaid') && isTermVisible

	const handleOpenModal = () => {
		analytics.track(SEGMENT_EVENTS.ADD_PAYMENT_INFO, {
			screen: 'new_invoice'
		})
		setIsPaymentModalVisible(true)
	}

	return (
		<FlexRow style={{ width: '100%' }} justify={'space-between'}>
			<FlexCol style={{ width: '70%' }}>
				<FlexCol>
					{selectedMethod && (
						<FlexCol>
							<Text variant="caption">
								Method:{' '}
								<Text variant="caption" mode={'styledText'}>
									{selectedMethod}
								</Text>
							</Text>
						</FlexCol>
					)}
					{!isTermVisible && (
						<Typography
							variant="caption"
							color={colors.gray['900']}
							fontWeight={'600'}
						>
							Terms:{' '}
							<Typography
								variant="caption"
								color={colors.green['900']}
								fontWeight={'600'}
							>
								<>
									{getTermsText(dueDate)}
									{custom.length > 0 && (
										<Abbr length={50} title={custom[0]} />
									)}
								</>
							</Typography>
						</Typography>
					)}
				</FlexCol>
				{isVisible && (
					<Typography variant="caption" color={colors.gray['500']}>
						Include the payment method and terms here
					</Typography>
				)}
			</FlexCol>
			<WrapperButton onClick={handleOpenModal}>
				{!isVisible ? 'Edit' : 'Add'}
			</WrapperButton>
			<PaymentsModal
				open={isPaymentModalVisible}
				onClose={() => setIsPaymentModalVisible(false)}
				{...props}
			/>
		</FlexRow>
	)
}
