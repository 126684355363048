import type { ColorsType, LedgerPayload } from 'typings'
import type { FilterButtonDataSourceProps } from 'screen/Invoices/Components/InvoiceFilter'
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined'
import CreditCardIcon from '@mui/icons-material/CreditCard'
import LanguageIcon from '@mui/icons-material/Language'
import ConfirmationNumberOutlinedIcon from '@mui/icons-material/ConfirmationNumberOutlined'

export const LEDGER_DUMMY_DATA: LedgerPayload = {
	__type: 'TransactionLedgerEntry',
	txDirection: 'credit',
	text: '',
	amount: 0,
	label: '',
	message: 'Cash',
	paymentMethod: 'cash'
}

export const LEDGER_STATUS_TITLE: { [key: string]: string } = {
	paid: 'Paid',
	partial: 'Partially Paid',
	pending: 'Pending'
}

export const LEDGER_CAPSULE_COLOR: { [key: string]: ColorsType } = {
	paid: 'green',
	partial: 'yellow',
	pending: 'blue'
}

export const LEDGER_FILTER_OPTIONS: FilterButtonDataSourceProps[] = [
	{ id: 1, label: 'Pending Invoices', value: { paymentStatus: ['paid'] } },
	{
		id: 2,
		label: 'Partially Paid Invoices',
		value: { paymentStatus: ['partial'] }
	},
	{ id: 3, label: 'Paid Invoices', value: { paymentStatus: ['pending'] } },
	{ id: 4, label: 'Void Invoices', value: { status: ['void'] } }
]

export const PAYMENT_METHOD_ICONS: { [key: string]: any } = {
	cash: PaymentsOutlinedIcon,
	card: CreditCardIcon,
	cheque: ConfirmationNumberOutlinedIcon,
	online: LanguageIcon
}

export const LEDGER_INVOICE_TYPE: { [key: string]: 'red' | 'green' } = {
	invoice_issued: 'green',
	invoice_voided: 'red',
	invoice_voided_and_inventory_returned: 'red',
	invoice_voided_and_inventory_not_returned: 'red',
	payment_made_against_invoice: 'red',
	refund_processed: 'green',
	manual_payment_made_by_customer: 'red', //1 Payment recorded RED
	manual_payment_made_to_customer: 'green', //2 Payment refunded GREEn
	opening_balance: 'green'
}
