import React from 'react'
import { styled } from '@mui/system'
import {
	Button,
	ClickAwayListener,
	Fade,
	Popper,
	Typography
} from '@mui/material'
import { Gutter } from 'components'
import {
	DeleteOutline,
	DoDisturb,
	DownloadOutlined,
	EditOutlined,
	MoreVert,
	Replay
} from '@mui/icons-material'
import PopupState, { bindToggle, bindPopper } from 'material-ui-popup-state'
import { FlexCol, FlexRow } from 'components/atoms/Flex'
import type { InvoiceType } from 'typings'
import { LAYOUT_BREAKPOINT_WIDTH } from 'data'

const TopButton = styled(Button)`
	background-color: ${({ theme }) => theme.palette.background.default};
	border: 1px solid ${({ theme }) => theme.palette.colors.gray[300]};
	align-items: center;
	min-width: 0;
	min-height: 32px;
	border-radius: 5px;
	padding: 0 9px;
	color: ${({ theme }) => theme.palette.colors.black['300']};
	box-shadow: 0 1px 2px 0 rgba(21, 29, 45, 0.08);
`

const MobileWrapper = styled(FlexRow)`
	@media (max-width: ${LAYOUT_BREAKPOINT_WIDTH}px) {
		display: none;
	}
`

const MoreIcon = styled(MoreVert)`
	font-size: 20px;
`

const ButtonText = styled(Typography)`
	color: ${({ theme }) => theme.palette.colors.gray['700']};
	font-weight: 500;
`

const PopupWrapper = styled(FlexCol)`
	background-color: ${({ theme }) => theme.palette.background.default};
	border: 1px solid ${({ theme }) => theme.palette.background.paper};
	border-radius: 8px;
	margin-top: 5px;
	min-width: 184px;
	box-shadow: 0 12px 16px -4px rgba(16, 24, 40, 0.08),
		0 4px 6px -2px rgba(16, 24, 40, 0.03);
`

const PopupButton = styled(Button, {
	shouldForwardProp: props => props !== 'danger'
})<{ danger?: boolean }>`
	justify-content: flex-start;
	width: 100%;
	padding: 10px 16px;
	color: ${({ theme, danger }) =>
		danger
			? theme.palette.colors.red['600']
			: theme.palette.colors.gray['600']};
`

const PopupButtonText = styled(Typography)`
	font-size: 12px;
`

const DownloadIcon = styled(DownloadOutlined)`
	font-size: 16px;
`

const StopIcon = styled(DoDisturb)`
	font-size: 16px;
`

const EditIcon = styled(EditOutlined)`
	font-size: 16px;
`

const DeleteIcon = styled(DeleteOutline)`
	font-size: 16px;
`

const ReplayIcon = styled(Replay)`
	font-size: 16px;
`

type ActionButtonProps = {
	currentInvoice: InvoiceType
	editButtonClick?: () => void
	downloadButtonClick?: () => void
	deleteButtonClick?: () => void
	voidButtonClick?: () => void
	reissueButtonClick?: () => void
}

// ToDo: Rework this component
export const ActionButton: React.ComponentType<ActionButtonProps> = ({
	currentInvoice,
	editButtonClick,
	downloadButtonClick,
	deleteButtonClick,
	voidButtonClick,
	reissueButtonClick
}) => {
	return (
		<PopupState
			variant="popper"
			popupId="demo-popup-popper"
			disableAutoFocus={true}
		>
			{popupState => (
				<ClickAwayListener onClickAway={() => popupState.close()}>
					<div>
						<TopButton {...bindToggle(popupState)}>
							<MobileWrapper>
								<ButtonText variant={'subtitle2'}>
									Action
								</ButtonText>
								<Gutter gap={0.2} />
							</MobileWrapper>
							<MoreIcon />
						</TopButton>
						<Popper
							{...bindPopper(popupState)}
							transition
							placement="bottom-start"
						>
							{({ TransitionProps }) => (
								<Fade {...TransitionProps} timeout={350}>
									<PopupWrapper>
										{currentInvoice.status !== 'void' && (
											<PopupButton
												onClick={downloadButtonClick}
											>
												<FlexRow align="center">
													<DownloadIcon />
													<Gutter gap={0.2} />
													<PopupButtonText variant="caption">
														Download
													</PopupButtonText>
												</FlexRow>
											</PopupButton>
										)}
										{currentInvoice.status ===
											'publish' && (
											<PopupButton
												onClick={voidButtonClick}
											>
												<FlexRow align="center">
													<StopIcon />
													<Gutter gap={0.2} />
													<PopupButtonText variant="caption">
														Void
													</PopupButtonText>
												</FlexRow>
											</PopupButton>
										)}
										{currentInvoice.status === 'draft' && (
											<>
												<PopupButton
													onClick={editButtonClick}
												>
													<FlexRow align="center">
														<EditIcon />
														<Gutter gap={0.2} />
														<PopupButtonText variant="caption">
															Edit
														</PopupButtonText>
													</FlexRow>
												</PopupButton>

												<PopupButton
													danger={true}
													onClick={deleteButtonClick}
												>
													<FlexRow align="center">
														<DeleteIcon />
														<Gutter gap={0.2} />
														<PopupButtonText variant="caption">
															Delete
														</PopupButtonText>
													</FlexRow>
												</PopupButton>
											</>
										)}
										{currentInvoice.status === 'void' && (
											<PopupButton
												onClick={reissueButtonClick}
											>
												<FlexRow align="center">
													<ReplayIcon />
													<Gutter gap={0.2} />
													<PopupButtonText variant="caption">
														Reissue
													</PopupButtonText>
												</FlexRow>
											</PopupButton>
										)}
									</PopupWrapper>
								</Fade>
							)}
						</Popper>
					</div>
				</ClickAwayListener>
			)}
		</PopupState>
	)
}
