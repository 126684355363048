import type { TableComponents } from 'react-virtuoso'
import * as React from 'react'
import {
	Table,
	TableBody,
	TableContainer,
	TableHead,
	TableRow
} from '@mui/material'
import Paper from '@mui/material/Paper'
import { Gutter } from 'components'

export const LedgerTableComponent: TableComponents<any> = {
	Scroller: React.forwardRef<HTMLDivElement>((props, ref) => (
		<TableContainer component={Paper} {...props} ref={ref} />
	)),
	Table: props => (
		<Table
			{...props}
			sx={{
				borderCollapse: 'separate',
				tableLayout: 'fixed'
			}}
		/>
	),
	TableHead,
	TableRow: ({ item: _item, ...props }) => (
		<TableRow
			{...props}
			style={{
				backgroundColor:
					_item.metaData?.invoice?.status === 'void'
						? '#FEF3F2'
						: props['data-index'] % 2 === 0
						? 'white'
						: '#F9FAFB',
				paddingBottom: 30
			}}
		/>
	),
	TableBody: React.forwardRef<HTMLTableSectionElement>((props, ref) => (
		<TableBody {...props} ref={ref} />
	)),
	TableFoot: () => <Gutter />
}

if (LedgerTableComponent.Scroller)
	LedgerTableComponent.Scroller.displayName = 'Scroller'
if (LedgerTableComponent.Table) LedgerTableComponent.Table.displayName = 'Table'
if (LedgerTableComponent.TableRow)
	LedgerTableComponent.TableRow.displayName = 'TableRow'
if (LedgerTableComponent.TableBody)
	LedgerTableComponent.TableBody.displayName = 'TableBody'
