import React, { useCallback, useState } from 'react'
import {
	ClickAwayListener,
	Fade,
	Popper,
	styled,
	Typography,
	useMediaQuery,
	useTheme
} from '@mui/material'
import { LAYOUT_BREAKPOINT_WIDTH, SEGMENT_EVENTS } from 'data'
import { FlexCol, FlexRow } from 'components/atoms/Flex'
import { motion } from 'framer-motion'
import {
	Button,
	Capsule,
	Capsule as SCapsule,
	Gutter,
	SearchBar
} from 'components'
import analytics from 'utils/segment'
import {
	KeyboardArrowDownOutlined,
	KeyboardArrowUpOutlined,
	Search
} from '@mui/icons-material'
import type { KababMenuItemProps } from 'components/atoms/KababMenu'
import { BulkPaymentModal } from 'screen/Home/BulkPaymentModal/BulkPaymentModal'
import { RecordContactPaymentModal } from 'components/app/Payments/RecordContactPaymentModal'
import type { IContactProps } from 'typings'
import PopupState, { bindPopper, bindToggle } from 'material-ui-popup-state'

const NumberCapsule = styled(Capsule)`
	background-color: ${({ theme }) => theme.palette.colors.gray['100']};
	color: ${({ theme }) => theme.palette.colors.black['700']};
	padding: 2px 10px;
	width: 24px;
	height: 24px;
	align-content: center;
	font-size: 14px;
	font-weight: 500;
`

const StyledButton = styled(Button)`
	background-color: ${({ theme }) => theme.palette.background.default};
	border: 1px solid ${({ theme }) => theme.palette.colors.gray[300]};
	align-items: center;
	min-width: 0;
	min-height: 32px;
	border-radius: 5px;
	padding: 0px 9px;
`

const Wrapper = styled(FlexRow)`
	width: 100%;
	flex-wrap: nowrap;
	align-items: center;
	justify-content: space-between;
	padding: 11px 16px 10px 16px;
	border-bottom: 1px solid ${({ theme }) => theme.palette.colors.gray['200']};
`

const ItemWrapper = styled(FlexCol)`
	background-color: ${({ theme }) => theme.palette.background.default};
	border: 1px solid ${({ theme }) => theme.palette.background.paper};
	border-radius: 8px;
	margin-top: 5px;
	box-shadow: 0 12px 16px -4px rgba(16, 24, 40, 0.08),
		0px 4px 6px -2px rgba(16, 24, 40, 0.03);
`

const WrapperCapsule = styled(SCapsule)`
	width: min-content;
	flex-wrap: nowrap;
	padding: 7px;
	background-color: ${({ theme }) => theme.palette.background.default};
	border: 1px solid ${({ theme }) => theme.palette.colors.gray['300']};
	border-radius: 9px;
`

const StyledSearchBar = styled(SearchBar)`
	width: 100%;
	.MuiOutlinedInput-root {
		border-radius: 5px;
		padding-right: 6px;
	}
`

const ArrowDownIcon = styled(KeyboardArrowDownOutlined)`
	font-size: 22px;
`

const ArrowUpIcon = styled(KeyboardArrowUpOutlined)`
	font-size: 22px;
`

const SearchIcon = styled(Search)`
	font-size: 20px;
	color: ${({ theme }) => theme.palette.colors.gray['700']};
`

const Col = styled(FlexCol)<{ isSelected?: boolean; disabled?: boolean }>`
	min-height: 50px;
	padding: 0 15px;
	width: 100%;
	background-color: ${({ theme, isSelected, disabled }) =>
		disabled
			? theme.palette.background.paper
			: isSelected
			? theme.palette.colors.blue['100']
			: theme.palette.background.default};

	:hover {
		cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
		background-color: ${({ theme }) => theme.palette.colors.blue['50']};
	}
`

const HeaderText = styled(Typography)`
	margin-bottom: 4px;

	@media (max-width: ${LAYOUT_BREAKPOINT_WIDTH}px) {
		margin-bottom: 0;
	}
`

type HeaderProps = {
	numberOfTransactions?: number
	onSearch: (searchText: string) => void
	user?: IContactProps
	onSuccess: () => void
}

export const Header: React.FC<HeaderProps> = ({
	numberOfTransactions,
	onSearch,
	user,
	onSuccess
}) => {
	const [open, setOpen] = useState(false)
	const [paymentMode, setPaymentMode] = useState<'single' | 'bulk'>('single')
	const [isActive, setIsActive] = useState<boolean>(false)
	const isMobile = useMediaQuery(`(max-width:${LAYOUT_BREAKPOINT_WIDTH}px)`)
	const theme = useTheme()
	const {
		palette: { colors }
	} = theme

	const MotionAnimation = {
		opacity: 1,
		transition: {
			duration: 0.5
		}
	}

	const SearchAnimation = {
		width: isMobile ? '100%' : '30ch',
		transition: {
			duration: 0.2,
			type: 'spring',
			damping: 14
		}
	}

	const Item = useCallback(
		({
			name,
			value,
			onSelect,
			onClick,
			disabled,
			color,
			icon,
			onClose,
			iconColor,
			...props
		}: Omit<KababMenuItemProps, 'id'> & { onClose: () => void }) => {
			const Icon = icon

			return (
				<Col
					justify={'center'}
					style={{ minWidth: 220 }}
					disabled={disabled}
					onClick={() => {
						if (!disabled) {
							onSelect?.(name, value)
							onClose()
							onClick?.()
						}
					}}
					{...props}
				>
					<FlexRow align={'center'}>
						{icon && (
							<FlexRow>
								<Icon
									size={20}
									color={
										iconColor
											? iconColor
											: colors['black']['700']
									}
								/>
								<Gutter gap={0.5} />
							</FlexRow>
						)}
						<Typography variant={'subtitle2'} color={color}>
							{name}
						</Typography>
					</FlexRow>
				</Col>
			)
		},
		[colors]
	)

	const searchButtonClicked = () => {
		setIsActive(true)
	}

	return (
		<>
			<Wrapper>
				<motion.div
					initial={{ opacity: 0 }}
					animate={MotionAnimation}
					exit={{ opacity: 0 }}
					hidden={isMobile && isActive}
				>
					<FlexRow align="center">
						<HeaderText
							variant={isMobile ? 'body2' : 'h6'}
							fontWeight={'medium'}
						>
							Ledger
						</HeaderText>
						{numberOfTransactions && (
							<NumberCapsule
								name={numberOfTransactions.toString()}
								value="numberOfTransactions"
							/>
						)}
					</FlexRow>
				</motion.div>

				<FlexRow
					align={'center'}
					sx={{ width: isMobile && isActive ? '100%' : null }}
				>
					{isMobile && !isActive ? (
						<StyledButton onClick={searchButtonClicked}>
							<SearchIcon />
						</StyledButton>
					) : (
						<motion.div animate={SearchAnimation}>
							<StyledSearchBar
								onSearch={onSearch}
								onFocus={() => setIsActive(true)}
								onBlur={text => {
									analytics.track(SEGMENT_EVENTS.QUO_SEARCH)
									setIsActive(text !== '')
								}}
								autoFocus={isMobile}
								placeholder="Search using invoice ID"
								inputProps={{
									style: {
										padding: '4.5px 0 4.5px 14px'
									}
								}}
							/>
						</motion.div>
					)}
					<PopupState
						variant="popper"
						popupId="demo-popup-popper"
						disableAutoFocus
					>
						{popupState => (
							<ClickAwayListener
								onClickAway={() => popupState.close()}
							>
								<div>
									<WrapperCapsule
										name="Record Payment"
										value="Record Payment"
										color="gray"
										renderRight={
											popupState.isOpen ? (
												<ArrowUpIcon />
											) : (
												<ArrowDownIcon />
											)
										}
										isSelected
										{...bindToggle(popupState)}
									/>
									<Popper
										{...bindPopper(popupState)}
										transition
										placement={'bottom-end'}
									>
										{({ TransitionProps }) => (
											<Fade
												{...TransitionProps}
												timeout={350}
											>
												<ItemWrapper>
													<Item
														key={'Record Payment'}
														onClose={
															popupState.close
														}
														name={
															'Record Single Payment'
														}
														value={'single payment'}
														onClick={() => {
															setOpen(true)
															setPaymentMode(
																'single'
															)
														}}
													/>
													<Item
														key={
															'Record Payment in Bulk'
														}
														onClose={
															popupState.close
														}
														name={
															'Record Payments in Bulk'
														}
														value={'do something'}
														onClick={() => {
															setOpen(true)
															setPaymentMode(
																'bulk'
															)
														}}
													/>
												</ItemWrapper>
											</Fade>
										)}
									</Popper>
								</div>
							</ClickAwayListener>
						)}
					</PopupState>
				</FlexRow>
			</Wrapper>
			{user && paymentMode === 'bulk' && (
				<BulkPaymentModal
					contact={user as IContactProps}
					open={open}
					onClose={() => {
						setOpen(false)
						setPaymentMode('single')
					}}
					onSuccess={() => {
						onSuccess()
					}}
				/>
			)}
			{user && paymentMode === 'single' && (
				<RecordContactPaymentModal
					open={open}
					contact={user as IContactProps}
					onClose={() => {
						setOpen(false)
						setPaymentMode('single')
					}}
					onSuccess={() => {
						onSuccess()
					}}
				/>
			)}
		</>
	)
}
