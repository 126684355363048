import { styled, Typography } from '@mui/material'
import { FlexCol } from 'components/atoms/Flex'
import { useFormikContext } from 'formik'
import React from 'react'
import type { QuotationType } from 'typings'
import { PaymentStatus } from 'components/app/Payments/PaymentStatus'

const PaymentContainer = styled(FlexCol)`
	background-color: ${({ theme }) => theme.palette.colors.gray['50']};
	padding: 15px;
	border-radius: 10px;
	height: min-content;
	max-height: min-content;
	width: 100%;
`

export const PaymentTerms: React.ComponentType<
	Pick<QuotationType, 'paymentTerms' | 'paymentMethod'>
> = ({ ...props }) => {
	const { setFieldValue } = useFormikContext()

	const onSuccess = (
		response: Pick<QuotationType, 'paymentTerms' | 'paymentMethod'>
	) => {
		setFieldValue('paymentTerms', response.paymentTerms)
		setFieldValue('paymentMethod', response.paymentMethod)
	}

	return (
		<PaymentContainer>
			<Typography variant="body2">Payment Information</Typography>
			<PaymentStatus onSuccess={onSuccess} {...props} />
		</PaymentContainer>
	)
}
