import React, { useState } from 'react'
import { Popover, Typography, styled, useMediaQuery } from '@mui/material'
import {
	LAYOUT_BREAKPOINT_WIDTH,
	PRIMARY_BUTTON_BREAKPOINT_WIDTH,
	SEGMENT_EVENTS
} from 'data'
import { FlexRow } from 'components/atoms/Flex'
import { motion } from 'framer-motion'
import { Button, Capsule, Gutter, SearchBar } from 'components'
import analytics from 'utils/segment'
import { useNavigate } from 'react-router-dom'
import { MoreVert, RestoreOutlined, Search } from '@mui/icons-material'
import { PopoverElement } from 'screen'

const NumberCapsule = styled(Capsule)`
	background-color: ${({ theme }) => theme.palette.colors.gray['100']};
	color: ${({ theme }) => theme.palette.colors.black['700']};
	padding: 2px 10px;
	align-content: center;
	font-size: 14px;
	font-weight: 500;
`

const PrimaryButtonWrapper = styled(FlexRow)`
	@media (max-width: ${PRIMARY_BUTTON_BREAKPOINT_WIDTH}px) {
		display: none;
	}
`

const SearchButton = styled(Button)`
	background-color: ${({ theme }) => theme.palette.background.default};
	border: 1px solid ${({ theme }) => theme.palette.colors.gray[300]};
	color: ${({ theme }) => theme.palette.colors.gray[700]};
	min-width: 0;
	min-height: 32px;
	border-radius: 5px;
	padding: 0px 9px;

	:hover {
		background-color: ${({ theme }) => theme.palette.colors.gray[100]};
	}
`

const RestoreButton = styled(Button)`
	background-color: ${({ theme }) => theme.palette.background.default};
	border: 1px solid ${({ theme }) => theme.palette.colors.gray[300]};
	color: ${({ theme }) => theme.palette.colors.gray[900]};
	min-width: 0;
	min-height: 32px;
	border-radius: 5px;
	padding: 0px 9px;

	:hover {
		background-color: ${({ theme }) => theme.palette.colors.gray[100]};
	}
`

const Wrapper = styled(FlexRow)`
	width: 100%;
	flex-wrap: nowrap;
	align-items: center;
	justify-content: space-between;
	padding: 11px 16px 10px 16px;
	border-bottom: 1px solid ${({ theme }) => theme.palette.colors.gray['200']};
`

const StyledSearchBar = styled(SearchBar)`
	width: 100%;
	.MuiOutlinedInput-root {
		border-radius: 5px;
		padding-right: 6px;
	}
`

const SearchIcon = styled(Search)`
	font-size: 20px;
	color: ${({ theme }) => theme.palette.colors.gray['700']};
`

const HeaderText = styled(Typography)`
	margin-bottom: 4px;

	@media (max-width: ${LAYOUT_BREAKPOINT_WIDTH}px) {
		margin-bottom: 0;
	}
`

type HeaderProps = {
	numOfOrders: number
	onSearch: (searchText: string) => void
	onSelectClick: () => void
	isSelectVisible: boolean
	onRestore?: () => void
}

export const Header: React.FC<HeaderProps> = ({
	numOfOrders,
	onSearch,
	onSelectClick,
	isSelectVisible,
	onRestore
}) => {
	const [isActive, setIsActive] = useState<boolean>(false)
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

	const isMobile = useMediaQuery(`(max-width:${LAYOUT_BREAKPOINT_WIDTH}px)`)

	const navigate = useNavigate()

	const open = Boolean(anchorEl)

	const handleClick = (event: any) => {
		analytics.track(SEGMENT_EVENTS.ORDER_CENTER_KEBAB_MENU)
		setAnchorEl(event.currentTarget)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	const MotionAnimation = {
		opacity: 1,
		transition: {
			duration: 0.5
		}
	}

	const SearchAnimation = {
		width: isMobile ? '100%' : '30ch',
		transition: {
			duration: 0.2,
			type: 'spring',
			damping: 14
		}
	}

	const searchButtonClicked = () => {
		setIsActive(true)
	}

	return (
		<Wrapper>
			<motion.div
				initial={{ opacity: 0 }}
				animate={MotionAnimation}
				exit={{ opacity: 0 }}
				hidden={isMobile && isActive}
			>
				<FlexRow align="center">
					<HeaderText
						variant={isMobile ? 'body2' : 'h6'}
						fontWeight={'medium'}
					>
						Orders and Inquiries
					</HeaderText>
					<NumberCapsule
						name={numOfOrders.toString()}
						value="numOfOrders"
					/>
				</FlexRow>
			</motion.div>

			<FlexRow
				align={'center'}
				sx={{ width: isMobile && isActive ? '100%' : null }}
			>
				{isMobile && !isActive ? (
					<SearchButton onClick={searchButtonClicked}>
						<SearchIcon />
					</SearchButton>
				) : (
					<motion.div animate={SearchAnimation}>
						<StyledSearchBar
							onSearch={onSearch}
							onFocus={() => setIsActive(true)}
							onBlur={text => {
								analytics.track(SEGMENT_EVENTS.QUO_SEARCH)
								setIsActive(text !== '')
							}}
							autoFocus={isMobile}
							placeholder="Search using Contact Name"
							inputProps={{
								style: {
									padding: '4.5px 0 4.5px 14px'
								}
							}}
						/>
					</motion.div>
				)}

				<motion.div
					initial={{ opacity: 0 }}
					animate={MotionAnimation}
					exit={{ opacity: 0 }}
					hidden={isMobile && isActive}
				>
					<FlexRow align={'center'}>
						{onRestore && (
							<>
								<Gutter gap={isMobile ? 0.5 : 1} />
								<RestoreButton onClick={onRestore}>
									<RestoreOutlined fontSize="small" />
									<PrimaryButtonWrapper>
										<Gutter gap={0.5} />
										<Typography
											variant="caption"
											fontWeight={'bold'}
										>
											Restore
										</Typography>
									</PrimaryButtonWrapper>
								</RestoreButton>
							</>
						)}

						<Gutter gap={isMobile ? 0.2 : 0.6} />

						<MoreVert fontSize={'small'} onClick={handleClick} />
						<Popover
							elevation={2}
							open={open}
							anchorEl={anchorEl}
							onClose={handleClose}
							anchorOrigin={{
								vertical: 'bottom',
								horizontal: 'right'
							}}
							transformOrigin={{
								vertical: 'top',
								horizontal: 'right'
							}}
						>
							<PopoverElement
								variant="body2"
								onClick={() => {
									onSelectClick()
									setAnchorEl(null)
								}}
							>
								{isSelectVisible ? 'Unselect' : 'Select'}
							</PopoverElement>
							<PopoverElement
								variant="body2"
								onClick={() => navigate('/orders')}
							>
								View Order Center
							</PopoverElement>
						</Popover>
					</FlexRow>
				</motion.div>
			</FlexRow>
		</Wrapper>
	)
}
