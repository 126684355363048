import React, { useCallback, useEffect, useState } from 'react'
import { HomeHeader } from 'screen/Home/HomeHeader'
import { styled } from '@mui/system'
import { FlexCol, FlexRow } from 'components/atoms/Flex'
import { Button, type FilterObjProps, Gutter } from 'components'
import { Paper, Typography, useTheme } from '@mui/material'
import { currencyInput } from 'helpers'
import type {
	ColorsCodeType,
	DataResponseType,
	IContactProps,
	ResponsePageInfo
} from 'typings'
import { Add } from '@mui/icons-material'
import { LAYOUT_BREAKPOINT_WIDTH } from 'data'
import type { InventorySortType } from 'screen/Inventory'
import { useAppErrors, useDimension } from 'hooks'
import { getContactBalance, getContactList } from 'api/contacts'
import { useSettings } from 'context/settings'
import { HTableComponents } from 'screen/Home/TableComponents/HTableComponents'
import { TableVirtuoso } from 'react-virtuoso'
import { HTableHeader } from 'screen/Home/TableComponents/HTableHeader'
import { HomeItem } from 'screen/Home/TableComponents/HomeItem'
import { BulkPaymentModal } from 'screen/Home/BulkPaymentModal/BulkPaymentModal'
import { RecordContactPaymentModal } from 'components/app/Payments/RecordContactPaymentModal'
import { useNavigate } from 'react-router-dom'
import { OFHeader } from 'screen/OrderFinancing/OFHeader'
import { useSelector } from 'react-redux'
import type { RootState } from 'store/index-reducer'
import { WHITELIST } from 'data/whitelist'

const GreenCircle = styled(FlexCol)`
	border-radius: 50%;
	width: 8px;
	height: 8px;
	background-color: ${({ theme }) => theme.palette.colors['green']['600']};
`

const RedCircle = styled(FlexCol)`
	border-radius: 50%;
	width: 8px;
	height: 8px;
	background-color: ${({ theme }) => theme.palette.colors['red']['600']};
`

const AmountLayout = styled(FlexRow)`
	display: flex;
	padding: 8px 16px;
	justify-content: space-between;
	align-items: center;
	border-radius: 8px;
	border: 1px solid ${({ theme }) => theme.palette.colors['gray']['300']};
	width: 100%;
`

const LabelText = styled(Typography)`
	font-weight: 400;
	color: ${({ theme }) => theme.palette.colors['gray']['700']};
`

const MobileWrapper = styled(FlexRow)`
	@media (max-width: ${LAYOUT_BREAKPOINT_WIDTH}px) {
		display: none;
	}
`

const Table = styled(TableVirtuoso)`
	width: 100%;
	background: ${({ theme }) => theme.palette.colors.white['500']};
`

const StyledPaper = styled(Paper)`
	position: relative;
	width: 100%;
	height: 100%;
	background: ${({ theme }) => theme.palette.colors.white['500']};
	z-index: 0;
`

const Container = styled(FlexRow)`
	width: 100%;
	padding: 8px 16px;
`

export const Home = () => {
	const { user } = useSelector((state: RootState) => state.user)
	const [open, setOpen] = useState(false)
	const [selectedContact, setSelectedContact] = useState<
		IContactProps | undefined
	>()
	const [summary, setSummary] = useState<{
		payable: number
		receivable: number
	}>({ payable: 0, receivable: 0 })
	const [singlePayment, setSinglePayment] = useState<
		IContactProps | undefined
	>(undefined)
	const [filterObj, setFilterObj] = useState<FilterObjProps>({})
	const [loading, setLoading] = useState<boolean>(true)
	const [dataSource, setDataSource] = useState<IContactProps[]>([])
	const [pageInfo, setPageInfo] = useState<ResponsePageInfo>({
		totalPages: 1,
		currentPage: 1,
		edgesPerPage: 20
	})
	const [searchKey, setSearchKey] = useState('')
	const [sortObj, setSortObj] = useState<InventorySortType>({
		sortOrder: 'asc',
		sortBy: 'name'
	})
	const { setAppError } = useAppErrors()
	const { height } = useDimension()
	const { business } = useSettings()
	const theme = useTheme()
	const navigate = useNavigate()
	const {
		palette: { colors }
	} = theme
	const isAllowedNumber = WHITELIST.includes(user?.phone.number || '')

	const fetchSummary = async () => {
		try {
			const res1 = await getContactBalance()
			if (res1.length > 0) {
				setSummary(res1[0])
			}
		} catch (e) {
			//
		}
	}

	const getInitialContactList = useCallback(async () => {
		try {
			setLoading(true)
			const res: DataResponseType<IContactProps> = await getContactList({
				business,
				page: 1,
				limit: pageInfo.edgesPerPage,
				searchKey,
				...sortObj,
				sortOrder: sortObj.sortOrder.toUpperCase() as 'ASC' | 'DESC',
				...filterObj
			})
			setDataSource(res.edges)
			setPageInfo(res.pageInfo)
		} catch (e: any) {
			setAppError(e)
		} finally {
			setLoading(false)
		}
	}, [
		business,
		filterObj,
		pageInfo.edgesPerPage,
		searchKey,
		setAppError,
		sortObj
	])

	const fetchContact = async () => {
		try {
			setLoading(true)
			if (
				!loading &&
				pageInfo?.totalPages &&
				pageInfo?.totalPages >= pageInfo.currentPage + 1
			) {
				const res: DataResponseType<IContactProps> =
					await getContactList({
						business,
						page: pageInfo.currentPage + 1,
						limit: pageInfo.edgesPerPage,
						searchKey,
						...sortObj,
						sortOrder: sortObj.sortOrder.toUpperCase() as
							| 'ASC'
							| 'DESC',
						...filterObj
					})

				setDataSource([...dataSource, ...res.edges])
				setPageInfo(res.pageInfo)
			}
		} catch (e: any) {
			setAppError(e)
		} finally {
			setLoading(false)
		}
	}

	const onSuccess = () => {
		getInitialContactList()
		fetchSummary()
	}

	useEffect(() => {
		getInitialContactList()
		fetchSummary()
	}, [getInitialContactList])

	return (
		<>
			<HomeHeader
				onSearch={text => setSearchKey(text)}
				onFilterSelect={obj => setFilterObj(obj)}
				filterObj={filterObj}
			/>
			<Gutter spacing={0.5} />
			<Container>
				<AmountLayout>
					<FlexRow>
						<FlexCol>
							<FlexRow align={'center'}>
								<GreenCircle />
								<Gutter gap={0.5} />
								<LabelText variant={'caption'}>
									Receivables
								</LabelText>
							</FlexRow>
							<Typography variant={'subtitle2'} fontWeight={600}>
								PKR {currencyInput(`${summary.receivable}`)}
							</Typography>
						</FlexCol>
						<Gutter gap={2} />
						<FlexCol>
							<FlexRow align={'center'}>
								<RedCircle />
								<Gutter gap={0.5} />
								<LabelText variant={'caption'}>
									Payables
								</LabelText>
							</FlexRow>
							<Typography variant={'subtitle2'} fontWeight={600}>
								PKR {currencyInput(`${summary.payable}`)}
							</Typography>
						</FlexCol>
					</FlexRow>
					<Button
						textColor={colors['gray']['900'] as ColorsCodeType}
						mode={'primary'}
						buttonColor={colors['yellow']['300'] as ColorsCodeType}
						sx={{
							minWidth: 0,
							minHeight: '32px',
							padding: '0px 10px',
							borderRadius: '5px'
						}}
						onClick={() => navigate('/invoice/create-invoice')}
					>
						<Add fontSize="small" />
						<MobileWrapper align="center">
							<Gutter gap={0.5} />
							Create Sale Invoice
						</MobileWrapper>
					</Button>
				</AmountLayout>
			</Container>
			{isAllowedNumber && <OFHeader />}
			<Container
				style={{
					height: isAllowedNumber ? height - 280 : height - 220
				}}
			>
				<StyledPaper>
					<Table
						data={dataSource}
						components={HTableComponents}
						fixedHeaderContent={HTableHeader}
						itemContent={(_index: number, row: any) => (
							<HomeItem
								row={row}
								setSelectedContact={contact => {
									setSelectedContact(contact)
									setOpen(true)
								}}
								onSinglePaymentClick={contact => {
									setSinglePayment(contact)
								}}
							/>
						)}
						endReached={() => fetchContact()}
					/>
				</StyledPaper>
			</Container>
			{selectedContact && (
				<BulkPaymentModal
					contact={selectedContact}
					open={open}
					onClose={() => {
						setOpen(false)
						setSelectedContact(undefined)
					}}
					onSuccess={onSuccess}
				/>
			)}
			{singlePayment && (
				<RecordContactPaymentModal
					open={!!singlePayment}
					contact={singlePayment}
					onClose={() => setSinglePayment(undefined)}
					onSuccess={onSuccess}
				/>
			)}
		</>
	)
}
