import type { DiscountType, ICart } from 'typings'
import { type FieldHelperProps, useFormikContext } from 'formik'
import {
	amountToLocal,
	convertAmountToPercentage,
	convertNumber,
	convertPercentageToAmount,
	currencyInput,
	getNameChar,
	POSITIVE_NUMBER
} from 'helpers'
import { calculateDiscount } from 'helpers/mixed'
import { DISCOUNT_OPTION, LAYOUT_BREAKPOINT_WIDTH } from 'data'
import {
	ClickAwayListener,
	Fade,
	InputAdornment,
	Popper,
	TableCell,
	TableRow,
	Typography,
	useTheme
} from '@mui/material'
import { FlexCol, FlexRow } from 'components/atoms/Flex'
import { Abbr, Avatar, FieldNumberInput, Gutter } from 'components'
import PopupState, { bindPopper, bindToggle } from 'material-ui-popup-state'
import {
	MdOutlineKeyboardArrowDown,
	MdOutlineKeyboardArrowUp
} from 'react-icons/md'
import React from 'react'
import type { CurrentInvoiceProps } from 'screen/Invoices/NewCreate/Invoice'
import { styled } from '@mui/system'
import { RiDeleteBinLine } from 'react-icons/ri'

const StyledTableRow = styled(TableRow)`
	background-color: ${({ theme }) => theme.palette.background.default};
`

const Price = styled(Typography)`
	display: none;
	@media (max-width: ${LAYOUT_BREAKPOINT_WIDTH}px) {
		display: block;
	}
`

const DeleteButton = styled(RiDeleteBinLine)`
	color: ${({ theme }) => theme.palette.colors.rose['500']};

	:hover {
		cursor: pointer;
	}
`

const Box = styled(FlexRow)`
	padding-left: 10px;
	border-left: 1px solid ${({ theme }) => theme.palette.colors.gray['300']};
	flex-wrap: nowrap;
	align-items: center;

	:hover {
		cursor: pointer;
	}
`

const PriceCell = styled(TableCell)`
	@media (max-width: ${LAYOUT_BREAKPOINT_WIDTH}px) {
		display: none;
	}
`

const Opt = styled(FlexRow)`
	padding: 8px 14px;
	width: 100%;

	:hover {
		cursor: pointer;
		background-color: ${({ theme }) => theme.palette.colors['gray']['50']};
	}
`

const Wrapper = styled(FlexCol)`
	background-color: ${({ theme }) => theme.palette.background.default};
	border: 1px solid ${({ theme }) => theme.palette.background.paper};
	border-radius: 8px;
	margin-top: 5px;
	box-shadow: 0 12px 16px -4px rgba(16, 24, 40, 0.08),
		0 4px 6px -2px rgba(16, 24, 40, 0.03);
`

type ItemProps = {
	id: string
	name: string
	code: string
	unit: string
	quantity: number
	perItemCostPrice: number
	perItemSellPrice: number
	stockId: string
}
export const OrdrBookerItems = ({
	row,
	index,
	value,
	helpers,
	setSelectedImageUrl,
	setModalOpen,
	displayItemLevelDiscountOB
}: {
	row: ICart
	index: number
	value: ItemProps[]
	helpers: FieldHelperProps<any>
	setSelectedImageUrl: any
	setModalOpen: any
	displayItemLevelDiscountOB: boolean
}) => {
	const isOpen = true
	const theme = useTheme()
	const {
		palette: { colors }
	} = theme
	const { values, setFieldValue } = useFormikContext<CurrentInvoiceProps>()

	const toggleImageSize = (icon: string) => {
		if (icon) {
			setSelectedImageUrl(icon)
			setModalOpen(true)
		}
	}

	const onValueChange = (type: string, amount: string) => {
		setFieldValue(
			`${values.items ? `items[${index}].discount` : 'random'}`,
			{
				...values.discount,
				type: type,
				amount: amount
			}
		)
	}

	const onTypeChange = (type: DiscountType) => {
		if (
			type === 'percent' && values.items
				? values.items[index].discount?.type !== 'percent'
				: false
		) {
			onValueChange(
				type,
				convertNumber(
					convertAmountToPercentage(
						parseFloat(
							`${
								values.items
									? values.items[index].perItemSellPrice
									: '0'
							}`
						),
						parseFloat(
							`${
								values.items
									? values.items[index].discount?.amount
									: '0'
							}`
						)
					)
				).toString()
			)
		} else if (type === 'flat' && values.discount?.type !== 'flat') {
			onValueChange(
				type,
				convertNumber(
					convertPercentageToAmount(
						parseFloat(
							`${
								values.items
									? values.items[index].perItemSellPrice
									: '0'
							}`
						),
						parseFloat(
							`${
								values.items
									? values.items[index].discount?.amount
									: '0'
							}`
						)
					)
				).toString()
			)
		}
	}

	const lineTotal = calculateDiscount(
		convertNumber(row.quantity || 0) *
			convertNumber(row.perItemSellPrice || 0),
		row.discount?.amount || 0,
		row.discount?.type || 'percent'
		// eslint-disable-next-line no-mixed-spaces-and-tabs
	)

	const seletedDiscount = DISCOUNT_OPTION.filter(
		f =>
			f.value ===
			(values.items ? values.items[index]?.discount?.type : 'percent')
	)

	const onBlur = () => {
		if (
			values.items &&
			values.items[index].discount?.type === 'percent' &&
			(values.items[index].discount?.amount || 0) > 100
		) {
			setFieldValue(`items[${index}].discount.amount`, 100)
		} else if (
			values.items &&
			values.items[index].discount?.type !== 'percent' &&
			(values.items[index].discount?.amount || 0) >
				convertNumber(row.quantity || 0) *
					convertNumber(row.perItemSellPrice || 0)
		) {
			setFieldValue(`items[${index}].discount.amount`, '0')
		}
	}

	return (
		<StyledTableRow hover role="checkbox" tabIndex={-1}>
			<TableCell>
				<FlexRow>
					<FlexRow align={'center'}>
						{row.icon ? (
							<img
								src={row.icon}
								alt={row.name}
								loading="lazy"
								height={30}
								width={30}
								style={{
									objectFit: 'cover',
									cursor: 'pointer'
								}}
								onClick={() => toggleImageSize(row.icon || '')}
							/>
						) : (
							<Avatar
								title={getNameChar(row.name)}
								borderWidth={0}
								sx={{
									backgroundColor:
										theme.palette.colors.green['50'],
									color: theme.palette.colors.green['900'],
									fontSize: 14,
									width: 30,
									height: 30
								}}
							/>
						)}
					</FlexRow>
					<Gutter gap={1} />
					<FlexCol>
						<Typography variant={'caption'} fontWeight={'500'}>
							{row.name}
						</Typography>

						<Typography
							variant={'caption'}
							color={theme.palette.colors.gray['800']}
						>
							{row.code}
						</Typography>
						<Typography
							variant={'caption'}
							color={theme.palette.colors.gray['500']}
						>
							{`In Stock: ${row.maxQuantity}`}
						</Typography>
						<Price variant={'caption'} fontWeight={'500'}>
							<Abbr
								title={`Rs. ${currencyInput(
									row.perItemSellPrice.toString()
								)}`}
								length={10}
							/>
						</Price>
					</FlexCol>
				</FlexRow>
			</TableCell>
			<PriceCell>
				<Typography variant={'caption'} fontWeight={'500'}>
					<Abbr
						title={`Rs. ${currencyInput(
							row.perItemSellPrice.toString()
						)}`}
						length={10}
					/>
				</Typography>
			</PriceCell>
			<TableCell>
				<FieldNumberInput
					name={`items[${index}].quantity`}
					size={'small'}
					regex={POSITIVE_NUMBER}
					sx={{ width: '8ch' }}
				/>
			</TableCell>
			{displayItemLevelDiscountOB && isOpen && (
				<TableCell>
					<PopupState
						variant="popper"
						popupId="demo-popup-popper"
						disableAutoFocus={true}
					>
						{popupState => (
							<>
								<FieldNumberInput
									name={`items[${index}].discount.amount`}
									size={'small'}
									placeholder={'Amount'}
									style={{
										maxWidth: 200,
										width: '100%',
										minWidth: 180
									}}
									onBlur={onBlur}
									InputProps={{
										endAdornment: (
											<InputAdornment
												position="end"
												{...bindToggle(popupState)}
											>
												<ClickAwayListener
													onClickAway={() =>
														popupState.close()
													}
												>
													<Box>
														<Typography>
															{seletedDiscount.length >
															0
																? seletedDiscount[0]
																		.name
																: 'PKR'}
														</Typography>
														<Gutter gap={0.2} />

														{popupState.isOpen ? (
															<MdOutlineKeyboardArrowUp />
														) : (
															<MdOutlineKeyboardArrowDown />
														)}
													</Box>
												</ClickAwayListener>
											</InputAdornment>
										)
									}}
								/>
								<Popper
									{...bindPopper(popupState)}
									transition
									placement="bottom-end"
								>
									{({ TransitionProps }) => (
										<Fade
											{...TransitionProps}
											timeout={350}
										>
											<Wrapper>
												<FlexCol
													style={{
														minWidth: 220
													}}
												>
													<Gutter spacing={0.5} />
													{DISCOUNT_OPTION.map(d => (
														<Opt
															align={'center'}
															onClick={() => {
																onTypeChange(
																	d.value as DiscountType
																)

																popupState.close()
															}}
															key={d.name}
														>
															<Typography
																variant={
																	'body2'
																}
															>
																{d.name}
															</Typography>
														</Opt>
													))}
												</FlexCol>
											</Wrapper>
										</Fade>
									)}
								</Popper>
							</>
						)}
					</PopupState>
				</TableCell>
			)}
			<TableCell width={30} align={'right'}>
				<FlexRow
					justify={'flex-end'}
					style={{ flexWrap: 'nowrap' }}
					align={'center'}
				>
					{amountToLocal(convertNumber(lineTotal))}
					<Gutter gap={0.3} />
					<DeleteButton
						onClick={() => {
							helpers.setValue(
								value.filter(
									(f: ItemProps) =>
										!(
											f.stockId === row.stockId &&
											f.unit === row.unit
										)
								)
							)
						}}
					/>
				</FlexRow>
			</TableCell>
		</StyledTableRow>
	)
}
