import React from 'react'
import { styled, Typography } from '@mui/material'
import { Gutter } from 'components'
import { FlexCol } from 'components/atoms/Flex'
import { useNavigate } from 'react-router-dom'
import { amountToLocal, convertNumber } from 'helpers'
import type { InvoiceType } from 'typings/invoices'
import { InternalComment } from './InternalComment'
import { PaymentsTable } from './Component/PaymentsTable'
import type { TransactionPaymentType } from 'typings'

const Wrapper = styled(FlexCol)`
	padding: 24px;
	height: max-content;
`

const AmountDueText = styled(Typography)`
	color: ${({ theme }) => theme.palette.colors.gray['700']};
	font-weight: 500;
`

const AmountText = styled(Typography)`
	line-height: 25px;
`

type PdfRightWrapperProps = {
	currentInvoice: InvoiceType
	totalAmountDue: number
	payments: TransactionPaymentType[]
}

export const RightViewOptions: React.ComponentType<PdfRightWrapperProps> = ({
	currentInvoice,
	totalAmountDue,
	payments
}) => {
	const navigate = useNavigate()

	const { internalComments } = currentInvoice

	return (
		<Wrapper>
			{currentInvoice.paymentStatus !== 'paid' && (
				<>
					<AmountDueText variant="caption">Amount Due</AmountDueText>
					<AmountText variant="h6" fontWeight={'bold'}>
						{`PKR ${amountToLocal(
							convertNumber(totalAmountDue || 0)
						)}`}
					</AmountText>
				</>
			)}

			<Gutter spacing={1.2} />

			{payments && currentInvoice.status !== 'draft' && (
				<>
					<PaymentsTable data={payments} />
					<Gutter spacing={1} />
				</>
			)}

			<InternalComment
				invoiceId={currentInvoice?.id || ''}
				internalComment={
					internalComments.length > 0
						? internalComments[internalComments.length - 1]
						: undefined
				}
				onSuccess={comment => {
					const invoice = currentInvoice
					if (invoice.internalComments.length === 0) {
						invoice.internalComments.push(comment)
					} else {
						invoice.internalComments[
							invoice.internalComments.length - 1
						] = comment
					}

					navigate(`/invoice/view/${currentInvoice?.id || 0}`, {
						replace: true,
						state: { currentInvoice: invoice }
					})
				}}
			/>
		</Wrapper>
	)
}
