import React, { useCallback, useState } from 'react'
import { styled } from '@mui/system'
import {
	Button,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography
} from '@mui/material'
import { FlexCol, FlexRow } from 'components/atoms/Flex'
import { Capsule, Gutter } from 'components'
import {
	ConfirmationNumberOutlined,
	CreditCard,
	KeyboardArrowDownOutlined,
	KeyboardArrowUpOutlined,
	LanguageOutlined,
	Payments
} from '@mui/icons-material'
import type { PaymentMethod, TransactionPaymentType } from 'typings'
import moment from 'moment'
import { currencyInput } from 'helpers'
import { SCROLLBAR_STYLE } from 'data'

const Wrapper = styled(FlexCol)`
	border: 1px solid ${({ theme }) => theme.palette.colors.gray['300']};
	border-radius: 8px;
	width: 100%;
	max-width: 95%;
`

const UpperWrapper = styled(Button)`
	width: 100%;
	padding: 16px;
	justify-content: space-between;
	align-items: center;
	color: ${({ theme }) => theme.palette.colors.black['900']};
`

const PaymentsText = styled(Typography)`
	color: ${({ theme }) => theme.palette.colors.gray['700']};
`

const TableText = styled(Typography)`
	color: ${({ theme }) => theme.palette.colors.gray['700']};
	font-size: 12px;
	margin-top: 5px;
`

const ArrowDownIcon = styled(KeyboardArrowDownOutlined)`
	font-size: 30px;
`

const ArrowUpIcon = styled(KeyboardArrowUpOutlined)`
	font-size: 30px;
`

const MoneyIcon = styled(Payments)`
	font-size: 16px;
	color: ${({ theme }) => theme.palette.colors.green['500']};
`

const CardIcon = styled(CreditCard)`
	font-size: 16px;
	color: ${({ theme }) => theme.palette.colors.green['500']};
`

const ChequeIcon = styled(ConfirmationNumberOutlined)`
	font-size: 16px;
	color: ${({ theme }) => theme.palette.colors.green['500']};
`

const OnlineIcon = styled(LanguageOutlined)`
	font-size: 16px;
	color: ${({ theme }) => theme.palette.colors.green['500']};
`

const NumberCapsule = styled(Capsule)`
	padding: 2px 8px;
	border-radius: 16px;
	background-color: ${({ theme }) => theme.palette.colors.blue['50']};
	width: 22px;
	height: 22px;
	align-content: center;
	color: ${({ theme }) => theme.palette.colors.blue['700']};
	font-size: 12px;
`

const StyledTableContainer = styled(TableContainer)`
	border-radius: 0;
	overflow: auto;
	position: relative;
	min-height: 250px;

	${SCROLLBAR_STYLE}
`

const AbsoluteWrapper = styled(FlexCol)`
	width: 100%;
	height: 150px;
	position: absolute;
	align-self: center;
	justify-content: center;
`

const StyledTableRow = styled(TableRow)`
	&:last-child td,
	&:last-child th {
		border: 0;
	}
`

const HeaderTableCell = styled(TableCell)`
	font-size: 12px;
	background-color: ${({ theme }) => theme.palette.colors.gray['100']};
	white-space: nowrap;
`

const BodyTableCell = styled(TableCell)`
	font-size: 10px;
	background-color: ${({ theme }) => theme.palette.background.default};
	vertical-align: top;
	word-wrap: break-word;
`

const AmountTableCell = styled(TableCell)`
	font-size: 12px;
	font-weight: 500;
	background-color: ${({ theme }) => theme.palette.background.default};
	vertical-align: top;
`

const StyledRow = styled(FlexRow)`
	justify-content: center;
	align-items: center;
	align-self: center;
	height: 250px;
`

type PaymentsTableProps = {
	data: TransactionPaymentType[]
}

export const PaymentsTable: React.ComponentType<PaymentsTableProps> = ({
	data
}) => {
	const [expand, setExpand] = useState(false)

	const getModeIcon = (mode: PaymentMethod) => {
		switch (mode) {
			case 'cash':
				return <MoneyIcon />
			case 'card':
				return <CardIcon />
			case 'cheque':
				return <ChequeIcon />
			case 'online':
				return <OnlineIcon />
			default:
				return mode
		}
	}

	const RenderRow = useCallback(
		({ row }: { row: TransactionPaymentType }) => {
			return (
				<StyledTableRow>
					<BodyTableCell sx={{ width: '70px' }}>
						{moment(row.date).format('DD/MM/YY')}
					</BodyTableCell>
					<BodyTableCell align="center" sx={{ width: '70px' }}>
						{getModeIcon(row.method)}
					</BodyTableCell>
					<BodyTableCell sx={{ maxWidth: '132px' }}>
						{row?.note}
					</BodyTableCell>
					<AmountTableCell align="center">
						{currencyInput(`${row.amount || 0}`)}
					</AmountTableCell>
				</StyledTableRow>
			)
		},
		[]
	)

	return (
		<Wrapper>
			<UpperWrapper onClick={() => setExpand(!expand)}>
				<FlexRow align="center">
					<PaymentsText variant="subtitle2">
						Payments Received
					</PaymentsText>
					<Gutter gap={0.2} />
					<NumberCapsule name={`${data.length}`} value="2" />
				</FlexRow>
				{expand ? <ArrowUpIcon /> : <ArrowDownIcon />}
			</UpperWrapper>

			{expand && (
				<>
					<StyledTableContainer>
						<Table stickyHeader size="small">
							<TableHead>
								<TableRow>
									<HeaderTableCell>Date</HeaderTableCell>
									<HeaderTableCell align="center">
										Mode
									</HeaderTableCell>
									<HeaderTableCell>Note</HeaderTableCell>
									<HeaderTableCell align="center">
										Amount (PKR)
									</HeaderTableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{data.map(row => (
									<RenderRow key={row.id} row={row} />
								))}
							</TableBody>
						</Table>

						{data.length === 0 && (
							<AbsoluteWrapper>
								<StyledRow>
									<TableText variant="caption">
										No payments recorded yet.
									</TableText>
								</StyledRow>
							</AbsoluteWrapper>
						)}
						<Gutter gap={0.1} />
					</StyledTableContainer>
				</>
			)}
		</Wrapper>
	)
}
