import React, { useCallback, useMemo } from 'react'
import {
	Button,
	ClickAwayListener,
	Fade,
	Popper,
	styled,
	Typography,
	useMediaQuery,
	useTheme
} from '@mui/material'
import PopupState, { bindPopper, bindToggle } from 'material-ui-popup-state'
import { FlexCol, FlexRow } from 'components/atoms/Flex'
import { objectCompare } from 'helpers'
import { BiCheck } from 'react-icons/bi'
import { KeyboardArrowDown } from '@mui/icons-material'
import { Capsule } from 'components'
import { LAYOUT_BREAKPOINT_WIDTH, SEGMENT_EVENTS } from 'data'
import analytics from 'utils/segment'

const Wrapper = styled(FlexCol)`
	background-color: ${({ theme }) => theme.palette.background.default};
	border: 1px solid ${({ theme }) => theme.palette.background.paper};
	border-radius: 8px;
	margin-top: 5px;
	box-shadow: 0 12px 16px -4px rgba(16, 24, 40, 0.08),
		0px 4px 6px -2px rgba(16, 24, 40, 0.03);
	overflow: hidden;
`

const Option = styled(FlexRow, {
	shouldForwardProp: props => props !== 'selected'
})<{ selected: boolean }>`
	justify-content: space-between;
	align-items: center;
	padding: 12px 20px;
	width: 100%;
	background-color: ${({ theme, selected }) =>
		selected
			? theme.palette.colors.green['50']
			: theme.palette.background.default};

	:hover {
		cursor: pointer;
		background-color: ${({ theme }) => theme.palette.colors.green['100']};
	}
`

const NumberCapsule = styled(Capsule)`
	background-color: ${({ theme }) => theme.palette.colors.gray['100']};
	color: ${({ theme }) => theme.palette.colors.black['700']};
	padding: 2px 10px;
	width: 24px;
	height: 24px;
	align-content: center;
	font-size: 14px;
	font-weight: 500;
`

const Col = styled(FlexCol)`
	min-width: 220px;
`

const ButtonWrapper = styled(Button)`
	padding-left: 0;
`

export type FilterObjProps = { [key: string]: string[] }

export type FilterButtonDataSourceProps = {
	id: string | number
	label: string
	value: FilterObjProps
}

export type FilterButtonProps = {
	dataSource: FilterButtonDataSourceProps[]
	onSelect?(filterObj: FilterObjProps): void
	filterObj: FilterObjProps
	total: number
}

export const RolesFilter: React.ComponentType<FilterButtonProps> = ({
	dataSource,
	onSelect,
	filterObj,
	total
}) => {
	const theme = useTheme()
	const {
		palette: { colors, mode }
	} = theme
	const isMobile = useMediaQuery(`(max-width:${LAYOUT_BREAKPOINT_WIDTH}px)`)

	const RenderFilter = useCallback(
		({
			label,
			value,
			close
		}: FilterButtonDataSourceProps & { close(): void }) => {
			const isFilter = Object.keys(filterObj).some(key =>
				objectCompare(filterObj[key], value[key])
			)

			return (
				<Col>
					<Option
						align={'flex-start'}
						selected={isFilter}
						onClick={() => {
							analytics.track(
								SEGMENT_EVENTS.SETTINGS_FILTER_USER_ROLE,
								{ filter: label }
							)
							onSelect?.({ ...value })
							close()
						}}
					>
						<Typography variant={'body1'}>{label}</Typography>
						{isFilter && <BiCheck color={colors.green['600']} />}
					</Option>
				</Col>
			)
		},
		[colors.green, filterObj, onSelect]
	)

	const label = useMemo(() => {
		let text: string | undefined = undefined

		dataSource.filter((d: any) => {
			return Object.keys(filterObj).filter(key => {
				if (objectCompare(filterObj[key], d.value[key])) {
					text = d.label
					return true
				}
			})
		})

		return text
	}, [dataSource, filterObj])

	return (
		<PopupState
			variant="popper"
			popupId="demo-popup-popper"
			disableAutoFocus={true}
		>
			{popupState => (
				<ClickAwayListener onClickAway={() => popupState.close()}>
					<div>
						<ButtonWrapper {...bindToggle(popupState)}>
							<Typography
								variant={isMobile ? 'body2' : 'h6'}
								fontWeight={'bold'}
								color={
									mode === 'dark'
										? colors.white['100']
										: colors.black['900']
								}
								textAlign={'center'}
								sx={{ mt: isMobile ? 0 : -0.4 }}
							>
								{label ? label : 'Filter'}
							</Typography>
							<NumberCapsule
								name={total.toString()}
								value="numOfUsers"
							/>
							<KeyboardArrowDown
								sx={{ color: colors.black['900'] }}
								fontSize="small"
							/>
						</ButtonWrapper>
						<Popper
							{...bindPopper(popupState)}
							transition
							placement="bottom-start"
						>
							{({ TransitionProps }) => (
								<Fade {...TransitionProps} timeout={350}>
									<Wrapper>
										{dataSource.map(d => (
											<React.Fragment key={d.id}>
												<RenderFilter
													{...d}
													close={popupState.close}
												/>
											</React.Fragment>
										))}
									</Wrapper>
								</Fade>
							)}
						</Popper>
					</div>
				</ClickAwayListener>
			)}
		</PopupState>
	)
}
