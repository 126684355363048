import React from 'react'
import { StyledModal } from 'components/StyledModal'
import type { ModalProps } from '@mui/material'
import { Button, Typography, useTheme } from '@mui/material'
import { styled } from '@mui/system'
import { FlexCol, FlexRow } from 'components/atoms/Flex'
import { Gutter } from 'components/atoms'
import { IoIosClose } from 'react-icons/io'
import { FieldTextInput } from 'components'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { CNIC_FORMAT } from 'helpers'
import { getAllBusiness, updateBusiness } from 'api'
import { useSettings } from 'context/settings'
import { useSnackbar } from 'notistack'
import { useDispatch } from 'react-redux'
import { fetchAllBusinessSuccess } from 'store/business/actions'
import { useAppErrors } from 'hooks'

const Container = styled(FlexCol)`
	background-color: ${({ theme }) => theme.palette.background.default};
	padding: 24px;
	border-radius: 12px;
	max-width: 400px;
`

const WrapperButton = styled(Button)(({ theme }) => ({
	backgroundColor: theme.palette.colors.green['500'],
	color: theme.palette.colors.white['900'],
	borderRadius: '8px',
	width: '48%',
	'&:hover': {
		background: theme.palette.colors.green['700']
	},
	'&:disabled': {
		background: theme.palette.colors.gray['300']
	},
	fontWeight: 600
}))

const TransparentButton = styled(Button)`
	color: ${({ theme }) => theme.palette.colors.gray['900']};
	border: 1px solid ${({ theme }) => theme.palette.colors.gray['300']};
	border-radius: 8px;
	width: 48%;
	font-weight: 600;
`

const CloseButtonWrapper = styled(FlexCol)`
	justify-content: center;
	align-items: center;
	height: 30px;
	width: 30px;
	&:hover {
		cursor: pointer;
		border-radius: 8px;
		background-color: ${({ theme }) => theme.palette.colors.gray['100']};
		display: flex;
	}
`

type CnicEditModalProps = {
	onSuccess?: () => void
	existingValue?: string
} & Omit<ModalProps, 'children'>

const validationSchema = Yup.object().shape({
	cnic: Yup.string()
		.required('NTN/CNIC is required')
		.matches(
			CNIC_FORMAT,
			'NTN/CNIC can only contain alphanumeric characters and hyphen'
		)
		.max(25, 'NTN/CNIC length should be less than 25 characters')
		.min(4, 'NTN/CNIC length should be greater than 4 characters')
})

export const CnicEditModal: React.ComponentType<CnicEditModalProps> = ({
	onSuccess,
	existingValue,
	...props
}) => {
	const theme = useTheme()
	const { colors } = theme.palette
	const { business } = useSettings()
	const { enqueueSnackbar } = useSnackbar()
	const dispatch = useDispatch()
	const { setAppError } = useAppErrors()

	const onSubmit = async (values: any, actions: any) => {
		try {
			actions.setSubmitting(true)
			await updateBusiness(values, business)
			enqueueSnackbar('CNIC/NTN Updated Successfully', {
				variant: 'success'
			})
			const res = await getAllBusiness()
			if (res.length > 0) {
				dispatch(fetchAllBusinessSuccess(res))
			}
			actions.setSubmitting(false)
			onSuccess?.()
			props.onClose?.({}, 'escapeKeyDown')
		} catch (err: any) {
			setAppError(err, actions)
		}
	}

	return (
		<StyledModal {...props}>
			<Container>
				<Formik
					initialValues={{
						cnic: existingValue || ''
					}}
					onSubmit={onSubmit}
					validationSchema={validationSchema}
				>
					{({ handleSubmit, isSubmitting, dirty, initialValues }) => (
						<FlexCol>
							<FlexRow
								sx={{ width: '100%' }}
								justify="space-between"
							>
								<Typography
									variant={'h6'}
									fontWeight={'medium'}
								>
									{!initialValues.cnic
										? 'Enter Required Information*'
										: 'Edit NTN/CNIC Information'}
								</Typography>
								<CloseButtonWrapper>
									<IoIosClose
										size={25}
										onClick={() =>
											props.onClose?.({}, 'escapeKeyDown')
										}
									/>
								</CloseButtonWrapper>
							</FlexRow>
							<Gutter spacing={0.2} />
							<Typography
								variant={'body2'}
								color={colors.gray['500']}
							>
								{!initialValues.cnic
									? 'Please enter your NTN/CNIC details to display on the invoice.'
									: 'Manage NTN/CNIC details to display on the invoice.'}
							</Typography>
							<Gutter spacing={1} />
							<FieldTextInput
								name="cnic"
								size="small"
								fullWidth
								wrapperStyle={{ width: '100%' }}
								placeholder="Enter NTN/CNIC"
							/>
							<Gutter spacing={1.5} />
							<FlexRow
								align={'center'}
								sx={{ width: '100%' }}
								justify="space-between"
							>
								<TransparentButton
									onClick={() =>
										props.onClose?.({}, 'escapeKeyDown')
									}
								>
									Cancel
								</TransparentButton>
								<WrapperButton
									disabled={isSubmitting || !dirty}
									onClick={() => handleSubmit()}
								>
									Save{!initialValues.cnic ? '' : ' Changes'}
								</WrapperButton>
							</FlexRow>
						</FlexCol>
					)}
				</Formik>
			</Container>
		</StyledModal>
	)
}
