import React, { type ChangeEvent, type CSSProperties, useCallback } from 'react'
import { useField } from 'formik'
import type { TextInputProps } from 'components'
import { ErrorText, TextInput } from 'components'
import { FlexCol, FlexRow } from 'components/atoms/Flex'

export type FieldTextInputProps = Omit<TextInputProps, 'onBlur' | 'onFocus'> & {
	name: string
	placeholder?: string
	wrapperStyle?: CSSProperties
	nextInputRef?: React.MutableRefObject<typeof TextInput> | null
}

export const FieldTextInput = React.forwardRef<
	TextInputProps,
	FieldTextInputProps
>(({ name, onChange, wrapperStyle, nextInputRef, ...props }, ref) => {
	const [field, meta, helpers] = useField(name)

	const error = meta.touched && meta.error

	const handleBlur = useCallback(() => {
		helpers.setValue(meta.value && meta.value?.trim())
		helpers.setTouched(true)
	}, [helpers, meta.value])

	return (
		<FlexCol align={'flex-start'} style={wrapperStyle}>
			<TextInput
				id={name}
				value={field.value ? field.value.toString() : ''}
				onChange={(e: ChangeEvent<HTMLInputElement>) => {
					helpers.setValue(e.target.value)
					onChange?.(e)
				}}
				error={!!error}
				onBlur={handleBlur}
				{...props}
			/>

			<FlexRow style={{ width: '100%' }} align={'flex-start'}>
				<ErrorText text={error} />
			</FlexRow>
		</FlexCol>
	)
})

FieldTextInput.displayName = 'FieldTextInput'
