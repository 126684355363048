import { axios } from 'store'
import type {
	AwsResponsePropS,
	DataResponseType,
	IContactResponseProps,
	InventoryItemType,
	InventoryListPayloadType,
	InventoryStockType,
	StockQuantity
} from 'typings'
import queryString from 'query-string'

export const getLabelsList = async (businessId: string) => {
	const url = `/inventory/stockLabels/business/${businessId.replaceAll(
		'"',
		''
	)}`

	try {
		const { data } = await axios.get<string[]>(url)

		return data
	} catch (error: any) {
		throw error.response.data
	}
}

export const createAnInventoryItem = async (inventory: InventoryItemType) => {
	const url = '/Inventory'

	try {
		const { data } = await axios.post<InventoryItemType>(url, inventory)

		return data
	} catch (error: any) {
		throw error.response.data
	}
}

export const deleteInventoryUnit = async (
	item: string,
	business: string,
	unit: { stock: string[] }
) => {
	const url = `/inventory/${item}/business/${business}/stock?${queryString.stringify(
		unit
	)}`

	try {
		const { data } = await axios.delete<string[]>(url)

		return data
	} catch (error: any) {
		throw error.response.data
	}
}

export const updateInventory = async (
	item: string,
	business: string,
	payload: Partial<InventoryItemType>
) => {
	const url = `/inventory/${item}/business/${business}`

	try {
		const { data } = await axios.patch<string[]>(url, payload)

		return data
	} catch (error: any) {
		throw error.response.data
	}
}

export const getInventoryList = async (payload: InventoryListPayloadType) => {
	const url = `/inventory/list?${queryString.stringify(payload)}`

	try {
		const { data } = await axios.get<DataResponseType<InventoryItemType>>(
			url
		)

		return data
	} catch (error: any) {
		throw error.response.data
	}
}

export const getStockQuantitiesItems = async (stockIds: string[]) => {
	const url = `/inventory/check-available?stockIds=${stockIds.toString()}`

	try {
		const { data } = await axios.get<StockQuantity>(url)
		return data
	} catch (error: any) {
		throw error.response.data
	}
}

export const restockInventory = async (
	itemId: string,
	payload: {
		business: string
		unit: string
		amount: string | number
	}
) => {
	const url = `/inventory/restock/${itemId}`

	try {
		const { data } = await axios.patch<IContactResponseProps>(url, payload)

		return data
	} catch (error: any) {
		throw error.response.data
	}
}

export const addInventoryItem = async (
	payload: { items: InventoryStockType[] },
	inventoryId: string
) => {
	const url = `/inventory/${inventoryId}/stock`

	try {
		const { data } = await axios.post<IContactResponseProps>(url, payload)

		return data
	} catch (error: any) {
		throw error.response.data
	}
}

export const updateInventoryItem = async (
	payload: { item: InventoryStockType },
	inventoryId: string,
	stockId: string
) => {
	const url = `/inventory/${inventoryId}/stock/${stockId}`

	try {
		const { data } = await axios.patch<IContactResponseProps>(url, payload)

		return data
	} catch (error: any) {
		throw error.response.data
	}
}

export const uploadInventoryPic = async (
	fileType: string,
	payload: { inventoryId?: string }
) => {
	let url = `/inventory/item-image/upload-url?fileType=${fileType}`

	if (payload.inventoryId) {
		url += `&${queryString.stringify(payload)}`
	}

	try {
		const { data } = await axios.get<AwsResponsePropS>(url)

		return data
	} catch (error: any) {
		throw error.response.data
	}
}

export const checkAvailableStock = async (stockIds: string[]) => {
	const url = `/inventory/check-available?stockIds=${stockIds.join()}`

	try {
		const { data } = await axios.get<{
			[key: string]: number
		}>(url)

		return data
	} catch (error: any) {
		throw error.response.data
	}
}
