import type { QuotationType } from 'typings/quotation'
import { objectMissingValue } from 'helpers/object'
import type { StatusOptionProps } from 'components/molecules/Status'
import type { FilterButtonDataSourceProps } from 'components'
import moment from 'moment'
import { convertNumber } from 'helpers/number'

export const StatusOptions = [
	{ value: 'approved', label: 'Approved' },
	{ value: 'closed', label: 'Closed' },
	{ value: 'private', label: 'Private' }
]

export const CREATE_QUOTATION_INITIAL: QuotationType = {
	business: '',
	businessInfo: {
		address: ''
	},
	contact: {
		id: '',
		name: '',
		businessName: '',
		phone: {
			countryInitials: '',
			countryCode: '',
			number: ''
		},
		address: '',
		businessRelationship: []
	},
	initiationAt: '',
	items: [],
	taxes: [],
	extraCharges: [],
	discount: {
		amount: '',
		currency: 'PKR'
	},
	paymentTerms: {
		numDays: '',
		dueDate: '',
		custom: []
	},
	paymentMethod: '',
	status: 'private',
	comments: ''
}

export const QUOTATION_STATUS_OPTIONS: StatusOptionProps[] = [
	{
		id: 0,
		label: 'Approved',
		value: 'approved',
		color: 'green'
	},
	{
		id: 1,
		label: 'Closed',
		value: 'closed',
		color: 'yellow'
	}
]

export const QUOTATION_FILTER_OBJ: FilterButtonDataSourceProps[] = [
	{
		id: 0,
		title: 'Status',
		mode: 'multi',
		name: 'status',
		options: [
			{ id: 0, label: 'Approved', value: 'approved' },
			{ id: 1, label: 'Closed', value: 'closed' },
			{ id: 2, label: 'Private', value: 'private' }
		],
		selectAll: { id: 143, label: 'All', value: 'both' }
	}
]

export const GENERATE_QUOTATION_DATA = (data: QuotationType): any => {
	return objectMissingValue(CREATE_QUOTATION_INITIAL, {
		...data,
		items: data.items,
		isPaymentTerms:
			!!data.paymentTerms?.numDays ||
			data.paymentTerms?.custom?.length > 0 ||
			!!data.paymentTerms?.dueDate
	})
}

export const calculateQuotationTaxes = (
	value: QuotationType,
	total: number
) => {
	let x = 0
	value.taxes.map((tax: any) => {
		x += total * (parseFloat((tax.rate || 0).toString()) / 100)
	})
	return x
}

export const totalAmount = (values: QuotationType) => {
	if (values?.total) return convertNumber(values.total)
	else if (values?.items) values = GENERATE_QUOTATION_DATA(values)
	return convertNumber(calculateQuotationSubTotal(values))
}

export const calculateQuotationSubTotal = (values: QuotationType) => {
	let total = 0
	values.items.forEach((item: any) => {
		const sellPrice =
			item.perItemSellPrice == ''
				? 0
				: parseFloat(item.perItemSellPrice.toString())
		const quantity =
			item.quantity == '' ? 0 : parseInt(item.quantity.toString())
		total += sellPrice * quantity
	})
	return total
}

export const calculateQuotationDiscountAmount = (
	values: Required<QuotationType>
) => {
	const { discount } = values
	const subtotal = calculateQuotationSubTotal(values)
	const totalExtraCharges = calculateQuotationExtraCharges(values)
	const total = subtotal + totalExtraCharges

	return discount.amount == '' || discount?.amount == '.'
		? 0
		: discount.amount == null
		? 0
		: (convertNumber(discount.amount) / 100) * total
}

export const calculateQuotationExtraCharges = (
	values: Required<QuotationType>
) => {
	let total = 0
	values.extraCharges.forEach((charge: any) => {
		total += parseFloat(
			charge.amount == '' || charge.amount == '.' ? 0 : charge.amount
		)
	})
	return total
}

export const calculateQuotationTotal = (values: Required<QuotationType>) => {
	const total = calculateQuotationSubTotal(values)
	const totalExtraCharges = calculateQuotationExtraCharges(values)

	const baseAmt = total + totalExtraCharges
	const baseAmtAfterDiscount =
		baseAmt - calculateQuotationDiscountAmount(values)

	const taxes = calculateQuotationTaxes(values, baseAmtAfterDiscount)

	const postTaxAmount = baseAmtAfterDiscount + taxes

	return convertNumber(postTaxAmount)
}
