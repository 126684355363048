import React, { useRef, useState } from 'react'
import { FlexCol, FlexRow } from 'components/atoms/Flex'
import { styled } from '@mui/system'
import { Button, Modal, Typography, useTheme } from '@mui/material'
import { BouncingDotsLoader, Gutter, ModalWrapper } from 'components'
import { HiOutlineDocument } from 'react-icons/hi'
import { IoIosClose } from 'react-icons/io'
import { useAppErrors } from 'hooks'
import { getSignedUrlForInvoicePdf } from 'api/invoice'
import type { InvoiceType } from 'typings'
import { enqueueSnackbar } from 'notistack'
import ReactToPrint from 'react-to-print'
import { ThermalReceipt } from './ThermalReceipt'
import { useSelector } from 'react-redux'
import type { RootState } from 'store/index-reducer'
import { TransparentButton } from 'components/atoms/Button/TransparentButton'

const Wrapper = styled(FlexRow)`
	padding: 30px 20px;
	border-radius: 10px;
	background-color: ${({ theme }) => theme.palette.colors.white['900']};
	max-width: 550px;
	position: relative;
`

const Box = styled(FlexRow)`
	padding: 12px;
	margin-top: 5px;
	border-radius: 7px;
	background-color: ${({ theme }) => theme.palette.colors.green['50']};
`

const CloseButtonWrapper = styled(FlexCol)`
	position: absolute;
	top: 10px;
	right: 10px;
	justify-content: center;
	align-items: center;
	height: 30px;
	width: 30px;
	&:hover {
		cursor: pointer;
		border-radius: 25%;
		background-color: ${({ theme }) => theme.palette.colors.gray['100']};
		display: flex;
	}
`

const Grid = styled('div')`
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 10px;
	width: 100%;
`

type Props = {
	open: boolean
	onClose: () => void
	onPrintSuccess: () => void
	invoice: InvoiceType
}

export const PrintInvoice: React.ComponentType<Props> = ({
	open,
	onClose,
	onPrintSuccess,
	invoice
}) => {
	const theme = useTheme()
	const { colors } = theme.palette
	const pdfRef = useRef(null)
	const { setAppError } = useAppErrors()
	const [isDownloading, setIsDownloading] = useState<boolean>(false)
	const [isDownloadingPOS, setIsDownloadingPOS] = useState<boolean>(false)
	const { user } = useSelector((state: RootState) => state.user)

	const handleDownloadandPrintInvoice = async (invoice: InvoiceType) => {
		try {
			setIsDownloading(true)
			const data = {
				...invoice,
				businessInfo: {
					...invoice.businessInfo,
					email: user?.email || '',
					phone: user?.phone || { countryCode: '', number: '' }
				}
			}
			const res: any = await getSignedUrlForInvoicePdf([data], true)

			window.open(res.data.signedUrl, '_blank')
			onPrintSuccess?.()
			enqueueSnackbar('Invoice Ready to Print', {
				variant: 'success'
			})
		} catch (e: any) {
			setAppError(e)
		}
		// close modal
		setIsDownloading(false)
		onClose?.()
	}

	const handleDownloadPOSInvoice = async () => {
		try {
			setIsDownloadingPOS(true)

			enqueueSnackbar('POS Invoice Receipt is Ready to Print', {
				variant: 'success'
			})
		} catch (e: any) {
			setAppError(e)
		}
		// close modal
		setIsDownloadingPOS(false)
		onClose()
	}

	return (
		<Modal open={open} onClose={onClose}>
			<ModalWrapper>
				<Wrapper>
					<CloseButtonWrapper>
						<IoIosClose size={25} onClick={onClose} />
					</CloseButtonWrapper>
					<Box>
						<HiOutlineDocument
							color={colors.green['500']}
							size={22}
						/>
					</Box>
					<Gutter gap={1.5} />
					<FlexCol style={{ flex: 1 }}>
						<FlexRow>
							<Typography variant="h6" fontWeight={600}>
								Print Invoice as...
							</Typography>
						</FlexRow>
						<Typography
							variant="body2"
							color={colors.gray['400']}
							fontWeight={500}
						>
							Do you want to print the invoice as an A4 invoice or
							an 80mm POS receipt invoice?
						</Typography>
						<Gutter spacing={2.2} />
						<Grid>
							<TransparentButton
								disabled={isDownloading || isDownloadingPOS}
								onClick={() =>
									handleDownloadandPrintInvoice(invoice)
								}
							>
								<Typography
									variant="subtitle1"
									fontWeight={500}
								>
									{!isDownloading ? (
										'A4 Invoice'
									) : (
										<FlexRow align="center">
											<Gutter gap={1.2} />
											<BouncingDotsLoader />
										</FlexRow>
									)}
								</Typography>
							</TransparentButton>
							<ReactToPrint
								trigger={() => (
									<TransparentButton
										onClick={() =>
											handleDownloadPOSInvoice()
										}
										disabled={
											isDownloading || isDownloadingPOS
										}
									>
										<Typography
											variant="subtitle1"
											fontWeight={500}
										>
											{!isDownloadingPOS ? (
												`POS Receipt Invoice`
											) : (
												<BouncingDotsLoader />
											)}
										</Typography>
									</TransparentButton>
								)}
								pageStyle={
									'@page { size: 80mm 170mm; margin: 5mm; }'
								}
								content={() => pdfRef?.current}
							/>
						</Grid>
					</FlexCol>
					<div style={{ display: 'none' }}>
						<ThermalReceipt invoice={invoice} ref={pdfRef} />
					</div>
				</Wrapper>
			</ModalWrapper>
		</Modal>
	)
}
