import { axios } from 'store'
import type {
	AwsResponsePropS,
	ILogin,
	IOtp,
	ISession,
	IUser
} from 'typings/auth'

export type RequestOtpProps = {
	phone: string
}

export const requestOtp = async (payload: RequestOtpProps) => {
	const url = '/auth/start'

	try {
		const { data } = await axios.post<IOtp>(url, payload)

		return data
	} catch (error: any) {
		throw error.response.data
	}
}

export const uploadADoc = async (
	BUSINESS: string,
	fileType: string,
	applicationType: 'image' | 'application',
	type: string
) => {
	const url = `/auth/cbfs/upload-url?mediaType=${applicationType}&businessId=${BUSINESS}&fileType=${fileType}&enityType=${type}`

	try {
		const { data } = await axios.get<AwsResponsePropS>(url)

		return data
	} catch (error: any) {
		throw error.response.data
	}
}

export const auth = async (payload: ILogin) => {
	const url = '/auth/login'

	try {
		const { data } = await axios.post<ISession>(url, payload)

		return data
	} catch (error: any) {
		throw error.response.data
	}
}

export const uploadProfilePic = async (payload: string) => {
	const url = `/auth/profile-pic/upload-url?fileType=${payload}`

	try {
		const { data } = await axios.get<AwsResponsePropS>(url)

		return data
	} catch (error: any) {
		throw error.response.data
	}
}

export const getRefreshToken = async () => {
	const url = '/auth/refresh-token'

	try {
		const { data } = await axios.get<{ token: string; user: IUser }>(url)

		return data
	} catch (error: any) {
		throw error.response.data
	}
}
