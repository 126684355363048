import {
	FormControlLabel,
	Popover,
	Radio,
	RadioGroup,
	styled
} from '@mui/material'
import { Capsule } from 'components/atoms'
import { BsChevronDown } from 'react-icons/bs'
import React, { useState } from 'react'
import { StatusOptions } from 'data'

const WrapperCapsule = styled(Capsule)`
	padding: 5px;
	border-radius: 15px;
	width: 110px;
`

const DownArrow = styled(BsChevronDown)`
	margin-left: 5px;
`

const FieldRadioGroupWrapper = styled(RadioGroup)(() => ({
	'& .MuiSvgIcon-root': {
		fontSize: 15
	}
}))

const WrapperFormControlLabel = styled('div')`
	background-color: white;
	padding: 0 0 0 10px;
`
type Props = {
	selected: string
	quotationId: string
}

function capitalizeFirstLetter(string: string) {
	return string.charAt(0).toUpperCase() + string.slice(1)
}

export const DropdownCapsule: React.ComponentType<Props> = ({ selected }) => {
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

	const handleClick = (event: any) => {
		setAnchorEl(event.currentTarget)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	const [status, setStatus] = useState<string>(selected)

	const handleStatusChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setStatus((event.target as HTMLInputElement).value)
		setAnchorEl(null)
	}

	const open = Boolean(anchorEl)
	return (
		<>
			<WrapperCapsule
				name={capitalizeFirstLetter(status)}
				value={status}
				isSelected
				color={status == 'approved' ? 'green' : 'yellow'}
				renderRight={<DownArrow onClick={handleClick} />}
			/>
			<Popover
				elevation={2}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right'
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right'
				}}
			>
				<FieldRadioGroupWrapper
					value={status}
					onChange={handleStatusChange}
				>
					{StatusOptions.map(status => (
						<WrapperFormControlLabel key={status.label}>
							<FormControlLabel
								value={status.value}
								control={<Radio />}
								label={status.label}
							/>
						</WrapperFormControlLabel>
					))}
				</FieldRadioGroupWrapper>
			</Popover>
		</>
	)
}
