import React, { useCallback, useState } from 'react'
import { styled } from '@mui/system'
import {
	Button,
	ClickAwayListener,
	Fade,
	Popper,
	Typography,
	useTheme
} from '@mui/material'
import { AlertModal, Gutter } from 'components'
import {
	DeleteOutline,
	DownloadOutlined,
	EditOutlined,
	MoreVert
} from '@mui/icons-material'
import PopupState, { bindToggle, bindPopper } from 'material-ui-popup-state'
import { FlexCol, FlexRow } from 'components/atoms/Flex'
import type { LoadsheetType } from 'typings'
import { useNavigate } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { useAppErrors } from 'hooks'
import {
	deleteLoadSheet,
	getALoadSheet,
	getSignedUrlForLoadSheetPdf
} from 'api'
import { useSettings } from 'context/settings'

const TopButton = styled(Button)`
	border-radius: 8px;
	padding: 0 12px;
	border: 1px solid ${({ theme }) => theme.palette.colors.gray['300']};
	color: ${({ theme }) => theme.palette.colors.gray['700']};
	box-shadow: 0 1px 2px 0 rgba(21, 29, 45, 0.08);
	height: 36px;
`

const MoreIcon = styled(MoreVert)`
	font-size: 20px;
`

const ButtonText = styled(Typography)`
	color: ${({ theme }) => theme.palette.colors.gray['700']};
	font-weight: 500;
`

const PopupWrapper = styled(FlexCol)`
	background-color: ${({ theme }) => theme.palette.background.default};
	border: 1px solid ${({ theme }) => theme.palette.background.paper};
	border-radius: 8px;
	margin-top: 5px;
	min-width: 184px;
	box-shadow: 0 12px 16px -4px rgba(16, 24, 40, 0.08),
		0 4px 6px -2px rgba(16, 24, 40, 0.03);
`

const PopupButton = styled(Button, {
	shouldForwardProp: props => props !== 'danger'
})<{ danger?: boolean }>`
	justify-content: flex-start;
	width: 100%;
	padding: 10px 16px;
	color: ${({ theme, danger }) =>
		danger
			? theme.palette.colors.red['600']
			: theme.palette.colors.gray['600']};
`

const PopupButtonText = styled(Typography)`
	font-size: 12px;
`

const DownloadIcon = styled(DownloadOutlined)`
	font-size: 16px;
`

const EditIcon = styled(EditOutlined)`
	font-size: 16px;
`

const DeleteIcon = styled(DeleteOutline)`
	font-size: 16px;
`

type ActionButtonProps = {
	loadSheet: LoadsheetType
}

export const ActionButton: React.ComponentType<ActionButtonProps> = ({
	loadSheet
}) => {
	const [openDeleteAlert, setOpenDeleteAlert] = React.useState(false)

	const [isDownloading, setIsDownloading] = useState<boolean>(false)
	const navigate = useNavigate()
	const { setAppError } = useAppErrors()
	const { enqueueSnackbar } = useSnackbar()
	const { business } = useSettings()
	const theme = useTheme()
	const { colors } = theme.palette

	const downloadButtonClick = async () => {
		// Download Button Functionality comes here
		setIsDownloading(true)

		const loadSheetForPdf = await getALoadSheet(
			business,
			loadSheet.id || ''
		)
		const res = await getSignedUrlForLoadSheetPdf([loadSheetForPdf])

		const filename = `loadsheet-${loadSheet.id}.pdf`
		const response = await fetch(res.data.signedUrl)
		const blob = await response.blob()

		// Create a temporary URL for the blob
		const blobUrl = window.URL.createObjectURL(new Blob([blob]))

		const link = document.createElement('a')
		link.href = blobUrl
		link.download = filename
		link.click()

		window.URL.revokeObjectURL(blobUrl)
		link.remove()

		setIsDownloading(false)

		enqueueSnackbar('Loadsheet downloaded successfully', {
			variant: 'success'
		})
	}

	const editButtonClick = () => {
		navigate(`/load-sheet/create-load-sheet/${loadSheet.id}`)
	}

	const deleteButtonClick = () => {
		setOpenDeleteAlert(true)
	}

	const onDelete = useCallback(
		async (id: string) => {
			try {
				await deleteLoadSheet(business, [id])
				enqueueSnackbar('Successfully Deleted', {
					variant: 'success'
				})
				navigate('/load-sheet')
			} catch (e: any) {
				setAppError(e)
			}
		},
		[enqueueSnackbar, navigate, setAppError, business]
	)

	return (
		<>
			<PopupState
				variant="popper"
				popupId="demo-popup-popper"
				disableAutoFocus={true}
			>
				{popupState => (
					<ClickAwayListener onClickAway={() => popupState.close()}>
						<div>
							<TopButton {...bindToggle(popupState)}>
								<ButtonText variant={'subtitle2'}>
									Action
								</ButtonText>
								<Gutter gap={0.2} />
								<MoreIcon />
							</TopButton>
							<Popper
								{...bindPopper(popupState)}
								transition
								placement="bottom-start"
							>
								{({ TransitionProps }) => (
									<Fade {...TransitionProps} timeout={350}>
										<PopupWrapper>
											<PopupButton
												onClick={downloadButtonClick}
											>
												<FlexRow
													align="center"
													style={{
														width: '100%'
													}}
												>
													<DownloadIcon />
													<Gutter gap={0.2} />
													{!isDownloading && (
														<PopupButtonText variant="caption">
															Download
														</PopupButtonText>
													)}
													{isDownloading && (
														<PopupButtonText
															variant="caption"
															color={
																colors.gray[
																	'500'
																]
															}
														>
															Downloading...
														</PopupButtonText>
													)}
												</FlexRow>
											</PopupButton>

											<PopupButton
												onClick={editButtonClick}
											>
												<FlexRow align="center">
													<EditIcon />
													<Gutter gap={0.2} />
													<PopupButtonText variant="caption">
														Edit
													</PopupButtonText>
												</FlexRow>
											</PopupButton>
											<PopupButton
												danger={true}
												onClick={deleteButtonClick}
											>
												<FlexRow align="center">
													<DeleteIcon />
													<Gutter gap={0.2} />
													<PopupButtonText variant="caption">
														Delete
													</PopupButtonText>
												</FlexRow>
											</PopupButton>
										</PopupWrapper>
									</Fade>
								)}
							</Popper>
						</div>
					</ClickAwayListener>
				)}
			</PopupState>
			<AlertModal
				open={openDeleteAlert}
				setOpen={setOpenDeleteAlert}
				title="Delete Load Sheet"
				message="Are you sure you want to delete this load sheet? This action cannot be undone."
				onDelete={() => {
					onDelete(loadSheet.id || '0')
				}}
			/>
		</>
	)
}
