import React from 'react'
import {
	ClickAwayListener,
	Fade,
	InputAdornment,
	Popper,
	Skeleton,
	Typography,
	useTheme
} from '@mui/material'
import { FieldNumberInput, Gutter } from 'components'
import { styled } from '@mui/system'
import { useFormikContext } from 'formik'
import type { CurrentInvoiceProps } from 'screen/Invoices/NewCreate/Invoice'
import { FlexCol, FlexRow } from 'components/atoms/Flex'
import { IoIosClose } from 'react-icons/io'
import PopupState, { bindPopper, bindToggle } from 'material-ui-popup-state'
import {
	calculateInvoiceDiscountAmount,
	calculateInvoiceSTInclEC,
	DISCOUNT_OPTION,
	SEGMENT_EVENTS
} from 'data'
import {
	convertAmountToPercentage,
	convertNumber,
	convertPercentageToAmount,
	currencyInput
} from 'helpers'
import type { DiscountType, InvoiceType } from 'typings'
import {
	MdOutlineKeyboardArrowDown,
	MdOutlineKeyboardArrowUp
} from 'react-icons/md'
import analytics from 'utils/segment'

const Grid = styled('div')`
	display: grid;
	width: 100%;
	grid-template-columns: 1fr 1fr;
	align-items: flex-start;
	justify-content: space-between;
`

const Button = styled(Typography)`
	color: ${({ theme }) => theme.palette.colors['blue']['600']};

	:hover {
		cursor: pointer;
	}
`

const Row = styled(FlexRow)`
	width: 100%;
	justify-content: flex-end;
	flex-wrap: nowrap;
	align-items: center;
`

const Option = styled(FlexRow)`
	padding: 8px 14px;
	width: 100%;

	:hover {
		cursor: pointer;
		background-color: ${({ theme }) => theme.palette.colors['gray']['50']};
	}
`

const Box = styled(FlexRow)`
	padding-left: 10px;
	border-left: 1px solid ${({ theme }) => theme.palette.colors.gray['300']};
	flex-wrap: nowrap;
	align-items: center;

	:hover {
		cursor: pointer;
	}
`

const Wrapper = styled(FlexCol)`
	background-color: ${({ theme }) => theme.palette.background.default};
	border: 1px solid ${({ theme }) => theme.palette.background.paper};
	border-radius: 8px;
	margin-top: 5px;
	box-shadow: 0 12px 16px -4px rgba(16, 24, 40, 0.08),
		0 4px 6px -2px rgba(16, 24, 40, 0.03);
`

const Icon = styled(IoIosClose)`
	:hover {
		cursor: pointer;
	}
`

export const Discount = () => {
	const theme = useTheme()
	const {
		palette: { colors }
	} = theme
	const { values, setFieldValue } = useFormikContext<CurrentInvoiceProps>()
	const subTotal = !values.loading
		? convertNumber(calculateInvoiceSTInclEC(values as InvoiceType))
		: 0
	const seletedDiscount = DISCOUNT_OPTION.filter(
		f => f.value === values.discount?.type
	)

	const onBlur = () => {
		if (
			values.discount?.type === 'percent' &&
			(values.discount.amount || 0) > 100
		) {
			setFieldValue('discount.amount', 100)
		} else {
			setFieldValue(
				'discount.amount',
				subTotal - parseFloat(values.discount?.amount || '0') < 0
					? subTotal
					: values.discount?.amount
			)
		}
	}

	const onValueChange = (type: string, amount: string) => {
		setFieldValue('discount', {
			...values.discount,
			type: type,
			amount: amount
		})
	}

	const onTypeChange = (type: DiscountType) => {
		if (type === 'percent' && values.discount?.type !== 'percent') {
			onValueChange(
				type,
				convertNumber(
					convertAmountToPercentage(
						subTotal,
						parseFloat(values.discount?.amount || '0')
					)
				).toString()
			)
		} else if (type === 'flat' && values.discount?.type !== 'flat') {
			onValueChange(
				type,
				convertNumber(
					convertPercentageToAmount(
						subTotal,
						parseFloat(values.discount?.amount || '0')
					)
				).toString()
			)
		}
	}

	return (
		<>
			{values.loading ? (
				<Skeleton variant="rectangular" width={410} height={400} />
			) : (
				<>
					{values.isDiscountVisible && (
						<>
							<Grid>
								<PopupState
									variant="popper"
									popupId="demo-popup-popper"
									disableAutoFocus={true}
								>
									{popupState => (
										<>
											<FieldNumberInput
												title={'Discount'}
												name={'discount.amount'}
												size={'small'}
												placeholder={'Amount'}
												style={{
													maxWidth: 200,
													width: '100%'
												}}
												onBlur={onBlur}
												InputProps={{
													endAdornment: (
														<InputAdornment
															position="end"
															{...bindToggle(
																popupState
															)}
														>
															<ClickAwayListener
																onClickAway={() =>
																	popupState.close()
																}
															>
																<Box>
																	<Typography>
																		{seletedDiscount.length >
																		0
																			? seletedDiscount[0]
																					.name
																			: 'PKR'}
																	</Typography>
																	<Gutter
																		gap={
																			0.2
																		}
																	/>

																	{popupState.isOpen ? (
																		<MdOutlineKeyboardArrowUp />
																	) : (
																		<MdOutlineKeyboardArrowDown />
																	)}
																</Box>
															</ClickAwayListener>
														</InputAdornment>
													)
												}}
											/>
											<Popper
												{...bindPopper(popupState)}
												transition
												placement="bottom-end"
											>
												{({ TransitionProps }) => (
													<Fade
														{...TransitionProps}
														timeout={350}
													>
														<Wrapper>
															<FlexCol
																style={{
																	minWidth: 220
																}}
															>
																<Gutter
																	spacing={
																		0.5
																	}
																/>
																{DISCOUNT_OPTION.map(
																	d => (
																		<Option
																			align={
																				'center'
																			}
																			onClick={() => {
																				onTypeChange(
																					d.value as DiscountType
																				)
																				analytics.track(
																					SEGMENT_EVENTS.INVOICE_SELECT_DISCOUNT_TYPE,
																					{
																						selected_discount_type:
																							d.value
																					}
																				)
																				popupState.close()
																			}}
																			key={
																				d.name
																			}
																		>
																			<Typography
																				variant={
																					'body2'
																				}
																			>
																				{
																					d.name
																				}
																			</Typography>
																		</Option>
																	)
																)}
															</FlexCol>
														</Wrapper>
													</Fade>
												)}
											</Popper>
										</>
									)}
								</PopupState>

								<Row>
									<Typography
										variant={'subtitle2'}
										align={'right'}
										fontWeight={400}
										color={colors['gray']['900']}
									>
										{currencyInput(
											calculateInvoiceDiscountAmount(
												values as any
											) as any
										)}
									</Typography>
									<Gutter gap={0.2} />
									<Icon
										size={25}
										onClick={() => {
											{
												setFieldValue(
													'isDiscountVisible',
													false
												)
												setFieldValue(
													'discount.amount',
													0
												)
											}
										}}
									/>
								</Row>
							</Grid>
							<Gutter spacing={0.5} />
						</>
					)}
					{!values.isDiscountVisible && (
						<Grid>
							<Button
								onClick={() => {
									setFieldValue('isDiscountVisible', true)
									analytics.track(
										SEGMENT_EVENTS.INVOICE_ADD_DISCOUNT
									)
								}}
							>
								<Typography variant={'subtitle2'}>
									Additional Discount
								</Typography>
							</Button>
						</Grid>
					)}
					<Gutter />
				</>
			)}
		</>
	)
}
