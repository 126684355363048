import React, { useCallback, useState } from 'react'
import { FlexCol, FlexRow } from 'components/atoms/Flex'
import { CircularProgress, Skeleton, Typography, useTheme } from '@mui/material'
import type {
	DataResponseType,
	IContactProps,
	InvoiceSettingsType
} from 'typings'
import { styled } from '@mui/system'
import { Gutter, Label } from 'components'
import { MdAddCircle } from 'react-icons/md'
import { useFormikContext } from 'formik'
import type { CurrentInvoiceProps } from 'screen/Invoices/NewCreate/Invoice'
import { useDebouncedCallback } from 'use-debounce'
import { useSettings } from 'context/settings'
import { getContactList } from 'api/contacts'
import { objectPick } from 'helpers'
import { ContactCreateModalInv } from 'screen/Invoices/NewCreate/Components/ContactCreateModalInv'
import { CONTACTS_INITIAL_VALUE, SEGMENT_EVENTS } from 'data'
import { FieldAutoComplete } from 'components/molecules/FieldAutoComplete'
import analytics from 'utils/segment'

const Item = styled(FlexCol)`
	padding: 10px 16px;
	width: 100%;

	:hover {
		background-color: ${({ theme }) => theme.palette.colors.blue['50']};
	}
`

const Row = styled(FlexRow)`
	:hover {
		cursor: pointer;
	}
`

const Container = styled(FlexCol)`
	background-color: ${({ theme }) => theme.palette.colors['gray']['50']};
	width: 100%;
	padding: 16px;
	border-radius: 8px;
	min-width: 300px;
	max-width: 450px;
`

const Grid = styled('div')`
	display: grid;
	grid-template-columns: 0.5fr 1fr;
	column-gap: 20px;
	align-items: center;
	width: 100%;
`

const BottomBar = styled(FlexRow)`
	border-top: 1px solid ${({ theme }) => theme.palette.colors.gray['100']};
	padding: 10px 16px;
	align-items: center;
`

const RenderOption = ({ contact, ...props }: { contact: IContactProps }) => {
	const theme = useTheme()
	const {
		palette: { colors }
	} = theme

	return (
		<Item {...props}>
			<Typography variant={'subtitle1'} fontWeight={500}>
				{contact.name}
			</Typography>
			{contact.phone.number && (
				<Typography
					variant={'caption'}
					fontWeight={400}
					color={colors['gray']['600']}
				>
					+{contact.phone.countryCode}-{contact.phone.number}
				</Typography>
			)}
			<Typography
				variant={'caption'}
				fontWeight={400}
				color={colors['gray']['600']}
				style={{ overflowWrap: 'break-word' }}
			>
				{contact.address}
			</Typography>
		</Item>
	)
}

type ContactDetailsProps = {
	invoiceSettings?: InvoiceSettingsType
}

export const ContactDetails: React.ComponentType<ContactDetailsProps> = ({
	invoiceSettings
}) => {
	const [isOpen, setIsOpen] = useState(false)
	const [loading, setLoading] = useState(true)
	const [search, setSearch] = useState('')
	const [contactList, setContactList] = useState<IContactProps[]>([])
	const { values, setFieldValue } = useFormikContext<CurrentInvoiceProps>()
	const theme = useTheme()
	const { business } = useSettings()
	const {
		palette: { colors }
	} = theme

	const debouncedSearch = useDebouncedCallback(
		text => getBusinessContactList(text),
		200
	)

	const dataSet = [
		{
			id: 0,
			title: 'Phone',
			value: `+${values.contact?.phone.countryCode}-${values.contact?.phone.number}`,
			disabled: !values.contact?.phone.number
		},
		{
			id: 1,
			title: 'Email',
			value: values.contact?.email,
			disabled: !values.contact?.email
		},
		{
			id: 2,
			title: 'Location',
			value: values.contact?.address,
			disabled: !values.contact?.address
		},
		{
			id: 3,
			title: 'NTN',
			value: values.contact?.cnic,
			disabled:
				!values.contact?.cnic ||
				!invoiceSettings?.settings.displayCustomerCnic
		}
	].filter(f => !f.disabled)

	const getBusinessContactList = useCallback(
		async (searchKey: string = '') => {
			try {
				setLoading(true)
				const res: DataResponseType<IContactProps> =
					await getContactList({
						business,
						page: 1,
						limit: 20,
						searchKey,
						sortOrder: 'ASC',
						sortBy: 'name'
					})

				setContactList(res.edges)
			} catch (e: any) {
				//
			} finally {
				setLoading(false)
			}
		},
		[business]
	)

	return (
		<>
			<Label>Customer Name*</Label>
			{values.loading ? (
				<Skeleton variant="rectangular" width={300} height={80} />
			) : (
				<FieldAutoComplete
					placeholder={'eg. Ahmed khan'}
					name={'contact.name'}
					options={contactList}
					renderOption={(props, option) => (
						<RenderOption contact={option} {...props} />
					)}
					onOpen={() => getBusinessContactList()}
					disabled={!!values.id}
					size={'small'}
					footer={
						<BottomBar
							onClick={() => {
								setSearch('')
								setIsOpen(true)
								analytics.track(SEGMENT_EVENTS.ADD_NEW_CONTACT)
							}}
						>
							<MdAddCircle
								color={colors.green['600']}
								size={20}
							/>
							<Gutter gap={1} />
							<Typography variant={'subtitle2'}>
								Create New Contact
							</Typography>
						</BottomBar>
					}
					sx={{ width: '100%', minWidth: 300, maxWidth: 450 }}
					value={values.contact?.name || ''}
					onInputChange={(e, txt) => {
						if (e && e.type !== 'blur') {
							setSearch(txt)
						}
						debouncedSearch(txt)
					}}
					noOptionsText={
						<Row
							onClick={() => {
								if (search) setIsOpen(true)
							}}
							style={{ width: '100%' }}
							justify={!loading ? 'flex-start' : 'center'}
						>
							{loading ? (
								<CircularProgress size={20} />
							) : search ? (
								<div>{`Create "${search}"`} </div>
							) : contactList.length === 0 ? (
								'No data found'
							) : (
								'Something went wrong'
							)}
						</Row>
					}
					onChange={(e, p) => {
						if (p) {
							setFieldValue('ledgerBalance', {
								currency: 'PKR',
								amount: parseFloat(
									p.balances
										.find(
											(f: any) =>
												f.accountName ===
												'accounts_receivables'
										)
										.balance.toFixed(2) || '0'
								)
							})
							setFieldValue(
								'contact',
								objectPick(
									p,
									'address',
									'businessName',
									'businessRelationship',
									'email',
									'id',
									'name',
									'phone',
									'cnic'
								)
							)
						}

						analytics.track(SEGMENT_EVENTS.CONTACT_SELECT_SUCCESS, {
							screen: 'new_invoice'
						})
					}}
					onBlur={() =>
						analytics.track(SEGMENT_EVENTS.SELECT_CONTACT, {
							screen: 'new_invoice'
						})
					}
				/>
			)}

			<Gutter spacing={0.4} />
			{dataSet.length > 0 && (
				<Container>
					{dataSet.map(d => (
						<React.Fragment key={d.id}>
							<Grid>
								<Typography
									variant={'subtitle2'}
									fontWeight={600}
								>
									{d.title}
								</Typography>
								<Typography
									variant={'subtitle2'}
									style={{ overflowWrap: 'break-word' }}
									color={theme.palette.colors.gray['600']}
								>
									{d.value}
								</Typography>
							</Grid>
							<Gutter spacing={0.5} />
						</React.Fragment>
					))}
				</Container>
			)}

			<ContactCreateModalInv
				open={isOpen}
				user={{ ...CONTACTS_INITIAL_VALUE, name: search }}
				onClose={() => setIsOpen(false)}
				onSuccess={res =>
					setFieldValue(
						'contact',
						objectPick(
							res,
							'address',
							'businessName',
							'businessRelationship',
							'email',
							'name',
							'phone',
							'id'
						)
					)
				}
			/>
		</>
	)
}
