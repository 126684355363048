import { call, fork, put, take } from 'redux-saga/effects'
import { FETCH_CBFS_INFO } from 'store/cbfs/types'
import { getProfileStatus } from 'api/cbfs'
import { setCBFSInfo } from 'store/cbfs/actions'
import type { IBusiness } from 'typings'

function* fetchCBFSBusinessInfo(businessId: string) {
	try {
		const res: IBusiness[] = yield call(getProfileStatus, businessId)
		yield put(setCBFSInfo(res))
	} catch (e: any) {
		//
	}
}

function* cbfsWatcher(): any {
	while (true) {
		const everything = yield take([FETCH_CBFS_INFO])
		if (!everything) {
			return
		}

		const { type, businessId } = everything

		switch (type) {
			case FETCH_CBFS_INFO:
				yield fork(() => fetchCBFSBusinessInfo(businessId))
				break
		}
	}
}

export default [cbfsWatcher()]
