import React from 'react'
import { Button, FieldNumberInput, Gutter } from 'components'
import { FlexCol, FlexRow } from 'components/atoms/Flex'
import { useNavigate } from 'react-router-dom'
import { LoginLayout } from './LoginLayout'
import { Typography, useTheme } from '@mui/material'
import { Formik } from 'formik'
import { styled } from '@mui/system'
import { useTranslate } from 'hooks'
import { requestOtp } from 'api'
import type { RequestOtpProps } from 'api/auth'
import * as Yup from 'yup'
import { getAllErrors, phoneNumPatternForLogin, POSITIVE_NUMBER } from 'helpers'
import { useAppErrors } from 'hooks/useAppErrors'
import analytics from 'utils/segment'
import { SEGMENT_EVENTS } from 'data'
import Logo from 'assets/logo.svg'

const Container = styled('div')`
	display: grid;
	width: 100%;
	height: 100vh;
	grid-template-columns: 3fr 4fr;

	@media screen and (max-width: 800px) {
		grid-template-columns: 1fr;
	}
`

const StyledButton = styled(Button)`
	align-self: center;
	border-radius: 7px;
	padding: 12px 20px;
	width: 100%;
`

const Row = styled(FlexCol)`
	flex: 1;
	justify-content: center;
	align-items: center;
	width: 100%;

	@media screen and (max-width: 400px) {
		flex-direction: column;
	}
`

const validationSchema = Yup.object().shape({
	phone: Yup.string()
		.matches(phoneNumPatternForLogin, 'Phone number is not valid')
		.min(10, 'Phone number should be 10 digits long.')
		.max(10, 'Phone number should be 10 digits long.')
})

export const Login = () => {
	const navigate = useNavigate()
	const translate = useTranslate()
	const { setAppError } = useAppErrors()
	const theme = useTheme()
	const { colors } = theme.palette

	const onSubmit = async (payload: RequestOtpProps, actions: any) => {
		try {
			actions.setSubmitting(true)
			analytics.track(SEGMENT_EVENTS.ONBOARDING_LOGIN)
			const res = await requestOtp(payload)
			if (res) {
				analytics.track(SEGMENT_EVENTS.ONBOARDING_LOGIN_SUCCESS)
				localStorage.setItem('phone', payload.phone)
				navigate('/otp-verification', { state: { otp: res } })
			}
		} catch (e: any) {
			if (
				getAllErrors(e).messageErrors.findIndex(
					e =>
						e.message ===
						'Can not re-request a new code until timeout'
				) !== -1
			) {
				navigate('/otp-verification')
			} else {
				setAppError(e)
			}
		} finally {
			actions.setSubmitting(false)
		}
	}

	return (
		<Container>
			<FlexCol style={{ padding: 16 }}>
				<img src={Logo} alt="login" width={70} />
				<Row>
					<Formik
						initialValues={{
							phone: ''
						}}
						onSubmit={async (values, actions) => {
							await onSubmit(
								{
									phone: values.phone.replace(/^0+/, '')
								},
								actions
							)
						}}
						validationSchema={validationSchema}
					>
						{({ handleSubmit, dirty, isSubmitting }) => (
							<FlexCol>
								<Typography
									variant={'h4'}
									color={colors.green['900']}
									alignSelf={'center'}
									fontWeight={500}
									fontFamily="Inter"
								>
									{translate('onboarding.login.welcomeTo')}
								</Typography>

								<Gutter />
								<FlexRow
									align={'center'}
									style={{ width: '100%' }}
									justify={'center'}
								>
									<Typography variant={'body2'}>
										{translate(
											'onboarding.login.loginWithPhone'
										)}
									</Typography>
								</FlexRow>
								<Gutter spacing={1} />

								<FieldNumberInput
									type={'tel'}
									inputProps={{ maxLength: 10 }}
									title={'Phone number'}
									placeholder={'300 1245679'}
									InputProps={{
										startAdornment: (
											<Typography sx={{ marginRight: 1 }}>
												+92
											</Typography>
										)
									}}
									name={'phone'}
									style={{
										width: 350
									}}
									regex={POSITIVE_NUMBER}
									onClick={() =>
										analytics.track(
											SEGMENT_EVENTS.ONBOARDING_PHONE_NUMBER
										)
									}
								/>
								<Gutter spacing={1.5} />
								<StyledButton
									variant={'contained'}
									onClick={() => {
										handleSubmit()
									}}
									type={'submit'}
									disabled={!dirty || isSubmitting}
								>
									{translate('onboarding.login.loginButton')}
								</StyledButton>
							</FlexCol>
						)}
					</Formik>
				</Row>
			</FlexCol>
			<LoginLayout />
		</Container>
	)
}
