import produce from 'immer'
import type { IAPIError, IStockUnits, ITaxUnit } from 'typings'
import {
	FETCH_ALL_STOCK_UNIT_ERROR,
	FETCH_ALL_STOCK_UNIT_SUCCESS,
	FETCH_TAX_ERROR,
	FETCH_TAX_SUCCESS,
	SET_ALL_STOCK_UNIT_LOADING,
	SET_TAX_LOADING,
	type SettingActionTypes
} from 'store/settings/types'

interface SettingState {
	stockUnit: {
		loading: boolean
		dataSource: IStockUnits[]
		errors: IAPIError[]
	}
	taxUnits: {
		loading: boolean
		dataSource: ITaxUnit[]
		errors: IAPIError[]
	}
}

const initialState: SettingState = {
	stockUnit: {
		loading: false,
		dataSource: [],
		errors: []
	},
	taxUnits: {
		loading: false,
		dataSource: [],
		errors: []
	}
}

const reducer = produce((draft: SettingState, action: SettingActionTypes) => {
	switch (action.type) {
		case SET_ALL_STOCK_UNIT_LOADING:
			draft.stockUnit.loading = action.payload
			return

		case FETCH_ALL_STOCK_UNIT_SUCCESS:
			draft.stockUnit.dataSource = action.payload
			draft.stockUnit.errors = []
			return

		case FETCH_ALL_STOCK_UNIT_ERROR:
			draft.stockUnit.errors = action.payload
			return

		case SET_TAX_LOADING:
			draft.taxUnits.loading = action.payload
			return

		case FETCH_TAX_SUCCESS:
			draft.taxUnits.dataSource = action.payload
			draft.taxUnits.errors = []
			return

		case FETCH_TAX_ERROR:
			draft.taxUnits.errors = action.payload
			return

		default:
			return
	}
}, initialState)

export default reducer
