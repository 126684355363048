import React, { useState } from 'react'
import { Button, styled, Typography, useTheme } from '@mui/material'
import { StyledModal } from 'components/StyledModal'
import { FlexCol, FlexRow } from 'components/atoms/Flex'
import { useAppErrors } from 'hooks'
import Warning from 'assets/alerts/warning.png'
import { BouncingDotsLoader, Gutter } from 'components'
import { formatId } from 'helpers'
import type { InvoiceType } from 'typings'
import { enqueueSnackbar } from 'notistack'
import { getSignedUrlForInvoicePdf } from 'api/invoice'
import { TransparentButton } from 'components/atoms/Button/TransparentButton'

const Wrapper = styled('div')`
	width: 520px;
`

const Background = styled('div')`
	padding: 20px;
	border-radius: 10px;
	background-color: ${({ theme }) => theme.palette.background.default};
	margin-top: 20px;
	margin-bottom: 20px;
	overflow-y: auto;
`
const WrapperButton = styled(Button)(({ theme }) => ({
	backgroundColor: theme.palette.colors.green['600'],
	color: theme.palette.colors.white['900'],
	padding: '8px 12px',
	borderRadius: '8px',
	height: '40px',
	'&:hover': {
		background: theme.palette.colors.green['700']
	}
}))

type BulkPrintModalProps = {
	open: boolean
	onClose(): void
	invoices: InvoiceType[]
}

export const BulkPrintModal: React.ComponentType<BulkPrintModalProps> = ({
	onClose,
	open,
	invoices
}) => {
	const invoicesToMap = Math.min(10, invoices.length)

	const { setAppError } = useAppErrors()
	const theme = useTheme()
	const { colors } = theme.palette
	const [isDownloading, setIsDownloading] = useState<boolean>(false)

	const handleDownloadBulkInvoices = async (invoices: InvoiceType[]) => {
		try {
			setIsDownloading(true)
			await getSignedUrlForInvoicePdf(invoices, true)

			setIsDownloading(false)

			// close modal
			onClose()
			enqueueSnackbar(
				'Hang on! We are processing the bulk invoices for print.',
				{
					variant: 'success'
				}
			)
		} catch (e: any) {
			setAppError(e)
		}
	}

	const commaSeparatedInvoiceIds = invoices
		.slice(0, invoicesToMap)
		.map((invoice, idx) => {
			return (
				<Typography
					key={invoice.id}
					variant="caption"
					style={{ display: 'inline' }}
				>
					{idx !== 0 && ', '}
					{`INV-${formatId(invoice.friendlyId)}`}
				</Typography>
			)
		})

	return (
		<StyledModal open={open} onClose={onClose}>
			<Background>
				<Wrapper>
					<FlexCol justify="space-between">
						<FlexRow>
							<img
								alt={'warning'}
								src={Warning}
								style={{
									width: '50px',
									height: '50px'
								}}
							/>
							<Gutter gap={2} />
							<FlexCol style={{ width: '420px' }}>
								<Typography variant={'h6'} fontWeight={600}>
									Bulk Invoice Printing
								</Typography>
								<Typography
									variant="body2"
									color={colors.gray['500']}
								>
									{`You are about to print the following ${invoices.length} invoices:`}
								</Typography>
								<Gutter spacing={0.7} />
								<Typography>
									{commaSeparatedInvoiceIds}{' '}
									{invoices.length > 10 && '...'}
								</Typography>
								<Gutter spacing={0.7} />
								<Typography
									variant="body2"
									color={colors.gray['500']}
								>
									{
										'Please verify that these are the invoices you want to print. Note that depending on the number of invoices, this process may take some time.'
									}
								</Typography>
								<Gutter spacing={2.5} />
								<FlexRow
									justify="flex-end"
									style={{ width: '100%' }}
								>
									<TransparentButton
										onClick={() => onClose?.()}
									>
										Cancel
									</TransparentButton>
									<Gutter gap={0.5} />

									<WrapperButton
										onClick={() =>
											handleDownloadBulkInvoices(invoices)
										}
										disabled={isDownloading}
									>
										{!isDownloading ? (
											'Print'
										) : (
											<BouncingDotsLoader />
										)}
									</WrapperButton>
								</FlexRow>
							</FlexCol>
						</FlexRow>
					</FlexCol>
				</Wrapper>
			</Background>
		</StyledModal>
	)
}
