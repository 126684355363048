import type { IAPIError, IStockUnits, ITaxUnit } from 'typings'

export const SET_ALL_STOCK_UNIT_LOADING = 'SET_ALL_STOCK_UNIT_LOADING'
export const FETCH_ALL_STOCK_UNIT_SUCCESS = 'FETCH_ALL_STOCK_UNIT_SUCCESS'
export const FETCH_ALL_STOCK_UNIT_ERROR = 'FETCH_ALL_STOCK_UNIT_ERROR'
export const FETCH_ALL_STOCK_UNIT = 'FETCH_ALL_STOCK_UNIT'
export const SET_TAX_LOADING = 'SET_TAX_LOADING'
export const FETCH_TAX_SUCCESS = 'FETCH_TAX_SUCCESS'
export const FETCH_TAX_ERROR = 'FETCH_TAX_ERROR'
export const FETCH_TAXES = 'FETCH_TAXES'

interface IFetchStockUnitLoading {
	type: typeof SET_ALL_STOCK_UNIT_LOADING
	payload: boolean
}

interface IFetchStockUnitSuccess {
	type: typeof FETCH_ALL_STOCK_UNIT_SUCCESS
	payload: IStockUnits[]
}

interface IFetchStockUnitError {
	type: typeof FETCH_ALL_STOCK_UNIT_ERROR
	payload: IAPIError[]
}

interface IFetchAllStockUnit {
	type: typeof FETCH_ALL_STOCK_UNIT
}

interface IFetchTaxUnitLoading {
	type: typeof SET_TAX_LOADING
	payload: boolean
}

interface IFetchTaxUnitSuccess {
	type: typeof FETCH_TAX_SUCCESS
	payload: ITaxUnit[]
}

interface IFetchTaxUnitError {
	type: typeof FETCH_TAX_ERROR
	payload: IAPIError[]
}

interface IFetchTaxUnit {
	type: typeof FETCH_TAXES
}

export type SettingActionTypes =
	| IFetchStockUnitLoading
	| IFetchStockUnitError
	| IFetchStockUnitSuccess
	| IFetchAllStockUnit
	| IFetchTaxUnit
	| IFetchTaxUnitLoading
	| IFetchTaxUnitError
	| IFetchTaxUnitSuccess
