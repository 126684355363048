import React, { useCallback } from 'react'
import { FlexCol, FlexRow } from 'components/atoms/Flex'
import { styled } from '@mui/system'
import { Button, Modal, Typography, useTheme } from '@mui/material'
import { Gutter, ModalWrapper } from 'components'
import { HiOutlineDocument } from 'react-icons/hi'
import { IoIosClose } from 'react-icons/io'
import jsPDF from 'jspdf'
import { objectPick } from 'helpers/object'
import { pdfPageConfig } from 'data/pdfPageConfig'
import analytics from 'utils/segment'
import { SEGMENT_EVENTS } from 'data'
import html2canvas from 'html2canvas'

const Wrapper = styled(FlexRow)`
	padding: 30px 20px;
	border-radius: 10px;
	background-color: ${({ theme }) => theme.palette.colors.white['900']};
	max-width: 550px;
	position: relative;
`

const Box = styled(FlexRow)`
	padding: 12px;
	margin-top: 5px;
	border-radius: 7px;
	background-color: ${({ theme }) => theme.palette.colors.green['50']};
`

const CloseButtonWrapper = styled(FlexCol)`
	position: absolute;
	top: 10px;
	right: 10px;
	justify-content: center;
	align-items: center;
	height: 30px;
	width: 30px;
	&:hover {
		cursor: pointer;
		border-radius: 25%;
		background-color: ${({ theme }) => theme.palette.colors.gray['100']};
		display: flex;
	}
`

const TransparentButton = styled(Button)`
	background-color: ${({ theme }) => theme.palette.background.default};
	border: 1px solid ${({ theme }) => theme.palette.colors.gray['300']};
	color: ${({ theme }) => theme.palette.colors.gray['700']};
	border-radius: 10px;
	padding: 7px 3px;
	width: 100%;
`

const Grid = styled('div')`
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 10px;
	width: 100%;
`

type Props = {
	open: boolean
	onClose: () => void
	divRef: React.RefObject<HTMLDivElement>
	id?: string
}

export const DownloadQuotation: React.ComponentType<Props> = ({
	open,
	onClose,
	id,
	divRef
}) => {
	const theme = useTheme()
	const { colors } = theme.palette

	const handleGeneratePdf = useCallback(() => {
		const doc = new jsPDF({
			...objectPick(pdfPageConfig.A4, 'format', 'unit', 'orientation')
		})

		if (divRef !== null) {
			doc.html(divRef.current as any, {
				...objectPick(pdfPageConfig.A4, 'width', 'height'),
				windowWidth: pdfPageConfig.A4.width,
				margin: [10, 0, 50, 0],
				autoPaging: true,
				html2canvas: {
					backgroundColor: '#999'
				},
				async callback(doc) {
					doc.setFontSize(5)
					await doc.save(`quotation-${id}`)
				}
			})
		}

		analytics.track(SEGMENT_EVENTS.PDF_QUO_SHARE, {
			channel_selected: 'Download'
		})

		onClose()
	}, [divRef, onClose, id])

	const generateImage = useCallback(() => {
		if (divRef.current) {
			html2canvas(divRef.current, {
				windowWidth: 2000,
				windowHeight: 8000,
				height: divRef.current.scrollHeight,
				scrollX: 0,
				scrollY: -window.scrollY
			}).then(canvas => {
				const image = canvas.toDataURL('image/png')
				const link = document.createElement('a')
				link.href = image
				link.download = `Quotation-${id}.jpg`
				link.click()
			})
		}
		onClose()
	}, [divRef, id, onClose])

	return (
		<Modal open={open} onClose={onClose}>
			<ModalWrapper>
				<Wrapper>
					<CloseButtonWrapper>
						<IoIosClose size={25} onClick={onClose} />
					</CloseButtonWrapper>
					<Box>
						<HiOutlineDocument
							color={colors.green['500']}
							size={22}
						/>
					</Box>
					<Gutter gap={1.5} />
					<FlexCol style={{ flex: 1 }}>
						<FlexRow>
							<Typography variant="h6" fontWeight={600}>
								Download Quotation as...
							</Typography>
						</FlexRow>
						<Typography
							variant="body2"
							color={colors.gray['400']}
							fontWeight={500}
						>
							Do you want to download this invoice as PDF document
							or a png image?
						</Typography>
						<Gutter spacing={2.2} />
						<Grid>
							<TransparentButton
								onClick={generateImage}
								//disabled
							>
								<Typography
									variant="subtitle1"
									fontWeight={500}
								>
									Image{' '}
									{/*<Typography
										variant={'caption'}
										fontWeight={500}
										style={{
											backgroundColor: '#EFF8FF',
											padding: 2,
											borderRadius: 5
										}}
									>
										Comming soon
									</Typography>*/}
								</Typography>
							</TransparentButton>
							<TransparentButton onClick={handleGeneratePdf}>
								<Typography
									variant="subtitle1"
									fontWeight={500}
								>
									Pdf
								</Typography>
							</TransparentButton>
						</Grid>
					</FlexCol>
				</Wrapper>
			</ModalWrapper>
		</Modal>
	)
}
