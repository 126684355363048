import React, { useState } from 'react'
import { FlexRow } from 'components/atoms/Flex'
import { styled } from '@mui/system'
import { Button, Gutter, PageTitle } from 'components'
import { TransparentButton } from 'components/atoms/Button/TransparentButton'
import { SelectQuotationModal } from 'components/app/QuotationModal'
import { useFormikContext } from 'formik'
import {
	GENERATE_INVOICE_DATA,
	LAYOUT_BREAKPOINT_WIDTH,
	LAYOUT_PADDING,
	SEGMENT_EVENTS
} from 'data'
import { objectOmit } from 'helpers'
import { MdOutlineFileDownload, MdOutlineSave } from 'react-icons/md'
import { Typography } from '@mui/material'
import type { InvoiceType } from 'typings'
import { checkAvailableStock } from 'api'
import analytics from 'utils/segment'

const Row = styled(FlexRow)`
	position: sticky;
	top: 0;
	width: 100%;
	max-height: 56px;
	height: 56px;
	align-items: center;
	background-color: ${({ theme }) => theme.palette.background.default};
	border-bottom: 1px solid ${({ theme }) => theme.palette.colors.gray['200']};
	z-index: 10;
	padding: 0 ${LAYOUT_PADDING}px;
`

const SaveButton = styled(Button)`
	background-color: ${({ theme }) => theme.palette.colors.yellow['300']};
	color: ${({ theme }) => theme.palette.colors.gray['900']};
	border-radius: 8px;
	min-width: 38px;
	min-height: 38px;
	padding: 0 12px;

	:hover {
		background-color: ${({ theme }) => theme.palette.colors.yellow['200']};
	}
`

const StyledGutter = styled(Gutter)`
	@media (max-width: ${LAYOUT_BREAKPOINT_WIDTH}px) {
		display: none;
	}
`

const Text = styled(Typography)`
	@media (max-width: ${LAYOUT_BREAKPOINT_WIDTH}px) {
		display: none;
	}
`

// Replace any with proper format
export const Header = ({ businessInfo }: { businessInfo: any }) => {
	const [open, setOpen] = useState(false)
	const { isSubmitting, resetForm, handleSubmit, values } =
		useFormikContext<any>()

	const getAvailableStock = async (inv: InvoiceType) => {
		try {
			const stockIds = inv.items.map(itm => {
				return itm.stockId
			})
			const stockValues = await checkAvailableStock(stockIds as string[])

			inv.items.map(d => {
				stockValues[`${d?.stockId}`] = stockValues[d.stockId as string]
			})
			return stockValues
		} catch (e) {
			//
		}
	}

	return (
		<>
			<Row justify={'space-between'}>
				<PageTitle>
					{values.id ? 'Edit Invoice' : 'Create New Invoice'}
				</PageTitle>
				<FlexRow>
					<TransparentButton
						onClick={() => {
							setOpen(true)
							analytics.track(
								SEGMENT_EVENTS.INVOICE_QUOTATION_IMPORT,
								{ screen: 'new_invoice' }
							)
						}}
						disabled={values.id || values.loading}
					>
						<MdOutlineFileDownload size={16} />
						<StyledGutter gap={0.5} />
						<Text variant={'caption'} fontWeight={600}>
							Import from Quotation
						</Text>
					</TransparentButton>
					<Gutter gap={1} />
					<SaveButton
						disabled={isSubmitting || values.loading}
						onClick={() => handleSubmit()}
					>
						<MdOutlineSave size={16} />
						<StyledGutter gap={0.5} />
						<Text variant={'caption'} fontWeight={600}>
							Save Invoice
						</Text>
					</SaveButton>
				</FlexRow>
			</Row>
			<SelectQuotationModal
				open={open}
				onClose={() => {
					setOpen(false)
				}}
				onQuotationSelect={async quotation => {
					const stockValues = await getAvailableStock(
						quotation as any
					)
					resetForm({
						values: GENERATE_INVOICE_DATA({
							...objectOmit(quotation, 'id'),
							dueAmount: 0,
							internalComments: [],
							paymentStatus: 'pending',
							pdfKey: null,
							businessInfo,
							initialValue: stockValues
						})
					})
					setOpen(false)
				}}
			/>
		</>
	)
}
