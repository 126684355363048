import React, { useState } from 'react'
import { Drawer, Paper, Typography, useTheme } from '@mui/material'
import { styled } from '@mui/system'
import { TableVirtuoso } from 'react-virtuoso'
import { FlexCol, FlexRow } from 'components/atoms/Flex'
import { TableHeader } from './Table/TableHeader'
import { useDimension } from 'hooks'
import { UserRoleTableComponent } from 'screen/Settting/UserRoles/Table/UserRolesTableComponent'
import { RoleItem } from 'screen/Settting/UserRoles/Table/TableItem'
import type { InviteUserProps, Roles } from 'typings'
import { ReactivateModal } from 'screen/Settting/UserRoles/ReactivateModal'
import { DeactivateModal } from 'screen/Settting/UserRoles/DeactivateModal'
import { AddUser } from 'screen/Settting/UserRoles/InviteUser'

const StyledPaper = styled(Paper)`
	position: relative;
	width: 100%;
	height: 100%;
	background: ${({ theme }) => theme.palette.colors.white['500']};
	z-index: 0;
`

const Table = styled(TableVirtuoso)`
	width: 100%;
	background: ${({ theme }) => theme.palette.colors.white['500']};
`

const AbsoluteWrapper = styled(FlexRow)`
	width: 100%;
	z-index: 1;
	align-items: center;
	justify-content: center;
	position: absolute;
	height: 100%;
`

const Wrapper = styled(FlexCol)<{ height: number }>`
	background-color: ${({ theme }) => theme.palette.background.default};
	width: -webkit-fill-available;
	border-radius: 8px;
	border: 1px solid ${({ theme }) => theme.palette.colors.gray['300']};
	box-shadow: 0 4px 6px -2px rgba(16, 24, 40, 0.03),
		0 12px 16px -4px rgba(16, 24, 40, 0.08);
	overflow: hidden;
	margin: 0 7px;

	min-height: ${({ height }) => height - 170}px;

	@media screen and (max-width: 600px) {
		min-height: ${({ height }) => height - 220}px;
	}
`

type InviteUserTableProps = {
	searchKey: string
	dataSource: InviteUserProps[]
	fetchUserList?(): void
	isActive?: boolean
	onSuccess?(): void
	role?: Roles
}

export const UserRolesTable: React.ComponentType<InviteUserTableProps> = ({
	searchKey,
	dataSource,
	fetchUserList,
	onSuccess,
	isActive,
	role
}) => {
	const [selectedModal, setSelectedModal] = useState<
		InviteUserProps | undefined
	>()
	const [deactivateModal, setDeActivateModal] = useState<
		InviteUserProps | undefined
	>()
	const [editUser, setEditUser] = useState<InviteUserProps | undefined>()
	const { height } = useDimension()
	const theme = useTheme()
	const { colors } = theme.palette

	return (
		<Wrapper height={height}>
			<StyledPaper>
				{searchKey && dataSource.length === 0 && (
					<AbsoluteWrapper>
						<Typography>
							No entry found for &quot;{`${searchKey}`}&quot;
						</Typography>
					</AbsoluteWrapper>
				)}
				{!searchKey && dataSource.length === 0 && (
					<AbsoluteWrapper>
						<FlexCol justify="center" align="center">
							<Typography
								variant="subtitle2"
								color={colors.gray['600']}
							>
								No user to display
							</Typography>
						</FlexCol>
					</AbsoluteWrapper>
				)}
				<Table
					data={dataSource}
					components={UserRoleTableComponent}
					fixedHeaderContent={TableHeader}
					itemContent={(_index: number, row: any) => (
						<RoleItem
							{...row}
							isActive={isActive}
							onReactivateClick={item => {
								setSelectedModal(item)
							}}
							onDeactivateClick={item => {
								setDeActivateModal(item)
							}}
							onEditClick={item => {
								setEditUser(item)
							}}
							role={role}
						/>
					)}
					endReached={() => {
						fetchUserList?.()
					}}
					//onScroll={onScroll}
				/>
			</StyledPaper>
			{selectedModal && (
				<ReactivateModal
					open={!!selectedModal}
					onClose={() => setSelectedModal(undefined)}
					onSuccess={onSuccess}
					{...selectedModal}
				/>
			)}
			{deactivateModal && (
				<DeactivateModal
					open={!!deactivateModal}
					onClose={() => setDeActivateModal(undefined)}
					onSuccess={onSuccess}
					{...deactivateModal}
				/>
			)}
			<React.Fragment key={'right'}>
				<Drawer
					anchor={'right'}
					open={!!editUser}
					onClose={() => setEditUser(undefined)}
				>
					{editUser && (
						<AddUser
							onClose={() => setEditUser(undefined)}
							user={{
								phone: editUser.phone as any,
								business: editUser.business.id,
								fullName: editUser.fullName,
								businessRole: editUser.businessRole,
								id: editUser.id,
								activate: editUser.activate
							}}
							inactive={!isActive}
							onSuccess={onSuccess}
						/>
					)}
				</Drawer>
			</React.Fragment>
		</Wrapper>
	)
}
