import React from 'react'
import { type FilterObjProps, Gutter } from 'components'
import { FlexCol, FlexRow } from 'components/atoms/Flex'
import {
	ClickAwayListener,
	Fade,
	Popper,
	styled,
	Typography
} from '@mui/material'
import PopupState, { bindPopper, bindToggle } from 'material-ui-popup-state'
import { BiDotsVerticalRounded } from 'react-icons/bi'
import analytics from 'utils/segment'
import { SEGMENT_EVENTS } from 'data'

const Wrapper = styled(FlexCol)`
	background-color: ${({ theme }) => theme.palette.background.default};
	border: 1px solid ${({ theme }) => theme.palette.background.paper};
	border-radius: 8px;
	margin-top: 5px;
	box-shadow: 0 12px 16px -4px rgba(16, 24, 40, 0.08),
		0px 4px 6px -2px rgba(16, 24, 40, 0.03);
`

const Option = styled(FlexRow)`
	padding: 8px 14px;
	width: 100%;

	:hover {
		cursor: pointer;
	}
`

const VerticalThreeDots = styled(BiDotsVerticalRounded)`
	cursor: pointer;
`

type KababMenuProps = {
	isRecycleBin: boolean
	bulkPrint: React.Dispatch<React.SetStateAction<boolean>>
	bulkPublish: React.Dispatch<React.SetStateAction<boolean>>
	selectedRows: React.Dispatch<React.SetStateAction<any[]>>
	onSelect(filterObj: FilterObjProps): void
}

export const KababMenu: React.ComponentType<KababMenuProps> = ({
	isRecycleBin,
	bulkPrint,
	bulkPublish,
	onSelect,
	selectedRows
}) => {
	return (
		<PopupState
			variant="popper"
			popupId="demo-popup-popper"
			disableAutoFocus={true}
		>
			{popupState => (
				<ClickAwayListener onClickAway={() => popupState.close()}>
					<div>
						<VerticalThreeDots {...bindToggle(popupState)} />
						<Popper
							{...bindPopper(popupState)}
							transition
							placement="bottom-start"
						>
							{({ TransitionProps }) => (
								<Fade {...TransitionProps} timeout={350}>
									<Wrapper>
										{!isRecycleBin && (
											<>
												<FlexCol
													style={{ minWidth: 220 }}
												>
													<Gutter spacing={0.5} />
													<Option
														align={'center'}
														onClick={() => {
															popupState.close()
															bulkPublish(false)
															bulkPrint(true)
															selectedRows([])
															onSelect({
																status: []
															})
														}}
													>
														<Typography
															variant={'body2'}
														>
															Bulk Print
														</Typography>
													</Option>
												</FlexCol>
												<FlexCol
													style={{ minWidth: 220 }}
												>
													<Gutter spacing={0.5} />
													<Option
														align={'center'}
														onClick={() => {
															analytics.track(SEGMENT_EVENTS.INVOICE_SELECT_BULK_PUBLISH_ACTION)
															popupState.close()
															bulkPrint(false)
															bulkPublish(true)
															selectedRows([])
															onSelect({
																status: [
																	'draft'
																]
															})
														}}
													>
														<Typography
															variant={'body2'}
														>
															Bulk Publish
														</Typography>
													</Option>
												</FlexCol>
											</>
										)}
									</Wrapper>
								</Fade>
							)}
						</Popper>
					</div>
				</ClickAwayListener>
			)}
		</PopupState>
	)
}
