import React, { useCallback, useEffect } from 'react'
import { Typography } from '@mui/material'
import { styled } from '@mui/system'
import { Gutter } from 'components'
import { FlexCol } from 'components/atoms/Flex'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import { getOrderFormSettings, updateOrderFormSettings } from 'api/setting'
import { useSettings } from 'context/settings'
import { useAppErrors } from 'hooks'
import { Header } from '../Header'
import { LAYOUT_PADDING } from 'data'

const Wrapper = styled(FlexCol)`
	width: 100%;
	height: 100%;
`

const Container = styled(FlexCol)`
	width: 100%;
	padding: ${LAYOUT_PADDING}px;
`

export const SettingOrderForm = () => {
	const [orderFormSettingId, setOrderFormSettingId] = React.useState('')
	const { business } = useSettings()
	const [settings, setSettings] = React.useState(null)
	const [checkedStates, setCheckedStates] = React.useState({
		quantityChecked: true,
		sellingPriceChecked: false,
		itemLevelDiscChecked: false
	})

	const { setAppError } = useAppErrors()

	const getInitialOrderFormSetting = useCallback(async () => {
		try {
			const orderFormSettings = await getOrderFormSettings(business)
			// TODO: we'll update this later
			setOrderFormSettingId(orderFormSettings.id)
			const settings = orderFormSettings.settings
			setSettings(settings)
			setCheckedStates({
				quantityChecked: settings.displayStockQuantity,
				sellingPriceChecked: settings.displaySellingPrice,
				itemLevelDiscChecked: settings?.displayItemLevelDiscountOB || false
			})
		} catch (error: any) {
			setAppError(error)
		}
	}, [business, setAppError])

	useEffect(() => {
		getInitialOrderFormSetting()
	}, [getInitialOrderFormSetting])

	const handleQuantityChange = async (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		try {
			const checked = event.target.checked

			setCheckedStates(prevState => ({
				...prevState,
				quantityChecked: checked
			}))
			const settingsObj = {
				settings: {
					displayStockQuantity: checked,
					displaySellingPrice: checkedStates.sellingPriceChecked,
					displayItemLevelDiscountOB: checkedStates.itemLevelDiscChecked
				}
			}
			await updateOrderFormSettings(settingsObj, orderFormSettingId)
		} catch (error: any) {
			setAppError(error)
		}
	}

	const handleSellingPriceChange = async (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		try {
			const checked = event.target.checked

			setCheckedStates(prevState => ({
				...prevState,
				sellingPriceChecked: checked
			}))
			const settingsObj = {
				settings: {
					displaySellingPrice: checked,
					displayStockQuantity: checkedStates.quantityChecked,
					displayItemLevelDiscountOB: checkedStates.itemLevelDiscChecked
				}
			}
			await updateOrderFormSettings(settingsObj, orderFormSettingId)
		} catch (error: any) {
			setAppError(error)
		}
	}

	const handleItemLevelDiscChange = async (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		try {
			const checked = event.target.checked

			setCheckedStates(prevState => ({
				...prevState,
				itemLevelDiscChecked: checked
			}))
			const settingsObj = {
				settings: {
					displayItemLevelDiscountOB: checked,
					displaySellingPrice: checkedStates.sellingPriceChecked,
					displayStockQuantity: checkedStates.quantityChecked,
				}
			}
			await updateOrderFormSettings(settingsObj, orderFormSettingId)
		} catch (error: any) {
			setAppError(error)
		}
	}

	return (
		<Wrapper>
			<Header headerText="Manage Order Form" />
			{settings && (
				<Container>
					<FormGroup>
						<FormControlLabel
							control={
								<Switch
									checked={checkedStates.quantityChecked}
									color="primary"
									onChange={handleQuantityChange}
								/>
							}
							label="Display item stock quantity"
						/>
						<Typography variant="subtitle2" fontWeight={'regular'}>
							{checkedStates.quantityChecked ? 'D' : 'Not d'}
							isplaying item stock quantity to the customers in
							the order form.
						</Typography>
					</FormGroup>
					<Gutter spacing={0.8} />
					<FormGroup>
						<FormControlLabel
							control={
								<Switch
									checked={checkedStates.sellingPriceChecked}
									color="primary"
									onChange={handleSellingPriceChange}
								/>
							}
							label="Display item selling price"
						/>
						<Typography variant="subtitle2" fontWeight={'regular'}>
							{checkedStates.sellingPriceChecked ? 'D' : 'Not d'}
							isplaying item selling prices to the customers in
							the order form.
						</Typography>
					</FormGroup>

					<Gutter spacing={2} />

					<Typography fontWeight={600}>Order Booker Form</Typography>

					<Gutter spacing={1} />

					<FormGroup>
						<FormControlLabel
							control={
								<Switch
									checked={checkedStates.itemLevelDiscChecked}
									color="primary"
									onChange={handleItemLevelDiscChange}
								/>
							}
							label="Allow line item discount"
						/>
						<Typography variant="subtitle2" fontWeight={'regular'}>
							The order bookers can input discounts on items at the time of recording orders.
						</Typography>
					</FormGroup>
				</Container>
			)}
		</Wrapper>
	)
}
