import * as React from 'react'
import { TableCell, TableRow, Typography } from '@mui/material'
import { styled } from '@mui/system'

const Cell = styled(TableCell)`
	background-color: ${({ theme }) => theme.palette.colors.gray['50']};
	color: ${({ theme }) => theme.palette.colors.gray['500']};
	padding: 7px 7px 7px 24px;
`

const Text = styled(Typography)`
	color: ${({ theme }) => theme.palette.colors.gray['500']};
	font-weight: 500;
`

const SmallCell = styled(TableCell, {
	shouldForwardProp: props => props !== 'isVoid'
})`
	background-color: ${({ theme }) => theme.palette.colors.gray['50']};
	color: ${({ theme }) => theme.palette.colors.gray['500']};
	padding: 7px 7px 7px 24px;
	min-width: 150px;
	max-width: 200px;
`

export const TableHeader = () => {
	return (
		<TableRow>
			<SmallCell variant="head" align={'left'}>
				<Text variant={'subtitle1'} fontWeight={500}>
					Load Sheet ID
				</Text>
			</SmallCell>
			<SmallCell variant="head" align={'left'}>
				<Text variant={'subtitle1'} fontWeight={500}>
					No. of Invoices
				</Text>
			</SmallCell>
			<Cell variant="head" align={'left'}>
				<Text variant={'subtitle1'} fontWeight={500}>
					Stock
				</Text>
			</Cell>
			<Cell variant="head" align={'right'} sx={{ paddingRight: '24px' }}>
				<Text variant={'subtitle1'} fontWeight={500}>
					Amount Due
				</Text>
			</Cell>
			<Cell variant="head" align={'right'} sx={{ paddingRight: '24px' }}>
				<Text variant={'subtitle1'} fontWeight={500}>
					Date Dispatched
				</Text>
			</Cell>
			<Cell variant="head" align={'right'} sx={{ paddingRight: '24px' }}>
				<Text variant={'subtitle1'} fontWeight={500}>
					Date Created
				</Text>
			</Cell>
			<Cell
				variant="head"
				align={'right'}
				sx={{ paddingRight: '24px' }}
			/>
		</TableRow>
	)
}
