import React, { type Dispatch, type SetStateAction } from 'react'
import { Gutter, StyledModal } from 'components'
import type { ModalProps } from '@mui/material'
import { Typography, useTheme } from '@mui/material'
import { styled } from '@mui/system'
import { FlexCol, FlexRow } from 'components/atoms/Flex'
import { CustomRadioGroup } from 'components/atoms/RadioGroup'
import { INVOICE_RETURN_INVENTORY_MODAL } from 'data'
import Delete from 'assets/alerts/delete.png'
import { TransparentButton } from 'components/atoms/Button/TransparentButton'

const Container = styled(FlexCol)`
	align-items: flex-end;
	background-color: ${({ theme }) => theme.palette.background.default};
	padding: 20px 40px 20px 30px;
	z-index: 0;
	border-radius: 10px;
`

const Col = styled(FlexCol)`
	min-width: 300px;
	max-width: 400px;
`

const Row = styled(FlexRow)`
	width: 100%;
`

const StyledButton = styled(TransparentButton)`
	background-color: ${({ theme }) => theme.palette.colors.red['600']};

	:hover {
		background-color: ${({ theme }) => theme.palette.colors.red['700']};
	}
`

type ReturnInventoryModalProps = {
	returnInventorySelected: string
	onCancel: () => void
	setReturnInventorySelected: Dispatch<SetStateAction<string>>
} & Omit<ModalProps, 'children'>

export const ReturnInventoryModal: React.ComponentType<
	ReturnInventoryModalProps
> = ({
	onCancel,
	onClose,
	returnInventorySelected,
	setReturnInventorySelected,
	...props
}) => {
	const theme = useTheme()
	const { colors } = theme.palette

	const onReturn = async () => {
		onClose?.({}, 'escapeKeyDown')
	}

	return (
		<StyledModal {...props}>
			<Container>
				<FlexRow>
					<img
						alt={'delete'}
						src={Delete}
						style={{ width: '50px', height: '50px' }}
					/>
					<Gutter gap={1} />
					<Col>
						<FlexRow align={'center'}>
							<Typography variant="body1" fontWeight={'medium'}>
								Void Invoice
							</Typography>
						</FlexRow>
						<Gutter spacing={0.5} />
						<Typography
							color={colors.gray['600']}
							fontWeight={'regular'}
							variant={'body2'}
						>
							Are you sure you want to void this invoice?
						</Typography>
						<Gutter spacing={1} />
						<Typography
							color={colors.gray['600']}
							fontWeight={'regular'}
							variant={'body2'}
						>
							Once voided, the invoice cannot be edited and can
							only be reissued.
						</Typography>
						<Gutter spacing={1} />
						<Typography
							color={colors.gray['600']}
							fontWeight={'regular'}
							variant={'body2'}
						>
							Do you want the referenced items to be returned to
							the inventory?
						</Typography>

						<CustomRadioGroup
							options={INVOICE_RETURN_INVENTORY_MODAL}
							defaultValue={returnInventorySelected}
							onChange={(
								event: React.ChangeEvent<HTMLInputElement>
							) => {
								setReturnInventorySelected(event?.target.value)
							}}
							style={{
								display: 'flex',
								flexDirection: 'row'
							}}
						/>
						<Gutter spacing={2} />
						<Row justify={'flex-end'}>
							<TransparentButton onClick={() => onCancel()}>
								<Typography
									fontWeight={500}
									variant={'caption'}
								>
									Cancel
								</Typography>
							</TransparentButton>
							<StyledButton
								onClick={() => {
									onReturn()
								}}
							>
								<Typography
									fontWeight={500}
									variant={'caption'}
									color={colors.white['200']}
								>
									Confirm
								</Typography>
							</StyledButton>
						</Row>
					</Col>
				</FlexRow>
			</Container>
		</StyledModal>
	)
}
