import type { TableComponents } from 'react-virtuoso'
import * as React from 'react'
import {
	Table,
	TableBody,
	TableContainer,
	TableHead,
	TableRow
} from '@mui/material'
import Paper from '@mui/material/Paper'
import { Gutter } from 'components'

export const HTableComponents: TableComponents<any> = {
	Scroller: React.forwardRef<HTMLDivElement>((props, ref) => (
		<TableContainer component={Paper} {...props} ref={ref} />
	)),
	Table: props => (
		<Table
			{...props}
			sx={{
				borderCollapse: 'separate',
				tableLayout: 'fixed'
			}}
		/>
	),
	TableHead,
	TableRow: ({ item: _item, ...props }) => (
		<TableRow
			{...props}
			style={{
				backgroundColor: 'white',
				paddingBottom: 30
			}}
		/>
	),
	TableBody: React.forwardRef<HTMLTableSectionElement>((props, ref) => (
		<TableBody {...props} ref={ref} />
	)),
	TableFoot: () => <Gutter />
}

if (HTableComponents.Scroller)
	HTableComponents.Scroller.displayName = 'Scroller'
if (HTableComponents.Table) HTableComponents.Table.displayName = 'Table'
if (HTableComponents.TableRow)
	HTableComponents.TableRow.displayName = 'TableRow'
if (HTableComponents.TableBody)
	HTableComponents.TableBody.displayName = 'TableBody'
