export const SEGMENT_EVENTS = {
	// GLOBAL
	SELECT_CONTACT: 'click_selectContact',
	SELECT_CONTACT_SUCCESS: 'complete_selectContact',
	ADD_ITEMS: 'click_addItems',
	ADD_NEW_ITEM: 'start_addNewItem',
	ADD_NEW_ITEM_SUCCESS: 'success_addItem',
	SEARCH_CONTACT: 'click_searchContacts',
	ADD_NEW_CONTACT: 'click_addNewContact',
	CLICK_CONTINUE: 'click_continue',
	CLICK_BACK: 'click_selectBack',
	ADD_PAYMENT_INFO: 'click_addPaymentInformation',
	ADD_PAYMENT_INFO_COMPLETED: 'complete_addPaymentInformation',
	SHARE_WHATSAPP: 'click_shareWhatsapp',
	RECYCLEBIN: 'click_openRecycleBin',
	BUSINESS_PICTURE_UPLOAD_CLICKED: 'click_uploadBusinessPicture',

	// ADD USER
	ADD_USER: 'click_addUser',

	// ORDER CENTER
	NEW_ORDER_INQUIRY: 'click_recordNewOrderInquiry',
	ORDER_KEBAB_MENU: 'click_selectOrderInquiryKebabMenu',
	DELETE_ORDER: 'click_deleteOrderInquiry',
	ORDER_CENTER_KEBAB_MENU: 'click_selectOrderCenterKebabMenu',
	ORDER_CENTER_KEBAB_MENU_OPTION: 'click_selectOrderCenterKebabMenuOption',
	ORDER_GENERATE: 'click_selectGenerate',
	ORDER_GENERATE_SUCCESS: 'success_selectGenerate',
	ORDER_MULTIPLE_DELETE: 'click_deleteMultipleOrderInquiries',
	NEW_INQUIRY: 'click_recordNewInquiry',
	NEW_ORDER: 'click_recordNewOrder',
	ORDER_RECEIVING_CHANNEL: 'click_selectOrderInquiryReceivingChannel',
	ORDER_COMMENT: 'clickOrderInquiryComments',
	COMPLETE_NEW_ORDER_INQUIRY: 'complete_recordNewOrderInquiry',

	// INVENTORY
	INVENTORY_EDIT_COLUMN: 'click_editInventoryColumns',
	INVENTORY_FILTER_BY: 'click_filterInventoryBy',
	INVENTORY_FILTER_BY_LABEL: 'click_filterByLabels',
	INVENTORY_SEARCH: 'click_searchInventoryItems',
	INVENTORY_RESTOCK: 'click_restockInventoryItem',
	INVENTORY_EDIT: 'click_editInventoryItem',
	TOGGLE_INV_COL_LABELS: 'toggle_inventoryColumnLabels',
	TOGGLE_INV_COL_IN_STOCK: 'toggle_inventoryColumnInStock',
	TOGGLE_INV_COL_COST_PRICE: 'toggle_inventoryColumnCostPrice',
	TOGGLE_INV_COL_SELLING_PRICE: 'toggle_inventoryColumnSellingPrice',
	TOGGLE_INV_COL_DATE_STOCKED: 'toggle_inventoryColumnDateStocked',
	INVENTORY_ADD_NEW_ITEM_DROPDOWN: 'click_addNewItemDropdown',
	INVENTORY_ADD_NEW_ITEM: 'start_addNewItem',
	INVENTORY_ADD: 'complete_addNewItem',
	INVENTORY_CLOSE_ADD_NEW_ITEM: 'click_closeAddNewItem',
	INVENTORY_RESTOCK_DONE: 'complete_restockExistingItem',

	// CONTACTS
	CONTACTBOOK: 'click_viewContactBook',
	CONTACT_SEARCH: 'click_searchContacts',
	CONTACT_FILTER: 'click_filterContacts',
	CONTACT_ADD_NEW: 'start_addNewContact',
	CONTACT_VIEW_DETAILS: 'click_viewContactDetails',
	CONTACT_FILTER_BY: 'click_selectFilterContacts',
	CONTACT_ADD: 'complete_addNewContact',
	CONTACT_CLOSE_ADD_NEW: 'click_closeAddNewContact',
	CONTACT_SEARCH_SUCCESS: 'success_searchContacts',
	CONTACT_FILTER_SELECTED: 'click_selectfilterContacts',
	CONTACT_EDIT_START: 'click_startEditContact',
	CONTACT_EDIT_SAVE: 'click_saveEditContact',
	CONTACT_SELECT_SUCCESS: 'success_selectContact',
	CONTACT_DELETE_SUCCESS: 'success_deleteContact',
	CONTACT_DELETE_CLICK: 'click_deleteContact',

	// LEDGER
	LEDGER_CONTACT_DETAILS: 'click_viewContactDetails',
	LEDGER_ADD_ENTRY: 'click_addEntry',
	LEDGER_NEW_PAYMENT_COMPLETED: 'complete_createNewPayment',
	LEDGER_EDIT_CONTACT_CLICK: 'click_saveEditContact',
	LEDGER_MANUAL_PAYMENT_OPTION: 'click_viewManualPaymentOption',
	LEDGER_INVOICE_CLICK: 'click_viewInvoiceActivity',
	LEDGER_START_MANUAL_PAYMENT: 'start_recordManualPayment',
	LEDGER_MANUAL_PAYMENT_SUCCESSFUL: 'complete_recordManualPayment',

	// ONBOARDING
	ONBOARDING_PHONE_NUMBER: 'click_enterPhoneNumber',
	ONBOARDING_LOGIN: 'click_login',
	ONBOARDING_LOGIN_SUCCESS: 'success_login',
	ONBOARDING_RESEND_OTP: 'click_resendCode',
	ONBOARDING_BUSINESS_NAME: 'click_enterBusinessName',
	ONBOARDING_BUSINESS_ADDRESS: 'click_enterBusinessAddress',
	ONBOARDING_BUSINESS_TYPE: 'click_enterBusinessType',
	ONBOARDING_UPLOAD_DP: 'click_uploadProfilePicture',
	ONBOARDING_UPLOAD_DP_SUCCESS: 'success_uploadProfilePicture',
	ONBOARDING_UPLOAD_BUSINESS_PICTURE: 'click_uploadBusinessPicture',
	ONBOARDING_UPLOAD_BUSINESS_PICTURE_SUCCESS: 'success_uploadBusinessPicture',
	ONBOARDING_START_WEBAPP: 'click_startUsingWebApp',
	ONBOARDING_WATCH_VIDEO_TUTORIAL: 'click_watchVideoTutorial',
	ONBOARDING_ENTER_NAME_CLICKED: 'click_enterName',
	ONBOARDING_ENTER_ADDRESS_CLICKED: 'click_enterAddress',
	ONBOARDING_SUPPLY_CHAIN: 'click_selectSupplyChainPosition',

	// INVOICES
	INVOICES_SEARCH: 'click_searchInvoices',
	INVOICE_CREATE_NEW: 'start_createNewInvoice',
	INVOICE_CREATE_DIRECT_SALE: 'click_createDirectSalesInvoice',
	INVOICE_CREATE_IMPORT_QUOTATION: 'click_createInvoiceImportFromQuotation',
	INVOICE_PAYMENT_STATUS: 'click_changeInvoicePaymentStatus',
	INVOICE_PAYMENT_STATUS_CHANGED: 'success_changeInvoicePaymentStatus',
	INVOICE_VIEW: 'click_viewInvoice',
	INVOICE_KEBAB_MENU: 'click_openInvoiceBookKebabMenu',
	INVOICE_RESTORE: 'click_restoreInvoice',
	INVOICE_ADD_ITEMS: 'start_addInvoiceItem',
	INVOICE_DATE: 'click_selectInvoiceDate',
	INVOICE_ADVANCE: 'click_addInvoiceAdvance',
	INVOICE_DISCOUNT: 'click_addInvoiceDiscount',
	INVOICE_EDIT: 'click_editInvoice',
	INVOICE_DELETE: 'click_deleteInvoice',
	INVOICE_DELETE_SUCCESS: 'success_deleteInvoice',
	INVOICE_IMPORT_QUOTATION: 'click_selectQuotationToImport',
	INVOICE_IMPORT_QUOTATION_SUCCESS: 'success_selectQuotationToImport',
	INVOICE_CREATE_NEW_COMPLETE: 'complete_createNewInvoice',
	INVOICE_ADD_DISCOUNT: 'click_addInvoiceDiscount',
	INVOICE_ADD_ITEM: 'complete_addInvoiceItem',
	INVOICE_SELECT_INVOICE_DATE: 'click_selectInvoiceDate',
	INVOICE_COMMENTS: 'click_enterInvoiceComments',
	INVOICE_QUOTATION_IMPORT: 'click_createInvoiceimportFromQuotation',
	INVOICE_DUE_DATE_SELECTED: 'click_addPaymentDueDate',
	INVOICE_CLICK_PAYMENT_METHOD: 'click_addPaymentMethod',
	INVOICE_ADD_PAYMENT_METHOD: 'success_addPaymentMethod',
	INVOICE_SUCCESS_ADD_ITEMS: 'success_addInvoiceItem',
	INVOICE_ADD_ANOTHER_LINE: 'click_addItemLine',
	INVOICE_ADD_ITEM_IN_BULK: 'click_addMultipleInvoiceItems',
	INVOICE_DESCRIPTIoN_ADDED: 'click_enterItemDescription',
	INVOICE_ADD_INVOICE_TAX: 'click_addInvoiceTax',
	INVOICE_ADD_ANOTHER_INVOICE_TAX: 'click_addAnotherTax',
	INVOICE_ADD_NEW_TAX_TYPE: 'start_addNewTaxType',
	INVOICE_SELECT_TAX: 'click_selectTaxType',
	INVOICE_ADD_TAX: 'start_addNewTaxType',
	INVOICE_EXTRA_CHARGES: 'click_enterExtraCharge',
	INVOICE_COMPLETE_NEW_INVOICE: 'complete_createNewInvoice',
	INVOICE_SELECT_DISCOUNT_TYPE: 'click_selectDiscountType',
	INVOICE_SELECT_BULK_PUBLISH_ACTION: 'click_selectBulkPublishAction',
	INVOICE_CLICK_EXIT_BULK_PUBLISH_MODE: 'click_exitBulkPublishMode',
	INVOICE_CLICK_SELECT_CHECKBOX: 'click_selectInvoiceCheckBox',
	INVOICE_CLICK_BULK_PUBLISH: 'click_bulkPublishInvoices',
	INVOICE_CANCEL_BULK_PUBLISH: 'cancel_bulkPublishInvoices',
	INVOICE_SUCCESS_BULK_PUBLISH: 'success_bulkPublishInvoices',
	INVOICE_FAILURE_BULK_PUBLISH: 'failure_bulkPublishInvoices',
	INVOICE_INCOMPLETE_BULK_PUBLISH: 'incomplete_bulkPublishInvoices',

	// QUOTATIONS
	QUO_SEARCH: 'click_searchQuotations',
	QUO_CREATE_NEW: 'start_createNewQuotation',
	QUO_VIEW: 'click_viewQuotation',
	QUO_STATUS: 'click_changeQuotationStatus',
	QUO_STATUS_CHANGED: 'success_changeQuotationStatus',
	QUO_DRAFT_EDIT: 'click_editDraftQuotation',
	QUO_KEBAB_MENU: 'click_openQuotationBookKebabMenu',
	QUO_GENERATE_INVOICE: 'click_generateInvoice',
	QUO_RESTORE: 'click_restoreQuotation',
	QUO_DELETE: 'click_deleteQuotation',
	QUO_CALCULATOR: 'click_selectQuotationCalculator',
	QUO_SAVE_DRAFT: 'click_saveQuotationDraft',
	QUO_DATE: 'click_selectQuotationDate',
	TOGGLE_QUO_COST_PRICE: 'toggle_selectCostPrice',
	QUO_ADD_ITEMS: 'start_addQuotationItem',
	QUO_ADD_CHARGES: 'click_addChargeQuotation',
	QUO_COMMENTS: 'click_enterQuotationComments',
	QUO_DISCOUNT: 'click_addQuotationDiscount',
	QUO_TAX: 'click_addQuotationTax',
	QUO_PREVIEW: 'click_selectPreviewQuotation',
	QUO_DELETE_FROM_PDF: 'click_selectDeleteQuotation',
	QUO_DELETE_SUCCESS: 'click_confirmDeleteQuotation',

	// PROFILE
	PRO_EDIT: 'start_editPersonalProfile',
	PRO_EDIT_NAME: 'click_editName',
	PRO_ENTER_EMAIL: 'click_enterEmail',
	PRO_UPLOAD_PIC: 'click_uploadProfilePicture',
	PRO_UPLOAD_PIC_SUCCESS: 'success_uploadProfilePicture',
	PRO_DELETE_PIC: 'click_deleteProfilePicture',
	PRO_DELETE_PIC_SUCCESS: 'success_deleteProfilePicture',
	PRO_EDIT_COMPLETED: 'complete_editPersonalProfile',
	PROFILE_UPLOAD_CLICKED: 'click_uploadProfilePicture',

	// SETTINGS
	SETTINGS_PIN_SETUP: 'start_setUpPin',
	SETTINGS_PIN_ENTER: 'click_enterNewPin',
	SETTINGS_PIN_ENTER_CONTINUE: 'click_enterPinContinue',
	SETTINGS_PIN_REENTER: 'click_reenterPin',
	SETTINGS_PIN_COMPLETE: 'complete_setUpPin',
	SETTINGS_SEARCH: 'click_searchSettings',
	SETTINGS_SEARCH_SUCCESS: 'success_searchSettings',
	SETTINGS_PRIVACY: 'click_selectPrivacySettings',
	SETTINGS_INVENTORY: 'click_selectInventorySettings',
	SETTINGS_INVENTORY_NEW_UNIT: 'click_addNewInventoryUnit',
	SETTINGS_CUSTOM_UNIT: 'click_enterCustomUnit',
	SETTINGS_INVENTORY_NEW_UNIT_SUCCESS: 'success_addNewInventoryUnit',
	SETTINGS_INVENTORY_EDIT_UNIT: 'click_editInventoryUnit',
	SETTINGS_EDIT_CUSTOM_UNIT: 'click_editCustomUnitField',
	SETTINGS_DELETE_CUSTOM_UNIT: 'click_deleteCustomUnit',
	SETTINGS_INVENTORY_EDIT_UNIT_SUCCESS: 'success_editInventoryUnit',
	SETTINGS_TAXES: 'click_selectTaxesSettings',
	SETTINGS_ORDER_FORM: 'click_selectOrderFormSettings',
	SETTINGS_USER_AND_ROLES: 'click_viewUsersAndPermissionsSettings',
	SETTINGS_FILTER_USER_ROLE: 'click_filterUsersByStatus',
	SETTINGS_VIEW_USER_ACTIONS: 'click_viewUserActions',
	SETTINGS_EDIT_USER: 'start_editUser',
	SETTINGS_DELETE_USER: 'click_deleteUser',
	SETTINGS_SUCCESS_DELETE_USER: 'success_deactivateActiveUser',
	SETTINGS_SUCCESS_REACTIVATE_USER: 'click_reactivateInactiveUser',
	SETTINGS_SUCCESS_REACTIVATE_IN_ACTIVE_USER:
		'success_reactivateInactiveUser',
	SETTINGS_ADD_NEW_USER: 'start_inviteNewUser',
	SETTINGS_COMPLETE_NEW_USER: 'complete_inviteNewUser',
	SETTINGS_COMPLETE_UPDATE_USER: 'complete_editUser',
	SETTINGS_USER_PERMISSION: 'click_selectUserPermissionSettings',
	SETTINGS_TAX_NEW: 'start_addNewTaxType',
	SETTINGS_TAX_EDIT: 'start_editTax',
	SETTINGS_TAX_NEW_SUCCESS: 'success_addNewTaxType',
	SETTINGS_TAX_EDIT_SUCCESS: 'success_editNewTaxType',
	SETTINGS_DELETE_TAX: 'click_deleteTax',
	SETTINGS_INVOICE: 'click_selectInvoiceSettings',

	// PDFS
	PDF_QUO_SHARE: 'click_selectShareQuotationChannel',
	PDF_INV_SHARE: 'click_selectShareInvoiceChannel',

	// SIDEBAR
	SIDEBAR_ORDER: 'click_selectOrderCenter',
	SIDEBAR_INVOICE: 'click_selectInvoiceBook',
	SIDEBAR_QUOTATION: 'click_selectQuotationBook',
	SIDEBAR_CONTACTS: 'click_viewContactBook',
	SIDEBAR_INVENTORY: 'click_viewInventory',
	SIDEBAR_ANALYTICS: 'click_viewAnalytics',
	SIDEBAR_LOAD_SHEET: 'click_viewLoadSheet',
	SIDEBAR_WHATS_NEW: 'click_selectWhatsNew',
	SIDEBAR_ORDER_BOOKERS: 'click_viewOrderBookers',
	SIDEBAR_HELP: 'click_selectHelp',
	SIDEBAR_SETTING: 'click_viewSettings',
	SIDEBAR_PROFILE: 'click_selectProfile',
	SIDEBAR_COLLAPSE: 'toggle_sideNavState'
}
