import type { TableComponents } from 'react-virtuoso'
import * as React from 'react'
import {
	Table,
	TableBody,
	TableContainer,
	TableHead,
	TableRow
} from '@mui/material'
import { Gutter } from 'components'
import { styled } from '@mui/system'
import { SCROLLBAR_STYLE } from 'data'

const ScrollerBar = styled(TableContainer)`
	${SCROLLBAR_STYLE}
`

export const MTableComponents: TableComponents<any> = {
	Scroller: React.forwardRef<HTMLDivElement>((props, ref) => (
		<ScrollerBar /* component={Paper}*/ {...props} ref={ref} />
	)),
	Table: props => (
		<Table
			{...props}
			sx={{
				borderCollapse: 'separate',
				tableLayout: 'fixed'
			}}
		/>
	),
	TableHead,
	TableRow: ({ item: _item, ...props }) => (
		<>
			<TableRow
				{...props}
				style={{
					backgroundColor: _item.bulkAmount ? '#F5FAFF' : 'white',
					paddingBottom: 30
				}}
			/>
		</>
	),
	TableBody: React.forwardRef<HTMLTableSectionElement>((props, ref) => (
		<TableBody {...props} ref={ref} />
	)),
	TableFoot: () => <Gutter />
}

if (MTableComponents.Scroller)
	MTableComponents.Scroller.displayName = 'Scroller'
if (MTableComponents.Table) MTableComponents.Table.displayName = 'Table'
if (MTableComponents.TableRow)
	MTableComponents.TableRow.displayName = 'TableRow'
if (MTableComponents.TableBody)
	MTableComponents.TableBody.displayName = 'TableBody'
