import React from 'react'
import {
	formHeight,
	formWidth,
	FormWrapper
} from 'screen/Profile/BusinessManagement/BusinessForm'
import { Box } from '@mui/material'
import { styled } from '@mui/system'
import { StyledModal } from 'components/StyledModal'

/* TODO: @abid I don't know why absolute still exist */
const Wrapper = styled(Box)`
	display: flex;
	justify-content: center;
	align-items: center;
	width: ${formWidth}ch;
	height: ${formHeight}ch;
	background-color: ${({ theme }) => theme.palette.colors.white['900']};
	border-radius: 15px;
	position: absolute;
	left: 15%;
	top: 7%;
`

type BusinessesModal = {
	isOpen: boolean
	handleCloseForm: () => void
	businessId?: string
}

export const BusinessesModal: React.ComponentType<BusinessesModal> = ({
	businessId,
	isOpen,
	handleCloseForm
}) => {
	const handleModalClose = (event: {}, reason: string) => {
		if (reason === 'backdropClick') {
			return
		}
		handleCloseForm()
	}

	return (
		<StyledModal open={isOpen} onClose={handleModalClose}>
			<Wrapper>
				<FormWrapper
					handleCloseForm={() => handleModalClose({}, '')}
					businessId={businessId}
				/>
			</Wrapper>
		</StyledModal>
	)
}
