import React from 'react'
import { styled } from '@mui/system'
import { SettingSideBar } from 'screen/Settting/Sidebar'
import { useDimension } from 'hooks'
import { LAYOUT_BREAKPOINT_WIDTH, LAYOUT_PADDING } from 'data'
import { FlexCol } from 'components/atoms/Flex'
import { Box } from '@mui/material'
import { SettingsBar } from './SettingsBar'

const Container = styled('div')<{ height: number }>`
	width: 100%;
	height: ${({ height }) => height - LAYOUT_PADDING * 2 - 10}px;
	display: grid;

	@media (min-width: ${LAYOUT_BREAKPOINT_WIDTH}px) {
		display: grid;
		grid-template-columns: minmax(170px, 200px) 1fr;
	}
`

const WebWrapper = styled(FlexCol)`
	@media (min-width: ${LAYOUT_BREAKPOINT_WIDTH}px) {
		display: none;
	}
`

const MobileWrapper = styled(Box)`
	@media (max-width: ${LAYOUT_BREAKPOINT_WIDTH}px) {
		display: none;
	}
`

type SettingsLayoutProps = {
	children?: React.ReactNode
}

export const SettingLayout: React.ComponentType<SettingsLayoutProps> = ({
	children
}) => {
	const { height } = useDimension()
	return (
		<>
			<Container height={height - 60}>
				<MobileWrapper>
					<SettingSideBar />
				</MobileWrapper>
				<WebWrapper>
					<SettingsBar />
				</WebWrapper>
				<FlexCol>{children}</FlexCol>
			</Container>
		</>
	)
}
