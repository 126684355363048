import {
	Paper,
	styled,
	Table,
	TableBody,
	TableContainer,
	Typography,
	useTheme
} from '@mui/material'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { FlexRow } from 'components/atoms/Flex'
import { TableHeadings } from 'screen/Quotations/Tables/TableHeadings'
import { RxCross2 } from 'react-icons/rx'
import { useBottomScrollListener } from 'react-bottom-scroll-listener'
import { getQuotationList } from 'api/quotation'
import { useSettings } from 'context/settings'
import { useAppErrors } from 'hooks/useAppErrors'
import type { QuotationType, ResponsePageInfo } from 'typings'
import { QuotationItem } from 'screen/Quotations/Tables/QuotationItem'
import { StyledModal } from 'components/StyledModal'
import { SCROLLBAR_STYLE } from 'data'

const Wrapper = styled('div')`
	border: 1px solid ${({ theme }) => theme.palette.background.paper};
	border-radius: 7px;
	overflow: scroll;
	height: 90%;
	margin-top: 8px;
	width: 100%;

	${SCROLLBAR_STYLE}
`

const SquareWrapper = styled('div')`
	border-radius: 3px;
	background-color: ${({ theme }) => theme.palette.colors.white['50']};
	padding: 3px 10px 0 10px;
	cursor: pointer;
`

const StyledPaper = styled(Paper)`
	width: 100%;
	height: 100%;
	overflow: scroll;
	position: relative;
	background-color: ${({ theme }) => theme.palette.background.default};
	${SCROLLBAR_STYLE}
`

const StyledTable = styled(Table)`
	min-width: 700px;
	width: 100%;
	background-color: ${({ theme }) => theme.palette.background.default};
`

// const Container = styled(FlexCol)`
// 	border-radius: 5px;
// 	// height: 87vh;
// 	width: 100%;
// 	background-color: ${({ theme }) => theme.palette.background.default};
// `
const Container = styled(FlexRow)`
	align-self: center;
	justify-self: center;
	padding: 15px 25px;
	min-width: 900px;
	max-width: 1300px;
	width: 100%;
	height: 800px;
	max-height: 800px;
	background-color: ${({ theme }) => theme.palette.background.default};
	border-radius: 10px;
	margin-top: 20px;
	margin-bottom: 20px;
	overflow: hidden;
`

type Props = {
	openModal: boolean
	closeModal: any
}

export const PrivateQuotationModal: React.ComponentType<Props> = ({
	openModal,
	closeModal
}) => {
	const [height, setHeight] = useState<number>()
	const [loading, setLoading] = useState<boolean>(true)
	const [dataSource, setDataSource] = useState<QuotationType[]>([])
	const [pageInfo, setPageInfo] = useState<ResponsePageInfo>({
		currentPage: 1,
		edgesPerPage: 10,
		totalPages: 1
	})
	const theme = useTheme()
	const scrollRef = useBottomScrollListener(() => getDataSource(), {
		offset: 10,
		debounce: 200
	})
	const ref = useRef<HTMLDivElement>(null)

	const { business } = useSettings()
	const { setAppError } = useAppErrors()

	const { colors } = theme.palette

	const getDataSource = async () => {
		try {
			setLoading(true)
			if (
				!loading &&
				pageInfo?.totalPages &&
				pageInfo?.totalPages >= pageInfo.currentPage + 1
			) {
				const res = await getQuotationList(business, {
					page: pageInfo.currentPage + 1,
					limit: 20,
					status: ['private']
				})
				setDataSource([...dataSource, ...res.edges])
				setPageInfo(res.pageInfo)
			}
		} catch (e: any) {
			setAppError(e)
		} finally {
			setLoading(false)
		}
	}

	const getInitialDataSource = useCallback(async () => {
		try {
			setLoading(true)
			const res = await getQuotationList(business, {
				page: 1,
				limit: 20,
				status: ['private']
			})
			setDataSource(res.edges)
			setPageInfo(res.pageInfo)
		} catch (e: any) {
			setAppError(e)
		} finally {
			setLoading(false)
		}
	}, [business, setAppError])

	const RenderRow = useCallback(
		({ row }: { row: QuotationType }) => {
			return (
				<QuotationItem
					row={row}
					status={'private'}
					// onStatusChange={onStatusChange}
				/>
			)
		},
		[
			/*onStatusChange*/
		]
	)

	useEffect(() => {
		getInitialDataSource()
	}, [getInitialDataSource])

	useEffect(() => {
		setTimeout(() => {
			setHeight(ref.current?.clientHeight || 0)
		}, 100)
	})

	return (
		<>
			<StyledModal open={openModal} onClose={closeModal}>
				<Container>
					<FlexRow
						justify="space-between"
						style={{ alignSelf: 'flex-end', width: '100%' }}
					>
						<Typography
							variant="body2"
							fontWeight={'600'}
							color={colors.green['900']}
							style={{ margin: '15px' }}
						>
							Draft Quotations
						</Typography>
						<SquareWrapper onClick={closeModal}>
							<RxCross2 />
						</SquareWrapper>
					</FlexRow>
					<Wrapper>
						<StyledPaper ref={ref}>
							<TableContainer
								ref={scrollRef as any}
								style={{
									minWidth: 700,
									width: '100%',
									maxHeight: height
								}}
							>
								<StyledTable
									stickyHeader
									aria-label="sticky table"
								>
									<TableHeadings readyToShare />
									<TableBody>
										{dataSource.map(row => (
											<RenderRow row={row} key={row.id} />
										))}
									</TableBody>
								</StyledTable>
							</TableContainer>
						</StyledPaper>
					</Wrapper>
				</Container>
			</StyledModal>
		</>
	)
}
