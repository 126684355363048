import React from 'react'
import type { InvoiceSettingsType, InvoiceType } from 'typings'
import { styled } from '@mui/system'
import { Gutter } from 'components'
import { Header } from 'screen/Invoices/View/Component/Header'
import { BusinessInfo } from 'screen/Invoices/View/Component/BusinessInfo'
import { Cart } from 'screen/Invoices/View/Component/Cart'
import { AmountDetails } from 'screen/Invoices/View/Component/AmountDetails'
import { FlexCol, FlexRow } from 'components/atoms/Flex'
import { Typography, useTheme } from '@mui/material'
import Logo from 'assets/Logo.png'

const Wrapper = styled('div')`
	padding: 30px;
	width: 100%;
	height: 100%;
	background: #fff;
`

const Grid = styled('div')`
	display: grid;
	grid-template-columns: 2fr 1.3fr;
	grid-gap: 10px;
	margin-bottom: 1rem;
`

const Card = styled(FlexRow)`
	background-color: ${({ theme }) => theme.palette.colors.gray['50']};
	border-radius: 7px;
	padding: 10px;
	width: 100%;
`

type Props = {
	title?: string
	currentInvoice: InvoiceType
	invoiceSettings?: InvoiceSettingsType
	isTermsVisible?: boolean
}

export const InvoicePdf = React.forwardRef<HTMLDivElement, Props>(
	({ currentInvoice, invoiceSettings, isTermsVisible }, ref) => {
		const theme = useTheme()
		const { colors } = theme.palette

		return (
			<Wrapper ref={ref}>
				<Header
					currentInvoice={currentInvoice}
					invoiceSettings={invoiceSettings}
				/>
				<Gutter spacing={0.5} />
				<hr />
				<Gutter spacing={1.5} />
				<BusinessInfo
					currentInvoice={currentInvoice}
					invoiceSettings={invoiceSettings}
				/>
				<Gutter spacing={1.5} />
				<Cart currentInvoice={currentInvoice} />
				<Gutter spacing={1.5} />
				<Grid>
					<FlexCol>
						{currentInvoice.comments && (
							<div>
								<Typography variant="subtitle2">
									Notes
								</Typography>

								<Card>
									<Typography
										variant={'subtitle2'}
										fontWeight={400}
										style={{ whiteSpace: 'pre-line' }}
									>
										{currentInvoice.comments}
									</Typography>
								</Card>
								<Gutter spacing={2} />
							</div>
						)}

						{currentInvoice?.termsAndConditions &&
							isTermsVisible && (
								<div>
									<Typography variant="subtitle2">
										Terms And Conditions
									</Typography>

									<Card>
										<Typography
											variant={'subtitle2'}
											fontWeight={400}
											style={{ whiteSpace: 'pre-line' }}
										>
											{currentInvoice.termsAndConditions}
										</Typography>
									</Card>
								</div>
							)}
					</FlexCol>
					<AmountDetails currentInvoice={currentInvoice} />
				</Grid>
				<Gutter spacing={4} />
				<FlexCol>
					<Typography
						variant={'subtitle2'}
						color={colors.green['700']}
					>
						Generated using
					</Typography>
					<Gutter spacing={0.2} />
					<img src={Logo} alt={'logo'} height={30} />
				</FlexCol>
			</Wrapper>
		)
	}
)

InvoicePdf.displayName = 'InvoicePdf'
