import { axios } from 'store'
import type { AwsResponsePropS, IBusiness } from 'typings'

export const postBusiness = async (payload: IBusiness) => {
	const url = '/business'

	try {
		const { data } = await axios.post(url, payload)

		return data
	} catch (error: any) {
		throw error.response.data
	}
}

export const updateBusiness = async (
	payload: Partial<IBusiness>,
	id: string
) => {
	const url = `/business/${id}`

	try {
		const { data } = await axios.patch(url, payload)

		return data
	} catch (error: any) {
		throw error.response.data
	}
}

export const getAllBusiness = async () => {
	const url = '/business'

	try {
		const { data } = await axios.get(url)

		return data
	} catch (error: any) {
		throw error.response.data
	}
}

export const uploadBusinessPic = async (
	fileType: string,
	payload: { businessId?: string }
) => {
	let url = `/business/business-pic/upload-url?fileType=${fileType}`

	if (payload.businessId) {
		url += `&businessId=${payload.businessId}`
	}

	try {
		const { data } = await axios.get<AwsResponsePropS>(url)
		return data
	} catch (error: any) {
		throw error.response.data
	}
}

export const getAllBusinessBookers = async (id: string) => {
	const url = `/business/bookers?businessId=${id}`

	try {
		const { data } = await axios.get(url)

		return data
	} catch (error: any) {
		throw error.response.data
	}
}
