import * as React from 'react'
import { TableCell, TableRow, Typography } from '@mui/material'
import { styled } from '@mui/system'

const Cell = styled(TableCell)`
	background-color: ${({ theme }) => theme.palette.colors.gray['50']};
	color: ${({ theme }) => theme.palette.colors.gray['500']};
	padding: 7px 7px 7px 24px;
`

const CheckBoxCell = styled(TableCell)`
	background-color: ${({ theme }) => theme.palette.colors.gray['50']};
	color: ${({ theme }) => theme.palette.colors.gray['500']};
	padding: 7px 7px 7px 24px;
	max-width: 40px;
	overflow: hidden;
`

const Text = styled(Typography)`
	color: ${({ theme }) => theme.palette.colors.gray['500']};
	font-weight: 500;
`

export const AllInvoiceHeader = () => {
	return (
		<TableRow>
			<CheckBoxCell variant="head" align={'left'} width={50} />
			<Cell variant="head" align={'left'}>
				<Text variant={'subtitle1'} fontWeight={500}>
					Contact
				</Text>
			</Cell>
			<Cell variant="head" align={'left'}>
				<Text variant={'subtitle1'} fontWeight={500}>
					Invoice
				</Text>
			</Cell>
			<Cell variant="head" align={'left'} sx={{ paddingRight: '24px' }}>
				<Text variant={'subtitle1'} fontWeight={500}>
					Amount (PKR)
				</Text>
			</Cell>
			<Cell variant="head" align={'left'} sx={{ paddingRight: '24px' }}>
				<Text variant={'subtitle1'} fontWeight={500}>
					Stock
				</Text>
			</Cell>
			<Cell variant="head" align={'right'} sx={{ paddingRight: '24px' }}>
				<Text variant={'subtitle1'} fontWeight={500}>
					Date Created
				</Text>
			</Cell>
		</TableRow>
	)
}
