import React, { useCallback, useEffect } from 'react'
import { useAppErrors, useDimension } from 'hooks'
import type { DataResponseType, InvoiceType } from 'typings'
import { FlexRow } from 'components/atoms/Flex'
import { styled } from '@mui/system'
import Paper from '@mui/material/Paper'
import { TableVirtuoso } from 'react-virtuoso'
import { MTableComponents } from 'screen/Home/BulkPaymentModal/MTableComponent'
import { MTableHeader } from 'screen/Home/BulkPaymentModal/MTableHeader'
import { BulkPaymentItem } from 'screen/Home/BulkPaymentModal/BulkPaymentItem'
import { getInvoiceList } from 'api/invoice'
import { useFormikContext } from 'formik'
import type { BulKPaymentInitialValue } from 'data'
import { useSettings } from 'context/settings'

const Table = styled(TableVirtuoso)`
	width: 100%;
	background: ${({ theme }) => theme.palette.colors.white['500']};
`

const StyledPaper = styled(Paper)`
	position: relative;
	width: 100%;
	height: 100%;
	background: ${({ theme }) => theme.palette.colors.white['500']};
	z-index: 0;
`

const Container = styled(FlexRow)`
	width: 100%;
`

type BulkPaymentFormProps = {
	contactId: string
}

export const BulkPaymentForm: React.ComponentType<BulkPaymentFormProps> = ({
	contactId
}) => {
	const { values, setFieldValue } =
		useFormikContext<typeof BulKPaymentInitialValue>()
	const { height } = useDimension()
	const { business } = useSettings()
	const { setAppError } = useAppErrors()

	const getInitialInvoiceList = useCallback(async () => {
		try {
			setFieldValue('loading', true)
			const res: DataResponseType<InvoiceType> = await getInvoiceList(
				business,
				{
					page: 1,
					limit: values.pageInfo.edgesPerPage,
					contactId: contactId,
					compact: true,
					status: 'publish',
					sortOrder: 'ASC'
				},
				'&paymentStatus=pending,partial'
			)
			setFieldValue('invoices', res.edges)
			setFieldValue('pageInfo', res.pageInfo)
		} catch (e: any) {
			setAppError(e)
		} finally {
			setFieldValue('loading', false)
		}
	}, [
		business,
		contactId,
		setAppError,
		setFieldValue,
		values.pageInfo.edgesPerPage
	])

	const fetchInvoiceList = async () => {
		try {
			setFieldValue('loading', true)
			if (
				!values.loading &&
				values.pageInfo?.totalPages &&
				values.pageInfo?.totalPages >= values.pageInfo.currentPage + 1
			) {
				const res: DataResponseType<InvoiceType> = await getInvoiceList(
					business,
					{
						page: values.pageInfo.currentPage + 1,
						limit: values.pageInfo.edgesPerPage,
						contactId: contactId,
						compact: true,
						sortOrder: 'ASC',
						status: 'publish'
					},
					'&paymentStatus=pending,partial'
				)
				setFieldValue('invoices', [...values.invoices, ...res.edges])
				setFieldValue('pageInfo', res.pageInfo)
			}
		} catch (e: any) {
			setAppError(e)
		} finally {
			setFieldValue('loading', false)
		}
	}

	useEffect(() => {
		getInitialInvoiceList()
	}, [])

	return (
		<Container style={{ height: height * 0.9 - 240 }}>
			<StyledPaper>
				<Table
					data={values.invoices}
					components={MTableComponents}
					fixedHeaderContent={MTableHeader}
					itemContent={(_index: number, row: any) => (
						<BulkPaymentItem invoice={row} index={_index} />
					)}
					endReached={() => fetchInvoiceList()}
				/>
			</StyledPaper>
		</Container>
	)
}
