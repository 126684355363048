export const getNameChar = (name: string) => {
	const full_name = name.trim().split(' ')
	let Char = ''

	Char = full_name[0].charAt(0).toUpperCase()
	if (full_name.length > 1) {
		Char += full_name[full_name.length - 1].charAt(0).toUpperCase()
	}

	return Char
}
