import React, { useCallback, useMemo, useRef } from 'react'
import { styled } from '@mui/system'
import type { CountryListProps } from 'data'
import { COUNTRIES_LIST } from 'data'
import {
	ClickAwayListener,
	Fade,
	Paper,
	Popper,
	Typography,
	useTheme
} from '@mui/material'
import { Gutter } from 'components/index'
import { TextInput, type TextInputProps } from 'components/atoms/TextInput'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import { FlexCol, FlexRow } from 'components/atoms/Flex'
import { useTranslate } from 'hooks'
import { bindToggle, usePopupState } from 'material-ui-popup-state/hooks'
import { POSITIVE_NUMBER } from 'helpers'
import { CountryDropdown } from 'components/atoms/PhoneNumber/CountryDropdown'
import { bindPopper } from 'material-ui-popup-state'

const Wrapper = styled(FlexCol)`
	border-radius: 7px;
	align-items: center;
	outline: none;
	height: 100%;
	width: 100%;
	-webkit-appearance: none;
`

const Input = styled(TextInput)`
	width: 100%;
	background-color: ${({ theme }) => theme.palette.background.default};
	& .MuiInputBase-root {
		padding: 0;
		border-radius: 10px;
	}
`

const MenuDropDown = styled(Paper, {
	shouldForwardProp: props => props !== 'maxWidth'
})<{ maxWidth?: number }>`
	background-color: ${({ theme }) => theme.palette.background.default};
	border: 1px solid ${({ theme }) => theme.palette.background.paper};
	border-radius: 8px;
	margin-top: 10px;
	box-shadow: 0 12px 16px -4px rgba(16, 24, 40, 0.08),
		0px 4px 6px -2px rgba(16, 24, 40, 0.03);
	max-width: ${({ maxWidth }) => maxWidth || 300}px;
	min-width: ${({ maxWidth }) => maxWidth || 300}px;
	padding: 8px;
`

const Button = styled(FlexRow, {
	shouldForwardProp: props => props !== 'flagDisabled'
})<Pick<PhoneNumberProps, 'flagDisabled'>>`
	border-top-left-radius: 10px;
	border-bottom-left-radius: 10px;
	height: 100%;
	padding: 6px 0 6px 14px;
	background-color: ${({ theme }) => theme.palette.colors.green['50']};

	:hover {
		color: ${({ theme }) => theme.palette.primary.main};
		cursor: ${({ flagDisabled }) => (flagDisabled ? 'no-drop' : 'pointer')};
	}
`

const Img = styled('img')`
	width: 32px;
	height: 32px;
`

const initialValue = {
	offsetTop: 0,
	offsetLeft: 0,
	offsetWidth: 0
}

export type PhoneNumberProps = {
	onChange?(
		value: string,
		formattedValue?: string,
		country?: CountryListProps
	): void
	phoneNumber: string
	code: string
	selectedCode: string
	onBlur?(): void
	error?: boolean
	disabled?: boolean
	flagDisabled?: boolean
} & Omit<TextInputProps, 'onChange' | 'sx'>

const PhoneNumber: React.ComponentType<PhoneNumberProps> = ({
	onChange,
	selectedCode,
	phoneNumber,
	onBlur,
	error,
	flagDisabled,
	disabled,
	code,
	...props
}) => {
	const wrapperRef = useRef<any>(initialValue)
	const translate = useTranslate()
	const theme = useTheme()
	const {
		palette: { colors }
	} = theme

	const selectedCountry = useMemo(
		() =>
			COUNTRIES_LIST.find(
				c => c.dial_code === selectedCode && c.code === code
			) || {
				name: 'Pakistan',
				flag: '🇵🇰',
				code: 'PK',
				dial_code: '92',
				phone: {
					countryCode: '92',
					mobile_begin_with: ['3'],
					phone_number_lengths: ['10']
				},
				image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PK.svg'
			},
		[code, selectedCode]
	)

	const maxLength = selectedCountry.phone.phone_number_lengths

	const onCountryChange = useCallback(
		(country: CountryListProps) => {
			onChange?.('', `${country.dial_code}`, country)
			onBlur?.()
		},
		[onBlur, onChange]
	)

	const onPhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (!disabled && e.target.value.match(POSITIVE_NUMBER)) {
			onChange?.(
				e.target.value,
				selectedCountry.dial_code || selectedCode,
				selectedCountry
			)
			onBlur?.()
		}
	}

	const RenderLeft = () => {
		const popupState = usePopupState({
			variant: 'popover',
			popupId: 'demoMenu'
		})

		return (
			<ClickAwayListener onClickAway={popupState.close}>
				<div style={{ minWidth: 'max-content' }}>
					<FlexRow
						align={'center'}
						justify={'flex-start'}
						style={{
							minWidth: 'max-content'
						}}
					>
						<Button
							flagDisabled={flagDisabled}
							align="center"
							{...bindToggle(popupState)}
						>
							<Img
								src={selectedCountry.image}
								alt={selectedCountry.name}
							/>
							<Gutter gap={0.2} />
							{popupState.isOpen ? (
								<ArrowDropUpIcon />
							) : (
								<ArrowDropDownIcon />
							)}
							<Gutter gap={0.2} />
						</Button>
						<Gutter gap={0.4} />
						<Typography color={colors.gray['400']}>
							+{selectedCountry?.dial_code}
						</Typography>
						<Gutter gap={0.4} />
					</FlexRow>
					<Popper
						{...bindPopper(popupState)}
						transition
						placement={'bottom-start'}
						style={{ zIndex: 4000 }}
					>
						{({ TransitionProps }) => (
							<Fade {...TransitionProps} timeout={0}>
								<MenuDropDown
									maxWidth={wrapperRef.current.offsetWidth}
								>
									<CountryDropdown
										//searchCountry={searchCountry}
										popupState={popupState}
										selectedCountry={selectedCountry}
										onCountryChange={onCountryChange}
									/>
								</MenuDropDown>
							</Fade>
						)}
					</Popper>
				</div>
			</ClickAwayListener>
		)
	}

	return (
		<Wrapper ref={wrapperRef} align="center">
			<Input
				error={!!error}
				size={'small'}
				InputProps={{
					startAdornment: <RenderLeft />
				}}
				placeholder={translate('phoneNumber')}
				value={phoneNumber}
				onChange={onPhoneNumberChange}
				onBlur={onBlur}
				disabled={disabled}
				inputProps={{
					maxLength: maxLength?.length === 1 ? maxLength?.[0] : 20
				}}
				{...props}
			/>
		</Wrapper>
	)
}

export { PhoneNumber }
