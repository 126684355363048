import React from 'react'
import { TableCell, Typography, useTheme } from '@mui/material'
import { styled } from '@mui/system'
import moment from 'moment'
import { dateFormat, SEGMENT_EVENTS } from 'data'
import type { InviteUserProps, Roles } from 'typings'
import { pascalCase } from 'helpers'
import { KababMenu } from 'components/atoms/KababMenu'
import {
	MdOutlineModeEdit,
	MdOutlineModeEditOutline,
	MdRestartAlt
} from 'react-icons/md'
import { IoIosRemoveCircleOutline } from 'react-icons/io'
import { FlexRow } from 'components/atoms/Flex'
import { Gutter } from 'components'
import analytics from 'utils/segment'

const Cell = styled(TableCell)`
	color: ${({ theme }) => theme.palette.colors.gray['500']};
	padding: 7px 7px 7px 24px;

	@media screen and (max-width: 600px) {
		padding: 14px 7px 14px 14px;
	}
`

const Text = styled(Typography)`
	color: ${({ theme }) => theme.palette.colors.black['500']};
	font-weight: 500;
`

const SmallCell = styled(TableCell, {
	shouldForwardProp: props => props !== 'isVoid'
})`
	color: ${({ theme }) => theme.palette.colors.gray['500']};
	padding: 14px 7px 14px 24px;
	min-width: 150px;
	max-width: 200px;

	@media screen and (max-width: 600px) {
		padding: 14px 7px 14px 14px;
	}
`

const HiddenText = styled(Typography)`
	color: ${({ theme }) => theme.palette.colors.black['500']};
	font-weight: 500;
	//white-space: nowrap;
	display: none;

	@media screen and (max-width: 700px) {
		display: block;
		grid-template-columns: 1fr;
	}
`

const HiddenCell = styled(TableCell, {
	shouldForwardProp: props => props !== 'isVoid'
})`
	color: ${({ theme }) => theme.palette.colors.gray['500']};
	padding: 14px 7px 14px 24px;
	min-width: 150px;
	max-width: 200px;

	@media screen and (max-width: 700px) {
		display: none;
	}
`

const FlexWrapper = styled(FlexRow)`
	align-items: center;
	justify-content: center;

	:hover {
		cursor: pointer;
		background-color: ${({ theme }) => theme.palette.colors.blue['25']};
	}
`

type RoleItemProps = InviteUserProps & {
	isActive: boolean
	onReactivateClick?(item: InviteUserProps): void
	onDeactivateClick?(item: InviteUserProps): void
	onEditClick?(item: InviteUserProps): void
	role?: Roles
}

export const RoleItem: React.ComponentType<RoleItemProps> = ({
	phone,
	businessRole,
	isSystemGenerated,
	isActive,
	fullName,
	onReactivateClick,
	onEditClick,
	onDeactivateClick,
	role,
	...props
}) => {
	const theme = useTheme()
	const {
		palette: { colors }
	} = theme

	const obj = {
		phone,
		businessRole,
		isSystemGenerated,
		fullName,
		...props
	}

	return (
		<>
			<SmallCell variant="head" align={'left'}>
				<Text variant={'subtitle2'} fontWeight={400}>
					{fullName}
				</Text>
				<HiddenText>
					{phone.number
						? `+${phone.countryCode} ${phone.number}`
						: ''}
				</HiddenText>
			</SmallCell>
			<HiddenCell variant="head" align={'left'}>
				<Text variant={'subtitle2'} fontWeight={400}>
					{phone.number
						? `+${phone.countryCode} ${phone.number}`
						: ''}
				</Text>
			</HiddenCell>
			<Cell variant="head" align={'left'}>
				<Text variant={'subtitle2'} fontWeight={400}>
					{businessRole === 'co_owner'
						? 'Admin'
						: businessRole === 'admin'
						? 'Staff'
						: 'Owner'}
				</Text>
			</Cell>
			<Cell variant="head" align={'right'} sx={{ paddingRight: '24px' }}>
				<Text variant={'subtitle2'} fontWeight={400}>
					{moment(props.createdAt).format(dateFormat)}
				</Text>
			</Cell>
			<Cell variant="head" align={'right'} sx={{ paddingRight: '24px' }}>
				<Text
					variant={'subtitle2'}
					fontWeight={500}
					style={{
						color: props.deleted.status
							? colors.red['600']
							: colors.green['600']
					}}
				>
					{pascalCase(props.deleted.status ? 'Inactive' : 'Active')}
				</Text>
			</Cell>
			<Cell
				variant="head"
				align={'right'}
				sx={{ paddingRight: '24px', maxWidth: 20 }}
			>
				{isActive && !isSystemGenerated && (
					<>
						{businessRole === 'co_owner' && role === 'co_owner' ? (
							<></>
						) : (
							<KababMenu
								dataSource={[
									{
										id: 0,
										icon: MdOutlineModeEdit,
										name: 'Edit',
										value: 'edit',
										iconColor: 'gray',
										onClick: () => {
											analytics.track(
												SEGMENT_EVENTS.SETTINGS_VIEW_USER_ACTIONS
											)
											analytics.track(
												SEGMENT_EVENTS.SETTINGS_EDIT_USER,
												{
													user_status: isActive
														? 'Active'
														: 'In-Active'
												}
											)
											onEditClick?.(obj)
										}
									},
									{
										id: 1,
										icon: IoIosRemoveCircleOutline,
										name: 'Deactivate',
										value: 'Deactivate',
										iconColor: 'red',
										color: 'red',
										onClick: () => {
											analytics.track(
												SEGMENT_EVENTS.SETTINGS_VIEW_USER_ACTIONS
											)
											analytics.track(
												SEGMENT_EVENTS.SETTINGS_DELETE_USER
											)
											onDeactivateClick?.(obj)
										}
									}
								]}
							/>
						)}
					</>
				)}
				{!isActive && (
					<>
						{businessRole === 'co_owner' && role === 'co_owner' ? (
							<></>
						) : (
							<FlexRow>
								<FlexWrapper
									onClick={() => {
										onReactivateClick?.(obj)
										analytics.track(
											SEGMENT_EVENTS.SETTINGS_SUCCESS_REACTIVATE_USER
										)
									}}
								>
									<MdRestartAlt color={colors.blue['600']} />
									<Gutter gap={0.2} />
									<Typography
										variant={'caption'}
										color={colors.blue['600']}
									>
										Reactivate
									</Typography>
								</FlexWrapper>
								<Gutter gap={1} />
								<FlexWrapper onClick={() => onEditClick?.(obj)}>
									<MdOutlineModeEditOutline
										color={colors.blue['600']}
									/>
									<Gutter gap={0.2} />
									<Typography
										variant={'caption'}
										color={colors.blue['600']}
									>
										Edit
									</Typography>
								</FlexWrapper>
							</FlexRow>
						)}
					</>
				)}
			</Cell>
		</>
	)
}
