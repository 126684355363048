import React from 'react'
import type { TypographyProps } from '@mui/material'
import { Typography, useTheme } from '@mui/material'
import { Abbr } from 'components/atoms/Abbr'

type FormatAmountProps = {
	amount: number | string
	prefixCurrency?: string
	limit?: number
} & TypographyProps

export const FormatAmount: React.ComponentType<FormatAmountProps> = ({
	amount = 0,
	prefixCurrency,
	limit,
	...props
}) => {
	const price = typeof amount === 'string' ? parseFloat(amount) : amount
	const finalPrice = price //price < 0 ? price * -1 : price
	const theme = useTheme()

	return (
		<Typography
			variant={'body2'}
			fontWeight={'normal'}
			color={
				price === 0
					? theme.palette.colors.gray['600']
					: price < 0
					? theme.palette.colors.red['600']
					: theme.palette.colors.green['600']
			}
			{...props}
		>
			{prefixCurrency}{' '}
			<Abbr
				title={finalPrice.toLocaleString()}
				length={limit || finalPrice.toString().length}
			/>
		</Typography>
	)
}
