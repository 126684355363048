import {
	Badge,
	type ModalProps,
	styled,
	Typography,
	useTheme
} from '@mui/material'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Gutter, SearchBar } from 'components/index'
import { FlexCol, FlexRow } from 'components/atoms/Flex'
import type { DataResponseType, IContactProps, ResponsePageInfo } from 'typings'
import type { ContactSortType } from 'screen'
import { StyledModal } from 'components/StyledModal'
import { IoIosClose } from 'react-icons/io'
import AddContact from 'assets/contacts/add_contact.png'
import NoContacts from 'assets/contacts/no_contact_found.png'
import Paper from '@mui/material/Paper'
import { ScrollableTable } from './ScrollableTable'
import { useSettings } from 'context/settings'
import analytics from 'utils/segment'
import { SEGMENT_EVENTS } from 'data'
import { getContactList } from 'api/contacts'
import { useAppErrors, useDimension } from 'hooks'
import { CreateContactModal } from 'screen/Contact/AddContact/CreateContactModal'
import { TransparentButton } from 'components/atoms/Button/TransparentButton'

const Container = styled(FlexCol)`
	height: 80%;
	width: 75%;
	background-color: ${({ theme }) => theme.palette.background.default};
	padding: 15px;
	z-index: 0;
	border-radius: 10px;
`

const GrayDot = styled(Badge)(({ theme }) => ({
	'& .MuiBadge-badge': {
		backgroundColor: theme.palette.colors.gray['400']
	},
	margin: '0px 10px'
}))

const CloseButtonWrapper = styled(FlexCol)`
	justify-content: center;
	align-items: center;
	height: 30px;
	width: 30px;
	right: 1.75em;

	&:hover {
		cursor: pointer;
		border-radius: 25%;
		background-color: ${({ theme }) => theme.palette.colors.gray['100']};
		display: flex;
	}
`

const Center = styled(FlexCol)`
	position: absolute;
	width: 100%;
	height: 100%;
	align-self: center;
	align-items: center;
	justify-content: center;
`

const Image = styled('img')`
	min-width: 100px;
	max-width: 250px;
`

const StyledPaper = styled(Paper)`
	width: 100%;
	overflow: hidden;
	z-index: 0;
	position: relative;
`

type Props = {
	onContactSelect?(contact: IContactProps): void
	segmentEventCall?(): void
} & Omit<ModalProps, 'children'>

export const SelectContactModal: React.ComponentType<Props> = ({
	onClose,
	onContactSelect,
	segmentEventCall,
	...props
}) => {
	const [loading, setLoading] = useState<boolean>(true)
	const [isAddVisible, setIsAddVisible] = useState(false)
	const [isActive, setIsActive] = useState<boolean>(false)
	const [dataSource, setDataSource] = useState<IContactProps[]>([])
	const [pageInfo, setPageInfo] = useState<ResponsePageInfo>({
		totalPages: 1,
		currentPage: 1,
		edgesPerPage: 20
	})
	const [searchKey, setSearchKey] = useState('')
	const [sortObj] = useState<ContactSortType>({
		sortOrder: 'asc',
		sortBy: 'name'
	})
	const ref = useRef<HTMLDivElement>(null)
	const { business } = useSettings()
	const { setAppError } = useAppErrors()
	const { height } = useDimension()
	const theme = useTheme()
	const { colors } = theme.palette

	const handleModalClose = (contact: IContactProps) => {
		segmentEventCall?.()
		setSearchKey('')
		onClose?.({}, 'escapeKeyDown')
		onContactSelect?.(contact)
	}

	const tableHeight = useMemo(() => {
		if (ref?.current) {
			return ref?.current?.clientHeight - 200
		}

		return height * 0.8 - 230 // note here 0.8 is the modal height
	}, [height])

	const onClick = () => {
		setSearchKey('')
		onClose?.({}, 'escapeKeyDown')
	}

	const onSearch = async (searchText: string) => {
		setDataSource([])
		setSearchKey(searchText)
	}

	const getInitialContactList = useCallback(async () => {
		try {
			setLoading(true)
			const res: DataResponseType<IContactProps> = await getContactList({
				business,
				page: 1,
				limit: pageInfo.edgesPerPage,
				searchKey,
				...sortObj,
				sortOrder: sortObj.sortOrder.toUpperCase() as 'ASC' | 'DESC'
			})

			setDataSource(res.edges)
			setPageInfo(res.pageInfo)
		} catch (e: any) {
			setAppError(e)
		} finally {
			setLoading(false)
		}
	}, [business, pageInfo.edgesPerPage, searchKey, setAppError, sortObj])

	const fetchContacts = async () => {
		try {
			setLoading(true)
			if (
				!loading &&
				pageInfo?.totalPages &&
				pageInfo?.totalPages >= pageInfo.currentPage + 1
			) {
				const res: DataResponseType<IContactProps> =
					await getContactList({
						business,
						page: pageInfo.currentPage + 1,
						limit: pageInfo.edgesPerPage,
						searchKey,
						...sortObj,
						sortOrder: sortObj.sortOrder.toUpperCase() as
							| 'ASC'
							| 'DESC'
					})

				setDataSource([...dataSource, ...res.edges])
				setPageInfo(res.pageInfo)
			}
		} catch (e: any) {
			setAppError(e)
		} finally {
			setLoading(false)
		}
	}

	useEffect(() => {
		getInitialContactList()
	}, [getInitialContactList])

	return (
		<>
			<StyledModal onClose={onClose} {...props}>
				<Container ref={ref}>
					<FlexRow
						style={{ width: '100%' }}
						justify={'space-between'}
						align={'center'}
					>
						<FlexRow align="center">
							<Typography fontWeight={'600'}>
								Select Contact
							</Typography>
							<GrayDot badgeContent={''} variant="dot" />
							<Typography
								variant="caption"
								color={colors.gray['500']}
							>
								Displaying {dataSource.length} contacts
							</Typography>
						</FlexRow>
						<CloseButtonWrapper>
							<IoIosClose size={25} onClick={onClick} />
						</CloseButtonWrapper>
					</FlexRow>
					<Gutter />
					<FlexRow
						align={'center'}
						justify={'space-between'}
						style={{ width: '100%' }}
					>
						<FlexRow align={'center'}>
							<SearchBar
								onSearch={onSearch}
								onFocus={() => setIsActive(true)}
								onBlur={text => {
									setIsActive(text !== '')
								}}
								sx={{ width: isActive ? '45ch' : '35ch' }}
							/>
						</FlexRow>

						<TransparentButton
							onClick={() => {
								setIsAddVisible(true)
								analytics.track(SEGMENT_EVENTS.ADD_NEW_CONTACT)
							}}
						>
							Add a new Contact
						</TransparentButton>
					</FlexRow>
					<Gutter spacing={1.4} />
					<StyledPaper>
						{dataSource.length === 0 && !loading && (
							<Center>
								<Image
									src={
										searchKey === ''
											? AddContact
											: NoContacts
									}
								/>
								<Gutter spacing={0.3} />
								<Typography variant={'body2'}>
									{searchKey === ''
										? 'Your Inventory is empty, add items to it to track your stock better'
										: `No items titled "${searchKey}" found`}
								</Typography>
							</Center>
						)}
						<ScrollableTable
							tableHeight={tableHeight}
							fetchContacts={fetchContacts}
							sortObj={sortObj}
							searchKey={searchKey}
							dataSource={dataSource}
							loading={loading}
							onRowClick={handleModalClose}
						/>
					</StyledPaper>
				</Container>
			</StyledModal>
			<CreateContactModal
				open={isAddVisible}
				onClose={() => setIsAddVisible(false)}
				onSuccess={() => {
					getInitialContactList()
				}}
			/>
		</>
	)
}
