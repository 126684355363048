import React from 'react'
import {
	ClickAwayListener,
	Fade,
	Popper,
	styled,
	Typography,
	useTheme
} from '@mui/material'
import PopupState, { bindPopper, bindToggle } from 'material-ui-popup-state'
import { FlexCol, FlexRow } from 'components/atoms/Flex'
import { CustomRadioGroup } from 'components/atoms/RadioGroup'
import type { ColorsType } from 'typings'
import { BsChevronDown, BsChevronUp } from 'react-icons/bs'

const StyledButton = styled(FlexRow, {
	shouldForwardProp: props => props !== 'color'
})<Pick<StatusOptionProps, 'color'>>`
	align-items: center;
	background-color: ${({ theme, color }) => theme.palette.colors[color][50]};
	border-radius: 30px;
	padding: 5px 20px;
	width: fit-content;
	flex-wrap: nowrap;

	:hover {
		cursor: pointer;
		background-color: ${({ theme, color }) =>
			theme.palette.colors[color][100]};
	}
`

const Wrapper = styled(FlexCol)`
	background-color: ${({ theme }) => theme.palette.background.default};
	border: 1px solid ${({ theme }) => theme.palette.background.paper};
	border-radius: 8px;
	margin-top: 5px;
	box-shadow: 0 12px 16px -4px rgba(16, 24, 40, 0.08),
		0px 4px 6px -2px rgba(16, 24, 40, 0.03);
`

const DownArrow = styled(BsChevronDown, {
	shouldForwardProp: props => props !== 'color'
})<Pick<StatusOptionProps, 'color'>>`
	margin-left: 5px;
	color: ${({ theme, color }) => theme.palette.colors[color]['700']};
`

const UpArrow = styled(BsChevronUp, {
	shouldForwardProp: props => props !== 'color'
})<Pick<StatusOptionProps, 'color'>>`
	margin-left: 5px;
	color: ${({ theme, color }) => theme.palette.colors[color]['700']};
`

const Option = styled(FlexRow)`
	padding: 8px 14px;
	width: 100%;

	:hover {
		cursor: pointer;
	}
`

export type StatusOptionProps = {
	id: string | number
	label: string
	value: string
	color: ColorsType
}

export type StatusProps = {
	options: StatusOptionProps[]
	onSelect?(selectedOption: StatusOptionProps): void
	selectedValue: string
}

export const Status: React.ComponentType<StatusProps> = ({
	options,
	onSelect,
	selectedValue
}) => {
	const theme = useTheme()
	const {
		palette: { colors, mode }
	} = theme
	const filteredValue = options.filter(f => f.value === selectedValue)
	const selectedObj = filteredValue.length > 0 && filteredValue[0]
	const dark: ColorsType = selectedObj ? selectedObj.color : 'white'
	const light: ColorsType = selectedObj ? selectedObj.color : 'black'

	return (
		<PopupState
			variant="popper"
			popupId="demo-popup-popper"
			disableAutoFocus={true}
		>
			{popupState => (
				<ClickAwayListener onClickAway={() => popupState.close()}>
					<div>
						<StyledButton
							color={mode === 'dark' ? dark : light}
							{...bindToggle(popupState)}
						>
							<>
								<Typography
									variant={'caption'}
									color={
										mode === 'dark'
											? colors[dark || 'white']['700']
											: colors[light || 'black']['700']
									}
								>
									{(selectedObj && selectedObj.label) || ''}
								</Typography>
								{popupState.isOpen ? (
									<UpArrow
										color={mode === 'dark' ? dark : light}
									/>
								) : (
									<DownArrow
										color={mode === 'dark' ? dark : light}
									/>
								)}
							</>
						</StyledButton>
						<Popper
							{...bindPopper(popupState)}
							transition
							placement="bottom-start"
						>
							{({ TransitionProps }) => (
								<Fade {...TransitionProps} timeout={350}>
									<Wrapper>
										<FlexCol style={{ minWidth: 220 }}>
											<Option align={'flex-start'}>
												<CustomRadioGroup
													options={options}
													defaultValue={
														selectedObj &&
														selectedObj.value
													}
													onChange={(
														event: React.ChangeEvent<HTMLInputElement>
													) =>
														onSelect?.(
															options.find(
																f =>
																	f.value ===
																	event.target
																		.value
															) as StatusOptionProps
														)
													}
												/>
											</Option>
										</FlexCol>
									</Wrapper>
								</Fade>
							)}
						</Popper>
					</div>
				</ClickAwayListener>
			)}
		</PopupState>
	)
}
