import produce from 'immer'
import type { AppActionsTypes } from 'store/app/types'

export type NavigationType = {
	activeUrl: string
	title: string
	state?: any
	type?: 'redirect' | 'push' | 'replace' | 'navigate'
}

export type RightMenuType = {
	title: string
	ref: any
}

interface IAppState {
	pageName: string
	navigations?: NavigationType[]
	rightMenu?: RightMenuType
}

const initialState: IAppState = { pageName: '', navigations: undefined }

const reducer = produce((draft: IAppState, action: AppActionsTypes) => {
	switch (action.type) {
		case 'SET_NAVIGATION':
			draft.navigations = action.payload || undefined
			draft.rightMenu = action.rightMenu || undefined
			return

		default:
			return
	}
}, initialState)

export default reducer
