import React, { useCallback } from 'react'
import {
	TableBody,
	TableCell,
	TableHead,
	TableSortLabel,
	Typography,
	useTheme
} from '@mui/material'
import { styled } from '@mui/system'
import type { ISettings, InventoryItemType } from 'typings'
import { InventoryRow } from './InventoryRow'
import type { InventorySortType } from 'screen/Inventory/Inventory'

const ProductCell = styled(TableCell)<{ width?: number }>`
	min-width: ${({ width }) => (width ? `${width}px` : '300px')};
	background-color: ${({ theme }) => theme.palette.colors.gray['50']};
	color: ${({ theme }) => theme.palette.colors.gray['500']};
`

const MedCell = styled(TableCell, {
	shouldForwardProp: props => props !== 'width' && props !== 'hidden'
})<{ width?: number; hidden?: boolean }>`
	min-width: ${({ width }) => (width ? `${width}px` : '180px')};
	max-width: 200px;
	background-color: ${({ theme }) => theme.palette.colors.gray['50']};
	color: ${({ theme }) => theme.palette.colors.gray['500']};

	@media (max-width: 650px) {
		display: ${({ hidden }) => (hidden ? 'none' : 'table-cell')};
		visibility: ${({ hidden }) => (hidden ? 'hidden' : 'visible')};
	}
`

const StyledTableBody = styled(TableBody)`
	width: 100%;
	height: 100%;
	overflow-x: hidden;
`

export type InventoryTableProps<P> = {
	gridTemplateColumns?: string
	searchKey?: string
	onSort(name: string): void
	sortObj: InventorySortType
	selectedInventory?: P[]
	onRowClick?(inventory: P): void
	onModalClose?(): void
	inventoryState: {
		dataSource: P[]
		transformedData: P[]
		loading: boolean
	}
	OrderFormSettings: ISettings
}

export const OrderFormInventoryTable: React.ComponentType<
	InventoryTableProps<InventoryItemType>
> = ({
	sortObj,
	onSort,
	onRowClick,
	selectedInventory = [],
	inventoryState = { dataSource: [], loading: false, transformedData: [] },
	OrderFormSettings
}) => {
	const theme = useTheme()
	const { colors } = theme.palette

	const {
		settings: { displaySellingPrice }
	} = OrderFormSettings

	const RenderRow = useCallback(
		({ row }: { row: InventoryItemType }) => (
			<InventoryRow
				row={row}
				selectedInventory={selectedInventory}
				OrderFormSettings={OrderFormSettings}
				onRowClick={onRowClick}
				disabled={
					row.stock[0] &&
					(row.stock[0].quantity <= 0 || !row.stock[0].stockAvailable)
				}
			/>
		),
		[onRowClick, selectedInventory, OrderFormSettings]
	)

	return (
		<>
			<TableHead>
				<MedCell align={'left'} width={50} hidden={true} />
				<ProductCell
					id={'name'}
					sortDirection={sortObj.sortOrder}
					onClick={() => onSort('name')}
				>
					<TableSortLabel
						active={sortObj.sortBy === 'name'}
						direction={sortObj.sortOrder}
					>
						<Typography
							variant={'body2'}
							style={{ color: colors.gray['500'] }}
							fontWeight={'500'}
						>
							Item Name
						</Typography>
					</TableSortLabel>
				</ProductCell>
				{displaySellingPrice && (
					<MedCell align="left" width={100} hidden={true}>
						<Typography variant={'caption'}>
							Selling Price
						</Typography>
					</MedCell>
				)}
				<MedCell align="left" width={100} hidden={true}>
					<Typography variant={'caption'}>Stock</Typography>
				</MedCell>
			</TableHead>
			<StyledTableBody>
				{inventoryState.transformedData.map(row => (
					<RenderRow key={`${row.stock[0].id}`} row={row} />
				))}
			</StyledTableBody>
		</>
	)
}
