import React, { useMemo } from 'react'
import {
	dateFormat,
	LEDGER_CAPSULE_COLOR,
	LEDGER_INVOICE_TYPE,
	PaymentMethodOptions,
	SEGMENT_EVENTS,
	TRANSACTION_MAPPER
} from 'data'
import {
	TableCell,
	Tooltip,
	tooltipClasses,
	type TooltipProps,
	Typography,
	useTheme
} from '@mui/material'
import { styled } from '@mui/system'
import { type CapsuleProps, Gutter } from 'components'
import { FlexRow } from 'components/atoms/Flex'
import type { ColorsType, TransactionProps } from 'typings'
import { MdNavigateNext, MdOutlineNote } from 'react-icons/md'
import moment from 'moment'
import { amountToLocal, formatId } from 'helpers'
import analytics from 'utils/segment'

const Cell = styled(TableCell, {
	shouldForwardProp: props => props !== 'isVoid'
})<{ isVoid?: boolean }>`
	color: ${({ theme }) => theme.palette.colors.gray['500']};
	padding: 7px 7px 7px 24px;
`

const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
	<Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
	[`& .${tooltipClasses.arrow}`]: {
		color: theme.palette.common.black
	},
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: theme.palette.common.black
	}
}))

const SmallCell = styled(TableCell, {
	shouldForwardProp: props => props !== 'isVoid'
})<{ isVoid?: boolean }>`
	color: ${({ theme }) => theme.palette.colors.gray['500']};
	padding: 7px 7px 7px 24px;
	min-width: 150px;
	max-width: 200px;
`

const Text = styled(Typography)`
	font-weight: 500;
`

const StyledCapsule = styled(FlexRow, {
	shouldForwardProp: props =>
		props !== 'disabled' && props !== 'isSelected' && props !== 'color'
})<Pick<CapsuleProps, 'isSelected' | 'color' | 'disabled'>>`
	background-color: ${({ theme, color, isSelected }) =>
		isSelected
			? theme.palette.colors[color || 'gray']['100']
			: theme.palette.colors[color || 'gray']['50']};
	padding: 5px 10px;
	border-radius: 25px;
	margin: 5px 0;
	color: ${({ theme, color }) =>
		theme.palette.colors[color || 'gray']['700']};
	align-items: center;
	justify-content: center;

	white-space: nowrap;

	:hover {
		cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};
	}
`

const Amount = styled(TableCell)<{ isVoid?: boolean }>`
	background-color: ${({ theme }) => theme.palette.colors.gray['100']};
	color: ${({ theme }) => theme.palette.colors.gray['500']};
	padding: 7px 24px 7px 7px;
`

const DetailsSubText = styled(Typography)`
	font-size: 10px;
	color: ${({ theme }) => theme.palette.colors.gray['600']};
`

export const LedgerItem: React.ComponentType<{
	row: TransactionProps
	onItemSelect?(item: TransactionProps): void
	totalItem?: number
}> = ({ row, onItemSelect, totalItem }) => {
	const theme = useTheme()
	const {
		palette: { colors }
	} = theme

	const paymentStatusColor: ColorsType =
		LEDGER_CAPSULE_COLOR[row.metaData.invoice?.paymentStatus || 'pending']

	const selectedColor: ColorsType =
		row.metaData.invoice?.status === 'void' ? 'red' : paymentStatusColor
	const amountColor: ColorsType =
		row.type === 'opening_balance'
			? row.amount < 0
				? 'red'
				: 'green'
			: LEDGER_INVOICE_TYPE[row.type]

	const isVoid = row.metaData.invoice?.status === 'void'

	const PaymentMethodText = useMemo(() => {
		if (!row.metaData.payment) return undefined
		if (!row.metaData.payment.method) return undefined
		return PaymentMethodOptions.find(
			d => row.metaData.payment?.method === d.value
		)?.label
	}, [row.metaData.payment])
	const PaymentCreationDate = row.metaData?.recordedDate

	return (
		<>
			<SmallCell
				variant="head"
				align={'left'}
				isVoid={isVoid}
				width={150}
			>
				<Text variant={'caption'}>
					{moment(row.createdAt).format(dateFormat)}
				</Text>
			</SmallCell>
			<SmallCell
				variant="head"
				align={'left'}
				isVoid={isVoid}
				width={170}
			>
				{row.metaData.invoice ? (
					<StyledCapsule
						isSelected={isVoid}
						color={selectedColor}
						onClick={() => {
							analytics.track(
								SEGMENT_EVENTS.LEDGER_INVOICE_CLICK,
								{
									invoiceId: row.id,
									friendlyId: row.metaData.invoice?.friendlyId
								}
							)
							onItemSelect?.(row)
						}}
					>
						<FlexRow align={'center'}>
							<Typography variant={'caption'}>{`INV-${formatId(
								row.metaData.invoice.friendlyId || 0
							)}`}</Typography>

							<Gutter gap={0.5} />
							<MdNavigateNext
								color={colors[selectedColor]['700']}
								size={20}
							/>
						</FlexRow>
					</StyledCapsule>
				) : (
					<Text
						variant={'caption'}
						textAlign={'left'}
						style={{ marginLeft: 20 }}
					>
						N/A
					</Text>
				)}
			</SmallCell>
			<Cell variant="head" align={'left'} isVoid={isVoid}>
				<FlexRow align={'center'}>
					<Text
						variant={'caption'}
						textAlign={'left'}
						style={{
							overflowWrap: 'break-word'
						}}
					>
						{TRANSACTION_MAPPER[row.type]}
					</Text>
					<Gutter gap={0.4} />
					{row.metaData.payment && row.metaData.payment.note && (
						<BootstrapTooltip title={row.metaData.payment.note}>
							<FlexRow>
								<MdOutlineNote
									color={colors.yellow['500']}
									size={'20'}
								/>
							</FlexRow>
						</BootstrapTooltip>
					)}
				</FlexRow>
				<FlexRow align={'center'}>
					{PaymentMethodText && (
						<DetailsSubText>{PaymentMethodText}</DetailsSubText>
					)}
					{PaymentMethodText && PaymentCreationDate && (
						<>
							<DetailsSubText>{','}</DetailsSubText>
							<Gutter gap={0.2} />
						</>
					)}
					{PaymentCreationDate && (
						<DetailsSubText>
							{moment(PaymentCreationDate).format('DD MMM YYYY')}
						</DetailsSubText>
					)}
				</FlexRow>
			</Cell>
			<Cell
				variant="head"
				align={'right'}
				isVoid={isVoid}
				sx={{ paddingRight: '24px' }}
			>
				<FlexRow justify={'flex-end'} align={'center'}>
					<Text variant={'caption'} color={amountColor}>
						{/*TODO:// Add currency here */}
						PKR {amountToLocal(Math.abs(row.amount))}
					</Text>
				</FlexRow>
			</Cell>
			<Amount variant="head" align={'right'} width={260}>
				<Text variant={'caption'} sx={{ color: colors.gray['900'] }}>
					PKR{' '}
					{amountToLocal(row.contact?.ledger?.runningBalance || 0)}
				</Text>
			</Amount>
		</>
	)
}
