import React, { useMemo } from 'react'
import type { ButtonProps as MUIButtonProps } from '@mui/material'
import { Button as MUIButton, useTheme } from '@mui/material'
import { styled } from '@mui/material/styles'
import type { ColorsCode, ColorsType } from 'typings'

const TButton = styled(MUIButton, {
	shouldForwardProp: props =>
		props !== 'backgroundColor' &&
		props !== 'textColor' &&
		props !== 'hoverColor'
})<{
	backgroundColor: string
	textColor: string
	hoverColor: string
}>`
	font-weight: 600;
	color: ${({ textColor }) => textColor};
	background-color: ${({ backgroundColor }) => backgroundColor};
	:hover {
		background-color: ${({ hoverColor }) => hoverColor};
	}
	padding: 10px 50px;
`

type PrimaryBtnProps = {
	buttonColor?: ColorsCode
	textColor?: ColorsCode
	mode?: 'primary'
}

type SecondaryBtnProps = {
	buttonColor?: never
	textColor?: ColorsCode
	mode?: 'secondary'
}

type BtnProps = PrimaryBtnProps | SecondaryBtnProps

export type ButtonProps = BtnProps & {
	children?: React.ReactNode
} & MUIButtonProps

export const Button: React.ComponentType<ButtonProps> = ({
	children,
	buttonColor,
	textColor,
	mode,
	...props
}) => {
	const theme = useTheme()
	const {
		palette: { colors }
	} = theme

	const isThemeColor = (str: string) => {
		return str.indexOf('#') !== -1
	}

	const backgroundColor = useMemo(() => {
		if (buttonColor && isThemeColor(buttonColor)) return buttonColor

		return mode === 'secondary'
			? colors[textColor as ColorsType]['50']
			: buttonColor
			? colors[buttonColor as ColorsType]['800']
			: colors['green']['800']
	}, [mode, textColor, buttonColor, colors])

	const hoverColor = useMemo(() => {
		// Not the ideal way to deal it, only work with hex value
		if (buttonColor && isThemeColor(buttonColor)) return buttonColor + 'aa'

		return mode === 'secondary'
			? colors[textColor as ColorsType]['100']
			: colors[(buttonColor as ColorsType) || 'green']['500']
	}, [buttonColor, mode, textColor, colors])

	const labelColor = useMemo(() => {
		if (textColor && isThemeColor(textColor)) return textColor

		return textColor
			? colors[textColor as ColorsType]['800']
			: colors[theme.palette.mode === 'light' ? 'white' : 'black']['800']
	}, [theme.palette.mode, textColor, colors])

	return (
		<TButton
			variant="contained"
			backgroundColor={backgroundColor}
			textColor={labelColor}
			hoverColor={hoverColor}
			disableElevation
			{...props}
		>
			{children}
		</TButton>
	)
}
