import React, { useMemo } from 'react'
import {
	Button,
	ClickAwayListener,
	Fade,
	Popper,
	styled,
	Typography,
	useMediaQuery,
	useTheme
} from '@mui/material'
import PopupState, { bindPopper, bindToggle } from 'material-ui-popup-state'
import { DatePicker, Gutter } from 'components'
import { FlexCol, FlexRow } from 'components/atoms/Flex'
import DateRangeIcon from '@mui/icons-material/DateRange'
import CloseIcon from '@mui/icons-material/Close'
import moment from 'moment'
import {
	dateFormat,
	LAYOUT_BREAKPOINT_WIDTH,
	SECONDARY_BUTTON_BREAKPOINT_WIDTH
} from 'data'

const StyledButton = styled(Button, {
	shouldForwardProp: props => props !== 'isFilter'
})<{ isFilter?: boolean }>`
	background-color: ${({ theme, isFilter }) =>
		isFilter
			? theme.palette.colors.green[50]
			: theme.palette.background.default};
	border: 1px solid
		${({ theme, isFilter, disabled }) =>
			disabled
				? theme.palette.colors.gray[300]
				: isFilter
				? theme.palette.colors.green[300]
				: theme.palette.colors.gray[300]};
	align-items: center;
	min-width: 0;
	min-height: 32px;
	border-radius: 5px;
	padding: 0px 9px;

	color: ${({ theme, disabled }) =>
		disabled
			? theme.palette.colors.gray['300']
			: theme.palette.colors.gray['700']};
	width: max-content;
`

const StyledButtonText = styled(Typography)`
	overflow-wrap: normal;
`

const Wrapper = styled(FlexCol)`
	background-color: ${({ theme }) => theme.palette.background.default};
	border: 1px solid ${({ theme }) => theme.palette.background.paper};
	border-radius: 8px;
	margin-top: 5px;
	padding: 10px 16px;
	box-shadow: 0 12px 16px -4px rgba(16, 24, 40, 0.08),
		0px 4px 6px -2px rgba(16, 24, 40, 0.03);
`

const ClearButton = styled(Button)`
	background-color: ${({ theme }) => theme.palette.colors.green['50']};
	color: ${({ theme }) => theme.palette.colors.green['700']};
	border-radius: 8px;
	margin-top: 10px;
	align-self: flex-end;
	:hover {
		background: ${({ theme }) => theme.palette.colors.green['100']};
	}
	:disabled {
		background: ${({ theme }) => theme.palette.colors.gray['100']};
		color: ${({ theme }) => theme.palette.colors.gray['400']};
	}
`

const SecondaryButtonWrapper = styled(FlexRow)`
	@media (max-width: ${SECONDARY_BUTTON_BREAKPOINT_WIDTH}px) {
		display: none;
	}
`

type FilterObjProps = { [key: string]: string }

type FilterButtonProps = {
	onSelect?(filterObj: FilterObjProps): void
	filterObj: FilterObjProps
	placeholder?: string
	disabled?: boolean
}

export const DateFilter: React.ComponentType<FilterButtonProps> = ({
	onSelect,
	filterObj,
	placeholder,
	disabled
}) => {
	const theme = useTheme()

	const isMobile = useMediaQuery(`(max-width:${LAYOUT_BREAKPOINT_WIDTH}px)`)

	const isFilter = useMemo(() => {
		return !(!filterObj?.startDate && !filterObj?.endDate)
	}, [filterObj])

	const filterButtonText = useMemo(() => {
		if (isFilter) {
			const startText = filterObj.startDate
				? moment(filterObj.startDate).format(dateFormat)
				: 'Start'
			const endText = filterObj.endDate
				? moment(filterObj.endDate).format(dateFormat)
				: 'Present'
			return `${startText} - ${endText}`
		} else {
			return placeholder ? placeholder : 'Filter By Date'
		}
	}, [filterObj, isFilter])

	const applyFilter = (key: string, dateString: string | null) => {
		const newObj = {
			...filterObj,
			[key]: dateString || ''
		}
		onSelect?.(newObj)
	}

	const clearFilter = () => {
		const newObj = {
			...filterObj,
			['startDate']: '',
			['endDate']: ''
		}
		onSelect?.(newObj)
	}

	const styledButtonIconStyle = {
		color: theme.palette.colors.gray['900']
	}

	return (
		<PopupState
			variant="popper"
			popupId="demo-popup-popper"
			disableAutoFocus={true}
		>
			{popupState => (
				<ClickAwayListener onClickAway={() => popupState.close()}>
					<div>
						<StyledButton
							{...bindToggle(popupState)}
							isFilter={isFilter}
							disabled={disabled}
						>
							{(!isFilter || isMobile) && (
								<DateRangeIcon
									style={styledButtonIconStyle}
									fontSize="small"
								/>
							)}
							<SecondaryButtonWrapper>
								<Gutter gap={0.3} />
								<StyledButtonText
									variant="caption"
									fontWeight={500}
								>
									{filterButtonText}
								</StyledButtonText>
								<Gutter gap={0.3} />
							</SecondaryButtonWrapper>
							{isFilter && !isMobile && (
								<CloseIcon
									onClick={e => {
										e.stopPropagation()
										clearFilter()
									}}
									style={styledButtonIconStyle}
									fontSize="small"
								/>
							)}
						</StyledButton>
						<Popper
							{...bindPopper(popupState)}
							transition
							placement="bottom-start"
						>
							{({ TransitionProps }) => (
								<Fade {...TransitionProps} timeout={350}>
									<Wrapper>
										<FlexRow>
											<DatePicker
												slotProps={{
													textField: {
														placeholder:
															'Start Date'
													}
												}}
												value={
													filterObj?.startDate || ''
												}
												onChange={e => {
													applyFilter('startDate', e)
												}}
											/>
											<Gutter gap={0.8} />
											<DatePicker
												slotProps={{
													textField: {
														placeholder: 'End Date'
													}
												}}
												value={filterObj?.endDate || ''}
												onChange={e => {
													applyFilter('endDate', e)
												}}
											/>
										</FlexRow>
										<ClearButton
											onClick={clearFilter}
											disabled={!isFilter}
										>
											<Typography
												variant="caption"
												fontWeight={'600'}
												fontSize={'12px'}
											>
												Clear
											</Typography>
										</ClearButton>
									</Wrapper>
								</Fade>
							)}
						</Popper>
					</div>
				</ClickAwayListener>
			)}
		</PopupState>
	)
}
