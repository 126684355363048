import { axios } from 'store'
import type { DataResponseType, InventoryItemType, InventoryListPayloadType, InvoiceType } from 'typings'
import type { IBookerProps } from "typings/orderbooker"
import queryString from 'query-string'

export const createOrderBooker = async (payload: IBookerProps) => {
	const url = '/business/bookers'

	try {
		const { data } = await axios.post(url, payload)

		return data
	} catch (error: any) {
		throw error.response.data
	}
}

export const updateOrderBooker = async (
	payload: Partial<IBookerProps>,
	id: string
) => {
	const url = `/business/bookers/${id}`

	try {
		const { data } = await axios.patch(url, payload)

		return data
	} catch (error: any) {
		throw error.response.data
	}
}

export const getBookerInfo = async (id: string) => {
	const url = `/open-api/order-booker?id=${id}`

	try {
		const { data } = await axios.get(url)

		return data
	} catch (error: any) {
		throw error.response.data
	}
}

export const processOrderBookerForm = async (payload: InvoiceType) => {
	const url = `/open-api/process-order-booker-form`

	try {
		const { data } = await axios.post(url, payload)

		return data
	} catch (error: any) {
		throw error.response.data
	}
}

export const getOpenOrderBookerInventoryList = async (
	businessId: string,
	payload: Partial<InventoryListPayloadType>
) => {
	const url = `/open-api/order-booker-inventory?business=${businessId}&${queryString.stringify(
		payload
	)}`

	try {
		const { data } = await axios.get<DataResponseType<InventoryItemType>>(
			url
		)

		return data
	} catch (error: any) {
		throw error.response.data
	}
}
