import React from 'react'
import { styled } from '@mui/system'
import { Typography } from '@mui/material'
import { Gutter } from 'components'
import { currencyInput } from 'helpers'
import { FlexRow } from 'components/atoms/Flex'

const Grid = styled('div')`
	display: flex;
	flex-direction: column;
	column-gap: 10px;
`

export const LoanDetails: React.ComponentType<any> = ({ loanDetail }) => {
	return (
		<Grid>
			<FlexRow>
				<Typography fontWeight={500}>Total loan amount :</Typography>
				<Gutter gap={0.2} />
				<Typography>
					PKR {currencyInput(`${loanDetail.required_financing}`)}
				</Typography>
			</FlexRow>
			<Gutter spacing={0.5} />
			<FlexRow>
				<Typography fontWeight={500}>Tenure :</Typography>
				<Gutter gap={0.2} />
				<Typography>{loanDetail.tenure}</Typography>
			</FlexRow>
			<Gutter spacing={0.5} />
			<FlexRow>
				<Typography fontWeight={500}>Mark up type :</Typography>
				<Gutter gap={0.2} />
				<Typography>{loanDetail.type}</Typography>
			</FlexRow>
		</Grid>
	)
}
