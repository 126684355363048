import React from 'react'
import { InputAdornment, Skeleton, Typography } from '@mui/material'
import { Abbr, FieldCurrencyInput, FieldTextInput, Gutter } from 'components'
import { styled } from '@mui/system'
import { useFormikContext } from 'formik'
import type { CurrentInvoiceProps } from 'screen/Invoices/NewCreate/Invoice'
import { FlexRow } from 'components/atoms/Flex'
import { IoIosClose } from 'react-icons/io'
import analytics from 'utils/segment'
import { SEGMENT_EVENTS } from 'data'

const Grid = styled('div')`
	display: grid;
	width: 100%;
	grid-template-columns: 1fr 1fr;
	align-items: flex-start;
	justify-content: space-between;
`

const Box = styled(FlexRow)`
	padding-left: 10px;
	border-left: 1px solid ${({ theme }) => theme.palette.colors.gray['300']};
`

const Row = styled(FlexRow)`
	width: 100%;
	justify-content: flex-end;
	flex-wrap: nowrap;
	align-items: center;
`

const Button = styled(Typography)`
	color: ${({ theme }) => theme.palette.colors['blue']['600']};

	:hover {
		cursor: pointer;
	}
`

const Icon = styled(IoIosClose)`
	:hover {
		cursor: pointer;
	}
`

export const AddExtraCharges = () => {
	const { values, setFieldValue } = useFormikContext<CurrentInvoiceProps>()

	return (
		<>
			{values.loading ? (
				<Skeleton variant="rectangular" width={410} height={400} />
			) : (
				<>
					{values.extraCharges &&
						values.extraCharges.map((t, i) => (
							<>
								<Grid key={t.id}>
									<FieldTextInput
										name={`extraCharges[${i}].description`}
										size={'small'}
										placeholder={'eg delivery'}
										style={{ maxWidth: 200, width: '100%' }}
									/>

									<Row>
										<FieldCurrencyInput
											name={`extraCharges[${i}].amount`}
											size={'small'}
											placeholder={'eg 250'}
											style={{
												maxWidth: 200,
												width: '100%'
											}}
											onBlurCapture={() =>
												analytics.track(
													SEGMENT_EVENTS.INVOICE_EXTRA_CHARGES
												)
											}
											InputProps={{
												endAdornment: (
													<InputAdornment position="end">
														<Box>
															<Typography>
																<Abbr
																	title={
																		'PKR'
																	}
																	length={10}
																/>
															</Typography>
														</Box>
													</InputAdornment>
												)
											}}
										/>
										<Gutter gap={0.2} />
										<Icon
											size={25}
											onClick={() => {
												setFieldValue(
													'extraCharges',
													values.extraCharges?.filter(
														(f, p) => {
															return i !== p
														}
													)
												)
											}}
										/>
									</Row>
								</Grid>
								<Gutter spacing={0.5} />
							</>
						))}
					<Grid>
						<Button
							onClick={() =>
								setFieldValue('extraCharges', [
									...(values.extraCharges || []),
									{
										description: '',
										amount: 0,
										id: new Date().toString()
									}
								])
							}
						>
							<Typography variant={'subtitle2'}>
								Add Extra Charge
							</Typography>
						</Button>
					</Grid>
					<Gutter />
				</>
			)}
		</>
	)
}
