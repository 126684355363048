import React from 'react'
import { styled } from '@mui/system'
import { FlexCol, FlexRow } from 'components/atoms/Flex'
import { Divider, Typography, useTheme } from '@mui/material'
import { useFormikContext } from 'formik'
import type { CurrentInvoiceProps } from 'screen/Invoices/NewCreate/Invoice'
import { CheckBox, FormatAmount, Gutter } from 'components'
import { invoice_calculation } from 'data'
import type { InvoiceType } from 'typings'
import { InvoiceTaxes } from 'screen/Invoices/NewCreate/Components/InvoiceTaxes'
import { AddExtraCharges } from 'screen/Invoices/NewCreate/Components/AddExtraChanges'
import { AdvancePayment } from 'screen/Invoices/NewCreate/Components/AdvancePayment'
import { Discount } from 'screen/Invoices/NewCreate/Components/Discount'

const Grid = styled('div')`
	display: grid;

	width: 100%;

	grid-template-columns: 1fr 1fr;
	justify-content: space-between;
	align-items: center;
`

const Row = styled(FlexRow)`
	width: 100%;
	justify-content: flex-end;
`

const Wrapper = styled(FlexCol)`
	padding: 24px;
	border-radius: 16px;
	width: 100%;
	background-color: ${({ theme }) => theme.palette.colors['gray']['50']};
`

const Col = styled(FlexCol)`
	width: 100%;
`

const StyledDivider = styled(Divider)`
	width: 100%;
	margin: 13px 0;
`

export const Summary = () => {
	const theme = useTheme()
	const {
		palette: { colors }
	} = theme
	const { values } = useFormikContext<CurrentInvoiceProps>()
	const { totalAmount, totalItemCost, amountDue } = invoice_calculation(
		values as InvoiceType
	)
	const { setFieldValue } = useFormikContext()

	return (
		<Col>
			<Wrapper>
				<Grid>
					<Typography
						variant={'subtitle2'}
						color={colors['gray']['900']}
					>
						Total Items
					</Typography>
					<Row>
						<Typography align={'right'}>
							{values.items?.length}
						</Typography>
						<Gutter gap={1.8125} />
					</Row>
				</Grid>
				<Gutter />
				<Grid>
					<Typography
						variant={'subtitle2'}
						color={colors['gray']['900']}
					>
						Sub Total
					</Typography>
					<Row>
						<FormatAmount
							variant={'subtitle2'}
							align={'right'}
							fontWeight={400}
							limit={15}
							amount={totalItemCost}
							style={{
								color: totalItemCost >= 0 ? 'black' : 'red'
							}}
						/>
						<Gutter gap={1.8125} />
					</Row>
				</Grid>
				<Gutter />
				<InvoiceTaxes />
				<StyledDivider light={true} />
				<AddExtraCharges />
				<Gutter />
				<Discount />
				<StyledDivider light={true} />
				<Grid>
					<Typography variant={'subtitle2'} fontWeight={700}>
						Grand Total
					</Typography>
					<Row>
						<FormatAmount
							variant={'subtitle2'}
							align={'right'}
							fontWeight={700}
							limit={15}
							amount={totalAmount}
							style={{
								color: totalAmount >= 0 ? 'black' : 'red'
							}}
						/>
						<Gutter gap={1.8125} />
					</Row>
				</Grid>
				<Gutter />
				{!values.loading && <AdvancePayment />}
				<StyledDivider light={true} />
				<Grid>
					<Typography variant={'h6'} fontWeight={700}>
						Amount Due (PKR)
					</Typography>
					<Row>
						<FormatAmount
							variant={'h6'}
							align={'right'}
							fontWeight={700}
							limit={15}
							amount={amountDue}
							style={{
								color: amountDue >= 0 ? 'black' : 'red'
							}}
						/>
						<Gutter gap={1.8125} />
					</Row>
				</Grid>
			</Wrapper>
			<Gutter />
			<Wrapper>
				<Grid>
					<FlexRow align={'center'}>
						<CheckBox
							checked={!!values.includeBalance}
							onClick={() =>
								setFieldValue(
									'includeBalance',
									!values.includeBalance
								)
							}
						/>
						<Gutter gap={0.6} />
						<Typography variant="caption" fontWeight={'500'}>
							Include Previous Balance (PKR)
						</Typography>
					</FlexRow>
					<Row>
						{values.includeBalance && (
							<Typography variant="caption" fontWeight={'500'}>
								{values?.ledgerBalance?.amount}
							</Typography>
						)}
					</Row>
				</Grid>
			</Wrapper>
		</Col>
	)
}
