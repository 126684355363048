import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Button, Divider, styled } from '@mui/material'
import { useAppErrors, useDimension } from 'hooks'
import { FlexCol, FlexRow } from 'components/atoms/Flex'
import type { QuotationType } from 'typings'
import { QuotationPdf } from 'screen/Quotations/View/QuotationPdf'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { getAQuotation } from 'api/quotation'
import { RightOption } from 'screen/Quotations/View/RightWrapper'
import { setNavigation } from 'store/app/actions'
import { QUOTATION_VIEW_NAVIGATION } from 'data'
import { useDispatch } from 'react-redux'
import { SCROLLBAR_STYLE } from 'data'

const DividerWrapper = styled(Divider)`
	border: 1.5px solid ${({ theme }) => theme.palette.colors.green['100']};
	margin: 0 10px 0 0;
`

const Wrapper = styled('div')<{ height: number }>`
	display: grid;
	grid-template-columns: 3fr 0.5fr 2fr;
	max-height: 100%;
	height: ${({ height }) => (height ? `${height}px` : '100%')};
	width: 100%;
	overflow: hidden;
	padding: 16px;
`

const Col = styled(FlexCol)<{ height: number }>`
	width: 100%;
	height: ${({ height }) => (height ? `${height}px` : '100%')};
	overflow: auto;
	margin: 10px;
	border-radius: 10px;

	box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.33);
	-webkit-box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.33);
	-moz-box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.33);

	${SCROLLBAR_STYLE}
`

const TransparentButton = styled(Button)`
	background-color: ${({ theme }) => theme.palette.background.default};
	border: 1px solid ${({ theme }) => theme.palette.colors.gray['300']};
	color: ${({ theme }) => theme.palette.colors.gray['700']};
	border-radius: 8px;
	width: 150px;
	margin: 10px 0 0 20px;
`

export const QuotationView = () => {
	const [currentQuotation, setCurrentQuotation] = useState<QuotationType>()
	const { state } = useLocation()
	const { id } = useParams()
	const { setAppError } = useAppErrors()
	const { height } = useDimension()
	const componentRef = useRef(null)
	const navigate = useNavigate()
	const dispatch = useDispatch()

	const dispatchTitle = useCallback(
		(quo: QuotationType) => {
			dispatch(
				setNavigation(
					QUOTATION_VIEW_NAVIGATION(
						quo?.friendlyId || '0',
						quo.id || '0'
					)
				)
			)
		},
		[dispatch]
	)

	const getQuotationDetails = useCallback(async () => {
		try {
			const res = await getAQuotation(id || '0')
			dispatchTitle(res)
			setCurrentQuotation(res)
		} catch (e: any) {
			setAppError(e)
		}
	}, [dispatchTitle, setAppError, id])

	useEffect(() => {
		if (state?.data) {
			dispatchTitle(state?.data)
			setCurrentQuotation(state?.data)
		} else {
			getQuotationDetails()
		}
	}, [dispatchTitle, getQuotationDetails, id, state?.data])

	return (
		<>
			{currentQuotation && (
				<Wrapper height={height - 60}>
					<Col height={height - 110}>
						<QuotationPdf
							ref={componentRef}
							title={`Quotation-${currentQuotation.id}`}
							currentQuotation={currentQuotation}
						/>
					</Col>
					<div>
						<TransparentButton
							onClick={() =>
								navigate(`/quotation/create-quotation/${id}`, {
									state: { quotation: currentQuotation }
								})
							}
						>
							Edit Quotation
						</TransparentButton>
					</div>
					<FlexRow justify="space-around">
						<DividerWrapper orientation="vertical" flexItem />
						<RightOption
							currentQuotation={currentQuotation}
							pdfRef={componentRef}
						/>
					</FlexRow>
				</Wrapper>
			)}
		</>
	)
}
