import React, { useState } from 'react'
import { styled } from '@mui/system'
import { Button, Typography } from '@mui/material'
import { FlexCol, FlexRow } from 'components/atoms/Flex'

import type { LoadsheetType } from 'typings/loadsheet'
import { formatId } from 'helpers'
import { ActionButton } from './ActionButton'
import { BouncingDotsLoader, Gutter } from 'components'
import { PrintOutlined } from '@mui/icons-material'
import { getALoadSheet, getSignedUrlForLoadSheetPdf } from 'api'
import { useSettings } from 'context/settings'

const Wrapper = styled(FlexRow)`
	width: 100%;
	padding: 10px 16px 9px 16px;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid ${({ theme }) => theme.palette.colors.gray['200']};
`

const TopButton = styled(Button)`
	border-radius: 8px;
	padding: 0 12px;
	border: 1px solid ${({ theme }) => theme.palette.colors.gray['300']};
	color: ${({ theme }) => theme.palette.colors.gray['700']};
	box-shadow: 0 1px 2px 0 rgba(21, 29, 45, 0.08);
	height: 36px;
`

type LoadSheetHeaderProps = {
	loadSheet: LoadsheetType
}

export const LoadSheetHeader: React.ComponentType<LoadSheetHeaderProps> = ({
	loadSheet
}) => {
	const { business } = useSettings()
	const [printing, setIsPrinting] = useState(false)

	const printButtonClick = async () => {
		// Print Button Functionality comes here
		setIsPrinting(true)
		const loadSheetForPdf = await getALoadSheet(
			business,
			loadSheet.id || ''
		)
		const res: any = await getSignedUrlForLoadSheetPdf([loadSheetForPdf])

		window.open(res.data.signedUrl, '_blank')
		setIsPrinting(false)
	}

	return (
		<>
			<Wrapper>
				<FlexCol>
					<Typography
						variant="body1"
						fontWeight={500}
					>{`LS-${formatId(
						parseInt(loadSheet?.friendlyId || '')
					)}`}</Typography>
				</FlexCol>

				<FlexRow>
					<TopButton onClick={printButtonClick}>
						{!printing && (
							<>
								<Typography variant={'subtitle2'}>
									Print
								</Typography>
								<Gutter gap={0.2} />
								<PrintOutlined sx={{ fontSize: '16px' }} />
							</>
						)}
						{printing && (
							<>
								<BouncingDotsLoader />
							</>
						)}
					</TopButton>
					<Gutter gap={0.7} />
					<ActionButton loadSheet={loadSheet} />
				</FlexRow>
			</Wrapper>
		</>
	)
}
