import React from 'react'
import type { ModalProps } from '@mui/material'
import { FlexCol } from 'components/atoms/Flex'
import { styled } from '@mui/system'
import CloseIcon from '@mui/icons-material/Close'
import { PinSetup } from 'screen/Settting/Privacy/PinSetup/PinSetup'
import { UnlockScreen } from 'screen/Settting/Privacy/PinSetup/Lock'
import { StyledModal } from 'components/StyledModal'

const Root = styled('div')`
	position: absolute;
	width: 100%;
	height: 100%;
	z-index: 1;
	backdrop-filter: blur(5px);
`
const Container = styled(FlexCol)`
	position: relative;
	padding: 20px;
	width: 500px;
	height: 500px;
	border-radius: 10px;
	z-index: 200;
	background-color: ${({ theme }) => theme.palette.background.default};
`

const CenterBox = styled(FlexCol)`
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
`

const CloseButton = styled(FlexCol)`
	position: absolute;
	top: 20px;
	right: 20px;
	align-self: flex-end;

	:hover {
		cursor: pointer;
		border-radius: 25%;
		background-color: ${({ theme }) => theme.palette.colors.gray['100']};
		display: flex;
	}
`

type PinModalProps = {} & Omit<ModalProps, 'children'>

export const LockModal = React.forwardRef<HTMLDivElement, PinModalProps>(
	({ open, onClose, ...props }, ref) => {
		const lockPin = localStorage.getItem('pin')

		return (
			<StyledModal
				ref={ref}
				open={open}
				sx={{
					display: 'flex',
					flexDirection: 'column'
				}}
				onClose={onClose}
				{...props}
			>
				<>
					{' '}
					<Root />
					<Container>
						{!lockPin && (
							<CloseButton
								onClick={() => onClose?.({}, 'escapeKeyDown')}
							>
								<CloseIcon />
							</CloseButton>
						)}
						<CenterBox>
							{!lockPin && <PinSetup onClose={onClose} />}
							{lockPin && <UnlockScreen onClose={onClose} />}
						</CenterBox>
					</Container>
				</>
			</StyledModal>
		)
	}
)

LockModal.displayName = 'LockModal'
