import * as React from 'react'
import { InputAdornment, styled, Typography } from '@mui/material'
import {
	Button,
	FieldNumberInput,
	FieldTextInput,
	Gutter,
	StyledModal
} from 'components'
import { FlexCol, FlexRow } from 'components/atoms/Flex'
import { Formik } from 'formik'
import { useAppErrors } from 'hooks'
import * as Yup from 'yup'
import { CONTACTS_INITIAL_VALUE } from 'data'
import type { IBookerProps, IContactProps } from 'typings'
import { createOpenContact } from 'api/contacts'
import {
	CONTACT_NAME_FORMAT,
	CONTACT_NUMBER_FORMAT,
	objectRemoveEmpty
} from 'helpers'
import { MdBusiness, MdPersonOutline } from 'react-icons/md'
import { TransparentButton } from 'components/atoms/Button/TransparentButton'

const Container = styled(FlexRow)`
	max-width: 90%;
	width: auto;
	height: auto;
	border-radius: 8px;
	padding: 20px;
	background-color: ${({ theme }) => theme.palette.background.default};
`

const Wrapper = styled(FlexCol)`
	width: 100%;
	height: 100%;
`
type CreateNewCustomerModalProps = {
	open: boolean
	onClose(): void
	onSuccess(contact: IContactProps): void
	orderBooker: IBookerProps
}

const validationSchema = Yup.object().shape({
	name: Yup.string()
		.matches(
			CONTACT_NAME_FORMAT,
			'Name can only contain letters, numbers, and spaces'
		)
		.required('Required')
		.max(100, 'Name must be less than 100 characters'),
	address: Yup.string().required('Required'),
	phone: Yup.object().shape({
		number: Yup.string()
			.required('Required')
			.matches(CONTACT_NUMBER_FORMAT, 'Invalid Phone number')
	})
})
export const CreateNewCustomerModal: React.ComponentType<
	CreateNewCustomerModalProps
> = ({ open, onClose, orderBooker, onSuccess }) => {
	const { setAppError } = useAppErrors()

	const onSubmit = async (values: any, actions: any) => {
		try {
			actions.setSubmitting(true)
			const businessRelationship = ['customer']

			const obj = objectRemoveEmpty(
				{
					...values,
					businessRelationship
				},
				['email']
			)

			const contact = await createOpenContact({
				...obj,
				business: orderBooker.businessId,
				balance: {
					...obj.balance,
					amount:
						obj.balance.to === 'toPay'
							? obj.balance.amount * -1
							: obj.balance.amount
				}
			})
			onClose()
			onSuccess?.(contact)
		} catch (e: any) {
			setAppError(e, actions)
		} finally {
			actions.setSubmitting(false)
		}
	}
	return (
		<StyledModal
			onClose={onClose}
			aria-labelledby="customized-dialog-title"
			open={open}
		>
			<Container>
				<Gutter spacing={4} />

				<FlexCol style={{ width: '100%' }}>
					<Typography fontWeight={'600'}>
						Create New Customer
					</Typography>
					<Gutter spacing={0.8} />

					<Formik
						initialValues={{
							...CONTACTS_INITIAL_VALUE,
							selectedBank: 0,
							selectedTab: 'openingBalance',
							user: orderBooker.user
						}}
						validationSchema={validationSchema}
						onSubmit={(values, actions) => {
							onSubmit(values, actions)
						}}
					>
						{({ handleSubmit, ...actions }) => (
							<Wrapper>
								<Typography variant="caption">
									Customer Name*
								</Typography>
								<div style={{ width: '100%' }}>
									<FieldTextInput
										name={'name'}
										sx={{ marginTop: 1, width: '100%' }}
										size={'small'}
										InputProps={{
											startAdornment: (
												<FlexRow>
													<MdPersonOutline
														size={15}
														style={{
															marginRight: '5px'
														}}
													/>
												</FlexRow>
											)
										}}
									/>

									<Gutter spacing={1} />
									<Typography variant="caption">
										Contact Number*
									</Typography>
									<FieldNumberInput
										name={'phone.number'}
										size={'small'}
										sx={{ width: '100%' }}
										InputProps={{
											startAdornment: (
												<InputAdornment position="start">
													<Typography>
														{'+92 '}
													</Typography>
												</InputAdornment>
											)
										}}
										inputProps={{ maxLength: 10 }}
									/>
									<Gutter spacing={1} />
									<Typography variant="caption">
										Business Location*
									</Typography>
									<FieldTextInput
										name={'address'}
										sx={{ marginTop: 1, width: '100%' }}
										size={'small'}
										InputProps={{
											startAdornment: (
												<FlexRow>
													<MdBusiness
														size={15}
														style={{
															marginRight: '5px'
														}}
													/>
												</FlexRow>
											)
										}}
									/>
								</div>

								<Gutter spacing={2} />
								<FlexRow
									justify={'flex-end'}
									align={'center'}
									style={{
										width: '100%'
									}}
								>
									<TransparentButton
										onClick={() => {
											onClose()
										}}
									>
										Cancel
									</TransparentButton>
									<Gutter gap={0.3} />
									<Button
										type="submit"
										disabled={
											actions.isSubmitting ||
											!actions.dirty
										}
										onClick={() => {
											handleSubmit()
										}}
										style={{ padding: '5px 20px' }}
									>
										Save Contact
									</Button>
								</FlexRow>
							</Wrapper>
						)}
					</Formik>
				</FlexCol>
			</Container>
		</StyledModal>
	)
}
