import React from 'react'
import type { TextFieldProps } from '@mui/material'
import {
	inputLabelClasses,
	outlinedInputClasses,
	TextField,
	useTheme
} from '@mui/material'
import { styled } from '@mui/system'
import { Label } from 'components/atoms/Label'
import { BiErrorCircle } from 'react-icons/bi'
import { FlexRow } from 'components/atoms/Flex'

const StyledTextField = styled(TextField)(({ theme, disabled }) => ({
	borderRadius: 10,
	backgroundColor: theme.palette.background.default,

	[`& .${outlinedInputClasses.root} .${outlinedInputClasses.notchedOutline}`]:
		{ borderColor: theme.palette.colors.gray['300'] },
	[`&:hover .${outlinedInputClasses.root} .${outlinedInputClasses.notchedOutline}`]:
		{
			borderColor: !disabled
				? theme.palette.colors.gray['900']
				: undefined
		},

	[`& .${outlinedInputClasses.root} .${outlinedInputClasses.disabled}`]: {
		backgroundColor: theme.palette.background.paper
	},

	[`& .${outlinedInputClasses.root}.${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline}`]:
		{ borderColor: theme.palette.colors.green['300'] },

	[`& .${outlinedInputClasses.input}`]: {
		color: theme.palette.primary.contrastText
	},

	[`&:hover .${outlinedInputClasses.input}`]: {
		color: theme.palette.primary.contrastText
	},

	[`& .${outlinedInputClasses.root}.${outlinedInputClasses.focused} .${outlinedInputClasses.input}`]:
		{
			color: theme.palette.primary.contrastText
		},

	[`& .${inputLabelClasses.outlined}`]: {
		color: theme.palette.colors.gray['900']
	},

	[`&:hover .${inputLabelClasses.outlined}`]: {
		color: theme.palette.colors.gray['900']
	},

	[`& .${inputLabelClasses.outlined}.${inputLabelClasses.focused}`]: {
		color: theme.palette.colors.gray['900']
	},

	[`& .${outlinedInputClasses.error}`]: {
		border: `1px solid ${theme.palette.colors.red['500']}`
	}
}))

export type TextInputProps = Omit<TextFieldProps, 'label'> & {
	title?: string
}

export const TextInput: React.ComponentType<TextInputProps> = ({
	title,
	...rest
}) => {
	const theme = useTheme()

	return (
		<>
			{title && (
				<FlexRow style={{ width: '100%' }} align={'flex-start'}>
					<Label>{title}</Label>
				</FlexRow>
			)}
			<StyledTextField
				className="textInput"
				InputLabelProps={{ shrink: false }}
				InputProps={{
					endAdornment: rest.error && (
						<BiErrorCircle
							color={theme.palette.colors['red']['500']}
						/>
					)
				}}
				{...rest}
			/>
		</>
	)
}
