export const ORDER_BOOKER_INITIAL_DATA = {
	businessId: '',
	user: '',
	name: '',
	phone: {
		countryCode: '92',
		number: ''
	},
	profilePic: ''
}

export const ORDER_BOOKER_FORM_INITIAL_VALUES = {
	items: [],
	contact: {
		name: '',
		phone: {
			number: ''
		}
	},
	comments: '',
	paymentMethod: ''
}
