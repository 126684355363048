import React, { useCallback, useMemo } from 'react'
import analytics from 'utils/segment'
import { AnimatePresence, motion } from 'framer-motion'
import { Tooltip, Typography, useTheme } from '@mui/material'
import type { SideBarDataSourcesProps } from 'data'
import { styled } from '@mui/system'
import { NavLink, useLocation } from 'react-router-dom'
import { useTranslate } from 'hooks'

const Icons = styled('div')`
	display: flex;
`

const LinkText = styled(motion.div)`
	white-space: nowrap;
	height: min-content;
`

const StyledNavLink = styled(NavLink, {
	shouldForwardProp: props => props !== 'isSideBarOpen'
})<{ isSideBarOpen: boolean }>`
	text-decoration-line: none;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	column-gap: ${props => (props.isSideBarOpen ? '8px' : '0')};
	border-radius: 6px;
	row-gap: 10px;
	width: 100%;
	height: 36px;
	margin: 4px 0;
	padding: 8px;
	color: ${({ theme }) => theme.palette.colors['white']['25']};

	:hover {
		cursor: pointer;
		background-color: ${({ theme }) => theme.palette.colors['white']['50']};
		color: ${({ theme }) => theme.palette.colors['gray']['600']};
	}
`

const ShowAnimation = {
	hidden: {
		width: 0,
		opacity: 0,
		transition: {
			duration: 0.2
		}
	},
	show: {
		width: 'auto',
		opacity: 1,
		transition: {
			duration: 0.2
		}
	}
}

type SidebarItemProps = {
	item: SideBarDataSourcesProps
	isOpen?: boolean
}

export const SidebarItem: React.ComponentType<SidebarItemProps> = ({
	item,
	isOpen
}) => {
	const translate = useTranslate()
	const location = useLocation()
	const theme = useTheme()

	const {
		palette: { colors }
	} = theme

	const isActiveStyle = useMemo(() => {
		return {
			backgroundColor: theme.palette.colors.green['700'],
			color: theme.palette.colors.green['900']
		}
	}, [theme.palette.colors.green])

	const isActive = useCallback(
		(item: SideBarDataSourcesProps) => {
			return location.pathname.startsWith(item.activePath)
		},
		[location.pathname]
	)

	const activeStyle = useCallback(
		(isActive: boolean, path: string) => {
			return location.pathname.startsWith(path) ? isActiveStyle : {}
		},
		[isActiveStyle, location.pathname]
	)

	const selectedStyle = useCallback(
		(item: SideBarDataSourcesProps) => {
			return isActive(item)
				? colors['white']['25']
				: colors['grayGreen']['600']
		},
		[colors, isActive]
	)

	return (
		<Tooltip
			title={!isActive(item) && !isOpen && translate(item.name)}
			placement="right"
		>
			<StyledNavLink
				style={({ isActive }) => activeStyle(isActive, item.activePath)}
				to={item.path}
				key={item.name}
				isSideBarOpen={!!isOpen}
				onClick={() => analytics.track(item.segmentEvent)}
			>
				<Icons>
					{isActive(item) ? (
						<item.selectedIcons
							size={20}
							color={selectedStyle(item)}
						/>
					) : (
						<item.icons size={20} color={selectedStyle(item)} />
					)}
				</Icons>
				<AnimatePresence>
					{isOpen && (
						<LinkText
							variants={ShowAnimation}
							initial={'hidden'}
							animate={'show'}
							exit={'hidden'}
						>
							<Typography
								variant={'subtitle2'}
								color={selectedStyle(item)}
							>
								{translate(item.name)}
							</Typography>
						</LinkText>
					)}
				</AnimatePresence>
			</StyledNavLink>
		</Tooltip>
	)
}
