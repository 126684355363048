import React from 'react'
import { truncate } from 'helpers'
import { styled } from '@mui/system'

const ABBR = styled('abbr')`
	border-bottom: none !important;
	cursor: inherit !important;
	text-decoration: none !important;
`

type AbbrProps = {
	title: string
	length: number
}

export const Abbr: React.ComponentType<AbbrProps> = ({
	title = '',
	length = 1
}) => {
	return (
		<>
			{title.length > length ? (
				<ABBR title={title}>{truncate(title, length)}</ABBR>
			) : (
				truncate(title, length)
			)}
		</>
	)
}
