import { Autocomplete, TextField, Typography, useTheme } from '@mui/material'
import { getOpenContactList } from 'api/contacts'
import { useField } from 'formik'
import React, { useCallback, useEffect, useState } from 'react'
import type { IBookerProps, IContactProps } from 'typings'
import { MdOutlinePerson, MdOutlinePersonAdd } from 'react-icons/md'
import { FlexRow } from '../../../components/atoms/Flex'
import { CreateNewCustomerModal } from './CreateNewCustomerModal'
import { Gutter } from 'components'
type ContactsDropdownProps = {
	name: string
	formId: string
	orderBooker: IBookerProps
}

export const ContactsDropdown: React.ComponentType<ContactsDropdownProps> = ({
	name,
	formId,
	orderBooker
}) => {
	const [contacts, setContacts] = useState<IContactProps[]>([])
	const [open, setOpen] = useState<boolean>(false)
	const [field, meta, helpers] = useField(name)
	const error = meta.touched && meta.error
	const theme = useTheme()
	const { colors } = theme.palette

	const getContacts = useCallback(
		async (searchText: string) => {
			try {
				if (searchText) {
					const allContacts = await getOpenContactList({
						formId: formId as string,
						page: 1,
						limit: 20,
						searchKey: searchText
					})
					setContacts(allContacts.edges)
				} else setContacts([])
			} catch (e) {
				alert('Invalid link')
			}
		},
		[formId]
	)

	const handleOnchange = (newValue: any) => {
		if (!newValue) {
			helpers.setValue({
				name: '',
				phone: {
					number: ''
				}
			})
			setContacts([])
		} else helpers.setValue(newValue)
		// helpers.setValue(newValue)
	}

	// useEffect(() => {
	// 	getContacts('')
	// }, [])

	return (
		<>
			<Autocomplete
				id="contact-dropdown"
				options={contacts}
				getOptionLabel={contact =>
					`${contact.name}${
						contact.phone.number
							? `\n - 0${contact.phone.number}`
							: ''
					}`
				}
				value={field.value}
				autoHighlight
				onChange={(_, newValue) => {
					handleOnchange(newValue)
				}}
				noOptionsText={
					<FlexRow
						align="center"
						onClick={() => setOpen(true)}
						style={{ cursor: 'pointer' }}
					>
						<MdOutlinePersonAdd />
						<Gutter gap={0.3} />
						<Typography>Create New Customer</Typography>
					</FlexRow>
				}
				renderInput={params => (
					<TextField
						onChange={e => getContacts(e.target.value)}
						{...params}
						InputProps={{
							...params.InputProps,
							endAdornment: (
								<React.Fragment>
									{/* This is the Clear (cross) icon */}
									{params.InputProps.endAdornment}
								</React.Fragment>
							),
							startAdornment: (
								<FlexRow>
									<MdOutlinePerson size={20} />
								</FlexRow>
							)
						}}
					/>
				)}
				sx={{ width: '100%' }}
				renderOption={(props, option, state) => (
					<li {...props} style={{ backgroundColor: 'white' }}>
						<div
							style={{
								alignItems: 'center'
							}}
						>
							<Typography variant="subtitle2">
								{option.name}{' '}
								<Typography display={'none'}>
									{option.phone.number}
								</Typography>
							</Typography>
							{option.phone.number && (
								<div>
									<Typography
										variant="caption"
										color={colors.gray['500']}
									>
										{`0${option.phone.number}`}
									</Typography>
								</div>
							)}
							<Typography
								variant="caption"
								color={colors.gray['500']}
							>
								{`${option.address}`}
							</Typography>
						</div>
					</li>
				)}
			/>
			<CreateNewCustomerModal
				open={open}
				onClose={() => setOpen(false)}
				orderBooker={orderBooker}
				onSuccess={contact => helpers.setValue(contact)}
			/>
		</>
	)
}
