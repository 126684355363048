import React, { type FC, type ReactElement, type ReactNode } from 'react'
import type { CheckboxProps } from '@mui/material/Checkbox'
import { useField } from 'formik'
import { styled } from '@mui/material/styles'
import { FlexRow } from 'components/atoms/Flex'
import { CheckBox, ErrorText } from 'components/atoms'

const Container = styled(FlexRow)`
	align-items: center;
	width: 100%;
	gap: 10px;
`

export type CheckBoxFieldProps = {
	children: ReactNode
	name: string
} & Omit<CheckboxProps, 'onChange' | 'value' | 'id'>

export const FieldCheckBox: FC<CheckBoxFieldProps> = ({
	children,
	name,
	...props
}): ReactElement => {
	const [, meta, helpers] = useField(name as string)

	return (
		<Container>
			<CheckBox
				id={name}
				value={meta.value}
				onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
					helpers.setValue(event.target.checked)
				}
				{...props}
			/>
			{children}
			{meta.touched && meta.error && <ErrorText text={meta.error} />}
		</Container>
	)
}
