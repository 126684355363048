import React from 'react'
import { FieldTextInput } from 'components'
import { styled } from '@mui/material'
import { useTranslate } from 'hooks'

const StyledFieldTextInput = styled(FieldTextInput)`
	width: 100%;
`

const ContactContainer = styled('div')`
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 10px 20px;
	align-items: flex-start;

	@media screen and (max-width: 750px) {
		grid-template-columns: 1fr;
	}
`

export const CommentsTab = () => {
	const translate = useTranslate()
	return (
		<ContactContainer>
			<StyledFieldTextInput
				multiline
				rows={2}
				name="comments"
				placeholder={translate('contacts.form.placeholder.comments')}
			/>
		</ContactContainer>
	)
}
