import React, { useCallback, useEffect, useState } from 'react'
import { styled } from '@mui/system'
import { FlexCol, FlexRow } from 'components/atoms/Flex'
import { Paper, Typography } from '@mui/material'
import { TableVirtuoso } from 'react-virtuoso'
import { useAppErrors, useDimension } from 'hooks'
import type { DataResponseType, InvoiceType, ResponsePageInfo } from 'typings'
import { getInvoiceList } from 'api/invoice'
import { useSettings } from 'context/settings'
import { AllInvoiceTableComponent } from 'screen/LoadSheet/Create/AllInvoiceTable/AllInvoiceTableComponents'
import { AllInvoiceHeader } from 'screen/LoadSheet/Create/AllInvoiceTable/AllInvoiceHeader'
import { AllInvoiceItem } from 'screen/LoadSheet/Create/AllInvoiceTable/AllInvoiceTableItem'
import { invoice_calculation } from 'data'

const StyledPaper = styled(Paper)`
	position: relative;
	width: 100%;
	height: 100%;
	background: ${({ theme }) => theme.palette.colors.white['500']};
	z-index: 0;
`

const Table = styled(TableVirtuoso)`
	width: 100%;
	background: ${({ theme }) => theme.palette.colors.white['500']};
`

const Wrapper = styled(FlexCol)`
	background-color: ${({ theme }) => theme.palette.background.default};
	width: 100%;
	border-radius: 16px;
	border: 1px solid ${({ theme }) => theme.palette.colors.gray['300']};
	box-shadow: 0 4px 6px -2px rgba(16, 24, 40, 0.03),
		0px 12px 16px -4px rgba(16, 24, 40, 0.08);
	overflow: hidden;
`

const AbsoluteWrapper = styled(FlexRow)`
	width: 100%;
	z-index: 1;
	align-items: center;
	justify-content: center;
	position: absolute;
	height: 100%;
`

type AllInvoicesProps = {
	searchKey: string
	onItemSelect?(inv: InvoiceType): void
	showIncludedInvoice: boolean
	filterObj: { [key: string]: string }
}

export const AllInvoices: React.ComponentType<AllInvoicesProps> = ({
	searchKey,
	onItemSelect,
	showIncludedInvoice,
	filterObj
}) => {
	const [loading, setLoading] = useState<boolean>(true)
	const [dataSource, setDataSource] = useState<InvoiceType[]>([])
	const [filteredDataSource, setFilterDataSource] = useState<InvoiceType[]>(
		[]
	)
	const [pageInfo, setPageInfo] = useState<ResponsePageInfo>({
		totalPages: 1,
		currentPage: 1,
		edgesPerPage: 20
	})

	const { height } = useDimension()
	const { business } = useSettings()
	const { setAppError } = useAppErrors()

	const getInitialInvoiceList = useCallback(async () => {
		try {
			setLoading(true)
			const res: DataResponseType<InvoiceType> = await getInvoiceList(
				business,
				{
					page: 1,
					limit: pageInfo.edgesPerPage,
					...filterObj,
					orderBooker:
						filterObj?.orderBooker === ''
							? undefined
							: filterObj?.orderBooker,
					searchKey,
					status: 'publish',
					hasLoadSheet: showIncludedInvoice
				}
			)
			const processedData = res.edges.map(e => {
				return {
					...e,
					totalCostAmount: invoice_calculation(e).totalAmount
				}
			})

			const data = processedData.filter(m => m.hasLoadSheet === false)

			setDataSource(processedData)
			setFilterDataSource(showIncludedInvoice ? processedData : data)
			setPageInfo(res.pageInfo)
		} catch (e: any) {
			setAppError(e)
		} finally {
			setLoading(false)
		}
	}, [
		business,
		filterObj,
		pageInfo.edgesPerPage,
		searchKey,
		showIncludedInvoice,
		setAppError
	])

	const fetchInvoiceList = async () => {
		try {
			setLoading(true)
			if (
				!loading &&
				pageInfo?.totalPages &&
				pageInfo?.totalPages >= pageInfo.currentPage + 1
			) {
				const res: DataResponseType<InvoiceType> = await getInvoiceList(
					business,
					{
						page: pageInfo.currentPage + 1,
						limit: pageInfo.edgesPerPage,
						...filterObj,
						orderBooker:
							filterObj?.orderBooker === ''
								? undefined
								: filterObj?.orderBooker,
						searchKey,
						status: 'publish'
					}
				)
				const processedData = res.edges.map(e => {
					return {
						...e,
						totalCostAmount: invoice_calculation(e).totalAmount
					}
				})

				const allData = [...dataSource, ...processedData]
				const filteredData = allData.filter(
					m => m.hasLoadSheet === false
				)

				setDataSource(allData)
				setFilterDataSource(
					showIncludedInvoice ? allData : filteredData
				)
				setPageInfo(res.pageInfo)
			}
		} catch (e: any) {
			setAppError(e)
		} finally {
			setLoading(false)
		}
	}

	useEffect(() => {
		getInitialInvoiceList()
	}, [getInitialInvoiceList])

	return (
		<Wrapper>
			<StyledPaper
				style={{
					height: height - 56 * 4 - 80
				}}
			>
				{searchKey && filteredDataSource.length === 0 && (
					<AbsoluteWrapper>
						<Typography>
							No entry found for &quot;{`${searchKey}`}&quot;
						</Typography>
					</AbsoluteWrapper>
				)}
				<Table
					data={filteredDataSource}
					components={AllInvoiceTableComponent}
					fixedHeaderContent={AllInvoiceHeader}
					itemContent={(_index: number, row: any) => (
						<AllInvoiceItem
							key={row.id}
							onRowClick={() =>
								onItemSelect?.(row as InvoiceType)
							}
							invoice={row}
							{...row}
						/>
					)}
					endReached={() => {
						fetchInvoiceList()
					}}
					//onScroll={onScroll}
				/>
			</StyledPaper>
		</Wrapper>
	)
}
