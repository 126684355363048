import React from 'react'
import * as yup from 'yup'
import { MdLockOutline } from 'react-icons/md'
import { Typography, useTheme } from '@mui/material'
import { FieldOtpTextInput, Gutter } from 'components'
import { FlexCol } from 'components/atoms/Flex'
import { styled } from '@mui/system'

const Wrapper = styled(FlexCol)`
	width: 320px;
	align-items: center;
	justify-content: center;
`

export const FormStep2Schema = yup.object().shape({
	confirmPin: yup
		.string()
		.trim()
		.required('Required')
		.oneOf([yup.ref('pin')], 'Passwords must match')
})

type Form1Props = {
	title: string
}
export const Form2: React.ComponentType<Form1Props> = ({ title }) => {
	const theme = useTheme()
	return (
		<Wrapper>
			<MdLockOutline size={60} color={theme.palette.colors.green[900]} />
			<Gutter />
			<Typography fontWeight={500}>{title}</Typography>
			<Gutter spacing={2} />
			<FieldOtpTextInput name={'confirmPin'} />
			<Gutter spacing={2} />
		</Wrapper>
	)
}
