type P = {
	[key: string]: {
		color: 'green' | 'red' | 'yellow' | 'blue' | 'black'
		text: string
		hide?: boolean
	}
}

export const SNACKBAR_OPTIONS: P = {
	success: {
		color: 'green',
		text: 'Success'
	},
	error: {
		color: 'red',
		text: 'Error'
	},
	warning: {
		color: 'yellow',
		text: 'Warning'
	},
	info: {
		color: 'blue',
		text: 'Info'
	},
	default: {
		color: 'red',
		text: 'Default',
		hide: true
	}
}
