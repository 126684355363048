import React, { useCallback, useState } from 'react'
import Cropper from 'react-easy-crop'
import { styled } from '@mui/system'
import getCroppedImg from './cropImage'
import ImageCompressor from 'utils/image-compression'
import { FlexRow } from 'components/atoms/Flex'
import { Gutter } from 'components'
import { Button, Slider, Typography } from '@mui/material'
import type { Area } from 'react-easy-crop/types'
import { useTranslate } from 'hooks'

const ControlsContainer = styled('div')`
	padding: 16px 30px;
	text-align: center;
`

const Wrapper = styled('div')`
	background-color: ${({ theme }) => theme.palette.background.default};
	padding: 10px 0;
	max-width: 400px;
	border-radius: 10px;
`

type CROP_SHAPES = 'round' | 'rect'

interface ImageCropProps {
	image: string
	allowShapeChange?: boolean
	cropShape?: CROP_SHAPES
	showGrid?: boolean
	aspect?: any
	onImageCropped?: (image: File) => void
	onCropCancelled?: () => void
}

const ImageCrop = ({
	image,
	cropShape = 'rect',
	showGrid = true,
	aspect = 1,
	onImageCropped,
	onCropCancelled
}: ImageCropProps) => {
	const ZOOM_SCALING_FACTOR = 100
	const [shape] = useState(cropShape)
	const [gridActive] = useState(showGrid)
	const [crop, setCrop] = useState<{
		x: number
		y: number
	}>({ x: 0, y: 0 })
	const [zoom, setZoom] = useState(1)
	const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area>()
	const [, setCroppedImage] = useState<Blob | null>(null)

	const onCropComplete = useCallback(
		(croppedArea: Area, croppedAreaPixels: Area) => {
			setCroppedAreaPixels(croppedAreaPixels)
		},
		[]
	)

	const translate = useTranslate()

	const showCropResult = useCallback(async () => {
		try {
			let resultImage = await getCroppedImg(image, croppedAreaPixels)

			// if image data is null no need to proceed forward
			if (!resultImage) {
				return
			}

			// compress the image now
			resultImage = await ImageCompressor(resultImage)

			const uploadDate = new Date()

			const theFile = new File(
				[resultImage],
				`${uploadDate.getTime()}.jpeg`,
				{
					type: 'image/jpeg'
				}
			)

			onImageCropped && onImageCropped(theFile)

			setCroppedImage(resultImage)
		} catch (error) {
			console.error(error)
		}
	}, [onImageCropped, image, croppedAreaPixels])

	const defaultAspectRatio = 1
	const aspectRatio =
		shape === 'round' ? defaultAspectRatio : aspect || defaultAspectRatio

	return (
		<Wrapper>
			<div style={{ textAlign: 'center' }}>
				<Typography variant={'h5'}>
					{translate('molecules.imageCrop.editPhoto')}
				</Typography>
				<Gutter spacing={1} />
				<Typography
					variant={'body2'}
					sx={{
						maxWidth: 332,
						margin: '0 auto 32px',
						fontSize: 14
					}}
				>
					{translate('molecules.imageCrop.uploadPhototypeSize')}
				</Typography>
			</div>
			<div className="crop-container">
				<div
					style={{
						position: 'relative',
						width: '100%',
						height: '40vh',
						maxHeight: 300
					}}
				>
					<Cropper
						style={{
							containerStyle: {
								background: '#09264A',
								height: '40vh',
								width: '100%',
								maxHeight: 300
							}
						}}
						cropShape={shape}
						showGrid={gridActive}
						image={image}
						crop={crop}
						zoom={zoom}
						aspect={aspectRatio}
						onCropChange={setCrop}
						onCropComplete={onCropComplete}
						onZoomChange={setZoom}
					/>
				</div>
				<ControlsContainer>
					<Typography
						variant={'caption'}
						sx={{
							textAlign: 'center',
							color: 'black'
						}}
					>
						{translate('molecules.imageCrop.dragPhoto')}
					</Typography>
					<Slider
						defaultValue={50}
						aria-label="Default"
						valueLabelDisplay="auto"
						min={ZOOM_SCALING_FACTOR}
						max={3 * ZOOM_SCALING_FACTOR}
						value={Math.floor(zoom * ZOOM_SCALING_FACTOR)}
						onChange={(e: any) => {
							setZoom(e.target.value / ZOOM_SCALING_FACTOR)
						}}
					/>
				</ControlsContainer>
			</div>
			<FlexRow
				justify="space-between"
				align="center"
				style={{ margin: 32 }}
			>
				<Button
					variant="text"
					onClick={onCropCancelled}
					sx={{ color: 'black' }}
				>
					{translate('molecules.imageCrop.cancel')}
				</Button>

				<Button
					variant="contained"
					onClick={showCropResult}
					sx={{ color: 'white' }}
				>
					{translate('molecules.imageCrop.save')}
				</Button>
			</FlexRow>
		</Wrapper>
	)
}

export { ImageCrop }
