import * as React from 'react'
import { TableCell, TableRow, Typography } from '@mui/material'
import { styled } from '@mui/system'

const Cell = styled(TableCell)`
	background-color: ${({ theme }) => theme.palette.colors.gray['50']};
	color: ${({ theme }) => theme.palette.colors.gray['500']};
	padding: 7px 7px 7px 24px;
`

const Text = styled(Typography)`
	color: ${({ theme }) => theme.palette.colors.gray['500']};
	font-weight: 500;
`

const SmallCell = styled(TableCell, {
	shouldForwardProp: props => props !== 'isVoid'
})`
	background-color: ${({ theme }) => theme.palette.colors.gray['50']};
	color: ${({ theme }) => theme.palette.colors.gray['500']};
	padding: 7px 7px 7px 24px;
	min-width: 150px;
	max-width: 200px;
`

export const RPHeader = () => {
	return (
		<TableRow>
			<Cell variant="head" align={'left'}>
				<Text variant={'subtitle1'} fontWeight={500}>
					Method
				</Text>
			</Cell>
			<Cell variant="head" align={'left'}>
				<Text variant={'subtitle1'} fontWeight={500}>
					Amount
				</Text>
			</Cell>
			<SmallCell variant="head" align={'left'}>
				<Text variant={'subtitle1'} fontWeight={500}>
					Status
				</Text>
			</SmallCell>
			<SmallCell variant="head" align={'left'}>
				<Text variant={'subtitle1'} fontWeight={500}>
					Payment Date
				</Text>
			</SmallCell>

			<SmallCell variant="head" align={'left'}>
				<Text variant={'subtitle1'} fontWeight={500}>
					Updated At
				</Text>
			</SmallCell>
		</TableRow>
	)
}
