import { formatId } from 'helpers'

export const INVOICE_VIEW_NAVIGATION = (friendlyId: string, id: string) => {
	return [
		{ activeUrl: '/invoices', title: 'Invoices' },
		{
			activeUrl: `/invoice/view/${id}`,
			title: `Invoice ${formatId(friendlyId)}`
		}
	]
}

export const QUOTATION_VIEW_NAVIGATION = (friendlyId: string, id: string) => {
	return [
		{ activeUrl: '/quotation', title: 'Quotation' },
		{
			activeUrl: `/quotation/view/${id}`,
			title: `QUO ${formatId(friendlyId)}`
		}
	]
}

export const INVOICE_EDIT_NAVIGATION = (friendlyId: string, id: string) => {
	return [
		{ activeUrl: '/invoices', title: 'Invoices' },
		{
			activeUrl: `/invoice/create-invoice/${id}`,
			title: `${
				id.toString() === '0'
					? 'Create Invoice'
					: `Edit - INV ${formatId(friendlyId)}`
			}`
		}
	]
}

export const LOAD_SHEET_EDIT_NAVIGATION = (friendlyId: string, id: string) => {
	return [
		{
			activeUrl: '/load-sheet',
			title: 'Load Sheet'
		},
		{
			activeUrl: `/load-sheet/create-load-sheet/${id}`,
			title: `${
				id.toString() === '0'
					? 'Create Load Sheet'
					: `Edit: LS-${formatId(friendlyId)}`
			}`
		}
	]
}

export const QUOTATION_EDIT_NAVIGATION = (friendlyId: string, id: string) => {
	return [
		{ activeUrl: '/quotation', title: 'Quotation' },
		{
			activeUrl: `quotation/create-quotation/${id}`,
			title: `${
				id.toString() === '0'
					? 'Create Quotation'
					: `Edit - QUO ${formatId(friendlyId)}`
			}`
		}
	]
}

export const LEDGER_NAVIGATION = (name: string, mob: string, id: string) => {
	return [
		{ activeUrl: '/contacts', title: 'Contacts' },
		{
			activeUrl: `/contacts/ledger/${id}`,
			title: `${name} - (${mob})`
		}
	]
}

export const USER_PERMISSION_NAVIGATION = () => {
	return [
		{ activeUrl: '/settings/inventory', title: 'Settings' },
		{
			activeUrl: '/settings/userRoles',
			title: 'User and Roles'
		}
	]
}
