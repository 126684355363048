import { type CBFSActionTypes, FETCH_CBFS_INFO, SET_CBFS_INFO } from './types'

export const fetchAllInfo = (business: string): CBFSActionTypes => ({
	type: FETCH_CBFS_INFO,
	businessId: business
})

export const setCBFSInfo = (payload: any): CBFSActionTypes => ({
	type: SET_CBFS_INFO,
	payload
})
