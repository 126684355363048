import produce from 'immer'
import type { IAPIError, IBusiness } from 'typings'
import {
	type BusinessActionTypes,
	FETCH_ALL_BUSINESS,
	FETCH_ALL_BUSINESS_ERROR,
	FETCH_ALL_BUSINESS_SUCCESS,
	SET_BUSINESS_LOADING,
	SET_BUSINESS_TERMS_CONDITION
} from 'store/business/types'

interface BusinessState {
	loading: boolean
	businesses: IBusiness[]
	errors: IAPIError[]
}

const initialState: BusinessState = {
	loading: false,
	businesses: [],
	errors: []
}

const reducer = produce((draft: BusinessState, action: BusinessActionTypes) => {
	switch (action.type) {
		case FETCH_ALL_BUSINESS:
			draft.loading = true
			return

		case FETCH_ALL_BUSINESS_SUCCESS:
			draft.loading = false
			draft.businesses = action.payload
			return

		case FETCH_ALL_BUSINESS_ERROR:
			draft.errors = action.payload
			return

		case SET_BUSINESS_LOADING:
			draft.loading = action.payload
			return

		case SET_BUSINESS_TERMS_CONDITION:
			draft.businesses = draft.businesses.map(m => {
				if (m.id === action.businessId) {
					return { ...m, termsAndConditions: action.terms }
				}
				return m
			})
			return

		default:
			return
	}
}, initialState)

export default reducer
