import React, { useCallback, useEffect, useState } from 'react'
import { styled } from '@mui/system'
import { FlexCol, FlexRow } from 'components/atoms/Flex'
import { DatePicker, FieldMultipleLineInput, Gutter, Label } from 'components'
import { useFormikContext } from 'formik'
import type { CurrentInvoiceProps } from 'screen/Invoices/NewCreate/Invoice'
import { CustomAutoComplete } from 'components/app/CutomAutoComplete'
import { dateFormat, PaymentMethodOptions, SEGMENT_EVENTS } from 'data'
import type { InvoiceSettingsType, PaymentMethod } from 'typings'
import moment from 'moment/moment'
import { pascalCase } from 'helpers'
import { useTheme } from '@mui/material'
import analytics from 'utils/segment'
import { useSettings } from 'context/settings'
import { getInvoiceSettings } from 'api'
import { useAppErrors } from 'hooks'

const Wrapper = styled(FlexCol)`
	width: min-content;
	min-width: 410px;
`

const Container = styled(FlexRow)`
	flex-wrap: nowrap;
	width: 100%;
`

const Row = styled(FlexRow)`
	padding: 10px;
	width: 100%;
`

const Col = styled(FlexCol)`
	max-width: 200px;
`

type PaymentMethodT = { value: PaymentMethod; label: string }

export const PaymentTerms = () => {
	const [invoiceSettings, setInvoiceSettings] =
		useState<InvoiceSettingsType>()
	const { business } = useSettings()
	const { setAppError } = useAppErrors()
	const theme = useTheme()
	const {
		palette: { colors }
	} = theme

	const { values, setFieldValue, initialValues } =
		useFormikContext<CurrentInvoiceProps>()

	const isVisible = values?.isCustomTermsAndCondition
		? true
		: values?.status === 'draft'
		? invoiceSettings?.settings.displayTermsAndConditions
		: !!values?.termsAndConditions

	const RenderOption = ({ opt, ...props }: { opt: PaymentMethodT }) => {
		return (
			<Row
				onClick={() => {
					setFieldValue('paymentMethod', opt.value)
					analytics.track(SEGMENT_EVENTS.INVOICE_ADD_PAYMENT_METHOD, {
						payment_method_selected: opt.value
					})
				}}
			>
				{opt.label}
			</Row>
		)
	}

	const getInitialInvoiceSettings = useCallback(async () => {
		try {
			const invoiceSettings = await getInvoiceSettings(business)
			setInvoiceSettings(invoiceSettings)
		} catch (err: any) {
			setAppError(err)
		}
	}, [business, setAppError])

	useEffect(() => {
		getInitialInvoiceSettings()
	}, [getInitialInvoiceSettings])

	return (
		<Wrapper>
			<Label>Payment Terms</Label>
			<Gutter spacing={0.1} />
			<Container>
				<CustomAutoComplete
					options={PaymentMethodOptions}
					renderOption={(props, option) => (
						<RenderOption opt={option} {...props} />
					)}
					size={'small'}
					style={{ maxWidth: 200, width: '100%' }}
					value={pascalCase(
						values.paymentMethod === 'unpaid'
							? ''
							: values.paymentMethod || ''
					)}
					filterOptions={(i, p) => {
						return i.filter(r =>
							r.label
								.toLowerCase()
								.includes((p.inputValue || '').toLowerCase())
						)
					}}
					onClick={() =>
						analytics.track(
							SEGMENT_EVENTS.INVOICE_CLICK_PAYMENT_METHOD
						)
					}
					placeholder={'Method'}
				/>
				<Gutter gap={0.5} />
				<Col>
					<DatePicker
						slotProps={{
							textField: {
								placeholder: 'Due Date',
								style: { borderRadius: 10 }
							}
						}}
						format={dateFormat}
						value={values.paymentTerms?.dueDate}
						onChange={e => {
							setFieldValue(
								'paymentTerms.dueDate',
								moment.utc(e).format().toString()
							)
							analytics.track(
								SEGMENT_EVENTS.INVOICE_DUE_DATE_SELECTED,
								{
									due_date_selected: moment
										.utc(e)
										.format()
										.toString()
								}
							)
						}}
						maxDate={moment().add(3, 'years')}
					/>
				</Col>
			</Container>
			<Gutter gap={0.5} />
			<Label>Customer Notes</Label>
			<Gutter spacing={0.1} />
			<FieldMultipleLineInput
				multiline
				limit={2000}
				rows={3}
				name={'comments'}
				placeholder={'eg: thanks for your business'}
				onBlurCapture={() =>
					analytics.track(SEGMENT_EVENTS.INVOICE_COMMENTS)
				}
			/>
			<Label style={{ color: colors['gray']['600'] }}>
				Will be displayed on the invoice
			</Label>
			{isVisible && (
				<>
					<Gutter gap={0.5} />
					<Label>Terms and Conditions</Label>
					<Gutter spacing={0.1} />
					<FieldMultipleLineInput
						multiline
						limit={2000}
						rows={3}
						name={'termsAndConditions'}
						placeholder={'Terms and Conditions'}
						onBlur={() => {
							if (
								values.termsAndConditions !==
								initialValues.termsAndConditions
							) {
								setFieldValue('isCustomTermsAndCondition', true)
							} else {
								setFieldValue(
									'isCustomTermsAndCondition',
									false
								)
							}
						}}
					/>
				</>
			)}
		</Wrapper>
	)
}
