import { axios } from 'store'

export const registerLoan = async (payload: any) => {
	const url = '/auth/cbfs/loan'

	try {
		const { data } = await axios.post(url, payload)

		return data
	} catch (error: any) {
		throw error.response.data
	}
}

export const loanRePayment = async (payload: any) => {
	const url = '/auth/cbfs/loan/repayment'

	try {
		const { data } = await axios.post(url, payload)

		return data
	} catch (error: any) {
		throw error.response.data
	}
}

export const loanRePaymentList = async (id: string) => {
	const url = `/auth/cbfs/loan/repayment?loanId=${id}`

	try {
		const { data } = await axios.get(url)

		return data
	} catch (error: any) {
		throw error.response.data
	}
}

export const getABank = async (BUSINESS: string) => {
	const url = `/auth/cbfs/loan/repayment/account?business=${BUSINESS}`

	try {
		const { data } = await axios.get(url)

		return data
	} catch (error: any) {
		throw error.response.data
	}
}

export const getLoanList = async (id?: string) => {
	let url = '/auth/cbfs/loan'

	if (id) {
		url += `/${id}`
	}

	try {
		const { data } = await axios.get(url)

		return data
	} catch (error: any) {
		throw error.response.data
	}
}

export const loanCalculator = async (
	principal: string,
	tenure: string,
	userId: string
) => {
	const url = `/auth/cbfs/loan/predict/balance?principalAmount=${principal}&tenure=${tenure}&user_id=${userId}`

	try {
		const { data } = await axios.get(url)

		return data
	} catch (error: any) {
		throw error.response.data
	}
}

export const getProfileStatus = async (business: string) => {
	const url = `/auth/cbfs/register/status?business=${business}`

	try {
		const { data } = await axios.get(url)

		return data
	} catch (error: any) {
		throw error.response.data
	}
}

export const registerInCBFS = async (payload: any) => {
	const url = '/auth/cbfs/register'

	try {
		const { data } = await axios.post(url, payload)

		return data
	} catch (error: any) {
		throw error.response.data
	}
}

export const updateCBFS = async (payload: any) => {
	const url = '/auth/cbfs/register'

	try {
		const { data } = await axios.patch(url, payload)

		return data
	} catch (error: any) {
		throw error.response.data
	}
}
