import React from 'react'
import { styled } from '@mui/material'
import { FlexRow } from 'components/atoms/Flex'
import { Button, Gutter, TextInput } from 'components'

const TransparentButton = styled(Button)`
	background-color: ${({ theme }) => theme.palette.background.default};
	border: 1px solid ${({ theme }) => theme.palette.colors.gray['300']};
	color: ${({ theme }) => theme.palette.colors.gray['700']};
	border-radius: 8px;
	padding: 8px 14px;
	height: 40px;

	:hover {
		background-color: ${({ theme }) => theme.palette.background.default};
	}
`

type Props = {
	title: string
	value: string
	setModal: React.Dispatch<React.SetStateAction<boolean>>
}

export const BusinessDetailButton: React.ComponentType<Props> = ({
	title,
	value,
	setModal
}) => {
	return (
		<>
			<FlexRow>
				<TextInput
					disabled
					value={value}
					size="small"
					sx={{ width: 295 }}
					title={title}
				/>
				<Gutter gap={0.6} />
				<TransparentButton onClick={() => setModal(true)}>
					Edit
				</TransparentButton>
			</FlexRow>
		</>
	)
}
