export type CountryListProps = {
	name: string
	flag: string
	code: string
	dial_code: string
	phone: {
		countryCode: string
		mobile_begin_with: string[] | null
		phone_number_lengths: string[] | null
	}
	languages?: string[]
	image: string
}

export const COUNTRIES_LIST: CountryListProps[] = [
	{
		name: 'Afghanistan',
		flag: '🇦🇫',
		code: 'AF',
		dial_code: '93',
		phone: {
			countryCode: '93',
			mobile_begin_with: ['7'],
			phone_number_lengths: ['9']
		},
		languages: ['pus'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AF.svg'
	},
	{
		name: 'Åland Islands',
		flag: '🇦🇽',
		code: 'AX',
		dial_code: '358',
		phone: {
			countryCode: '358',
			mobile_begin_with: ['18'],
			phone_number_lengths: ['6', '7', '8']
		},
		languages: ['swe'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AX.svg'
	},
	{
		name: 'Albania',
		flag: '🇦🇱',
		code: 'AL',
		dial_code: '355',
		phone: {
			countryCode: '355',
			mobile_begin_with: ['6'],
			phone_number_lengths: ['9']
		},
		languages: ['sqi'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AL.svg'
	},
	{
		name: 'Algeria',
		flag: '🇩🇿',
		code: 'DZ',
		dial_code: '213',
		phone: {
			countryCode: '213',
			mobile_begin_with: ['5', '6', '7'],
			phone_number_lengths: ['9']
		},
		languages: ['ara'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/DZ.svg'
	},
	{
		name: 'American Samoa',
		flag: '🇦🇸',
		code: 'AS',
		dial_code: '1684',
		phone: {
			countryCode: '1684',
			mobile_begin_with: ['258', '733'],
			phone_number_lengths: ['7']
		},
		languages: ['eng', 'smo'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AS.svg'
	},
	{
		name: 'Andorra',
		flag: '🇦🇩',
		code: 'AD',
		dial_code: '376',
		phone: {
			countryCode: '376',
			mobile_begin_with: ['3', '4', '6'],
			phone_number_lengths: ['6']
		},
		languages: ['cat'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AD.svg'
	},
	{
		name: 'Angola',
		flag: '🇦🇴',
		code: 'AO',
		dial_code: '244',
		phone: {
			countryCode: '244',
			mobile_begin_with: ['9'],
			phone_number_lengths: ['9']
		},
		languages: ['por'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AO.svg'
	},
	{
		name: 'Anguilla',
		flag: '🇦🇮',
		code: 'AI',
		dial_code: '1264',
		phone: {
			countryCode: '1264',
			mobile_begin_with: null,
			phone_number_lengths: null
		},
		languages: [],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AI.svg'
	},
	{
		name: 'Antarctica',
		flag: '🇦🇶',
		code: 'AQ',
		dial_code: '672',
		phone: {
			countryCode: '672',
			mobile_begin_with: ['14'],
			phone_number_lengths: ['6']
		},
		languages: [],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AQ.svg'
	},
	{
		name: 'Antigua and Barbuda',
		flag: '🇦🇬',
		code: 'AG',
		dial_code: '1268',
		phone: {
			countryCode: '1268',
			mobile_begin_with: ['7'],
			phone_number_lengths: ['7']
		},
		languages: ['eng'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AG.svg'
	},
	{
		name: 'Argentina',
		flag: '🇦🇷',
		code: 'AR',
		dial_code: '54',
		phone: {
			countryCode: '54',
			mobile_begin_with: null,
			phone_number_lengths: ['6', '7', '8', '9']
		},
		languages: ['spa'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AR.svg'
	},
	{
		name: 'Armenia',
		flag: '🇦🇲',
		code: 'AM',
		dial_code: '374',
		phone: {
			countryCode: '374',
			mobile_begin_with: ['4', '5', '7', '9'],
			phone_number_lengths: ['8']
		},
		languages: ['hye', 'rus'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AM.svg'
	},
	{
		name: 'Aruba',
		flag: '🇦🇼',
		code: 'AW',
		dial_code: '297',
		phone: {
			countryCode: '297',
			mobile_begin_with: ['5', '6', '7', '9'],
			phone_number_lengths: ['7']
		},
		languages: ['nld'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AW.svg'
	},
	{
		name: 'Australia',
		flag: '🇦🇺',
		code: 'AU',
		dial_code: '61',
		phone: {
			countryCode: '61',
			mobile_begin_with: ['4'],
			phone_number_lengths: ['9']
		},
		languages: ['eng'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AU.svg'
	},
	{
		name: 'Austria',
		flag: '🇦🇹',
		code: 'AT',
		dial_code: '43',
		phone: {
			countryCode: '43',
			mobile_begin_with: ['6'],
			phone_number_lengths: ['10', '11', '12', '13', '14']
		},
		languages: ['deu'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AT.svg'
	},
	{
		name: 'Azerbaijan',
		flag: '🇦🇿',
		code: 'AZ',
		dial_code: '994',
		phone: {
			countryCode: '994',
			mobile_begin_with: ['4', '5', '6', '7'],
			phone_number_lengths: ['9']
		},
		languages: ['aze'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AZ.svg'
	},
	{
		name: 'Bahamas',
		flag: '🇧🇸',
		code: 'BS',
		dial_code: '1242',
		phone: {
			countryCode: '1242',
			mobile_begin_with: ['1', '2', '3', '4', '5', '6', '7', '8', '9'],
			phone_number_lengths: ['7']
		},
		languages: ['eng'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BS.svg'
	},
	{
		name: 'Bahrain',
		flag: '🇧🇭',
		code: 'BH',
		dial_code: '973',
		phone: {
			countryCode: '973',
			mobile_begin_with: ['3'],
			phone_number_lengths: ['8']
		},
		languages: ['ara'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BH.svg'
	},
	{
		name: 'Bangladesh',
		flag: '🇧🇩',
		code: 'BD',
		dial_code: '880',
		phone: {
			countryCode: '880',
			mobile_begin_with: ['1'],
			phone_number_lengths: ['8', '9', '10']
		},
		languages: ['ben'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BD.svg'
	},
	{
		name: 'Barbados',
		flag: '🇧🇧',
		code: 'BB',
		dial_code: '1246',
		phone: {
			countryCode: '1246',
			mobile_begin_with: ['1', '2', '3', '4', '5', '6', '7', '8', '9'],
			phone_number_lengths: ['7']
		},
		languages: ['eng'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BB.svg'
	},
	{
		name: 'Belarus',
		flag: '🇧🇾',
		code: 'BY',
		dial_code: '375',
		phone: {
			countryCode: '375',
			mobile_begin_with: ['25', '29', '33', '44'],
			phone_number_lengths: ['9']
		},
		languages: [],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BY.svg'
	},
	{
		name: 'Belgium',
		flag: '🇧🇪',
		code: 'BE',
		dial_code: '32',
		phone: {
			countryCode: '32',
			mobile_begin_with: ['4'],
			phone_number_lengths: ['9']
		},
		languages: ['deu', 'fra', 'nld'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BE.svg'
	},
	{
		name: 'Belize',
		flag: '🇧🇿',
		code: 'BZ',
		dial_code: '501',
		phone: {
			countryCode: '501',
			mobile_begin_with: ['6'],
			phone_number_lengths: ['7']
		},
		languages: ['eng'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BZ.svg'
	},
	{
		name: 'Benin',
		flag: '🇧🇯',
		code: 'BJ',
		dial_code: '229',
		phone: {
			countryCode: '229',
			mobile_begin_with: ['9'],
			phone_number_lengths: ['8']
		},
		languages: ['fra'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BJ.svg'
	},
	{
		name: 'Bermuda',
		flag: '🇧🇲',
		code: 'BM',
		dial_code: '1441',
		phone: {
			countryCode: '1441',
			mobile_begin_with: ['3', '5', '7'],
			phone_number_lengths: ['7']
		},
		languages: ['eng'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BM.svg'
	},
	{
		name: 'Bhutan',
		flag: '🇧🇹',
		code: 'BT',
		dial_code: '975',
		phone: {
			countryCode: '975',
			mobile_begin_with: ['17'],
			phone_number_lengths: ['8']
		},
		languages: ['dzo'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BT.svg'
	},
	{
		name: 'Bolivia, Plurinational State of bolivia',
		flag: '🇧🇴',
		code: 'BO',
		dial_code: '591',
		phone: {
			countryCode: '591',
			mobile_begin_with: ['7'],
			phone_number_lengths: ['8']
		},
		languages: ['aym', 'que', 'spa'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BO.svg'
	},
	{
		name: 'Bosnia and Herzegovina',
		flag: '🇧🇦',
		code: 'BA',
		dial_code: '387',
		phone: {
			countryCode: '387',
			mobile_begin_with: ['6'],
			phone_number_lengths: ['8']
		},
		languages: ['bos', 'cre', 'srp'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BA.svg'
	},
	{
		name: 'Botswana',
		flag: '🇧🇼',
		code: 'BW',
		dial_code: '267',
		phone: {
			countryCode: '267',
			mobile_begin_with: ['71', '72', '73', '74', '75', '76'],
			phone_number_lengths: ['8']
		},
		languages: ['eng', 'tsn'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BW.svg'
	},
	{
		name: 'Bouvet Island',
		flag: '🇧🇻',
		code: 'BV',
		dial_code: '47',
		phone: {
			countryCode: '47',
			mobile_begin_with: null,
			phone_number_lengths: null
		},
		languages: [],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BV.svg'
	},
	{
		name: 'Brazil',
		flag: '🇧🇷',
		code: 'BR',
		dial_code: '55',
		phone: {
			countryCode: '55',
			mobile_begin_with: [
				'31',
				'32',
				'34',
				'38',
				'41',
				'43',
				'44',
				'45',
				'47',
				'48',
				'51',
				'53',
				'54',
				'55',
				'61',
				'62',
				'65',
				'67',
				'68',
				'69',
				'71',
				'73',
				'74',
				'75',
				'77',
				'79',
				'81',
				'82',
				'83',
				'84',
				'85',
				'86',
				'91',
				'92',
				'95',
				'96',
				'98',
				'119',
				'129',
				'139',
				'149',
				'159',
				'169',
				'179',
				'189',
				'199',
				'219',
				'229',
				'249',
				'279',
				'289'
			],
			phone_number_lengths: ['10', '11']
		},
		languages: ['por'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BR.svg'
	},
	{
		name: 'British Indian Ocean Territory',
		flag: '🇮🇴',
		code: 'IO',
		dial_code: '246',
		phone: {
			countryCode: '246',
			mobile_begin_with: ['37'],
			phone_number_lengths: ['7']
		},
		languages: ['eng'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IO.svg'
	},
	{
		name: 'Brunei Darussalam',
		flag: '🇧🇳',
		code: 'BN',
		dial_code: '673',
		phone: {
			countryCode: '673',
			mobile_begin_with: ['7', '8'],
			phone_number_lengths: ['7']
		},
		languages: ['eng', 'msa'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BN.svg'
	},
	{
		name: 'Bulgaria',
		flag: '🇧🇬',
		code: 'BG',
		dial_code: '359',
		phone: {
			countryCode: '359',
			mobile_begin_with: ['43', '87', '88', '89', '98', '99'],
			phone_number_lengths: ['8', '9']
		},
		languages: ['bul'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BG.svg'
	},
	{
		name: 'Burkina Faso',
		flag: '🇧🇫',
		code: 'BF',
		dial_code: '226',
		phone: {
			countryCode: '226',
			mobile_begin_with: ['6', '7'],
			phone_number_lengths: ['8']
		},
		languages: ['fra'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BF.svg'
	},
	{
		name: 'Burundi',
		flag: '🇧🇮',
		code: 'BI',
		dial_code: '257',
		phone: {
			countryCode: '257',
			mobile_begin_with: ['7', '29'],
			phone_number_lengths: ['8']
		},
		languages: ['fra'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BI.svg'
	},
	{
		name: 'Cambodia',
		flag: '🇰🇭',
		code: 'KH',
		dial_code: '855',
		phone: {
			countryCode: '855',
			mobile_begin_with: ['1', '6', '7', '8', '9'],
			phone_number_lengths: ['8', '9']
		},
		languages: ['khm'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KH.svg'
	},
	{
		name: 'Cameroon',
		flag: '🇨🇲',
		code: 'CM',
		dial_code: '237',
		phone: {
			countryCode: '237',
			mobile_begin_with: ['7', '9'],
			phone_number_lengths: ['8']
		},
		languages: ['eng', 'fra'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CM.svg'
	},
	{
		name: 'Canada',
		flag: '🇨🇦',
		code: 'CA',
		dial_code: '1',
		phone: {
			countryCode: '1',
			mobile_begin_with: [
				'204',
				'226',
				'236',
				'249',
				'250',
				'289',
				'306',
				'343',
				'365',
				'403',
				'416',
				'418',
				'431',
				'437',
				'438',
				'450',
				'506',
				'514',
				'519',
				'579',
				'581',
				'587',
				'600',
				'604',
				'613',
				'639',
				'647',
				'705',
				'709',
				'778',
				'780',
				'807',
				'819',
				'867',
				'873',
				'902',
				'905'
			],
			phone_number_lengths: ['10']
		},
		languages: ['eng', 'fra'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CA.svg'
	},
	{
		name: 'Cape Verde',
		flag: '🇨🇻',
		code: 'CV',
		dial_code: '238',
		phone: {
			countryCode: '238',
			mobile_begin_with: ['5', '9'],
			phone_number_lengths: ['7']
		},
		languages: ['por'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CV.svg'
	},
	{
		name: 'Cayman Islands',
		flag: '🇰🇾',
		code: 'KY',
		dial_code: '345',
		phone: {
			countryCode: '345',
			mobile_begin_with: ['1', '2', '3', '4', '5', '6', '7', '8', '9'],
			phone_number_lengths: ['7']
		},
		languages: ['eng'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KY.svg'
	},
	{
		name: 'Central African Republic',
		flag: '🇨🇫',
		code: 'CF',
		dial_code: '236',
		phone: {
			countryCode: '236',
			mobile_begin_with: ['7'],
			phone_number_lengths: ['8']
		},
		languages: ['fra', 'sag'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CF.svg'
	},
	{
		name: 'Chad',
		flag: '🇹🇩',
		code: 'TD',
		dial_code: '235',
		phone: {
			countryCode: '235',
			mobile_begin_with: ['6', '7', '9'],
			phone_number_lengths: ['8']
		},
		languages: ['ara', 'fra'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TD.svg'
	},
	{
		name: 'Chile',
		flag: '🇨🇱',
		code: 'CL',
		dial_code: '56',
		phone: {
			countryCode: '56',
			mobile_begin_with: ['9'],
			phone_number_lengths: ['9']
		},
		languages: ['spa'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CL.svg'
	},
	{
		name: 'China',
		flag: '🇨🇳',
		code: 'CN',
		dial_code: '86',
		phone: {
			countryCode: '86',
			mobile_begin_with: ['13', '14', '15', '16', '17', '18', '19'],
			phone_number_lengths: ['11']
		},
		languages: ['zho'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CN.svg'
	},
	{
		name: 'Christmas Island',
		flag: '🇨🇽',
		code: 'CX',
		dial_code: '61',
		phone: {
			countryCode: '61',
			mobile_begin_with: null,
			phone_number_lengths: ['5']
		},
		languages: ['eng'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CX.svg'
	},
	{
		name: 'Cocos (Keeling) Islands',
		flag: '🇨🇨',
		code: 'CC',
		dial_code: '61',
		phone: {
			countryCode: '61',
			mobile_begin_with: null,
			phone_number_lengths: null
		},
		languages: ['eng'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CC.svg'
	},
	{
		name: 'Colombia',
		flag: '🇨🇴',
		code: 'CO',
		dial_code: '57',
		phone: {
			countryCode: '57',
			mobile_begin_with: ['3'],
			phone_number_lengths: ['10']
		},
		languages: ['spa'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CO.svg'
	},
	{
		name: 'Comoros',
		flag: '🇰🇲',
		code: 'KM',
		dial_code: '269',
		phone: {
			countryCode: '269',
			mobile_begin_with: ['3', '76'],
			phone_number_lengths: ['7']
		},
		languages: ['ara', 'fra'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KM.svg'
	},
	{
		name: 'Congo',
		flag: '🇨🇬',
		code: 'CG',
		dial_code: '242',
		phone: {
			countryCode: '242',
			mobile_begin_with: ['0'],
			phone_number_lengths: ['9']
		},
		languages: ['fra', 'lin'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CG.svg'
	},
	{
		name: 'Congo, The Democratic Republic of the Congo',
		flag: '🇨🇩',
		code: 'CD',
		dial_code: '243',
		phone: {
			countryCode: '243',
			mobile_begin_with: ['8', '9'],
			phone_number_lengths: ['9']
		},
		languages: ['fra', 'kon', 'lin', 'swa'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CD.svg'
	},
	{
		name: 'Cook Islands',
		flag: '🇨🇰',
		code: 'CK',
		dial_code: '682',
		phone: {
			countryCode: '682',
			mobile_begin_with: ['5', '7'],
			phone_number_lengths: ['5']
		},
		languages: ['eng', 'mri'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CK.svg'
	},
	{
		name: 'Costa Rica',
		flag: '🇨🇷',
		code: 'CR',
		dial_code: '506',
		phone: {
			countryCode: '506',
			mobile_begin_with: ['5', '6', '7', '8'],
			phone_number_lengths: ['8']
		},
		languages: ['spa'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CR.svg'
	},
	{
		name: "Cote d'Ivoire",
		flag: '🇨🇮',
		code: 'CI',
		dial_code: '225',
		phone: {
			countryCode: '225',
			mobile_begin_with: ['0', '4', '5', '6'],
			phone_number_lengths: ['8']
		},
		languages: ['fra'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CI.svg'
	},
	{
		name: 'Croatia',
		flag: '🇭🇷',
		code: 'HR',
		dial_code: '385',
		phone: {
			countryCode: '385',
			mobile_begin_with: ['9'],
			phone_number_lengths: ['8', '9']
		},
		languages: ['hrv'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/HR.svg'
	},
	{
		name: 'Cuba',
		flag: '🇨🇺',
		code: 'CU',
		dial_code: '53',
		phone: {
			countryCode: '53',
			mobile_begin_with: ['5'],
			phone_number_lengths: ['8']
		},
		languages: ['spa'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CU.svg'
	},
	{
		name: 'Cyprus',
		flag: '🇨🇾',
		code: 'CY',
		dial_code: '357',
		phone: {
			countryCode: '357',
			mobile_begin_with: ['9'],
			phone_number_lengths: ['8']
		},
		languages: ['ell', 'tur'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CY.svg'
	},
	{
		name: 'Czech Republic',
		flag: '🇨🇿',
		code: 'CZ',
		dial_code: '420',
		phone: {
			countryCode: '420',
			mobile_begin_with: ['6', '7'],
			phone_number_lengths: ['9']
		},
		languages: ['ces'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CZ.svg'
	},
	{
		name: 'Denmark',
		flag: '🇩🇰',
		code: 'DK',
		dial_code: '45',
		phone: {
			countryCode: '45',
			mobile_begin_with: [
				'2',
				'30',
				'31',
				'40',
				'41',
				'42',
				'50',
				'51',
				'52',
				'53',
				'60',
				'61',
				'71',
				'81',
				'91',
				'92',
				'93'
			],
			phone_number_lengths: ['8']
		},
		languages: ['dan'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/DK.svg'
	},
	{
		name: 'Djibouti',
		flag: '🇩🇯',
		code: 'DJ',
		dial_code: '253',
		phone: {
			countryCode: '253',
			mobile_begin_with: ['77'],
			phone_number_lengths: ['8']
		},
		languages: ['ara', 'fra'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/DJ.svg'
	},
	{
		name: 'Dominica',
		flag: '🇩🇲',
		code: 'DM',
		dial_code: '1767',
		phone: {
			countryCode: '1767',
			mobile_begin_with: ['1', '2', '3', '4', '5', '6', '7', '8', '9'],
			phone_number_lengths: ['7']
		},
		languages: ['eng'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/DM.svg'
	},
	{
		name: 'Dominican Republic',
		flag: '🇩🇴',
		code: 'DO',
		dial_code: '1849',
		phone: {
			countryCode: '1849',
			mobile_begin_with: ['809', '829', '849'],
			phone_number_lengths: ['10']
		},
		languages: ['spa'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/DO.svg'
	},
	{
		name: 'Ecuador',
		flag: '🇪🇨',
		code: 'EC',
		dial_code: '593',
		phone: {
			countryCode: '593',
			mobile_begin_with: ['9'],
			phone_number_lengths: ['9']
		},
		languages: ['que', 'spa'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/EC.svg'
	},
	{
		name: 'Egypt',
		flag: '🇪🇬',
		code: 'EG',
		dial_code: '20',
		phone: {
			countryCode: '20',
			mobile_begin_with: ['1'],
			phone_number_lengths: ['10']
		},
		languages: ['ara'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/EG.svg'
	},
	{
		name: 'El Salvador',
		flag: '🇸🇻',
		code: 'SV',
		dial_code: '503',
		phone: {
			countryCode: '503',
			mobile_begin_with: ['7'],
			phone_number_lengths: ['8']
		},
		languages: ['spa'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SV.svg'
	},
	{
		name: 'Equatorial Guinea',
		flag: '🇬🇶',
		code: 'GQ',
		dial_code: '240',
		phone: {
			countryCode: '240',
			mobile_begin_with: ['222', '551'],
			phone_number_lengths: ['9']
		},
		languages: ['fra', 'por', 'spa'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GQ.svg'
	},
	{
		name: 'Eritrea',
		flag: '🇪🇷',
		code: 'ER',
		dial_code: '291',
		phone: {
			countryCode: '291',
			mobile_begin_with: ['1', '7', '8'],
			phone_number_lengths: ['7']
		},
		languages: ['ara', 'eng', 'tir'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ER.svg'
	},
	{
		name: 'Estonia',
		flag: '🇪🇪',
		code: 'EE',
		dial_code: '372',
		phone: {
			countryCode: '372',
			mobile_begin_with: ['5', '81', '82', '83'],
			phone_number_lengths: ['7', '8']
		},
		languages: ['est'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/EE.svg'
	},
	{
		name: 'Ethiopia',
		flag: '🇪🇹',
		code: 'ET',
		dial_code: '251',
		phone: {
			countryCode: '251',
			mobile_begin_with: ['9'],
			phone_number_lengths: ['9']
		},
		languages: ['amh'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ET.svg'
	},
	{
		name: 'Falkland Islands (Malvinas)',
		flag: '🇫🇰',
		code: 'FK',
		dial_code: '500',
		phone: {
			countryCode: '500',
			mobile_begin_with: ['5', '6'],
			phone_number_lengths: ['5']
		},
		languages: ['eng'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/FK.svg'
	},
	{
		name: 'Faroe Islands',
		flag: '🇫🇴',
		code: 'FO',
		dial_code: '298',
		phone: {
			countryCode: '298',
			mobile_begin_with: null,
			phone_number_lengths: ['6']
		},
		languages: ['dan', 'fao'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/FO.svg'
	},
	{
		name: 'Fiji',
		flag: '🇫🇯',
		code: 'FJ',
		dial_code: '679',
		phone: {
			countryCode: '679',
			mobile_begin_with: ['7', '9'],
			phone_number_lengths: ['7']
		},
		languages: ['eng', 'fij'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/FJ.svg'
	},
	{
		name: 'Finland',
		flag: '🇫🇮',
		code: 'FI',
		dial_code: '358',
		phone: {
			countryCode: '358',
			mobile_begin_with: ['4', '5'],
			phone_number_lengths: ['9', '10']
		},
		languages: ['fin', 'swe'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/FI.svg'
	},
	{
		name: 'France',
		flag: '🇫🇷',
		code: 'FR',
		dial_code: '33',
		phone: {
			countryCode: '33',
			mobile_begin_with: ['6', '7'],
			phone_number_lengths: ['9']
		},
		languages: ['fra'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/FR.svg'
	},
	{
		name: 'French Guiana',
		flag: '🇬🇫',
		code: 'GF',
		dial_code: '594',
		phone: {
			countryCode: '594',
			mobile_begin_with: ['694'],
			phone_number_lengths: ['9']
		},
		languages: ['fra'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GF.svg'
	},
	{
		name: 'French Polynesia',
		flag: '🇵🇫',
		code: 'PF',
		dial_code: '689',
		phone: {
			countryCode: '689',
			mobile_begin_with: null,
			phone_number_lengths: ['6']
		},
		languages: ['fra'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PF.svg'
	},
	{
		name: 'French Southern Territories',
		flag: '🇹🇫',
		code: 'TF',
		dial_code: '262',
		phone: {
			countryCode: '262',
			mobile_begin_with: null,
			phone_number_lengths: null
		},
		languages: ['fra'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TF.svg'
	},
	{
		name: 'Gabon',
		flag: '🇬🇦',
		code: 'GA',
		dial_code: '241',
		phone: {
			countryCode: '241',
			mobile_begin_with: ['05', '06', '07'],
			phone_number_lengths: ['8']
		},
		languages: ['fra'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GA.svg'
	},
	{
		name: 'Gambia',
		flag: '🇬🇲',
		code: 'GM',
		dial_code: '220',
		phone: {
			countryCode: '220',
			mobile_begin_with: ['7', '9'],
			phone_number_lengths: ['7']
		},
		languages: ['eng'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GM.svg'
	},
	{
		name: 'Georgia',
		flag: '🇬🇪',
		code: 'GE',
		dial_code: '995',
		phone: {
			countryCode: '995',
			mobile_begin_with: ['5', '7'],
			phone_number_lengths: ['9']
		},
		languages: [],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GE.svg'
	},
	{
		name: 'Germany',
		flag: '🇩🇪',
		code: 'DE',
		dial_code: '49',
		phone: {
			countryCode: '49',
			mobile_begin_with: ['15', '16', '17'],
			phone_number_lengths: ['10', '11']
		},
		languages: ['deu'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/DE.svg'
	},
	{
		name: 'Ghana',
		flag: '🇬🇭',
		code: 'GH',
		dial_code: '233',
		phone: {
			countryCode: '233',
			mobile_begin_with: ['2', '5'],
			phone_number_lengths: ['9']
		},
		languages: ['eng'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GH.svg'
	},
	{
		name: 'Gibraltar',
		flag: '🇬🇮',
		code: 'GI',
		dial_code: '350',
		phone: {
			countryCode: '350',
			mobile_begin_with: ['5'],
			phone_number_lengths: ['8']
		},
		languages: ['eng'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GI.svg'
	},
	{
		name: 'Greece',
		flag: '🇬🇷',
		code: 'GR',
		dial_code: '30',
		phone: {
			countryCode: '30',
			mobile_begin_with: ['6'],
			phone_number_lengths: ['10']
		},
		languages: ['ell'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GR.svg'
	},
	{
		name: 'Greenland',
		flag: '🇬🇱',
		code: 'GL',
		dial_code: '299',
		phone: {
			countryCode: '299',
			mobile_begin_with: ['2', '4', '5'],
			phone_number_lengths: ['6']
		},
		languages: ['kal'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GL.svg'
	},
	{
		name: 'Grenada',
		flag: '🇬🇩',
		code: 'GD',
		dial_code: '1473',
		phone: {
			countryCode: '1473',
			mobile_begin_with: ['1', '2', '3', '4', '5', '6', '7', '8', '9'],
			phone_number_lengths: ['7']
		},
		languages: ['eng'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GD.svg'
	},
	{
		name: 'Guadeloupe',
		flag: '🇬🇵',
		code: 'GP',
		dial_code: '590',
		phone: {
			countryCode: '590',
			mobile_begin_with: ['690'],
			phone_number_lengths: ['9']
		},
		languages: ['fra'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GP.svg'
	},
	{
		name: 'Guam',
		flag: '🇬🇺',
		code: 'GU',
		dial_code: '1671',
		phone: {
			countryCode: '1671',
			mobile_begin_with: ['1', '2', '3', '4', '5', '6', '7', '8', '9'],
			phone_number_lengths: ['7']
		},
		languages: ['eng'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GU.svg'
	},
	{
		name: 'Guatemala',
		flag: '🇬🇹',
		code: 'GT',
		dial_code: '502',
		phone: {
			countryCode: '502',
			mobile_begin_with: ['3', '4', '5'],
			phone_number_lengths: ['8']
		},
		languages: ['spa'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GT.svg'
	},
	{
		name: 'Guernsey',
		flag: '🇬🇬',
		code: 'GG',
		dial_code: '44',
		phone: {
			countryCode: '44',
			mobile_begin_with: ['1481'],
			phone_number_lengths: ['11']
		},
		languages: ['fra'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GG.svg'
	},
	{
		name: 'Guinea',
		flag: '🇬🇳',
		code: 'GN',
		dial_code: '224',
		phone: {
			countryCode: '224',
			mobile_begin_with: ['6'],
			phone_number_lengths: ['8']
		},
		languages: ['fra'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GN.svg'
	},
	{
		name: 'Guinea-Bissau',
		flag: '🇬🇼',
		code: 'GW',
		dial_code: '245',
		phone: {
			countryCode: '245',
			mobile_begin_with: ['5', '6', '7'],
			phone_number_lengths: ['7']
		},
		languages: ['por'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GW.svg'
	},
	{
		name: 'Guyana',
		flag: '🇬🇾',
		code: 'GY',
		dial_code: '595',
		phone: {
			countryCode: '595',
			mobile_begin_with: ['6'],
			phone_number_lengths: ['7']
		},
		languages: ['eng'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GY.svg'
	},
	{
		name: 'Haiti',
		flag: '🇭🇹',
		code: 'HT',
		dial_code: '509',
		phone: {
			countryCode: '509',
			mobile_begin_with: ['3', '4'],
			phone_number_lengths: ['8']
		},
		languages: ['fra', 'hat'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/HT.svg'
	},
	{
		name: 'Heard Island and Mcdonald Islands',
		flag: '🇭🇲',
		code: 'HM',
		dial_code: '672',
		phone: {
			countryCode: '672',
			mobile_begin_with: null,
			phone_number_lengths: null
		},
		languages: [],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/HM.svg'
	},
	{
		name: 'Holy See (Vatican City State)',
		flag: '🇻🇦',
		code: 'VA',
		dial_code: '379',
		phone: {
			countryCode: '379',
			mobile_begin_with: ['6698'],
			phone_number_lengths: ['9']
		},
		languages: ['ita'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/VA.svg'
	},
	{
		name: 'Honduras',
		flag: '🇭🇳',
		code: 'HN',
		dial_code: '504',
		phone: {
			countryCode: '504',
			mobile_begin_with: ['3', '7', '8', '9'],
			phone_number_lengths: ['8']
		},
		languages: ['spa'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/HN.svg'
	},
	{
		name: 'Hong Kong',
		flag: '🇭🇰',
		code: 'HK',
		dial_code: '852',
		phone: {
			countryCode: '852',
			mobile_begin_with: ['5', '6', '9'],
			phone_number_lengths: ['8']
		},
		languages: ['eng', 'zho'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/HK.svg'
	},
	{
		name: 'Hungary',
		flag: '🇭🇺',
		code: 'HU',
		dial_code: '36',
		phone: {
			countryCode: '36',
			mobile_begin_with: ['20', '30', '31', '70'],
			phone_number_lengths: ['9']
		},
		languages: ['hun'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/HU.svg'
	},
	{
		name: 'Iceland',
		flag: '🇮🇸',
		code: 'IS',
		dial_code: '354',
		phone: {
			countryCode: '354',
			mobile_begin_with: ['6', '7', '8'],
			phone_number_lengths: ['7']
		},
		languages: ['isl'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IS.svg'
	},
	{
		name: 'India',
		flag: '🇮🇳',
		code: 'IN',
		dial_code: '91',
		phone: {
			countryCode: '91',
			mobile_begin_with: ['6', '7', '8', '9'],
			phone_number_lengths: ['10']
		},
		languages: ['eng', 'hin'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IN.svg'
	},
	{
		name: 'Indonesia',
		flag: '🇮🇩',
		code: 'ID',
		dial_code: '62',
		phone: {
			countryCode: '62',
			mobile_begin_with: ['8'],
			phone_number_lengths: ['9', '10', '11', '12']
		},
		languages: ['ind'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ID.svg'
	},
	{
		name: 'Iran, Islamic Republic of Persian Gulf',
		flag: '🇮🇷',
		code: 'IR',
		dial_code: '98',
		phone: {
			countryCode: '98',
			mobile_begin_with: ['9'],
			phone_number_lengths: ['10']
		},
		languages: ['fas'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IR.svg'
	},
	{
		name: 'Iraq',
		flag: '🇮🇶',
		code: 'IQ',
		dial_code: '964',
		phone: {
			countryCode: '964',
			mobile_begin_with: ['7'],
			phone_number_lengths: ['10']
		},
		languages: ['ara', 'kur'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IQ.svg'
	},
	{
		name: 'Ireland',
		flag: '🇮🇪',
		code: 'IE',
		dial_code: '353',
		phone: {
			countryCode: '353',
			mobile_begin_with: ['82', '83', '84', '85', '86', '87', '88', '89'],
			phone_number_lengths: ['9']
		},
		languages: ['eng', 'gle'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IE.svg'
	},
	{
		name: 'Isle of Man',
		flag: '🇮🇲',
		code: 'IM',
		dial_code: '44',
		phone: {
			countryCode: '44',
			mobile_begin_with: null,
			phone_number_lengths: ['6']
		},
		languages: ['eng', 'glv'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IM.svg'
	},
	{
		name: 'Israel',
		flag: '🇮🇱',
		code: 'IL',
		dial_code: '972',
		phone: {
			countryCode: '972',
			mobile_begin_with: ['5'],
			phone_number_lengths: ['9']
		},
		languages: ['ara', 'eng', 'heb'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IL.svg'
	},
	{
		name: 'Italy',
		flag: '🇮🇹',
		code: 'IT',
		dial_code: '39',
		phone: {
			countryCode: '39',
			mobile_begin_with: ['3'],
			phone_number_lengths: ['10']
		},
		languages: ['ita'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IT.svg'
	},
	{
		name: 'Jamaica',
		flag: '🇯🇲',
		code: 'JM',
		dial_code: '1876',
		phone: {
			countryCode: '1876',
			mobile_begin_with: ['1', '2', '3', '4', '5', '6', '7', '8', '9'],
			phone_number_lengths: ['7']
		},
		languages: ['eng'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/JM.svg'
	},
	{
		name: 'Japan',
		flag: '🇯🇵',
		code: 'JP',
		dial_code: '81',
		phone: {
			countryCode: '81',
			mobile_begin_with: ['70', '80', '90'],
			phone_number_lengths: ['10']
		},
		languages: ['jpn'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/JP.svg'
	},
	{
		name: 'Jersey',
		flag: '🇯🇪',
		code: 'JE',
		dial_code: '44',
		phone: {
			countryCode: '44',
			mobile_begin_with: null,
			phone_number_lengths: ['7']
		},
		languages: ['eng', 'fra'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/JE.svg'
	},
	{
		name: 'Jordan',
		flag: '🇯🇴',
		code: 'JO',
		dial_code: '962',
		phone: {
			countryCode: '962',
			mobile_begin_with: ['7'],
			phone_number_lengths: ['9']
		},
		languages: ['ara'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/JO.svg'
	},
	{
		name: 'Kazakhstan',
		flag: '🇰🇿',
		code: 'KZ',
		dial_code: '77',
		phone: {
			countryCode: '77',
			mobile_begin_with: ['70', '74', '77'],
			phone_number_lengths: ['10']
		},
		languages: ['kaz', 'rus'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KZ.svg'
	},
	{
		name: 'Kenya',
		flag: '🇰🇪',
		code: 'KE',
		dial_code: '254',
		phone: {
			countryCode: '254',
			mobile_begin_with: ['7'],
			phone_number_lengths: ['9']
		},
		languages: ['eng', 'swa'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KE.svg'
	},
	{
		name: 'Kiribati',
		flag: '🇰🇮',
		code: 'KI',
		dial_code: '686',
		phone: {
			countryCode: '686',
			mobile_begin_with: ['9', '30'],
			phone_number_lengths: ['5']
		},
		languages: ['eng'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KI.svg'
	},
	{
		name: "Korea, Democratic People's Republic of Korea",
		flag: '🇰🇵',
		code: 'KP',
		dial_code: '850',
		phone: {
			countryCode: '850',
			mobile_begin_with: ['1', '2', '3', '4', '5', '6', '7', '8', '9'],
			phone_number_lengths: ['6', '7']
		},
		languages: ['kor'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KP.svg'
	},
	{
		name: 'Korea, Republic of South Korea',
		flag: '🇰🇷',
		code: 'KR',
		dial_code: '82',
		phone: {
			countryCode: '82',
			mobile_begin_with: ['1'],
			phone_number_lengths: ['9', '10']
		},
		languages: ['kor'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KR.svg'
	},
	{
		name: 'Kosovo',
		flag: '🇽🇰',
		code: 'XK',
		dial_code: '383',
		phone: {
			countryCode: '383',
			mobile_begin_with: null,
			phone_number_lengths: null
		},
		languages: ['bos', 'rom', 'sqi', 'srp', 'tur'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/XK.svg'
	},
	{
		name: 'Kuwait',
		flag: '🇰🇼',
		code: 'KW',
		dial_code: '965',
		phone: {
			countryCode: '965',
			mobile_begin_with: ['5', '6', '9'],
			phone_number_lengths: ['8']
		},
		languages: ['ara'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KW.svg'
	},
	{
		name: 'Kyrgyzstan',
		flag: '🇰🇬',
		code: 'KG',
		dial_code: '996',
		phone: {
			countryCode: '996',
			mobile_begin_with: ['5', '7'],
			phone_number_lengths: ['9']
		},
		languages: ['rus'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KG.svg'
	},
	{
		name: 'Laos',
		flag: '🇱🇦',
		code: 'LA',
		dial_code: '856',
		phone: {
			countryCode: '856',
			mobile_begin_with: ['20'],
			phone_number_lengths: ['10']
		},
		languages: ['lao'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LA.svg'
	},
	{
		name: 'Latvia',
		flag: '🇱🇻',
		code: 'LV',
		dial_code: '371',
		phone: {
			countryCode: '371',
			mobile_begin_with: ['2'],
			phone_number_lengths: ['8']
		},
		languages: ['lav'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LV.svg'
	},
	{
		name: 'Lebanon',
		flag: '🇱🇧',
		code: 'LB',
		dial_code: '961',
		phone: {
			countryCode: '961',
			mobile_begin_with: ['3', '7'],
			phone_number_lengths: ['7', '8']
		},
		languages: ['ara', 'hye'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LB.svg'
	},
	{
		name: 'Lesotho',
		flag: '🇱🇸',
		code: 'LS',
		dial_code: '266',
		phone: {
			countryCode: '266',
			mobile_begin_with: ['5', '6'],
			phone_number_lengths: ['8']
		},
		languages: ['eng', 'sot'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LS.svg'
	},
	{
		name: 'Liberia',
		flag: '🇱🇷',
		code: 'LR',
		dial_code: '231',
		phone: {
			countryCode: '231',
			mobile_begin_with: ['4', '5', '6', '7'],
			phone_number_lengths: ['7', '8']
		},
		languages: ['eng'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LR.svg'
	},
	{
		name: 'Libyan Arab Jamahiriya',
		flag: '🇱🇾',
		code: 'LY',
		dial_code: '218',
		phone: {
			countryCode: '218',
			mobile_begin_with: ['9'],
			phone_number_lengths: ['9']
		},
		languages: ['ara'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LY.svg'
	},
	{
		name: 'Liechtenstein',
		flag: '🇱🇮',
		code: 'LI',
		dial_code: '423',
		phone: {
			countryCode: '423',
			mobile_begin_with: ['7'],
			phone_number_lengths: ['7']
		},
		languages: ['deu'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LI.svg'
	},
	{
		name: 'Lithuania',
		flag: '🇱🇹',
		code: 'LT',
		dial_code: '370',
		phone: {
			countryCode: '370',
			mobile_begin_with: ['6'],
			phone_number_lengths: ['8']
		},
		languages: ['lit'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LT.svg'
	},
	{
		name: 'Luxembourg',
		flag: '🇱🇺',
		code: 'LU',
		dial_code: '352',
		phone: {
			countryCode: '352',
			mobile_begin_with: ['6'],
			phone_number_lengths: ['9']
		},
		languages: ['deu', 'fra', 'ltz'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LU.svg'
	},
	{
		name: 'Macao',
		flag: '🇲🇴',
		code: 'MO',
		dial_code: '853',
		phone: {
			countryCode: '853',
			mobile_begin_with: ['6'],
			phone_number_lengths: ['8']
		},
		languages: ['por', 'zho'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MO.svg'
	},
	{
		name: 'Macedonia',
		flag: '🇲🇰',
		code: 'MK',
		dial_code: '389',
		phone: {
			countryCode: '389',
			mobile_begin_with: ['7'],
			phone_number_lengths: ['8']
		},
		languages: ['mkd'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MK.svg'
	},
	{
		name: 'Madagascar',
		flag: '🇲🇬',
		code: 'MG',
		dial_code: '261',
		phone: {
			countryCode: '261',
			mobile_begin_with: ['3'],
			phone_number_lengths: ['9']
		},
		languages: ['fra', 'mlg'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MG.svg'
	},
	{
		name: 'Malawi',
		flag: '🇲🇼',
		code: 'MW',
		dial_code: '265',
		phone: {
			countryCode: '265',
			mobile_begin_with: ['77', '88', '99'],
			phone_number_lengths: ['9']
		},
		languages: ['eng', 'nya'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MW.svg'
	},
	{
		name: 'Malaysia',
		flag: '🇲🇾',
		code: 'MY',
		dial_code: '60',
		phone: {
			countryCode: '60',
			mobile_begin_with: ['1'],
			phone_number_lengths: ['9', '10']
		},
		languages: ['eng', 'msa'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MY.svg'
	},
	{
		name: 'Maldives',
		flag: '🇲🇻',
		code: 'MV',
		dial_code: '960',
		phone: {
			countryCode: '960',
			mobile_begin_with: ['7', '9'],
			phone_number_lengths: ['7']
		},
		languages: ['div'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MV.svg'
	},
	{
		name: 'Mali',
		flag: '🇲🇱',
		code: 'ML',
		dial_code: '223',
		phone: {
			countryCode: '223',
			mobile_begin_with: ['6', '7'],
			phone_number_lengths: ['8']
		},
		languages: ['fra'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ML.svg'
	},
	{
		name: 'Malta',
		flag: '🇲🇹',
		code: 'MT',
		dial_code: '356',
		phone: {
			countryCode: '356',
			mobile_begin_with: ['79', '99'],
			phone_number_lengths: ['8']
		},
		languages: ['eng', 'mlt'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MT.svg'
	},
	{
		name: 'Marshall Islands',
		flag: '🇲🇭',
		code: 'MH',
		dial_code: '692',
		phone: {
			countryCode: '692',
			mobile_begin_with: null,
			phone_number_lengths: ['7']
		},
		languages: ['eng', 'mah'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MH.svg'
	},
	{
		name: 'Martinique',
		flag: '🇲🇶',
		code: 'MQ',
		dial_code: '596',
		phone: {
			countryCode: '596',
			mobile_begin_with: ['696'],
			phone_number_lengths: ['9']
		},
		languages: [],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MQ.svg'
	},
	{
		name: 'Mauritania',
		flag: '🇲🇷',
		code: 'MR',
		dial_code: '222',
		phone: {
			countryCode: '222',
			mobile_begin_with: null,
			phone_number_lengths: ['8']
		},
		languages: ['ara', 'fra'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MR.svg'
	},
	{
		name: 'Mauritius',
		flag: '🇲🇺',
		code: 'MU',
		dial_code: '230',
		phone: {
			countryCode: '230',
			mobile_begin_with: ['5'],
			phone_number_lengths: ['8']
		},
		languages: ['eng', 'fra'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MU.svg'
	},
	{
		name: 'Mayotte',
		flag: '🇾🇹',
		code: 'YT',
		dial_code: '262',
		phone: {
			countryCode: '262',
			mobile_begin_with: ['639'],
			phone_number_lengths: ['9']
		},
		languages: ['fra'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/YT.svg'
	},
	{
		name: 'Mexico',
		flag: '🇲🇽',
		code: 'MX',
		dial_code: '52',
		phone: {
			countryCode: '52',
			mobile_begin_with: [''],
			phone_number_lengths: ['10', '11']
		},
		languages: ['spa'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MX.svg'
	},
	{
		name: 'Micronesia, Federated States of Micronesia',
		flag: '🇫🇲',
		code: 'FM',
		dial_code: '691',
		phone: {
			countryCode: '691',
			mobile_begin_with: null,
			phone_number_lengths: ['7']
		},
		languages: ['eng'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/FM.svg'
	},
	{
		name: 'Moldova',
		flag: '🇲🇩',
		code: 'MD',
		dial_code: '373',
		phone: {
			countryCode: '373',
			mobile_begin_with: ['6', '7'],
			phone_number_lengths: ['8']
		},
		languages: ['ron'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MD.svg'
	},
	{
		name: 'Monaco',
		flag: '🇲🇨',
		code: 'MC',
		dial_code: '377',
		phone: {
			countryCode: '377',
			mobile_begin_with: ['4', '6'],
			phone_number_lengths: ['8', '9']
		},
		languages: ['fra'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MC.svg'
	},
	{
		name: 'Mongolia',
		flag: '🇲🇳',
		code: 'MN',
		dial_code: '976',
		phone: {
			countryCode: '976',
			mobile_begin_with: ['5', '8', '9'],
			phone_number_lengths: ['8']
		},
		languages: ['mon'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MN.svg'
	},
	{
		name: 'Montenegro',
		flag: '🇲🇪',
		code: 'ME',
		dial_code: '382',
		phone: {
			countryCode: '382',
			mobile_begin_with: ['6'],
			phone_number_lengths: ['8']
		},
		languages: ['mot'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ME.svg'
	},
	{
		name: 'Montserrat',
		flag: '🇲🇸',
		code: 'MS',
		dial_code: '1664',
		phone: {
			countryCode: '1664',
			mobile_begin_with: ['1', '2', '3', '4', '5', '6', '7', '8', '9'],
			phone_number_lengths: ['7']
		},
		languages: [],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MS.svg'
	},
	{
		name: 'Morocco',
		flag: '🇲🇦',
		code: 'MA',
		dial_code: '212',
		phone: {
			countryCode: '212',
			mobile_begin_with: ['6'],
			phone_number_lengths: ['9']
		},
		languages: ['ara'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MA.svg'
	},
	{
		name: 'Mozambique',
		flag: '🇲🇿',
		code: 'MZ',
		dial_code: '258',
		phone: {
			countryCode: '258',
			mobile_begin_with: ['8'],
			phone_number_lengths: ['9']
		},
		languages: ['por'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MZ.svg'
	},
	{
		name: 'Myanmar',
		flag: '🇲🇲',
		code: 'MM',
		dial_code: '95',
		phone: {
			countryCode: '95',
			mobile_begin_with: ['9'],
			phone_number_lengths: ['8', '9', '10']
		},
		languages: ['mya'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MM.svg'
	},
	{
		name: 'Namibia',
		flag: '🇳🇦',
		code: 'NA',
		dial_code: '264',
		phone: {
			countryCode: '264',
			mobile_begin_with: ['60', '81', '82', '85'],
			phone_number_lengths: ['9']
		},
		languages: ['eng'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NA.svg'
	},
	{
		name: 'Nauru',
		flag: '🇳🇷',
		code: 'NR',
		dial_code: '674',
		phone: {
			countryCode: '674',
			mobile_begin_with: ['555'],
			phone_number_lengths: ['7']
		},
		languages: ['eng', 'nau'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NR.svg'
	},
	{
		name: 'Nepal',
		flag: '🇳🇵',
		code: 'NP',
		dial_code: '977',
		phone: {
			countryCode: '977',
			mobile_begin_with: ['97', '98'],
			phone_number_lengths: ['10']
		},
		languages: ['nep'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NP.svg'
	},
	{
		name: 'Netherlands',
		flag: '🇳🇱',
		code: 'NL',
		dial_code: '31',
		phone: {
			countryCode: '31',
			mobile_begin_with: ['6'],
			phone_number_lengths: ['9']
		},
		languages: ['nld'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NL.svg'
	},
	/*{
		name: "Netherlands Antilles",
		flag: "",
		code: "AN",
		dial_code: "599",
		phone: {
			countryCode: "599",
			mobile_begin_with: ["4", "5", "6", "7", "8"],
			phone_number_lengths: ["7"],
		},
		languages: [],
		image: "",
	},*/
	{
		name: 'New Caledonia',
		flag: '🇳🇨',
		code: 'NC',
		dial_code: '687',
		phone: {
			countryCode: '687',
			mobile_begin_with: null,
			phone_number_lengths: ['6']
		},
		languages: ['fra'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NC.svg'
	},
	{
		name: 'New Zealand',
		flag: '🇳🇿',
		code: 'NZ',
		dial_code: '64',
		phone: {
			countryCode: '64',
			mobile_begin_with: ['2', '3', '6', '9'],
			phone_number_lengths: ['8', '9', '10']
		},
		languages: ['eng'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NZ.svg'
	},
	{
		name: 'Nicaragua',
		flag: '🇳🇮',
		code: 'NI',
		dial_code: '505',
		phone: {
			countryCode: '505',
			mobile_begin_with: ['8'],
			phone_number_lengths: ['8']
		},
		languages: ['spa'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NI.svg'
	},
	{
		name: 'Niger',
		flag: '🇳🇪',
		code: 'NE',
		dial_code: '227',
		phone: {
			countryCode: '227',
			mobile_begin_with: ['9'],
			phone_number_lengths: ['8']
		},
		languages: ['fra'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NE.svg'
	},
	{
		name: 'Nigeria',
		flag: '🇳🇬',
		code: 'NG',
		dial_code: '234',
		phone: {
			countryCode: '234',
			mobile_begin_with: ['70', '80', '81'],
			phone_number_lengths: ['10']
		},
		languages: ['eng'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NG.svg'
	},
	{
		name: 'Niue',
		flag: '🇳🇺',
		code: 'NU',
		dial_code: '683',
		phone: {
			countryCode: '683',
			mobile_begin_with: null,
			phone_number_lengths: ['4']
		},
		languages: ['eng'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NU.svg'
	},
	{
		name: 'Norfolk Island',
		flag: '🇳🇫',
		code: 'NF',
		dial_code: '672',
		phone: {
			countryCode: '672',
			mobile_begin_with: ['5', '8'],
			phone_number_lengths: ['5']
		},
		languages: ['eng'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NF.svg'
	},
	{
		name: 'Northern Mariana Islands',
		flag: '🇲🇵',
		code: 'MP',
		dial_code: '1670',
		phone: {
			countryCode: '1670',
			mobile_begin_with: ['1', '2', '3', '4', '5', '6', '7', '8', '9'],
			phone_number_lengths: ['7']
		},
		languages: ['eng'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MP.svg'
	},
	{
		name: 'Norway',
		flag: '🇳🇴',
		code: 'NO',
		dial_code: '47',
		phone: {
			countryCode: '47',
			mobile_begin_with: ['4', '9'],
			phone_number_lengths: ['8']
		},
		languages: ['nor'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NO.svg'
	},
	{
		name: 'Oman',
		flag: '🇴🇲',
		code: 'OM',
		dial_code: '968',
		phone: {
			countryCode: '968',
			mobile_begin_with: ['9'],
			phone_number_lengths: ['8']
		},
		languages: ['ara'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/OM.svg'
	},
	{
		name: 'Pakistan',
		flag: '🇵🇰',
		code: 'PK',
		dial_code: '92',
		phone: {
			countryCode: '92',
			mobile_begin_with: ['3'],
			phone_number_lengths: ['10']
		},
		languages: ['eng', 'urd'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PK.svg'
	},
	{
		name: 'Palau',
		flag: '🇵🇼',
		code: 'PW',
		dial_code: '680',
		phone: {
			countryCode: '680',
			mobile_begin_with: null,
			phone_number_lengths: ['7']
		},
		languages: ['eng'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PW.svg'
	},
	{
		name: 'Palestinian Territory, Occupied',
		flag: '🇵🇸',
		code: 'PS',
		dial_code: '970',
		phone: {
			countryCode: '970',
			mobile_begin_with: ['5'],
			phone_number_lengths: ['9']
		},
		languages: ['ara'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PS.svg'
	},
	{
		name: 'Panama',
		flag: '🇵🇦',
		code: 'PA',
		dial_code: '507',
		phone: {
			countryCode: '507',
			mobile_begin_with: ['6'],
			phone_number_lengths: ['8']
		},
		languages: ['spa'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PA.svg'
	},
	{
		name: 'Papua New Guinea',
		flag: '🇵🇬',
		code: 'PG',
		dial_code: '675',
		phone: {
			countryCode: '675',
			mobile_begin_with: ['7'],
			phone_number_lengths: ['8']
		},
		languages: ['eng'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PG.svg'
	},
	{
		name: 'Paraguay',
		flag: '🇵🇾',
		code: 'PY',
		dial_code: '595',
		phone: {
			countryCode: '595',
			mobile_begin_with: ['9'],
			phone_number_lengths: ['9']
		},
		languages: ['spa'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PY.svg'
	},
	{
		name: 'Peru',
		flag: '🇵🇪',
		code: 'PE',
		dial_code: '51',
		phone: {
			countryCode: '51',
			mobile_begin_with: ['9'],
			phone_number_lengths: ['9']
		},
		languages: ['aym', 'que', 'spa'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PE.svg'
	},
	{
		name: 'Philippines',
		flag: '🇵🇭',
		code: 'PH',
		dial_code: '63',
		phone: {
			countryCode: '63',
			mobile_begin_with: ['9'],
			phone_number_lengths: ['10']
		},
		languages: ['eng'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PH.svg'
	},
	{
		name: 'Pitcairn',
		flag: '🇵🇳',
		code: 'PN',
		dial_code: '872',
		phone: {
			countryCode: '872',
			mobile_begin_with: null,
			phone_number_lengths: null
		},
		languages: ['eng'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PN.svg'
	},
	{
		name: 'Poland',
		flag: '🇵🇱',
		code: 'PL',
		dial_code: '48',
		phone: {
			countryCode: '48',
			mobile_begin_with: ['4', '5', '6', '7', '8'],
			phone_number_lengths: ['9']
		},
		languages: ['pol'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PL.svg'
	},
	{
		name: 'Portugal',
		flag: '🇵🇹',
		code: 'PT',
		dial_code: '351',
		phone: {
			countryCode: '351',
			mobile_begin_with: ['9'],
			phone_number_lengths: ['9']
		},
		languages: ['por'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PT.svg'
	},
	{
		name: 'Puerto Rico',
		flag: '🇵🇷',
		code: 'PR',
		dial_code: '1939',
		phone: {
			countryCode: '1939',
			mobile_begin_with: ['787', '939'],
			phone_number_lengths: ['10']
		},
		languages: ['eng', 'spa'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PR.svg'
	},
	{
		name: 'Qatar',
		flag: '🇶🇦',
		code: 'QA',
		dial_code: '974',
		phone: {
			countryCode: '974',
			mobile_begin_with: ['3', '5', '6', '7'],
			phone_number_lengths: ['8']
		},
		languages: ['ara'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/QA.svg'
	},
	{
		name: 'Romania',
		flag: '🇷🇴',
		code: 'RO',
		dial_code: '40',
		phone: {
			countryCode: '40',
			mobile_begin_with: ['7'],
			phone_number_lengths: ['9']
		},
		languages: ['ron'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/RO.svg'
	},
	{
		name: 'Russia',
		flag: '🇷🇺',
		code: 'RU',
		dial_code: '7',
		phone: {
			countryCode: '7',
			mobile_begin_with: ['9', '495'],
			phone_number_lengths: ['10']
		},
		languages: ['rus'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/RU.svg'
	},
	{
		name: 'Rwanda',
		flag: '🇷🇼',
		code: 'RW',
		dial_code: '250',
		phone: {
			countryCode: '250',
			mobile_begin_with: ['7'],
			phone_number_lengths: ['9']
		},
		languages: ['eng', 'fra', 'kin'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/RW.svg'
	},
	{
		name: 'Reunion',
		flag: '🇷🇪',
		code: 'RE',
		dial_code: '262',
		phone: {
			countryCode: '262',
			mobile_begin_with: ['692', '693'],
			phone_number_lengths: ['9']
		},
		languages: ['fra'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/RE.svg'
	},
	{
		name: 'Saint Barthelemy',
		flag: '🇧🇱',
		code: 'BL',
		dial_code: '590',
		phone: {
			countryCode: '590',
			mobile_begin_with: ['691', '690'],
			phone_number_lengths: ['9']
		},
		languages: ['fra'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BL.svg'
	},
	{
		name: 'Saint Helena, Ascension and Tristan Da Cunha',
		flag: '🇸🇭',
		code: 'SH',
		dial_code: '290',
		phone: {
			countryCode: '290',
			mobile_begin_with: null,
			phone_number_lengths: ['4']
		},
		languages: ['eng'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SH.svg'
	},
	{
		name: 'Saint Kitts and Nevis',
		flag: '🇰🇳',
		code: 'KN',
		dial_code: '1869',
		phone: {
			countryCode: '1869',
			mobile_begin_with: ['1', '2', '3', '4', '5', '6', '7', '8', '9'],
			phone_number_lengths: ['7']
		},
		languages: ['eng'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KN.svg'
	},
	{
		name: 'Saint Lucia',
		flag: '🇱🇨',
		code: 'LC',
		dial_code: '1758',
		phone: {
			countryCode: '1758',
			mobile_begin_with: ['1', '2', '3', '4', '5', '6', '7', '8', '9'],
			phone_number_lengths: ['7']
		},
		languages: ['eng'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LC.svg'
	},
	{
		name: 'Saint Martin',
		flag: '🇲🇫',
		code: 'MF',
		dial_code: '590',
		phone: {
			countryCode: '590',
			mobile_begin_with: null,
			phone_number_lengths: ['9']
		},
		languages: ['fra'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MF.svg'
	},
	{
		name: 'Saint Pierre and Miquelon',
		flag: '🇵🇲',
		code: 'PM',
		dial_code: '508',
		phone: {
			countryCode: '508',
			mobile_begin_with: ['55'],
			phone_number_lengths: ['6']
		},
		languages: ['eng'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PM.svg'
	},
	{
		name: 'Saint Vincent and the Grenadines',
		flag: '🇻🇨',
		code: 'VC',
		dial_code: '1784',
		phone: {
			countryCode: '1784',
			mobile_begin_with: ['1', '2', '3', '4', '5', '6', '7', '8', '9'],
			phone_number_lengths: ['7']
		},
		languages: ['eng'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/VC.svg'
	},
	{
		name: 'Samoa',
		flag: '🇼🇸',
		code: 'WS',
		dial_code: '685',
		phone: {
			countryCode: '685',
			mobile_begin_with: ['7'],
			phone_number_lengths: ['7']
		},
		languages: ['eng', 'smo'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/WS.svg'
	},
	{
		name: 'San Marino',
		flag: '🇸🇲',
		code: 'SM',
		dial_code: '378',
		phone: {
			countryCode: '378',
			mobile_begin_with: ['3', '6'],
			phone_number_lengths: ['10']
		},
		languages: ['ita'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SM.svg'
	},
	{
		name: 'Sao Tome and Principe',
		flag: '🇸🇹',
		code: 'ST',
		dial_code: '239',
		phone: {
			countryCode: '239',
			mobile_begin_with: ['98', '99'],
			phone_number_lengths: ['7']
		},
		languages: ['por'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ST.svg'
	},
	{
		name: 'Saudi Arabia',
		flag: '🇸🇦',
		code: 'SA',
		dial_code: '966',
		phone: {
			countryCode: '966',
			mobile_begin_with: ['5'],
			phone_number_lengths: ['9']
		},
		languages: ['ara'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SA.svg'
	},
	{
		name: 'Senegal',
		flag: '🇸🇳',
		code: 'SN',
		dial_code: '221',
		phone: {
			countryCode: '221',
			mobile_begin_with: ['7'],
			phone_number_lengths: ['9']
		},
		languages: ['fra'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SN.svg'
	},
	{
		name: 'Serbia',
		flag: '🇷🇸',
		code: 'RS',
		dial_code: '381',
		phone: {
			countryCode: '381',
			mobile_begin_with: ['6'],
			phone_number_lengths: ['8', '9']
		},
		languages: ['srp'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/RS.svg'
	},
	{
		name: 'Seychelles',
		flag: '🇸🇨',
		code: 'SC',
		dial_code: '248',
		phone: {
			countryCode: '248',
			mobile_begin_with: ['2'],
			phone_number_lengths: ['7']
		},
		languages: ['eng', 'fra'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SC.svg'
	},
	{
		name: 'Sierra Leone',
		flag: '🇸🇱',
		code: 'SL',
		dial_code: '232',
		phone: {
			countryCode: '232',
			mobile_begin_with: [
				'21',
				'25',
				'30',
				'33',
				'34',
				'40',
				'44',
				'50',
				'55',
				'76',
				'77',
				'78',
				'79',
				'88'
			],
			phone_number_lengths: ['8']
		},
		languages: ['eng'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SL.svg'
	},
	{
		name: 'Singapore',
		flag: '🇸🇬',
		code: 'SG',
		dial_code: '65',
		phone: {
			countryCode: '65',
			mobile_begin_with: ['8', '9'],
			phone_number_lengths: ['8']
		},
		languages: ['eng', 'msa', 'tam', 'zho'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SG.svg'
	},
	{
		name: 'Slovakia',
		flag: '🇸🇰',
		code: 'SK',
		dial_code: '421',
		phone: {
			countryCode: '421',
			mobile_begin_with: ['9'],
			phone_number_lengths: ['9']
		},
		languages: [],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SK.svg'
	},
	{
		name: 'Slovenia',
		flag: '🇸🇮',
		code: 'SI',
		dial_code: '386',
		phone: {
			countryCode: '386',
			mobile_begin_with: ['3', '4', '5', '6', '7'],
			phone_number_lengths: ['8']
		},
		languages: ['slv'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SI.svg'
	},
	{
		name: 'Solomon Islands',
		flag: '🇸🇧',
		code: 'SB',
		dial_code: '677',
		phone: {
			countryCode: '677',
			mobile_begin_with: ['7', '8'],
			phone_number_lengths: ['7']
		},
		languages: ['eng'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SB.svg'
	},
	{
		name: 'Somalia',
		flag: '🇸🇴',
		code: 'SO',
		dial_code: '252',
		phone: {
			countryCode: '252',
			mobile_begin_with: ['9'],
			phone_number_lengths: ['8']
		},
		languages: ['som'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SO.svg'
	},
	{
		name: 'South Africa',
		flag: '🇿🇦',
		code: 'ZA',
		dial_code: '27',
		phone: {
			countryCode: '27',
			mobile_begin_with: ['1', '2', '3', '4', '5', '6', '7', '8'],
			phone_number_lengths: ['9']
		},
		languages: ['afr', 'eng', 'nbl', 'som', 'tso', 'ven', 'xho', 'zul'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ZA.svg'
	},
	{
		name: 'South Sudan',
		flag: '🇸🇸',
		code: 'SS',
		dial_code: '211',
		phone: {
			countryCode: '211',
			mobile_begin_with: ['5'],
			phone_number_lengths: ['9']
		},
		languages: ['eng'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SS.svg'
	},
	{
		name: 'South Georgia and the South Sandwich Islands',
		flag: '🇬🇸',
		code: 'GS',
		dial_code: '500',
		phone: {
			countryCode: '500',
			mobile_begin_with: ['4'],
			phone_number_lengths: ['5']
		},
		languages: ['eng'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GS.svg'
	},
	{
		name: 'Spain',
		flag: '🇪🇸',
		code: 'ES',
		dial_code: '34',
		phone: {
			countryCode: '34',
			mobile_begin_with: ['6', '7'],
			phone_number_lengths: ['9']
		},
		languages: ['spa'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ES.svg'
	},
	{
		name: 'Sri Lanka',
		flag: '🇱🇰',
		code: 'LK',
		dial_code: '94',
		phone: {
			countryCode: '94',
			mobile_begin_with: ['7'],
			phone_number_lengths: ['9']
		},
		languages: ['sin', 'tam'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LK.svg'
	},
	{
		name: 'Sudan',
		flag: '🇸🇩',
		code: 'SD',
		dial_code: '249',
		phone: {
			countryCode: '249',
			mobile_begin_with: ['9'],
			phone_number_lengths: ['9']
		},
		languages: ['ara', 'eng'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SD.svg'
	},
	{
		name: 'Suriname',
		flag: '🇸🇷',
		code: 'SR',
		dial_code: '597',
		phone: {
			countryCode: '597',
			mobile_begin_with: ['6', '7', '8'],
			phone_number_lengths: ['7']
		},
		languages: ['nld'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SR.svg'
	},
	{
		name: 'Svalbard and Jan Mayen',
		flag: '🇸🇯',
		code: 'SJ',
		dial_code: '47',
		phone: {
			countryCode: '47',
			mobile_begin_with: null,
			phone_number_lengths: ['8']
		},
		languages: [],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SJ.svg'
	},
	{
		name: 'Eswatini',
		flag: '🇸🇿',
		code: 'SZ',
		dial_code: '268',
		phone: {
			countryCode: '268',
			mobile_begin_with: ['6'],
			phone_number_lengths: ['7']
		},
		languages: ['eng', 'ssw'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SZ.svg'
	},
	{
		name: 'Sweden',
		flag: '🇸🇪',
		code: 'SE',
		dial_code: '46',
		phone: {
			countryCode: '46',
			mobile_begin_with: ['7'],
			phone_number_lengths: ['9']
		},
		languages: ['swe'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SE.svg'
	},
	{
		name: 'Switzerland',
		flag: '🇨🇭',
		code: 'CH',
		dial_code: '41',
		phone: {
			countryCode: '41',
			mobile_begin_with: ['7'],
			phone_number_lengths: ['9']
		},
		languages: ['deu', 'fra', 'ita', 'roh'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CH.svg'
	},
	{
		name: 'Syrian Arab Republic',
		flag: '🇸🇾',
		code: 'SY',
		dial_code: '963',
		phone: {
			countryCode: '963',
			mobile_begin_with: ['9'],
			phone_number_lengths: ['9']
		},
		languages: ['ara'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SY.svg'
	},
	{
		name: 'Taiwan',
		flag: '🇹🇼',
		code: 'TW',
		dial_code: '886',
		phone: {
			countryCode: '886',
			mobile_begin_with: ['9'],
			phone_number_lengths: ['9']
		},
		languages: ['zho'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TW.svg'
	},
	{
		name: 'Tajikistan',
		flag: '🇹🇯',
		code: 'TJ',
		dial_code: '992',
		phone: {
			countryCode: '992',
			mobile_begin_with: ['9'],
			phone_number_lengths: ['9']
		},
		languages: ['rus', 'tgk'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TJ.svg'
	},
	{
		name: 'Tanzania, United Republic of Tanzania',
		flag: '🇹🇿',
		code: 'TZ',
		dial_code: '255',
		phone: {
			countryCode: '255',
			mobile_begin_with: ['6', '7'],
			phone_number_lengths: ['9']
		},
		languages: ['eng', 'swa'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TZ.svg'
	},
	{
		name: 'Thailand',
		flag: '🇹🇭',
		code: 'TH',
		dial_code: '66',
		phone: {
			countryCode: '66',
			mobile_begin_with: ['6', '8', '9'],
			phone_number_lengths: ['9']
		},
		languages: ['tha'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TH.svg'
	},
	{
		name: 'Timor-Leste',
		flag: '🇹🇱',
		code: 'TL',
		dial_code: '670',
		phone: {
			countryCode: '670',
			mobile_begin_with: ['7'],
			phone_number_lengths: ['8']
		},
		languages: ['por'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TL.svg'
	},
	{
		name: 'Togo',
		flag: '🇹🇬',
		code: 'TG',
		dial_code: '228',
		phone: {
			countryCode: '228',
			mobile_begin_with: ['9'],
			phone_number_lengths: ['8']
		},
		languages: ['fra'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TG.svg'
	},
	{
		name: 'Tokelau',
		flag: '🇹🇰',
		code: 'TK',
		dial_code: '690',
		phone: {
			countryCode: '690',
			mobile_begin_with: null,
			phone_number_lengths: ['4']
		},
		languages: ['eng'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TK.svg'
	},
	{
		name: 'Tonga',
		flag: '🇹🇴',
		code: 'TO',
		dial_code: '676',
		phone: {
			countryCode: '676',
			mobile_begin_with: null,
			phone_number_lengths: ['5']
		},
		languages: ['eng'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TO.svg'
	},
	{
		name: 'Trinidad and Tobago',
		flag: '🇹🇹',
		code: 'TT',
		dial_code: '1868',
		phone: {
			countryCode: '1868',
			mobile_begin_with: ['1', '2', '3', '4', '5', '6', '7', '8', '9'],
			phone_number_lengths: ['7']
		},
		languages: ['eng'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TT.svg'
	},
	{
		name: 'Tunisia',
		flag: '🇹🇳',
		code: 'TN',
		dial_code: '216',
		phone: {
			countryCode: '216',
			mobile_begin_with: ['2', '4', '5', '9'],
			phone_number_lengths: ['8']
		},
		languages: ['ara'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TN.svg'
	},
	{
		name: 'Turkey',
		flag: '🇹🇷',
		code: 'TR',
		dial_code: '90',
		phone: {
			countryCode: '90',
			mobile_begin_with: ['5'],
			phone_number_lengths: ['10']
		},
		languages: ['tur'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TR.svg'
	},
	{
		name: 'Turkmenistan',
		flag: '🇹🇲',
		code: 'TM',
		dial_code: '993',
		phone: {
			countryCode: '993',
			mobile_begin_with: ['6'],
			phone_number_lengths: ['8']
		},
		languages: ['rus', 'tuk'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TM.svg'
	},
	{
		name: 'Turks and Caicos Islands',
		flag: '🇹🇨',
		code: 'TC',
		dial_code: '1649',
		phone: {
			countryCode: '1649',
			mobile_begin_with: ['6492', '6493', '6494'],
			phone_number_lengths: ['10']
		},
		languages: ['eng'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TC.svg'
	},
	{
		name: 'Tuvalu',
		flag: '🇹🇻',
		code: 'TV',
		dial_code: '688',
		phone: {
			countryCode: '688',
			mobile_begin_with: null,
			phone_number_lengths: ['5']
		},
		languages: ['eng'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TV.svg'
	},
	{
		name: 'Uganda',
		flag: '🇺🇬',
		code: 'UG',
		dial_code: '256',
		phone: {
			countryCode: '256',
			mobile_begin_with: ['7'],
			phone_number_lengths: ['9']
		},
		languages: ['eng', 'swa'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/UG.svg'
	},
	{
		name: 'Ukraine',
		flag: '🇺🇦',
		code: 'UA',
		dial_code: '380',
		phone: {
			countryCode: '380',
			mobile_begin_with: ['9', '39', '50', '63', '66', '67', '68'],
			phone_number_lengths: ['9']
		},
		languages: ['rus', 'ukr'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/UA.svg'
	},
	{
		name: 'United Arab Emirates',
		flag: '🇦🇪',
		code: 'AE',
		dial_code: '971',
		phone: {
			countryCode: '971',
			mobile_begin_with: ['5'],
			phone_number_lengths: ['9']
		},
		languages: ['ara'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AE.svg'
	},
	{
		name: 'United Kingdom',
		flag: '🇬🇧',
		code: 'GB',
		dial_code: '44',
		phone: {
			countryCode: '44',
			mobile_begin_with: ['7'],
			phone_number_lengths: ['10']
		},
		languages: ['cor', 'cym', 'eng', 'gla', 'gle'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GB.svg'
	},
	{
		name: 'United States',
		flag: '🇺🇸',
		code: 'US',
		dial_code: '1',
		phone: {
			countryCode: '1',
			mobile_begin_with: [
				'201',
				'202',
				'203',
				'205',
				'206',
				'207',
				'208',
				'209',
				'210',
				'212',
				'213',
				'214',
				'215',
				'216',
				'217',
				'218',
				'219',
				'224',
				'225',
				'227',
				'228',
				'229',
				'231',
				'234',
				'239',
				'240',
				'248',
				'251',
				'252',
				'253',
				'254',
				'256',
				'260',
				'262',
				'267',
				'269',
				'270',
				'272',
				'274',
				'276',
				'278',
				'281',
				'283',
				'301',
				'302',
				'303',
				'304',
				'305',
				'307',
				'308',
				'309',
				'310',
				'312',
				'313',
				'314',
				'315',
				'316',
				'317',
				'318',
				'319',
				'320',
				'321',
				'323',
				'325',
				'327',
				'330',
				'331',
				'334',
				'336',
				'337',
				'339',
				'341',
				'346',
				'347',
				'351',
				'352',
				'360',
				'361',
				'364',
				'369',
				'380',
				'385',
				'386',
				'401',
				'402',
				'404',
				'405',
				'406',
				'407',
				'408',
				'409',
				'410',
				'412',
				'413',
				'414',
				'415',
				'417',
				'419',
				'423',
				'424',
				'425',
				'430',
				'432',
				'434',
				'435',
				'440',
				'442',
				'443',
				'445',
				'447',
				'458',
				'464',
				'469',
				'470',
				'475',
				'478',
				'479',
				'480',
				'484',
				'501',
				'502',
				'503',
				'504',
				'505',
				'507',
				'508',
				'509',
				'510',
				'512',
				'513',
				'515',
				'516',
				'517',
				'518',
				'520',
				'530',
				'531',
				'534',
				'539',
				'540',
				'541',
				'551',
				'557',
				'559',
				'561',
				'562',
				'563',
				'564',
				'567',
				'570',
				'571',
				'573',
				'574',
				'575',
				'580',
				'582',
				'585',
				'586',
				'601',
				'602',
				'603',
				'605',
				'606',
				'607',
				'608',
				'609',
				'610',
				'612',
				'614',
				'615',
				'616',
				'617',
				'618',
				'619',
				'620',
				'623',
				'626',
				'627',
				'628',
				'629',
				'630',
				'631',
				'636',
				'641',
				'646',
				'650',
				'651',
				'657',
				'659',
				'660',
				'661',
				'662',
				'667',
				'669',
				'678',
				'679',
				'681',
				'682',
				'689',
				'701',
				'702',
				'703',
				'704',
				'706',
				'707',
				'708',
				'712',
				'713',
				'714',
				'715',
				'716',
				'717',
				'718',
				'719',
				'720',
				'724',
				'725',
				'727',
				'730',
				'731',
				'732',
				'734',
				'737',
				'740',
				'747',
				'752',
				'754',
				'757',
				'760',
				'762',
				'763',
				'764',
				'765',
				'769',
				'770',
				'772',
				'773',
				'774',
				'775',
				'779',
				'781',
				'785',
				'786',
				'801',
				'802',
				'803',
				'804',
				'805',
				'806',
				'808',
				'810',
				'812',
				'813',
				'814',
				'815',
				'816',
				'817',
				'818',
				'828',
				'830',
				'831',
				'832',
				'835',
				'843',
				'845',
				'847',
				'848',
				'850',
				'854',
				'856',
				'857',
				'858',
				'859',
				'860',
				'862',
				'863',
				'864',
				'865',
				'870',
				'872',
				'878',
				'901',
				'903',
				'904',
				'906',
				'907',
				'908',
				'909',
				'910',
				'912',
				'913',
				'914',
				'915',
				'916',
				'917',
				'918',
				'919',
				'920',
				'925',
				'927',
				'928',
				'929',
				'931',
				'934',
				'935',
				'936',
				'937',
				'938',
				'940',
				'941',
				'947',
				'949',
				'951',
				'952',
				'954',
				'956',
				'957',
				'959',
				'970',
				'971',
				'972',
				'973',
				'975',
				'978',
				'979',
				'980',
				'984',
				'985',
				'986',
				'989'
			],
			phone_number_lengths: ['10']
		},
		languages: ['eng'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/US.svg'
	},
	{
		name: 'Uruguay',
		flag: '🇺🇾',
		code: 'UY',
		dial_code: '598',
		phone: {
			countryCode: '598',
			mobile_begin_with: ['9'],
			phone_number_lengths: ['8']
		},
		languages: ['spa'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/UY.svg'
	},
	{
		name: 'Uzbekistan',
		flag: '🇺🇿',
		code: 'UZ',
		dial_code: '998',
		phone: {
			countryCode: '998',
			mobile_begin_with: ['9'],
			phone_number_lengths: ['9']
		},
		languages: ['rus', 'uzb'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/UZ.svg'
	},
	{
		name: 'Vanuatu',
		flag: '🇻🇺',
		code: 'VU',
		dial_code: '678',
		phone: {
			countryCode: '678',
			mobile_begin_with: ['5', '7'],
			phone_number_lengths: ['7']
		},
		languages: ['bis', 'eng', 'fra'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/VU.svg'
	},
	{
		name: 'Venezuela, Bolivarian Republic of Venezuela',
		flag: '🇻🇪',
		code: 'VE',
		dial_code: '58',
		phone: {
			countryCode: '58',
			mobile_begin_with: ['4'],
			phone_number_lengths: ['10']
		},
		languages: ['spa'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/VE.svg'
	},
	{
		name: 'Vietnam',
		flag: '🇻🇳',
		code: 'VN',
		dial_code: '84',
		phone: {
			countryCode: '84',
			mobile_begin_with: ['1', '3', '5', '7', '8', '9'],
			phone_number_lengths: ['9', '10']
		},
		languages: ['vie'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/VN.svg'
	},
	{
		name: 'Virgin Islands, British',
		flag: '🇻🇬',
		code: 'VG',
		dial_code: '1284',
		phone: {
			countryCode: '1284',
			mobile_begin_with: ['1', '2', '3', '4', '5', '6', '7', '8', '9'],
			phone_number_lengths: ['7']
		},
		languages: ['eng'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/VG.svg'
	},
	{
		name: 'Virgin Islands, U.S.',
		flag: '🇻🇮',
		code: 'VI',
		dial_code: '1340',
		phone: {
			countryCode: '1340',
			mobile_begin_with: ['1', '2', '3', '4', '5', '6', '7', '8', '9'],
			phone_number_lengths: ['7']
		},
		languages: ['eng'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/VI.svg'
	},
	{
		name: 'Wallis and Futuna',
		flag: '🇼🇫',
		code: 'WF',
		dial_code: '681',
		phone: {
			countryCode: '681',
			mobile_begin_with: null,
			phone_number_lengths: ['6']
		},
		languages: ['fra'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/WF.svg'
	},
	{
		name: 'Yemen',
		flag: '🇾🇪',
		code: 'YE',
		dial_code: '967',
		phone: {
			countryCode: '967',
			mobile_begin_with: ['7'],
			phone_number_lengths: ['9']
		},
		languages: ['ara'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/YE.svg'
	},
	{
		name: 'Zambia',
		flag: '🇿🇲',
		code: 'ZM',
		dial_code: '260',
		phone: {
			countryCode: '260',
			mobile_begin_with: ['9'],
			phone_number_lengths: ['9']
		},
		languages: ['eng'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ZM.svg'
	},
	{
		name: 'Zimbabwe',
		flag: '🇿🇼',
		code: 'ZW',
		dial_code: '263',
		phone: {
			countryCode: '263',
			mobile_begin_with: ['71', '73', '77'],
			phone_number_lengths: ['9']
		},
		languages: ['eng', 'nde', 'sna'],
		image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ZW.svg'
	}
]
