import React, { useCallback, useMemo, useState } from 'react'
import { AppLayout } from '../AppLayout'
import { FormLayout } from './FormLayout'
import { Button, Gutter } from 'components'
import { Formik } from 'formik'
import { Progressbar } from './ProgressBar'
import { useNavigate } from 'react-router-dom'
import { postBusiness, updateUser } from 'api'
import { store } from 'store'
import { setCurrentBusiness, setSession } from 'store/user/actions'
import { useDispatch } from 'react-redux'
import { useAppErrors } from 'hooks/useAppErrors'
import { useTranslate } from 'hooks'
import { useSettings } from 'context/settings'
import analytics from 'utils/segment'
import { ONBOARDINGFORM, SEGMENT_EVENTS } from 'data'

type InitialValue = {
	fullName: string
	businessType: string
	name: string
	profilePic: string
	bProfilePic: string
	supplyChainRole: string[]
	address: ''
}

const initialState: InitialValue = {
	fullName: '',
	profilePic: '',
	bProfilePic: '',
	businessType: '',
	name: '',
	supplyChainRole: [],
	address: ''
}

const OnBoardingForm = () => {
	const [step, setStep] = useState(0)
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const { setAppError } = useAppErrors()
	const { setBusiness } = useSettings()
	const translate = useTranslate()

	const multiStepForm = useMemo(() => {
		return ONBOARDINGFORM
	}, [])

	const submitOnBoarding = useCallback(
		async (values: InitialValue) => {
			const obj = { ...values, profilePic: values.bProfilePic }
			try {
				const res = await postBusiness(obj as any)
				setBusiness(res.id)
				dispatch(setCurrentBusiness(res.id))
				if (res) {
					analytics.track(SEGMENT_EVENTS.CLICK_CONTINUE, {
						screen: 'supply_role',
						supply_chain_position: values.supplyChainRole
					})
					navigate('/signup_complete')
				}
			} catch (error: any) {
				setAppError(error)
			}
		},
		[setAppError, navigate, setBusiness, dispatch]
	)

	const onUserUpdate = async (values: InitialValue) => {
		const { user, token } = store.getState().user
		const phoneNo = user?.phone.number || ''
		try {
			const res = await updateUser({
				fullName: values.fullName,
				phone: phoneNo,
				profilePic: values.profilePic
			})
			dispatch(setSession(token as string, res))
			if (res) {
				analytics.track(SEGMENT_EVENTS.CLICK_CONTINUE, {
					screen: 'full_name',
					name_entered: values.fullName
				})
				setStep(step + 1)
			}
		} catch (e: any) {
			setAppError(e)
		}
	}

	const FormFragment = multiStepForm[step].screenForm

	const getProgress = useMemo(() => {
		return (100 / (ONBOARDINGFORM.length + 1)) * (step + 1)
	}, [step])

	return (
		<AppLayout rightItem={<Progressbar progress={getProgress} />}>
			<Formik
				initialValues={initialState}
				onSubmit={async (values, actions) => {
					actions.setSubmitting(true)
					if (step === 0) {
						await onUserUpdate(values)
					} else if (step === multiStepForm.length - 1) {
						await submitOnBoarding(values)
					} else {
						if (step == 1)
							analytics.track(SEGMENT_EVENTS.CLICK_CONTINUE, {
								screen: 'business_name'
							})
						else if (step == 2)
							analytics.track(SEGMENT_EVENTS.CLICK_CONTINUE, {
								screen: 'business_type'
							})

						setStep(step + 1)
					}
					actions.setSubmitting(false)
					actions.setTouched({})
				}}
				validationSchema={multiStepForm[step].validationSchema}
			>
				{({ handleSubmit, values, dirty, isSubmitting }) => (
					<>
						<FormLayout
							progress={getProgress}
							step={step}
							onBackPress={() =>
								setStep(prevState => prevState - 1)
							}
						>
							<FormFragment
								values={values}
								handleSubmit={handleSubmit}
								name={multiStepForm[step].name}
							/>
							<Gutter spacing={2} />

							<Button
								onClick={() => handleSubmit()}
								variant={'contained'}
								type={'submit'}
								disabled={!dirty || isSubmitting}
							>
								{ONBOARDINGFORM.length - 1 === step
									? translate('onboarding.form.submit')
									: translate('onboarding.form.continue')}
							</Button>
						</FormLayout>
					</>
				)}
			</Formik>
		</AppLayout>
	)
}

export default OnBoardingForm
