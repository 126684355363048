import { axios } from 'store'
import queryString from 'query-string'
import type {
	AwsResponsePropS,
	BulkPublishPayload,
	DataResponseType,
	InvoiceType,
	PaymentMethod,
	PaymentStatus
} from 'typings'
import moment from 'moment'
import { objectOmit } from 'helpers'

export const createAInvoice = async (payload: InvoiceType) => {
	const url = '/invoices'

	try {
		const { data } = await axios.post(url, payload)

		return data
	} catch (error: any) {
		throw error.response.data
	}
}

export const getInvoiceList = async (
	businessId: string,
	payload: {
		page: number
		limit: number
		deleted?: boolean
		paymentStatus?: string
		searchKey?: string
		startDate?: string
		endDate?: string
		status?: string
		orderBooker?: string
		hasLoadSheet?: boolean
		contactId?: string
		compact?: boolean
		sortOrder?: 'ASC' | 'DESC'
	},
	others?: any
) => {
	const { startDate, endDate, sortOrder } = payload

	const convertedStartDate = startDate
		? moment(startDate).utc(true).valueOf().toString()
		: undefined
	const convertedEndDate = endDate
		? moment(endDate).utc(true).valueOf().toString()
		: undefined

	let url = `/invoices/list?business=${businessId.replaceAll(
		'"',
		''
	)}&sortOrder=${
		sortOrder || 'DESC'
	}&sortBy=initiationAt&${queryString.stringify({
		...objectOmit(payload, 'sortOrder'),
		startDate: convertedStartDate,
		endDate: convertedEndDate
	})}`

	if (others) {
		url += others
	}

	try {
		const { data } = await axios.get<DataResponseType<InvoiceType>>(url)

		return data
	} catch (error: any) {
		throw error.response.data
	}
}

export const getAInvoice = async (invoiceId: string) => {
	const url = `/invoices/${invoiceId}`

	try {
		const { data } = await axios.get<InvoiceType>(url)

		return data
	} catch (error: any) {
		throw error.response.data
	}
}

export const recycleRestoreInvoice = async (invoiceId: string[]) => {
	const url = `/invoices/mark-deleted?${queryString.stringify({ invoiceId })}`

	try {
		const { data } = await axios.patch(url)

		return data
	} catch (error: any) {
		throw error.response.data
	}
}

export const postBulkPayment = async (payload: any) => {
	const url = '/invoices/bulk/payments'

	try {
		const { data } = await axios.post(url, payload)

		return data
	} catch (e: any) {
		throw e.response.data
	}
}

export const updateAnInvoice = async (invoiceId: string, payload: any) => {
	const url = `/invoices/${invoiceId}`

	try {
		const { data } = await axios.patch(url, payload)
		return data
	} catch (error: any) {
		throw error.response.data
	}
}

export const markVoidInvoice = async (payload: {
	invoiceId: string
	restoreInventory: boolean
	paymentRefunded: boolean
}) => {
	const url = '/invoices/mark-voided'

	try {
		const { data } = await axios.patch(url, payload)

		return data
	} catch (error: any) {
		throw error.response.data
	}
}

export const editInternalComment = async (
	invoiceId: string,
	internalCommentId: string,
	payload: {
		comment: string
		file: { url: string; name: string }
		business: string
	}
) => {
	const url = `/invoices/${invoiceId}/internalcomment/${internalCommentId}`

	try {
		const { data } = await axios.patch(url, payload)

		return data
	} catch (error: any) {
		throw error.response.data
	}
}

export const createInternalComment = async (
	invoiceId: string,
	payload: {
		comment: string
		file: { url: string; name: string }
		business: string
	}
) => {
	const url = `/invoices/${invoiceId}/internalcomment`

	try {
		const { data } = await axios.post(url, payload)

		return data
	} catch (error: any) {
		throw error.response.data
	}
}

export const uploadInvoiceAttachments = async (
	invoiceId: string,
	fileType: string
) => {
	const url = `/invoices/${invoiceId}/internalcomment/attachment-upload-url?fileType=${fileType}`

	try {
		const { data } = await axios.get<AwsResponsePropS>(url)

		return data
	} catch (error: any) {
		throw error.response.data
	}
}

export const isReissueInvoice = async (invoiceId: string) => {
	const url = `/invoices/${invoiceId}/check-reissue-inventory`

	try {
		const { data } = await axios.get(url)

		return data
	} catch (error: any) {
		throw error.response.data
	}
}

export const reIssueInvoice = async (payload: {
	reissueReason: string
	invoiceId: string
}) => {
	const url = '/invoices/reissue-voided-invoice'

	try {
		const { data } = await axios.patch(url, payload)

		return data
	} catch (error: any) {
		throw error.response.data
	}
}

export const getSignedUrlForInvoicePdf = async (
	payload: InvoiceType[],
	toPrint: boolean = false
) => {
	const url = `/pdf-maker/generate/invoice?print=${toPrint}`

	try {
		const { data } = await axios.post(url, payload)

		return data
	} catch (error: any) {
		throw error.response.data
	}
}

export const publishAnInvoice = async (
	invoiceId: string,
	payload: InvoiceType
) => {
	const url = `/invoices/${invoiceId}/publish`

	try {
		const { data } = await axios.patch(url, payload)

		return data
	} catch (error: any) {
		throw error.response.data
	}
}

export const publishBulkInvoices = async (payload: BulkPublishPayload) => {
	const url = `/invoices/publish/bulk`

	try {
		const { data } = await axios.patch(url, payload)

		return data
	} catch (error: any) {
		throw error.response.data
	}
}

export const recordPayment = async (
	invoiceId: string,
	payload: {
		businessId: string
		contactId: string
		paymentStatus: PaymentStatus
		paymentAmount: number
		paymentMethod: PaymentMethod
		paymentNote: string
		paymentDate: string
	}
) => {
	const url = `/invoices/${invoiceId}/payments`

	try {
		const { data } = await axios.post(url, payload)

		return data
	} catch (error: any) {
		throw error.response.data
	}
}

export const getContactInvoices = async (
	contactId: string,
	payload: {
		page: number
		limit: number
		searchKey: string
	}
) => {
	const url = `/invoices/contact/${contactId}?${queryString.stringify(
		payload
	)}`

	try {
		const { data } = await axios.get<DataResponseType<InvoiceType>>(url)

		return data
	} catch (error: any) {
		throw error.response.data
	}
}
