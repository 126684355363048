import React, { useCallback, useEffect } from 'react'
import type { ModalProps } from '@mui/material'
import { Divider, styled, Typography } from '@mui/material'
import { StyledModal } from 'components/StyledModal'
import { FlexCol, FlexRow } from 'components/atoms/Flex'
import { useAppErrors } from 'hooks'
import { type CapsuleProps, Gutter } from 'components'
import CloseIcon from '@mui/icons-material/Close'
import { amountToLocal, formatId } from 'helpers'
import { TransactionTable } from './TransactionTable'
import { getTransactionByInvoiceId } from 'api'
import type { InvoiceType, TransactionProps } from 'typings'
import { dateFormat, LEDGER_STATUS_TITLE } from 'data'
import { getAInvoice } from 'api/invoice'
import moment from 'moment'

const Wrapper = styled('div')`
	min-width: 553px;
	max-width: 700px;
	width: 100%;
	max-height: 527px;
`
const Background = styled('div')`
	padding: 20px;
	border-radius: 10px;
	background-color: ${({ theme }) => theme.palette.background.default};
	margin: 20px 0;
	overflow-y: auto;
`
const StyledCapsule = styled(FlexRow, {
	shouldForwardProp: props =>
		props !== 'disabled' && props !== 'isSelected' && props !== 'color'
})<Pick<CapsuleProps, 'isSelected' | 'color' | 'disabled'>>`
	background-color: ${({ theme }) => theme.palette.colors['gray']['100']};

	padding: 5px 10px;
	border-radius: 25px;
	margin: 5px;
	color: ${({ theme, color }) =>
		theme.palette.colors[color || 'gray']['700']};
	align-items: center;
	justify-content: center;

	white-space: nowrap;

	:hover {
		cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};
	}
`

const Label = styled(Typography)`
	margin-left: 5px;
`

const CloseIconWrapper = styled(CloseIcon)`
	:hover {
		cursor: pointer;
	}
`
type LedgerTransactionModalProps = {
	selectedTransaction?: TransactionProps
} & Pick<ModalProps, 'onClose' | 'open'>

export const LedgerTransactionModal: React.ComponentType<
	LedgerTransactionModalProps
> = ({ onClose, open, selectedTransaction }) => {
	const [currentInvoice, setCurrentInvoice] = React.useState<InvoiceType>()
	const [dataSources, setDataSources] = React.useState<TransactionProps[]>([])
	const { setAppError } = useAppErrors()

	const isVoid = selectedTransaction?.metaData.invoice?.status === 'void'

	const statusTitle: string =
		LEDGER_STATUS_TITLE[currentInvoice?.paymentStatus as any] || 'Pending'

	const fetchTransaction = useCallback(async () => {
		try {
			const res = await getTransactionByInvoiceId(
				selectedTransaction?.metaData.invoice?.id || '0'
			)
			setDataSources(res.edges)
		} catch (e: any) {
			setAppError(e)
		}
	}, [selectedTransaction?.metaData.invoice?.id, setAppError])

	const getInvoiceDetail = useCallback(async () => {
		try {
			const res = await getAInvoice(
				selectedTransaction?.metaData.invoice?.id || '0'
			)
			setCurrentInvoice(res)
		} catch (e: any) {
			setAppError(e)
		}
	}, [selectedTransaction?.metaData.invoice?.id, setAppError])

	useEffect(() => {
		if (selectedTransaction?.metaData.invoice?.id) {
			getInvoiceDetail()
			fetchTransaction()
		}
	}, [
		fetchTransaction,
		getInvoiceDetail,
		selectedTransaction?.metaData.invoice?.id
	])

	return (
		<StyledModal open={open} onClose={() => onClose?.({}, 'escapeKeyDown')}>
			<Background>
				<Wrapper>
					<FlexRow justify="space-between">
						<FlexRow>
							<FlexCol>
								<Label variant={'caption'} fontWeight={500}>
									Invoice ID
								</Label>
								<StyledCapsule isSelected={isVoid}>
									<FlexRow align={'center'}>
										<Typography
											variant={'caption'}
											fontWeight={600}
										>{`INV-${formatId(
											selectedTransaction?.metaData
												.invoice?.friendlyId || 0
										)}`}</Typography>
									</FlexRow>
								</StyledCapsule>
							</FlexCol>
							<Gutter gap={0.5} />
							<FlexCol>
								<Label variant={'caption'} fontWeight={500}>
									Invoice Date
								</Label>
								<StyledCapsule isSelected={isVoid}>
									<FlexRow align={'center'}>
										<Typography
											variant={'caption'}
											fontWeight={600}
										>
											{moment(
												currentInvoice?.initiationAt
											).format(dateFormat)}
										</Typography>
									</FlexRow>
								</StyledCapsule>
							</FlexCol>
						</FlexRow>
						<CloseIconWrapper
							onClick={() => {
								onClose?.({}, 'escapeKeyDown')
								setDataSources([])
							}}
						/>
					</FlexRow>
					<Gutter spacing={0.5} />
					<Typography
						variant="caption"
						fontSize={'10'}
						fontWeight={'500'}
					>
						Amount Due
					</Typography>
					<Typography
						variant={'subtitle2'}
						fontWeight={600}
						fontSize={'27px'}
					>
						PKR {amountToLocal(currentInvoice?.amountDue || 0)}
					</Typography>
					<Typography
						variant="caption"
						fontSize={'10'}
						fontWeight={'500'}
					>
						Invoice Status
					</Typography>
					<FlexRow align="center">
						<Typography
							variant={'subtitle2'}
							fontWeight={600}
							fontSize={'20px'}
						>
							{currentInvoice?.status === 'void'
								? 'Void'
								: statusTitle}
						</Typography>
						<Gutter gap={0.5} />
					</FlexRow>
					<Divider style={{ width: '50%', margin: '13px 0' }} />
					<Typography variant="caption">History</Typography>
					<Gutter spacing={1} />
					<TransactionTable entries={dataSources} />
				</Wrapper>
			</Background>
		</StyledModal>
	)
}
