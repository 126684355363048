import React, { useCallback, useMemo } from 'react'
import analytics from 'utils/segment'
import { AnimatePresence, motion } from 'framer-motion'
import { Tooltip, Typography, useTheme } from '@mui/material'
import type { SideBarDataSourcesProps } from 'data'
import { SIDEBAR_PROFILE } from 'data'
import { styled } from '@mui/system'
import { NavLink, useLocation } from 'react-router-dom'
import { useTranslate } from 'hooks'
import { useSelector } from 'react-redux'
import type { RootState } from 'store/index-reducer'
import { getNameChar, truncate } from 'helpers'
import { Avatar } from 'components'
import type { ColorsCodeType, IBusiness } from 'typings'
import { FlexCol } from 'components/atoms/Flex'
import { useSettings } from 'context/settings'

const Icons = styled('div')`
	display: flex;
`

const LinkText = styled(motion.div)`
	white-space: nowrap;
	height: min-content;
`

const StyledNavLink = styled(NavLink, {
	shouldForwardProp: props => props !== 'isSideBarOpen'
})<{ isSideBarOpen: boolean }>`
	text-decoration-line: none;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	column-gap: ${props => (props.isSideBarOpen ? '8px' : '0')};
	border-radius: 6px;
	row-gap: 10px;
	width: 100%;
	height: 46px;
	margin: 4px 0;
	padding: 4px;
	color: ${({ theme }) => theme.palette.colors['white']['25']};

	:hover {
		cursor: pointer;
		background-color: ${({ theme }) => theme.palette.colors['white']['50']};
		color: ${({ theme }) => theme.palette.colors['gray']['600']};
	}
`

const StyledAvatar = styled(Avatar)`
	width: 28px;
	height: 28px;
	font-size: 14px;
	align-items: center;
	justify-content: center;
`

const ShowAnimation = {
	hidden: {
		width: 0,
		opacity: 0,
		transition: {
			duration: 0.2
		}
	},
	show: {
		width: 'auto',
		opacity: 1,
		transition: {
			duration: 0.2
		}
	}
}

type SidebarItemProps = {
	isOpen?: boolean
}

export const SidebarProfile: React.ComponentType<SidebarItemProps> = ({
	isOpen
}) => {
	const user = useSelector((state: RootState) => state.user.user)
	const { businesses } = useSelector((state: RootState) => state.business)

	const translate = useTranslate()
	const location = useLocation()
	const theme = useTheme()
	const { business } = useSettings()

	const item = SIDEBAR_PROFILE

	const activeBusiness = businesses.find(
		(biz: IBusiness) => biz.id === business
	)

	const {
		palette: { colors }
	} = theme

	const isActiveStyle = useMemo(() => {
		return {
			backgroundColor: theme.palette.colors.green['700'],
			color: theme.palette.colors.green['900']
		}
	}, [theme.palette.colors.green])

	const isActive = useCallback(
		(item: SideBarDataSourcesProps) => {
			return location.pathname.startsWith(item.activePath)
		},
		[location.pathname]
	)

	const activeStyle = useCallback(
		(isActive: boolean, path: string) => {
			return location.pathname.startsWith(path) ? isActiveStyle : {}
		},
		[isActiveStyle, location.pathname]
	)

	const selectedStyle = useCallback(
		(item: SideBarDataSourcesProps) => {
			return isActive(item)
				? colors['white']['25']
				: colors['grayGreen']['600']
		},
		[colors, isActive]
	)

	return (
		<Tooltip
			title={!isActive(item) && !isOpen && translate(item.name)}
			placement="right"
		>
			<StyledNavLink
				style={({ isActive }) => activeStyle(isActive, item.activePath)}
				to={item.path}
				key={item.name}
				isSideBarOpen={!!isOpen}
				onClick={() => analytics.track(item.segmentEvent)}
			>
				<Icons>
					<StyledAvatar
						borderWidth={0}
						borderColor={
							theme.palette.background.paper as ColorsCodeType
						}
						title={getNameChar(user?.fullName || '')}
						src={user?.profilePic}
					/>
				</Icons>
				<AnimatePresence>
					{isOpen && (
						<LinkText
							variants={ShowAnimation}
							initial={'hidden'}
							animate={'show'}
							exit={'hidden'}
						>
							<FlexCol>
								<Typography
									variant={'subtitle2'}
									fontWeight={500}
									color={selectedStyle(item)}
									style={{ lineHeight: '20px' }}
								>
									{truncate(user?.fullName || '', 13)}
								</Typography>
								<Typography
									variant={'caption'}
									color={selectedStyle(item)}
									fontSize={10}
								>
									{truncate(activeBusiness?.name || '', 18)}
								</Typography>
							</FlexCol>
						</LinkText>
					)}
				</AnimatePresence>
			</StyledNavLink>
		</Tooltip>
	)
}
