import React from 'react'
import { Typography, useTheme } from '@mui/material'
import { styled } from '@mui/system'
import type { InvoiceSettingsType, InvoiceType } from 'typings'
import { isEmpty } from 'helpers/constant'
import { getTermsText, pascalCase } from 'helpers'

const Wrapper = styled('div')`
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 10px;
	margin-bottom: 1rem;
`

const Card = styled('div')`
	padding: 14px;
	border-radius: 7px;
	width: 100%;
	background-color: ${({ theme }) => theme.palette.colors.gray['50']};
`

type Props = {
	title?: string
	currentInvoice: InvoiceType
	invoiceSettings?: InvoiceSettingsType
}

export const BusinessInfo: React.ComponentType<Props> = ({
	currentInvoice,
	invoiceSettings
}) => {
	const theme = useTheme()
	const { colors } = theme.palette
	const {
		contact: { phone, name, address, email, cnic },
		paymentTerms: { custom, dueDate, numDays },
		paymentMethod
	} = currentInvoice

	const isUnpaid = paymentMethod === 'unpaid'
	const isPaymentMethod =
		isUnpaid || paymentMethod === undefined || paymentMethod === ''

	const isVisible =
		isPaymentMethod &&
		custom.length === 0 &&
		isEmpty(dueDate) &&
		(isEmpty(numDays) || numDays == '0')

	return (
		<Wrapper>
			<Card>
				<Typography variant={'subtitle2'}>Billed to</Typography>
				<Typography variant={'subtitle1'} fontWeight={500}>
					{name}
				</Typography>
				{phone.number && (
					<Typography
						variant={'subtitle2'}
						color={colors.gray['600']}
					>
						+{phone.countryCode} {phone.number}
					</Typography>
				)}
				<Typography variant={'subtitle2'} color={colors.gray['600']}>
					{email}
				</Typography>
				<Typography variant={'subtitle2'} color={colors.gray['600']}>
					{address}
				</Typography>
				{(invoiceSettings?.settings?.displayCustomerCnic ||
					currentInvoice.status !== 'draft') && (
					<Typography
						variant={'subtitle2'}
						color={colors.gray['600']}
					>
						{cnic}
					</Typography>
				)}
			</Card>
			{!isVisible && (
				<Card>
					<Typography variant={'subtitle1'} fontWeight={500}>
						Payment Information
					</Typography>
					{!isPaymentMethod && (
						<Typography variant={'caption'} display={'block'}>
							Method:{' '}
							<Typography
								variant={'caption'}
								display={'inline'}
								color={colors.green['700']}
							>
								{pascalCase(currentInvoice.paymentMethod)}
							</Typography>{' '}
						</Typography>
					)}
					{(dueDate || (custom.length > 0 && custom[0])) && (
						<Typography variant={'caption'} display={'block'}>
							Terms:{' '}
							<Typography
								variant={'caption'}
								display={'inline'}
								color={colors.green['700']}
							>
								<>
									{getTermsText(dueDate)}
									{custom.length > 0 && custom[0]}
								</>
							</Typography>{' '}
						</Typography>
					)}
				</Card>
			)}
		</Wrapper>
	)
}
