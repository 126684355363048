import React, { type FC } from 'react'
import {
	FormControlLabel,
	type FormControlLabelProps,
	Radio
} from '@mui/material'

export const RadioButton: FC<FormControlLabelProps> = props => {
	return <FormControlLabel {...props} control={<Radio />} />
}
