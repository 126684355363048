import React, { useCallback, useMemo } from 'react'
import { styled, Typography } from '@mui/material'
import { FlexCol, FlexRow } from 'components/atoms/Flex'
import { FieldCurrencyInput, FieldNumberInput, Gutter } from 'components'
import { useTranslate } from 'hooks/translate'
import { MdOutlineDeleteOutline } from 'react-icons/md'
import { useSelector } from 'react-redux'
import type { RootState } from 'store/index-reducer'
import type { InventoryStockType } from 'typings'
import { AddUnitMenu } from 'screen/Inventory/AddStock/AddUnitMenu'
import { useFormikContext } from 'formik'
import { FieldUnit } from 'screen/Inventory/AddStock/FieldUnit'
import { POSITIVE_NUMBER } from 'helpers'

const Container = styled(FlexCol)`
	flex-wrap: nowrap;
	max-height: 42ch;
	width: 100%;
	background-color: ${({ theme }) => theme.palette.colors.blue['25']};
	padding: 0.75em;
	height: 100%;
`

const UnitContainer = styled(FlexCol)`
	background-color: ${({ theme }) => theme.palette.colors.white['900']};
	border-radius: 0.3em;
	width: 100%;
	height: auto;
	padding: 0.75em 0.75em 0.75em 0.75em;
	margin-bottom: 10px;
`
const DeleteIconContainer = styled('div')`
	:hover {
		cursor: pointer;
	}
`

const Grid = styled('div')`
	display: grid;
	align-items: flex-start;
	justify-content: center;
	grid-template-columns: 1fr 1fr 1fr;
	column-gap: 5px;
`

const Wrapper = styled('div')`
	display: block;
	overflow-y: auto;
	height: 100%;
`

type ItemUnit = { stocks: InventoryStockType[] }

export const ItemUnit: React.ComponentType<ItemUnit> = ({ stocks }) => {
	const translate = useTranslate()
	const { setFieldValue } = useFormikContext()

	const { dataSource } = useSelector(
		(state: RootState) => state.settings.stockUnit
	)

	const Units = useMemo(() => {
		return dataSource.map(d => {
			return { name: d.name, value: d.name }
		})
	}, [dataSource])

	const RenderContainer = useCallback(
		({ idx, stock }: { idx: number; stock: InventoryStockType[] }) => {
			return (
				<UnitContainer>
					<FlexRow
						style={{ width: '100%' }}
						align={'center'}
						justify={'space-between'}
					>
						<FlexCol>
							<FieldUnit
								id={'unit-field'}
								disabled={!!stock[idx].id}
								stocks={stock}
								dataSource={Units}
								name={`stock[${idx}].unit`}
							/>
						</FlexCol>
						{stock.length > 1 && (
							<DeleteIconContainer
								id={`delete-unit-${idx}`}
								onClick={() =>
									setFieldValue(
										'stock',
										stock.filter((d, i) => i !== idx)
									)
								}
							>
								<MdOutlineDeleteOutline size={20} color="red" />
							</DeleteIconContainer>
						)}
					</FlexRow>
					<Gutter />
					<Grid>
						<FieldNumberInput
							name={`stock[${idx}].quantity`}
							size="small"
							title="Quantity"
							placeholder="0"
							regex={POSITIVE_NUMBER}
						/>
						<FieldCurrencyInput
							name={`stock[${idx}].perItemCostPrice`}
							size="small"
							title="Cost Price"
							placeholder="0"
							InputProps={{
								endAdornment: (
									<Typography variant={'caption'}>
										PKR
									</Typography>
								)
							}}
						/>
						<FieldCurrencyInput
							name={`stock[${idx}].perItemSellPrice`}
							size="small"
							title="Selling Price"
							placeholder="0"
							InputProps={{
								endAdornment: (
									<Typography variant={'caption'}>
										PKR
									</Typography>
								)
							}}
						/>
					</Grid>
				</UnitContainer>
			)
		},
		[Units, setFieldValue]
	)

	return (
		<Container>
			<FlexRow
				align="center"
				justify={'space-between'}
				style={{ width: '100%' }}
			>
				<Typography variant="subtitle1" fontWeight={500}>
					{translate('inventory.units.units')}
				</Typography>
				<AddUnitMenu stock={stocks} />
			</FlexRow>
			<Gutter />
			<Wrapper>
				{stocks.map((stock, i) => (
					<RenderContainer key={i} idx={i} stock={stocks} />
				))}
			</Wrapper>
			<Gutter />
		</Container>
	)
}
