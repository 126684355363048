import React, { useCallback } from 'react'
import {
	TableBody,
	TableCell,
	tableCellClasses,
	TableHead,
	TableRow,
	TableSortLabel,
	Typography
} from '@mui/material'
import { styled } from '@mui/system'
import type { IContactProps } from 'typings'
import { useDimension } from 'hooks'
import type { ContactSortType } from 'screen/Contact/Contacts'
import { OrderBookerRow } from './OrderBookerRow'
import type { IBookerProps } from 'typings/orderbooker'

const StyledTableRow = styled(TableRow)(() => ({
	'&:last-child td, &:last-child th': {
		border: 0
	}
}))

const MUIRow = styled(StyledTableRow, {
	shouldForwardProp: props => props !== 'isEditable' && props !== 'cursor'
})<{ isEditable: boolean; cursor?: 'pointer' | 'auto' }>`
	:hover {
		cursor: ${({ cursor }) => cursor || 'auto'};
	}
`

const StyledTableCell = styled(TableCell)(({ theme }) => ({
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
	padding: 10,
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: theme.palette.colors.gray['50'],
		fontSize: 17,
		color:
			theme.palette.mode === 'dark'
				? theme.palette.colors['gray']['400']
				: theme.palette.colors['gray']['500']
	},
	[`&.${tableCellClasses.body}`]: {
		fontSize: 14
	}
}))

const StyledTableBody = styled(TableBody)<{ height: number }>`
	height: 100%;
	max-height: ${({ height }) => `${height}px`};
`

export type ContactTableProps<T> = {
	searchKey?: string
	onSort(name: string): void
	sortObj: ContactSortType
	onSuccess?: () => void
	dataSource: IBookerProps[]
	loading: boolean
	isEditable?: boolean
	onRowClick?(row: T): void
	onRecordPaymentClick?: (row: T) => void
	onUpdate?(): void
}

export const OrderBookersTable: React.ComponentType<
	ContactTableProps<IContactProps>
> = ({
	sortObj,
	onSort,
	onUpdate,
	dataSource = [],
	isEditable = true,
	...props
}) => {
	const { height } = useDimension()

	const RenderRow = useCallback(
		({ row }: { row: IBookerProps }) => (
			<OrderBookerRow
				row={row}
				isEditable={isEditable}
				onUpdate={onUpdate}
				{...props}
			/>
		),
		[isEditable, props]
	)

	return (
		<>
			<TableHead>
				<MUIRow isEditable={!isEditable}>
					<StyledTableCell
						id={'name'}
						sortDirection={sortObj.sortOrder}
						onClick={() => onSort('name')}
					>
						{/* <TableSortLabel
							active={sortObj.sortBy === 'name'}
							direction={sortObj.sortOrder}
						> */}
						<Typography variant={'subtitle1'} fontWeight={500}>
							Booker Name
						</Typography>
						{/* </TableSortLabel> */}
					</StyledTableCell>
					<StyledTableCell align="left">Phone</StyledTableCell>
					<StyledTableCell align="left">Order Form</StyledTableCell>

					{isEditable && <StyledTableCell align="left" />}
				</MUIRow>
			</TableHead>
			<StyledTableBody height={height - 230}>
				{dataSource.map(row => (
					<RenderRow key={row.id} row={row} />
				))}
			</StyledTableBody>
		</>
	)
}
