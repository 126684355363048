import React from 'react'
import { Typography } from '@mui/material'
import { styled } from '@mui/system'
import type { InvoiceType } from 'typings'
import { FlexCol, FlexRow } from 'components/atoms/Flex'
import { invoice_calculation } from 'data'
import { amountToLocal, convertNumber } from 'helpers'

const Wrapper = styled(FlexCol)`
	padding: 14px;
	grid-gap: 10px;
	margin-bottom: 1rem;
	border-radius: 7px;
	height: min-content;
	background-color: ${({ theme }) => theme.palette.colors.orange['50']};
`

const Row = styled(FlexRow)`
	display: grid;
	grid-template-columns: 2fr 1fr;
	justify-content: space-between;
	width: 100%;
	column-gap: 10px;
`

const Col = styled(FlexCol)`
	align-items: flex-end;
	flex: 1;
	width: 100%;
	height: 100%;
`

const TaxWrapper = styled(FlexCol)`
	margin-left: 10px;
	width: 100%;
	padding-right: 10px;
`

const Line = styled('div')`
	width: 100%;
	height: 1px;
	background-color: ${({ theme }) => theme.palette.colors.gray['900']};
	margin: 10px 0 0 0;
`

type Props = {
	title?: string
	currentInvoice: InvoiceType
}

export const AmountDetails: React.ComponentType<Props> = ({
	currentInvoice
}) => {
	const { taxes, extraCharges } = currentInvoice

	const {
		totalAmount,
		totalItemCost,
		amountDue,
		totalDiscount,
		baseAmtAfterDiscount,
		totalAdvance
	} = invoice_calculation(currentInvoice as InvoiceType)

	const Text = ({ txt }: { txt: string }) => {
		return <Typography variant={'caption'}>{txt}</Typography>
	}
	const Title = ({ txt }: { txt: string }) => {
		return <Typography variant={'subtitle2'}>{txt}</Typography>
	}

	const amtx = amountToLocal(
		convertNumber(
			currentInvoice.ledgerBalance?.amount
				? parseInt(`${currentInvoice.ledgerBalance?.amount}` || '0')
				: 0
		) + amountDue
	)

	return (
		<div>
			<Wrapper>
				<Row>
					<Title txt={'Total items'} />
					<Col>
						<Text txt={currentInvoice.items.length.toString()} />
					</Col>
				</Row>
				<Row>
					<Title txt={'SubTotal (PKR):'} />
					<Col>
						<Text txt={amountToLocal(totalItemCost)} />
					</Col>
				</Row>

				{extraCharges &&
					extraCharges.map(e => (
						<Row key={`${e.description}-${e.amount}`}>
							<Title txt={`${e.description}:`} />
							<Col>
								<Text txt={amountToLocal(e.amount)} />
							</Col>
						</Row>
					))}
				{totalDiscount !== 0 && (
					<Row>
						<Title txt={'Discount (PKR)'} />
						<Col>
							<Text txt={amountToLocal(totalDiscount)} />
						</Col>
					</Row>
				)}
				{taxes && taxes.length > 0 && (
					<>
						<Row>
							<Title txt={'Taxes (PKR):'} />
						</Row>
						<TaxWrapper>
							{taxes.map(d => (
								<Row key={`${d.name}${d.rate}`}>
									<Title txt={`${d.name} (${d.rate}%)`} />
									<Col>
										<Text
											txt={amountToLocal(
												(baseAmtAfterDiscount *
													d.rate) /
													100
											)}
										/>
									</Col>
								</Row>
							))}
						</TaxWrapper>
					</>
				)}
				<Line />
				<Row>
					<Typography variant={'caption'} fontWeight={600}>
						{'Grand Total (PKR)'}{' '}
					</Typography>
					<Col>
						<Text txt={amountToLocal(convertNumber(totalAmount))} />
					</Col>
				</Row>
				{totalAdvance !== 0 && (
					<Row>
						<Title txt={'Payments Made'} />
						<Col>
							<Text txt={`-${amountToLocal(totalAdvance)}`} />
						</Col>
					</Row>
				)}
				<Line />

				<Row>
					<Typography variant={'caption'} fontWeight={600}>
						{'Amount Due (PKR)'}{' '}
					</Typography>
				</Row>
				<Row>
					<FlexRow />
					<Typography
						variant={'subtitle2'}
						fontWeight={600}
						fontSize={'30px'}
					>
						{amountToLocal(convertNumber(amountDue))}
					</Typography>
				</Row>
			</Wrapper>
			{currentInvoice.ledgerBalance &&
				currentInvoice.ledgerBalance?.amount !== 0 && (
					<Wrapper>
						<Row>
							<Title txt={'Previous Balance'} />
							<Col>
								<Typography variant={'caption'}>
									{currentInvoice.ledgerBalance?.amount}
								</Typography>
							</Col>
						</Row>

						<Row>
							<Title txt={'Invoice Amount'} />
							<Col>
								<Typography variant={'caption'}>
									{amountToLocal(convertNumber(amountDue))}
								</Typography>
							</Col>
						</Row>
						<Line />
						<Row>
							<Title txt={'Net Payable Balance'} />
							<Col>
								<Typography variant={'caption'}>
									{amtx}
								</Typography>
							</Col>
						</Row>
					</Wrapper>
				)}
		</div>
	)
}
