import React from 'react'
import { TableCell, Typography, useTheme } from '@mui/material'
import { styled } from '@mui/system'
import { currencyInput, pascalCase } from 'helpers'
import moment from 'moment/moment'
import { dateFormat } from 'data'
import { AiOutlineEye } from 'react-icons/ai'
import { Gutter } from 'components'
import { TransparentButton } from 'components/atoms/Button/TransparentButton'
import { useNavigate } from 'react-router-dom'

const Cell = styled(TableCell)`
	color: ${({ theme }) => theme.palette.colors.gray['500']};
	padding: 7px 7px 7px 24px;
`

const Text = styled(Typography)`
	color: #1c1c1c;

	font-weight: 500;
`

const SmallCell = styled(TableCell, {
	shouldForwardProp: props => props !== 'isVoid'
})`
	color: ${({ theme }) => theme.palette.colors.gray['500']};
	padding: 7px 7px 7px 24px;
	min-width: 150px;
	max-width: 200px;
`

export const ACLHItem: React.ComponentType<any> = ({
	display_id,
	principal_amount,
	created_at,
	updated_at,
	status,
	tenure,
	id
}) => {
	const theme = useTheme()
	const navigate = useNavigate()
	const { colors } = theme.palette

	return (
		<>
			<SmallCell variant="head" align={'left'}>
				<Text variant={'subtitle1'} fontWeight={500}>
					{display_id}
				</Text>
			</SmallCell>
			<SmallCell variant="head" align={'left'}>
				<Text variant={'subtitle1'} fontWeight={500}>
					PKR {currencyInput(principal_amount)}
				</Text>
			</SmallCell>
			<Cell variant="head" align={'left'}>
				<Text variant={'subtitle1'} fontWeight={500}>
					{tenure ? `${tenure} days` : '-'}
				</Text>
			</Cell>
			<Cell variant="head" align={'center'} sx={{ paddingRight: '24px' }}>
				<Text variant={'subtitle1'} fontWeight={500}>
					{pascalCase(status.replaceAll('_', ' '))}
				</Text>
			</Cell>
			<Cell variant="head" align={'center'} sx={{ paddingRight: '24px' }}>
				<Text variant={'subtitle1'} fontWeight={500}>
					{moment(created_at).format(dateFormat)}
				</Text>
			</Cell>
			<Cell variant="head" align={'center'} sx={{ paddingRight: '24px' }}>
				<Text variant={'subtitle1'} fontWeight={500}>
					{moment(updated_at).format(dateFormat)}
				</Text>
			</Cell>
			<Cell variant="head" align={'right'} sx={{ paddingRight: '24px' }}>
				{
					<TransparentButton
						onClick={() => {
							navigate(`/ordr-financing/loanView/${id}`)
						}}
					>
						<AiOutlineEye color={colors.gray['700']} />
						<Gutter gap={0.25} />
						<Typography variant={'caption'} fontWeight={400}>
							View
						</Typography>
					</TransparentButton>
				}
			</Cell>
		</>
	)
}
