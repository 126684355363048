import React from 'react'
import { Skeleton, Typography, useTheme } from '@mui/material'
import { FormatAmount, Gutter } from 'components'
import { styled } from '@mui/system'
import { useFormikContext } from 'formik'
import type { CurrentInvoiceProps } from 'screen/Invoices/NewCreate/Invoice'
import type { InvoiceType } from 'typings'
import { FlexRow } from 'components/atoms/Flex'
import { invoice_calculation, SEGMENT_EVENTS } from 'data'
import { TaxAutoComplete } from 'screen/Invoices/NewCreate/Components/TaxAutoComplete'
import { IoIosClose } from 'react-icons/io'
import analytics from 'utils/segment'

const Grid = styled('div')`
	display: grid;
	width: 100%;
	grid-template-columns: 1fr 1fr;
	align-items: center;
	justify-content: space-between;
`

const Row = styled(FlexRow)`
	width: 100%;
	justify-content: flex-end;
`

const Button = styled(Typography)`
	color: ${({ theme }) => theme.palette.colors['blue']['600']};

	:hover {
		cursor: pointer;
	}
`

const Icon = styled(IoIosClose)`
	:hover {
		cursor: pointer;
	}
`

export const InvoiceTaxes = () => {
	const theme = useTheme()
	const {
		palette: { colors }
	} = theme
	const { values, setFieldValue } = useFormikContext<CurrentInvoiceProps>()
	const { baseAmtAfterDiscount } = invoice_calculation(values as InvoiceType)

	return (
		<>
			{values.loading ? (
				<Skeleton variant="rectangular" width={410} height={400} />
			) : (
				<>
					{values.taxes && values.taxes.length > 0 && (
						<Grid>
							<Typography
								variant={'subtitle2'}
								color={colors['gray']['900']}
							>
								Taxes
							</Typography>
							<div />
						</Grid>
					)}
					{values.taxes &&
						values?.taxes.map((t, i) => (
							<>
								<Grid key={t.name}>
									<TaxAutoComplete name={`taxes[${i}]`} />

									<Row>
										<FormatAmount
											variant={'subtitle2'}
											align={'right'}
											fontWeight={400}
											limit={15}
											amount={
												(baseAmtAfterDiscount *
													t.rate) /
												100
											}
											color={colors['gray']['900']}
										/>

										<Gutter gap={0.2} />
										<Icon
											size={25}
											onClick={() => {
												setFieldValue(
													'taxes',
													values.taxes?.filter(
														(f, p) => {
															return i !== p
														}
													)
												)
											}}
										/>
									</Row>
								</Grid>
								<Gutter spacing={0.5} />
							</>
						))}
					<Grid>
						<Button
							onClick={() => {
								setFieldValue('taxes', [
									...(values.taxes || []),
									{
										name: '',
										rate: 0,
										type: 'percent'
									}
								])
								analytics.track(
									values.taxes?.length === 0
										? SEGMENT_EVENTS.INVOICE_ADD_INVOICE_TAX
										: SEGMENT_EVENTS.INVOICE_ADD_ANOTHER_INVOICE_TAX
								)
							}}
						>
							<Typography variant={'subtitle2'}>
								{values.taxes?.length === 0
									? 'Add Tax'
									: 'Add Another Tax'}
							</Typography>
						</Button>
					</Grid>
					<Gutter />
				</>
			)}
		</>
	)
}
