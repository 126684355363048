import React from 'react'
import { FlexCol } from 'components/atoms/Flex'
import { styled, Typography, useTheme } from '@mui/material'
import NoSelectedOrder from 'assets/orders/NoSelectedOrder.png'

const Image = styled('img')`
	width: 250px;
	height: 200px;
`
export const EmptyScreen = () => {
	const theme = useTheme()
	const { colors } = theme.palette
	return (
		<FlexCol
			style={{
				width: '100%',
				height: '100%',
				backgroundColor: colors.gray['50']
			}}
			align={'center'}
			justify={'center'}
		>
			<Image src={NoSelectedOrder} />
			<Typography>The easiest way to manage your business!</Typography>
		</FlexCol>
	)
}
