import React, { useCallback } from 'react'
import { Capsule, FormatAmount, Gutter } from 'components'
import { FlexRow } from 'components/atoms/Flex'
import { BsWhatsapp } from 'react-icons/bs'
import { AiOutlineEye } from 'react-icons/ai'
import { StyledTableCell } from 'screen/Quotations/Tables/ReadyToShareTable'
import type { QuotationStatusType, QuotationType } from 'typings/quotation'
import { Button, styled, TableRow, Typography } from '@mui/material'
import moment from 'moment'
import { formatId, objectOmit } from 'helpers'
import { Status } from 'components/molecules/Status'
import { useNavigate } from 'react-router-dom'
import { recycleRestoreQuotation } from 'api/quotation'
import { useAppErrors } from 'hooks/useAppErrors'
import { useSnackbar } from 'notistack'
import { MdOutlineModeEditOutline } from 'react-icons/md'
import { sendWhatsapp } from 'helpers/share'
import analytics from 'utils/segment'
import {
	dateFormat,
	QUOTATION_STATUS_OPTIONS,
	SEGMENT_EVENTS,
	totalAmount
} from 'data'
import { UserProfile } from 'screen/Invoices/Components'

const WrapperCapsule = styled(Capsule)`
	padding: 7px;
	border-radius: 6px;
	width: auto;
`

const StyledTableRow = styled(TableRow)`
	:hover {
		background-color: ${({ theme }) => theme.palette.colors.blue['100']};
	}
`

const OutlinedButton = styled(Button)`
	border-radius: 7px;
	padding: 6px 12px;
	border: 1px solid ${({ theme }) => theme.palette.colors.gray['300']};
	color: ${({ theme }) => theme.palette.colors.black['300']};
`

const EditIcon = styled(MdOutlineModeEditOutline)`
	color: ${({ theme }) => theme.palette.colors.black['500']};
`

const StyledCapsule = styled(Capsule)`
	width: min-content;
	padding: 2px 5px;
	font-size: 12px;
	font-weight: 500;
	margin: 0;
`

type QuotationItemProps = {
	row: QuotationType
	onSuccess?: () => void
	status?: 'private' | 'public'
	onDelete?: (id: string) => void
	onStatusChange?: (row: QuotationType, status: QuotationStatusType) => void
}

export const QuotationItem: React.ComponentType<QuotationItemProps> = ({
	row,
	onSuccess,
	status,
	onStatusChange,
	onDelete
}) => {
	const navigate = useNavigate()
	const { setAppError } = useAppErrors()
	const { enqueueSnackbar } = useSnackbar()

	const onRestore = useCallback(
		async (id: string) => {
			try {
				await recycleRestoreQuotation([id])
				analytics.track(SEGMENT_EVENTS.QUO_RESTORE)
				enqueueSnackbar('Successfully Restored', {
					variant: 'success'
				})
				onSuccess?.()
			} catch (e: any) {
				setAppError(e)
			}
		},
		[enqueueSnackbar, onSuccess, setAppError]
	)

	return (
		<StyledTableRow style={{ justifyContent: 'center' }}>
			<StyledTableCell>
				<FlexRow align={'center'} justify={'flex-start'}>
					<UserProfile contact={row.contact}>
						{status === undefined && (
							<>
								<Gutter gap={0.5} />
								<StyledCapsule
									isSelected
									name={`${
										30 -
										moment(moment()).diff(
											moment(moment(row.deleted?.asOf)),
											'days'
										)
									} days until deletion`}
									value={''}
									color={'red'}
								/>
							</>
						)}
					</UserProfile>
				</FlexRow>
			</StyledTableCell>
			<StyledTableCell align="left">
				{formatId(row?.friendlyId || 1)}
			</StyledTableCell>

			<StyledTableCell align="left">
				<FormatAmount
					limit={10}
					amount={totalAmount(row)}
					style={{ color: 'black' }}
				/>
			</StyledTableCell>
			<StyledTableCell align="left">
				{status === 'public' && (
					<Status
						options={QUOTATION_STATUS_OPTIONS}
						selectedValue={row.status}
						onSelect={opt => {
							analytics.track(SEGMENT_EVENTS.QUO_STATUS, {
								current_status: row.status
							})
							onStatusChange?.(
								row,
								opt.value as QuotationStatusType
							)
						}}
					/>
				)}
				{status === 'private' && (
					<Capsule
						name={'Private'}
						value={row.status}
						color={'blue'}
						disabled
						isSelected
					/>
				)}
				{status === undefined && (
					<Capsule
						name={
							QUOTATION_STATUS_OPTIONS.find(
								d => row.status === d.value
							)?.label || 'Private'
						}
						value={row.status}
						color={
							QUOTATION_STATUS_OPTIONS.find(
								d => row.status === d.value
							)?.color || 'blue'
						}
						disabled
						isSelected
					/>
				)}
			</StyledTableCell>
			<StyledTableCell align="left">
				{moment(row?.initiationAt).format(dateFormat)}
			</StyledTableCell>
			<StyledTableCell align="right">
				{status === undefined && (
					<FlexRow justify="flex-end">
						<WrapperCapsule
							name="Restore"
							value="Restore"
							color="green"
							isSelected
							onItemClick={() => onRestore(row?.id || '')}
						/>
						<WrapperCapsule
							name="Delete"
							value="Delete"
							color="red"
							isSelected
							onItemClick={() => {
								analytics.track(SEGMENT_EVENTS.QUO_DELETE)
								onDelete?.(row?.id || '')
							}}
						/>
					</FlexRow>
				)}
				{status === 'private' && (
					<OutlinedButton
						variant={'outlined'}
						onClick={() => {
							analytics.track(SEGMENT_EVENTS.QUO_DRAFT_EDIT)
							navigate(`/quotation/create-quotation/${row.id}`, {
								state: { quotation: row }
							})
						}}
					>
						<EditIcon size={18} />
						<Gutter gap={0.3} />
						<Typography variant={'caption'}>Edit</Typography>
					</OutlinedButton>
				)}
				{status !== undefined && status !== 'private' && (
					<FlexRow justify="flex-end" align={'center'}>
						<WrapperCapsule
							name=""
							value=""
							renderLeft={<BsWhatsapp color="green" />}
							color="green"
							isSelected
							onItemClick={() => {
								analytics.track(SEGMENT_EVENTS.SHARE_WHATSAPP, {
									screen: 'quotation_book'
								})
								sendWhatsapp(
									row?.contact.phone.countryCode +
										row?.contact.phone.number
								)
							}}
						/>
						<WrapperCapsule
							name=""
							value=""
							renderLeft={
								<AiOutlineEye
									size={20}
									color="green"
									style={{
										paddingRight: '5px'
									}}
								/>
							}
							renderRight="View"
							color="green"
							isSelected
							onItemClick={() => {
								analytics.track(SEGMENT_EVENTS.QUO_VIEW)
								navigate(`/quotation/view/${row.id}`, {
									replace: true,
									state: { data: row }
								})
							}}
						/>
						<WrapperCapsule
							name="Generate Invoice"
							value="Generate Invoice"
							color="green"
							isSelected
							onItemClick={() => {
								analytics.track(
									SEGMENT_EVENTS.QUO_GENERATE_INVOICE,
									{
										screen: 'quotation_book'
									}
								)
								navigate('/invoice/create-invoice', {
									state: {
										currentInvoice: objectOmit(row, 'id')
									}
								})
							}}
						/>
					</FlexRow>
				)}
			</StyledTableCell>
		</StyledTableRow>
	)
}
