import React from 'react'
import { styled } from '@mui/system'

const Container = styled('div')`
	padding: 16px 16px 25px 16px;
	min-height: 100px;
`

const Box = styled('div', {
	shouldForwardProp: props => props !== 'backgroundColor'
})<Pick<ZigZagContainerProps, 'backgroundColor'>>`
	background-color: ${({ backgroundColor, theme }) =>
		backgroundColor || theme.palette.colors.orange['300']};
	--mask: radial-gradient(
				11.93px at 50% calc(100% - 16.5px),
				#000 99%,
				#0000 101%
			)
			calc(50% - 20px) 0/40px 100%,
		radial-gradient(11.93px at 50% calc(100% + 6.5px), #0000 99%, #000 101%)
			50% calc(100% - 10px) / 40px 100% repeat-x;
	-webkit-mask: var(--mask);
	mask: var(--mask);
`

type ZigZagContainerProps = {
	backgroundColor?: string
	children?: React.ReactNode
}

export const ZigZagContainer: React.ComponentType<ZigZagContainerProps> = ({
	children,
	...props
}) => {
	return (
		<Box {...props}>
			<Container>{children}</Container>
		</Box>
	)
}
