import React, { useCallback, useState } from 'react'
import { CircularProgress, MenuItem, Typography, useTheme } from '@mui/material'
import { amountToLocal, objectOmit, objectPick } from 'helpers'
import { CustomAutoComplete } from 'components/app/CutomAutoComplete'
import { useField, useFormikContext } from 'formik'
import type {
	DataResponseType,
	InventoryItemType,
	InvoiceItemProps
} from 'typings'
import { useDebouncedCallback } from 'use-debounce'
import { useSettings } from 'context/settings'
import { styled } from '@mui/system'
import { FlexRow } from 'components/atoms/Flex'
import { getInventoryList } from 'api'
import { AddInventoryModal } from 'screen/Inventory/AddStock'
import moment from 'moment/moment'
import { INVENTORY_INITIAL_STATE, SEGMENT_EVENTS } from 'data'
import analytics from 'utils/segment'

/*const BottomBar = styled(FlexRow)`
	border-top: 1px solid ${({ theme }) => theme.palette.colors.gray['100']};
	padding: 10px 16px;
	align-items: center;
`*/

const Row = styled(FlexRow)`
	:hover {
		cursor: pointer;
	}
`

const Item = styled(MenuItem)`
	flex-direction: column;
	padding: 10px 16px;
	width: 100%;

	:hover {
		background-color: ${({ theme }) => theme.palette.colors.blue['50']};
	}
`

const Text = styled(Typography)`
	width: 100%;
	overflow-wrap: break-word;
`

type TableItemProps = {
	item: InvoiceItemProps
	index: number
	name: string
}

const RenderOption = ({ item, ...props }: { item: InventoryItemType }) => {
	const theme = useTheme()
	const {
		palette: { colors }
	} = theme

	return (
		<Item {...props} disabled={item.stock[0].quantity <= 0}>
			<Text variant={'subtitle1'} fontWeight={500}>
				{item.name}
			</Text>
			<Text
				variant={'caption'}
				fontWeight={400}
				color={colors['gray']['600']}
			>
				{item.stock[0].unit}, Selling Price:{' '}
				{item.stock[0].sellCurrency}{' '}
				{amountToLocal(item.stock[0].perItemSellPrice)}
			</Text>
		</Item>
	)
}

export const InventoryAutoComplete: React.ComponentType<TableItemProps> = ({
	index,
	name
}) => {
	const [open, setOpen] = useState(false)
	const [loading, setLoading] = useState(true)
	const [search, setSearch] = useState('')
	const [inventoryList, setInventoryList] = useState<InventoryItemType[]>([])
	const debouncedSearch = useDebouncedCallback(text => getList(text), 200)
	const [, , helper] = useField(name)

	const { business } = useSettings()
	const { values } = useFormikContext<any>()

	const ItemValue = values.items[index] ? values.items[index].name : ''

	const getTransformedData = (res: InventoryItemType[]) => {
		const arr: InventoryItemType[] = []
		res.map(m =>
			m.stock.map(d => {
				return arr.push(Object.assign({}, { ...m, stock: [d] }))
			})
		)

		return arr
	}

	const getList = useCallback(
		async (searchKey: string = '') => {
			try {
				setLoading(true)
				const res: DataResponseType<InventoryItemType> =
					await getInventoryList({
						business,
						page: 1,
						limit: 20,
						searchKey,
						sortOrder: 'ASC',
						sortBy: 'name'
					})

				setInventoryList(getTransformedData(res.edges))
			} catch (e: any) {
				//
			} finally {
				setLoading(false)
			}
		},
		[business]
	)

	const onSuccess = (p: InventoryItemType) => {
		helper.setValue({
			...objectOmit(
				p.stock[0],
				'costCurrency',
				'lastStockedAt',
				'sellCurrency',
				'id'
			),
			...objectPick(p, 'name', 'stockId'),
			quantity: 1,
			maxQuantity: p.stock[0].quantity,
			stockId: p.stock[0].id,
			id: moment().toISOString(),
			discount: {
				currency: 'PKR',
				amount: 0,
				type: 'flat'
			}
		})
	}

	return (
		<>
			<CustomAutoComplete
				options={inventoryList}
				renderOption={(props, option) => {
					return <RenderOption item={option} {...props} />
				}}
				onOpen={() => getList()}
				placeholder={'Type or click to select an item.'}
				size={'small'}
				/*footer={
				<BottomBar
					onClick={() => {
						setSearch('')
					}}
				>
					<MdAddCircle color={colors.green['600']} size={20} />
					<Gutter gap={1} />
					<Typography variant={'subtitle2'}>Add New Item</Typography>
				</BottomBar>
			}*/

				getOptionDisabled={option => option.stock[0].quantity <= 0}
				sx={{ width: '100%' }}
				value={ItemValue}
				onInputChange={(e, txt) => {
					if (e && e.type !== 'blur') {
						setSearch(txt)
					}
					debouncedSearch(txt)
				}}
				noOptionsText={
					<Row
						onClick={() => {
							if (search) setOpen(true)
						}}
						style={{ width: '100%' }}
						justify={!loading ? 'flex-start' : 'center'}
					>
						{loading ? (
							<CircularProgress size={20} />
						) : search ? (
							`Create "${search}"`
						) : inventoryList.length === 0 ? (
							'No data found'
						) : (
							'Something went wrong'
						)}
					</Row>
				}
				onChange={(e, p) => {
					if (p) {
						onSuccess(p)
						analytics.track(
							SEGMENT_EVENTS.INVOICE_SUCCESS_ADD_ITEMS
						)
					}
				}}
				onBlur={() => {
					analytics.track(SEGMENT_EVENTS.INVOICE_ADD_ITEMS)
				}}
			/>
			<AddInventoryModal
				open={open}
				onClose={() => setOpen(false)}
				onSuccess={res => onSuccess(res as InventoryItemType)}
				inventory={{ ...INVENTORY_INITIAL_STATE, name: search }}
			/>
		</>
	)
}
