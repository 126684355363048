import moment from 'moment/moment'

export const isBeforeToday = (
	date: string = moment.utc().format()
): boolean => {
	const dueDate = moment.utc(date).format()
	const today = moment.utc(moment().subtract(1, 'days')).format()

	return moment(dueDate).isBefore(today)
}
