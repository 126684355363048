import React from 'react'
import Table from '@mui/material/Table'
import { OrderFormInventoryTable } from 'screen/OrderForm/Inventory/OrderFormInventoryTable'
import { styled, TableContainer } from '@mui/material'
import { useBottomScrollListener } from 'react-bottom-scroll-listener'
import { SCROLLBAR_STYLE } from 'data'

const StyledTableContainer = styled(TableContainer)<{ height: number }>`
	min-height: ${({ height }) => height}px;
	max-height: ${({ height }) => height}px;
	background-color: ${({ theme }) => theme.palette.background.default};
	overflow: auto;

	${SCROLLBAR_STYLE}
`

export const ScrollableInventoryTable = ({
	tableHeight,
	fetchInventory,
	...props
}: any) => {
	const scrollRef = useBottomScrollListener(() => fetchInventory(), {
		offset: 10,
		debounce: 200
	})

	return (
		<StyledTableContainer ref={scrollRef as any} height={tableHeight}>
			<Table stickyHeader aria-label="sticky table">
				<OrderFormInventoryTable {...props} />
			</Table>
		</StyledTableContainer>
	)
}
