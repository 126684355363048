import React, { useState } from 'react'
import { styled } from '@mui/system'
import { FlexCol, FlexRow } from 'components/atoms/Flex'
import {
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Tooltip,
	type TooltipProps,
	Typography,
	tooltipClasses,
	useTheme
} from '@mui/material'
import { useFormikContext } from 'formik'
import type { LoadsheetType } from 'typings/loadsheet'
import { useDimension } from 'hooks'
import {
	CheckBox,
	DatePicker,
	FieldMultipleLineInput,
	FieldTextInput,
	Gutter
} from 'components'
import { dateFormat, SCROLLBAR_STYLE } from 'data'
import moment from 'moment'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { InfoOutlined } from '@mui/icons-material'

const Container = styled(FlexCol)`
	flex-wrap: nowrap;
	width: 100%;
	overflow: scroll;
	${SCROLLBAR_STYLE}
`

const Wrapper = styled(FlexCol)`
	background-color: ${({ theme }) => theme.palette.colors['gray']['50']};
	border-radius: var(--borderRadius, 4px);
	border: 1px solid ${({ theme }) => theme.palette.colors['gray']['300']};
	flex-wrap: nowrap;
	height: min-content;
	width: 100%;
	margin-bottom: 16px;
`

const Grid = styled('div')`
	display: grid;
	width: 100%;
	grid-template-columns: 1fr 1fr;
	padding: 10px 15px;
	justify-content: space-between;
`

const TableWrapper = styled(FlexCol)`
	padding: 10px;
	width: 100%;
`

const Head = styled(TableHead)`
	background-color: ${({ theme }) => theme.palette.colors['gray']['25']};
`

const StyledRow = styled(FlexRow)`
	flex-wrap: nowrap;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	margin-bottom: 16px;
`

const ShowSummaryRow = styled(FlexRow)`
	flex-wrap: nowrap;
	align-items: center;
	width: 100%;
	margin-bottom: 16px;
`

const ButtonText = styled(Typography)`
	:hover {
		cursor: pointer;
	}
`

const InputWrapper = styled(FlexCol)`
	width: 100%;
	max-width: 200px;
`

const Row = styled(TableRow)`
	background-color: ${({ theme }) => theme.palette.colors['white']['25']};
`

const Option = styled(FlexRow)`
	align-items: center;
	:hover {
		cursor: pointer;
	}
`

const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
	<Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
	[`& .${tooltipClasses.arrow}`]: {
		color: theme.palette.common.black
	},
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: theme.palette.common.black
	}
}))

export const Summary = () => {
	const [isAdditionalInfo, setIsAdditionalInfo] = useState(false)
	const { values, setFieldValue, errors } = useFormikContext<LoadsheetType>()
	const { height } = useDimension()
	const theme = useTheme()
	const {
		palette: { colors }
	} = theme

	const val: any = {}
	const total = { totalQuantity: 0, totalItem: 0 }

	values.invoiceItems.map(invoiceItem =>
		invoiceItem.items.map(item => {
			if (val[item.stockId as string]) {
				val[item.stockId as string].quantity += item.quantity || 0
				val[item.stockId as string].unit = item.unit || ''
				total.totalQuantity += parseInt(`${item.quantity || 0}`)
			} else {
				val[item.stockId as string] = {
					name: item.name,
					quantity: item.quantity,
					unit: item.unit
				}
				total.totalQuantity += parseInt(`${item.quantity || 0}`)
				total.totalItem += 1
			}
		})
	)

	const arr = []

	for (const key in val) {
		if (val[key]) {
			arr.push({ stockId: key, ...val[key] })
		}
	}

	return (
		<Container style={{ maxHeight: height - 56 * 3 - 30 }}>
			<Wrapper>
				<Typography
					variant={'subtitle1'}
					style={{ margin: '8px 10px' }}
					fontWeight={500}
				>
					Summary
				</Typography>
				<Grid>
					<FlexCol>
						<Typography
							variant={'subtitle2'}
							color={colors['gray']['900']}
						>
							Total Quantity
						</Typography>
						<Typography
							variant={'subtitle2'}
							color={colors['gray']['900']}
						>
							Total Item
						</Typography>
					</FlexCol>
					<FlexCol style={{ width: '100%' }} align={'flex-end'}>
						<Typography
							variant={'subtitle2'}
							color={colors['gray']['900']}
						>
							{total.totalQuantity}
						</Typography>
						<Typography
							variant={'subtitle2'}
							color={colors['gray']['900']}
						>
							{total.totalItem}
						</Typography>
					</FlexCol>
				</Grid>
				<TableWrapper>
					<TableContainer component={Paper}>
						<Table
							sx={{ minWidth: '100%' }}
							size="small"
							aria-label="a dense table"
						>
							<Head>
								<TableRow>
									<TableCell>Item Name</TableCell>
									<TableCell align="right">
										Quantity
									</TableCell>
								</TableRow>
							</Head>
							<TableBody>
								{arr.map(row => {
									return (
										<Row
											key={row.stockId}
											sx={{
												'&:last-child td, &:last-child th':
													{
														border: 0
													}
											}}
										>
											<TableCell
												component="th"
												scope="row"
											>
												{row.name}
											</TableCell>
											<TableCell align="right">
												{`${row.quantity} ${row.unit}`}
											</TableCell>
										</Row>
									)
								})}
							</TableBody>
						</Table>
					</TableContainer>
				</TableWrapper>
			</Wrapper>

			<StyledRow>
				<Typography variant={'caption'} fontWeight={500}>
					Dispatch Date*
				</Typography>
				<InputWrapper>
					<DatePicker
						slotProps={{
							textField: {
								placeholder: 'Date',
								style: { borderRadius: 10 },
								error: !!errors.dispatchDate
							}
						}}
						format={dateFormat}
						value={values.dispatchDate}
						onChange={e => {
							setFieldValue(
								'dispatchDate',
								moment(e).format('YYYY-MM-DD').toString()
							)
						}}
						maxDate={moment().add(3, 'years')}
					/>
				</InputWrapper>
			</StyledRow>

			<ShowSummaryRow>
				<Option
					onClick={() =>
						setFieldValue(
							'metaData.config.showInvoiceSummary',
							!values.metaData.config.showInvoiceSummary
						)
					}
				>
					<CheckBox
						checked={values.metaData.config.showInvoiceSummary}
					/>
					<Gutter gap={0.6} />
					<Typography variant="caption" fontWeight={'500'}>
						Show invoice summary
					</Typography>
				</Option>
				<Gutter gap={0.4} />
				<BootstrapTooltip
					title={
						'Keep this option enabled to display a summary of customer invoices on the load sheet, including customer details, total invoice amount, and amount due'
					}
				>
					<InfoOutlined sx={{ fontSize: '16px' }} />
				</BootstrapTooltip>
			</ShowSummaryRow>

			<FlexRow onClick={() => setIsAdditionalInfo(!isAdditionalInfo)}>
				<ButtonText variant={'subtitle2'} fontWeight={500}>
					Additional Information
				</ButtonText>
				{isAdditionalInfo ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
			</FlexRow>
			<Gutter />
			{isAdditionalInfo && (
				<>
					<StyledRow>
						<Typography variant={'caption'} fontWeight={500}>
							Driver Name
						</Typography>
						<InputWrapper>
							<FieldTextInput
								name={'metaData.additonalInfo.driverName'}
								size={'small'}
								placeholder="Name"
							/>
						</InputWrapper>
					</StyledRow>

					<StyledRow>
						<Typography variant={'caption'} fontWeight={500}>
							Vehicle
						</Typography>
						<InputWrapper>
							<FieldTextInput
								name={'metaData.additonalInfo.vehicle'}
								size={'small'}
								placeholder="Vehicle Information"
							/>
						</InputWrapper>
					</StyledRow>

					<Typography variant={'caption'} fontWeight={500}>
						Notes
					</Typography>
					<FieldMultipleLineInput
						multiline
						name={'metaData.additonalInfo.notes'}
						hideLimit={true}
						rows={2}
						size={'small'}
						placeholder="e.g. Deliver between 2 to 4pm"
						inputProps={{ maxLength: 300 }}
					/>
					<Typography color={colors.gray['600']} variant={'caption'}>
						Will be displayed on the load sheet
					</Typography>
				</>
			)}
		</Container>
	)
}
