import {
	type ContactActionsTypes,
	FETCH_CONTACT_LIST,
	RESET_CONTACT_LIST,
	SET_CONTACT_LIST,
	SET_CONTACT_LOADING,
	SET_CONTACT_PAGE_INFO,
	SET_CONTACT_PAGE_NUMBER
} from './types'
import type {
	ContactListPayloadType,
	IContactProps,
	ResponsePageInfo
} from 'typings'

export const fetchContactList = (
	payload: ContactListPayloadType
): ContactActionsTypes => ({
	type: FETCH_CONTACT_LIST,
	payload
})

export const setContactList = (
	dataSource: IContactProps[]
): ContactActionsTypes => ({
	type: SET_CONTACT_LIST,
	payload: dataSource
})

export const setContactPageInfo = (
	payload: ResponsePageInfo
): ContactActionsTypes => ({
	type: SET_CONTACT_PAGE_INFO,
	payload
})

export const setContactLoading = (payload: boolean): ContactActionsTypes => ({
	type: SET_CONTACT_LOADING,
	payload
})

export const resetContactList = (): ContactActionsTypes => ({
	type: RESET_CONTACT_LIST
})

export const setContactPageNumber = (page: number): ContactActionsTypes => ({
	type: SET_CONTACT_PAGE_NUMBER,
	payload: page
})
