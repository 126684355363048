import React, { useCallback, useEffect, useMemo, useState } from 'react'
import type {
	InvoiceItemProps,
	InvoiceSettingsType,
	InvoiceType
} from 'typings'
import { useNavigate, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useSettings } from 'context/settings'
import { useSnackbar } from 'notistack'
import { useAppErrors } from 'hooks'
import type { RootState } from 'store/index-reducer'
import { createAInvoice, updateAnInvoice } from 'api/invoice'
import { detailedDiff } from 'deep-object-diff'
import { objectOmit, objectPick, objectRemoveEmptyDeeply } from 'helpers'
import { styled } from '@mui/system'
import { FlexCol } from 'components/atoms/Flex'
import * as Yup from 'yup'
import { Formik } from 'formik'
import { InvoiceForm } from 'screen/Invoices/NewCreate/InvoiceForm'
import moment from 'moment'
import { amountWithoutAdvance, SEGMENT_EVENTS } from 'data'
import analytics from 'utils/segment'
import { getInvoiceSettings } from 'api'

const Container = styled(FlexCol)`
	width: 100%;
	flex: 1;
	flex-wrap: nowrap;
`

const validationSchema = Yup.object().shape({
	discount: Yup.object().shape({
		amount: Yup.number()
			.positive('Should be positive')
			.typeError('Number Required')
			.nullable()
			.min(0, 'Should be greater than 0')
	}),
	contact: Yup.object().shape({
		name: Yup.string().required('Required')
	}),
	extraCharges: Yup.array().of(
		Yup.object().shape({
			description: Yup.string().required('Required'),
			amount: Yup.number()
				.positive('Should be positive')
				.typeError('Number Required')
				.required('Required')
				.min(1, 'Should be greater than 0')
		})
	),
	items: Yup.array()
		.of(
			Yup.object().shape({
				perItemSellPrice: Yup.number()
					.positive('Error')
					.nullable()
					.typeError('Required'),
				quantity: Yup.number()
					.transform(v => (v === '' || Number.isNaN(v) ? null : v))
					.test('maxQuantity', (quantity, values) => {
						let totalItem = 0
						let usedItem = 0
						const stockId = values.parent.stockId

						/*	if (!values.parent.name) {
							return true
						}*/

						const maxQuantity =
							(values.options.context &&
								values.options.context.initialValue &&
								values.options.context.initialValue[stockId]) ||
							values.parent.maxQuantity ||
							0

						const indexInSameItems =
							values.options.context?.items.findIndex(
								(item: any) => item.id === values.parent.id
							)
						values.options.context?.items.map(
							(f: InvoiceItemProps, i: number) => {
								if (
									f.stockId === values.parent.stockId &&
									i <= indexInSameItems
								) {
									totalItem += parseInt(`${f.quantity}`)
								}
								if (
									f.stockId === values.parent.stockId &&
									i < indexInSameItems
								) {
									usedItem += parseInt(`${f.quantity}`)
								}
							}
						)

						const allowed = maxQuantity - totalItem

						if (totalItem > maxQuantity) {
							return values.createError({
								path: values.path,
								message: `${
									allowed < 0
										? maxQuantity - usedItem === 0
											? '0 allowed'
											: `Out of stock (${maxQuantity})`
										: `${allowed} allowed`
								}`
							})
						}
						return true
					})
					.nullable()
					.positive('Quantity should not be 0')
					.typeError('Required'),
				description: Yup.mixed().test(
					'description',
					(description, values) => {
						if (description && description.length > 500) {
							return values.createError({
								path: values.path,
								message:
									'Description should be less than 500 characters'
							})
						}

						return true
					}
				)
			})
		)
		.min(1, 'min 1 item is required')
		.required('Required'),

	comments: Yup.string().max(2000, 'Max 2000 char allowed'),
	taxes: Yup.array().of(
		Yup.object().shape({
			name: Yup.string().required('Required').typeError('Required')
		})
	)
})

export type CurrentInvoiceProps = {
	isPaymentTerms?: boolean
	paymentTermsSelected?: string
	percentageDiscount?: string
} & Partial<InvoiceType> & { loading: boolean }

export const CreateInvoice = () => {
	const [currentInvoice] = useState<CurrentInvoiceProps>({
		loading: true
	})
	const [invoiceSettings, setInvoiceSettings] =
		useState<InvoiceSettingsType>()
	const { id } = useParams()
	const { business } = useSettings()
	const { enqueueSnackbar } = useSnackbar()
	const { setAppError } = useAppErrors()
	const { businesses } = useSelector((state: RootState) => state.business)
	const { user } = useSelector((state: RootState) => state.user)
	const navigate = useNavigate()

	const businessInfo = useMemo(() => {
		const currentBusiness = businesses.find(bus => bus.id === business)
		return {
			businessName: currentBusiness?.name || '',
			address: currentBusiness?.address || '',
			profilePic: currentBusiness?.profilePic || '',
			email: user?.email || '',
			phone: user?.phone || { countryCode: '', number: '' },
			cnic: currentBusiness?.cnic || '',
			strn: currentBusiness?.strn || '',
			termsAndConditions: invoiceSettings?.settings
				?.displayTermsAndConditions
				? currentBusiness?.termsAndConditions || ''
				: ''
		}
	}, [business, businesses, user?.email, user?.phone, invoiceSettings])

	const onUpdate = async (values: InvoiceType, actions: any) => {
		try {
			actions.setSubmitting(true)
			const res1: Partial<InvoiceType> = detailedDiff(
				objectOmit(currentInvoice, 'taxes', 'items'),
				objectOmit(values, 'taxes', 'items')
			).updated

			const res = await updateAnInvoice(values?.id || '', {
				...res1,
				...objectPick(
					values,
					'extraCharges',
					'taxes',
					'advance',
					'comments',
					'isCustomTermsAndCondition',
					'termsAndConditions'
				),
				items: values.items.map(m => {
					if (moment(m.id).isValid()) {
						return objectOmit(m, 'id')
					}
					return m
				}),

				discount: {
					...values.discount,
					amount: values.discount?.amount || '0'
				},
				paymentTerms: values?.paymentTerms,
				paymentMethod: values?.paymentMethod,
				ledgerBalance: values.includeBalance
					? values.ledgerBalance
					: null
			})
			enqueueSnackbar('Successfully Updated', {
				variant: 'success'
			})

			navigate(`/invoice/view/${res?.id}`, {
				replace: true,
				state: { data: res, isUpdated: true }
			})
			analytics.track(SEGMENT_EVENTS.INVOICE_COMPLETE_NEW_INVOICE)
		} catch (e: any) {
			setAppError(e, actions)
		} finally {
			actions.setSubmitting(false)
		}
	}

	const onCreate = async (values: InvoiceType, actions: any) => {
		let newValue: InvoiceType = {
			...values
		}
		newValue = objectRemoveEmptyDeeply(newValue)

		try {
			actions.setSubmitting(true)
			const res = await createAInvoice({
				...newValue,
				ledgerBalance: newValue.includeBalance
					? newValue.ledgerBalance
					: null,
				items: newValue.items.map(m => {
					if (moment(m.id).isValid()) {
						return objectOmit(m, 'id')
					}
					return m
				})
			})
			enqueueSnackbar('Successfully Created', {
				variant: 'success'
			})
			navigate(`/invoice/view/${res.id}`, {
				replace: true,
				state: { data: res, isUpdated: true }
			})
			analytics.track(SEGMENT_EVENTS.INVOICE_COMPLETE_NEW_INVOICE)
		} catch (e: any) {
			setAppError(e, actions)
		} finally {
			actions.setSubmitting(false)
		}
	}

	const onSubmit = (values: InvoiceType, actions: any) => {
		const WithoutAdvance = amountWithoutAdvance(
			values as Required<InvoiceType>
		)
		const finalValue: InvoiceType = {
			...values,
			ledgerBalance: values.includeBalance
				? values.ledgerBalance
				: undefined
		}
		if (
			parseFloat(
				(finalValue.advance && finalValue.advance.amount) || '0'
			) > WithoutAdvance
		) {
			actions.setErrors({
				advance: {
					amount: 'Advance amount cannot be greater'
				}
			})
			actions.setSubmitting(false)
		} else {
			if (id === undefined || id === '0') {
				onCreate(finalValue, actions)
			} else {
				onUpdate(finalValue, actions)
			}
		}
	}
	const getInitialInvoiceSettings = useCallback(async () => {
		try {
			const invoiceSettings = await getInvoiceSettings(business)
			setInvoiceSettings(invoiceSettings)
		} catch (err: any) {
			setAppError(err)
		}
	}, [business, setAppError])

	useEffect(() => {
		getInitialInvoiceSettings()
	}, [getInitialInvoiceSettings])

	return (
		<Container>
			<Formik
				initialValues={{ loading: true } as any}
				onSubmit={onSubmit}
				validationSchema={validationSchema}
			>
				{() => <InvoiceForm businessInfo={businessInfo} />}
			</Formik>
		</Container>
	)
}
