/* eslint-disable quotes */
import type { Localization } from './index'

export const ur: Localization = {
	components: {
		MuiBreadcrumbs: {
			defaultProps: {
				expandText: 'Pokaži putanju'
			}
		},
		MuiTablePagination: {
			defaultProps: {
				getItemAriaLabel: type => {
					if (type === 'first') {
						return 'Idi na prvu stranicu'
					}
					if (type === 'last') {
						return 'Idi na posljednju stranicu'
					}
					if (type === 'next') {
						return 'Idi na sljedeću stranicu'
					}
					// if (type === 'previous') {
					return 'Idi na prethodnu stranicu'
				},
				labelRowsPerPage: 'قطار فی صفحہ',
				labelDisplayedRows: ({ from, to, count }) =>
					`${from}–${to} od ${
						count !== -1 ? count : `više nego ${to}`
					}`
			}
		},
		MuiRating: {
			defaultProps: {
				getLabelText: value => {
					const lastDigit = value % 10
					const lastTwoDigits = value % 100
					if (
						[2, 3, 4].includes(lastDigit) &&
						![12, 13, 14].includes(lastTwoDigits)
					) {
						return 'Zvijezde'
					}
					return 'Zvijezda'
				},
				emptyLabelText: 'Prazno'
			}
		},
		MuiAutocomplete: {
			defaultProps: {
				clearText: 'Briši',
				closeText: 'Zatvori',
				loadingText: 'Učitavanje…',
				noOptionsText: 'Nema opcija',
				openText: 'Otvori'
			}
		},
		MuiAlert: {
			defaultProps: {
				closeText: 'Zatvori'
			}
		},
		MuiPagination: {
			defaultProps: {
				'aria-label': 'Navigacija po stranicama',
				getItemAriaLabel: (type, page, selected) => {
					if (type === 'page') {
						return `${selected ? '' : 'Idi na '}stranicu ${page}`
					}
					if (type === 'first') {
						return 'Idi na prvu stranicu'
					}
					if (type === 'last') {
						return 'Idi na zadnju stranicu'
					}
					if (type === 'next') {
						return 'Idi na sljedeću stranicu'
					}
					// if (type === 'previous') {
					return 'Idi na prethodnu stranicu'
				}
			}
		}
	},
	other: {
		home: 'گھر',
		ordercenter: 'آرڈر سینٹر',
		orderbookers: 'Order Booker',
		contacts: 'رابطے',
		inventory: 'انوینٹری',
		quotation: 'اقتباس',
		invoices: 'رسیدیں',
		changelog: 'Changelog',
		help: 'Help',
		analytics: 'تجزیات',
		orderFinancing: 'Ordr Finance',
		creditbook: 'Creditbook',
		logout: 'لاگ آوٹ',
		menu: 'مینو',
		phoneNumber: 'صفحہ',
		orderForm: 'آرڈر فارم',
		userPermissions: 'Users and Permissions',
		settings: 'Settings',
		whatsNew: 'What’s New',
		loadSheet: 'Load Sheet',
		userRoles: 'User and Roles',

		// APP
		'app.button.continue': 'جاری رہے',
		'app.button.back': 'پیچھے',
		'app.notifications.markAsRead': 'سب کو پڑھا ہوا شمار کریں',
		'app.notifications.notifications': 'اطلاعات',
		'app.notifications.noNewNotifications': 'کوئی نئی اطلاعات نہیں ہیں۔',
		'app.signOut': 'باہر جائیں',

		// field error messages
		'fieldErrorMessages.required': 'درکار ہے۔',

		// components
		// molecules
		'molecules.imageCrop.editPhoto': 'تصویر میں ترمیم کریں۔',
		'molecules.imageCrop.uploadPhototypeSize':
			'ایسی تصویر اپ لوڈ کریں جس کی کم از کم ریزولوشن 320px x 400px .JPEG، .JPG یا .PNG ہو۔',
		'molecules.imageCrop.dragPhoto': 'تصویر کو تبدیل کرنے کے لیے گھسیٹیں۔',
		'molecules.imageCrop.cancel': 'منسوخ کریں۔',
		'molecules.imageCrop.save': 'محفوظ کریں۔',

		// atoms
		'atoms.searchBar.search': 'تلاش کریں۔',

		// snackbar
		'snackbar.successCreate': 'کامیابی کے ساتھ تخلیق ہو گیا۔',
		'snackbar.successUpdate': 'کامیابی کے ساتھ اپ ڈیٹ ہو گیا',
		'snackbar.successDelete': 'کامیابی سے حذف کر دیا گیا۔',
		'snackbar.created': 'بنایا',
		'snackbar.updated': 'اپ ڈیٹ',
		'snackbar.deleted': 'حذف کر دیا گیا',

		// Add User
		'addUser.addUserButton': 'Add User',
		'addUser.title': 'Enter phone number for the user you want to add',

		// ONBOARDING
		'onboarding.enterCode': 'وہ کوڈ درج کریں جو {mobNo} کو بھیجا گیا ہے',
		'onboarding.phoneVerification': 'فون نمبر کی تصدیق',
		'onboarding.didnotGetCode': 'کوڈ نہیں ملا؟',
		'onboarding.resend': 'دوبارہ بھیجیں۔',
		'onboarding.tryAgain': 'آپ دوبارہ کوشش کر سکتے ہیں۔',

		'onboarding.form.yourName': 'آپ کا پورا نام کیا ہے؟',
		'onboarding.form.yourName.placeholder': 'نام درج کریں',
		'onboarding.form.businessName': 'آپ کے کاروبار کا نام کیا ہے؟',
		'onboarding.form.businessName.placeholder': 'کاروبار کا نام درج کریں',
		'onboarding.form.businessType': 'آپ کے کاروبار کی قسم کیا ہے؟',
		'onboarding.form.businessType.placeholder': 'کاروبار کی قسم درج کریں',
		'onboarding.form.supplyChainPosition':
			'سپلائی چین میں آپ کا کیا کردار ہے؟',
		'onboarding.form.settingUpWebApp': 'اپنا Webapp ترتیب دے رہا ہے۔',
		'onboarding.form.almostDone': 'تقریپا ہو گیا!',
		'onboarding.login.welcomeTo': 'میں خوش آمدید',
		'onboarding.login.creditBookWebApp': 'کریڈٹ بک ویب ایپ',
		'onboarding.login.loginWithPhone':
			'جاری رکھنے کے لیے اپنے فون نمبر کے ساتھ لاگ ان کریں۔',
		'onboarding.login.loginButton': 'لاگ ان کریں',
		'onboarding.login.manageBusiness':
			'اپنے کاروبار کو منظم کرنے کا آسان ترین طریقہ!',

		'onboarding.signup.webappReady': 'آپ کی کریڈٹ بک ویب ایپ تیار ہے۔',
		'onboarding.signup.customizedRequirements':
			'آپ کی تفصیلات کی مدد سے، ہم نے آپ کے لیے آپ کی کاروباری ضروریات کو پورا کرنے کے لیے WebApp کو حسب ضرورت بنایا ہے!',
		'onboarding.signup.startUsingWebApp':
			'اپنا WebApp استعمال کرنا شروع کریں۔',
		'onboarding.snackbar.otpVerification': 'Otp کامیابی کے ساتھ بھیجیں۔',
		'onboarding.signup.tutorial': 'WebApp ٹیوٹوریل کھیلیں',
		'onboarding.form.submit': 'جمع کرائیں',
		'onboarding.form.continue': 'جاری رہے',

		// CONTACTS
		'contacts.newContact': 'نئے رابطے بنائیں',
		'contacts.toReceive': 'Receivables',
		'contacts.toPay': 'Payables',
		'contacts.form.placeholder.name': 'نام',
		'contacts.form.placeholder.businessName': 'کاروبار کا نام',
		'contacts.form.placeholder.mobileNumber': 'موبائل فون کانمبر*',
		'contacts.form.placeholder.emailAddress': 'ای میل اڈریس*',
		'contacts.form.placeholder.mailAddress': 'ڈاک کا پتہ*',
		'contacts.form.placeholder.comments': 'تبصرے',
		'contacts.form.businessRelationShip': 'کاروباری تعلقات*',
		'contacts.form.bankingInformation.addBank': 'بینک شامل کریں۔',
		'contacts.form.bankingInformation.placeholder.accNumber':
			'اکاؤنٹ نمبر یا IBAN*',
		'contacts.form.bankingInformation.placeholder.bankName': 'بینک کا نام*',
		'contacts.form.bankingInformation.placeholder.accTitle':
			'اکاؤنٹ کا عنوان*',
		'contacts.form.bankingInformation.placeholder.branch': 'شاخ*',
		'contacts.form.openingBalance.placeholder.balance': 'اوپننگ بیلنس',
		'contacts.form.openingBalance.credit': 'آپ کو ادا کرنا ہوگا۔		',
		'contacts.form.openingBalance.debit': 'آپ کو ادا کرنا ہے',
		'contacts.form.viewContact': 'رابطہ دیکھیں',
		'contacts.form.addContact': 'ایک نیا رابطہ شامل کریں',
		'contacts.form.saveContact': 'رابطہ محفوظ کریں',
		'contacts.table.name': 'نام',
		'contacts.table.phone': 'فون',
		'contacts.table.type': 'قسم',
		'contacts.table.amount': 'رقم',
		'contacts.table.noContactsAdded':
			'آپ نے ابھی تک کوئی رابطہ شامل نہیں کیا ہے۔',
		'contacts.table.noContactsFound': 'کے لیے کوئی رابطے نہیں ملے',
		'contacts.ledger.footer.help': 'مدد',
		'contacts.ledger.header.viewContactDetails': 'رابطے کی تفصیلات دیکھیں',
		'contacts.ledger.chat.addEntry': 'اندراج شامل کریں۔',
		'contacts.ledger.chat.payment.recordPayment': 'ادائیگی ریکارڈ کریں۔',
		'contacts.ledger.chat.payment.paymentMethod': 'ادائیگی کا طریقہ',
		'contacts.ledger.chat.payment.financialTransactionsMessage':
			'یہ خصوصیت مالی لین دین کو انجام دیتی ہے۔',
		'contacts.ledger.chat.payment.cancel': 'منسوخ کریں۔',
		'contacts.ledger.chat.payment.done': 'ہو گیا',
		'contacts.ledger.chat.addEntry.Invoice': 'رسید',
		'contacts.ledger.chat.addEntry.Quotation': 'اقتباس',
		'contacts.ledger.chat.addEntry.Payment': 'ادائیگی',

		// Order Center
		'orderCenter.welcome': 'Khushamdeed {name}',
		'orderCenter.welcome.welcomeMessage':
			'Welcome to your Order Center. You can view all of your orders and customer inquiries here',
		'orderCenter.pageName': 'Order Center',
		'orderCenter.recordNewInquiryOrder': 'Record New Order/Inquiry',
		'orderCenter.recordOrder': 'Record new Order recieved from Customer',
		'orderCenter.recordInquiry':
			'Record new Inquiry recieved from Customer',
		'orderCenter.Inquiry': 'Inquiry',
		'orderCenter.Order': 'Order',
		'orderCenter.contactDetails': 'Contact details',
		'orderCenter.inquiryID': 'Inquiry ID',
		'orderCenter.selectContact': 'Select a Contact',
		'orderCenter.inquiryDate': 'Inquiry Date',
		'orderCenter.attachDocument': 'Attach document',
		'orderCenter.receivingChannel': 'Select Receiving Channel',
		'orderCenter.Inventory.SelectItems': 'Select items',
		'orderCenter.Inventory.AddItem': 'Add a new Item',

		// INVENTORY
		'inventory.pageName.inventory': 'Inventory',
		'inventory.pageName.searchInventory': 'Search Inventory',
		'inventory.stock.addNewStock': 'Add a New Stock Item',
		'inventory.imagePlaceHolder.dragDropFile':
			'Drag and drop a file here or',
		'inventory.imagePlaceHolder.browse': 'browse',
		'inventory.units.units': 'Units',
		'inventory.units.addUnit': 'Add another unit',
		'inventory.addToInventory': 'Add to Inventory',

		// PROFILE
		'profile.personalInformation.saveProfile': 'Save Profile',
		'profile.personalInformation.title': 'Personal Information',
		'profile.BusinessManagement.title': 'Business Management',
		'profile.BusinessManagement.addNewBusinessButton': 'Add a New Business',
		'profile.BusinessManagement.addNewBusiness': 'Add a New Business',
		'profile.BusinessManagement.viewBusiness': 'View',
		'profile.BusinessManagement.saveBusiness': 'Save Business',
		'profile.profile': 'Profile',

		// Setting
		privacy: 'Privacy',
		taxes: 'Taxes',
		invoice: 'Invoices'
	}
}
