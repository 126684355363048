import { combineReducers } from 'redux'

import user from 'store/user'
import auth from 'store/auth'
import app from 'store/app'
import business from 'store/business'
import settings from 'store/settings'
import inventory from 'store/inventory'
import contact from 'store/contact'
import cbfs from 'store/cbfs'

const rootReducer = combineReducers({
	user,
	auth,
	contact,
	app,
	business,
	settings,
	inventory,
	cbfs
})

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer
