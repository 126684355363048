import React, { useState } from 'react'
import type { ColorsCodeType } from 'typings'
import { FlexCol, FlexRow } from 'components/atoms/Flex'
import { Button, Gutter } from 'components'
import {
	ClickAwayListener,
	Fade,
	Popper,
	styled,
	Typography,
	useTheme
} from '@mui/material'
import PopupState, { bindPopper, bindToggle } from 'material-ui-popup-state'
import { useNavigate } from 'react-router-dom'
import { SelectQuotationModal } from 'components/app/QuotationModal'
import analytics from 'utils/segment'
import { LAYOUT_BREAKPOINT_WIDTH, PRIMARY_BUTTON_BREAKPOINT_WIDTH, SEGMENT_EVENTS } from 'data'
import { Add, KeyboardArrowDown } from '@mui/icons-material'

const Wrapper = styled(FlexCol)`
	background-color: ${({ theme }) => theme.palette.background.default};
	border: 1px solid ${({ theme }) => theme.palette.background.paper};
	border-radius: 8px;
	margin-top: 5px;
	box-shadow: 0 12px 16px -4px rgba(16, 24, 40, 0.08),
		0px 4px 6px -2px rgba(16, 24, 40, 0.03);
`

const Option = styled(FlexRow)`
	padding: 8px 14px;
	width: 100%;

	:hover {
		cursor: pointer;
	}
`

const PrimaryButtonWrapper = styled(FlexRow)`
	@media (max-width: ${PRIMARY_BUTTON_BREAKPOINT_WIDTH}px) {
		display: none;
	}
`

export const CreateInvoicesButton = () => {
	const [isModalOpen, setIsModalOpen] = useState(false)
	const theme = useTheme()
	const {
		palette: { colors }
	} = theme

	const navigate = useNavigate()

	return (
		<>
			<PopupState
				variant="popper"
				popupId="demo-popup-popper"
				disableAutoFocus={true}
			>
				{popupState => (
					<ClickAwayListener onClickAway={() => popupState.close()}>
						<div>
							<Button
								textColor={
									colors['gray']['900'] as ColorsCodeType
								}
								mode={'primary'}
								buttonColor={
									colors['yellow']['300'] as ColorsCodeType
								}
								sx={{
									minWidth: 0,
									minHeight: '32px',
									padding: '0px 10px',
									borderRadius: '5px'
								}}
								{...bindToggle(popupState)}
							>
								<Add fontSize="small" />
								<PrimaryButtonWrapper align="center">
									<Gutter gap={0.5} />
									Create Invoice
									<Gutter gap={0.5} />
									<KeyboardArrowDown fontSize="small" />
								</PrimaryButtonWrapper>
							</Button>
							<Popper
								{...bindPopper(popupState)}
								transition
								placement="bottom-start"
							>
								{({ TransitionProps }) => (
									<Fade {...TransitionProps} timeout={350}>
										<Wrapper>
											<FlexCol style={{ minWidth: 220 }}>
												<Gutter spacing={0.5} />
												<Option
													align={'center'}
													onClick={() => {
														navigate(
															'/invoice/create-invoice'
														)
														analytics.track(
															SEGMENT_EVENTS.INVOICE_CREATE_NEW
														)
														analytics.track(
															SEGMENT_EVENTS.INVOICE_CREATE_DIRECT_SALE
														)
														popupState.close()
													}}
												>
													<Typography
														variant={'body2'}
													>
														Direct Sale Invoice
													</Typography>
												</Option>
											</FlexCol>
											<FlexCol style={{ minWidth: 220 }}>
												<Gutter spacing={0.5} />
												<Option
													align={'center'}
													onClick={() => {
														setIsModalOpen(true)
														analytics.track(
															SEGMENT_EVENTS.INVOICE_CREATE_NEW
														)
														analytics.track(
															SEGMENT_EVENTS.INVOICE_CREATE_IMPORT_QUOTATION,
															{
																screen: 'invoice_book'
															}
														)
														popupState.close()
													}}
												>
													<Typography
														variant={'body2'}
													>
														Import from Quotation
													</Typography>
												</Option>
											</FlexCol>
										</Wrapper>
									</Fade>
								)}
							</Popper>
							<SelectQuotationModal
								open={isModalOpen}
								onClose={() => {
									setIsModalOpen(false)
								}}
								onQuotationSelect={quotation => {
									const currentInvoice: any = { ...quotation }
									for (const item of currentInvoice.items) {
										item.isFrontendKey = true
									}
									navigate('/invoice/create-invoice', {
										state: {
											currentInvoice: currentInvoice
										}
									})

									setIsModalOpen(false)
								}}
							/>
						</div>
					</ClickAwayListener>
				)}
			</PopupState>
		</>
	)
}
