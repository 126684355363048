import React from 'react'
import { styled } from '@mui/system'
import { Avatar, Capsule, Gutter } from 'components'
import { FlexCol, FlexRow } from 'components/atoms/Flex'
import type { ColorsCodeType, IOrderCenterProps } from 'typings'
import { Typography, useTheme } from '@mui/material'
import moment from 'moment/moment'
import { OrderTable } from 'screen/OrderCenter/OrderTable'
import { GenerateButton } from 'screen/OrderCenter/Components/GenerateButton'
import { formatId } from 'helpers/formatId'
import { dateTimeFormat } from 'data'
import { Abbr } from 'components/atoms/Abbr'
import { useSettings } from 'context/settings'

const Header = styled(FlexRow)`
	align-items: center;
	height: 80px;
	width: 100%;
	padding: 5px 20px;
	background-color: ${({ theme }) => theme.palette.colors.green['600']};
`

const StyledCapsule = styled(Capsule)`
	padding: 2px 5px;
	font-size: 10px;
	margin: 0;
`

const Text = styled(Typography)`
	color: ${({ theme }) =>
		theme.palette.mode === 'dark'
			? theme.palette.colors.black['300']
			: theme.palette.colors.white['300']};
`

const Wrapper = styled(FlexCol)`
	width: 100%;
	height: 100%;
	max-height: 100%;
`

const Col = styled(FlexCol)`
	padding: 1px 0;
	flex: 1;
`

const Body = styled(FlexCol)`
	width: 100%;
	flex: 1;
	padding: 10px;
	overflow: hidden;
	background-color: ${({ theme }) => theme.palette.colors.blue['50']};
`

const BodyWrapper = styled(FlexCol)`
	padding: 10px;
	width: 100%;
	height: 100%;
	border-radius: 10px;
	background-color: ${({ theme }) => theme.palette.background.default};
`

const CommentText = styled(Typography)`
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
`

const CommentBox = styled(FlexRow)`
	flex: 1;
	overflow: hidden;
	text-overflow: ellipsis;
	//white-space: nowrap;
	max-width: 100%;
`

type OrderCenterDetailsProps = {
	item: IOrderCenterProps
	maxHeight?: number
	page?: string
}

export const OrderCenterDetails: React.ComponentType<
	OrderCenterDetailsProps
> = ({ item, maxHeight, page = 'default' }) => {
	const theme = useTheme()
	const { business } = useSettings()
	const {
		palette: { colors }
	} = theme
	const {
		contact: { name, profilePic },
		type,
		items,
		initiationAt,
		friendlyId
	} = item

	/*	const handleButtonClick = async () => {
		for (let i = 9000; i < 12000; i++) {
			try {
				const res = seedD[i]

				const modifiedData = {
					business,
					name: res['Item Name'].toString(),
					code: res['Code'].replaceAll(' ', ''),
					icon: '',
					labels: [res['Labels'].toString()],
					comments: '',
					stock: [
						{
							code: res['Code'] + 'A',
							unit: res['Units '],
							quantity: res['Quantity Available'].toString(),
							perItemCostPrice: res['Cost Price'].toString(),
							perItemSellPrice: res['Selling Price'].toString()
						}
					]
				}

				await createAnInventoryItem(modifiedData)

				//Optionally, you can update the UI or log success here
			} catch (error) {
				// Handle errors if the API request fails
			}
		}
	}

	useEffect(() => {
		handleButtonClick()
	}, [])*/

	return (
		<Wrapper>
			<Header>
				<Avatar
					src={profilePic}
					borderWidth={2}
					borderColor={colors.green['100'] as ColorsCodeType}
				/>
				<Gutter gap={0.6} />
				<Col>
					<FlexRow
						justify={'space-between'}
						align={'center'}
						style={{ width: '100%' }}
					>
						<FlexRow>
							<Text variant={'body2'} fontWeight={500}>
								<Abbr title={name} length={25} />
							</Text>
							<Gutter gap={0.4} />
							{friendlyId && (
								<StyledCapsule
									isSelected
									disabled
									name={`${
										type === 'order' ? 'ORD' : 'INQ'
									} - ${formatId(friendlyId)}`}
									value={`${
										type === 'order' ? 'ORD' : 'INQ'
									} - ${formatId(friendlyId)}`}
									color={type === 'order' ? 'yellow' : 'blue'}
								/>
							)}
						</FlexRow>
					</FlexRow>
					<Text variant={'caption'}>
						{moment(initiationAt).format(dateTimeFormat)}
					</Text>
				</Col>
			</Header>
			<Body>
				<BodyWrapper>
					<OrderTable items={items} maxHeight={maxHeight || 100} />
					<Gutter />
					{page == 'default' && (
						<FlexRow
							justify={'space-between'}
							style={{ width: '100%' }}
						>
							<CommentBox>
								<CommentText variant={'caption'}>
									{item?.comments}
								</CommentText>
							</CommentBox>
							<Gutter gap={1} />
							<GenerateButton item={item} />
						</FlexRow>
					)}
				</BodyWrapper>
			</Body>
		</Wrapper>
	)
}
