import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchTaxes } from 'store/settings/actions'
import { FlexCol } from 'components/atoms/Flex'
import { styled } from '@mui/system'
import { TaxesTable } from './TaxesTable'
import { Header } from '../Header'
import { AddTaxModal } from './AddTaxesModal'

import type { RootState } from 'store/index-reducer'
import { LAYOUT_PADDING } from 'data'

const Wrapper = styled(FlexCol)`
	width: 100%;
	height: 100%;
`

const TableWrapper = styled('div')`
	width: 100%;
	padding: ${LAYOUT_PADDING}px;
`

export const Taxes = () => {
	const [open, setOpen] = useState(false)
	const [editId, setEditId] = useState<string>()

	const dispatch = useDispatch()
	const { dataSource } = useSelector(
		(state: RootState) => state.settings.taxUnits
	)

	const onClose = () => {
		setOpen(false)
		setEditId(undefined)
	}

	useEffect(() => {
		dispatch(fetchTaxes())
	}, [dispatch])

	return (
		<Wrapper>
			<Header
				numOfItems={dataSource.length}
				headerText="Taxes"
				buttonText="Add New Tax"
				onButtonClick={() => setOpen(true)}
			/>
			<TableWrapper>
				<TaxesTable
					dataSource={dataSource}
					setModalOpen={setOpen}
					setEditId={setEditId}
				/>
			</TableWrapper>
			<AddTaxModal onClose={onClose} open={open} id={editId} />
		</Wrapper>
	)
}
