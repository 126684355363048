import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import type { InvoiceType, PaymentStatus } from 'typings/invoices'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useAppErrors, useDimension } from 'hooks'
import { getAInvoice, publishAnInvoice } from 'api/invoice'
import { Divider, styled } from '@mui/material'
import { Gutter } from 'components'
import { FlexCol } from 'components/atoms/Flex'
import { RightViewOptions } from 'screen/Invoices/View/RightViewOptions'
import { InvoicePdf } from 'screen/Invoices/View/InvoicePdf'
import { InvoiceHeader } from './Component/InvoiceHeader'
import { objectFindKey, objectOmit } from 'helpers'
import { PublishWarningModal } from './Component/PublishWarningModal'
import { EditWarningModal } from './Component/EditWarningModal'
import * as Yup from 'yup'
import { enqueueSnackbar } from 'notistack'
import {
	calculateInvoiceAdvanceAmount,
	invoice_calculation,
	LAYOUT_PADDING,
	SCROLLBAR_STYLE
} from 'data'
import type {
	BalancesProps,
	InvoiceSettingsType,
	PaymentMethods,
	TransactionPaymentType
} from 'typings'
import { getPaymentByInvoiceId } from 'api/transaction'
import { getInvoiceSettings } from 'api'
import { useDispatch, useSelector } from 'react-redux'
import { setNavigation } from 'store/app/actions'
import { INVOICE_VIEW_NAVIGATION } from 'data/navigation'
import { Header } from './Header'
import { getAContact } from 'api/contacts'
import type { RootState } from 'store/index-reducer'
import { useSettings } from 'context/settings'

const Wrapper = styled('div')<{ height: number }>`
	display: grid;
	border: 1px solid ${({ theme }) => theme.palette.colors.gray['300']};
	border-radius: 8px;
	grid-template-columns: auto 1px minmax(400px, auto);
	max-height: ${({ height }) => height}px;
	//height: max-content;
	width: 100%;
	overflow: auto;

	${SCROLLBAR_STYLE}
`

const DividerWrapper = styled(Divider)`
	width: 0;
	border: 0.5px solid ${({ theme }) => theme.palette.colors.gray['300']};
`

const Col = styled(FlexCol)<{ height: number }>`
	height: ${({ height }) => (height ? `${height}px` : '100%')};
	overflow: auto;

	box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.33);
	-webkit-box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.33);
	-moz-box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.33);

	${SCROLLBAR_STYLE}
`

const LeftViewOptions = styled(FlexCol)`
	padding: 24px 30px 0 24px;
`

const Container = styled(FlexCol)`
	width: 100%;
	padding: ${LAYOUT_PADDING}px;
`

const validationSchema = Yup.object().shape({
	discount: Yup.object().shape({
		amount: Yup.number()
			.positive('Should be positive')
			.typeError('Number Required')
			.nullable()
			.min(0, 'should be greater than 0')
	}),
	contact: Yup.object().shape({
		name: Yup.string().required('Required')
	}),
	extraCharges: Yup.array().of(
		Yup.object().shape({
			description: Yup.string().required('Required'),
			amount: Yup.string().required('Required')
		})
	),
	items: Yup.array()
		.of(
			Yup.object().shape({
				perItemSellPrice: Yup.number()
					.positive('Error')
					.min(1, 'Required')
					.required('Required')
					.typeError('Required'),
				quantity: Yup.number()
					.positive('Error')
					.integer()
					.min(1, 'Required')
					.test('maxQuantity', (quantity, values) => {
						const { parent } = values
						let maxQuantity = parent?.maxQuantity

						if ((quantity || 0) > maxQuantity) {
							if (maxQuantity < 0) {
								maxQuantity = 0
							}
							return values.createError({
								path: values.path,
								message: `${maxQuantity} allowed`
							})
						}
						return true
					})
					.required('Required')
					.typeError('Required'),
				description: Yup.mixed().test(
					'description',
					(description, values) => {
						const { from } = values.options as any
						if (
							from &&
							objectFindKey(from[0].value, 'description')
						) {
							if (description === undefined) {
								return values.createError({
									path: values.path,
									message: 'Description is required'
								})
							}

							if (description === '') {
								return values.createError({
									path: values.path,
									message: 'Description is required'
								})
							}

							if (description.length > 500) {
								return values.createError({
									path: values.path,
									message:
										'Description should be less than 500 characters'
								})
							}
						}
						return true
					}
				)
			})
		)
		.min(1, 'min 1 item is required')
		.required('Required')
})

export const InvoiceView = () => {
	const [currentInvoice, setCurrentInvoice] = useState<InvoiceType>()
	const [invoiceSettings, setInvoiceSettings] =
		useState<InvoiceSettingsType>()
	const [openPublishWarningModal, setOpenPublishWarningModal] =
		useState(false)
	const [openEditWarningModal, setOpenEditWarningModal] = useState(false)
	const [payments, setPayments] = useState<TransactionPaymentType[]>([])
	const { state } = useLocation()
	const { id } = useParams()
	const { business } = useSettings()
	const { setAppError } = useAppErrors()
	const { height } = useDimension()
	const dispatch = useDispatch()
	const { businesses } = useSelector((state: RootState) => state.business)
	const componentRef = useRef(null)

	const navigate = useNavigate()

	const isVisible = currentInvoice?.isCustomTermsAndCondition
		? true
		: currentInvoice?.status === 'draft'
		? invoiceSettings?.settings.displayTermsAndConditions
		: !!currentInvoice?.termsAndConditions

	const businessInfo = useMemo(() => {
		const currentBusiness = businesses.find(bus => bus.id === business)
		return {
			termsAndConditions: invoiceSettings?.settings
				?.displayTermsAndConditions
				? currentBusiness?.termsAndConditions || ''
				: ''
		}
	}, [business, businesses, invoiceSettings])

	const publishInvoice = async () => {
		try {
			await validationSchema.validate(currentInvoice)
			setOpenPublishWarningModal(true)
		} catch (e) {
			setOpenEditWarningModal(true)
		}
	}

	const dispatchTitle = useCallback(
		(inv: InvoiceType) => {
			dispatch(
				setNavigation(
					INVOICE_VIEW_NAVIGATION(inv?.friendlyId || '0', inv.id)
				)
			)
		},
		[dispatch]
	)

	const { totalAmount, advance } = useMemo(() => {
		let totalAmount = 0
		let advance = 0

		if (currentInvoice) {
			const { baseAmtAfterDiscount, totalTax } = invoice_calculation(
				currentInvoice as InvoiceType
			)

			if (payments?.length === 0) {
				advance = calculateInvoiceAdvanceAmount(
					objectOmit(currentInvoice, 'id')
				)
			}

			let totalPayments = 0

			if (payments) {
				totalPayments = payments.reduce(
					(accumulator, payment) =>
						payment.type === 'sent'
							? accumulator - payment.amount
							: accumulator + payment.amount,
					totalPayments
				)
			}

			totalAmount = Number(
				(baseAmtAfterDiscount + totalTax - totalPayments).toFixed(2)
			)
		}

		return { totalAmount, advance }
	}, [currentInvoice, payments])

	const createPublishInvoiceRequest = async () => {
		try {
			if (currentInvoice) {
				let paymentStatus: PaymentStatus = 'pending'
				let paymentMethod: PaymentMethods = 'unpaid'
				if (advance > 0) {
					paymentMethod = 'cash'
					if (advance === totalAmount) {
						paymentStatus = 'paid'
					} else if (advance > totalAmount) {
						enqueueSnackbar('Advance greater than Total Amount', {
							variant: 'error'
						})
						return
					} else {
						paymentStatus = 'partial'
					}
				}
				if (totalAmount === 0) {
					paymentStatus = 'paid'
				}

				const businessInfo = {
					...currentInvoice.businessInfo,
					cnic: invoiceSettings?.settings?.displayBusinessCnic
						? currentInvoice.businessInfo.cnic
						: '',
					strn: invoiceSettings?.settings?.displayBusinessStrn
						? currentInvoice.businessInfo.strn
						: ''
				}

				const contact = {
					...currentInvoice.contact,
					cnic: invoiceSettings?.settings?.displayCustomerCnic
						? currentInvoice.contact.cnic
						: ''
				}

				const updatedInvoice: InvoiceType = {
					...currentInvoice,
					paymentStatus,
					paymentMethod,
					contact,
					businessInfo,
					termsAndConditions: isVisible
						? currentInvoice.termsAndConditions
						: null
				}
				const res = await publishAnInvoice(
					currentInvoice.id,
					updatedInvoice
				)

				enqueueSnackbar('Invoice Published Successfully', {
					variant: 'success'
				})

				navigate(`/invoice/view/${res?.id}`, {
					replace: true,
					state: { data: res, isUpdated: true }
				})

				const paymentsRes = await getPaymentByInvoiceId(
					currentInvoice.id
				)
				setPayments(paymentsRes)
			}
		} catch (e: any) {
			setAppError(e)
		}
	}

	const fetchLedgerInformation = async (id: string) => {
		try {
			const contactResponse = await getAContact(id || '')
			const filterD = `${
				contactResponse.balances?.find(
					(f: BalancesProps) =>
						f.accountName === 'accounts_receivables'
				)?.balance || 0
			}`

			return {
				ledgerBalance: {
					currency: 'PKR',
					amount: parseFloat(parseFloat(filterD).toFixed(2)) || 0
				}
			}
		} catch (e) {
			//
		}
	}

	const getInvoiceDetails = useCallback(async () => {
		try {
			let res = await getAInvoice(id || '0')
			if (
				(res.ledgerBalance?.amount || 0) > 0 &&
				res.status === 'draft'
			) {
				const ledger = await fetchLedgerInformation(
					res.contact.id || ''
				)
				res = { ...res, ...ledger }
			}

			dispatchTitle(res)

			setCurrentInvoice({
				...res,
				termsAndConditions:
					res.status === 'draft'
						? res.isCustomTermsAndCondition
							? res.termsAndConditions
							: businessInfo.termsAndConditions
						: res.termsAndConditions
			})
		} catch (e: any) {
			setAppError(e)
		}
	}, [id, dispatchTitle, businessInfo.termsAndConditions, setAppError])

	useEffect(() => {
		if (state?.data) {
			setCurrentInvoice({
				...state?.data,
				termsAndConditions:
					state?.data.status === 'draft'
						? state?.data.isCustomTermsAndCondition
							? state?.data.termsAndConditions
							: businessInfo.termsAndConditions
						: state?.data.termsAndConditions
			})
			dispatchTitle(state?.data)
		} else {
			getInvoiceDetails()
		}
	}, [
		businessInfo.termsAndConditions,
		dispatchTitle,
		getInvoiceDetails,
		id,
		state
	])

	useEffect(() => {
		getInvoiceDetails()
	}, [getInvoiceDetails, payments])

	const getInvoicePayments = useCallback(
		async (id: string) => {
			try {
				const res = await getPaymentByInvoiceId(id)
				setPayments(res)
			} catch (e: any) {
				setAppError(e)
			}
		},
		[setAppError]
	)

	useEffect(() => {
		if (id) {
			getInvoicePayments(id)
		}
	}, [id, getInvoicePayments])

	const fetchInvoiceSettings = useCallback(async () => {
		if (currentInvoice) {
			try {
				const res = await getInvoiceSettings(currentInvoice.business)
				setInvoiceSettings(res)
			} catch (e: any) {
				setAppError(e)
			}
		}
	}, [setAppError, currentInvoice])

	useEffect(() => {
		fetchInvoiceSettings()
	}, [fetchInvoiceSettings])

	return (
		<>
			{currentInvoice && (
				<>
					<Header
						currentInvoice={currentInvoice}
						publishInvoice={publishInvoice}
						pdfRef={componentRef}
					/>

					<Container>
						<InvoiceHeader
							amountDue={totalAmount - advance}
							currentInvoice={currentInvoice}
							publishInvoice={publishInvoice}
							setPayments={setPayments}
						/>
						<Gutter gap={2} />

						<Wrapper height={height}>
							<LeftViewOptions>
								<Col height={height - 255}>
									<InvoicePdf
										ref={componentRef}
										title={`invoice-${currentInvoice.id}`}
										currentInvoice={currentInvoice}
										invoiceSettings={invoiceSettings}
										isTermsVisible={isVisible}
									/>
								</Col>
							</LeftViewOptions>
							<DividerWrapper orientation="vertical" flexItem />
							<RightViewOptions
								currentInvoice={currentInvoice}
								totalAmountDue={totalAmount - advance}
								payments={payments}
							/>
						</Wrapper>
					</Container>
				</>
			)}

			<PublishWarningModal
				open={openPublishWarningModal}
				onClose={() => {
					setOpenPublishWarningModal(false)
					createPublishInvoiceRequest()
				}}
				onCancel={() => setOpenPublishWarningModal(false)}
			/>
			<EditWarningModal
				open={openEditWarningModal}
				onClose={() => {
					setOpenEditWarningModal(false)
					navigate(
						`/invoice/create-invoice/${currentInvoice?.id || 0}`,
						{
							state: { currentInvoice: currentInvoice }
						}
					)
				}}
				onCancel={() => setOpenEditWarningModal(false)}
			/>
		</>
	)
}
