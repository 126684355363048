import React from 'react'
import './App.css'
import { Root } from 'screen/root'
import { SettingProvider } from 'context/settings'
import { PusherProvider } from 'PusherJsProvider'

function App() {
	return (
		<SettingProvider
			setting={{ theme: 'light', locale: 'en', direction: 'ltr' }}
		>
			<PusherProvider>
				<Root />
			</PusherProvider>
		</SettingProvider>
	)
}

export default App
