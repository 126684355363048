import React from 'react'
import { TableCell, Typography } from '@mui/material'
import { styled } from '@mui/system'
import { currencyInput, pascalCase } from 'helpers'
import moment from 'moment'
import { dateFormat } from 'data'

const Cell = styled(TableCell)`
	color: ${({ theme }) => theme.palette.colors.gray['500']};
	padding: 7px 7px 7px 24px;
`

const Text = styled(Typography)`
	color: #1c1c1c;

	font-weight: 500;
`

const SmallCell = styled(TableCell, {
	shouldForwardProp: props => props !== 'isVoid'
})`
	color: ${({ theme }) => theme.palette.colors.gray['500']};
	padding: 7px 7px 7px 24px;
	min-width: 150px;
	max-width: 200px;
`

export const RePaymentItem: React.ComponentType<any> = ({
	method,
	status,
	payment_date,
	updated_at,
	amount
}) => {
	return (
		<>
			<Cell variant="head" align={'left'}>
				<Text variant={'subtitle1'} fontWeight={500}>
					{pascalCase(method.replaceAll('_', ' '))}
				</Text>
			</Cell>
			<Cell variant="head" align={'left'}>
				<Text variant={'subtitle1'} fontWeight={500}>
					{currencyInput(amount)}
				</Text>
			</Cell>
			<SmallCell variant="head" align={'left'}>
				<Text variant={'subtitle1'} fontWeight={500}>
					{pascalCase(status.replaceAll('_', ' '))}
				</Text>
			</SmallCell>
			<SmallCell variant="head" align={'left'}>
				<Text variant={'subtitle1'} fontWeight={500}>
					{moment(payment_date).format(dateFormat)}
				</Text>
			</SmallCell>

			<SmallCell variant="head" align={'left'}>
				<Text variant={'subtitle1'} fontWeight={500}>
					{moment(updated_at).format(dateFormat)}
				</Text>
			</SmallCell>
		</>
	)
}
