import {
	FETCH_ALL_STOCK_UNIT,
	FETCH_ALL_STOCK_UNIT_ERROR,
	FETCH_ALL_STOCK_UNIT_SUCCESS,
	FETCH_TAX_ERROR,
	FETCH_TAX_SUCCESS,
	FETCH_TAXES,
	SET_ALL_STOCK_UNIT_LOADING,
	SET_TAX_LOADING,
	type SettingActionTypes
} from './types'
import type { IAPIError, IStockUnits, ITaxUnit } from 'typings'

export const setStockLoading = (payload: boolean): SettingActionTypes => ({
	type: SET_ALL_STOCK_UNIT_LOADING,
	payload
})

export const fetchAllStock = (): SettingActionTypes => ({
	type: FETCH_ALL_STOCK_UNIT
})

export const fetchAllStockUnitSuccess = (
	payload: IStockUnits[]
): SettingActionTypes => ({
	type: FETCH_ALL_STOCK_UNIT_SUCCESS,
	payload
})

export const fetchAllStockUnitError = (
	payload: IAPIError[]
): SettingActionTypes => ({
	type: FETCH_ALL_STOCK_UNIT_ERROR,
	payload
})

export const setTaxLoading = (payload: boolean): SettingActionTypes => ({
	type: SET_TAX_LOADING,
	payload
})

export const fetchTaxes = (): SettingActionTypes => ({
	type: FETCH_TAXES
})

export const fetchTaxesSuccess = (payload: ITaxUnit[]): SettingActionTypes => ({
	type: FETCH_TAX_SUCCESS,
	payload
})

export const fetchTaxesError = (payload: IAPIError[]): SettingActionTypes => ({
	type: FETCH_TAX_ERROR,
	payload
})
