import type { LoadsheetType } from 'typings/loadsheet'
import React from 'react'
import { Typography, styled } from '@mui/material'
import { FlexRow } from 'components/atoms/Flex'

const CommentBox = styled('div')(() => ({
	width: '55%',
	pageBreakInside: 'avoid'
}))

const AmountBox = styled('div')(() => ({
	width: '40%',
	border: '1px solid black',
	borderRadius: '7px',
	padding: '9px 15px 9px 12px'
}))

const GutterVertical = styled('div')`
	margin: 14px 0px;
`

type Props = {
	loadSheet: LoadsheetType
}

export const NotesAmountBox: React.ComponentType<Props> = ({ loadSheet }) => {
	return (
		<>
			<GutterVertical />
			<FlexRow>
				<CommentBox>
					{loadSheet?.metaData?.additonalInfo?.notes && (
						<>
							<Typography variant="body2" fontWeight={'600'}>
								Notes
							</Typography>
							<Typography variant="caption">
								{loadSheet?.metaData?.additonalInfo?.notes}
							</Typography>
						</>
					)}
				</CommentBox>
				<div style={{ width: '5%' }} />
				{loadSheet?.totalAmountDue > 0 &&
					loadSheet?.metaData?.config?.showInvoiceSummary && (
						<AmountBox>
							<FlexRow
								style={{
									justifyContent: 'space-between',
									width: '100%'
								}}
							>
								<Typography
									variant="body2"
									fontWeight={'600'}
									fontSize={14}
								>
									Amount Due (PKR)
								</Typography>
								<Typography
									variant="body2"
									fontWeight={'600'}
									fontSize={18}
								>
									{loadSheet?.totalAmountDue.toLocaleString()}
								</Typography>
							</FlexRow>
						</AmountBox>
					)}
			</FlexRow>
		</>
	)
}
