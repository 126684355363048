import React, { type ChangeEvent, useCallback } from 'react'
import { useField } from 'formik'
import type { TextInputProps } from 'components'
import { ErrorText, TextInput } from 'components'
import { FlexCol, FlexRow } from 'components/atoms/Flex'
import {
	currencyInput,
	POSITIVE_NUMBER_WITH_DECILMAL,
	type Regex
} from 'helpers'

export type FieldCurrencyInputProps = Omit<
	TextInputProps,
	'onBlur' | 'onChange' | 'onFocus'
> & {
	regex?: Regex
	name: string
	onBlur?(amount: string): void
	placeholder?: string
	nextInputRef?: React.MutableRefObject<typeof TextInput> | null
}

export const FieldCurrencyInput = React.forwardRef<
	TextInputProps,
	FieldCurrencyInputProps
>(({ name, regex, nextInputRef, onBlur, ...props }, ref) => {
	const [field, meta, helpers] = useField(name as any)
	const { setValue, setTouched } = helpers
	const { value } = field

	const error = meta.touched && meta.error

	const handleBlur = useCallback(() => {
		if (
			value &&
			value.length > 0 &&
			value.charAt(value.length - 1) === '.'
		) {
			setValue(value.slice(0, -1))
			onBlur?.(value.slice(0, -1))
		} else {
			onBlur?.(value)
		}

		setTouched(true)
	}, [onBlur, setTouched, setValue, value])

	const onChange = (e: ChangeEvent<HTMLInputElement>) => {
		const typedValue = e.target.value.replaceAll(',', '') || ''

		if (typedValue.match(regex || POSITIVE_NUMBER_WITH_DECILMAL)) {
			setValue(typedValue)
		}
	}

	return (
		<FlexCol align={'flex-start'}>
			<TextInput
				id={name}
				value={value ? currencyInput(value) : ''}
				onChange={onChange}
				type={'tel'}
				error={!!error}
				onBlur={handleBlur}
				{...props}
			/>
			<FlexRow style={{ width: '100%' }} align={'flex-start'}>
				<ErrorText text={error} />
			</FlexRow>
		</FlexCol>
	)
})

FieldCurrencyInput.displayName = 'FieldCurrencyInput'
