import { call, fork, put, take } from 'redux-saga/effects'
import { FETCH_ALL_LABEL, FETCH_INVENTORY_LIST } from './types'
import { setAllLabel } from './actions'
import { getLabelsList } from 'api'
import { LogError } from 'utils/log'
import { store } from 'store/index'

function* fetchInventoryLabels() {
	try {
		const business = store.getState().user.currentBusiness || ''
		const res: string[] = yield call(getLabelsList, business as string)
		yield put(setAllLabel(res))
	} catch (e: any) {
		LogError(e)
	}
}

function* inventoryWatcher(): any {
	while (true) {
		const everything = yield take([FETCH_ALL_LABEL, FETCH_INVENTORY_LIST])
		if (!everything) {
			return
		}

		const { type } = everything

		switch (type) {
			case FETCH_ALL_LABEL:
				yield fork(fetchInventoryLabels)
				break
		}
	}
}

export default [inventoryWatcher()]
