/* eslint-disable quotes */
import type { Localization } from './typing'

export const pa: Localization = {
	components: {
		MuiBreadcrumbs: {
			defaultProps: {
				expandText: 'रास्ता दिखायें'
			}
		},
		MuiTablePagination: {
			defaultProps: {
				getItemAriaLabel: type => {
					if (type === 'first') {
						return 'पहले पृष्ठ पर जाएँ'
					}
					if (type === 'last') {
						return 'अंतिम पृष्ठ पर जाएँ'
					}
					if (type === 'next') {
						return 'अगले पृष्ठ पर जाएँ'
					}
					// if (type === 'previous') {
					return 'पिछले पृष्ठ पर जाएँ'
				},
				labelRowsPerPage: 'पंक्तियाँ प्रति पृष्ठ:',
				labelDisplayedRows: ({ from, to, count }) =>
					`${from}-${to === -1 ? count : to} कुल ${count} में`
			}
		},
		MuiRating: {
			defaultProps: {
				getLabelText: value => `${value} तार${value !== 1 ? 'े' : 'ा'}`,
				emptyLabelText: 'रिक्त'
			}
		},
		MuiAutocomplete: {
			defaultProps: {
				clearText: 'हटायें',
				closeText: 'बंद करें',
				loadingText: 'लोड हो रहा है…',
				noOptionsText: 'कोई विकल्प नहीं',
				openText: 'खोलें'
			}
		},
		MuiAlert: {
			defaultProps: {
				closeText: 'बंद करें'
			}
		},
		MuiPagination: {
			defaultProps: {
				'aria-label': 'पृस्ठानुसार संचालन',
				getItemAriaLabel: (type, page, selected) => {
					if (type === 'page') {
						return `पृष्ठ ${page} ${selected ? '' : ' पर जाएँ'}`
					}
					if (type === 'first') {
						return 'पहले पृष्ठ पर जाएँ'
					}
					if (type === 'last') {
						return 'अंतिम पृष्ठ पर जाएँ'
					}
					if (type === 'next') {
						return 'अगले पृष्ठ पर जाएँ'
					}
					// if (type === 'previous') {
					return 'पिछले पृष्ठ पर जाएँ'
				}
			}
		}
	},
	other: {
		home: 'Home',
		orderFinancing: 'Ordr Finance',
		ordercenter: 'Order Center',
		orderbookers: 'Order Booker',
		contacts: 'Contacts',
		inventory: 'Inventory',
		quotation: 'Quotations',
		invoices: 'Invoices',
		help: 'Help',
		changelog: 'Changelog',
		analytics: 'Analytics',
		creditbook: 'Creditbook',
		logout: 'Logout',
		menu: 'Menu',
		phoneNumber: 'Phone number',
		orderForm: 'Order Form',
		userPermissions: 'Users and Permissions',
		settings: 'Settings',
		whatsNew: 'What’s New',
		loadSheet: 'Load Sheet',
		userRoles: 'User and Roles',

		// app
		'app.button.continue': 'Continue',
		'app.button.back': 'Back',
		'app.notifications.markAsRead': 'Mark All As Read',
		'app.notifications.notifications': 'Notifications',
		'app.notifications.noNewNotifications': 'No new Notifications',
		'app.signOut': 'Sign out',

		// field error messages
		'fieldErrorMessages.required': 'Required',

		// components
		// molecules
		'molecules.imageCrop.editPhoto': 'Edit photo',
		'molecules.imageCrop.uploadPhototypeSize':
			"Upload a photo that's a minimum resolution 320px by 400px .JPEG, .JPG or .PNG",
		'molecules.imageCrop.dragPhoto': 'Drag to reposition photo',
		'molecules.imageCrop.cancel': 'Cancel',
		'molecules.imageCrop.save': 'Save',

		// atoms
		'atoms.searchBar.search': 'Search',

		// snackbar
		'snackbar.successCreate': 'Successfully Created',
		'snackbar.successUpdate': 'Successfully Updated',
		'snackbar.successDelete': 'Successfully Deleted',
		'snackbar.created': 'Created',
		'snackbar.updated': 'Updated',
		'snackbar.deleted': 'Deleted',

		// Add User
		'addUser.addUserButton': 'Add User',
		'addUser.title': 'Enter phone number for the user you want to add',

		// ONBOARDING
		'onboarding.enterCode': 'Enter the code that has been sent to {mobNo}',
		'onboarding.phoneVerification': 'Phone Number Verification',
		'onboarding.didnotGetCode': 'Didn’t get the code?',
		'onboarding.resend': 'Resend',
		'onboarding.tryAgain': 'you can try again in ',

		'onboarding.form.yourName': 'What is your full name?',
		'onboarding.form.yourName.placeholder': 'Enter Name',
		'onboarding.form.businessName': 'What is the name of your business?',
		'onboarding.form.businessName.placeholder': 'Enter Business Name',
		'onboarding.form.businessType': 'What is the type of your business?',
		'onboarding.form.businessType.placeholder': 'Enter Business Type',
		'onboarding.form.supplyChainPosition':
			'What is your role in a Supply Chain?',
		'onboarding.form.settingUpWebApp': 'Setting up your Webapp',
		'onboarding.form.almostDone': 'Almost done!',

		'onboarding.login.welcomeTo': 'Welcome to',
		'onboarding.login.creditBookWebApp': 'CreditBook Webapp',
		'onboarding.login.loginWithPhone':
			'Log in with your phone number to continue',
		'onboarding.login.loginButton': 'Login',
		'onboarding.login.manageBusiness':
			'The easiest way to manage your business!',

		'onboarding.signup.webappReady': 'Your CreditBook WebApp is ready',
		'onboarding.signup.customizedRequirements':
			'With the help of your details, we have customized the WebApp for you to match your business requirements!',
		'onboarding.signup.startUsingWebApp': 'Start using your WebApp',
		'onboarding.snackbar.otpVerification': 'Otp successfully send to',
		'onboarding.signup.tutorial': 'Play WebApp Tutorial',
		'onboarding.form.submit': 'submit',
		'onboarding.form.continue': 'continue',

		// CONTACTS
		'contacts.newContact': 'Create new Contacts',
		'contacts.toReceive': 'Receivables',
		'contacts.toPay': 'Payables',
		'contacts.form.placeholder.name': 'Contact Name*',
		'contacts.form.placeholder.businessName': 'Business Name',
		'contacts.form.placeholder.mobileNumber': 'Mobile Number',
		'contacts.form.placeholder.emailAddress': 'Email Address',
		'contacts.form.placeholder.mailAddress': 'Location',
		'contacts.form.placeholder.comments': 'Comments',
		'contacts.form.businessRelationShip': 'Business Relationship*',
		'contacts.form.bankingInformation.addBank': 'Add Bank',
		'contacts.form.bankingInformation.placeholder.accNumber':
			'Account Number or IBAN*',
		'contacts.form.bankingInformation.placeholder.bankName': 'Bank Name*',
		'contacts.form.bankingInformation.placeholder.accTitle':
			'Account Title*',
		'contacts.form.bankingInformation.placeholder.branch': 'Branch*',
		'contacts.form.openingBalance.placeholder.balance': 'Opening Balance',
		'contacts.form.openingBalance.credit': 'You have to pay',
		'contacts.form.openingBalance.debit': 'has to pay you',
		'contacts.form.viewContact': 'View Contact',
		'contacts.form.addContact': 'Add a new Contact',
		'contacts.form.saveContact': 'Save Contact',
		'contacts.table.name': 'Name',
		'contacts.table.phone': 'Phone',
		'contacts.table.type': 'Type',
		'contacts.table.amount': 'Amount',
		'contacts.table.noContactsAdded': 'You have added no contacts yet',
		'contacts.table.noContactsFound': 'No contacts found for',
		'contacts.ledger.footer.help': 'Help',
		'contacts.ledger.header.viewContactDetails': 'View Contact Details',
		'contacts.ledger.chat.addEntry': 'Add Entry',
		'contacts.ledger.chat.payment.recordPayment': 'Record a Payment',
		'contacts.ledger.chat.payment.paymentMethod': 'Payment Method',
		'contacts.ledger.chat.payment.financialTransactionsMessage':
			'This feature does perform financial transactions',
		'contacts.ledger.chat.payment.cancel': 'Cancel',
		'contacts.ledger.chat.payment.done': 'Done',
		'contacts.ledger.chat.addEntry.Invoice': 'Invoice',
		'contacts.ledger.chat.addEntry.Quotation': 'Quotation',
		'contacts.ledger.chat.addEntry.Payment': 'Payment',

		// Order Center
		'orderCenter.welcome': 'Khushamdeed {name}',
		'orderCenter.welcome.welcomeMessage':
			'Welcome to your Order Center. You can view all of your orders and customer inquiries here',
		'orderCenter.pageName': 'Order Center',
		'orderCenter.recordNewInquiryOrder': 'Record New Order/Inquiry',
		'orderCenter.recordOrder': 'Record new Order recieved from Customer',
		'orderCenter.recordInquiry':
			'Record new Inquiry recieved from Customer',
		'orderCenter.Inquiry': 'Inquiry',
		'orderCenter.Order': 'Order',
		'orderCenter.contactDetails': 'Contact details',
		'orderCenter.inquiryID': 'Inquiry ID',
		'orderCenter.selectContact': 'Select a Contact',
		'orderCenter.inquiryDate': 'Inquiry Date',
		'orderCenter.attachDocument': 'Attach document',
		'orderCenter.receivingChannel': 'Select Receiving Channel',
		'orderCenter.Inventory.SelectItems': 'Select items',
		'orderCenter.Inventory.AddItem': 'Add a new Item',

		// INVENTORY
		'inventory.pageName.inventory': 'Inventory',
		'inventory.pageName.searchInventory': 'Search Inventory',
		'inventory.stock.addNewStock': 'Add a New Stock Item',
		'inventory.imagePlaceHolder.dragDropFile':
			'Drag and drop a file here or',
		'inventory.imagePlaceHolder.browse': 'browse',
		'inventory.units.units': 'Units',
		'inventory.units.addUnit': 'Add another unit',
		'inventory.addToInventory': 'Add to Inventory',

		// PROFILE
		'profile.personalInformation.saveProfile': 'Save Profile',
		'profile.personalInformation.title': 'Personal Information',
		'profile.BusinessManagement.title': 'Business Management',
		'profile.BusinessManagement.addNewBusinessButton': 'Add New Business',
		'profile.BusinessManagement.viewBusiness': 'View',
		'profile.BusinessManagement.addNewBusiness': 'Add a New Business',
		'profile.BusinessManagement.saveBusiness': 'Save Business',
		'profile.profile': 'Profile',

		// Setting
		privacy: 'Privacy',
		taxes: 'Taxes',
		invoice: 'Invoices'
	}
}
