import React from 'react'
import { TableCell, Typography } from '@mui/material'
import { styled } from '@mui/system'
import type { InvoiceType } from 'typings'
import { convertNumber, currencyInput, formatId, truncate } from 'helpers'
import { FlexCol, FlexRow } from 'components/atoms/Flex'
import moment from 'moment'
import {
	GENERATE_INVOICE_DATA,
	calculateInvoiceTotalAmount,
	dateFormat
} from 'data'
import { Abbr, Capsule, CheckBox, Gutter } from 'components'
import { useFormikContext } from 'formik'
import type { LoadsheetType } from 'typings/loadsheet'

const Cell = styled(TableCell)`
	color: ${({ theme }) => theme.palette.colors.gray['500']};
	padding: 7px 7px 7px 24px;
	vertical-align: center;
`

const Text = styled(Typography)`
	color: #1c1c1c;
	font-weight: 500;
`

const Caption = styled(Typography)`
	color: ${({ theme }) => theme.palette.colors['gray']['500']}
	font-weight: 500;
`

const CheckBoxCell = styled(TableCell)`
	color: ${({ theme }) => theme.palette.colors.gray['500']};
	padding: 7px 7px 7px 24px;
	width: 100%;
	max-width: 40px;
	overflow: hidden;
`

const StyledCapsule = styled(Capsule)`
	padding: 3px;
	width: 100px;
	margin: 0px;
`

type LoadItemProps = {
	onRowClick?(inv: InvoiceType): void
	invoice: InvoiceType
} & InvoiceType

export const AllInvoiceItem: React.ComponentType<LoadItemProps> = ({
	friendlyId,
	contact,
	createdAt,
	orderBooker,
	id,
	hasLoadSheet,
	invoice,
	...props
}) => {
	const { values } = useFormikContext<LoadsheetType>()
	const quantity = props.items.reduce((acc, invoiceItem) => {
		return acc + parseInt(`${invoiceItem.quantity || 0}`)
	}, 0)

	return (
		<>
			<CheckBoxCell variant="head" align={'left'} width={50}>
				<CheckBox
					checked={
						values.invoiceItems.findIndex(itm => itm.id === id) !==
						-1
					}
				/>
			</CheckBoxCell>
			<Cell variant="head" align={'left'}>
				<Text variant={'subtitle2'} fontWeight={500}>
					{contact.name}
				</Text>
				<Caption variant={'caption'}>{contact.address}</Caption>
			</Cell>
			<Cell variant="head" align={'left'}>
				<StyledCapsule
					name={
						`INV-${formatId(friendlyId)}` +
						`${hasLoadSheet ? '*' : ''}`
					}
					value={`INV-${formatId(friendlyId)}`}
				/>
				{orderBooker?.name && (
					<Caption variant={'caption'}>
						{orderBooker?.name
							? `Booker: ${truncate(orderBooker?.name, 50)}`
							: ''}
					</Caption>
				)}
			</Cell>
			<Cell variant="head" align={'left'} sx={{ paddingRight: '24px' }}>
				{/* <Text variant={'subtitle2'} fontWeight={500}>
					{currencyInput(`${props?.totalCostAmount}`)}
				</Text> */}
				<Abbr
					title={(
						convertNumber(props.dueAmount) ||
						currencyInput(
							`${convertNumber(
								calculateInvoiceTotalAmount(
									GENERATE_INVOICE_DATA(invoice)
								)
							)}`
						)
					).toString()}
					length={11}
				/>
			</Cell>
			<Cell variant="head" align={'right'} sx={{ paddingRight: '24px' }}>
				<FlexRow justify={'flex-start'}>
					<FlexCol>
						<Caption variant={'subtitle2'} fontWeight={500}>
							{`Qty:`}
						</Caption>
						<Caption variant={'subtitle2'} fontWeight={500}>
							{`Items:`}
						</Caption>
					</FlexCol>
					<Gutter gap={0.5} />
					<FlexCol>
						<Text variant={'subtitle2'}>{quantity}</Text>
						<Text variant={'subtitle2'} fontWeight={500}>
							{props.items.length}
						</Text>
					</FlexCol>
				</FlexRow>
			</Cell>
			<Cell variant="head" align={'right'} sx={{ paddingRight: '24px' }}>
				<Text variant={'subtitle2'} fontWeight={500}>
					{moment(createdAt).format(dateFormat)}
				</Text>
			</Cell>
		</>
	)
}
