import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
	styled
} from '@mui/material'
import { sortLoadSheetInvoices } from 'data/loadsheetList'
import React from 'react'
import type { LoadsheetType } from 'typings/loadsheet'

const GutterVertical = styled('div')`
	margin: 3px 0px;
`

type StockTableProps = {
	loadSheet: LoadsheetType
}

export const StockTable: React.ComponentType<StockTableProps> = ({
	loadSheet
}) => {
	const stocks = sortLoadSheetInvoices(loadSheet)

	return (
		<>
			{loadSheet?.invoiceItems.length > 0 && (
				<>
					<div style={{ margin: '15px 0px' }} />
					<Typography variant="body1" fontWeight={'600'}>
						Stock
					</Typography>
					<GutterVertical />
					<TableContainer>
						<Table
							sx={{ minWidth: 650 }}
							size="small"
							aria-label="a dense table"
						>
							<TableHead>
								<TableRow>
									<TableCell
										style={{
											border: '1px solid black',
											backgroundColor: '#F2F4F7',
											fontSize: '13px',
											fontWeight: '600'
										}}
									>
										ITEM
									</TableCell>
									<TableCell
										style={{
											border: '1px solid black',
											borderRight: 'none',
											backgroundColor: '#F2F4F7',
											fontSize: '13px',
											fontWeight: '600'
										}}
									>
										QUANTITY
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{stocks.map((row: any) => (
									<TableRow key={row.name}>
										<TableCell
											component="th"
											scope="row"
											sx={{
												borderBottom:
													'1px solid black !important'
											}}
										>
											{row.name}
										</TableCell>
										<TableCell
											sx={{
												borderBottom:
													'1px solid black !important'
											}}
										>
											{`${row.quantity.toLocaleString()} ${
												row.unit
											}`}
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				</>
			)}
		</>
	)
}
