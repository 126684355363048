import React, { type Dispatch, type SetStateAction, useState } from 'react'
import { styled } from '@mui/system'
import { Typography, useTheme } from '@mui/material'
import { FlexCol, FlexRow } from 'components/atoms/Flex'
import InfoOutlined from '@mui/icons-material/InfoOutlined'
import { Button, Gutter } from 'components'
import type {
	ColorsCodeType,
	InvoiceStatus,
	InvoiceType,
	PaymentStatus,
	TransactionPaymentType
} from 'typings'
import {
	CheckCircleOutline,
	RateReviewOutlined,
	WarningAmber
} from '@mui/icons-material'
import { RecordPaymentModal } from 'components/app/Payments/RecordPaymentModal'
import { getPaymentByInvoiceId } from 'api/transaction'
import { useAppErrors } from 'hooks'
import { useNavigate } from 'react-router-dom'
import { LAYOUT_BREAKPOINT_WIDTH } from 'data'
import { TransparentButton } from 'components/atoms/Button/TransparentButton'
import { getSignedUrlForInvoicePdf } from 'api/invoice'
import { useSelector } from 'react-redux'
import type { RootState } from 'store/index-reducer'

const Wrapper = styled(FlexRow)`
	width: 100%;
	padding: 12px 24px;
	border-radius: 6px;
	border: 1px solid ${({ theme }) => theme.palette.colors.gray['300']};
	justify-content: space-between;
	align-items: center;

	@media (max-width: ${LAYOUT_BREAKPOINT_WIDTH}px) {
		padding: 8px;
	}
`

const PublishWrapper = styled(FlexRow)`
	align-items: center;
	margin-bottom: 6px;

	@media (max-width: ${LAYOUT_BREAKPOINT_WIDTH}px) {
		margin-bottom: 2px;
	}
`

const InfoIcon = styled(InfoOutlined)`
	font-size: 20px;
	color: ${({ theme }) => theme.palette.colors.gray['600']};
`

const PublishHeading = styled(Typography)<{ color?: string }>`
	font-weight: 700;
	color: ${({ theme, color }) =>
		color
			? theme.palette.colors[color]['600']
			: theme.palette.colors.gray['700']};
`

const PublishText = styled(Typography)`
	font-size: 12px;
	color: ${({ theme }) => theme.palette.colors.gray['600']};
`

const ActionText = styled(Typography)`
	color: ${({ theme }) => theme.palette.colors.gray['900']};
`

const WarningIcon = styled(WarningAmber)`
	font-size: 20px;
	color: ${({ theme }) => theme.palette.colors.red['600']};
`

const PendingIcon = styled(RateReviewOutlined)`
	font-size: 20px;
`

const PaidIcon = styled(CheckCircleOutline)`
	font-size: 20px;
	color: ${({ theme }) => theme.palette.colors.green['600']};
`

const StyledButton = styled(Button)`
	@media (max-width: ${LAYOUT_BREAKPOINT_WIDTH}px) {
		width: 100%;
		margin-top: 6px;
	}
`

const StatusLookup: Record<
	InvoiceStatus | PaymentStatus,
	{ heading: string; description: string; icon: any }
> = {
	draft: {
		heading: 'Ready to publish?',
		description:
			'Publishing will add this invoice to your ledger and allow you to record payments towards it.',
		icon: <InfoIcon />
	},
	publish: {
		heading: 'Invoice Published',
		description:
			'This invoice has been published and is visible in your ledger.',
		icon: <InfoIcon />
	},
	void: {
		heading: 'Invoice has been marked as void.',
		description:
			'This invoice is not reflected in your ledger with this customer.',
		icon: <WarningIcon />
	},
	pending: {
		heading: 'Record payment for the invoice',
		description:
			'If you’ve received a partial or full payment from your customer towards this invoice, you can record it.',
		icon: <PendingIcon />
	},
	partial: {
		heading: 'Record payment for the invoice',
		description:
			'If you’ve received a partial or full payment from your customer towards this invoice, you can record it.',
		icon: <PendingIcon />
	},
	paid: {
		heading: 'Invoice has been paid!',
		description: 'Congratulations, you’ve been paid for this invoice.',
		icon: <PaidIcon />
	}
}

type InvoiceHeaderProps = {
	amountDue: number
	currentInvoice: InvoiceType
	publishInvoice: () => void
	setPayments: Dispatch<SetStateAction<TransactionPaymentType[]>>
}

export const InvoiceHeader: React.ComponentType<InvoiceHeaderProps> = ({
	amountDue,
	currentInvoice,
	publishInvoice,
	setPayments
}) => {
	const [disabled, setDisabled] = useState(false)
	const [recordPaymentModal, setRecordPaymentModal] = useState(false)
	const theme = useTheme()
	const { colors } = theme.palette
	const { setAppError } = useAppErrors()
	const { user } = useSelector((state: RootState) => state.user)
	const navigate = useNavigate()

	const { status: invoiceStatus, paymentStatus } = currentInvoice

	const statusObj =
		StatusLookup[
			invoiceStatus === 'publish' ? paymentStatus : invoiceStatus
		]

	const onRecordPaymentSuccess = async () => {
		try {
			const res = await getPaymentByInvoiceId(currentInvoice.id)
			setPayments(res)
			navigate(`/invoice/view/${currentInvoice.id}`, {
				replace: true
			})
		} catch (e: any) {
			setAppError(e)
		}
	}

	const payUsingOrdr = async () => {
		try {
			setDisabled(true)
			const data = {
				...currentInvoice,
				businessInfo: {
					...currentInvoice.businessInfo,
					email: user?.email || '',
					phone: user?.phone || { countryCode: '', number: '' }
				}
			}
			const res: any = await getSignedUrlForInvoicePdf([data], true)
			navigate('/ordr-financing/new-finance-application', {
				state: {
					initialState: {
						invoiceDoc: [res.data.signedUrl],
						loanAmount: amountDue,
						days: 0
					}
				}
			})
		} catch (e) {
			//
		} finally {
			setDisabled(false)
		}
	}

	return (
		<>
			{statusObj && (
				<Wrapper>
					<FlexCol>
						<PublishWrapper>
							{statusObj?.icon}
							<Gutter gap={0.4} />
							<PublishHeading
								variant="body2"
								color={
									invoiceStatus === 'void'
										? 'red'
										: paymentStatus === 'paid'
										? 'green'
										: undefined
								}
							>
								{statusObj?.heading}
							</PublishHeading>
						</PublishWrapper>
						<PublishText variant="caption">
							{statusObj?.description}
						</PublishText>
					</FlexCol>

					{invoiceStatus === 'draft' && (
						<StyledButton
							textColor={colors.gray['900'] as ColorsCodeType}
							mode={'primary'}
							buttonColor={colors.yellow['300'] as ColorsCodeType}
							onClick={publishInvoice}
						>
							<ActionText
								variant={'caption'}
								fontWeight={'medium'}
							>
								Publish
							</ActionText>
						</StyledButton>
					)}
					{invoiceStatus === 'publish' &&
						paymentStatus !== 'paid' && (
							<TransparentButton
								onClick={payUsingOrdr}
								disabled={disabled}
							>
								<ActionText
									variant={'caption'}
									fontWeight={'medium'}
								>
									Pay using ordr finance
								</ActionText>
							</TransparentButton>
						)}

					{invoiceStatus === 'publish' &&
						paymentStatus !== 'paid' && (
							<StyledButton
								textColor={colors.gray['900'] as ColorsCodeType}
								mode={'primary'}
								buttonColor={
									colors.yellow['300'] as ColorsCodeType
								}
								onClick={() => setRecordPaymentModal(true)}
								disabled={disabled}
							>
								<ActionText
									variant={'caption'}
									fontWeight={'medium'}
								>
									Record Payment
								</ActionText>
							</StyledButton>
						)}
				</Wrapper>
			)}
			<RecordPaymentModal
				currentInvoice={currentInvoice}
				amountDue={amountDue}
				open={recordPaymentModal}
				onClose={() => setRecordPaymentModal(false)}
				onSuccess={onRecordPaymentSuccess}
			/>
		</>
	)
}
