import React, { useCallback } from 'react'
import { FlexRow } from 'components/atoms/Flex'
import { Capsule, Gutter } from 'components'
import { styled } from '@mui/system'
import type { SocialChannelProps } from 'data'
import { SEGMENT_EVENTS, SOCIAL_CHANNEL } from 'data'
import { Typography } from '@mui/material'
import { useField } from 'formik'
import analytics from 'utils/segment'

const StyledCapsule = styled(Capsule)`
	padding: 5px 10px;
	margin: 0 10px 10px 0;
	font-size: 14px;
`

type Props = {
	name: string
}

export const SocialChannel: React.ComponentType<Props> = ({ name }) => {
	const [, meta, helpers] = useField(name)

	const RenderCapsule = useCallback(
		({ channel }: { channel: SocialChannelProps }) => {
			return (
				<StyledCapsule
					isSelected={meta.value === channel.value}
					onItemClick={value => {
						helpers.setValue(value)
						analytics.track(
							SEGMENT_EVENTS.ORDER_RECEIVING_CHANNEL,
							{
								channel_selected: value
							}
						)
					}}
					{...channel}
				/>
			)
		},
		[helpers, meta.value]
	)

	return (
		<>
			<Typography variant={'body2'} fontWeight={500}>
				Select Receiving Channel
			</Typography>
			<Gutter spacing={0.4} />
			<FlexRow wrap={'wrap'} align={'center'}>
				{SOCIAL_CHANNEL.map(channel => (
					<RenderCapsule key={channel.id} channel={channel} />
				))}
			</FlexRow>
		</>
	)
}
