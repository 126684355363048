import React, { useCallback } from 'react'
import { FlexCol, FlexRow } from 'components/atoms/Flex'
import {
	Button,
	FieldImageDropZone,
	FieldTextInput,
	Gutter,
	ImagePlaceholder
} from 'components'
import { styled, Typography } from '@mui/material'
import { Formik } from 'formik'
import * as yup from 'yup'
import { BiUser } from 'react-icons/bi'
import { IoIosClose } from 'react-icons/io'
import { useTranslate } from 'hooks/translate'
import { MultiSelect } from './MultiSelect'
import {
	getAllBusiness,
	postBusiness,
	updateBusiness,
	uploadBusinessPic
} from 'api'
import { useAppErrors } from 'hooks/useAppErrors'
import { fetchAllBusinessSuccess } from 'store/business/actions'
import { useDispatch, useSelector } from 'react-redux'
import { useSnackbar } from 'notistack'
import type { RootState } from 'store/index-reducer'
import type { IBusiness } from 'typings'
import { SEGMENT_EVENTS, SUPPLY_CHAIN_POSITION } from 'data'
import analytics from 'utils/segment'

export const formWidth = 90
export const formHeight = 60

const FormLayout = styled(FlexCol)`
	position: relative;
	width: ${formWidth}ch;
	height: ${formHeight}ch;
	color: ${({ theme }) => theme.palette.colors.white['900']};
	padding: 2.5rem;
`

const Row = styled(FlexRow)`
	position: absolute;
	justify-content: flex-end;
	width: 100%;
	right: 3rem;
	bottom: 3rem;
`

const CloseButtonWrapper = styled(FlexCol)`
	justify-content: center;
	align-items: center;
	height: 30px;
	width: 30px;
	position: absolute;
	right: 2.5rem;
	&:hover {
		cursor: pointer;
		border-radius: 25%;
		background-color: ${({ theme }) => theme.palette.colors.gray['100']};
		display: flex;
	}
`

const initialState: IBusiness = {
	profilePic: '',
	businessType: '',
	name: '',
	supplyChainRole: [],
	address: ''
}

const validationSchema = yup.object().shape({
	name: yup.string().trim().required('Required'),
	businessType: yup.string().trim().required('Required'),
	supplyChainRole: yup
		.array()
		.min(1, 'Supply Chain Role Required')
		.required('Supply Chain Role Required'),
	address: yup
		.string()
		.trim()
		.test(
			'len',
			'Address length should be between 10 and 80 characters',
			val => {
				// count only non-white space characters
				const len = (val || '').replace(/\s+/g, '').length
				return len >= 10 && len <= 80
			}
		)
		.required('This is a mandatory field and cannot be left empty')
})

type BusinessFromprops = {
	handleCloseForm(): void
	businessId?: string
}

export const FormWrapper: React.ComponentType<BusinessFromprops> = ({
	handleCloseForm,
	businessId
}) => {
	const translate = useTranslate()
	const { setAppError } = useAppErrors()
	const dispatch = useDispatch()
	const { enqueueSnackbar } = useSnackbar()
	const { businesses } = useSelector((state: RootState) => state.business)

	const getInitialValue = () => {
		return businessId
			? businesses.filter(dx => dx.id === businessId)[0]
			: initialState
	}

	const business = businessId ? { business: businessId } : {}

	const onSubmit = useCallback(
		async (values: IBusiness, actions: any, businessId?: string) => {
			try {
				actions.setSubmitting(true)
				if (businessId) {
					await updateBusiness(values, businessId)
				} else {
					await postBusiness(values as any)
				}
				enqueueSnackbar(
					`Business successfully ${businessId ? 'update' : 'added'}`,
					{
						variant: 'success'
					}
				)
				const res = await getAllBusiness()
				dispatch(fetchAllBusinessSuccess(res))

				handleCloseForm()
			} catch (error: any) {
				setAppError(error, actions)
			} finally {
				actions.setSubmitting(false)
			}
		},
		[enqueueSnackbar, dispatch, setAppError, handleCloseForm]
	)

	return (
		<Formik
			initialValues={getInitialValue()}
			onSubmit={(values, actions) =>
				onSubmit(values, actions, businessId)
			}
			validationSchema={validationSchema}
		>
			{({ handleSubmit, dirty, isSubmitting, values }) => (
				<FormLayout>
					<CloseButtonWrapper>
						<IoIosClose
							size={25}
							color="#000000"
							onClick={() => handleCloseForm()}
						/>
					</CloseButtonWrapper>
					<Typography color="black">
						{translate('profile.BusinessManagement.addNewBusiness')}
					</Typography>
					<Gutter spacing={2} />
					<FlexCol justify="center" align="center">
						<FieldImageDropZone
							name="profilePic"
							placeholder={ImagePlaceholder}
							icon={BiUser}
							text={values['name']}
							api={uploadBusinessPic}
							payload={{ businessId: businessId }}
							onClick={() =>
								analytics.track(
									SEGMENT_EVENTS.BUSINESS_PICTURE_UPLOAD_CLICKED,
									business
								)
							}
						/>
					</FlexCol>
					<Gutter spacing={2} />
					<FlexRow>
						<FieldTextInput
							name="name"
							title="Business Name"
							placeholder="Enter Business Name"
							sx={{ width: '35ch' }}
							size="small"
						/>
						<Gutter gap={1} />
						<FieldTextInput
							name="businessType"
							title="Business Type"
							placeholder="Enter Business Type"
							sx={{ width: '30ch' }}
							size="small"
						/>
					</FlexRow>
					<Gutter />
					<MultiSelect
						name="supplyChainRole"
						sx={{ width: '35ch' }}
						placeholder="Select"
						dataSource={SUPPLY_CHAIN_POSITION}
						size="small"
					/>
					<Gutter />
					<FieldTextInput
						name="address"
						title="Address"
						placeholder="Enter Address"
						sx={{ width: '35ch' }}
						//size="medium"
						multiline
						maxRows={2}
						rows={2}
					/>
					<Gutter spacing={3} />
					<Row>
						<Button
							onClick={() => handleSubmit()}
							variant={'contained'}
							disabled={!dirty || isSubmitting}
							buttonColor="#FEC84B"
							textColor="#101828"
						>
							{translate(
								'profile.BusinessManagement.saveBusiness'
							)}
						</Button>
					</Row>
				</FormLayout>
			)}
		</Formik>
	)
}
