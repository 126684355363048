import React from 'react'
import { TableCell, Typography, useTheme } from '@mui/material'
import { styled } from '@mui/system'
import type { ColorsType, InvoiceType } from 'typings'
import { currencyInput, formatId, truncate } from 'helpers'
import moment from 'moment'
import {
	dateFormat,
	INVOICE_STATUS_OPTIONS,
	PAYMENT_STATUS_OPTIONS
} from 'data'
import { FieldCurrencyInput, Gutter, type StatusOptionProps } from 'components'
import { FlexCol, FlexRow } from 'components/atoms/Flex'
import { useFormikContext } from 'formik'
import { TbCircleCheck } from 'react-icons/tb'

const Cell = styled(TableCell)`
	color: ${({ theme }) => theme.palette.colors.gray['500']};
	padding: 7px 7px 7px 24px;
	border-right: 1px solid ${({ theme }) => theme.palette.colors.gray['200']};
`

const Text = styled(Typography)`
	color: ${({ theme }) => theme.palette.colors.black['500']};
	white-space: nowrap;
`

const StyledButton = styled(FlexRow, {
	shouldForwardProp: props => props !== 'color'
})<Pick<StatusOptionProps, 'color'>>`
	align-items: center;
	background-color: ${({ theme, color }) => theme.palette.colors[color][50]};
	border-radius: 30px;
	padding: 2px 8px;
	width: fit-content;
	white-space: nowrap;
	margin: 2px 0;

	:hover {
		cursor: not-allowed;
	}
`

const CheckIcon = styled(TbCircleCheck)`
	font-weight: 600;
	color: ${({ theme }) => theme.palette.colors['green'][800]};
`

const Status = styled(Typography)`
	font-weight: 600;
	align-items: center;
`

type BulkPaymentItem = {
	invoice: InvoiceType
	index: number
}

export const BulkPaymentItem: React.ComponentType<BulkPaymentItem> = ({
	invoice,
	index
}) => {
	const { values } = useFormikContext<any>()
	const { paymentTerms } = invoice
	const theme = useTheme()
	const {
		palette: { colors }
	} = theme

	const amountDue = parseFloat(
		`${parseFloat(`${invoice?.amountDue || 0}`).toFixed(2)}`
	)
	const amountPaid = parseFloat(
		`${parseFloat(`${invoice.bulkAmount || 0}`).toFixed(2)}`
	)

	const isChanged = amountDue - amountPaid === 0

	const txt = amountPaid ? (
		isChanged ? (
			<FlexRow
				align={'center'}
				style={{ flexWrap: 'nowrap', width: '100%' }}
			>
				<CheckIcon />
				<Gutter gap={0.25} />
				<Status variant={'subtitle2'} color={colors.green['600']}>
					Invoice paid
				</Status>
			</FlexRow>
		) : amountDue !== amountPaid ? (
			<FlexCol>
				<Typography variant={'subtitle2'}>
					PKR {currencyInput(`${amountDue - amountPaid}`)} due
				</Typography>
				<StyledButton color={'yellow'}>
					<Typography
						variant={'caption'}
						color={colors['yellow']['800']}
					>
						Partially Paid
					</Typography>
				</StyledButton>
			</FlexCol>
		) : (
			<Typography variant={'subtitle2'}>No change</Typography>
		)
	) : (
		<Typography variant={'subtitle2'}>No change</Typography>
	)

	let selectedOption

	if (invoice.status && invoice.status !== 'publish') {
		selectedOption = INVOICE_STATUS_OPTIONS.find(
			p => p.value === invoice.status
		)
	} else {
		selectedOption = PAYMENT_STATUS_OPTIONS.find(
			p => p.value === invoice.paymentStatus
		)
	}

	const message = paymentTerms.dueDate
		? moment(paymentTerms.dueDate).diff(moment(), 'days')
		: 0

	return (
		<>
			<Cell variant="head" align={'left'}>
				<Text variant={'subtitle2'}>{`INV-${formatId(
					invoice?.friendlyId
				)}`}</Text>
			</Cell>
			<Cell variant="head" align={'right'} sx={{ paddingRight: '24px' }}>
				<Text variant={'subtitle2'}>
					{invoice.orderBooker?.name || '-'}
				</Text>
			</Cell>
			<Cell variant="head" align={'right'} sx={{ paddingRight: '24px' }}>
				<Text variant={'subtitle2'}>
					{moment(invoice.createdAt).format(dateFormat)}
				</Text>
			</Cell>
			<Cell variant="head" align={'left'}>
				<Text variant={'subtitle2'}>
					{paymentTerms.dueDate
						? moment(paymentTerms.dueDate).format(dateFormat)
						: ''}
				</Text>
				{paymentTerms.dueDate ? (
					<Typography
						color={
							message < 0 ? colors.red['600'] : colors.gray['600']
						}
						variant={'caption'}
						fontWeight={message < 0 ? 500 : 400}
					>
						{message < 0
							? `Overdue by ${Math.abs(message)} days`
							: `Due in ${message} days`}
					</Typography>
				) : (
					''
				)}
			</Cell>
			<Cell variant="head" align={'left'} sx={{ paddingRight: '24px' }}>
				<Text variant={'subtitle2'}>{`PKR ${truncate(
					currencyInput(`${invoice.amountDue || 0}`),
					10
				)} due`}</Text>
				<StyledButton color={selectedOption?.color as ColorsType}>
					<Typography
						variant={'caption'}
						color={
							colors[selectedOption?.color as ColorsType]['500']
						}
					>
						{selectedOption?.label}
					</Typography>
				</StyledButton>
			</Cell>
			<Cell variant="head" align={'right'} sx={{ paddingRight: '24px' }}>
				{values.automatic ? (
					<Text variant={'subtitle2'}>
						{currencyInput(`${invoice?.bulkAmount || 0}`)}
					</Text>
				) : (
					<FieldCurrencyInput
						name={`invoices[${index}].bulkAmount`}
						size={'small'}
					/>
				)}
			</Cell>
			<Cell variant="head" align={'left'} sx={{ paddingRight: '24px' }}>
				<Text variant={'subtitle2'}>{txt}</Text>
			</Cell>
		</>
	)
}
