import React from 'react'
import { styled } from '@mui/system'
import { Box } from '@mui/material'

export const TutorialImage = styled('div')`
	background-color: ${({ theme }) => theme.palette.colors.white['500']};
	width: 340px;
	height: 200px;
	position: fixed;
	background-size: 100%;
	top: 70px;
	right: 80px;
	border-radius: 20px;
	box-shadow: 0 0 50px -15px ${({ theme }) => theme.palette.colors.green['400']};
	cursor: pointer;
	overflow: hidden;
`
export const StyledTutorialContent = styled(Box)`
	width: 650px;
	height: 400px;
	border-radius: 15px;
`
type modalContentProps = {
	children: React.ReactNode
}

export const TutorialContent: React.ComponentType<modalContentProps> = ({
	children
}) => {
	return <StyledTutorialContent>{children}</StyledTutorialContent>
}
