import React, { useState } from 'react'
import { InputAdornment, styled, Typography } from '@mui/material'
import { Formik } from 'formik'
import {
	Button,
	FieldNumberInput,
	FieldTextInput,
	Gutter,
	StyledModal
} from 'components'
import { FlexCol, FlexRow } from 'components/atoms/Flex'
import * as Yup from 'yup'
import { useAppErrors } from 'hooks/useAppErrors'
import { useSnackbar } from 'notistack'
import { IoIosClose } from 'react-icons/io'
import { ORDER_BOOKER_INITIAL_DATA } from 'data/orderbooker'
import type { IBookerProps } from 'typings/orderbooker'
import { useSettings } from 'context/settings'
import { createOrderBooker, updateOrderBooker } from 'api'

const validationSchema = Yup.object().shape({
	name: Yup.string()
		.required('Required')
		.max(100, 'Name must be less than 100 characters'),
	phone: Yup.object().shape({
		number: Yup.string()
			.required('Required')
			.matches(/^[3][0-4]\d{8}$/, 'Invalid Phone number')
	})
})

const Wrapper = styled(FlexCol)`
	width: 100%;
	height: 100%;
	max-width: 350px;
`

const Container = styled(FlexRow)`
	align-self: center;
	width: 440px;
	justify-self: center;
	padding: 40px;
	height: auto;
	background-color: ${({ theme }) => theme.palette.background.default};
	border-radius: 10px;
	justify-content: space-between;
`

const CloseButtonWrapper = styled(FlexCol)`
	justify-content: center;
	align-items: center;
	height: 30px;
	width: 30px;
	right: 1.75em;

	&:hover {
		cursor: pointer;
		border-radius: 25%;
		background-color: ${({ theme }) => theme.palette.colors.gray['100']};
		display: flex;
	}
`

type CreateOrderBookerModalProps = {
	open: boolean
	onClose(): void
	onSuccess(): void
	bookerToEdit?: IBookerProps
}

export const CreateOrderBookerModal: React.ComponentType<
	CreateOrderBookerModalProps
> = ({ open, onClose, onSuccess, bookerToEdit }) => {
	const [data] = useState<IBookerProps>(
		bookerToEdit ?? ORDER_BOOKER_INITIAL_DATA
	)
	const { setAppError } = useAppErrors()
	const { enqueueSnackbar } = useSnackbar()
	const { business } = useSettings()

	const onSubmit = async (values: IBookerProps, actions: any) => {
		try {
			actions.setSubmitting(true)
			const payload = { ...values, businessId: business }

			if (bookerToEdit && bookerToEdit?.id) {
				await updateOrderBooker(payload, bookerToEdit.id)
				enqueueSnackbar('Order Booker Updated Successfully', {
					variant: 'success'
				})
			} else {
				await createOrderBooker(payload)
				enqueueSnackbar('Order Booker Created Successfully', {
					variant: 'success'
				})
			}
			onClose()
			onSuccess?.()
		} catch (e: any) {
			setAppError(e, actions)
		} finally {
			actions.setSubmitting(false)
		}
	}

	return (
		<StyledModal
			onClose={onClose}
			aria-labelledby="customized-dialog-title"
			open={open}
		>
			<Container>
				<FlexRow
					align="center"
					justify="space-between"
					sx={{ width: '100%' }}
				>
					<Typography variant={'h6'} fontWeight={600}>
						{bookerToEdit ? 'Edit Booker' : 'Add new Booker'}
					</Typography>
					<CloseButtonWrapper>
						<IoIosClose size={25} onClick={onClose} />
					</CloseButtonWrapper>
				</FlexRow>
				<Gutter gap={1} />
				<Formik
					initialValues={data}
					validationSchema={validationSchema}
					onSubmit={(values, actions) => {
						onSubmit(values, actions)
					}}
				>
					{({ handleSubmit, ...actions }) => (
						<Wrapper>
							<FieldTextInput
								name={'name'}
								sx={{ marginTop: 1, width: '355px' }}
								size={'small'}
								placeholder={'Booker Name*'}
							/>
							<Gutter spacing={1} />
							<FieldNumberInput
								name={'phone.number'}
								size={'small'}
								placeholder={'Mobile Number'}
								sx={{ width: '355px' }}
								InputProps={{
									startAdornment: (
										<InputAdornment position="start">
											<Typography>{'+92 '}</Typography>
										</InputAdornment>
									)
								}}
								inputProps={{ maxLength: 10 }}
							/>

							<Gutter spacing={2} />
							<FlexRow
								justify={'flex-end'}
								align={'center'}
								style={{
									width: '100%'
								}}
							>
								<Button
									type="submit"
									disabled={
										actions.isSubmitting || !actions.dirty
									}
									onClick={() => {
										handleSubmit()
									}}
									style={{ padding: '11px 35px' }}
								>
									{bookerToEdit ? 'Done' : 'Save Booker'}
								</Button>
							</FlexRow>
						</Wrapper>
					)}
				</Formik>
			</Container>
		</StyledModal>
	)
}
