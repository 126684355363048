import React from 'react'
import {
	Button,
	DatePicker,
	FieldCurrencyInput,
	Gutter,
	Label,
	StyledModal
} from 'components'
import { type ModalProps, Tooltip, Typography, useTheme } from '@mui/material'
import type { IContactProps, InvoiceType } from 'typings'
import { styled } from '@mui/system'
import { FlexCol, FlexRow } from 'components/atoms/Flex'
import { IoIosClose } from 'react-icons/io'
import { BulkPaymentForm } from 'screen/Home/BulkPaymentModal/BulkPaymentForm'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import { Formik } from 'formik'
import {
	BulKPaymentInitialValue,
	BulkPaymentOption,
	dateFormat,
	PaymentMethodOptions,
	SEGMENT_EVENTS
} from 'data'
import { CustomRadioGroup } from 'components/atoms/RadioGroup'
import { objectOmit, pascalCase } from 'helpers'
import analytics from 'utils/segment'
import { CustomAutoComplete } from 'components/app/CutomAutoComplete'
import moment from 'moment'
import * as Yup from 'yup'
import { postBulkPayment } from 'api/invoice'
import { useSettings } from 'context/settings'
import { useSnackbar } from 'notistack'
import { useAppErrors } from 'hooks'

const Wrapper = styled(FlexCol)`
	background-color: ${({ theme }) => theme.palette.background.default};
	border-radius: 8px;
	min-width: 800px;
	flex-wrap: nowrap;
	width: 90%;
	min-height: 90%;
	max-width: 1300px;
`

const Header = styled(FlexRow)`
	padding: 16px;
	border-bottom: 1px solid ${({ theme }) => theme.palette.colors.gray['300']};
	align-items: center;
	width: 100%;
	justify-content: space-between;
`

const SubHeader = styled(FlexRow)`
	padding: 16px;
	border-bottom: 1px solid ${({ theme }) => theme.palette.colors.gray['300']};
	align-items: center;
	width: 100%;
	flex-wrap: nowrap;
	justify-content: space-between;
`

const Row = styled(FlexRow)`
	padding: 10px;
	width: 100%;
`

const StyledCapsule = styled(FlexCol)`
	background-color: ${({ theme }) => theme.palette.colors['gray']['50']};
	padding: 2px 8px;
	border-radius: 25px;
	margin: 5px;
	color: ${({ theme }) => theme.palette.colors['gray']['700']};
	align-items: center;
	justify-content: center;

	white-space: nowrap;

	:hover {
		cursor: pointer;
	}
`

const WrapperFieldRadioGroup = styled(CustomRadioGroup)(({ theme }) => ({
	'& .MuiSvgIcon-root': {
		fontSize: 15,
		color: theme.palette.colors.gray['300']
	},
	'& .MuiFormControlLabel-label': {
		fontSize: 12,
		color: theme.palette.colors.gray['500']
	},
	'& .MuiRadio-root': {
		'&.Mui-checked': {
			'& .MuiSvgIcon-root': {
				color: theme.palette.colors.green['600']
			}
		}
	}
}))

const Box = styled(FlexRow)`
	padding-left: 10px;
	border-left: 1px solid ${({ theme }) => theme.palette.colors.gray['300']};
`

const StyledRow = styled(FlexRow)`
	padding: 16px 16px 8px 16px;
	justify-content: space-between;
	width: 100%;
`

const CloseButtonWrapper = styled(FlexCol)`
	justify-content: center;
	align-items: center;
	height: 30px;
	width: 30px;
	right: 1.75em;
	&:hover {
		cursor: pointer;
		border-radius: 25%;
		background-color: ${({ theme }) => theme.palette.colors.gray['100']};
		display: flex;
	}
`

const Wrap = styled(FlexCol)`
	width: 100%;
	height: 100%;
	padding: 16px;
	flex-wrap: nowrap;
`

const validationSchema = Yup.object().shape({
	invoices: Yup.array()
		.min(1, 'Min 1 invoice is required')
		.of(
			Yup.object().shape({
				bulkAmount: Yup.number()
					.typeError('Number Required')
					.test('bulkAmount', (amount, values) => {
						const { parent } = values

						if (
							parent.amountDue > 0 &&
							(amount || 0) > parent.amountDue
						) {
							return values.createError({
								path: values.path,
								message: `Amount should not be greater than ${parent.amountDue}`
							})
						}

						return true
					})
			})
		),
	totalAmount: Yup.number()
		.typeError('Number Required')
		.test('totalAmount', (amount, values) => {
			const { parent } = values

			const total = parent.invoices.reduce(
				(accumulator: number, currentExpense: InvoiceType) => {
					return (
						accumulator + parseFloat(`${currentExpense.amountDue}`)
					)
				},
				0
			)

			if (parent.automatic && amount === 0) {
				return values.createError({
					path: values.path,
					message: 'Amount should be greater than 0'
				})
			}

			if (total < (amount || 0)) {
				return values.createError({
					path: values.path,
					message: `Amount should not be greater than ${total}`
				})
			}

			return true
		})
})

type BulkPaymentModalProps = Omit<ModalProps, 'children'> & {
	contact: IContactProps
	onSuccess?: () => void
}

export const BulkPaymentModal: React.ComponentType<BulkPaymentModalProps> = ({
	open,
	onClose,
	contact,
	onSuccess
}) => {
	const { business } = useSettings()
	const theme = useTheme()
	const { setAppError } = useAppErrors()
	const { enqueueSnackbar } = useSnackbar()

	const {
		palette: { colors }
	} = theme

	const onAmountDistribution = (values: any, setFieldValue: any) => {
		let amt = values.totalAmount
		const inv = values.invoices.map((m: InvoiceType) => {
			const finalObj = {
				...m,
				bulkAmount: amt < (m.amountDue || 0) ? amt : m.amountDue
			}
			amt -= amt < (m.amountDue || 0) ? amt : m.amountDue
			return finalObj
		})

		setFieldValue('invoices', inv)
	}

	const onSubmit = async (values: any, actions: any) => {
		try {
			actions.setSubmitting(true)
			let finalValue = {
				...objectOmit(values, 'invoices'),
				businessId: business,
				contactId: contact.id,
				payments: values.invoices
					.map((d: InvoiceType) => {
						return {
							invoiceId: d.id,
							amount: d.bulkAmount,
							paymentStatus:
								d.amountDue === d.bulkAmount
									? 'paid'
									: 'partial'
						}
					})
					.filter((m: any) => m.amount > 0)
			}

			if (finalValue.paymentMethod === '') {
				finalValue = objectOmit(finalValue, 'paymentMethod')
			}

			await postBulkPayment(finalValue)
			enqueueSnackbar('Payment successfully saved', {
				variant: 'success'
			})
			actions.resetForm({ values: BulKPaymentInitialValue })
			onSuccess?.()
			onClose?.({}, 'escapeKeyDown')
		} catch (e: any) {
			setAppError(e)
		} finally {
			actions.setSubmitting(false)
		}
	}

	return (
		<StyledModal open={open} onClose={onClose}>
			<Wrapper>
				<Header>
					<FlexRow>
						<Typography variant={'h6'} fontWeight={600}>
							Record Payments in Bulk
						</Typography>
						<Gutter gap={1} />
						<StyledCapsule>
							<Typography variant={'subtitle2'}>
								{contact.name}
							</Typography>
						</StyledCapsule>
					</FlexRow>
					<CloseButtonWrapper>
						<IoIosClose
							size={25}
							onClick={() => onClose?.({}, 'escapeKeyDown')}
						/>
					</CloseButtonWrapper>
				</Header>
				<Formik
					initialValues={BulKPaymentInitialValue}
					onSubmit={onSubmit}
					validationSchema={validationSchema}
				>
					{({
						values,
						setFieldValue,
						handleSubmit,
						isSubmitting,
						isValid
					}) => (
						<>
							<SubHeader>
								<FlexRow align={'center'}>
									<Typography
										variant={'caption'}
										fontWeight={500}
									>
										Payment Adjustment
									</Typography>
									<Gutter gap={0.5} />
									<Tooltip
										title="Automatic adjustment clears older invoices first, in manual you can clear individual invoices in any order."
										placement="top-start"
										arrow
									>
										<HelpOutlineIcon
											style={{
												color: colors.gray['400'],
												fontSize: 18
											}}
										/>
									</Tooltip>
									<Gutter gap={1} />
									<WrapperFieldRadioGroup
										row
										options={BulkPaymentOption}
										value={values.automatic}
										onChange={(
											event: React.ChangeEvent<HTMLInputElement>
										) => {
											const val = JSON.parse(
												`${event.target.value}`
											)

											setFieldValue(
												'invoices',
												values.invoices.map(
													(m: InvoiceType) =>
														objectOmit(
															m,
															'bulkAmount'
														)
												)
											)
											setFieldValue('totalAmount', '0')
											setFieldValue('automatic', val)
										}}
									/>
								</FlexRow>
								<Typography
									variant={'caption'}
								>{`Showing ${values.invoices.length} active invoices`}</Typography>
							</SubHeader>
							<StyledRow>
								<FlexRow>
									<FlexCol>
										<Label>Payment Date</Label>
										<DatePicker
											slotProps={{
												textField: {
													placeholder: 'Due Date',
													style: { borderRadius: 10 }
												}
											}}
											format={dateFormat}
											value={values.paymentDate}
											onChange={e => {
												setFieldValue(
													'paymentDate',
													moment
														.utc(e)
														.format()
														.toString()
												)
											}}
											maxDate={moment().add(3, 'years')}
										/>
									</FlexCol>
									<Gutter gap={1} />
									<FlexCol>
										<Label>Method (Optional)</Label>
										<CustomAutoComplete
											disableClearable={false}
											options={PaymentMethodOptions}
											renderOption={(props, option) => (
												<Row
													onClick={() => {
														setFieldValue(
															'paymentMethod',
															option.value
														)
														analytics.track(
															SEGMENT_EVENTS.INVOICE_ADD_PAYMENT_METHOD,
															{
																payment_method_selected:
																	option.value
															}
														)
													}}
												>
													{option.label}
												</Row>
											)}
											size={'small'}
											style={{
												minWidth: 200,
												width: '100%'
											}}
											value={pascalCase(
												values.paymentMethod ===
													'unpaid'
													? ''
													: values.paymentMethod || ''
											)}
											filterOptions={(i, p) => {
												return i.filter(r =>
													r.label
														.toLowerCase()
														.includes(
															(
																p.inputValue ||
																''
															).toLowerCase()
														)
												)
											}}
											placeholder={'Method'}
										/>
									</FlexCol>
								</FlexRow>
								{values.automatic && (
									<FlexCol align={'flex-end'}>
										<Label>Bulk Payment Amount</Label>
										<FieldCurrencyInput
											name="totalAmount"
											placeholder={''}
											size="small"
											style={{ width: '100%' }}
											InputProps={{
												endAdornment: (
													<Box>
														<Typography>
															PKR
														</Typography>
													</Box>
												)
											}}
											onBlur={() =>
												onAmountDistribution(
													values,
													setFieldValue
												)
											}
											inputProps={{
												style: {
													textAlign: 'end',
													paddingRight: 5
												}
											}}
										/>
									</FlexCol>
								)}
							</StyledRow>
							<Wrap>
								<BulkPaymentForm contactId={contact.id || ''} />
								<Gutter />
								<Button
									type="submit"
									disabled={isSubmitting || !isValid}
									onClick={() => {
										handleSubmit()
									}}
									style={{
										padding: '5px 20px',
										borderRadius: 8,
										alignSelf: 'flex-end'
									}}
								>
									Record Payment
								</Button>
							</Wrap>
						</>
					)}
				</Formik>
			</Wrapper>
		</StyledModal>
	)
}
