import React from 'react'
import { LAYOUT_PADDING } from 'data'
import { FlexCol } from 'components/atoms/Flex'
import { useDimension } from 'hooks'
import { styled } from '@mui/system'
// import { Header } from 'screen/OrderCenter/Components'

const Container = styled(FlexCol)<{ height: number }>`
	flex: 1;
	min-height: ${({ height }) => (height ? `${height - 1}px` : '100%')};
	max-height: ${({ height }) => (height ? `${height}px` : '100%')};
	padding: 1px;
	width: 100%;
`

const Col = styled(FlexCol)`
	width: 100%;
`

type OrderLayoutProps = {
	children?: React.ReactNode
}

export const OrderLayout: React.ComponentType<OrderLayoutProps> = ({
	children
}) => {
	const { height } = useDimension()

	return (
		<Container height={height - 60 - LAYOUT_PADDING * 2}>
			<Col>{children}</Col>
		</Container>
	)
}
