import React, { useState } from 'react'
import { useField, useFormikContext } from 'formik'
import { styled } from '@mui/system'
import {
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
	useTheme
} from '@mui/material'
import { FlexRow } from 'components/atoms/Flex'
import { Button, ErrorText, Gutter } from 'components'
import { AiOutlinePlus } from 'react-icons/ai'
import type { InventoryItemType, ISettings } from 'typings'
import { objectOmit, objectPick } from 'helpers/object'
import { LAYOUT_BREAKPOINT_WIDTH, SCROLLBAR_STYLE, SEGMENT_EVENTS } from 'data'
import analytics from 'utils/segment'
import { SelectInventoryModal } from './SelectInventoryModal'
import { ImageModal } from './ImageModal'
import type { CurrentInvoiceProps } from 'screen/Invoices/NewCreate/Invoice'
import { OrdrBookerItems } from 'screen/OrderBooker/Components/OrdrBookerItems'

const StyledTableRow = styled(TableRow)`
	background-color: ${({ theme }) => theme.palette.background.default};
`

const StyledPaper = styled(Paper)`
	width: 100%;
	overflow: hidden;
	position: relative;
	background-color: ${({ theme }) => theme.palette.background.default};
`

const TableWrapper = styled(TableContainer)`
	background-color: ${({ theme }) => theme.palette.background.default};
	min-height: 330px;
	max-height: 330px;
	${SCROLLBAR_STYLE}
`

const StyledButton = styled(Button)`
	position: absolute;
	bottom: 2px;
	right: 2px;
	padding: 5px 10px;
	border-radius: 7px;
	background-color: ${({ theme }) => theme.palette.colors['yellow']['300']};
	border: 1px solid ${({ theme }) => theme.palette.colors['gray']['200']};

	:hover {
		background-color: ${({ theme }) =>
		theme.palette.colors['yellow']['200']};
	}
`

const PriceCell = styled(TableCell)`
	@media (max-width: ${LAYOUT_BREAKPOINT_WIDTH}px) {
		display: none;
	}
`

type ItemProps = {
	id: string
	name: string
	code: string
	unit: string
	quantity: number
	perItemCostPrice: number
	perItemSellPrice: number
	stockId: string
}

type OrderTableProps = {
	name: string
	OrderFormSettings: ISettings
	type?: string
	businessId: string
}

export const OrderBookerFormTable: React.ComponentType<OrderTableProps> = ({
	name,
	OrderFormSettings,
	type,
	businessId
}) => {
	const [isModalOpen, setIsModalOpen] = useState(false)
	const [modalOpen, setModalOpen] = useState(false)
	const [selectedImageUrl, setSelectedImageUrl] = useState('')
	const [, meta, helpers] = useField(name)
	const isOpen = true

	const theme = useTheme()
	const {
		palette: { colors }
	} = theme
	const { values, setFieldValue } = useFormikContext<CurrentInvoiceProps>()

	const onInventorySelect = (inventory: InventoryItemType) => {
		const item = inventory.stock[0]
		const exist = meta.value.find(
			(f: ItemProps) => f.stockId === item.stockId
		)

		if (exist) {
			helpers.setValue(
				meta.value.filter((f: ItemProps) => f.stockId !== item.stockId)
			)
		} else {
			if (inventory.stock.length > 0) {
				helpers.setValue([
					...meta.value,
					{
						...objectOmit(
							inventory.stock[0],
							'costCurrency',
							'lastStockedAt',
							'sellCurrency',
							'id'
						),
						...objectPick(inventory, 'name', 'icon'),
						quantity: 1,
						discount: {
							currency: 'PKR',
							amount: 0,
							type: 'flat'
						}
					}
				])
			}
		}
	}

	const handleClose = () => {
		setModalOpen(false)
	}

	/*	const RenderRow = useCallback(
		({
			row,
			index,
			value,
			helpers,
			setSelectedImageUrl,
			setModalOpen
		}: {
			row: ICart
			index: number
			value: ItemProps[]
			helpers: FieldHelperProps<any>
			setSelectedImageUrl: any
			setModalOpen: any
		}) => {
			const toggleImageSize = (icon: string) => {
				if (icon) {
					setSelectedImageUrl(icon)
					setModalOpen(true)
				}
			}

			const onValueChange = (type: string, amount: string) => {
				setFieldValue(
					`${values.items ? `items[${index}].discount` : 'random'}`,
					{
						...values.discount,
						type: type,
						amount: amount
					}
				)
			}

			const onTypeChange = (type: DiscountType) => {
				if (
					type === 'percent' && values.items
						? values.items[index].discount?.type !== 'percent'
						: false
				) {
					onValueChange(
						type,
						convertNumber(
							convertAmountToPercentage(
								parseFloat(
									`${
										values.items
											? values.items[index]
													.perItemSellPrice
											: '0'
									}`
								),
								parseFloat(
									`${
										values.items
											? values.items[index].discount
													?.amount
											: '0'
									}`
								)
							)
						).toString()
					)
				} else if (
					type === 'flat' &&
					values.discount?.type !== 'flat'
				) {
					onValueChange(
						type,
						convertNumber(
							convertPercentageToAmount(
								parseFloat(
									`${
										values.items
											? values.items[index]
													.perItemSellPrice
											: '0'
									}`
								),
								parseFloat(
									`${
										values.items
											? values.items[index].discount
													?.amount
											: '0'
									}`
								)
							)
						).toString()
					)
				}
			}

			const lineTotal = calculateDiscount(
				convertNumber(row.quantity || 0) *
					convertNumber(row.perItemSellPrice || 0),
				row.discount?.amount || 0,
				row.discount?.type || 'percent'
				// eslint-disable-next-line no-mixed-spaces-and-tabs
			)

			const seletedDiscount = DISCOUNT_OPTION.filter(
				f =>
					f.value ===
					(values.items
						? values.items[index]?.discount?.type
						: 'percent')
			)

			const onBlur = () => {
				if (
					values.items &&
					values.items[index].discount?.type === 'percent' &&
					(values.items[index].discount?.amount || 0) > 100
				) {
					setFieldValue(`items[${index}].discount.amount`, 100)
				} else if (
					values.items &&
					values.items[index].discount?.type !== 'percent' &&
					(values.items[index].discount?.amount || 0) >
						convertNumber(row.quantity || 0) *
							convertNumber(row.perItemSellPrice || 0)
				) {
					setFieldValue(`items[${index}].discount.amount`, '0')
				}
			}

			return (
				<StyledTableRow hover role="checkbox" tabIndex={-1}>
					<TableCell>
						<FlexRow>
							<FlexRow align={'center'}>
								{row.icon ? (
									<img
										src={row.icon}
										alt={row.name}
										loading="lazy"
										height={30}
										width={30}
										style={{
											objectFit: 'cover',
											cursor: 'pointer'
										}}
										onClick={() =>
											toggleImageSize(row.icon || '')
										}
									/>
								) : (
									<Avatar
										title={getNameChar(row.name)}
										borderWidth={0}
										sx={{
											backgroundColor:
												theme.palette.colors.green[
													'50'
												],
											color: theme.palette.colors.green[
												'900'
											],
											fontSize: 14,
											width: 30,
											height: 30
										}}
									/>
								)}
							</FlexRow>
							<Gutter gap={1} />
							<FlexCol>
								<Typography
									variant={'caption'}
									fontWeight={'500'}
								>
									{row.name}
								</Typography>

								<Typography
									variant={'caption'}
									color={theme.palette.colors.gray['800']}
								>
									{row.code}
								</Typography>
								<Typography
									variant={'caption'}
									color={theme.palette.colors.gray['500']}
								>
									{`In Stock: ${row.maxQuantity}`}
								</Typography>
								<Price variant={'caption'} fontWeight={'500'}>
									<Abbr
										title={`Rs. ${currencyInput(
											row.perItemSellPrice.toString()
										)}`}
										length={10}
									/>
								</Price>
							</FlexCol>
						</FlexRow>
					</TableCell>
					<PriceCell>
						<Typography variant={'caption'} fontWeight={'500'}>
							<Abbr
								title={`Rs. ${currencyInput(
									row.perItemSellPrice.toString()
								)}`}
								length={10}
							/>
						</Typography>
					</PriceCell>
					<TableCell>
						<FieldNumberInput
							name={`items[${index}].quantity`}
							size={'small'}
							regex={POSITIVE_NUMBER}
							sx={{ width: '8ch' }}
						/>
					</TableCell>
					{isOpen && (
						<TableCell>
							<PopupState
								variant="popper"
								popupId="demo-popup-popper"
								disableAutoFocus={true}
							>
								{popupState => (
									<>
										<FieldNumberInput
											name={`items[${index}].discount.amount`}
											size={'small'}
											placeholder={'Amount'}
											style={{
												maxWidth: 200,
												width: '100%',
												minWidth: 180
											}}
											onBlur={onBlur}
											InputProps={{
												endAdornment: (
													<InputAdornment
														position="end"
														{...bindToggle(
															popupState
														)}
													>
														<ClickAwayListener
															onClickAway={() =>
																popupState.close()
															}
														>
															<Box>
																<Typography>
																	{seletedDiscount.length >
																	0
																		? seletedDiscount[0]
																				.name
																		: 'PKR'}
																</Typography>
																<Gutter
																	gap={0.2}
																/>

																{popupState.isOpen ? (
																	<MdOutlineKeyboardArrowUp />
																) : (
																	<MdOutlineKeyboardArrowDown />
																)}
															</Box>
														</ClickAwayListener>
													</InputAdornment>
												)
											}}
										/>
										<Popper
											{...bindPopper(popupState)}
											transition
											placement="bottom-end"
										>
											{({ TransitionProps }) => (
												<Fade
													{...TransitionProps}
													timeout={350}
												>
													<Wrapper>
														<FlexCol
															style={{
																minWidth: 220
															}}
														>
															<Gutter
																spacing={0.5}
															/>
															{DISCOUNT_OPTION.map(
																d => (
																	<Opt
																		align={
																			'center'
																		}
																		onClick={() => {
																			onTypeChange(
																				d.value as DiscountType
																			)

																			popupState.close()
																		}}
																		key={
																			d.name
																		}
																	>
																		<Typography
																			variant={
																				'body2'
																			}
																		>
																			{
																				d.name
																			}
																		</Typography>
																	</Opt>
																)
															)}
														</FlexCol>
													</Wrapper>
												</Fade>
											)}
										</Popper>
									</>
								)}
							</PopupState>
						</TableCell>
					)}
					<TableCell width={30}>
						<FlexRow style={{ flexWrap: 'nowrap' }}>
							{amountToLocal(convertNumber(lineTotal))}
							<DeleteButton
								onClick={() => {
									helpers.setValue(
										value.filter(
											(f: ItemProps) =>
												!(
													f.stockId === row.stockId &&
													f.unit === row.unit
												)
										)
									)
								}}
							/>
						</FlexRow>
					</TableCell>
				</StyledTableRow>
			)
		},
		[isOpen, setFieldValue, theme.palette, values.discount, values.items]
	)*/

	return (
		<>
			<StyledPaper>
				<TableWrapper>
					<Table stickyHeader aria-label="sticky table">
						<TableHead>
							<StyledTableRow>
								<TableCell>
									<Typography variant={'subtitle2'}>
										Items
									</Typography>
								</TableCell>
								<PriceCell>
									<Typography variant={'subtitle2'}>
										Price
									</Typography>
								</PriceCell>

								<TableCell>
									<Typography variant={'subtitle2'}>
										Quantity
									</Typography>
								</TableCell>

								{OrderFormSettings?.settings?.displayItemLevelDiscountOB && <TableCell>
									<Typography variant={'subtitle2'}>
										Discount
									</Typography>
								</TableCell>}

								<TableCell width={30} />
							</StyledTableRow>
						</TableHead>
						<TableBody>
							{meta.value.map((row: any, index: number) => (
								<OrdrBookerItems
									key={`${row.stockId}-${row.unit}`}
									row={row}
									index={index}
									value={meta.value}
									helpers={helpers}
									setModalOpen={setModalOpen}
									setSelectedImageUrl={setSelectedImageUrl}
									displayItemLevelDiscountOB={OrderFormSettings?.settings?.displayItemLevelDiscountOB || false}
								/>
							))}
						</TableBody>
					</Table>
				</TableWrapper>
				<Gutter spacing={3} />
				<StyledButton
					onClick={() => {
						setIsModalOpen(true)
						analytics.track(SEGMENT_EVENTS.ADD_ITEMS, {
							screen: `record_${type}`
						})
					}}
				>
					<FlexRow justify={'center'} align={'center'}>
						<AiOutlinePlus color={'black'} />
						<Gutter gap={0.3} />
						<Typography color={'black'} variant={'caption'}>
							Add Items
						</Typography>
					</FlexRow>
				</StyledButton>
			</StyledPaper>
			{meta.touched && meta.error && meta.value.length === 0 && (
				<ErrorText text={'min 1 item is required'} />
			)}
			<SelectInventoryModal
				OrderFormSettings={OrderFormSettings}
				businessId={businessId}
				selectedInventory={meta.value}
				open={isModalOpen}
				onClose={() => setIsModalOpen(false)}
				onRowClick={onInventorySelect}
			/>
			<ImageModal
				open={modalOpen}
				imageUrl={selectedImageUrl}
				handleClose={handleClose}
			/>
		</>
	)
}
