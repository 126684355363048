import React from 'react'
import { Typography, type TypographyProps } from '@mui/material'

export const PageTitle: React.ComponentType<TypographyProps> = ({
	children,
	...props
}) => {
	return (
		<Typography variant={'h6'} fontWeight={600} {...props}>
			{children}
		</Typography>
	)
}
