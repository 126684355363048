import React, { useCallback, useEffect, useState } from 'react'
import type { ResponsePageInfo } from 'typings'
import { useAppErrors, useDimension } from 'hooks'
import { useSettings } from 'context/settings'
import { TableContainer } from '@mui/material'
import { useBottomScrollListener } from 'react-bottom-scroll-listener'
import type { InventorySortType } from 'screen/Inventory'
import { LAYOUT_PADDING, SCROLLBAR_STYLE } from 'data'
import { FlexCol } from 'components/atoms/Flex'
import { type FilterObjProps } from 'components'
import { styled } from '@mui/system'
import Table from '@mui/material/Table'
import Paper from '@mui/material/Paper'
import { OrderBookersTable } from './OrderBookersTable'
import { CreateOrderBookerModal } from 'screen/OrderBooker/AddOrderBooker'
import { getAllBusinessBookers, getOrderBookerSettings } from 'api'
import type { IBookerProps } from 'typings/orderbooker'
import { useNavigate } from 'react-router-dom'
import { Header } from './Header'

const Container = styled(FlexCol)<{ height: number }>`
	width: 100%;
	height: ${({ height }) => (height ? `${height}px` : '100%')};
	max-height: ${({ height }) => (height ? `${height}px` : '100%')};
	padding: ${LAYOUT_PADDING}px;
`

const StyledTableContainer = styled(TableContainer)<{ height: number }>`
	min-height: ${({ height }) => height}px;
	max-height: ${({ height }) => height}px;
	background-color: ${({ theme }) => theme.palette.background.default};
	${SCROLLBAR_STYLE}
`

export const OrderBookers = () => {
	const [open, setOpen] = React.useState(false)
	const [filterObj] = useState<FilterObjProps>({})
	const [loading, setLoading] = useState<boolean>(true)
	const [dataSource, setDataSource] = useState<IBookerProps[]>([])
	const [pageInfo, setPageInfo] = useState<ResponsePageInfo>({
		totalPages: 1,
		currentPage: 1,
		edgesPerPage: 20
	})
	const [searchKey, setSearchKey] = useState('')
	const [sortObj, setSortObj] = useState<InventorySortType>({
		sortOrder: 'asc',
		sortBy: 'name'
	})
	const { height } = useDimension()
	const { business } = useSettings()
	const navigate = useNavigate()
	const { setAppError } = useAppErrors()
	const scrollRef = useBottomScrollListener(() => fetchInventory(), {
		offset: 10,
		debounce: 200
	})

	const onSearch = async (searchText: string) => {
		setSearchKey(searchText)
	}

	const getInitialBookersList = useCallback(async () => {
		try {
			setLoading(true)
			const res = await getAllBusinessBookers(business)

			setDataSource(res)
			setPageInfo(res.pageInfo)
		} catch (e: any) {
			setAppError(e)
		} finally {
			setLoading(false)
		}
	}, [business, filterObj, searchKey, setAppError, sortObj])

	const fetchInventory = async () => {
		try {
			setLoading(true)
			if (
				!loading &&
				pageInfo?.totalPages &&
				pageInfo?.totalPages >= pageInfo.currentPage + 1
			) {
				const res: IBookerProps[] = await getAllBusinessBookers(
					business
				)
				setDataSource([...res])
			}
		} catch (e: any) {
			setAppError(e)
		} finally {
			setLoading(false)
		}
	}

	const onSort = useCallback(
		(name: string) => {
			const order =
				sortObj.sortBy === name
					? sortObj.sortOrder === 'asc'
						? 'desc'
						: 'asc'
					: 'asc'

			setSortObj(() => {
				return {
					sortOrder: order,
					sortBy: name
				}
			})
		},
		[sortObj.sortBy, sortObj.sortOrder]
	)

	const fetchOrderBookerSettings = useCallback(async () => {
		try {
			const res = await getOrderBookerSettings(business)
			if (!res) {
				navigate('/home')
			}
		} catch (e: any) {
			setAppError(e)
		}
	}, [setAppError, business])

	const onPrimaryButtonClick = () => {
		setOpen(true)
	}

	useEffect(() => {
		fetchOrderBookerSettings()
		getInitialBookersList()
	}, [getInitialBookersList, business])

	return (
		<>
			<Header
				numOfBookers={dataSource.length}
				onSearch={onSearch}
				onPrimaryButtonClick={onPrimaryButtonClick}
			/>
			<Container height={height - 110}>
				<Paper
					sx={{
						width: '100%',
						overflow: 'hidden',
						zIndex: 0
					}}
				>
					<StyledTableContainer
						ref={scrollRef as any}
						height={height}
					>
						<Table stickyHeader aria-label="sticky table">
							<OrderBookersTable
								onSort={onSort}
								sortObj={sortObj}
								searchKey={searchKey}
								dataSource={dataSource}
								loading={loading}
								onUpdate={() => getInitialBookersList()}
							/>
						</Table>
					</StyledTableContainer>
				</Paper>
				<CreateOrderBookerModal
					open={open}
					onClose={() => setOpen(false)}
					onSuccess={() => {
						getInitialBookersList()
					}}
				/>
			</Container>
		</>
	)
}
