import { axios } from 'store'
import type {
	AwsResponsePropS,
	ContactListPayloadType,
	DataResponseType,
	IBankInfo,
	IContactProps,
	IContactResponseProps,
	OpenContactListPayloadType
} from 'typings'
import queryString from 'query-string'

export const createContact = async (payload: IContactProps) => {
	const url = '/contact'
	try {
		const { data } = await axios.post<IContactProps>(url, payload)

		return data
	} catch (error: any) {
		throw error.response.data
	}
}

export const getAContact = async (contactId: string) => {
	const url = `/contact/${contactId}`

	try {
		const { data } = await axios.get<IContactResponseProps>(url)

		return data
	} catch (error: any) {
		throw error.response.data
	}
}

export const getContactBalance = async () => {
	const url = '/contact/balance-summary'

	try {
		const { data } = await axios.get<
			{
				payable: number
				receivable: number
			}[]
		>(url)

		return data
	} catch (error: any) {
		throw error.response.data
	}
}

export const updateContacts = async (
	id: string,
	payload: Partial<IContactResponseProps>
) => {
	const url = `/contact/${id}`

	try {
		const { data } = await axios.patch<IContactResponseProps>(url, payload)

		return data
	} catch (error: any) {
		throw error.response.data
	}
}

export const updateContactsBank = async (
	contactId: string,
	bankEntryId: string,
	payload: Partial<IBankInfo>
) => {
	const url = `/contact/${contactId}/bank/${bankEntryId}`

	try {
		const { data } = await axios.patch<IContactResponseProps>(url, payload)

		return data
	} catch (error: any) {
		throw error.response.data
	}
}

export const addBankDetails = async (
	contactId: string,
	payload: IBankInfo[]
) => {
	const url = `/contact/${contactId}/banks`

	try {
		const { data } = await axios.post<IContactResponseProps>(url, payload)

		return data
	} catch (error: any) {
		throw error.response.data
	}
}

export const deleteBankDetails = async (
	contactId: string,
	bankId: string | undefined
) => {
	const url = `/contact/${contactId}/banks?bankId=${bankId}`

	try {
		const { data } = await axios.delete<IContactResponseProps>(url)

		return data
	} catch (error: any) {
		throw error.response.data
	}
}

export const getContactList = async (payload: ContactListPayloadType) => {
	const url = `/contact/list?${queryString.stringify(payload)}`

	try {
		const { data } = await axios.get<DataResponseType<IContactProps>>(url)

		return data
	} catch (error: any) {
		throw error.response.data
	}
}

export const uploadContactPic = async (
	fileType: string,
	payload: { contactId?: string }
) => {
	let url = `/contact/contact-pic/upload-url?fileType=${fileType}`

	if (payload.contactId) {
		url += `&contactId=${payload.contactId}`
	}

	try {
		const { data } = await axios.get<AwsResponsePropS>(url)

		return data
	} catch (error: any) {
		throw error.response.data
	}
}

export const getOpenContactList = async (
	payload: OpenContactListPayloadType
) => {
	const url = `/open-api/contacts?${queryString.stringify(payload)}`

	try {
		const { data } = await axios.get<DataResponseType<IContactProps>>(url)

		return data
	} catch (error: any) {
		throw error.response.data
	}
}

export const softDeleteContact = async (id: string) => {
	const url = `contact/${id}`

	try {
		const { data } = await axios.delete(url)

		return data
	} catch (error: any) {
		throw error.response.data
	}
}

export const createOpenContact = async (payload: IContactProps) => {
	const url = '/open-api/create-contact'
	try {
		const { data } = await axios.post<IContactProps>(url, payload)

		return data
	} catch (error: any) {
		throw error.response.data
	}
}
