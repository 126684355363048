import React from 'react'
import { styled } from '@mui/material/styles'
import { TextInput } from 'components'
import type { TextInputProps } from 'components'

const TextFieldStyled = styled(TextInput)`
	input {
		text-align: center;
	}
`

const OtpTextField = (props: TextInputProps) => {
	return <TextFieldStyled InputProps={undefined} {...props} />
}

export default OtpTextField
