export const pdfPageConfig = {
	A4: {
		width: 595 * 1.5,
		height: 842 * 1.5,
		unit: 'pt',
		format: [595 * 1.5, 842 * 1.5],
		orientation: 'portrait',
		margin: {
			top: 28.35,
			right: 28.35,
			bottom: 28.35,
			left: 28.35
		}
	},
	A5: {
		width: 420.94,
		height: 595.28,
		unit: 'pt',
		format: 'a5',
		orientation: 'portrait',
		margin: {
			top: 28.35,
			right: 28.35,
			bottom: 28.35,
			left: 28.35
		}
	}
}
