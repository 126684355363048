import { call, fork, put, take } from 'redux-saga/effects'
import { FETCH_ALL_BUSINESS } from 'store/business/types'
import {
	businessError,
	fetchAllBusinessSuccess,
	setBusinessLoading
} from 'store/business/actions'
import { getAllBusiness } from 'api'
import type { IBusiness } from 'typings/business'

function* fetchBusinesses() {
	try {
		yield put(setBusinessLoading(true))

		const res: IBusiness[] = yield call(getAllBusiness)
		yield put(fetchAllBusinessSuccess(res))
	} catch (e: any) {
		yield put(businessError(e.errors))
	} finally {
		yield put(setBusinessLoading(false))
	}
}

function* businessWatcher(): any {
	while (true) {
		const everything = yield take([FETCH_ALL_BUSINESS])
		if (!everything) {
			return
		}

		const { type } = everything

		switch (type) {
			case FETCH_ALL_BUSINESS:
				yield fork(fetchBusinesses)
				break
		}
	}
}

export default [businessWatcher()]
