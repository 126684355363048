export const ORDER_FORM_TYPE = [
	{
		id: 0,
		label: 'Inquiry',
		value: 'inquiry'
	},
	{
		id: 1,
		label: 'Order',
		value: 'order'
	}
]
