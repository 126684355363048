import {
	Button,
	FieldPhoneNumberInput,
	FieldTextInput,
	Gutter
} from 'components'
import { SEGMENT_EVENTS } from 'data'
import { Formik } from 'formik'
import { validatePhoneNo } from 'helpers/validatePhoneNo'
import React from 'react'
import { AppLayout } from 'screen/Onboarding/AppLayout'
import analytics from 'utils/segment'
import * as yup from 'yup'
import { truncate } from 'helpers'
import { useAppErrors, useDimension, useTranslate } from 'hooks'
import { FormLayout } from 'screen/Onboarding/Form/FormLayout'
import { Typography } from '@mui/material'
import { addUser } from 'api'
import { FlexCol } from 'components/atoms/Flex'
import { styled } from '@mui/system'
import { useSnackbar } from 'notistack'

const validationSchema = yup.object().shape({
	phone: yup.object().shape({
		number: yup
			.string()
			.required('Required')
			.test('number', (num, values) => {
				const {
					parent: { number, countryCode, countryInitials }
				} = values
				const res = validatePhoneNo(
					number,
					countryCode,
					countryInitials
				)

				if (!res.isValid) {
					return values.createError({
						path: 'phone.number',
						message: truncate(res.message, 47)
					})
				}
				return true
			})
			.min(4, 'Phone number should be 4 digits long.')
	})
})

const Container = styled(FlexCol)<{ height: number }>`
	height: ${({ height }) => height - 160}px;
	width: 100%;
`

export const AddUser = () => {
	const translate = useTranslate()
	const { setAppError } = useAppErrors()
	const { enqueueSnackbar } = useSnackbar()

	const { height } = useDimension()

	const onAddUser = async (values: any) => {
		try {
			await addUser({
				secretKey: values.secretKey,
				phone: values.phone.number,
				countryCode: values.phone.countryCode
			})
			enqueueSnackbar('User Added Successfully', {
				variant: 'success'
			})
		} catch (e: any) {
			setAppError(e)
		}
	}

	return (
		<Container height={height}>
			<AppLayout>
				<Formik
					initialValues={{
						secretKey: '',
						phone: {
							number: '',
							countryCode: '',
							countryInitials: ''
						}
					}}
					onSubmit={async (values, actions) => {
						actions.setSubmitting(true)
						await onAddUser(values)
						actions.setSubmitting(false)
					}}
					validationSchema={validationSchema}
				>
					{({ handleSubmit, dirty, isSubmitting, values }) => (
						<FormLayout progress={100} step={0}>
							<Typography
								variant={'h4'}
								fontWeight="medium"
								sx={{
									color: theme =>
										theme.palette.colors.gray['600']
								}}
							>
								{translate('addUser.title')}
							</Typography>
							<Gutter spacing={3} />
							<FieldTextInput
								title="Secret Key"
								placeholder="Enter Secret Key"
								name={'secretKey'}
								style={{ width: '45ch' }}
								size="small"
							/>
							<Gutter />
							<FieldPhoneNumberInput
								title="Phone Number"
								name={'phone.number'}
								dialCode={'phone.countryCode'}
								countryInitials={'phone.countryInitials'}
								style={{ width: '45ch' }}
							/>
							<Gutter />
							<Button
								onClick={() => {
									handleSubmit()
									analytics.track(SEGMENT_EVENTS.ADD_USER, {
										phone: values.phone
									})
								}}
								variant={'contained'}
								disabled={!dirty || isSubmitting}
								buttonColor="#FEC84B"
								textColor="#101828"
							>
								{translate('addUser.addUserButton')}
							</Button>
						</FormLayout>
					)}
				</Formik>
			</AppLayout>
		</Container>
	)
}
