import React, { useCallback, useEffect } from 'react'
import {
	Button,
	ClickAwayListener,
	Fade,
	Popper,
	styled,
	Typography,
	useTheme
} from '@mui/material'
import PopupState, { bindPopper, bindToggle } from 'material-ui-popup-state'
import { Capsule, CheckBox, ErrorText, Gutter } from 'components'
import { FlexCol, FlexRow } from 'components/atoms/Flex'
import { addOrRemoveArrObj } from 'helpers'
import { useField } from 'formik'
import type { ITaxUnitRes } from 'typings'
import { useDispatch, useSelector } from 'react-redux'
import { fetchTaxes } from 'store/settings/actions'
import type { RootState } from 'store/index-reducer'
import { Abbr } from 'components/atoms/Abbr'
import { AddTaxModal } from 'screen/Settting/Taxes'
import { VscError } from 'react-icons/vsc'
import { SCROLLBAR_STYLE } from 'data'

const StyledButton = styled(Button, {
	shouldForwardProp: props => props !== 'isSelected'
})<{ isSelected?: boolean }>`
	background-color: ${({ theme }) => theme.palette.background.default};
	border: 1px solid
		${({ theme, isSelected }) =>
			isSelected
				? theme.palette.colors.green[300]
				: theme.palette.colors.gray[300]};
	border-radius: 8px;
	min-width: 150px;
	min-height: 40px;
`

const Wrapper = styled(FlexCol)`
	background-color: ${({ theme }) => theme.palette.background.default};
	border: 1px solid ${({ theme }) => theme.palette.background.paper};
	border-radius: 8px;
`

const OptionsWrapper = styled(Wrapper)`
	max-height: 300px;
	min-height: 100px;
	overflow: scroll;

	${SCROLLBAR_STYLE}
`

const Col = styled(Wrapper)`
	margin-top: 5px;
	box-shadow: 0 12px 16px -4px rgba(16, 24, 40, 0.08),
		0px 4px 6px -2px rgba(16, 24, 40, 0.03);
`

const StyledCapsule = styled(Capsule)`
	border-radius: 0;
	font-weight: 600;
	font-size: 12px;
	padding: 10px 10px;
	margin: 5px 0 0 0;
	width: 100%;
`

const Option = styled(FlexRow)`
	padding: 8px 14px;
	width: 100%;

	:hover {
		cursor: pointer;
	}
`

const FlexColumn = styled(FlexCol)`
	min-width: 220px;
	flex: 1;
`

const NoData = styled(FlexCol)`
	flex: 1;
	width: 100%;
	height: 100%;
	align-items: center;
	justify-content: center;
`

export type FieldSelectTaxesProps = {
	name: string
}

export const FieldSelectTaxes: React.ComponentType<FieldSelectTaxesProps> = ({
	name
}) => {
	const [open, setOpen] = React.useState<boolean>(false)
	const dispatch = useDispatch()
	const [, meta, helpers] = useField(name as any)
	const error = meta.touched && meta.error
	const theme = useTheme()
	const { dataSource } = useSelector(
		(state: RootState) => state.settings.taxUnits
	)

	const {
		palette: { colors, mode }
	} = theme

	const onCheckBoxSelect = useCallback(
		(name: string, value: ITaxUnitRes) => {
			const res = addOrRemoveArrObj(meta.value || [], value, 'name')
			helpers.setValue(res)
		},
		[helpers, meta.value]
	)

	const RenderMultiSelect = useCallback(
		(opt: ITaxUnitRes) => {
			const checked = !!meta.value.find(
				(f: ITaxUnitRes) => f.name === opt.name && f.rate === opt.rate
			)

			return (
				<Option
					align={'center'}
					key={opt.id}
					onClick={() => onCheckBoxSelect(name, opt)}
				>
					<CheckBox checked={checked} />
					<Gutter gap={1} />
					<Typography variant={'body2'}>
						<Abbr title={opt.name} length={8} /> ({opt.rate}
						%)
					</Typography>
				</Option>
			)
		},
		[meta.value, name, onCheckBoxSelect]
	)

	useEffect(() => {
		dispatch(fetchTaxes())
	}, [dispatch])

	return (
		<>
			<PopupState
				variant="popper"
				popupId="demo-popup-popper"
				disableAutoFocus={true}
			>
				{popupState => (
					<ClickAwayListener onClickAway={() => popupState.close()}>
						<div>
							<StyledButton
								{...bindToggle(popupState)}
								isSelected={meta.value.length !== 0}
							>
								<Typography
									variant={'caption'}
									color={
										mode === 'dark'
											? colors.white['100']
											: colors.black['900']
									}
								>
									{meta.value.length === 0
										? 'Select Tax Type'
										: `${meta.value.length} Taxes Applied`}
								</Typography>
							</StyledButton>
							<div>
								<ErrorText text={error} />
							</div>
							<Popper
								{...bindPopper(popupState)}
								transition
								placement="bottom-start"
							>
								{({ TransitionProps }) => (
									<Fade {...TransitionProps} timeout={350}>
										<Col>
											<OptionsWrapper>
												<FlexColumn>
													<Gutter spacing={0.5} />
													{dataSource.length ===
														0 && (
														<NoData>
															<VscError
																color={
																	theme
																		.palette
																		.colors[
																		'red'
																	]['500']
																}
																size={30}
															/>
															<Gutter
																spacing={0.5}
															/>
															<Typography
																variant={
																	'caption'
																}
															>
																No taxes found
															</Typography>
														</NoData>
													)}
													{dataSource.map(opt => (
														<RenderMultiSelect
															key={opt.id}
															{...opt}
														/>
													))}
												</FlexColumn>
											</OptionsWrapper>
											<StyledCapsule
												name={'Add Tax'}
												value={'add tax'}
												isSelected
												color={'green'}
												onItemClick={() =>
													setOpen(true)
												}
											/>
										</Col>
									</Fade>
								)}
							</Popper>
						</div>
					</ClickAwayListener>
				)}
			</PopupState>
			<AddTaxModal
				onClose={() => {
					setOpen(false)
				}}
				open={open}
			/>
		</>
	)
}
