import type { InvoiceType } from 'typings/invoices'
import type { StatusOptionProps } from 'components/molecules/Status'
import { objectMissingValue, objectOmit } from 'helpers/object'
import { convertNumber } from 'helpers/number'
import moment from 'moment'
import { dateFormat } from 'data'
import type { RadioOptionProps } from 'components/atoms/RadioGroup'
import type { FilterButtonDataSourceProps } from 'screen/Invoices/Components/InvoiceFilter'
import { isEmpty } from 'helpers/constant'
import type { StepperStatusOptionProps } from 'screen/Invoices/View/Component/StatusStepper'
import { calculateDiscount } from 'helpers/mixed'

export const PAYMENT_STATUS_OPTIONS: StatusOptionProps[] = [
	{
		id: 0,
		label: 'Paid',
		value: 'paid',
		color: 'green'
	},
	{
		id: 1,
		label: 'Partially Paid',
		value: 'partial',
		color: 'yellow'
	},
	{
		id: 2,
		label: 'Pending',
		value: 'pending',
		color: 'blue'
	}
]

export const INVOICE_STATUS_OPTIONS: StatusOptionProps[] = [
	{
		id: 0,
		label: 'Void',
		value: 'void',
		color: 'red'
	},
	{
		id: 1,
		label: 'Draft',
		value: 'draft',
		color: 'gray'
	}
]

export const INVOICE_RETURN_INVENTORY_MODAL: RadioOptionProps[] = [
	{
		value: 'yes',
		label: 'Yes'
	},
	{
		value: 'no',
		label: 'No'
	}
]

export const PAYMENT_TABS = [
	{
		name: 'Due Date',
		value: 'dueDate'
	},
	{
		name: 'Number of days',
		value: 'numDays'
	},
	{
		name: 'Custom',
		value: 'custom'
	}
]
export const DISCOUNT_TYPE_OPTIONS: {
	id: number
	label: string
	value: 'percent' | 'flat'
}[] = [
	{
		id: 0,
		label: 'Percentage',
		value: 'percent'
	},
	{
		id: 1,
		label: 'Amount',
		value: 'flat'
	}
]

export const STEPPER_STATUS_OPTIONS_NON_VOID: StepperStatusOptionProps[] = [
	{
		id: 1,
		name: 'Draft',
		value: 'draft',
		color: 'blueLight'
	},
	{
		id: 2,
		name: 'Pending',
		value: 'pending',
		color: 'blue'
	},
	{
		id: 3,
		name: 'Partially Paid',
		value: 'partial',
		color: 'yellow'
	},
	{
		id: 4,
		name: 'Paid',
		value: 'paid',
		color: 'green'
	}
]

export const INVOICE_ITEM = {
	code: '',
	id: moment().toISOString(),
	name: '',
	perItemSellPrice: 0,
	quantity: 1,
	sellCurrency: 'PKR',
	stockId: '',
	unit: '',
	discount: {
		currency: 'PKR',
		amount: 0,
		type: 'flat'
	}
}

export const CREATE_INVOICE_INITIAL: InvoiceType = {
	ledgerBalance: {
		currency: 'PKR',
		amount: 0
	},
	includeBalance: false,
	business: '',
	businessInfo: {
		address: '',
		profilePic: '',
		businessName: '',
		email: '',
		phone: {
			number: '',
			countryCode: '',
			countryInitials: ''
		},
		cnic: '',
		strn: ''
	},
	contact: {
		id: '',
		name: '',
		businessName: '',
		phone: {
			countryInitials: 'PK',
			countryCode: '92',
			number: ''
		},
		address: '',
		businessRelationship: [],
		email: ''
	},
	items: [INVOICE_ITEM as any],
	taxes: [],
	extraCharges: [],
	internalComments: [],
	initiationAt: '',
	discount: {
		amount: '',
		currency: 'PKR',
		type: 'percent'
	},
	advance: {
		amount: '',
		currency: 'PKR'
	},
	paymentStatus: 'pending',
	paymentMethod: '',
	isCustomTermsAndCondition: false,
	paymentTerms: {
		numDays: '',
		dueDate: undefined,
		custom: []
	},
	comments: '',
	status: 'draft',
	id: '',
	pdfKey: null,
	dueAmount: 0
}

export const DISCOUNT_OPTION = [
	{ id: 0, name: 'PKR', value: 'flat' },
	{ id: 0, name: '%', value: 'percent' }
]

export const INVOICE_FILTER_OPTIONS: FilterButtonDataSourceProps[] = [
	{ id: 1, label: 'Active Invoices', value: { status: [] } },
	{ id: 1, label: 'Draft', value: { status: ['draft'] } },
	{
		id: 2,
		label: 'Pending',
		value: { paymentStatus: ['pending'], status: ['publish'] }
	},
	{ id: 3, label: 'Partial Paid', value: { paymentStatus: ['partial'] } },
	{ id: 4, label: 'Paid', value: { paymentStatus: ['paid'] } },
	{ id: 5, label: 'Void', value: { status: ['void'] } }
]

export const GENERATE_INVOICE_DATA = (
	data: InvoiceType
): Required<InvoiceType> => {
	return objectMissingValue(CREATE_INVOICE_INITIAL, {
		...data,
		items: data.items /*.map(itm => {
			return { ...itm, maxQuantity: itm.quantity }
		})*/,
		status: null,
		isDiscountVisible: parseFloat(`${data.discount?.amount}`) > 0,
		isPaymentTerms:
			!!data.paymentTerms?.numDays ||
			data.paymentTerms?.custom?.length > 0 ||
			!!data.paymentTerms?.dueDate
	})
}

export const calculateDueDate = (
	dueDate: string
): { date: string; days: number } => {
	const days = moment(dueDate)
		.startOf('days')
		.diff(moment().startOf('days'), 'days')
	const date = moment(dueDate).format(dateFormat)

	return { days, date }
}

export const calculateInvoiceExtraCharges = (values: InvoiceType) => {
	let totalExtraCharges = 0
	values?.extraCharges.map(charge => {
		const amount =
			charge.amount.toString() == '' ? 0 : parseFloat(`${charge.amount}`)
		totalExtraCharges += amount
	})

	return totalExtraCharges
}

export const calculateInvoiceSubTotal = (values: InvoiceType) => {
	let subTotal = 0

	values.items.forEach((item: any) => {
		const sellPrice =
			isEmpty(item.perItemSellPrice) ||
			Number.isNaN(item.perItemSellPrice)
				? 0
				: parseFloat(`${item.perItemSellPrice}`)
		const quantity =
			isEmpty(item.perItemSellPrice) ||
			Number.isNaN(item.quantity) ||
			!item.quantity
				? 0
				: parseInt(`${item.quantity}`)
		const discount = item.discount
		subTotal += calculateDiscount(
			sellPrice * quantity,
			discount?.amount || 0,
			discount?.type || 'percent'
		)
	})

	return subTotal
}

export const calculateInvoiceSTInclEC = (values: InvoiceType) => {
	let subTotal = 0
	values.items.forEach((item: any) => {
		const sellPrice =
			isEmpty(item.perItemSellPrice) ||
			Number.isNaN(item.perItemSellPrice)
				? 0
				: parseFloat(`${item.perItemSellPrice}`)
		const quantity =
			isEmpty(item.perItemSellPrice) ||
			Number.isNaN(item.quantity) ||
			!item.quantity
				? 0
				: parseInt(`${item.quantity}`)
		subTotal += sellPrice * quantity
	})

	return subTotal + calculateInvoiceExtraCharges(values)
}

export const calculateInvoiceTaxes = (value: InvoiceType, total: number) => {
	let x = 0
	value.taxes.map((tax: any) => {
		x += total * (parseFloat((tax.rate || 0).toString()) / 100)
	})
	return x
}

export const calculateInvoiceDiscountAmount = (
	values: Required<InvoiceType>
) => {
	const {
		discount: { amount, type }
	} = values
	const subtotal = calculateInvoiceSubTotal(values)
	const totalExtraCharges = calculateInvoiceExtraCharges(values)
	const total = subtotal + totalExtraCharges

	if (amount == '' || amount == '.') return 0

	if (amount == null) return 0

	return type === 'percent'
		? (convertNumber(amount) / 100) * total
		: parseFloat(amount.toString())
}

export const calculateInvoiceAdvanceAmount = (
	values: Required<InvoiceType>
) => {
	const { advance } = values

	return advance.amount == '' || values.advance?.amount == '.'
		? 0
		: advance.amount == null
		? 0
		: convertNumber(advance.amount)
}

export const calculateInvoiceTotalAmount = (values: Required<InvoiceType>) => {
	const total = calculateInvoiceSubTotal(values)
	const totalExtraCharges = calculateInvoiceExtraCharges(values)

	const baseAmt = total + totalExtraCharges
	const baseAmtAfterDiscount =
		baseAmt - calculateInvoiceDiscountAmount(values)

	const taxes = calculateInvoiceTaxes(values, baseAmtAfterDiscount)

	const postTaxAmount = baseAmtAfterDiscount + taxes

	return convertNumber(postTaxAmount)
}

export const amountWithoutAdvance = (values: Required<InvoiceType>) => {
	const discount = calculateInvoiceDiscountAmount(values)

	const subtotal = calculateInvoiceSTInclEC(values) - discount

	let total = subtotal

	values.taxes.map((tax: any) => {
		total += subtotal * (parseFloat((tax.rate || 0).toString()) / 100)
	})

	return convertNumber(total)
}

export const invoice_calculation = (values: InvoiceType) => {
	const length = Object.keys(values).length

	const totalExtraCharge =
		length < 2 ? 0 : calculateInvoiceExtraCharges(values)
	const totalItemCost = length < 2 ? 0 : calculateInvoiceSubTotal(values)
	const baseAmount = totalItemCost + totalExtraCharge || 0
	const totalDiscount =
		length < 2
			? 0
			: calculateInvoiceDiscountAmount(objectOmit(values as InvoiceType))
	const baseAmtAfterDiscount = baseAmount - totalDiscount || 0
	const totalAdvance =
		length < 2 ? 0 : calculateInvoiceAdvanceAmount(objectOmit(values, 'id'))
	const totalTax =
		length < 2
			? 0
			: calculateInvoiceTaxes(values as InvoiceType, baseAmtAfterDiscount)
	const totalAmount = length < 2 ? 0 : baseAmtAfterDiscount + totalTax
	const amountDue = length < 2 ? 0 : Math.abs(totalAmount - totalAdvance)

	return {
		advanceAmount: values.advance?.amount,
		totalAmount,
		totalTax,
		totalAdvance,
		baseAmtAfterDiscount,
		totalDiscount,
		totalExtraCharge,
		totalItemCost,
		baseAmount,
		amountDue
	}
}
