import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { FlexCol, FlexRow } from 'components/atoms/Flex'
import { styled } from '@mui/system'
import { Modal, Typography, useTheme } from '@mui/material'
import { BouncingDotsLoader, Gutter, ModalWrapper } from 'components'
import { HiOutlineDocument } from 'react-icons/hi'
import { IoIosClose } from 'react-icons/io'
import html2canvas from 'html2canvas'
import { useAppErrors } from 'hooks'
import analytics from 'utils/segment'
import { SEGMENT_EVENTS } from 'data'
import { getSignedUrlForInvoicePdf } from 'api/invoice'
import type { InvoiceSettingsType, InvoiceType } from 'typings'
import { enqueueSnackbar } from 'notistack'
import { useSelector } from 'react-redux'
import type { RootState } from 'store/index-reducer'
import { TransparentButton } from 'components/atoms/Button/TransparentButton'
import { truncate } from 'helpers'
import { getInvoiceSettings } from 'api'

const Wrapper = styled(FlexRow)`
	padding: 30px 20px;
	border-radius: 10px;
	background-color: ${({ theme }) => theme.palette.colors.white['900']};
	max-width: 550px;
	position: relative;
`

const Box = styled(FlexRow)`
	padding: 12px;
	margin-top: 5px;
	border-radius: 7px;
	background-color: ${({ theme }) => theme.palette.colors.green['50']};
`

const CloseButtonWrapper = styled(FlexCol)`
	position: absolute;
	top: 10px;
	right: 10px;
	justify-content: center;
	align-items: center;
	height: 30px;
	width: 30px;
	&:hover {
		cursor: pointer;
		border-radius: 25%;
		background-color: ${({ theme }) => theme.palette.colors.gray['100']};
		display: flex;
	}
`

const Grid = styled('div')`
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 10px;
	width: 100%;
`

type Props = {
	open: boolean
	onClose: () => void
	divRef: React.RefObject<HTMLDivElement>
	invoice: InvoiceType
	invoiceSettings?: InvoiceSettingsType
}

export const DownloadInvoice: React.ComponentType<Props> = ({
	open,
	onClose,
	invoice,
	divRef,
	invoiceSettings
}) => {
	const theme = useTheme()
	const { colors } = theme.palette
	const { setAppError } = useAppErrors()
	const [isDownloading, setIsDownloading] = useState<boolean>(false)
	const [invoiceSetting, setInvoiceSetting] =
		useState<InvoiceSettingsType>()
	const { user } = useSelector((state: RootState) => state.user)

	const fetchInvoiceSettings = useCallback(async () => {
		try {
			const res = await getInvoiceSettings(invoice.business)
			setInvoiceSetting(res)
		} catch (e: any) {
			setAppError(e)
		}

	}, [invoice])

	const handleDownload = async (invoice: InvoiceType) => {
		try {
			setIsDownloading(true)
			const data = {
				...invoice,
				businessInfo: {
					...invoice.businessInfo,
					email: user?.email || '',
					phone: user?.phone || { countryCode: '', number: '' },
					cnic: invoiceSetting?.settings.displayBusinessCnic
						? invoice.businessInfo.cnic
						: '',
					strn: invoiceSetting?.settings.displayBusinessStrn
						? invoice.businessInfo.strn
						: ''
				},
				contact: {
					...invoice.contact,
					cnic: invoiceSetting?.settings.displayCustomerCnic
						? invoice.contact.cnic
						: ''
				}
			}
			if (data?.orderBooker?.name) {
				data.orderBooker.name = truncate(
					data?.orderBooker?.name || '',
					30
				)
			}
			const res = await getSignedUrlForInvoicePdf([data])

			const filename = `invoice-${invoice?.id}.pdf`
			const response = await fetch(res.data.signedUrl)
			const blob = await response.blob()

			// Create a temporary URL for the blob
			const blobUrl = window.URL.createObjectURL(new Blob([blob]))

			const link = document.createElement('a')
			link.href = blobUrl
			link.download = filename
			link.click()

			setIsDownloading(false)

			window.URL.revokeObjectURL(blobUrl)
			link.remove()

			// Segment event
			analytics.track(SEGMENT_EVENTS.PDF_INV_SHARE, {
				channel_selected: 'Download'
			})
			// close modal
			onClose()

			enqueueSnackbar('Invoice downloaded successfully', {
				variant: 'success'
			})
		} catch (e: any) {
			setAppError(e)
		}
	}

	const generateImage = useCallback(() => {
		if (divRef.current) {
			html2canvas(divRef.current, {
				windowWidth: 2000,
				windowHeight: 8000,
				height: divRef.current.scrollHeight,
				scrollX: 0,
				scrollY: -window.scrollY
			}).then(canvas => {
				const image = canvas.toDataURL('image/png')
				const link = document.createElement('a')
				link.href = image
				link.download = `Invoice-${invoice?.id}.jpg`
				link.click()
			})
		}
		onClose()
	}, [divRef, invoice, onClose])

	useEffect(() => {
		fetchInvoiceSettings()
	}, [fetchInvoiceSettings])

	return (
		<Modal open={open} onClose={onClose}>
			<ModalWrapper>
				<Wrapper>
					<CloseButtonWrapper>
						<IoIosClose size={25} onClick={onClose} />
					</CloseButtonWrapper>
					<Box>
						<HiOutlineDocument
							color={colors.green['500']}
							size={22}
						/>
					</Box>
					<Gutter gap={1.5} />
					<FlexCol style={{ flex: 1 }}>
						<FlexRow>
							<Typography variant="h6" fontWeight={600}>
								Download Invoice as...
							</Typography>
						</FlexRow>
						<Typography
							variant="body2"
							color={colors.gray['400']}
							fontWeight={500}
						>
							Do you want to download this invoice as PDF document
							or a png image?
						</Typography>
						<Gutter spacing={2.2} />
						<Grid>
							<TransparentButton
								disabled={isDownloading}
								onClick={generateImage}
							>
								<Typography
									variant="subtitle1"
									fontWeight={500}
								>
									Image{' '}
								</Typography>
							</TransparentButton>
							<TransparentButton
								onClick={() => handleDownload(invoice)}
								disabled={isDownloading}
							>
								<Typography
									variant="subtitle1"
									fontWeight={500}
								>
									{!isDownloading ? (
										'Pdf'
									) : (
										<BouncingDotsLoader />
									)}
								</Typography>
							</TransparentButton>
						</Grid>
					</FlexCol>
				</Wrapper>
			</ModalWrapper>
		</Modal>
	)
}
