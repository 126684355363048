import React, { type FC, type ReactElement } from 'react'
import { useField } from 'formik'
import {
	CustomRadioGroup,
	type RadioGroupProps
} from 'components/atoms/RadioGroup'
import { ErrorText } from 'components/atoms'

export type RadioGroupFieldProps = {
	name: string
} & Omit<RadioGroupProps, 'defaultValue' | 'name'>

export const FieldRadioGroup: FC<RadioGroupFieldProps> = ({
	name,
	options,
	onChange,
	...props
}): ReactElement => {
	const [, meta, helpers] = useField(name as any)

	return (
		<>
			<CustomRadioGroup
				options={options}
				defaultValue={meta.value}
				onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
					helpers.setValue(event?.target.value)
					onChange?.(event, event.target.value)
				}}
				{...props}
			/>

			{meta.touched && meta.error && <ErrorText text={meta.error} />}
		</>
	)
}
