import React, { useCallback, useMemo } from 'react'
import {
	Button,
	ClickAwayListener,
	Fade,
	Popper,
	styled,
	Typography,
	useTheme
} from '@mui/material'
import PopupState, { bindPopper, bindToggle } from 'material-ui-popup-state'
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp'
import MuiAccordion, { type AccordionProps } from '@mui/material/Accordion'
import type { AccordionSummaryProps } from '@mui/material/AccordionSummary'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import { CheckBox, Gutter } from 'components'
import { FlexCol, FlexRow } from 'components/atoms/Flex'
import { addOrRemoveArr } from 'helpers'
import { useSelector } from 'react-redux'
import type { RootState } from 'store/index-reducer'
import analytics from 'utils/segment'
import { SECONDARY_BUTTON_BREAKPOINT_WIDTH, SEGMENT_EVENTS } from 'data'
import { FilterAltOutlined } from '@mui/icons-material'

const StyledButton = styled(Button, {
	shouldForwardProp: props => props !== 'isFilter'
})<{ isFilter?: boolean }>`
	background-color: ${({ theme, isFilter }) =>
		isFilter
			? theme.palette.colors.green[50]
			: theme.palette.background.default};
	border: 1px solid
		${({ theme, isFilter }) =>
			isFilter
				? theme.palette.colors.green[600]
				: theme.palette.colors.gray[300]};
	min-width: 0;
	min-height: 32px;
	border-radius: 5px;
	padding: 0px 9px;
`

const Option = styled(FlexRow)`
	padding: 8px 14px;

	:hover {
		cursor: pointer;
	}
`

const Wrapper = styled(FlexCol)`
	min-width: 280px;
	background-color: ${({ theme }) => theme.palette.background.default};
	border: 1px solid ${({ theme }) => theme.palette.background.paper};
	border-radius: 8px;
	margin-top: 5px;
	box-shadow: 0 12px 16px -4px rgba(16, 24, 40, 0.08),
		0px 4px 6px -2px rgba(16, 24, 40, 0.03);
`

const Accordion = styled((props: AccordionProps) => (
	<MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
	width: '100%',
	border: `1px solid ${theme.palette.background.paper}`,
	'&:before': {
		display: 'none'
	}
}))

const AccordionSummary = styled((props: AccordionSummaryProps) => (
	<MuiAccordionSummary
		expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
		{...props}
	/>
))(({ theme }) => ({
	width: '100%',
	backgroundColor: theme.palette.background.default,

	flexDirection: 'row-reverse',
	'& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
		transform: 'rotate(90deg)'
	},
	'& .MuiAccordionSummary-content': {
		marginLeft: theme.spacing(1)
	}
}))

const ClearBtn = styled(FlexRow)`
	width: 100%;
	padding: 10px 20px 10px 10px;

	:hover {
		cursor: pointer;
	}
`

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
	backgroundColor: theme.palette.background.default,
	width: '100%',
	padding: theme.spacing(2),
	borderTop: '1px solid rgba(0, 0, 0, .125)',
	maxHeight: 250,
	overflowY: 'scroll'
}))

const SecondaryButtonWrapper = styled(FlexRow)`
	@media (max-width: ${SECONDARY_BUTTON_BREAKPOINT_WIDTH}px) {
		display: none;
	}
`

export type InventoryFilterObjProps = {
	labels?: string[]
	prices?: number[]
	dates?: string[]
}

export type InventoryFilterButtonProps = {
	onSelect?(filterObj: InventoryFilterObjProps): void
	filterObj: InventoryFilterObjProps
}

export const InventoryFilterButton: React.ComponentType<
	InventoryFilterButtonProps
> = ({ onSelect, filterObj }) => {
	const [expanded, setExpanded] = React.useState<string | false>('label')
	const { labels } = useSelector((state: RootState) => state.inventory)
	const theme = useTheme()
	const {
		palette: { colors, mode }
	} = theme

	const handleChange =
		(panel: string) =>
		(event: React.SyntheticEvent, newExpanded: boolean) => {
			if (newExpanded)
				analytics.track(SEGMENT_EVENTS.INVENTORY_FILTER_BY_LABEL)
			setExpanded(newExpanded ? panel : false)
		}

	const onValueChange = useCallback(
		(name: string, value: string[] | number[]) => {
			onSelect?.({ ...filterObj, [name]: value })
		},
		[filterObj, onSelect]
	)

	const totalAppliedFilter = useMemo(() => {
		let length = 0
		for (const key in filterObj) {
			length +=
				filterObj[key as keyof InventoryFilterObjProps]?.length || 0
		}
		return length
	}, [filterObj])

	const { labels: filterLabel } = filterObj

	const LabelOption = useCallback(
		({ opt }: { opt: string }) => {
			return (
				<Option
					align={'center'}
					key={opt}
					onClick={() => {
						onValueChange(
							'labels',
							addOrRemoveArr(filterObj.labels || [], opt) as []
						)
					}}
				>
					<CheckBox
						checked={filterLabel && filterLabel.indexOf(opt) !== -1}
					/>
					<Gutter gap={1} />
					<Typography
						variant={'body2'}
						style={{ overflowWrap: 'break-word' }}
					>
						{opt}
					</Typography>
				</Option>
			)
		},
		[filterLabel, filterObj.labels, onValueChange]
	)

	return (
		<PopupState
			variant="popper"
			popupId="demo-popup-popper"
			disableAutoFocus={true}
		>
			{popupState => (
				<ClickAwayListener onClickAway={() => popupState.close()}>
					<div>
						<StyledButton
							{...bindToggle(popupState)}
							isFilter={totalAppliedFilter !== 0}
						>
							<FilterAltOutlined
								fontSize="small"
								sx={{ color: colors.gray['900'] }}
							/>
							<SecondaryButtonWrapper>
								<Gutter gap={0.3} />
								<Typography
									variant={'caption'}
									color={
										mode === 'dark'
											? colors.white['100']
											: colors.black['900']
									}
									fontWeight={500}
								>
									{totalAppliedFilter === 0
										? 'Filter By Label'
										: `${totalAppliedFilter} Filters Applied`}
								</Typography>
							</SecondaryButtonWrapper>
						</StyledButton>
						<Popper
							{...bindPopper(popupState)}
							transition
							placement="bottom-start"
						>
							{({ TransitionProps }) => (
								<Fade {...TransitionProps} timeout={350}>
									<Wrapper>
										<Accordion
											expanded={expanded === 'label'}
											onChange={handleChange('label')}
										>
											<AccordionSummary
												aria-controls="panel1d-content"
												id="panel1d-header"
											>
												<Typography
													variant={'body2'}
													fontWeight={500}
												>
													Label
												</Typography>
											</AccordionSummary>
											<AccordionDetails>
												{labels.length === 0 && (
													<Typography
														variant={'caption'}
														fontWeight={500}
													>
														No Label found
													</Typography>
												)}
												{labels.map((opt, i) => (
													<LabelOption
														key={`${i}-${opt}`}
														opt={opt}
													/>
												))}
											</AccordionDetails>
										</Accordion>

										{/*<Accordion
											expanded={expanded === 'price'}
											onChange={handleChange('price')}
										>
											<AccordionSummary
												aria-controls="panel2d-content"
												id="panel2d-header"
											>
												<Typography
													variant={'body2'}
													fontWeight={500}
												>
													Price
												</Typography>
											</AccordionSummary>
											<AccordionDetails>
												<Gutter spacing={1} />
												<FlexRow>
													<Slider
														getAriaLabel={() =>
															'Temperature range'
														}
														value={
															prices || [100, 800]
														}
														onChange={(
															e,
															newValue
														) => {
															onValueChange(
																'prices',
																newValue as number[]
															)
														}}
														valueLabelDisplay="auto"
														min={0}
														max={1200}
														marks={[
															{
																value: 0,
																label: 'Min'
															},
															{
																value: 1200,
																label: 'Max'
															}
														]}
														sx={{
															marginRight: 2,
															marginLeft: 2
														}}
													/>
												</FlexRow>
												<Gutter spacing={1} />
											</AccordionDetails>
										</Accordion>*/}

										{/*<Accordion
											expanded={expanded === 'panel3'}
											onChange={handleChange('panel3')}
										>
											<AccordionSummary
												aria-controls="panel3d-content"
												id="panel3d-header"
											>
												<Typography
													variant={'body2'}
													fontWeight={500}
												>
													Date Stocked
												</Typography>
											</AccordionSummary>
											<AccordionDetails>
												{INVENTORY_DATE_FILTER.map(
													opt => (
														<Option
															align={'center'}
															key={opt.value}
															onClick={() => {
																onValueChange(
																	'dates',
																	addOrRemoveArr(
																		dates ||
																			[],
																		opt.value
																	)
																)
															}}
														>
															<CheckBox
																checked={
																	dates &&
																	dates.indexOf(
																		opt.value
																	) !== -1
																}
															/>
															<Gutter gap={1} />
															<Typography
																variant={
																	'body2'
																}
															>
																{opt.name}
															</Typography>
														</Option>
													)
												)}
											</AccordionDetails>
										</Accordion>*/}
										<ClearBtn
											style={{ width: '100%' }}
											justify={'flex-end'}
											onClick={() => {
												onSelect?.({})
											}}
										>
											<Typography
												variant={'caption'}
												color={
													theme.palette.primary.main
												}
											>
												Clear All
											</Typography>
										</ClearBtn>
									</Wrapper>
								</Fade>
							)}
						</Popper>
					</div>
				</ClickAwayListener>
			)}
		</PopupState>
	)
}
