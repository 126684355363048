import * as React from 'react'
import { useCallback, useEffect, useState } from 'react'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { useTheme } from '@mui/material'
import { Gutter } from 'components'
import type { InvoiceType } from 'typings'
import { ActiveLoan } from 'screen/OrderFinancing/ActiveLoan/ActiveLoan'
import { getLoanList } from 'api/cbfs'
import { useAppErrors } from 'hooks'

interface TabPanelProps {
	children?: React.ReactNode
	index: number
	value: number
}

function CustomTabPanel(props: TabPanelProps) {
	const { children, value, index, ...other } = props

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box>
					<Gutter spacing={0.5} />
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	)
}

function a11yProps(index: number) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`
	}
}

type LoadSheetTabsProps = {
	onItemSelect?(inv: InvoiceType): void
	value: number
	setValue: React.Dispatch<React.SetStateAction<number>>
}

export const OFTabs: React.ComponentType<LoadSheetTabsProps> = ({
	onItemSelect,
	value,
	setValue
}) => {
	const [loading, setLoading] = useState(false)
	const [dataSource, setDataSource] = useState([])
	const { setAppError } = useAppErrors()

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setValue(newValue)
	}
	const theme = useTheme()
	const {
		palette: { colors }
	} = theme

	const getInitialList = useCallback(async () => {
		try {
			setLoading(true)
			const res = await getLoanList()

			setDataSource(res.loans)
		} catch (e: any) {
			setAppError(e)
		} finally {
			setLoading(false)
		}
	}, [setAppError])

	useEffect(() => {
		getInitialList()
	}, [getInitialList])

	return (
		<Box sx={{ width: '100%' }}>
			<Box sx={{ borderBottom: 1, borderColor: colors.gray['300'] }}>
				<Tabs
					value={value}
					onChange={handleChange}
					aria-label="basic tabs example"
				>
					<Tab
						label={`Active Loans (${dataSource.length})`}
						{...a11yProps(0)}
					/>
					<Tab label={'Pending Applications (0)'} {...a11yProps(1)} />
				</Tabs>
			</Box>
			<CustomTabPanel value={value} index={0}>
				<ActiveLoan
					dataSource={dataSource}
					fetchActiveLoan={() => {
						//
					}}
				/>
			</CustomTabPanel>
			<CustomTabPanel value={value} index={1}>
				<Typography />
			</CustomTabPanel>
		</Box>
	)
}
