import React, { useCallback, useState } from 'react'
import { styled, Typography } from '@mui/material'
import { Capsule, Gutter } from 'components'
import { FlexCol } from 'components/atoms/Flex'
import { RiCalendarEventFill } from 'react-icons/ri'
import { pascalCase } from 'helpers'
import type { DesktopDatePickerProps } from '@mui/x-date-pickers/DesktopDatePicker'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import type { TextFieldProps } from '@mui/material/TextField'
import moment from 'moment'
import { useField } from 'formik'
import { dateFormat } from 'data'

const WrapperCapsule = styled(Capsule)`
	padding: 3px 7px;
	font-size: 15px;
	margin: 0;
`

const Col = styled(FlexCol)`
	margin-bottom: 10px;
`

const Wrapper = styled(FlexCol)`
	position: relative;
	align-items: flex-end;
`

type InitiationDateProps = {
	title?: string
	name: string
	segmentEventCall?(): void
} & DesktopDatePickerProps<any>

export const InitiationDate: React.ComponentType<InitiationDateProps> = ({
	title,
	name,
	segmentEventCall,
	...props
}) => {
	const [, meta, helpers] = useField(name)
	const [isPickerOpen, setIsPickerOpen] = useState(false)

	const CustomInput = useCallback(
		function BrowserInput(props: TextFieldProps) {
			return (
				<Col
					onClick={e => {
						e.stopPropagation()
						setIsPickerOpen(!isPickerOpen)
						segmentEventCall?.()
					}}
					ref={props?.InputProps?.ref as any}
				>
					<WrapperCapsule
						name={`${moment(meta.value).format(dateFormat)}`}
						value="date"
						isSelected
						color="blue"
						renderLeft={
							<RiCalendarEventFill
								style={{ marginRight: '3px' }}
							/>
						}
					/>
				</Col>
			)
		},
		[isPickerOpen, meta.value, segmentEventCall]
	)

	return (
		<Wrapper align={'flex-end'}>
			{title && (
				<FlexCol>
					<Typography variant={'body2'} fontWeight={500}>
						{pascalCase(title)}
					</Typography>
					<Gutter spacing={0.5} />
				</FlexCol>
			)}
			<DesktopDatePicker
				closeOnSelect={true}
				value={moment(meta.value)}
				onChange={e => {
					helpers.setValue(moment.utc(e).format())
					setIsPickerOpen(false)
				}}
				open={isPickerOpen}
				slots={{
					textField: CustomInput
				}}
				{...props}
				onClose={() => setIsPickerOpen(false)}
			/>
		</Wrapper>
	)
}
