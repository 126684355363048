import React, { useState,useMemo } from 'react'
import { styled, Typography, useTheme } from '@mui/material'
import { PersonalInformationForm } from './PersonalInformation'
import { BusinessesTable } from './BusinessManagement'
import { Button, Gutter } from 'components'
import { FlexCol, FlexRow } from 'components/atoms/Flex'
import { LAYOUT_BREAKPOINT_WIDTH, LAYOUT_PADDING, PROFILE_OPTIONS } from 'data'
import { BusinessesModal } from './BusinessManagement/BusinessesModal'
import { ProfileFilter } from './ProfileFilter'
import type { ColorsCodeType, ProfileTypes } from 'typings'
import { Add } from '@mui/icons-material'
import { useDimension } from 'hooks'
import { useSelector } from 'react-redux'
import type { RootState } from 'store/index-reducer'
import { useSettings } from 'context/settings'

const Wrapper = styled(FlexCol)<{ height: number }>`
	flex: 1;
	width: 100%;
	height: ${({ height }) => (height ? `${height}px` : '100%')};
	max-height: ${({ height }) => (height ? `${height}px` : '100%')};
	align-items: center;
	padding: ${LAYOUT_PADDING}px;
`

const Container = styled(FlexCol)`
	width: 100%;
	height: 100%;
`

const StyledButton = styled(Button)`
	min-width: 0;
	min-height: 32px;
	border-radius: 5px;
	padding: 0px 10px;
`

const MobileWrapper = styled(FlexRow)`
	@media (max-width: ${LAYOUT_BREAKPOINT_WIDTH}px) {
		display: none;
	}
`

const RowWrapper = styled(FlexRow)`
	width: 100%;
	flex-wrap: nowrap;
	align-items: center;
	justify-content: space-between;
	padding: 5px 16px 4px 16px;
	border-bottom: 1px solid ${({ theme }) => theme.palette.colors.gray['200']};
`

export const Profile = () => {
	const [editId, setEditId] = useState<string | undefined>('')
	const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false)
	const [onPersonalInformation, setOnPersonalInformation] =
		useState<ProfileTypes>('personalInformation')
	const [capsuleNumber, setCapsuleNumber] = useState<number | undefined>()
	const { user } = useSelector((state: RootState) => state.user)
	const { business } = useSettings()
	const currentRole = useMemo(() => {
		if (user?.userRoles && user?.userRoles.length > 0)
			return user?.userRoles.find(f => f.business === business)?.role
		return 'admin'
	}, [user?.userRoles, business])

	const theme = useTheme()
	const {
		palette: { colors }
	} = theme
	const { height } = useDimension()

	const handleOnCapsuleClick = (title: ProfileTypes) => {
		setOnPersonalInformation(title)
	}

	const handleModalClose = () => {
		setIsEditModalOpen(false)
		setEditId(undefined)
	}

	return (
		<Container>
			<RowWrapper>
				<ProfileFilter
					capsuleNumber={capsuleNumber}
					dataSource={PROFILE_OPTIONS}
					filterObj={onPersonalInformation}
					onSelect={handleOnCapsuleClick}
				/>
				{onPersonalInformation === 'businessManagement' &&
					currentRole === 'owner' && (
						<StyledButton
							textColor={colors.gray['900'] as ColorsCodeType}
							mode={'primary'}
							buttonColor={colors.yellow['300'] as ColorsCodeType}
							onClick={() => setIsEditModalOpen(true)}
						>
							<Add fontSize="small" />
							<MobileWrapper>
								<Gutter gap={0.5} />
								{'Add New Business'}
							</MobileWrapper>
						</StyledButton>
					)}
			</RowWrapper>
			<Wrapper height={height - 110}>
				<FlexCol style={{ width: '100%' }}>
					{onPersonalInformation === 'personalInformation' ? (
						<PersonalInformationForm />
					) : (
						<BusinessesTable
							setEditId={setEditId}
							setIsEditModalOpen={setIsEditModalOpen}
							setCapsuleNumber={setCapsuleNumber}
						/>
					)}
				</FlexCol>
			</Wrapper>
			<BusinessesModal
				businessId={editId}
				handleCloseForm={handleModalClose}
				isOpen={isEditModalOpen}
			/>
		</Container>
	)
}
