import React, { useCallback } from 'react'
import {
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
	useTheme
} from '@mui/material'
import { styled } from '@mui/system'
import type { InvoiceItemProps, QuotationType } from 'typings'
import { convertNumber } from 'helpers/number'
import { amountToLocal } from 'helpers'
import { FlexRow } from 'components/atoms/Flex'
import { SCROLLBAR_STYLE } from 'data'

const Wrapper = styled('div')``

const StyledTableContainer = styled(TableContainer)`
	background-color: ${({ theme }) => theme.palette.background.default};
	${SCROLLBAR_STYLE}
`

const ProductCell = styled(TableCell)<{ width?: number }>`
	min-width: ${({ width }) => (width ? `${width}px` : '300px')};
	max-width: ${({ width }) => (width ? `${width}px` : '300px')};
	background-color: ${({ theme }) => theme.palette.colors.gray['50']};
	color: ${({ theme }) => theme.palette.colors.gray['500']};
	vertical-align: top;
`

const MedCell = styled(TableCell)<{ width?: number }>`
	vertical-align: top;
	min-width: ${({ width }) => (width ? `${width}px` : '180px')};
	max-width: 200px;
	background-color: ${({ theme }) => theme.palette.colors.gray['50']};
	color: ${({ theme }) => theme.palette.colors.gray['500']};
`

const Product = styled(ProductCell)`
	vertical-align: top;
	background-color: ${({ theme }) => theme.palette.background.default};
`

const Info = styled(MedCell)`
	vertical-align: top;
	background-color: ${({ theme }) => theme.palette.background.default};
`

const DescriptionWrapper = styled(FlexRow)`
	width: 100%;
	max-width: 100%;
	margin-top: 5px;
	padding: 4px 10px;
	background-color: ${({ theme }) => theme.palette.colors['gray']['50']};

	align-items: center;
	justify-content: flex-start;
`

const StyledTableBody = styled(TableBody)`
	height: 100%;
`

const StyledTableRow = styled(TableRow)(() => ({
	backgroundColor: '#fff'
}))

type Props = {
	title?: string
	currentQuotation: QuotationType
}

export const Cart: React.ComponentType<Props> = ({ currentQuotation }) => {
	const theme = useTheme()
	const { colors } = theme.palette
	const { items } = currentQuotation

	const Item = useCallback(
		({ row }: { row: InvoiceItemProps }) => {
			return (
				<StyledTableRow>
					<Product id={'name'}>
						<Typography
							variant={'caption'}
							style={{
								color: colors.gray['700'],
								overflowWrap: 'break-word'
							}}
							fontWeight={'500'}
							display={'block'}
						>
							{row.name}
						</Typography>
						{row.description && (
							<DescriptionWrapper>
								<Typography
									variant={'caption'}
									style={{
										color: colors.gray['700'],
										overflowWrap: 'break-word',
										overflowX: 'auto'
									}}
									fontWeight={'500'}
								>
									{row.description}
								</Typography>
							</DescriptionWrapper>
						)}
					</Product>
					<Info align="left" width={100}>
						<Typography
							variant={'caption'}
							style={{
								color: colors.gray['700'],
								overflowWrap: 'break-word',
								overflowX: 'auto'
							}}
							fontWeight={'500'}
						>
							{row.unit}
						</Typography>
					</Info>
					<Info align="left" width={120}>
						<Typography
							variant={'caption'}
							fontWeight={'500'}
							style={{
								overflowWrap: 'break-word'
							}}
						>
							{amountToLocal(row.perItemSellPrice)}
						</Typography>
					</Info>
					<Info align="left" width={100}>
						<Typography variant={'caption'} fontWeight={'500'}>
							{row.quantity}
						</Typography>
					</Info>
					<Info align="left" width={100}>
						<Typography
							variant={'caption'}
							fontWeight={'500'}
							color={colors.green['700']}
							style={{
								overflowWrap: 'break-word'
							}}
						>
							{amountToLocal(
								convertNumber(row.quantity) *
									convertNumber(row.perItemSellPrice)
							)}
						</Typography>
					</Info>
				</StyledTableRow>
			)
		},
		[colors.gray, colors.green]
	)

	return (
		<Wrapper>
			<Paper sx={{ width: '100%' }}>
				<StyledTableContainer>
					<Table aria-label="sticky table">
						<TableHead>
							<ProductCell id={'name'}>
								<Typography
									variant={'caption'}
									style={{ color: colors.gray['700'] }}
									fontWeight={'500'}
								>
									Items
								</Typography>
							</ProductCell>
							<MedCell align="left" width={100}>
								<Typography
									variant={'caption'}
									fontWeight={'500'}
								>
									Unit
								</Typography>
							</MedCell>
							<MedCell align="left" width={120}>
								<Typography
									variant={'caption'}
									fontWeight={'500'}
								>
									Selling Price
								</Typography>
							</MedCell>
							<MedCell align="left" width={100}>
								<Typography
									variant={'caption'}
									fontWeight={'500'}
								>
									Quantity
								</Typography>
							</MedCell>
							<MedCell align="left" width={100}>
								<Typography
									variant={'caption'}
									fontWeight={'500'}
								>
									Line Total
								</Typography>
							</MedCell>
						</TableHead>
						<StyledTableBody>
							{items.map(row => (
								<Item key={`${row.id}`} row={row} />
							))}
						</StyledTableBody>
					</Table>
				</StyledTableContainer>
			</Paper>
		</Wrapper>
	)
}
