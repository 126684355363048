import produce from 'immer'
import { type CBFSActionTypes, SET_CBFS_INFO } from './types'

interface CBFSState {
	loading: boolean
	business: { kyb: any; user: any }
}

const initialState: CBFSState = {
	loading: false,
	business: { kyb: undefined, user: undefined }
}

const reducer = produce((draft: CBFSState, action: CBFSActionTypes) => {
	switch (action.type) {
		case SET_CBFS_INFO:
			draft.business = action.payload
			return

		default:
			return
	}
}, initialState)

export default reducer
